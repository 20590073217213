import { CalendarMonth, ViewAgenda } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { setCalendarMode } from '../../reducers/CalendarReducer';

export default function ToggleCalendarAgenda() {
  const dispatch = useAppDispatch();
  const calendarMode = useAppSelector((state) => state.CalendarReducer.calendarMode);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>, 
    newView: 'calendar' | 'agenda' | null
  ) => {
    if (newView !== null) {
      dispatch(setCalendarMode(newView));
    }
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      padding: '16px 0'
    }}>
      <ToggleButtonGroup
        value={calendarMode}
        exclusive
        onChange={handleViewChange}
        aria-label="view mode"
        sx={{
          background: '#F8F9FA',
          borderRadius: '8px',
          gap: '4px',
          padding: '8px',
          border: 'none',
          minWidth: '240px',
          '& .MuiToggleButton-root': {
            flex: 1,
            border: 'none',
            borderRadius: '6px',
            padding: '4px 8px',
            textTransform: 'none',
            color: '#6B7280',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.01em',
            '&.Mui-selected': {
              background: '#FFFFFF',
              color: '#0088CC',
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
            },
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.8)',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '18px',
              marginRight: '8px',
              color: 'inherit',
              position: 'relative',
              top: '-1px',
            },
          },
        }}
      >
        <ToggleButton value="calendar" aria-label="calendar view">
          <CalendarMonth /> Calendar
        </ToggleButton>
        <ToggleButton value="agenda" aria-label="agenda view">
          <ViewAgenda /> Agenda
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
} 