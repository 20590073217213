import { Menu, MenuItem, SxProps } from '@mui/material';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import React, { useState } from 'react';
import BTbotbutton from './bt-bot-button';
import { getCurrentBotSelector } from '@common/reducers/ChatbotSelectors';

type Props = {
  onSelect: (item: string) => void;
  sx?: SxProps;
  buttonSize?: 'small' | 'medium' | 'large';
}

export default function SelectVariable({ onSelect, sx, buttonSize = 'small' }: Props) {
  const isReadOnly = useAppSelector(state => state.ChatbotReducer.isReadOnly);
  const currentBot = useAppSelector(state => getCurrentBotSelector(state));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const variablesList = Object.values(currentBot?.botSettings?.variables ?? {}).flatMap(value =>
    Array.isArray(value) ? value.map(v => `@${v}`) : [`@${value}`]
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (item) => {
    onSelect(item)
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BTbotbutton disabled={isReadOnly} sx={{ ...sx }} size={buttonSize} onClick={handleClick}>Variables</BTbotbutton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {variablesList.length > 0 ? (
          variablesList.map((item, index) => (
            <MenuItem key={index} onClick={() => handleSelect(item)}>{item}</MenuItem>
          ))
        ) : (
          <MenuItem disabled>No variables available</MenuItem>
        )}
      </Menu>
    </>
  )
}