import RowStack from "@common-components/common/row-stack/RowStack";
import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from 'react';
import NodeMenu from "../bot-components/node-menu";
import classes from "../bt-chatbot.module.scss";
import { nodeTypes, secondaryNodesThatLookLikeMainNodes } from "./bt-nodes-def";
import { NodeType } from "@common-models/bot-node";
import { useAppSelector } from "@common/reducers/hooks/store.hook";
import { getIsFromNodeEventSelector } from "@common/reducers/ChatbotSelectors";
import { useParams } from "react-router-dom";

type Props = {
    type: NodeType;
    id: string;
}

export default function NodeHeader({ type, id }: Props) {
    const { sessionId } = useParams<{ sessionId: string }>();
    const isFromNodeEvent = useAppSelector(state => getIsFromNodeEventSelector(state, id, sessionId));
    const isReadOnly = useAppSelector(state => state.ChatbotReducer.isReadOnly);

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const nodeType = nodeTypes?.[type]
    const NodeIcon = nodeType?.nodeIcon
    const isMainNode = nodeType?.isMainNode || secondaryNodesThatLookLikeMainNodes.includes(type);

    const title = () => {
        switch (type) {
            case 'sendMessage':
                return 'Message';
            case 'question':
                return 'Question';
            case 'condition':
                return 'Condition';
            case 'webhook':
                return 'Webhook';
            case 'googleSheets':
                return 'Google Spreadsheet';
            case 'messageContact':
                return 'Message Contact';
            case 'LLM':
                return 'LLM';
            case 'agent':
                return 'Agent';
            default:
                return type;
        }
    }

    const handleOpenNodeMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuOpen((prev => !prev))
        setAnchorEl(event.currentTarget);
    };

    const handleCloseNodeMenu = () => {
        setMenuOpen(false);
        setAnchorEl(null);
    }

    const bgColor = () => {
        if (!isFromNodeEvent) return '#939393';
        return isMainNode ? nodeType?.color : 'white';
    }

    const iconBgColor = () => {
        if (!isFromNodeEvent) return '#fff';
        return isMainNode ? nodeType?.color : 'transparent';
    }

    const iconColor = () => {
        if (!isFromNodeEvent) return '#000';
        return isMainNode ? 'white' : nodeType?.color;
    }

    const titleColor = () => {
        if (!isFromNodeEvent) return '#000';
        return isMainNode ? 'white' : 'black';
    }

    return (
        <RowStack className={isMainNode ? classes.main_node_header : classes.secondary_node_header}
            sx={{
                backgroundColor: bgColor(),
                zIndex: 2,
                color: titleColor()
            }}>
            <div style={{
                backgroundColor: iconBgColor(),
                width: '36px',
                height: '36px',
                borderRadius: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '7px',
                fontWeight: !isMainNode && 700,
            }} >
                {NodeIcon && <NodeIcon style={{ width: '25px', color: iconColor() }} />}
            </div>
            {!isMainNode && title()}
            {isMainNode && title()}
            <IconButton sx={{ p: 0, color: isMainNode ? 'white' : '#666666', marginLeft: '5px' }}
                onClick={handleOpenNodeMenu} disabled={isReadOnly}>
                <MoreVert />
            </IconButton>
            <NodeMenu type={type} open={menuOpen} onClose={handleCloseNodeMenu} anchorEl={anchorEl} id={id} />
        </RowStack>
    )
}