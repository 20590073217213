import { Box, Typography } from '@mui/material';
import { Virtuoso } from 'react-virtuoso';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { selectProfilePic } from '@common-reducers/WhatsAppSelectors';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { closeBulkDashboard } from '@common-reducers/CampaignReducer';
import CampaignMessageLogRow from '../../campaign-message-log-row';
import { ContentMessageServiceActions } from '@extension/context/content/services/contentMessageServiceActions';

const isValidWhatsAppId = (id: string | undefined): boolean => {
    if (!id) {
        console.debug('[isValidWhatsAppId] Invalid: id is undefined');
        return false;
    }

    if (typeof id !== 'string') {
        console.debug('[isValidWhatsAppId] Invalid: id is not a string', id);
        return false;
    }

    if (!id.endsWith('@c.us') && !id.endsWith('@g.us')) {
        console.debug('[isValidWhatsAppId] Invalid: id does not end with @c.us or @g.us', id);
        return false;
    }

    const numberPart = id.split('@')[0];

    if (!/^\d{10,}$/.test(numberPart)) {
        console.debug('[isValidWhatsAppId] Invalid: id number part is not numeric or less than 10 digits', numberPart);
        return false;
    }

    console.debug('[isValidWhatsAppId] Valid WhatsApp ID:', id);
    return true;
};

const getWhatsAppId = (item: any): string | undefined => {
    try {

        if (item?.contactList?.[0]?.id) {
            const id = item.contactList[0].id;
            console.debug('[getWhatsAppId] Found ID in contactList:', id);
            return id;
        }

        if (item?.message?.contactList?.[0]?.id) {
            const id = item.message.contactList[0].id;
            console.debug('[getWhatsAppId] Found ID in message.contactList:', id);
            return id;
        }

        if (item?.id?._serialized) {
            console.debug('[getWhatsAppId] Found serialized ID:', item.id._serialized);
            return item.id._serialized;
        }

        if (item?.id && typeof item.id === 'string') {
            console.debug('[getWhatsAppId] Found direct string ID:', item.id);
            return item.id;
        }

        const findWhatsAppId = (obj: any): string | undefined => {
            if (!obj || typeof obj !== 'object') return undefined;

            for (const [key, value] of Object.entries(obj)) {
                if (typeof value === 'string' &&
                    (value.endsWith('@c.us') || value.endsWith('@g.us'))) {
                    console.debug('[getWhatsAppId] Found WhatsApp ID in property:', key, value);
                    return value;
                }
                if (typeof value === 'object') {
                    const nestedId = findWhatsAppId(value);
                    if (nestedId) return nestedId;
                }
            }
            return undefined;
        };

        const foundId = findWhatsAppId(item);
        if (foundId) {
            console.debug('[getWhatsAppId] Found WhatsApp ID through deep search:', foundId);
            return foundId;
        }

        console.debug('[getWhatsAppId] No valid WhatsApp ID found in item');
        return undefined;
    } catch (error) {
        console.error('[getWhatsAppId] Error getting WhatsApp ID:', error);
        return undefined;
    }
};

interface CampaignMessageListContainerProps {
    isDataFromWaiting?: boolean;
    logsData?: any[];
}

export default function CampaignMessageListContainer({
    isDataFromWaiting,
    logsData: data,
}: CampaignMessageListContainerProps) {
    const dispatch = useAppDispatch();

    const profilePicUrls = useAppSelector((state) => {
        return data?.reduce((acc, item) => {
            const whatsappId = getWhatsAppId(item);
            if (isValidWhatsAppId(whatsappId)) {
                acc[whatsappId] = selectProfilePic(state, whatsappId);
            }
            return acc;
        }, {});
    });


    if (!data?.length) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography color="text.secondary">
                    No messages found
                </Typography>
            </Box>
        );
    }

    const getContactName = (log: any) => {
        if (isDataFromWaiting && log.status === "Waiting In Redis") {
            return log.contactName;
        }
        else {
            return log.contactList[0].firstName
        }
}


const prepareRowData = (log: any) => {

    const whatsappId = getWhatsAppId(log);
    const isGroup = whatsappId?.includes('@g.us');
    const contact = log?.message?.contactList?.[0] || log?.contactList?.[0];
    const contactName = getContactName(log);
    const status = !isDataFromWaiting ? 'Stuck' : log.status;
    const getContactPhone = (contact: any) => {
        if (contact?.id?.includes('@c.us')) {
            const phone = contact?.id?.replace('@c.us', '');
            return phone ? ` +${phone}` : '';
        }
        return '';
    };

    const focusWAContact = () => {
        if (isValidWhatsAppId(whatsappId)) {
            ContentMessageServiceActions.focusContact(whatsappId);
            dispatch(closeBulkDashboard());
        }
    };

    return {
        whatsappId,
        status,
        isGroup,
        contact,
        contactName,
        getContactPhone,
        focusWAContact,
        profilePicUrl: profilePicUrls?.[whatsappId]
    };
};

return (
    <Box>
        <Virtuoso
            style={{ height: '450px' }}
            data={data}
            itemContent={(index, item) => {
                const rowData = prepareRowData(item);
                return (
                    <CampaignMessageLogRow
                        index={index}
                        profilePicUrl={rowData.profilePicUrl}
                        isGroup={rowData.isGroup}
                        contactName={rowData.contactName}
                        contactPhone={rowData.getContactPhone(rowData.contact)}
                        messageLog={item}
                        onClick={rowData.focusWAContact}
                        status={rowData.status}
                    />
                );
            }}
        />
    </Box>
);
} 