import BotCheckbox from '@common-components/bt-chatbot/bot-components/bot-checkbox';
import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field';
import BotMessageEditor from '@common-components/bt-chatbot/bot-components/bot-message-editor';
import BTbotbutton from '@common-components/bt-chatbot/bot-components/bt-bot-button';
import RowStack from '@common-components/common/row-stack/RowStack';
import { setCurrentBot } from '@common/reducers/ChatbotReducer';
import { getCurrentBotSelector, getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { Asset } from '@common/types/interface';
import { memo, useCallback, useMemo, useState } from 'react';
import classes from './llm-dialog.module.scss';

interface Props {
    onClose: () => void;
    id?: string;
}

function AddLLMDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch();

    // Get node data if editing
    const data = useAppSelector(state => getNodeDataByNodeId(state, id));
    const currentBot = useAppSelector(state => getCurrentBotSelector(state));

    // Local state for the form
    const [localNodeData, setLocalNodeData] = useState({
        ...data,
        text: data?.text || '',
        asset: data?.asset || null,
        includeSessionHistory: data?.includeSessionHistory || false,
        sendResponseToUser: data?.sendResponseToUser ?? false // Default to true for backward compatibility
    });
    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot);

    // Handle message content change
    const onChange = useCallback((value: string | { message: string; asset: Asset }) => {
        setLocalNodeData(prev => ({
            ...prev,
            text: typeof value === 'object' ? value.message : value,
            asset: typeof value === 'object' ? value.asset : undefined
        }));
    }, []);

    // Handle session history checkbox change
    const toggleIncludeSessionHistory = useCallback(() => {
        setLocalNodeData(prev => ({
            ...prev,
            includeSessionHistory: !prev.includeSessionHistory
        }));
    }, []);

    // Handle send response checkbox change
    const toggleSendResponseToUser = useCallback(() => {
        setLocalNodeData(prev => ({
            ...prev,
            sendResponseToUser: !prev.sendResponseToUser
        }));
    }, []);

    // Handle variable name input change
    const onChangeVariableName = useCallback((value: string) => {
        setLocalCurrentBot(prev => ({
            ...prev,
            botSettings: {
                ...prev.botSettings,
                variables: {
                    ...prev.botSettings?.variables,
                    [id]: value
                }
            }
        }));
    }, [id]);

    // Save LLM data
    const saveLLMData = useCallback(async () => {
        if (id) {
            await dispatch(changeNodeDataThunk({
                id,
                dataToPatch: localNodeData
            }));
            await dispatch(setCurrentBot(localCurrentBot));
        }
        onClose();
    }, [dispatch, id, localNodeData, localCurrentBot, onClose]);

    // Message editor component
    const messageEditorDialogMemo = useMemo(() => (
        <>
            <div className={classes.llm_dialog_title}>LLM Prompt</div>
            <BotMessageEditor
                _message={{
                    message: localNodeData.text || '',
                    asset: localNodeData.asset
                }}
                onChange={onChange}
                type='LLM'
            />
        </>
    ), [localNodeData.text, localNodeData.asset, onChange]);

    return (
        <div className={classes.llm_dialog_wrapper}>
            <div className={classes.llm_dialog_content}>
                {messageEditorDialogMemo}

                <div className={classes.divider} />

                <div className={classes.settings_section}>
                    <BotCheckbox 
                        id="includeSessionHistory"
                        checked={localNodeData.includeSessionHistory}
                        onChange={toggleIncludeSessionHistory}
                        title="Include conversation history"
                        description="Include previous messages as context for the LLM"
                    />
                    
                    <BotCheckbox 
                        id="sendResponseToUser"
                        checked={localNodeData.sendResponseToUser}
                        onChange={toggleSendResponseToUser}
                        title="Send response to user"
                        description="Automatically send the LLM's response back to the contact"
                    />

                    <div className={classes.variable_section}>
                        <div className={classes.variable_label}>Save LLM response in variable:</div>
                        <BotInputField
                            value={localCurrentBot?.botSettings?.variables?.[id] ?? ''}
                            onChange={(newValue) => onChangeVariableName(newValue)}
                            placeholder="Variable name (e.g. llm_response)"
                        />
                        <div className={classes.variable_hint}>
                            You can access this variable later using @{localCurrentBot?.botSettings?.variables?.[id] || 'variable_name'}
                        </div>
                    </div>
                </div>

                <div className={classes.button_container}>
                    <RowStack sx={{ justifyContent: 'flex-end' }}>
                        <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                        <BTbotbutton onClick={saveLLMData} sx={{ marginLeft: "13px" }}>Save</BTbotbutton>
                    </RowStack>
                </div>
            </div>
        </div>
    );
}

export default memo(AddLLMDialog);