import BotInputField from '@common-components/bt-chatbot/bot-components/bot-input-field';
import BotMessageEditor from '@common-components/bt-chatbot/bot-components/bot-message-editor';
import BTbotbutton from '@common-components/bt-chatbot/bot-components/bt-bot-button';
import RowStack from '@common-components/common/row-stack/RowStack';
import { RemoveCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { setCurrentBot } from '@common/reducers/ChatbotReducer';
import { getCurrentBotSelector, getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk, removeHandleAndConnectedEdgesByHandleIdArrayThunk } from '@common-reducers/ChatbotThunks';
import { getChatbotTempId } from '@common-services/utils';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { memo, useState, useCallback, useMemo } from 'react';
import classes from "./question-node.module.scss";
import { Asset } from '@common/types/interface';
import BotCheckbox from '@common-components/bt-chatbot/bot-components/bot-checkbox';


interface Props {
    onClose: () => void;
    id?: string;
}

function AddQuestionDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch();

    const data = useAppSelector(state => getNodeDataByNodeId(state, id));
    const currentBot = useAppSelector(state => getCurrentBotSelector(state));

    const [newAnswer, setNewAnswer] = useState('');
    const [localNodeData, setLocalNodeData] = useState({
        ...data,
        isFreeTextAnswer: data?.isFreeTextAnswer ?? false,
    });
    const [localEntitiesArrayToDelete, setLocalEntitiesArrayToDelete] = useState<string[]>([]);
    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot);

    const onChange = useCallback((value: string | { message: string; asset: Asset }, type: string) => {
        if (type === 'question') {
            setLocalNodeData(prev => ({
                ...prev,
                question: typeof value === 'object' ? value.message : value,
                questionAsset: typeof value === 'object' ? value.asset : undefined
            }));
        } else {
            setLocalNodeData(prev => ({
                ...prev,
                answers: {
                    ...prev.answers,
                    [type]: typeof value === 'object' ? value.message : value
                }
            }));
        }
    }, []);

    const handleClickRemoveAnswer = useCallback((handleId: string) => {
        setLocalEntitiesArrayToDelete(prev => [...prev, handleId]);
        setLocalNodeData(prev => {
            const newOutputs = prev.handles.outputs.filter(handle => handle.id !== handleId);
            const { [handleId]: _, ...newAnswers } = prev.answers;
            return {
                ...prev,
                handles: {
                    ...prev.handles,
                    outputs: newOutputs
                },
                answers: newAnswers
            };
        });
    }, []);

    const handleClickAddNewAnswer = useCallback(() => {
        const newHandleId = getChatbotTempId();
        setLocalNodeData(prev => ({
            ...prev,
            handles: {
                ...prev.handles,
                outputs: [
                    ...(prev.handles.outputs || []),
                    { id: newHandleId, type: 'source' }
                ]
            },
            answers: {
                ...(prev.answers || {}),
                [newHandleId]: newAnswer
            }
        }));
        setNewAnswer('');
    }, [newAnswer]);

    const onChangeVariableName = useCallback((value: string) => {
        setLocalCurrentBot(prev => ({
            ...prev,
            botSettings: {
                ...prev.botSettings,
                variables: {
                    ...prev.botSettings?.variables,
                    [id]: value
                }
            }
        }));
    }, [id]);

    const toggleFreeTextAnswer = useCallback(() => {
        setLocalNodeData(prev => {
            const newIsFreeTextAnswer = !prev.isFreeTextAnswer;
            
            if (newIsFreeTextAnswer) {
                // Create a new handle ID using the same method as for regular answers
                const newHandleId = getChatbotTempId();
                
                // When enabling free text, create a new handle for it
                return {
                    ...prev,
                    isFreeTextAnswer: true,
                    // Create a placeholder answer with the new handle ID
                    answers: {
                        [newHandleId]: "Free text response" // Placeholder for display purposes
                    },
                    // Add the new handle to outputs
                    handles: {
                        ...prev.handles,
                        outputs: [
                            { id: newHandleId, type: 'source' }
                        ]
                    }
                };
            } else {
                // When disabling free text, clear answers and handles
                return {
                    ...prev,
                    isFreeTextAnswer: false,
                    answers: {},
                    handles: {
                        ...prev.handles,
                        outputs: []
                    }
                };
            }
        });
    }, []);

    const saveNewQuestionData = useCallback(async () => {
        await dispatch(changeNodeDataThunk({
            id,
            dataToPatch: localNodeData
        }));

        await dispatch(removeHandleAndConnectedEdgesByHandleIdArrayThunk(localEntitiesArrayToDelete));
        await dispatch(setCurrentBot(localCurrentBot));
        onClose();
    }, [dispatch, id, localNodeData, localEntitiesArrayToDelete, localCurrentBot, onClose]);

    const messageEditorDialogMemo = useMemo(() => (
        <>
            <div className={classes.add_question_title}>Question message text</div>
            <BotMessageEditor
                _message={{
                    message: localNodeData?.question ?? '',
                    asset: localNodeData?.questionAsset
                }}
                onChange={(value) => onChange(value, 'question')} />
        </>
    ), [localNodeData?.question, localNodeData?.questionAsset, onChange]);

    const answers = () => {
        const handleIds = Object.keys(localNodeData?.answers ?? {});
        if (handleIds?.length > 0) {
            return (
                <>
                    <div className={classes.add_question_title}>Answer options</div>
                    {handleIds.map((handleId, i) => {
                        const answer = localNodeData?.answers[handleId];
                        return (
                            <RowStack key={i}>
                                <BotInputField
                                    value={answer}
                                    onChange={(newValue) => onChange(newValue, handleId)}
                                    placeholder={'Type a new answer'}
                                    sx={{ marginBottom: '8px', }}
                                    endAdornment={
                                        <IconButton
                                            sx={{
                                                zIndex: 2,
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '8px',
                                                backgroundColor: '#FEE7E7',
                                                color: 'red',
                                                ".MuiSvgIcon-root": {
                                                    width: '20px',
                                                    height: '20px',
                                                }
                                            }}
                                            onClick={() => handleClickRemoveAnswer(handleId)}
                                        >
                                            <RemoveCircle />
                                        </IconButton>
                                    }
                                />
                            </RowStack>
                        );
                    })}
                </>
            );
        }
        return null;
    };

    const addAnswer = () => {
        return (
            <>
                <div className={classes.add_question_title}>Add an answer</div>
                <RowStack>
                    <BotInputField
                        value={newAnswer}
                        onChange={(newValue) => setNewAnswer(newValue)}
                        placeholder={'Type a new answer'}
                        endAdornment={
                            <BTbotbutton
                                sx={{ zIndex: 2, }}
                                size='small'
                                disabled={newAnswer === ''}
                                onClick={handleClickAddNewAnswer}
                            >
                                Create
                            </BTbotbutton>
                        }
                    />
                </RowStack>
            </>
        );
    };

    const addVariable = () => {
        return (
            <Stack>
                <div className={classes.add_question_title}>Save answers in a variable</div>
                <BotInputField
                    value={localCurrentBot?.botSettings?.variables?.[id] ?? ''}
                    onChange={(newValue) => onChangeVariableName(newValue)}
                    placeholder={'Type a new variable'} />
            </Stack>
        );
    };

    return (
        <div className={classes.add_question_message_editor_wrapper}>
            {messageEditorDialogMemo}
            <div className={classes.divider} />
            <BotCheckbox 
                id="free-text-toggle"
                checked={localNodeData.isFreeTextAnswer || false}
                onChange={toggleFreeTextAnswer}
                title="Free text answer"
                description="Allow end users to provide free-form text answers"
            />
            {!localNodeData.isFreeTextAnswer && answers()}
            {!localNodeData.isFreeTextAnswer && addAnswer()}
            <div className={classes.divider} />
            {addVariable()}
            <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px', }}>
                <BTbotbutton styleType='empty' onClick={onClose} sx={{ marginLeft: "13px" }}>Cancel</BTbotbutton>
                <BTbotbutton onClick={saveNewQuestionData} sx={{ marginLeft: "13px" }}>Save</BTbotbutton>
            </RowStack>
        </div>
    );
}

export default memo(AddQuestionDialog, (prevProps, nextProps) => {
    return prevProps.id === nextProps.id && prevProps.onClose === nextProps.onClose;
});