import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { ConnectedSocket, selectConnectedSockets } from '@common/reducers/ConnectedEnginesSlice';
import React from 'react';

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#1e1e1e',
    color: '#fff',
    borderRadius: '8px',
    margin: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: 'monospace',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse' as const,
    marginTop: '10px',
  },
  th: {
    textAlign: 'left' as const,
    padding: '12px',
    borderBottom: '1px solid #333',
    color: '#00ff00',
  },
  td: {
    padding: '12px',
    borderBottom: '1px solid #333',
  },
  title: {
    color: '#00ff00',
    marginBottom: '15px',
    fontSize: '1.2em',
  },
  noData: {
    textAlign: 'center' as const,
    padding: '20px',
    color: '#666',
  },
};

// Add utility function to check for DEBUG parameter
const shouldShowDebugTable = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('debug')?.toLocaleLowerCase() === 'true';
};

const SocketDebugTable: React.FC = () => {
  const connectedSockets = useAppSelector(selectConnectedSockets);

  // Only show in localhost
  if (!shouldShowDebugTable()) {
    return null;
  }

  return (
    <div style={styles.container}>
      <div style={styles.title}>🔌 Socket Debug Information</div>
      {connectedSockets.length > 0 ? (
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Socket ID</th>
              <th style={styles.th}>Connected From</th>
              <th style={styles.th}>User ID</th>
              <th style={styles.th}>Device</th>
              <th style={styles.th}>Browser</th>
              <th style={styles.th}>Connection Time</th>
            </tr>
          </thead>
          <tbody>
            {connectedSockets.map((socket: ConnectedSocket) => (
              <tr key={socket.id}>
                <td style={styles.td}>{socket.id}</td>
                <td style={styles.td}>{socket.connectedFrom}</td>
                <td style={styles.td}>{socket.user?._id || 'N/A'}</td>
                <td style={styles.td}>{socket.device}</td>
                <td style={styles.td}>{socket.browserType}</td>
                <td style={styles.td}>{socket.connectionTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div style={styles.noData}>No active sockets connected</div>
      )}
    </div>
  );
};

export default SocketDebugTable; 