import { getHandleId, getSourceHandleStyle, getTargetHandleStyle } from '@common-services/utils';
import { Handle, Position, NodeProps } from 'reactflow';
import { nodeTypes } from '../bt-nodes-def';
import NodeContainer from '../node-container';
import classes from "./send-message-node.module.scss";
import CustomSourceHandle from '@common-components/bt-chatbot/custom-source-handle';
import { CustomNodeData } from '../bt-nodes-def';

export default function SendMessageNode({ id, data }: NodeProps<CustomNodeData>) {
    const type = data?.type;
    const nodeColor = nodeTypes?.[type]?.color;

    const sourceHandleClass = () => {
        if (type === 'sendMessage') {
            return classes.send_message_source_handle;
        } else if (type === 'messageContact') {
            return classes.message_contact_source_handle;
        } else if (type === 'LLM') {
            return classes.llm_source_handle;
        }
    }


    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                id={getHandleId(data.handles, 'target', 0)}
                style={getTargetHandleStyle(nodeColor)}
                className={classes.target_handle} // Use class for !important rules
            />
            <NodeContainer type={type} id={id} />
            <CustomSourceHandle
                type="source"
                position={Position.Right}
                id={getHandleId(data.handles, 'source', 0)}
                className={sourceHandleClass()}
                style={getSourceHandleStyle(nodeColor)}
                connectioncount={1}
            />
        </>
    );
}