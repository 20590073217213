import { SearchBox } from '@common-components/tasks/person-picker/search-box';
import { updateSearchValue } from "@common-reducers/ContactTableReducer";
import { setAddMsgInCalendarContact } from "@common-reducers/WhatsAppReducer";
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { isExtensionContext } from "@common-services/detect-context";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
    IconButton
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useRef, useState } from "react";
import { getChatsAndContactsThunk } from "../../thunks/WhatsappThunks";
import { MessageContact, WhatsAppContact } from "./add-scheduled-message";
import WaContactLazyLoadingVirtuoso from "./wa-contact-lazy-loading-virtuoso";


interface WhatsAppId {
    _serialized: string;
    id?: string;
}

interface ExtendedContact {
    id: string | WhatsAppId;
    waId?: string;
    lid?: string;
    name?: string;
    waName?: string;
    notify?: string;
    displayName?: string;
    imgUrl?: string;
}



export const ContactSelect: React.FC = () => {
    const dispatch = useAppDispatch();
    const [searchFilter, setSearchFilter] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const allowToAdd = /^[0-9@+]+$/.test(searchFilter);
    const anchorRef = useRef(null);


    // Utility functions
    const getContactId = (contact: ExtendedContact): string => {
        if (typeof contact.id === 'string') return contact.id;
        return contact.id._serialized || '';
    };

    const getContactName = (contact: ExtendedContact): string => {
        return contact.name || contact.waName || contact.notify || contact.displayName || '';
    };

    const createMessageContact = (contact: ExtendedContact): MessageContact => {
        return {
            id: getContactId(contact),
            name: getContactName(contact),
            displayName: contact.displayName || getContactName(contact),
            imgUrl: contact.imgUrl
        };
    };

    const handleContactSave = (contact: ExtendedContact) => {
        dispatch(setAddMsgInCalendarContact(createMessageContact(contact)));
        setSearchFilter('');
    };

    const handleContactSelect = (contact: WhatsAppContact) => {
        dispatch(setAddMsgInCalendarContact({
            name: contact.name,
            id: contact.id._serialized,
            displayName: contact.pushname,
            imgUrl: contact.imgUrl,
        }));
    };

    const saveFreeInput = () => {
        let whatsappId = searchFilter.replace(/\+/g, '');
        if (!whatsappId.includes('@')) {
            whatsappId = `${whatsappId}@c.us`;
        }
        handleContactSave({ id: whatsappId, name: whatsappId });
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!value?.includes('+')) {
            if (isExtensionContext()) {
                dispatch(updateSearchValue(value));
            }
            setSearchFilter(value);
        }
    };

    useEffect(() => {
        try {
            dispatch(getChatsAndContactsThunk({ shouldForceUpdate: false }))
        } catch (e) {
            console.error("[getChatsAndContactsThunk] error", e)
        }
    }, []);

    return (
        <>
            <Stack direction="row" alignItems={'center'} justifyContent={'start'} ref={anchorRef}>
                <SearchBox
                    variant="outlined"
                    autoComplete='off'
                    value={searchFilter}
                    onChange={handleSearchChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={(e) => {
                        // Only blur if clicking outside both the input and the contact list
                        if (!e.relatedTarget?.closest('.contact-list-container')) {
                            setIsFocused(false);
                        }
                    }}
                    placeholder="Enter name or number"
                    InputProps={{
                        endAdornment: allowToAdd ? (
                            <IconButton onClick={saveFreeInput}>
                                <AddIcon sx={{ fontSize: 16 }} />
                            </IconButton>
                        ) : <SearchIcon sx={{ fontSize: 16 }} />,
                    }}
                    fullWidth
                />
            </Stack>
            {isFocused && (
                <div 
                    className="contact-list-container"
                    style={{
                        position: 'absolute',
                        zIndex: 1000,
                        backgroundColor: 'var(--default-background)',
                    }}
                    tabIndex={-1} // Make div focusable
                >
                    <WaContactLazyLoadingVirtuoso
                        height={350}
                        searchFilter={searchFilter}
                        onClick={(contact) => {
                            handleContactSelect(contact);
                            setIsFocused(false);
                        }}
                    />
                </div>
            )}
        </>
    );
};