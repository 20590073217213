import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonRootState } from '@common/types/common-root-state-type';

export interface ConnectedSocket {
  id: string;
  user?: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  device: string;
  browserType: string;
  connectionTime: string;
  connectedFrom: string;
  domain: string;
}

export interface ConnectedEnginesState {
  sockets: ConnectedSocket[];
}

const initialState: ConnectedEnginesState = {
  sockets: []
};

export const connectedEnginesSlice = createSlice({
  name: 'connectedEngines',
  initialState,
  reducers: {
    updateConnectedSockets: (state, action: PayloadAction<ConnectedSocket[]>) => {
      state.sockets = action.payload;
    }
  }
});

// Selectors
export const selectConnectedSockets = (state: CommonRootState) => 
  state.ConnectedEnginesReducer?.sockets || [];

export const { updateConnectedSockets } = connectedEnginesSlice.actions;

export default connectedEnginesSlice.reducer; 