import { configManager } from '../config/wcl.config';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// Function to get access token from client storage
async function getAccessToken(): Promise<string> {
  return localStorage.getItem('bt-token') || "";
}

// Function to get cron token from server configuration
function getCronToken(): string {
  const waCommunicationLayerConfig = configManager.getConfig()
  return waCommunicationLayerConfig.cronToken;
}

// Generic function for making API calls
export async function apiCall<T>(
  method: 'get' | 'post' | 'put' | 'delete',
  endpoint: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> {
  const waCommunicationLayerConfig = configManager.getConfig()
  
  try {
    let token = "";
    const axiosConfig: AxiosRequestConfig = {
      method,
      url: `${waCommunicationLayerConfig.API_ENDPOINT}${endpoint}`,
      headers: {
        'Content-Type': 'application/json',
        ...(config?.headers || {}),
      },
      ...config,
    };

    // Conditionally set the token and headers based on environment
    if (waCommunicationLayerConfig.WCL_ENV === 'server') {
      token = getCronToken();
      // Add cronToken to data payload
      axiosConfig.data = data ? { ...data, cronToken: token } : { cronToken: token };
    } else if (waCommunicationLayerConfig.WCL_ENV === 'client') {
      token = await getAccessToken();
      // Add Authorization header
      axiosConfig.headers['Authorization'] = `Bearer ${token}`;
      axiosConfig.data = data || {};
    }

    console.log(`API_ENDPOINT:`);
    console.log(`${waCommunicationLayerConfig.API_ENDPOINT}${endpoint}`);

    const response: AxiosResponse<T> = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    console.error(`Error in API call to ${endpoint}:`, error);
    throw error;
  }
}

export async function generateUserToken(ownerId: string): Promise<string> {
  try {
    const response = await apiCall<{ success: boolean; accessToken?: string; error?: string }>(
      'post',
      '/actions',
      {
        cmd: 'generate-user-token',
        userId: ownerId,
        cronToken: getCronToken()
      }
    );

    if (response.success && response.accessToken) {
      return response.accessToken;
    }
    throw new Error(response.error || 'No token received from server');
  } catch (error) {
    console.error('Error generating user token:', error);
    throw error;
  }
}
