import BTButton from "@common-components/bt-button/bt-button";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { isCurrentUserMemberOrOwner } from "@common-reducers/BoardsSelectors";
import { MODAL, modalActions } from "@common-reducers/ModalReducer";
import { useState } from "react";
import { FaPlus, FaRegFolderOpen } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { namespace } from "@common-config/constants";
import classes from "./boards-panel.module.scss";

interface NewBoardButton2Props {
    drawerIsOpen: boolean;
}

function NewBoardButton2({ drawerIsOpen }: NewBoardButton2Props) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const [isNewBoardLoading, setIsNewBoardLoading] = useState(false);
    const isMemberOrOwner = useAppSelector(isCurrentUserMemberOrOwner);

    async function addNewBoard() {
        if (isMemberOrOwner) {
            dispatch(
                modalActions.setComponentToRender(MODAL.NEW_BOARD)
            );
        } else {
            dispatch(
                modalActions.setComponentToRender(MODAL.FEATURE_NOT_AVAILABLE)
            );
        }
    }

    return (
        <div data-testid="new-board-button" style={{ padding: "0 22px" }}>
            <BTButton
                id="new-board"
                loading={isNewBoardLoading}
                onClick={addNewBoard}
                sx={{
                    startIcon: { margin: 0 },
                    span: { margin: "0" },
                    img: { margin: drawerIsOpen ? "4px" : "0" },
                    padding: drawerIsOpen ? "7.5px 16px" : "8px",
                    minWidth: "100%",
                    width: "100%",
                    borderRadius: "8px",
                }}
            >
                <FaPlus style={{ marginInlineEnd: drawerIsOpen ? '5px' : 0, height: '14px', width: '14px' }} />
                {drawerIsOpen ? t('boardsPanel.newBoardButton') : ""}
            </BTButton>
            <button style={{ display: "none" }} className={classes.directoryBtn}>
                <FaRegFolderOpen />
            </button>
        </div>
    );
}

export default NewBoardButton2; 