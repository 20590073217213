import { MessageServiceActions } from "@common/services/messageServiceActions";
import { postMessageWithResponse, postMessageToBackgroundWithResponse } from "@common/services/post-message-to-whatsapp-functions";
import { WaMessage } from "@extension/wa-communication-layer/wa-interfaces";
import { Picture } from "@wa-communication-layer/whatsapp";

interface ClaudeResponse {
  content: string;
  thinking: string | null;
  model: string;
  role: string;
  stream?: AsyncIterable<{
    content: string;
    thinking: string | null;
    model: string;
  }>;
}

export class ContentMessageServiceActions extends MessageServiceActions {
  // Base messaging method
  public static postMessageWithResponse<T>(cmd: string, data: any): Promise<T | undefined> {
    return super.postMessageWithResponse(cmd, data, "contentMessageService");
  }

  // ==========================================
  // Chat & Contact Management
  // ==========================================
  
  public static async getChatsAndContacts(): Promise<void> {
    return postMessageWithResponse({ cmd: "getChatsAndContacts" });
  }

  public static async getContacts(options?: { limit?: number; offset?: number }): Promise<any> {
    return postMessageWithResponse({ cmd: "get_contacts", data: options });
  }

  public static async getChats(options?: { limit?: number; offset?: number }): Promise<any> {
    return postMessageWithResponse({ cmd: "get_chats", data: options });
  }

  public static async getCurrentChat(options?: { clearInput?: boolean }): Promise<void> {
    return postMessageWithResponse({
      cmd: "getCurrentChat",
      data: options?.clearInput,
    });
  }

  public static async focusContact(contactId: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "focusContact",
      data: contactId,
    });
  }

  public static async openChatByPhone(phone: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "openChatByPhone",
      data: phone.replace('+', ''),
    });
  }

  public static async updateShouldAppearChatsByList(chatsThatShouldAppearInList: any): Promise<void> {
    return postMessageWithResponse({
      cmd: "update-should-appear-chats-by-list",
      data: chatsThatShouldAppearInList,
    });
  }

  public static async updateChatModels(contactLabels: any[]): Promise<void> {
    return postMessageWithResponse({
      cmd: "update-chat-models",
      contactLabels,
    });
  }

  public static async injectContactLabelActionsToAllMissingChats(): Promise<void> {
    return postMessageWithResponse({
      cmd: "injectContactLabeActionsToAllMissingChats",
    });
  }

  // ==========================================
  // Message Operations
  // ==========================================

  public static async getMessages(options?: { 
    chatId?: string; 
    limit?: number; 
    offset?: number;
    cutoffDate?: string;
    latestDate?: string;
  }): Promise<any> {
    return postMessageWithResponse({ cmd: "get_messages", data: options });
  }

  public static async sendMessageViaWhatsapp(preparedWaMessage: WaMessage, userMessageId: string): Promise<void> {
    const data = { waMessage: preparedWaMessage, userMessageId }
    return postMessageWithResponse({ cmd: "sendMessageViaWhatsapp", data });
  }

  public static async getMessageLogWAMessageAck(messageKeys: any): Promise<any[]> {
    return postMessageWithResponse({
      cmd: "getMessageLogWAMessageAck",
      data: {
        messageKeys,
      }
    });
  }

  public static async getWAMessageAckData(messageKeys: any): Promise<any[]> {
    return postMessageWithResponse({
      cmd: "getWAMessageAckData",
      data: {
        messageKeys,
      }
    });
  }

  public static async getWaMessageById(messageId: string): Promise<any> {
    return postMessageWithResponse({ cmd: "getWaMessageById", data: messageId });
  }

  public static async getUnloggedWaMessageKeyIfExistInStore(
    chatId: string,
    userMessageId: string,
    dueDate: Date
  ): Promise<string | null> {
    const data = { chatId, userMessageId, dueDate }
    return postMessageWithResponse({ cmd: "getUnloggedWaMessageKeyIfExistInStore", data });
  }

  public static async getWaMessagesAckByWaMessagesKeys(waMessageKeys?: any[]): Promise<any> {
    return postMessageWithResponse({ cmd: "get_wa_messages_ack_by_wa_messages_keys", data: waMessageKeys });
  }

  public static async getWaMessagesAckDataByWaMessagesKeys(waMessageKeys?: any[]): Promise<any> {
    return postMessageWithResponse({ cmd: "get_wa_messages_ack_data_by_wa_messages_keys", data: waMessageKeys });
  }

  public static async searchMessages(params: any): Promise<any> {
    console.log('[ContentMessageServiceActions] Searching messages with params:', params);
    return postMessageWithResponse({ cmd: "search_messages", data: params });
  }

  public static async checkIfReplyingToMessage(): Promise<void> {
    const replyData: any = await postMessageWithResponse({ cmd: "checkIfReplyingToMessage" });
    return replyData;
  }

  // ==========================================
  // Profile & User Info
  // ==========================================

  public static async getMyWaProfile(): Promise<void> {
    return postMessageWithResponse({ cmd: "getMyWaProfile" });
  }

  public static async getUserInfo(): Promise<void> {
    return postMessageWithResponse({ cmd: "getUserInfo" });
  }

  public static async getWaMeInfo(): Promise<any> {
    return postMessageWithResponse({ cmd: "getWaMeInfo" });
  }

  public static async setNewStatus(localNewStatus: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "setNewStatus",
      data: localNewStatus,
    });
  }

  // ==========================================
  // Media & Content
  // ==========================================

  public static async getMedia(): Promise<{
    cmd: string,
    mediaBlobList: any[],
    caption: string,
  }> {
    return postMessageWithResponse({ cmd: "getMedia" });
  }

  public static async getWAContactImageRequest(): Promise<void> {
    return postMessageWithResponse({ cmd: "getWAContactImageRequest" });
  }

  public static async getProfilePicThumb(id: string): Promise<Picture> {
    return postMessageWithResponse({
      cmd: "getProfilePicThumb",
      data: id,
    });
  }

  public static async getProfilePicUrl(contactId: string[]): Promise<string> {
    return postMessageWithResponse({ cmd: "getProfilePicUrl", data: contactId });
  }

  public static async getProfilePic(contactIds: string | string[]): Promise<Array<{ contactId: string; profilePic: string | undefined }>> {
    console.log('[ContentMessageServiceActions] Getting profile pictures for contacts:', contactIds);
    return postMessageWithResponse({ 
      cmd: "get_profile_pic", 
      data: { contactIds: Array.isArray(contactIds) ? contactIds : [contactIds] } 
    });
  }

  public static async getPollData(): Promise<void> {
    return postMessageWithResponse({ cmd: "getPollData" });
  }

  public static async getWaLinkPreview(link: string): Promise<any> {
    return postMessageWithResponse({ cmd: "getWaLinkPreview", link });
  }

  // ==========================================
  // WhatsApp UI Actions
  // ==========================================

  public static async clearChatInput(currentChatId?: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "clearChatInput",
      data: currentChatId,
    });
  }

  public static async rebuildContactNodes(): Promise<void> {
    // return postMessageWithResponse({ cmd: "rebuild-contact-nodes" });
    console.warn("rebuildContactNodes is not implemented");
  }

  public static async reloadPage(): Promise<any> {
    return postMessageWithResponse({ cmd: "reloadPage" });
  }

  // ==========================================
  // External Services
  // ==========================================

  public static async askClaude(query: string): Promise<ClaudeResponse> {
    return postMessageWithResponse({ cmd: "askClaude", data: { query } });
  }

  public static async fetchUrlContent(url: string): Promise<string> {
    return postMessageToBackgroundWithResponse({ cmd: "fetchUrlContent", data: url });
  }

  public static async fetchUrlContentAsBlob(url: string): Promise<string> {
    return postMessageToBackgroundWithResponse({ cmd: "fetchUrlContentAsBlob", data: url });
  }

  public static async openTab(boardURL: string): Promise<void> {
    return postMessageWithResponse({ cmd: "openTab", data: boardURL });
  }
}
