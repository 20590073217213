import NoResults from '@common-assets/lottie/no-results.json';
import { useAppSelector, useAppThunkDispatch } from "@common-reducers/hooks/store.hook";
import { getAllEntitiesIdsAndTypeForCalendarSidebar } from "@common-reducers/UserMessageReducer";
import { DBCampaignThunks } from '@common/reducers/DBServiceThunks';
import { isExtensionContext } from '@common/services/detect-context';
import { Stack } from "@mui/material";
import Lottie from "lottie-react";
import { useEffect } from 'react';
import CalendarSidebarCampaignRow from './calendar-sidebar-campaign-row';
import CalendarSidebarMessageRow from './calendar-sidebar-message-row';
import classes from "./scheduled-contact-list.module.scss";
import CalendarSidebarFilters from './calendar-sidebar-filters';



export default function ExtensionSidebarContactList() {
    const dispatch = useAppThunkDispatch();

    const combinedGroupedMessagesAndCampaignsArray = useAppSelector(state => getAllEntitiesIdsAndTypeForCalendarSidebar(state));
    const filters = useAppSelector(state => state.CalendarReducer.calendarSidebarFilters)


    useEffect(() => {
        dispatch(DBCampaignThunks.find({
            campaignStatus: { $in: ["pending", "running"] },
            $limit: -1,
        }))
    }, [])

    return (
        <div className={classes.contactListContainer}
            style={{
                height: isExtensionContext() && 'calc(100% - 49px)',
                flex: !isExtensionContext() && 1,
            }}>
            {combinedGroupedMessagesAndCampaignsArray?.length === 0 ? (
                <Stack alignItems={"center"} style={{ padding: '24px' }}>
                    <Lottie autoPlay={true} loop={true} animationData={NoResults} style={{ width: '250px', height: '250px' }} />
                    <br />
                    We couldn't find any scheduled messages.
                </Stack>
            ) : (
                <>
                    <CalendarSidebarFilters />
                    {combinedGroupedMessagesAndCampaignsArray.map((message, index) => {
                        if (message.type === 'message' && filters.showMessages) {
                            return (
                                <CalendarSidebarMessageRow index={index} messageId={message._id} />
                            )
                        } else if (message.type === 'campaign' && filters.showCampaigns) {
                            return (
                                <CalendarSidebarCampaignRow index={index} campaignId={message._id} />
                            )
                        } else {
                            return <></>
                        }
                    })}
                </>
            )
            }
        </div>
    )
}