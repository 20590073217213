import BTButton from '@common-components/bt-button/bt-button';
import { ChevronRightOutlined } from '@mui/icons-material';
import { Box, SxProps, Theme } from '@mui/system';
import { nextTab } from '@common-reducers/CampaignReducer';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';

type Props = {
    isDisabled: boolean;
    sx?: SxProps<Theme>
}

export default function NextTabButton
    ({
        isDisabled,
        sx,
    }: Props) {
    const dispatch = useAppDispatch()
    const handleClickNextTabButton = () => {
        dispatch(nextTab());
    }

    return (
        <Box sx={{
            alignContent: 'center',
            alignSelf: 'center',
            opacity: isDisabled ? 0.5 : 1,
            ...sx
        }}>
            <BTButton
                onClick={handleClickNextTabButton}
                disabled={isDisabled}
                sx={{
                    endIcon: { margin: 0 },
                    minWidth:"250px",
                    padding: "8px 16px",
                    width: "auto",
                    borderRadius: "8px",
                    background: "linear-gradient(44.54deg, #42d872 14.2%, #97e89c 85.23%)",
                }}
            >
                {"Next"} <ChevronRightOutlined style={{ marginInlineStart: '5px', height: '14px', width: '14px' }} />
            </BTButton>
        </Box>
    )
}