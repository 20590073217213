import { useAppSelector } from "@common-reducers/hooks/store.hook";
import DeployButton from "./deploy-button";
import { RocketIcon } from "lucide-react";
import BTbotbutton from "./bt-bot-button";
interface DeployButtonProps {
    disabled?: boolean;
    onBeforeDeploy?: () => boolean | Promise<boolean>;
    onAfterDeploy?: () => void;
    localCurrentBot?: any; // Type this properly based on your bot type
    shouldClose?: boolean;
}

export const DeployButtonWrapper = ({
    disabled = false,
    onBeforeDeploy,
    onAfterDeploy,
    localCurrentBot,
    shouldClose = false,
}: DeployButtonProps) => {
    const isDragging = useAppSelector(state => state.ChatbotReducer.isDragging);
    if (isDragging) return <BTbotbutton
        startIcon={<RocketIcon style={{ width: '18px' }} />}
        sx={{
            minWidth: '120px',
            position: 'relative',
            '& .MuiCircularProgress-root': {
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
            }
        }}
    >
        Deploy
    </BTbotbutton>
    return <DeployButton
        disabled={disabled}
        onBeforeDeploy={onBeforeDeploy}
        onAfterDeploy={onAfterDeploy}
        localCurrentBot={localCurrentBot}
        shouldClose={shouldClose} />
}; 