import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { 
  CreditUsageData, 
  UsageDataPoint, 
  BotUsageData, 
  CreditUsageResponse,
  ExportCreditUsageResponse
} from '../reducers/backend-api/backend-api';
import { backendApi } from '../reducers/backend-api/backend-api';
import { CommonRootState } from '../types/common-root-state-type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// Helper function to extract error message
const getErrorMessage = (error: FetchBaseQueryError | SerializedError): string => {
  if ('status' in error) {
    // FetchBaseQueryError
    return typeof error.data === 'string' ? error.data : 'Failed to fetch data';
  } else {
    // SerializedError
    return error.message || 'An unknown error occurred';
  }
};

// Define the state interface
export interface CreditUsageState {
  creditsData: CreditUsageData;
  usageData: UsageDataPoint[];
  botUsageData: BotUsageData[];
  isLoading: boolean;
  error: string | null;
  exportUrl: string | null;
}

// Define the initial state
const initialState: CreditUsageState = {
  creditsData: {
    planName: "",
    totalCredits: 0,
    creditsUsed: 0,
    creditsRemaining: 0,
    percentageUsed: 0,
    renewalDate: "",
    daysRemaining: 0
  },
  usageData: [],
  botUsageData: [],
  isLoading: false,
  error: null,
  exportUrl: null
};

// Create async thunks
export const fetchCreditUsage = createAsyncThunk(
  'creditUsage/fetchCreditUsage',
  async (timeframe: string, { dispatch }) => {
    try {
      // Get credit usage data from the API
      const response = await dispatch(
        backendApi.endpoints.getCreditUsage.initiate({ timeframe })
      );
      
      // Check for errors in the response
      if ('error' in response) {
        throw new Error(getErrorMessage(response.error) || 'Failed to fetch credit usage data');
      }
      
      // Return the response data directly
      return response.data;
    } catch (error) {
      console.error('Error fetching credit usage data:', error);
      throw error;
    }
  }
);

export const exportCreditUsage = createAsyncThunk(
  'creditUsage/exportCreditUsage',
  async (timeframe: string, { dispatch }) => {
    

  }
);

// Create the slice
const creditUsageSlice = createSlice({
  name: 'creditUsage',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    resetExportUrl: (state) => {
      state.exportUrl = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchCreditUsage
      .addCase(fetchCreditUsage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCreditUsage.fulfilled, (state, action: PayloadAction<CreditUsageResponse>) => {
        state.isLoading = false;
        state.creditsData = action.payload.creditUsageData;
        state.usageData = action.payload.usageDataPoints || [];
        state.botUsageData = action.payload.botUsageData || [];
        state.error = null;
      })
      .addCase(fetchCreditUsage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch credit usage data';
      })
      
      // Handle exportCreditUsage
      .addCase(exportCreditUsage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(exportCreditUsage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to export credit usage data';
      });
  }
});

// Export actions
export const { resetError, resetExportUrl } = creditUsageSlice.actions;

// Export selectors
export const selectCreditUsageState = (state: CommonRootState) => state.CreditUsageReducer;
export const selectCreditsData = (state: CommonRootState) => state.CreditUsageReducer.creditsData;
export const selectUsageData = (state: CommonRootState) => state.CreditUsageReducer.usageData;
export const selectBotUsageData = (state: CommonRootState) => state.CreditUsageReducer.botUsageData;
export const selectIsLoading = (state: CommonRootState) => state.CreditUsageReducer.isLoading;
export const selectError = (state: CommonRootState) => state.CreditUsageReducer.error;
export const selectExportUrl = (state: CommonRootState) => state.CreditUsageReducer.exportUrl;

// Export reducer
export default creditUsageSlice.reducer; 