import { memo } from 'react';
import { Skeleton } from '@/components/ui/skeleton';

export const DeploymentCardSkeleton = memo(() => {
    return (
        <div className="p-4 rounded-lg border border-gray-200 bg-white">
            <div className="flex justify-between items-start mb-2">
                <div className="flex items-center gap-2">
                    <Skeleton className="h-5 w-24" />
                    <Skeleton className="h-5 w-16" />
                </div>
                <Skeleton className="h-4 w-20" />
            </div>
            
            <div className="flex gap-4 mt-2">
                <Skeleton className="h-4 w-16" />
                <Skeleton className="h-4 w-16" />
            </div>
        </div>
    );
}); 