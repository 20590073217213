
import BTButton from "@common-components/bt-button/bt-button";
import { namespace } from "@common-config/constants";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
    to: string;
    text: string;
    extraStyles?: React.CSSProperties;
}

export default function MainBackToButton({ to, text, extraStyles }: Props) {
    const { t } = useTranslation(namespace);
    const direction = useAppSelector((state) => state.UserReducer.direction);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                width: "100%",
                ...extraStyles
            }}
        >
            <Link to={to} style={{
                width: 'calc(100% - 32px)',
                // margin: "auto",
                borderRadius: "8px",
            }} >
                <BTButton

                    sx={{
                        width: '268px',
                        textTransform: 'none',
                        ".MuiButton-startIcon": { marginInlineStart: '-4px', marginInlineEnd: '8px' }
                    }}

                    startIcon={<ArrowBackIcon
                        sx={{
                            rotate: direction === "rtl" ? '180deg' : undefined,
                            fontSize: '19px',
                        }} />}  >
                    {t(`${text}`)}</BTButton>
            </Link>
        </div>
    )
}