import { DBBotDeploymentThunks, DBBotThunks, botSelectByQuery, resetAutomationSessionState, resetBotDeploymentState } from '@common-reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { Bot } from '@common/models/bot';
import { setCurrentBot, setIsReadyToLoadFlow } from '@common/reducers/ChatbotReducer';
import { createAndReRouteToNewBotEditorThunk, toggleBotActivationThunk } from '@common/reducers/ChatbotThunks';
import { type ClassValue, clsx } from "clsx";
import { Activity, ArrowUpRight, Bot as BotIcon, Calendar, ChevronDown, Edit, Loader2, Plus, Search, Trash2 } from 'lucide-react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from "tailwind-merge";
import { useCalculateBotStatsMutation } from '@common/reducers/backend-api/backend-api';

// Utility function for merging class names
function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

// Memoized search input component to prevent losing focus
const SearchInput = memo(({
    value,
    onChange
}: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
    return (
        <div className="relative w-[600px] flex-auto">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
                type="text"
                placeholder="Search agents..."
                value={value}
                onChange={onChange}
                className="w-full h-12 pl-12 pr-6 bg-white border border-gray-200 rounded-xl text-lg shadow-sm hover:shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
            />
        </div>
    );
});
SearchInput.displayName = 'SearchInput';

// Memoized status filter component
const StatusFilter = memo(({
    value,
    onChange
}: {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}) => {
    return (
        <div className="relative w-[240px]">
            <select
                value={value}
                onChange={onChange}
                className="w-full h-12 bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 pl-6 pr-10 text-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent appearance-none"
            >
                {React.createElement('option', { value: 'all' }, 'All Status')}
                {React.createElement('option', { value: 'published' }, 'Published')}
                {React.createElement('option', { value: 'draft' }, 'Draft')}
            </select>
            <ChevronDown className="absolute right-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-500 pointer-events-none" />
        </div>
    );
});
StatusFilter.displayName = 'StatusFilter';

interface StatusControlProps {
    status: string;
    onActivate: (active: boolean) => void;
    botId: string;
    isLoading: boolean;
}

const StatusControl = memo(({ status, onActivate, botId, isLoading }: StatusControlProps) => {
    const isPublished = status === 'published';

    return (
        <div className="flex items-center gap-2 justify-end">
            <span className={cn(
                "px-3 py-1 rounded-full text-sm font-medium min-w-[70px] text-center",
                isPublished ? "bg-emerald-50 text-emerald-700" : "bg-gray-100 text-gray-700"
            )}>
                {isPublished ? "published" : "draft"}
            </span>
            <div
                className={cn(
                    "relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out",
                    isPublished ? "bg-emerald-600" : "bg-gray-200",
                    isLoading && "opacity-50 cursor-not-allowed"
                )}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!isLoading) {
                        onActivate(!isPublished);
                    }
                }}
            >
                {isLoading ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    </div>
                ) : (
                    <span
                        className={cn(
                            "pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out",
                            isPublished ? "translate-x-5" : "translate-x-0.5",
                            "my-0.5"
                        )}
                    />
                )}
            </div>
        </div>
    );
});
StatusControl.displayName = 'StatusControl';

// Delete confirmation dialog component
const DeleteConfirmationDialog = memo(({
    isOpen,
    bot,
    onClose,
    onConfirm
}: {
    isOpen: boolean;
    bot: Bot | null;
    onClose: () => void;
    onConfirm: () => void;
}) => {
    if (!isOpen || !bot) return null;

    return (
        <div
            style={{ zIndex: 1201 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                <h3 className="text-lg font-semibold mb-2">Delete Bot</h3>
                <p className="text-gray-600 mb-4">
                    Are you sure you want to delete "{bot.name}"? This action cannot be undone.
                </p>
                <div className="flex justify-end gap-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
});
DeleteConfirmationDialog.displayName = 'DeleteConfirmationDialog';

const BotListContent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [botToDelete, setBotToDelete] = useState<Bot | null>(null);
    const [togglingBots, setTogglingBots] = useState<Set<string>>(new Set());
    const [calculateBotStats] = useCalculateBotStatsMutation();

    const allBots: Bot[] = useAppSelector((state) => botSelectByQuery(state.DBBotReducer, {}));
    const isLoading = useAppSelector(state => state.DBBotReducer.itemsLoading);

    useEffect(() => {
        dispatch(DBBotThunks.find({ $paginate: false }));
        dispatch(DBBotDeploymentThunks.find({ $paginate: false }));
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading && allBots.length > 0) {
            const botIds = allBots.map(bot => bot._id);
            calculateBotStats(botIds).catch(error => 
                console.error('Failed to calculate bot stats:', error)
            );
        }
    }, [isLoading, allBots, calculateBotStats]);

    const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    }, []);

    const handleStatusFilterChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        setStatusFilter(e.target.value);
    }, []);

    const handleBotActivation = useCallback(async (botId: string, active: boolean) => {
        const bot = allBots.find(b => b._id === botId);
        if (!bot) return;

        setTogglingBots(prev => new Set(prev).add(botId));
        try {
            await dispatch(toggleBotActivationThunk({
                bot,
                shouldActivate: active,
                findLatestDeployment: active
            }));
        } catch (error) {
            console.error('Failed to toggle bot activation:', error);
        } finally {
            setTogglingBots(prev => {
                const next = new Set(prev);
                next.delete(botId);
                return next;
            });
        }
    }, [allBots, dispatch]);

    const handleDeleteClick = useCallback((e: React.MouseEvent, bot: Bot) => {
        e.stopPropagation();
        setBotToDelete(bot);
        setIsDeleteDialogOpen(true);
    }, []);

    const handleDeleteConfirm = useCallback(async () => {
        if (!botToDelete) return;

        const botToDeleteCopy = botToDelete;
        setBotToDelete(null);
        setIsDeleteDialogOpen(false);

        try {
            await dispatch(DBBotThunks.delete({ entity: botToDeleteCopy }));
            await dispatch(setCurrentBot(null));
        } catch (error) {
            console.error('Failed to delete bot:', error);
        }
    }, [botToDelete, dispatch]);

    const handleBotClick = useCallback(async (bot: Bot) => {
        // Clear all entities from both reducers using their dedicated reset actions
        await dispatch(resetBotDeploymentState());
        await dispatch(resetAutomationSessionState());

        navigate(`/bot-detail-view/${bot._id}`);
    }, [navigate, dispatch]);

    const handleEditClick = useCallback(async (e: React.MouseEvent, bot: Bot) => {
        e.stopPropagation(); // Prevent navigation to detail view
        if (bot?._id) {
            await dispatch(setIsReadyToLoadFlow(false));
            await navigate(`/bot-build-view/${bot._id}`);
        }
    }, [navigate]);

    const handleCreateNewBot = useCallback(async () => {
        await dispatch(setIsReadyToLoadFlow(false));
        dispatch(createAndReRouteToNewBotEditorThunk({ navigate }));
    }, [dispatch, navigate]);

    const filteredBots = allBots.filter(bot => {
        const matchesSearch = bot.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            bot.description?.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesStatus = statusFilter === 'all' ||
            (statusFilter === 'published' && bot.activeDeploymentId) ||
            (statusFilter === 'draft' && !bot.activeDeploymentId);
        return matchesSearch && matchesStatus;
    });

    return (
        <>
            <div className="h-full overflow-auto bg-gradient-to-br from-gray-50 to-gray-100 p-12">
                {/* Header */}
                <div className="max-w-[1600px] mx-auto space-y-12">
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-4xl font-semibold text-gray-900">Agents</h1>
                            <p className="text-lg text-gray-600 mt-2">Manage and monitor your automated workflows</p>
                        </div>
                        <button
                            onClick={handleCreateNewBot}
                            className="bg-emerald-600 hover:bg-emerald-700 text-white px-8 py-3 rounded-lg text-base font-medium transition-all duration-200 shadow-sm hover:shadow-lg flex items-center gap-3"
                        >
                            <Plus className="w-5 h-5" />
                            New Agent
                        </button>
                    </div>

                    {/* Filters */}
                    <div className="flex justify-between gap-2">
                        <SearchInput value={searchQuery} onChange={handleSearchChange} />
                        <StatusFilter value={statusFilter} onChange={handleStatusFilterChange} />
                    </div>

                    {/* Loading State */}
                    {isLoading && (
                        <div className="flex flex-col justify-center items-center min-h-[400px] py-20">
                            <div className="bg-white rounded-full p-6 shadow-md mb-4">
                                <Loader2 className="w-10 h-10 text-emerald-600 animate-spin" />
                            </div>
                            <p className="text-gray-600 font-medium text-lg animate-pulse">Loading your agents...</p>
                        </div>
                    )}

                    {/* Empty State */}
                    {!isLoading && filteredBots.length === 0 && (
                        <div className="flex justify-center items-center min-h-[400px]">
                            <p className="text-gray-500">No agents found. Create one to get started.</p>
                        </div>
                    )}

                    {/* Bot Grid */}
                    {!isLoading && filteredBots.length > 0 && (
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
                            {filteredBots.map((bot) => {
                                const stats = bot.botStats || {
                                    activeSessions: '--',
                                    todaySessions: '--',
                                    avgDuration: '--',
                                    latestDeployment: null
                                };
                                const isPublished = !!bot.activeDeploymentId;

                                // Format the deployment date with graceful handling
                                let deploymentDate = 'Never';
                                if (stats.latestDeployment?.createdAt) {
                                    const date = new Date(stats.latestDeployment.createdAt);
                                    if (!isNaN(date.getTime())) {
                                        deploymentDate = date.toLocaleDateString(undefined, {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric'
                                        });
                                    }
                                }

                                return (
                                    <div
                                        key={bot._id}
                                        onClick={() => handleBotClick(bot)}
                                        className="bg-white shadow-sm hover:shadow-lg transition-all duration-200 rounded-2xl overflow-hidden border border-gray-100 cursor-pointer relative group"
                                    >
                                        <div className="p-8">
                                            {/* Header with bot info and controls */}
                                            <div className="flex flex-col gap-4 mb-4">
                                                {/* Bot info in its own row with full width */}
                                                <div className="flex items-start gap-4 relative">
                                                    <div className="p-3 bg-emerald-50 rounded-xl self-center flex-shrink-0">
                                                        <BotIcon className="w-6 h-6 text-emerald-600" />
                                                    </div>
                                                    <div className="min-w-0 flex-1">
                                                        <div className="text-xl font-medium text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap">{bot.name || 'New Chatbot (unsaved)'}</div>
                                                        <p className="text-base text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">{bot.description || 'No description'}</p>
                                                    </div>

                                                    {/* Edit button positioned absolutely at top right */}
                                                    <button
                                                        onClick={(e) => handleEditClick(e, bot)}
                                                        className="absolute top-6 right-0 text-gray-400 hover:text-emerald-600 transition-all duration-200 opacity-0 group-hover:opacity-100"
                                                        title="Edit bot"
                                                    >
                                                        <Edit className="w-5 h-5" />
                                                    </button>
                                                </div>

                                                {/* Status controls in their own row */}
                                                <div className="flex items-center justify-end mt-2">
                                                    <StatusControl
                                                        status={isPublished ? 'published' : 'draft'}
                                                        onActivate={(active) => handleBotActivation(bot._id, active)}
                                                        botId={bot._id}
                                                        isLoading={togglingBots.has(bot._id)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-2 gap-6 mt-8 mb-6 relative">
                                                <div className="flex items-center gap-3">
                                                    <Activity className="w-5 h-5 text-gray-400" />
                                                    <div>
                                                        <p className="text-sm text-gray-500">Active Sessions</p>
                                                        <p className="text-lg font-medium text-gray-900">
                                                            {stats.activeSessions !== undefined ? stats.activeSessions : '--'}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-3">
                                                    <Calendar className="w-5 h-5 text-gray-400" />
                                                    <div>
                                                        <p className="text-sm text-gray-500">Today's Sessions</p>
                                                        <p className="text-lg font-medium text-gray-900">
                                                            {stats.todaySessions !== undefined ? stats.todaySessions : '--'}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* Delete button positioned absolutely at bottom right of the stats grid */}
                                                <button
                                                    onClick={(e) => handleDeleteClick(e, bot)}
                                                    className="absolute bottom-0 right-0 text-gray-400 hover:text-red-600 transition-all duration-200 opacity-0 group-hover:opacity-100"
                                                    title="Delete bot"
                                                >
                                                    <Trash2 className="w-5 h-5" />
                                                </button>
                                            </div>

                                            <div className="flex items-center justify-between pt-6 border-t border-gray-100">
                                                <div>
                                                    <p className="text-sm text-gray-500">Last deployed</p>
                                                    <p className="text-base font-medium text-gray-900">
                                                        {deploymentDate}
                                                    </p>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    {stats.latestDeployment?.version && (
                                                        <>
                                                            <span className="text-base font-medium text-emerald-600">v{stats.latestDeployment.version}</span>
                                                        </>
                                                    )}
                                                    <ArrowUpRight className="w-5 h-5 text-gray-400" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>

            <DeleteConfirmationDialog
                isOpen={isDeleteDialogOpen}
                bot={botToDelete}
                onClose={() => {
                    setIsDeleteDialogOpen(false);
                    setBotToDelete(null);
                }}
                onConfirm={handleDeleteConfirm}
            />
        </>
    );
};

export default memo(BotListContent); 