import { Alert, Divider } from '@mui/material';
import { Stack } from '@mui/system';
import { campaignsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import moment from 'moment';
import React from 'react';
import CampaignTabHeader from '../campaign-tab-header';
import CampaignSummaryAudienceDialog from './campaign-summary-audience-dialog';
import CampaignSummaryMessageTemplateDialog from './campaign-summary-message-template-dialog';
import CampaignSummaryTimingDialog from './campaign-summary-timing-dialog';


type Props = {
  campaignId?: string;
}


const BulkDBFinish = React.forwardRef<HTMLDivElement, Props>(({ campaignId }: Props, ref) => {
  let campaign
  const _campaign = useAppSelector((state) => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId }))
  const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign)

  if (campaignId) campaign = _campaign
  else campaign = newCampaign

  return (
    <Stack ref={ref} sx={{
      alignItems: 'center',
      width: '100%',
    }}>
      {!campaignId && (!campaign?.schedule?.isNow && moment.utc(campaign?.schedule?.dueDate).isBefore(moment.utc().subtract(1, "minute"))) &&
        <Alert sx={{
          alignItems: "center", boxShadow: "none", ".MuiAlert-message": {
            width: "100%", textAlign: "center", padding: "0"
          }
        }} severity="warning">
          <div>Pay attention, The scheduled time has already passed.</div>
          <div>Please select a future date and time</div>
        </Alert>
      }
      {!campaignId && <CampaignTabHeader htmlTitle="Your campaign is ready for launch."
        htmlSubTitle='Please double-check your target audience and message<br>and then press the "Launch campaign" button' />}

      <CampaignSummaryAudienceDialog campaignId={campaignId} />
      <Divider sx={{
        borderColor: "rgb(217 217 217)",
        width: "100%",
        marginBottom: "15px"
      }} />
      <CampaignSummaryMessageTemplateDialog campaignId={campaignId} />
      <Divider sx={{
        borderColor: "rgb(217 217 217)",
        width: "100%",
        marginBottom: "15px"
      }} />
      <CampaignSummaryTimingDialog campaignId={campaignId} />


    </Stack>
  )
})

export default BulkDBFinish