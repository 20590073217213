import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  ClickAwayListener,
  Box,
} from "@mui/material";
import { useState } from "react";

export const MessageConfirmationDialog = <T extends string>({
  title,
  onClose,
  options,
  selected,
}: {
  title: string;
  onClose: (value?: T) => void;
  options: T[];
  selected?: number;
}) => {
  const [value, setValue] = useState<T>(options[selected || 0]);
  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as T);
  };
  return (
    <Dialog open={true}>
      <ClickAwayListener onClickAway={handleCancel}>
        <Box onClick={(e) => e.stopPropagation()}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers>
            <RadioGroup
              value={value}
              onChange={handleChange}
              sx={{ "& .MuiRadio-root": { color: "#53bdeb" } }}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  label={option}
                  control={<Radio checked={option === value} />}
                />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Box>
      </ClickAwayListener>
    </Dialog>
  );
};
