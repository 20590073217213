import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { getBotDeploymentIdsByBotIdSelector, getCurrentBotSelector } from '@common/reducers/ChatbotSelectors';
import { Button } from '@/components/ui/button';
import { DeploymentCard } from './DeploymentCard';
import { DeploymentCardSkeleton } from './DeploymentCardSkeleton';
import { memo, useCallback } from 'react';

interface DeploymentsSidebarProps {
    botId?: string;
    selectedDeploymentId: string | null;
    onDeploymentSelect: (deploymentId: string | null) => void;
}

export const DeploymentsSidebar = memo(({ botId, selectedDeploymentId, onDeploymentSelect }: DeploymentsSidebarProps) => {
    const isLoading = useAppSelector(state => state.DBBotDeploymentReducer.itemsLoading);
    const bot = useAppSelector(state => getCurrentBotSelector(state));

    const deploymentIds = useAppSelector(state => 
        botId ? getBotDeploymentIdsByBotIdSelector(state, botId) : []
    );
    
    const handleAllVersionsClick = useCallback(() => {
        onDeploymentSelect(null);
    }, [onDeploymentSelect]);

    const renderDeployments = useCallback(() => {
        if (isLoading) {
            return Array(3).fill(0).map((_, index) => (
                <DeploymentCardSkeleton key={index} />
            ));
        }

        if (deploymentIds.length === 0) {
            return (
                <div className="text-center p-4 text-gray-500">
                    No deployments found
                </div>
            );
        }

        return deploymentIds.map(deploymentId => (
            <DeploymentCard
                key={deploymentId}
                deploymentId={deploymentId}
                isSelected={selectedDeploymentId === deploymentId}
                onSelect={() => onDeploymentSelect(deploymentId)}
            />
        ));
    }, [isLoading, deploymentIds, selectedDeploymentId, onDeploymentSelect]);

    return (
        <div className="flex flex-col h-full">
            <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold mb-4">Deployments</h2>
         
            </div>
            <div className="flex-1 overflow-auto p-4 space-y-4">
                {renderDeployments()}
            </div>
        </div>
    );
}); 