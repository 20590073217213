import { selectAllMessageLogRows, selectPaginatedMessageLogRows } from '@common-reducers/CampaignSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { MediaPreviewDialog } from '../messages/components/MediaPreview';
import { MessageCell } from '../messages/components/MessageCell';
import { StatusCell } from '../messages/components/StatusCell';
import { ContactCell } from '../messages/components/ContactCell';
import { CampaignIdCell } from '../messages/components/CampaignIdCell';

interface MessageLogsTabProps {
    supportedUserId: string | null;
}

export function MessageLogsTab({ supportedUserId }: MessageLogsTabProps) {
    const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});
    const [selectedMedia, setSelectedMedia] = useState<any | null>(null);
    const [copiedId, setCopiedId] = useState<string | null>(null);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });

    const allRows = useAppSelector(
        state => supportedUserId ? selectAllMessageLogRows(state, supportedUserId, expandedRows) : []
    );

    const rows = useAppSelector(
        state => supportedUserId ? 
            selectPaginatedMessageLogRows(
                state, 
                supportedUserId, 
                expandedRows, 
                paginationModel.page,
                paginationModel.pageSize
            ) : []
    );

    const totalRows = allRows.length;

    const handleRowClick = (params: any) => {
        if (params.row.isMainRow) {
            setExpandedRows(prev => ({
                ...prev,
                [params.row.id]: !prev[params.row.id]
            }));
        }
    };

    const handleCopyId = (e: React.MouseEvent, id: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(id);
        setCopiedId(id);
        setTimeout(() => setCopiedId(null), 2000);
    };

    // Find the first expanded row to show in header
    const expandedRow = useMemo(() => {
        const expandedRowId = Object.entries(expandedRows).find(([_, isExpanded]) => isExpanded)?.[0];
        return rows.find(row => row.id === expandedRowId && row.isMainRow);
    }, [rows, expandedRows]);

    const columns = [
        {
            field: 'expand',
            headerName: '',
            width: 50,
            sortable: false,
            renderCell: (params: any) => (
                params.row.isMainRow ? (
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            setExpandedRows(prev => ({
                                ...prev,
                                [params.row.id]: !prev[params.row.id]
                            }));
                        }}
                    >
                        {expandedRows[params.row.id] ? 
                            <KeyboardArrowUpIcon /> : 
                            <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                ) : (
                    <Box sx={{ 
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: '20px',
                            height: '100%',
                            width: '1px',
                            bgcolor: 'grey.300'
                        },
                        '&::after': {
                            content: '""',
                            width: '16px',
                            height: '1px',
                            bgcolor: 'grey.300',
                            position: 'relative',
                            left: '4px'
                        }
                    }}>
                        <Box 
                            component="span" 
                            sx={{ 
                                color: 'grey.500',
                                fontSize: '16px',
                                display: 'inline-block',
                                position: 'relative',
                                left: '4px',
                            }}
                        >
                            ↳
                        </Box>
                    </Box>
                )
            )
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 1,
            minWidth: 300,
            renderCell: (params: any) => (
                <MessageCell 
                    message={params.value}
                    onMediaClick={(message) => {
                        // Prevent row expansion when clicking media
                        setSelectedMedia(message);
                    }}
                />
            )
        },
        {
            field: 'latestStatus',
            headerName: 'Latest Status',
            width: 150,
            renderCell: (params: any) => (
                <StatusCell 
                    status={params.value} 
                    dueDate={params.row.dueDate}
                />
            )
        },
        {
            field: 'logCount',
            headerName: 'Log Count',
            width: 100,
            renderCell: (params: any) => (
                params.value ? (
                    <Typography variant="body2">
                        {params.value}
                    </Typography>
                ) : null
            )
        },
        {
            field: 'latestUpdate',
            headerName: 'Latest Update',
            width: 200,
            valueFormatter: (params: any) => moment(params.value).format('MM/DD/YYYY, h:mm:ss A')
        },
        {
            field: 'contact',
            headerName: 'Contact',
            width: 200,
            renderCell: (params: any) => (
                <ContactCell contact={params.row.message?.contactList?.[0]} />
            )
        },
        {
            field: 'messageId',
            headerName: 'Message ID',
            width: 280,
            renderCell: (params: any) => (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                        variant="body2"
                        sx={{
                            fontFamily: params.row.isMainRow ? 'monospace' : 'inherit',
                            fontWeight: params.row.isMainRow ? 500 : 400,
                            color: params.row.isMainRow ? 'text.primary' : 'text.secondary',
                            fontSize: '0.75rem'
                        }}
                    >
                        {params.value}
                    </Typography>
                    {params.row.isMainRow && (
                        <Tooltip title={copiedId === params.value ? "Copied!" : "Copy ID"}>
                            <IconButton
                                size="small"
                                onClick={(e) => handleCopyId(e, params.value)}
                                color={copiedId === params.value ? "success" : "default"}
                            >
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            )
        },
        {
            field: 'campaignId',
            headerName: 'Campaign ID',
            width: 180,
            renderCell: (params: any) => (
                <CampaignIdCell campaignId={params.row.message?.campaignId} />
            )
        }
    ];

    return (
        <Stack spacing={2} sx={{ height: '100%', width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[25, 50, 100]}
                    rowCount={totalRows}
                    paginationMode="server"
                    onRowClick={handleRowClick}
                    getRowClassName={(params) => 
                        params.row.isMainRow ? '' : 'sub-row'
                    }
                    sx={(theme) => ({
                        '& .sub-row': {
                            bgcolor: theme.palette.mode === 'dark'
                                ? theme.palette.background.paper
                                : theme.palette.grey[50],
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderColor: theme.palette.grey[200],
                        },
                        '& .MuiDataGrid-row:hover': {
                            bgcolor: theme.palette.action.hover,
                            cursor: 'pointer'
                        },
                        '& .sub-row:hover': {
                            bgcolor: theme.palette.mode === 'dark'
                                ? theme.palette.action.hover
                                : theme.palette.grey[100],
                        },
                        '& .MuiDataGrid-row': {
                            position: 'relative'
                        },
                        '& .MuiDataGrid-cell': {
                            overflow: 'visible !important',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }
                    })}
                />
            </Box>
            <MediaPreviewDialog 
                open={!!selectedMedia} 
                onClose={() => setSelectedMedia(null)} 
                message={selectedMedia} 
            />
        </Stack>
    );
} 