import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ApiBaseQuery, ApiTagTypes } from "./backend-api";
import { PaymentIntent } from "@chargebee/chargebee-js-types";

export interface FinalizePurchaseParams {
    item_price_id: string;
    quantity: number;
    coupon_ids: string[];
    payment_intent_id?: string;
    customerDetails: CustomerDetails;
}

export const chargebeeApiBuilder = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'backendApi'>) => ({
    createIntent: builder.mutation<
        PaymentIntent,
        {
            amount: number;
            quantity: number;
            payment_method_type?: string;
        }
    >({
        query: (params) => ({
            url: "actions",
            method: "POST",
            body: {
                cmd: "chargebee.createIntent",
                ...params
            },
        }),
    }),
    createEstimate: builder.mutation<
        Estimate,
        {
            quantity: number;
            item_price_id: string;
            customer_id?: string;
            coupon_ids?: string[];
            subscription_id?: string;
        }
    >({
        query: (params) => ({
            url: "actions",
            method: "POST",
            body: {
                cmd: "chargebee.createEstimate",
                ...params
            },
        }),
    }),
    finalizePurchase: builder.mutation<
        Subscription,
        FinalizePurchaseParams
    >({
        query: (params) => ({
            url: "actions",
            method: "POST",
            body: {
                cmd: "chargebee.finalizePurchase",
                ...params
            },
        }),
    }),
    getAddOns: builder.mutation<Array<{
        id: string;
        name: string;
        description: string;
        credits: number;
        price: number;
        perCredit: number;
        popular?: boolean;
        savePercentage?: number;
    }>, void>({
        query: () => ({
            url: "actions",
            method: "POST",
            body: {
                cmd: "chargebee.getAddOns"
            },
        }),
    }),
})