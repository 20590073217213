import ContactAvatar from '@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar';
import RowStack from '@common-components/common/row-stack/RowStack';
import { ContactInfo } from '@common-models/contact-info';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Stack } from '@mui/material';
import { cellValueSelector, updateCellValueThunk } from '@common-reducers/TaskThunks';
import { selectFilteredMergedContactList } from '@common-reducers/WhatsAppReducer';
import { extractPhoneNumber, getWaContactDisplayLabel, selectWhatsappContactById } from '@common-reducers/WhatsAppSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';

import { memo, useCallback, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { PickerProps } from '../cell-interface';
import { PhoneDisplay } from '../phone-number-cell/phone-display';
import WhatsappContactSearchBox from './whatsapp-contact-search-box';

const WhatsappIdCellEditPicker = ({ taskId, columnId, onEdit }: PickerProps) => {
    const dispatch = useAppDispatch();

    const cellValue = useAppSelector((state) => cellValueSelector(state, taskId, columnId))
    const currentContact = useAppSelector((state) => selectWhatsappContactById(state, cellValue))

    const [searchFilter, setSearchFilter] = useState('');

    const filteredContacts = useSelector((state) => selectFilteredMergedContactList(state, searchFilter));


    const handleSearchFilterChange = (value: string) => {
        setSearchFilter(value);
    };

    const clearCell = useCallback(() => {
        if (onEdit) {
            onEdit('')
        } else {
            dispatch(updateCellValueThunk({
                taskId,
                columnId,
                data: {
                    value: '',
                }
            }));
        }
    }, [columnId, dispatch, taskId]);



    const saveFreeInput = useCallback(() => {
        let whatsappId = searchFilter;
        if (!whatsappId.includes('@')) {
            whatsappId = `${whatsappId}@c.us`;
        }
        if (onEdit) {
            onEdit(whatsappId)
        } else {
            dispatch(updateCellValueThunk({
                taskId,
                columnId,
                data: {
                    value: whatsappId,
                }
            }));
        }
    }, [columnId, dispatch, searchFilter, taskId]);

    const save = useCallback((contact: ContactInfo) => {
        //console.log('contact', contact);
        if (onEdit) {
            onEdit(contact?.id._serialized)
        } else {
            dispatch(updateCellValueThunk({
                taskId,
                columnId,
                data: {
                    value: contact?.id._serialized,
                }
            }));
        }
        setSearchFilter('');
    }, [columnId, dispatch, taskId]);


    const isGroup = cellValue?.includes && cellValue?.includes('@g.us');
    const phoneNumber = extractPhoneNumber(cellValue);

    return (
        <Box p={2}>
            {cellValue &&
                <RowStack
                    flex={1}
                    alignItems={'center'}
                    justifyContent={'center'} spacing={1}>
                    <ContactAvatar
                        sx={{
                            mr: 1,
                            mb: "4px"
                        }}
                        contactId={cellValue}
                        size={24}
                        textSize={12}
                    />

                    <Stack justifyContent={"center"} alignItems={"center"} >
                        <span style={{ marginBottom: "5px" }}>{getWaContactDisplayLabel(currentContact)} </span>
                        {!isGroup && <PhoneDisplay phone={phoneNumber} />}
                    </Stack>
                    <IconButton size="small" onClick={clearCell} >
                        <CancelIcon sx={{ fontSize: '14px', cursor: 'pointer' }} color="action" />
                    </IconButton>
                </RowStack>
            }
            <WhatsappContactSearchBox
                searchFilter={searchFilter}
                handleSearchFilterChange={handleSearchFilterChange}
                saveFreeInput={saveFreeInput}
                filteredContacts={filteredContacts}
                save={save}
            />
        </Box>
    );
};


export default memo(WhatsappIdCellEditPicker);