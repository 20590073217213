import React, { FC, useState, useEffect, useCallback } from 'react';
import { CommonRootState } from '@/common/types/common-root-state-type';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import {
    Bot,
    FileText,
    Loader2,
    Plus,
    Settings,
    Sliders
} from 'lucide-react';
import { KnowledgeBase } from '@/common/models/knowledge-base';
import { DBKnowledgeBaseThunks, knowledgeBaseSelectByQuery } from '@/common/reducers/DBServiceThunks';
import { changeNodeDataThunk } from '@/common/reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@/common/reducers/hooks/store.hook';

interface AddAgentDialogProps {
    open: boolean;
    onClose: () => void;
    id?: string;
}

const AddAgentDialog: FC<AddAgentDialogProps> = ({ open, onClose, id }) => {
    const dispatch = useAppDispatch();
    
    // Get node data if editing
    const localNodeData = useAppSelector((state: CommonRootState) =>  {
        if (id) {
            return state.ChatbotReducer?.nodes.find(n => n.id === id)?.data;
        }
        return null;
    });
    
    // Get knowledge bases
    const knowledgeBases = useAppSelector(state => 
        knowledgeBaseSelectByQuery(state.DBKnowledgeBaseReducer, {
            status: 'active'
        })
    );
    
    // Get loading state
    const isLoadingKnowledgeBases = useAppSelector(state =>
        state.DBKnowledgeBaseReducer?.itemsLoading ?? false
    );
    
    // Fetch knowledge bases on mount
    useEffect(() => {
        dispatch(DBKnowledgeBaseThunks.find({}));
    }, [dispatch]);
    
    // Tab state
    const [activeTab, setActiveTab] = useState('general');
    
    // General tab state
    const [name, setName] = useState(localNodeData?.agentName ?? 'Customer Service Agent');
    const [systemPrompt, setSystemPrompt] = useState(localNodeData?.systemPrompt ?? 'You are a customer service representative, you can answer questions on Blueticks Product & Pricing Analysis');
    const [memory, setMemory] = useState(localNodeData?.memory ?? false);
    const [fileUploads, setFileUploads] = useState(localNodeData?.fileUploads ?? false);
    const [responseLength, setResponseLength] = useState(localNodeData?.responseLength ?? 60);
    
    // Knowledge tab state
    const [kbEnabled, setKbEnabled] = useState(localNodeData?.kbEnabled ?? false);
    const [knowledgeBaseIds, setknowledgeBaseIds] = useState<string[]>(
        localNodeData?.knowledgeBaseIds ?? []
    );
    const [resultsLimit, setResultsLimit] = useState(localNodeData?.resultsLimit ?? '5');
    const [minSimilarity, setMinSimilarity] = useState(localNodeData?.minSimilarity ?? '0.7');
    const [citationStyle, setCitationStyle] = useState(localNodeData?.citationStyle ?? 'numbered');
    const [failureBehavior, setFailureBehavior] = useState(localNodeData?.failureBehavior ?? 'admit');
    
    // Update state when localNodeData changes
    useEffect(() => {
        if (localNodeData) {
            setName(localNodeData.agentName ?? 'Customer Service Agent');
            setSystemPrompt(localNodeData.systemPrompt ?? 'You are a customer service representative, you can answer questions on Blueticks Product & Pricing Analysis');
            setMemory(localNodeData.memory ?? false);
            setFileUploads(localNodeData.fileUploads ?? false);
            setResponseLength(localNodeData.responseLength ?? 60);
            setKbEnabled(localNodeData.kbEnabled ?? false);
            setknowledgeBaseIds(localNodeData.knowledgeBaseIds ?? []);
            setResultsLimit(localNodeData.resultsLimit ?? '5');
            setMinSimilarity(localNodeData.minSimilarity ?? '0.7');
            setCitationStyle(localNodeData.citationStyle ?? 'numbered');
            setFailureBehavior(localNodeData.failureBehavior ?? 'admit');
        }
    }, [localNodeData]);
    
    // Toggle knowledge base selection
    const toggleKnowledgeBase = (id: string) => {
        setknowledgeBaseIds(prev => 
            prev.includes(id) ? prev.filter(kb => kb !== id) : [...prev, id]
        );
    };
    
    // Get response length label
    const getResponseLengthLabel = (value: number): string => {
        if (value <= 20) return 'Very Short';
        if (value <= 40) return 'Short';
        if (value <= 60) return 'Medium';
        if (value <= 80) return 'Long';
        return 'Very Long';
    };
    
    // Save all changes
    const handleSave = useCallback(async () => {
        const updatedNodeData = {
            ...localNodeData,
            // General settings
            agentName: name,
            systemPrompt,
            memory,
            fileUploads,
            responseLength,
            // Knowledge settings
            kbEnabled,
            knowledgeBaseIds,
            resultsLimit,
            minSimilarity,
            citationStyle,
            failureBehavior
        };
        
        console.log('Saving node data:', id, updatedNodeData);
        
        await dispatch(changeNodeDataThunk({
            id,
            dataToPatch: updatedNodeData
        }));
        
        onClose();
    }, [
        dispatch, id, onClose, localNodeData,
        name, systemPrompt, memory, fileUploads, responseLength,
        kbEnabled, knowledgeBaseIds, resultsLimit, minSimilarity,
        citationStyle, failureBehavior
    ]);
    
    // Only render if open is true
    if (!open) return null;

    return (
        <>
            <div className="flex items-center gap-2 pt-6 pb-2">
                <Bot className="h-5 w-5" />
                <h2 className="text-xl font-semibold">Agent Node Settings</h2>
            </div>
            <p className="text-muted-foreground mb-6">Configure your agent's behavior and knowledge access</p>
            
            <div className="mb-6">
                <div className="flex border-b">
                    <button 
                        className={`px-4 py-2 ${activeTab === 'general' ? 'text-primary border-b-2 border-primary font-medium' : 'text-muted-foreground'}`}
                        onClick={() => setActiveTab('general')}
                    >
                        <div className="flex items-center gap-2">
                            <Settings className="h-4 w-4" />
                            General
                        </div>
                    </button>
                    <button 
                        className={`px-4 py-2 ${activeTab === 'advanced' ? 'text-primary border-b-2 border-primary font-medium' : 'text-muted-foreground'}`}
                        onClick={() => setActiveTab('advanced')}
                    >
                        <div className="flex items-center gap-2">
                            <Sliders className="h-4 w-4" />
                            Advanced
                        </div>
                    </button>
                    <button 
                        className={`px-4 py-2 ${activeTab === 'knowledge' ? 'text-primary border-b-2 border-primary font-medium' : 'text-muted-foreground'}`}
                        onClick={() => setActiveTab('knowledge')}
                    >
                        <div className="flex items-center gap-2">
                            <FileText className="h-4 w-4" />
                            Knowledge
                        </div>
                    </button>
                </div>
            </div>
            
            {activeTab === 'general' && (
                <div className="space-y-4 p-4 border rounded-lg bg-card">
                    <div>
                        <Label htmlFor="name">Name</Label>
                        <Input
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter agent name"
                            className="mt-1"
                        />
                    </div>
                </div>
            )}
            
            {activeTab === 'advanced' && (
                <div className="space-y-4 p-4 border rounded-lg bg-card">
                    <h3 className="text-base font-medium my-2">General Options</h3>
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="font-medium">Memory</div>
                                <div className="text-sm text-muted-foreground">Keep context between interactions</div>
                            </div>
                            <Switch
                                checked={memory}
                                onCheckedChange={setMemory}
                            />
                        </div>
                        
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="font-medium">File Uploads</div>
                                <div className="text-sm text-muted-foreground">Allow users to upload files</div>
                            </div>
                            <Switch
                                checked={fileUploads}
                                onCheckedChange={setFileUploads}
                            />
                        </div>
                        
                        <div>
                            <div className="flex items-center justify-between mb-2">
                                <div className="font-medium">Response Length</div>
                                <div className="text-sm font-medium">{getResponseLengthLabel(responseLength)}</div>
                            </div>
                            <div className="grid grid-cols-5 gap-2">
                                <button
                                    type="button"
                                    className={`py-1 px-2 text-xs rounded-md transition-colors ${responseLength === 20 ? 'bg-primary text-primary-foreground' : 'bg-muted hover:bg-muted/80'}`}
                                    onClick={() => setResponseLength(20)}
                                >
                                    Very Short
                                </button>
                                <button
                                    type="button"
                                    className={`py-1 px-2 text-xs rounded-md transition-colors ${responseLength === 40 ? 'bg-primary text-primary-foreground' : 'bg-muted hover:bg-muted/80'}`}
                                    onClick={() => setResponseLength(40)}
                                >
                                    Short
                                </button>
                                <button
                                    type="button"
                                    className={`py-1 px-2 text-xs rounded-md transition-colors ${responseLength === 60 ? 'bg-primary text-primary-foreground' : 'bg-muted hover:bg-muted/80'}`}
                                    onClick={() => setResponseLength(60)}
                                >
                                    Medium
                                </button>
                                <button
                                    type="button"
                                    className={`py-1 px-2 text-xs rounded-md transition-colors ${responseLength === 80 ? 'bg-primary text-primary-foreground' : 'bg-muted hover:bg-muted/80'}`}
                                    onClick={() => setResponseLength(80)}
                                >
                                    Long
                                </button>
                                <button
                                    type="button"
                                    className={`py-1 px-2 text-xs rounded-md transition-colors ${responseLength === 100 ? 'bg-primary text-primary-foreground' : 'bg-muted hover:bg-muted/80'}`}
                                    onClick={() => setResponseLength(100)}
                                >
                                    Very Long
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            {activeTab === 'knowledge' && (
                <div className="space-y-4 p-4 border rounded-lg bg-card">
                    <div>
                        <h3 className="text-base font-medium my-2">Knowledge Bases</h3>
                        <p className="text-sm text-muted-foreground mb-3">Connect knowledge bases to enhance your agent's capabilities</p>
                        
                        <div className="space-y-3">
                            {isLoadingKnowledgeBases ? (
                                <div className="flex items-center justify-center p-4">
                                    <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
                                </div>
                            ) : knowledgeBases.length === 0 ? (
                                <p className="text-sm text-muted-foreground text-center py-4">
                                    No knowledge bases found. Create one to enhance your agent.
                                </p>
                            ) : knowledgeBases.map((kb: KnowledgeBase) => (
                                <div
                                    key={kb._id}
                                    onClick={() => toggleKnowledgeBase(kb._id)}
                                    className={`p-3 rounded-md border cursor-pointer transition-colors ${
                                        knowledgeBaseIds.includes(kb._id) 
                                            ? 'bg-green-50 border-green-200 hover:bg-green-100' 
                                            : 'bg-white border-border hover:bg-gray-50'
                                    }`}
                                >
                                    <div className="flex justify-between items-start">
                                        <div className="flex gap-3">
                                            <div className={`p-1 rounded ${knowledgeBaseIds.includes(kb._id) ? 'bg-green-100' : 'bg-gray-100'}`}>
                                                <FileText className={`h-4 w-4 ${knowledgeBaseIds.includes(kb._id) ? 'text-green-700' : 'text-gray-500'}`} />
                                            </div>
                                            <div>
                                                <div className="flex items-center gap-2">
                                                    <span className="text-sm font-medium">{kb.name}</span>
                                                    <span className={`text-xs px-2 py-0.5 rounded ${kb.status === 'active' ? 'bg-green-50 text-green-700' : 'bg-gray-50 text-gray-700'}`}>
                                                        {kb.status}
                                                    </span>
                                                </div>
                                                <p className="text-xs text-muted-foreground">{kb.description}</p>
                                                <p className="text-xs text-gray-400">{kb.documentCount} documents</p>
                                            </div>
                                        </div>
                                        <Switch 
                                            checked={knowledgeBaseIds.includes(kb._id)} 
                                            onCheckedChange={() => toggleKnowledgeBase(kb._id)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </div>
                                </div>
                            ))}
                            
                            <Button
                                variant="outline"
                                className="w-full border-dashed mt-2"
                                onClick={() => window.open('/knowledge-base', '_blank')}
                            >
                                <Plus className="h-4 w-4 mr-2" />
                                Create Knowledge Base
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            
            <div className="flex justify-end gap-2 mt-6">
                <Button variant="outline" onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSave}>
                    Save Changes
                </Button>
            </div>
        </>
    );
};

export default AddAgentDialog; 