import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ChatContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: theme.spacing(2),
}));

export const MessagesContainer = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    overflowY: 'auto',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

export const MessageBubble = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isUser',
})<{ isUser: boolean }>(({ theme, isUser }) => ({
    backgroundColor: isUser ? theme.palette.primary.main : theme.palette.grey[100],
    color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(2),
    maxWidth: '70%',
    wordBreak: 'break-word',
}));

export const InputContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
}));

export const MarkdownContainer = styled(Box)(({ theme }) => ({
    '& p': {
        margin: 0,
    },
    '& pre': {
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        overflowX: 'auto',
    },
}));

export const DebugContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& pre': {
        margin: 0,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    },
})); 