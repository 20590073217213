import { BTPaperProps } from "@common-components/atoms/bt-paper/bt-paper";
import BTThemeProvider from "@common-components/bt-theme-provider";
import RowStack from "@common-components/common/row-stack/RowStack";
import MessageEditor from "@common-components/message-editor/message-editor";
import WSButton from "@common-components/ws-button/ws-button";
import { userMessagesSelectByQuery } from "@common-reducers/DBServiceThunks";
import { isOverLimitOfScheduledMessages } from "@common-reducers/UserMessageReducer";
import { setAddMsgInCalendarContact, setIsReply, setMentions } from "@common-reducers/WhatsAppReducer";
import { getIsSocketConnectedToWhatsApp, getWaContactDisplayLabel } from '@common-reducers/WhatsAppSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";
import { confirmationDialogOption, confirmationDialogOptions } from "@common-views/calendar/message-calendar";
import ConnectedEngines from "@common/components/connected-engines/connected-engines";
import SchedulePicker from "@common/components/schedule-picker/schedule-picker";
import { UserMessage } from "@common/models/user-message";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { theme } from "@common/theme";
import { handleRecurringMessageEditThunk, scheduleMessage } from "@common/thunks/UserMessagesThunks";
import { Schedule } from "@common/types/interface";
import ContactAvatar from "@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar";
import { Popup } from "@extension/context/popup/components/popup/popup";
import {
  Alert,
  CircularProgress,
  Dialog, DialogActions, DialogContent,
  IconButton,
  Slide
} from '@mui/material';
import { TransitionProps } from "@mui/material/transitions";
import { Box, Stack } from '@mui/system';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { BulkContact } from '../bulk-dashboard/contact-table/contact-table';
import { ContactSelect } from "./contact-select";
import { MessageConfirmationDialog } from "./message-confirmation-dialog";

// Types and Interfaces
interface WhatsAppId {
  _serialized: string;
  id?: string;
}

export interface WhatsAppContact {
  id: WhatsAppId;
  name?: string;
  displayName?: string;
  pushname?: string;
  imgUrl?: string;
}



interface ExtendedUserMessage extends UserMessage {
  contactList?: BulkContact[];
}

type AddScheduledMessageButtonProps = {
  isDisabled?: boolean;
  classes?: Record<string, string>;
};

export interface MessageContact {
  id: string;
  name?: string;
  displayName?: string;
  mobile?: string;
  imgUrl?: string;
}


// Transition component for Dialog
const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Hook for managing contact selection
const useContactSelection = (
  dispatch: ReturnType<typeof useAppDispatch>,
  message: ExtendedUserMessage,
  currentChat?: {
    id?: WhatsAppId;
    name?: string;
    displayName?: string;
    contact?: { name?: string }
  }
) => {
  useEffect(() => {
    const contactList = message.contactList;
    if (contactList?.length && contactList[0]?.id) {
      dispatch(setAddMsgInCalendarContact({
        name: contactList[0]?.name,
        id: contactList[0].id,
        displayName: contactList[0]?.name,
      }));
    } else if (currentChat?.id?._serialized) {
      dispatch(setAddMsgInCalendarContact({
        name: currentChat.name ?? currentChat.displayName ?? currentChat.contact?.name,
        id: currentChat.id._serialized,
        displayName: currentChat.contact?.name ?? currentChat.displayName,
      }));
    } else {
      dispatch(setAddMsgInCalendarContact(null));
    }
  }, [currentChat, message.contactList, dispatch]);
};


// Hook for managing poll validation
const usePollValidation = (message: any) => {
  return useCallback(() => {
    function haveSimilarOptions() {
      const options = (message?.poll?.options ?? []).filter((option) => option.text !== '');
      const uniqueOptions = [...new Set(options.map((option) => option.text))];
      return options.length !== uniqueOptions.length;
    }

    return message?.isPoll &&
      message?.poll?.question?.length > 0 &&
      message?.poll?.options?.length > 1 &&
      message?.poll?.options?.filter(o => o.text.length > 0).length > 1 &&
      !haveSimilarOptions();
  }, [message?.isPoll, message?.poll?.question, message?.poll?.options]);
};

// Component for contact search and selection




// Component for displaying selected contact
interface SelectedContactProps {
  contact: MessageContact;
  onRemove: () => void;
}

const SelectedContact: React.FC<SelectedContactProps> = ({ contact, onRemove }) => (
  <RowStack>
    <ContactAvatar
      size={24}
      textSize={12}
      contactId={contact.id}
      sx={{ m: '0px 8px 0px 10px' }}
    />
    <span style={{
      maxWidth: '190px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>
      {getWaContactDisplayLabel(contact)}
    </span>
    <IconButton
      onClick={onRemove}
      sx={{
        padding: '4px',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
      }}
    >
      <RiCloseCircleFill style={{ fontSize: '16px' }} />
    </IconButton>
  </RowStack>
);

// Alert components
const PastDateAlert = () => (
  <Alert severity="warning" sx={{
    alignItems: "center",
    boxShadow: "none",
    ".MuiAlert-message": {
      width: "100%",
      textAlign: "center",
      padding: "0"
    }
  }}>
    <div>Pay attention, The scheduled time has already passed.</div>
    <div>Please select a future date and time</div>
  </Alert>
);

// Main component
const AddScheduledMessage: React.FC<AddScheduledMessageButtonProps> = (props) => {
  const dispatch = useAppDispatch();
  const analyticsService: AnalyticsService = useAnalyticsService();
  const [isUpdatingLocalMessageState, setIsUpdatingLocalMessageState] = useState(false);

  // Selectors
  const {
    messageContact,
    currentChat,
    message,
    open,
    showConfirmationDialog,
    isWaSocketsLimitReached,
    isSocketConnectedToWhatsApp,
    messageList,
    isOverLimit,
    selectedEventDate
  } = useAppSelector(state => ({
    messageContact: state.WhatsAppReducer.addMsgInCalendarContact,
    currentChat: state.WhatsAppReducer.currentChat,
    // errorState: state.WhatsAppReducer.errorState,
    message: state.AddScheduledMessageButtonReducer.message,
    open: state.AddScheduledMessageButtonReducer.open,
    showConfirmationDialog: state.AddScheduledMessageButtonReducer.showConfirmationDialog,
    // devices: state.MainReducer.devices,
    isWaSocketsLimitReached: state.AddScheduledMessageButtonReducer.isWaSocketsLimitReached,
    isSocketConnectedToWhatsApp: getIsSocketConnectedToWhatsApp(state),
    messageList: userMessagesSelectByQuery(state.DBUserMessageReducer, {
      $or: [{ deleted: { $exists: false } }, { deleted: false }],
    }),
    isOverLimit: isOverLimitOfScheduledMessages(state),
    selectedEventDate: state.CalendarReducer.selectedEventDate
  }));

  // Custom hooks
  useContactSelection(dispatch, message, currentChat);

  const isPollValid = usePollValidation(message);

  const canSchedule = useMemo(() => {
    return message?.dueDate &&
      moment(message?.dueDate).isValid() &&
      messageContact?.id &&
      ((!message.isPoll && (message.message?.length > 0 || message?.asset?.url || message?.imageId)) ||
        (message.isPoll && isPollValid()));
  }, [message?.dueDate, message.isPoll, message.message?.length, message?.asset?.url, message?.imageId, messageContact?.id, isPollValid]);

  // Event handlers
  const handleClose = () => {
    dispatch(addScheduledMessageButtonActions.closeDialog());
    dispatch(addScheduledMessageButtonActions.setPollFromWAModal(null));
    dispatch(setAddMsgInCalendarContact(null));
    dispatch(setIsReply(null));
    dispatch(setMentions(null));
  };

  const handleScheduleChange = async (schedule: Schedule) => {
    setIsUpdatingLocalMessageState(true);
    await Promise.resolve(dispatch(addScheduledMessageButtonActions.updateMessage({
      ...message,
      ...schedule,
      image: undefined,
    })));
    setIsUpdatingLocalMessageState(false);
  };

  const handleMessageChange = async (msg: UserMessage) => {
    setIsUpdatingLocalMessageState(true);
    await Promise.resolve(dispatch(addScheduledMessageButtonActions.updateMessage({
      ...message,
      ...msg,
      isPoll: msg.isPoll,
      ...(msg.isPoll ? {} : { image: undefined })
    })));
    setIsUpdatingLocalMessageState(false);
  };

  const scheduleSend = () => {
    if (isUpdatingLocalMessageState) return;

    // Find the original message if we're editing one
    const originalMessage = message._id ? messageList.find((m) => m._id === message._id) : null;
    console.log('[SCHEDULE_SEND] Original message:', originalMessage);

    const current = {
      ...message,
      contactList: [{
        id: messageContact.id.replace('s.whatsapp.net', 'c.us'),
        name: messageContact.name,
        firstName: messageContact.name?.split(' ')[0],
        lastName: messageContact.name?.split(' ').slice(1).join(' ') || undefined
      }],
      ...(!message.isRecurring && {
        rruleset: undefined,
        recurenceUnit: undefined,
        recurrenceQuantity: undefined,
      })
    };

    // Show dialog if we're editing a message that was originally recurring
    if (message._id && originalMessage?.isRecurring) {
      dispatch(addScheduledMessageButtonActions.setConfirmationDialog(true));
    } else {
      dispatch(scheduleMessage(current));
      handleClose();
    }
  };

  const onEditRecurringDialogClose = (selection?: confirmationDialogOption) => {
    if (!selection) {
      handleClose();
      return;
    }

    let current = {
      ...message,
      contactList: [{
        id: messageContact.id.replace('s.whatsapp.net', 'c.us'),
        name: messageContact.name,
        firstName: messageContact.name?.split(' ')[0],
        lastName: messageContact.name?.split(' ').slice(1).join(' ') || undefined
      }]
    };

    const original = messageList.find((m) => m._id === current._id);
    if (!original?.rruleset) {
      console.log('[EDIT_RECURRING] No original message or rruleset found');
      handleClose();
      return;
    }

    try {
      const scope = selection === "All messages" ? "all"
        : selection === "This message" ? "this"
          : "thisAndFollowing";

      dispatch(handleRecurringMessageEditThunk({
        currentMessage: current,
        originalMessage: original,
        draggedDate: new Date(selectedEventDate),
        newDate: new Date(current.dueDate),
        scope
      }));
    } catch (error) {
      console.error('[EDIT_RECURRING] Error handling edit:', error);
      throw error;
    }

    handleClose();
  };

  return (
    <BTThemeProvider>
      {open && isOverLimit && (
        <Dialog
          dir="ltr"
          TransitionComponent={DialogTransition}
          open={true}
          onClose={handleClose}
        >
          <Popup
            headerTitle="Only 1 message at a time"
            headerSubtitle="Go premium to unlock unlimited messages"
            onClose={handleClose}
          />
        </Dialog>
      )}

      <Dialog
        dir="ltr"
        fullWidth={true}
        PaperProps={{
          sx: {
            maxWidth: 700,
            ...BTPaperProps,
          },
        }}
        open={open && !isOverLimit}
        TransitionComponent={DialogTransition}
        onClose={handleClose}
      >
        <Stack sx={{ padding: "0" }}>
          {moment.utc(message?.dueDate).isBefore(moment.utc().subtract(1, "minute")) && (
            <PastDateAlert />
          )}
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" 
          sx={{ height: '45px', p: '20px 24px', pb: 0 }}>
          <span>Schedule a message to</span>
          <Box sx={{ ml: 1 }}>
            {messageContact ? (
              <SelectedContact
                contact={messageContact}
                onRemove={() =>
                  dispatch(setAddMsgInCalendarContact(null))
                }
              />
            ) : (
              <ContactSelect />
            )}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: '45px', my: 2 }}>
          <ConnectedEngines />
        </Stack>
        <DialogContent>
          <form className={props.classes?.container} noValidate>
            <SchedulePicker
              showRecurrence={true}
              showCancelIfReceived={true}
              schedule={message ?? {}}
              handleScheduleChange={handleScheduleChange}
            />
            <Stack>
              <MessageEditor
                showTemplateButtons={false}
                value={message}
                onChange={handleMessageChange}
                editorContext='scheduleMessage'
              />
            </Stack>
            <DialogActions>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={theme.spacing(1)}
                  sx={{
                    width: "100%",
                    px: 2,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <WSButton sx={{ flex: "0 0 150px" }} onClick={handleClose}>
                    Cancel
                  </WSButton>
                  <WSButton
                    sx={{
                      flex: "0 0 250px",
                      minHeight: '36px',
                      position: 'relative'
                    }}
                    onClick={() => {
                      analyticsService.event("first_action", {
                        category: "firstAction",
                        action: "first action",
                      });
                      scheduleSend();
                    }}
                    disabled={!canSchedule || isUpdatingLocalMessageState}
                    variant="contained"
                    color="primary"
                  >
                    {isUpdatingLocalMessageState &&
                      <>
                        <CircularProgress
                          size={20}
                          sx={{
                            position: 'absolute',
                            left: '50%',
                            marginLeft: '-10px',
                            color: 'inherit'
                          }}
                        />
                        <span style={{ visibility: 'hidden' }}>Schedule Send</span>
                      </>
                    }
                    {!isUpdatingLocalMessageState &&
                      'Schedule Send'
                    }
                  </WSButton>
                </Stack>
              </Stack>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {showConfirmationDialog && (
        <MessageConfirmationDialog
          title="Edit recurring message"
          onClose={onEditRecurringDialogClose}
          options={confirmationDialogOptions}
        />
      )}
    </BTThemeProvider>
  );
};

export default AddScheduledMessage;
