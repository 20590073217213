import { Button } from "@mui/material";
import { SxProps } from '@mui/system';
import React, { cloneElement, forwardRef } from "react";

// Define an interface for the BTbotbutton props
interface BTbotbuttonProps {
    disabled?: boolean;
    styleType?: 'full' | 'empty' | 'dashed'; // Add more types as needed
    size?: 'small' | 'medium' | 'large'; // Add more sizes as needed
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    sx?: SxProps; // Allow additional styles to be passed
    startIcon?: React.ReactNode;
}

// Define the styles for different button types
const buttonStyles: Record<NonNullable<BTbotbuttonProps['styleType']>, SxProps> = {
    full: {
        backgroundColor: '#111828',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(17, 24, 39, 0.9)',
        },
        '&.Mui-disabled': {
            color: '#666',
            backgroundColor: 'rgb(231, 231, 231)'
        }
    },
    empty: {
        backgroundColor: '#ffffff',
        border: '2px solid #ebedf0',
        color: 'rgb(55, 65, 81)',
        '&:hover': {
            backgroundColor: '#f3f4f6',
        },
    },
    dashed: {
        backgroundColor: '#ffffff',
        border: '2px dashed #ebedf0',
        color: 'rgb(55, 65, 81)',
        '&:hover': {
            backgroundColor: '#f3f4f6',
        },
    },
};

const sizeStyles: Record<NonNullable<BTbotbuttonProps['size']>, SxProps> = {
    small: {
        height: '32px'
    },
    medium: {
        height: '40px',
        minWidth: '100px'
    },
    large: {
        height: '50px'
    }
};

const BTbotbutton = forwardRef<HTMLButtonElement, BTbotbuttonProps>(({ 
    styleType = 'full', 
    startIcon, 
    children, 
    sx, 
    ...props 
}, ref) => {
    const modifiedStartIcon = startIcon ? cloneElement(startIcon as React.ReactElement, {
        sx: { fontSize: 20 }
    }) : null;

    const combinedSx: SxProps = {
        borderRadius: '6px',
        fontSize: '13px',
        textTransform: 'none',
        ...buttonStyles[styleType],
        ...sizeStyles[props.size || 'medium'],
        ...sx, // Allow additional styles to override default ones
        '& .MuiButton-startIcon': {
            marginRight: '4px',
        },
    };

    return (
        <Button
            {...props}
            ref={ref}
            startIcon={modifiedStartIcon}
            sx={combinedSx} // Merge styles
        >
            {children}
        </Button>
    );
});

BTbotbutton.displayName = 'BTbotbutton';

export default BTbotbutton;
