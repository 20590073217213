import { getIsFromNodeEventSelector } from '@common-reducers/ChatbotSelectors';
import { focusNodeEventsThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { NodeType } from '@common/models/bot-node';
import { Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import classes from "../bt-chatbot.module.scss";
import { nodeTypes, secondaryNodesThatLookLikeMainNodes } from './bt-nodes-def';
import NodeHeader from './node-header';
import { ReactNode } from 'react';

type Props = {
    id?: string;
    type?: NodeType;
    title?: string;
    color?: string;
    icon?: any;
    nodeId?: string;
    children?: ReactNode;
}

export default function NodeContainer({ 
    id, 
    type, 
    title, 
    color, 
    icon, 
    nodeId, 
    children 
}: Props) {
    const dispatch = useAppDispatch();
    const { sessionId } = useParams<{ sessionId: string }>();
    
    // Use provided id or nodeId
    const nodeIdentifier = id || nodeId;
    
    // Only check for events if we have a valid node identifier
    const isFromNodeEvent = useAppSelector(state => getIsFromNodeEventSelector(state, nodeIdentifier, sessionId))
    
    const isReadOnly = useAppSelector(state => state.ChatbotReducer.isReadOnly);

    // Use provided values or get from nodeTypes if type is provided
    const NodeContainerByType = type ? nodeTypes?.[type]?.container : null;
    const isMainNode = type ? 
        (nodeTypes?.[type]?.isMainNode || secondaryNodesThatLookLikeMainNodes.includes(type)) : 
        false;
    const nodeColor = color || (type ? nodeTypes?.[type]?.color : '#000000');
    const nodeTitle = title || (type ? nodeTypes?.[type]?.title : '');

    const handleNodeClick = () => {
        if (isReadOnly && isFromNodeEvent && nodeIdentifier) {
            dispatch(focusNodeEventsThunk(nodeIdentifier));
        }
    };

    const content = (
        <Stack
            className={isMainNode ? classes.main_node_wrapper : classes.secondary_node_wrapper}
            sx={{
                border: isMainNode ? 0 : `2px solid ${nodeColor}`,
                cursor: isReadOnly ? 'pointer' : 'default'
            }}
            onClick={handleNodeClick}
        >
            {type ? (
                <NodeHeader type={type} id={nodeIdentifier || ''} />
            ) : (
                <div className="flex items-center justify-between p-2 border-b border-gray-200 bg-gray-50">
                    <div className="flex items-center space-x-2">
                        {icon && <span className="text-indigo-600">{icon}</span>}
                        <span className="font-medium text-gray-800">{nodeTitle}</span>
                    </div>
                </div>
            )}
            <Stack style={{ padding: isMainNode && '10px 10px 15px', }}>
                {NodeContainerByType && nodeIdentifier ? (
                    <NodeContainerByType id={nodeIdentifier} />
                ) : (
                    children
                )}
            </Stack>
        </Stack>
    );

    if (isReadOnly && isFromNodeEvent && nodeIdentifier) {
        return (
            <Tooltip title="Click to view related events in the timeline" arrow placement="top">
                {content}
            </Tooltip>
        );
    }

    return content;
}