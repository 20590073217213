import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { namespace } from "@common-config/constants";
import {
  selectCreditPackages,
  selectError,
  selectIsLoading,
  selectSelectedPackageId,
  setSelectedPackage
} from '@common-reducers/CreditPurchaseReducer';
import {
  CreditPackage,
  fetchCreditPackagesThunk,
  purchaseCreditPackageThunk
} from '@common-reducers/CreditPurchaseThunk';
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { activeSubscriptionSelector } from '@common-reducers/UserSelectors';
import { Check, Info, AlertTriangle, Loader2 } from 'lucide-react';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { selectCreditsData, fetchCreditUsage, selectIsLoading as selectCreditUsageIsLoading } from '@common-reducers/CreditUsageReducer';

interface AddCreditsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

type BillingCycle = 'monthly' | 'annual';

const AddCreditsModal: React.FC<AddCreditsModalProps> = ({ open, onOpenChange }) => {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch();
  
  // Get active subscription info
  const activeSubscription = useAppSelector(activeSubscriptionSelector);
  // Get current credit usage data
  const creditsData = useAppSelector(selectCreditsData);
  const creditUsageIsLoading = useAppSelector(selectCreditUsageIsLoading);
  
  // Determine subscription billing cycle
  const subscriptionBillingCycle = useMemo((): BillingCycle | null => {
    if (!activeSubscription) return null;
    
    // Check subscription plan_id or other properties to determine billing cycle
    // This will depend on how your subscription data is structured
    const planId = activeSubscription.plan_id?.toLowerCase() || '';
    if (planId.includes('annual') || planId.includes('yearly')) {
      return 'annual';
    } else if (planId.includes('monthly')) {
      return 'monthly';
    }
    
    // Default to monthly if can't determine
    return 'monthly';
  }, [activeSubscription]);
  
  // State for billing cycle - initialize with subscription billing cycle if available
  const [billingCycle, setBillingCycle] = useState<BillingCycle>(subscriptionBillingCycle || 'monthly');
  
  // Update billing cycle if subscription changes
  useEffect(() => {
    if (subscriptionBillingCycle) {
      setBillingCycle(subscriptionBillingCycle);
    }
  }, [subscriptionBillingCycle]);
  
  // Use Redux state with fallbacks for safety
  const creditPackages = useAppSelector(selectCreditPackages);
  const selectedPackageId = useAppSelector(selectSelectedPackageId);
  const isLoading = useAppSelector(selectIsLoading) || false;
  const error = useAppSelector(selectError);
  
  // Find selected package and billing cycle details
  const selectedPackage = creditPackages.find(pkg => {
    const monthlyId = pkg.monthly?.id;
    const annualId = pkg.annual?.id;
    return (billingCycle === 'monthly' && monthlyId === selectedPackageId) || 
           (billingCycle === 'annual' && annualId === selectedPackageId);
  });
  
  // Fetch credit packages and usage data when the modal opens
  useEffect(() => {
    if (open) {
      dispatch(fetchCreditPackagesThunk());
      
      // Also fetch credit usage data if not loaded or stale
      const isCreditDataEmpty = !creditsData || 
        creditsData.totalCredits === 0 && 
        creditsData.creditsUsed === 0 && 
        creditsData.creditsRemaining === 0;
      
      if (isCreditDataEmpty) {
        // Default to 30 days timeframe
        dispatch(fetchCreditUsage('30days'));
      }
    }
  }, [dispatch, open, creditsData]);
  
  // When packages load or billing cycle changes, select the first package
  useEffect(() => {
    if (creditPackages.length > 0) {
      // Try to find the current package in the new billing cycle
      let matchingPackage: CreditPackage | undefined;
      
      if (selectedPackage) {
        // If we already have a selected package, try to find the same credit amount
        matchingPackage = creditPackages.find(pkg => pkg.credits === selectedPackage.credits);
      }
      
      // If no matching package or no previously selected package, prioritize the popular one
      if (!matchingPackage) {
        // First try to find the package with 200 credits (medium) which should be popular
        matchingPackage = creditPackages.find(pkg => pkg.credits === 200);
        
        // Then look for a package marked as popular
        if (!matchingPackage) {
          matchingPackage = creditPackages.find(pkg => pkg.popular);
        }
        
        // Fallback to the first package if nothing else works
        if (!matchingPackage) {
          matchingPackage = creditPackages[0];
        }
      }
      
      // Select the appropriate ID based on billing cycle
      if (matchingPackage) {
        const packageId = billingCycle === 'monthly' 
          ? matchingPackage.monthly?.id 
          : matchingPackage.annual?.id;
        
        if (packageId) {
          dispatch(setSelectedPackage(packageId));
        }
      }
    }
  }, [creditPackages, billingCycle, dispatch, selectedPackage]);

  // Handle package selection
  const handleSelectPackage = useCallback((pkg: CreditPackage) => {
    const packageId = billingCycle === 'monthly' 
      ? pkg.monthly?.id 
      : pkg.annual?.id;
    
    if (packageId) {
      dispatch(setSelectedPackage(packageId));
    }
  }, [dispatch, billingCycle]);

  // Callback for continuing to checkout
  const handleContinueToCheckout = useCallback(async () => {
    if (!selectedPackageId) return;
    
    try {
      // Dispatch the purchase thunk
      const result = await dispatch(purchaseCreditPackageThunk({
        packageId: selectedPackageId,
        quantity: 1 // Default to 1, could be adjustable in the future
      })).unwrap();
      
      // If successful, redirect to the checkout URL
      if (result && result.checkoutUrl) {
        window.location.href = result.checkoutUrl;
      }
    } catch (err) {
      console.error('Error during checkout:', err);
    }
  }, [selectedPackageId, dispatch]);

  // Discount calculation for annual billing
  const getAnnualDiscount = (): number => {
    // Calculate average discount across packages
    if (creditPackages.length === 0) return 15; // Default 15% if no packages
    
    const packagesWithBothCycles = creditPackages.filter(
      pkg => pkg.monthly && pkg.annual
    );
    
    if (packagesWithBothCycles.length === 0) return 15;
    
    const totalDiscount = packagesWithBothCycles.reduce((sum, pkg) => {
      if (!pkg.monthly || !pkg.annual) return sum;
      
      const monthlyAnnualized = pkg.monthly.price * 12;
      const annualPrice = pkg.annual.price;
      const discount = ((monthlyAnnualized - annualPrice) / monthlyAnnualized) * 100;
      
      return sum + discount;
    }, 0);
    
    return Math.round(totalDiscount / packagesWithBothCycles.length);
  };

  const annualDiscount = getAnnualDiscount();
  
  // Check if user has an active subscription
  const hasActiveSubscription = Boolean(activeSubscription);
  
  // Check if billing cycle matches subscription billing cycle
  const billingCycleMatchesSubscription = !subscriptionBillingCycle || billingCycle === subscriptionBillingCycle;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[480px] p-4 z-[1500]">
        <style>{`
          [data-radix-popper-content-wrapper] {
            z-index: 1500 !important; 
          }
          
          [data-slot="dialog-overlay"] {
            z-index: 1400 !important;
          }
          
          @keyframes shimmer {
            0% {
              background-position: -468px 0;
            }
            100% {
              background-position: 468px 0;
            }
          }
          
          .shimmer {
            background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
            background-size: 800px 104px;
            animation: shimmer 1.5s infinite linear;
          }
          
          /* Hide billing cycle heading */
          .hide-billing-cycle {
            display: block;
            margin: 12px 0 8px;
            text-align: center;
            width: 100%;
            position: relative;
            z-index: 5;
          }
          
          .hide-billing-cycle::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 20px;
            background: white;
            z-index: -1;
            top: -5px;
          }
        `}</style>
        <DialogHeader className="pb-1">
          <DialogTitle className="text-xl font-semibold">{t('credits.addCredits', 'Add Credits')}</DialogTitle>
          <DialogDescription className="text-xs">
            {t('credits.choosePackageDescription', 'Choose a credit package to add to your account. Credits will be available immediately after purchase.')}
          </DialogDescription>
        </DialogHeader>
        
        {/* Current Subscription and Credits Information */}
        {hasActiveSubscription && (
          <div className="mb-3 mt-1">
            <Card className="bg-gray-50">
              <CardContent className="p-3">
                <div className="space-y-2 text-sm">
                  <div className="flex items-start border-b pb-1.5">
                    <span className="font-medium text-gray-700 whitespace-nowrap mr-1 mt-0.5">{t('credits.currentSubscription', 'Current Subscription')}:</span>
                    <span className="text-blue-600 font-medium text-right flex-1 break-words">
                      {activeSubscription?.planName || t('credits.unavailable', 'Unavailable')}
                      {subscriptionBillingCycle && (
                        <span className="text-gray-500 ml-1 whitespace-nowrap">({subscriptionBillingCycle === 'monthly' ? 
                          t('credits.monthlyLower', 'monthly') : 
                          t('credits.annualLower', 'annual')})</span>
                      )}
                    </span>
                  </div>
                  {creditUsageIsLoading ? (
                    <div className="grid grid-cols-2 gap-x-4 gap-y-1.5 text-xs">
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.totalCredits', 'Total Credits')}:</span>
                        <span className="w-10 h-4 shimmer rounded"></span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.usedCredits', 'Used Credits')}:</span>
                        <span className="w-10 h-4 shimmer rounded"></span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.remainingCredits', 'Remaining')}:</span>
                        <span className="w-10 h-4 shimmer rounded"></span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.renewal', 'Renewal')}:</span>
                        <span className="w-16 h-4 shimmer rounded"></span>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 gap-x-4 gap-y-1.5 text-xs">
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.totalCredits', 'Total Credits')}:</span>
                        <span className="font-medium">{creditsData.totalCredits}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.usedCredits', 'Used Credits')}:</span>
                        <span className="font-medium">{creditsData.creditsUsed}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.remainingCredits', 'Remaining')}:</span>
                        <span className="font-medium text-blue-600">{creditsData.creditsRemaining}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-500">{t('credits.renewal', 'Renewal')}:</span>
                        <span className="font-medium">{creditsData.renewalDate ? 
                          creditsData.renewalDate : 
                          t('credits.notAvailable', 'N/A')}</span>
                      </div>
                    </div>
                  )}
                  
                  {/* Credit recommendation for low credit users */}
                  {!creditUsageIsLoading && (
                    <>
                      {creditsData.creditsRemaining === 0 && (
                        <div className="mt-1 pt-1 border-t text-xs text-amber-700 flex items-center">
                          <AlertTriangle className="h-3 w-3 mr-1 flex-shrink-0" />
                          <span>{t('credits.noCreditsRemaining', 'You have no credits remaining. Purchase credits to continue using premium features.')}</span>
                        </div>
                      )}
                      {creditsData.creditsRemaining > 0 && creditsData.percentageUsed > 90 && (
                        <div className="mt-1 pt-1 border-t text-xs text-amber-700 flex items-center">
                          <AlertTriangle className="h-3 w-3 mr-1 flex-shrink-0" />
                          <span>{t('credits.lowCreditsRemaining', 'You have very few credits remaining. Consider purchasing additional credits soon.')}</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        
        {/* Recommendation for users without active subscription */}
        {!hasActiveSubscription && (
          <Alert className="my-1 py-2 bg-amber-50 border-amber-200">
            <AlertTriangle className="h-4 w-4 text-amber-500" />
            <AlertDescription className="text-amber-700 text-xs">
              {t('credits.noActiveSubscription', 'You need an active subscription to purchase credits. Please subscribe to a plan first.')}
            </AlertDescription>
          </Alert>
        )}
        
        {error && (
          <Alert className="my-1 py-1.5 bg-red-50 border-red-200">
            <AlertDescription className="text-red-700 text-xs">
              {error}
            </AlertDescription>
          </Alert>
        )}
        
        {/* Billing cycle mismatch warning */}
        {hasActiveSubscription && subscriptionBillingCycle && !billingCycleMatchesSubscription && (
          <Alert className="my-1 py-1.5 bg-amber-50 border-amber-200">
            <AlertTriangle className="h-4 w-4 text-amber-500" />
            <AlertDescription className="text-amber-700 text-xs">
              {t('credits.billingCycleMismatch', 'The selected billing cycle must match your subscription billing cycle. Your current subscription is {{cycle}}.', 
                { cycle: subscriptionBillingCycle === 'monthly' ? 'monthly' : 'annual' })}
            </AlertDescription>
          </Alert>
        )}
        
        {/* Billing cycle toggle - only show if no subscription or if subscription doesn't specify a cycle */}
        {(!hasActiveSubscription || !subscriptionBillingCycle) && (
          <div className="flex justify-center my-3">
            <div className="inline-flex items-center bg-gray-100 p-0.5 rounded-lg text-sm">
              <button 
                className={`px-4 py-1.5 rounded-md transition-colors ${
                  billingCycle === 'monthly' 
                    ? 'bg-white shadow-sm text-gray-800 font-medium' 
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setBillingCycle('monthly')}
              >
                {t('credits.monthly', 'Monthly')}
              </button>
              <button 
                className={`px-4 py-1.5 rounded-md transition-colors ${
                  billingCycle === 'annual' 
                    ? 'bg-white shadow-sm text-gray-800 font-medium' 
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setBillingCycle('annual')}
              >
                {t('credits.annual', 'Annual')} ({annualDiscount}% {t('credits.off', 'off')})
              </button>
            </div>
          </div>
        )} 
        
        {/* NOTE: There should be no "Current billing cycle: Monthly" text here. 
            If it appears, it might be coming from a hard-coded string or translation in a parent component. */}
        
        {/* Add a subheading for the packages section that includes the billing cycle */}
        {hasActiveSubscription && (
          <div className="hide-billing-cycle">
            <div className="text-sm text-gray-700 font-medium bg-white px-2">
              {subscriptionBillingCycle === 'monthly' ? 
                t('credits.packagesBillingCycleMonthly', 'Credit packages (billed monthly)') : 
                t('credits.packagesBillingCycleAnnual', 'Credit packages (billed annually)')}
            </div>
          </div>
        )}
        
        {creditPackages.length > 0 ? (
          <div className="grid grid-cols-2 gap-3 my-2 packages-grid" data-hide-billing-cycle-text="true">
            {creditPackages.map((pkg) => {
              // Get pricing for current billing cycle
              const pricing = billingCycle === 'monthly' ? pkg.monthly : pkg.annual;
              
              // Skip if this package doesn't have pricing for the selected cycle
              if (!pricing) return null;
              
              const isSelected = selectedPackageId === pricing.id;
              
              // Fixed popular logic - checking the package's popular property directly
              // Adding fallback to mark 200 credit package as popular if no package has popular=true
              const isPopular = pkg.popular === true || (creditPackages.every(p => p.popular !== true) && pkg.credits === 200);
              
              return (
                <div key={pricing.id} className="h-[90px]">
                  <Card
                    className={`
                      h-full w-full px-3 pt-1.5 pb-2 cursor-pointer relative border-2 shadow-sm rounded-lg overflow-visible box-border
                      ${isSelected
                        ? 'border-blue-600 bg-blue-50' 
                        : 'border-gray-200 hover:border-gray-300 hover:shadow'
                      }
                      ${(!hasActiveSubscription || !billingCycleMatchesSubscription) ? 'opacity-70 pointer-events-none' : ''}
                    `}
                    style={{ transition: 'background-color 0.2s, border-color 0.2s', position: 'relative', zIndex: 1 }}
                    onClick={() => handleSelectPackage(pkg)}
                  >
                    {isPopular && (
                      <div className="absolute -top-2 right-2 bg-blue-600 text-white text-xs px-2 py-0.5 rounded-full font-medium shadow-sm z-[50]">
                        <span className="flex items-center">
                          <svg className="w-3 h-3 mr-0.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118l-2.799-2.034c-.784-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          {t('credits.popular', 'Popular')}
                        </span>
                      </div>
                    )}
                    
                    {/* Main content */}
                    <div className="flex flex-col justify-between h-full">
                      {/* Credits row */}
                      <div className="flex items-baseline">
                        <div className="text-xl font-bold leading-tight">{pkg.credits}</div>
                        <div className="text-gray-500 text-xs ml-1">
                          {billingCycle === 'monthly' ? 
                            t('credits.creditsPerMonth', 'credits per month') : 
                            t('credits.creditsPerMonthAnnual', 'credits per month (annual plan)')}
                        </div>
                      </div>
                      
                      {/* Price row */}
                      <div className="flex items-baseline">
                        <div className="text-base font-medium leading-tight">${pricing.price.toFixed(2)}</div>
                        <div className="text-gray-500 text-xs ml-1">
                          (${pricing.perCredit.toFixed(3)}/{t('credits.perCreditLabel', 'credit')})
                        </div>
                      </div>
                      
                      {/* Additional info */}
                      <div className="text-xs">
                        {pkg.description && !pkg.description.includes(pkg.credits.toString()) && (
                          <div className="text-gray-600 line-clamp-1">
                            {pkg.description}
                          </div>
                        )}
                        
                        {pkg.savePercentage && (
                          <div className="text-green-600">
                            {t('credits.savePercentage', 'Save {{percentage}}%', { percentage: pkg.savePercentage })}
                          </div>
                        )}
                      </div>
                    </div>
                    
                    {isSelected && (
                      <div className="absolute bottom-1.5 right-2 text-blue-600">
                        <Check className="h-4 w-4" />
                      </div>
                    )}
                  </Card>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center h-32 my-4">
            {isLoading ? (
              <p>{t('credits.loading', 'Loading packages...')}</p>
            ) : (
              <p>{t('credits.noPackages', 'No credit packages available')}</p>
            )}
          </div>
        )}

        <Alert className="bg-blue-50 border-blue-200 my-2 py-1.5">
          <Info className="h-4 w-4 text-blue-500" />
          <AlertDescription className="text-blue-700 text-xs">
            {t('credits.creditsPolicyInfo', 'Credits are non-refundable and will expire according to your subscription terms. See our terms of service for details.')}
          </AlertDescription>
        </Alert>
        
        <DialogFooter className="flex items-center justify-end space-x-3 mt-4">
          <DialogClose asChild>
            <Button variant="outline" size="sm" className="h-10 text-sm px-4">
              {t('common.cancel', 'Cancel')}
            </Button>
          </DialogClose>
          <Button 
            variant="default" 
            size="sm"
            className="h-10 text-sm px-5 bg-blue-600 hover:bg-blue-700"
            onClick={handleContinueToCheckout}
            disabled={isLoading || !selectedPackageId || !hasActiveSubscription || !billingCycleMatchesSubscription}
          >
            {isLoading ? t('credits.processing', 'Processing...') : t('credits.continueToCheckout', 'Continue to Checkout')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddCreditsModal; 