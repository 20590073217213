import { Box, Typography, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ThinkingResponse } from './types';
import { MarkdownContainer, MessageBubble } from './styled-components';

interface StreamSelectorProps {
    text: string;
    thinking?: ThinkingResponse[];
}

export function StreamSelector({ text, thinking }: StreamSelectorProps) {
    const [isThinkingExpanded, setIsThinkingExpanded] = React.useState(false);

    const renderThinkingContent = () => {
        if (!thinking?.length) return null;

        return (
            <Box sx={{ mb: 2 }}>
                <Box 
                    onClick={() => setIsThinkingExpanded(!isThinkingExpanded)}
                    sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    <IconButton size="small">
                        {isThinkingExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    <Typography variant="body2" color="text.secondary">
                        {isThinkingExpanded ? 'Hide thinking process' : 'Show thinking process'}
                    </Typography>
                </Box>

                <Collapse in={isThinkingExpanded}>
                    <Box sx={{ pl: 2, borderLeft: '2px solid #e0e0e0' }}>
                        {thinking.map((thought, index) => (
                            <Box 
                                key={`thinking-${index}`}
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'flex-start',
                                    mb: 1,
                                    p: 1.5,
                                    borderRadius: 1,
                                    border: '1px solid #e0e0e0',
                                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                    color: 'text.secondary',
                                    fontSize: '0.875rem'
                                }}
                            >
                                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                                    {thought.content}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Collapse>
            </Box>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {renderThinkingContent()}
            <MessageBubble isUser={false}>
                <MarkdownContainer>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {text}
                    </ReactMarkdown>
                </MarkdownContainer>
            </MessageBubble>
        </Box>
    );
} 