import RowStack from '@common-components/common/row-stack/RowStack';
import { getTaskSumByBoardIdRecord, selectCampaignNumberOfContactsByCampaignId, selectCampaignPendingMessageCountByCampaignId } from '@common-reducers/CampaignSelectors';
import { campaignsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { Stack } from '@mui/material';
import TemplateRowDetails from './template-row-details';

type Props = {
    campaignId: string;
    width: string;
    showSentProgress: boolean;
}

export default function CampaignInformation({ campaignId, width, showSentProgress }: Props) {
    const campaign = useAppSelector(state => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, {
        $or: [{ deleted: { $exists: false } }, { deleted: false }],
        _id: campaignId
    }));
    const numberOfContacts = useAppSelector(state => selectCampaignNumberOfContactsByCampaignId(state, campaign?._id))
    const numberOfSentMessages = useAppSelector(state => selectCampaignPendingMessageCountByCampaignId(state, campaign?._id))
    const taskSumByBoardIdRecord = useAppSelector(getTaskSumByBoardIdRecord);


    const totalNumberOfContacts = () => {
        if (campaign?.contactCount) {
            return campaign?.contactCount
        }
        else if (campaign?.list && campaign?.list?.contactIdList) {
            return campaign?.list?.contactIdList?.length
        }
        else {
            return (campaign?.boardIDArray?.reduce((sum, id) => sum + taskSumByBoardIdRecord[id], 0) || 0)
        }
    };

    const formatISOCampaignCreationTime = (isoString) => isoString ?
        `Started ${new Date(isoString).toLocaleDateString('en-GB')} - ${new Date(isoString).toLocaleTimeString('en-GB', { hour12: false })}`
        : 'Starting time unavailable to display';




    return (
        <Stack sx={{
            width: width,
            height: '100%',
        }}>
            <RowStack sx={{ marginBottom: '5px' }}>
                <span style={{ fontSize: '18px', }}>{campaign?.name}</span>
                <span style={{ fontSize: '12px', fontWeight: 600, color: '#a6a6a6', marginRight: "5px" }}>
                    {formatISOCampaignCreationTime(campaign?.schedule?.dueDate)}
                </span>
                <span style={{
                    color: 'var(--campaign-dialog-green-text)',
                    fontWeight: 600,
                    fontSize: '12px'
                }}>
                    {isNaN(totalNumberOfContacts()) ? 'Unknown number of contacts' : `${totalNumberOfContacts()} contacts`}
                </span>
            </RowStack>

            {/* Template Details */}
            <TemplateRowDetails template={campaign?.template ?? {}} />
            {showSentProgress && numberOfContacts &&
                <span style={{ marginTop: '5px', }}>{numberOfSentMessages}/{numberOfContacts} messages sent</span>
            }
        </Stack>
    );
}
