import { SideBarItem } from "@common-components/sidebar/sidebar";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import { namespace } from '@common-config/constants';
import { useAnalyticsService } from '@common-reducers/hooks/use-analytics-service';
import { useSafeLocation } from '@common/services/utils';
import { useRouter } from '@common-reducers/hooks/use-router';

interface Props {
    to: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; };
    title: string;
    active: SideBarItem;
    currentActive?: SideBarItem;
    analyticsAction: string;
    analyticsCategory?: string;
    testId?: string;
    marginTop?: string;
}

const StyledLink = styled(Link)<{ marginTop?: string }>(({ theme, marginTop }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    textDecoration: 'none',
    color: '#667781',
    borderRadius: '8px',
    gap: '12px',
    marginTop,
    fontSize: '14px',
    fontWeight: 400,
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: '#E8F5FE !important',
        color: '#0088CC !important',
        '& .MuiSvgIcon-root': {
            color: '#0088CC !important',
        }
    },

    '&.active': {
        backgroundColor: '#f5f5f5',
        color: '#667781',
        '& .MuiSvgIcon-root': {
            color: '#667781',
        }
    }
}));

const IconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
        fontSize: 20,
        color: 'inherit'
    }
});

const TextWrapper = styled('span')({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export function SidebarLinkButton({ 
    to, 
    icon: Icon, 
    title, 
    active, 
    currentActive, 
    analyticsAction,
    analyticsCategory = 'myAccountSidebar',
    testId,
    marginTop 
}: Props) {
    const { t } = useTranslation(namespace);
    const analyticsService = useAnalyticsService();
    const location = useSafeLocation();
    const router = useRouter();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (analyticsAction) {
            analyticsService.event(analyticsAction, {
                category: analyticsCategory,
                action: analyticsAction,
            });
        }
        router.navigate(`${to}${location.search}`);
    };

    return (
        <StyledLink
            to={to}
            className={active === currentActive ? 'active' : ''}
            data-analytics-action={analyticsAction}
            data-testid={testId}
            marginTop={marginTop}
            onClick={handleClick}
        >
            <IconWrapper>
                <Icon />
            </IconWrapper>
            <TextWrapper>{t(title)}</TextWrapper>
        </StyledLink>
    );
} 