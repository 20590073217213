import { Frequency, RRule, RRuleSet, rrulestr } from 'rrule';

export interface RecurrenceHandlerResult {
  originalAction: 'patch' | 'delete';
  originalNewRRule?: string;
  originalDueDate: string;

  newAction: 'create' | 'none';
  newRRule?: string;
  newDueDate?: string;
}

type Scope = 'this' | 'thisAndFollowing' | 'all';

export class RRuleRecurrenceHandler {
  private static nullableKeys = [
    'until', 'tzid', 'bysetpos', 'bymonth', 'byyearday',
    'byweekno', 'bynweekday', 'byeaster', 'count'
  ];

  private static getWeekday(date: Date): number {
    const jsDay = date.getUTCDay();
    // In RRule, MO=0, TU=1, WE=2, TH=3, FR=4, SA=5, SU=6
    // In JavaScript, SU=0, MO=1, TU=2, WE=3, TH=4, FR=5, SA=6
    // So we need to convert from JavaScript to RRule
    return jsDay === 0 ? 6 : jsDay - 1;
  }

  private static cleanRuleOptions(options: any): any {
    const cleanedOptions = { ...options };

    // Delete any keys that are null, undefined, or empty arrays
    this.nullableKeys.forEach(key => {
      if (cleanedOptions[key] == null ||
        (Array.isArray(cleanedOptions[key]) && cleanedOptions[key].length === 0)) {
        delete cleanedOptions[key];
      }
    });

    // Remove bynweekday as it's not a valid RRULE property
    delete cleanedOptions.bynweekday;

    return cleanedOptions;
  }

  private static formatRuleSetToString(rules: RRule[], exdates: Date[]): string {
    if (!rules.length) return '';

    const rule = rules[0];

    const options = this.cleanRuleOptions(rule.options);

    // Get the RRULE string without DTSTART
    const rruleOptions = { ...options };
    delete rruleOptions.dtstart;  // Remove dtstart from RRULE part
    const rruleString = new RRule(rruleOptions).toString();

    // Build the complete string
    let result = '';

    // Add DTSTART if it exists
    if (options.dtstart) {
      const dtstart = new Date(Date.UTC(
        options.dtstart.getUTCFullYear(),
        options.dtstart.getUTCMonth(),
        options.dtstart.getUTCDate(),
        options.dtstart.getUTCHours(),
        options.dtstart.getUTCMinutes(),
        options.dtstart.getUTCSeconds()
      ));
      result += `DTSTART:${dtstart.toISOString().replace(/[-:]/g, '').replace(/\.\d{3}Z$/, 'Z')}\n`;
    }

    // Add RRULE (will not include DTSTART now)
    result += `RRULE:${rruleString.replace(/^RRULE:/, '')}`;

    // Add EXDATE if there are any
    if (exdates.length > 0) {
      const formattedExdates = exdates.map(date => {
        const utcDate = new Date(Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        ));
        const formatted = utcDate.toISOString().replace(/[-:]/g, '').replace(/\.\d{3}Z$/, 'Z');
        return formatted;
      });
      result += `\nEXDATE:${formattedExdates.join(',')}`;
    } else {
      console.log('[EXDATE_DEBUG] formatRuleSetToString - No exdates to add');
    }

    return result;
  }

  private static offsetRule(rule: RRule, draggedDate: Date, newDate: Date, scope: 'this' | 'thisAndFollowing' | 'all'): RRule {

    // Calculate the time difference for the offset
    const timeDiff = newDate.getTime() - draggedDate.getTime();

    // Create new start time with offset
    const newStartTime = new Date(draggedDate.getTime());
    newStartTime.setTime(newDate.getTime());

    // Create a fresh options object with only the essential frequency properties
    const options: any = {
      freq: rule.options.freq,
      interval: rule.options.interval || 1,
      wkst: rule.options.wkst || 0,
      dtstart: newStartTime
    };

    // Special handling for weekly frequency - DO NOT MODIFY THIS BLOCK
    if (rule.options.freq === Frequency.WEEKLY) {
      const draggedDay = RRuleRecurrenceHandler.getWeekday(draggedDate);
      const newDay = RRuleRecurrenceHandler.getWeekday(newDate);

      if (scope === 'thisAndFollowing') {
        // For thisAndFollowing, replace the dragged weekday with the new weekday
        if (rule.options.byweekday) {
          const updatedWeekdays = [...rule.options.byweekday];
          const draggedDayIndex = updatedWeekdays.indexOf(draggedDay);

          if (draggedDayIndex !== -1) {
            // Replace the dragged weekday with the new weekday
            updatedWeekdays[draggedDayIndex] = newDay;
            options.byweekday = updatedWeekdays.sort((a: number, b: number) => a - b);
          } else {
            options.byweekday = rule.options.byweekday;
          }
        } else {
          options.byweekday = [newDay];
        }
      } else if (rule.options.byweekday) {
        // For other scopes, keep existing behavior
        const updatedWeekdays = [...rule.options.byweekday];
        const draggedDayIndex = updatedWeekdays.indexOf(draggedDay);

        if (draggedDayIndex !== -1) {
          updatedWeekdays[draggedDayIndex] = newDay;
          options.byweekday = updatedWeekdays.sort((a: number, b: number) => a - b);
        } else {
          options.byweekday = rule.options.byweekday;
        }
      } else {
        options.byweekday = [newDay];
      }
    }

    // Handle until if it exists by applying the same time offset
    if (rule.options.until) {
      // Create a new UNTIL date that preserves the original date but uses the time from newDate
      const originalUntil = rule.options.until;
      const newUntil = new Date(originalUntil.getTime());
      
      // Update just the time components
      newUntil.setUTCHours(newDate.getUTCHours());
      newUntil.setUTCMinutes(newDate.getUTCMinutes());
      newUntil.setUTCSeconds(newDate.getUTCSeconds());
      
      // Use this adjusted UNTIL for the new rule
      options.until = newUntil;
    }

    // For minutely/hourly frequencies, don't restrict their respective time components
    // For minutely, don't restrict hours either as minutes should flow across hours
    if (rule.options.freq !== Frequency.MINUTELY) {
      if (rule.options.freq !== Frequency.HOURLY) {
        options.byhour = [newDate.getUTCHours()];
      }
      options.byminute = [newDate.getUTCMinutes()];
    }

    // Always set seconds
    options.bysecond = [newDate.getUTCSeconds()];

    // Clean up the options and create a new rule
    const cleanedOptions = RRuleRecurrenceHandler.cleanRuleOptions(options);

    const finalRule = new RRule(cleanedOptions);

    return finalRule;
  }

  private formatDate(date: Date, includeTime: boolean = true, includeTimezone: boolean = true): string {
    return date.toISOString();
  }

  private static calculateRemainingCount(rule: any, draggedDate: Date): number | undefined {

    // If no count was set, return undefined to maintain infinite recurrence
    if (!rule.count) {
      return undefined;
    }

    // Create a rule to get all events up to but not including the dragged date
    const beforeRule = new RRule({
      ...rule,
      until: new Date(draggedDate.getTime() - 1) // Subtract 1ms to exclude the dragged date
    });

    // Get count of events before the dragged date (not including it)
    const eventsBefore = beforeRule.all().length;

    // Calculate remaining events (including the dragged event)
    const remainingCount = Math.max(1, rule.count - eventsBefore);

    return remainingCount;
  }

  private static validateRRule(rruleStr: string, referenceDate: Date): string | undefined {
    if (!rruleStr) return undefined;
    try {
      // Parse using forceset so we work with RRuleSet, then check the first rule.
      const ruleSet = rrulestr(rruleStr, { forceset: true }) as RRuleSet;
      const mainRule = ruleSet.rrules()[0];
      // See if there's an occurrence after the referenceDate.
      const nextOccurrence = mainRule.after(referenceDate, false);
      // If there is no next occurrence, then the rule doesn't really recur.
      if (!nextOccurrence) {
        return undefined;
      }
      return rruleStr;
    } catch (e) {
      return rruleStr; // fallback if there's a parsing issue
    }
  }

  public handleDragAndDrop(
    ruleString: string,
    draggedDate: Date,
    newDate: Date,
    scope: 'this' | 'thisAndFollowing' | 'all',
    originalDateTime: Date
  ): RecurrenceHandlerResult {

    let ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet;
    const mainRule = ruleSet.rrules()[0];
    // Parse the original ruleset

    // Fix: Ensure mainRule has the correct time from originalDateTime
    mainRule.options.dtstart = new Date(originalDateTime.getTime());

    // Fix: Reconstruct the ruleset with the updated rule
    const fixedRuleSet = new RRuleSet();
    fixedRuleSet.rrule(new RRule(mainRule.options));
    ruleSet.exdates().forEach(exdate => fixedRuleSet.exdate(exdate));
    ruleSet = fixedRuleSet;

    // Use the explicitly provided originalDateTime
    const originalDueDate = this.formatDate(originalDateTime, true, true);

    // Helper function to check if a date is the first occurrence
    const isFirstOccurrence = (date: Date): boolean => {
      const startDate = mainRule.options.dtstart;
      const endDate = new Date(startDate.getTime());
      endDate.setDate(endDate.getDate() + 1);

      const firstOccurrence = mainRule.between(startDate, endDate, true)[0];
      return firstOccurrence &&
        firstOccurrence.getTime() === startDate.getTime() &&
        date.getTime() === startDate.getTime();
    };

    if (scope === 'this') {
      // Create new rule set for original series
      const originalSet = new RRuleSet();
      originalSet.rrule(new RRule(mainRule.options));

      // Copy existing exclusions
      const existingExdates = ruleSet.exdates();
      existingExdates.forEach(existingExdate => {
        originalSet.exdate(existingExdate);
      });

      // Add the dragged date as an exclusion
      originalSet.exdate(new Date(draggedDate.getTime()));

      // Format and validate the original series with the new exclusion
      const originalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(originalSet.rrules(), originalSet.exdates());
      const originalDueDate = this.formatDate(mainRule.options.dtstart, true, true);
      const validatedOriginalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(originalNewRRule, new Date(originalDueDate));

      return {
        originalAction: 'patch',
        originalDueDate: originalDueDate,
        originalNewRRule: validatedOriginalNewRRule,
        newAction: 'create',
        newDueDate: this.formatDate(newDate, true, true)
      };
    } else if (scope === 'thisAndFollowing') {
      if (isFirstOccurrence(draggedDate)) {
        // If it's the first occurrence, delete the original series
        const newRule = (this.constructor as typeof RRuleRecurrenceHandler).offsetRule(mainRule, draggedDate, newDate, scope);
        const newSet = new RRuleSet();
        newSet.rrule(newRule);

        // Handle EXDATEs for the new series
        const timeDiff = newDate.getTime() - draggedDate.getTime();
        const exdatesAfter = ruleSet.exdates()
          .filter(d => d >= draggedDate)
          .map(d => new Date(d.getTime() + timeDiff));
        exdatesAfter.forEach(d => newSet.exdate(d));

        const newRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(newSet.rrules(), newSet.exdates());
        const newDueDate = this.formatDate(newDate, true, true);
        const validatedNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(newRRule, new Date(newDueDate));

        return {
          originalAction: 'delete',
          originalDueDate: this.formatDate(draggedDate, true, true),
          newAction: 'create',
          newDueDate: newDueDate,
          newRRule: validatedNewRRule
        };
      }

      // For weekly recurrence, check if it's the first occurrence of the weekday
      if (mainRule.options.freq === Frequency.WEEKLY) {
        const draggedDay = RRuleRecurrenceHandler.getWeekday(draggedDate);
        const newDay = RRuleRecurrenceHandler.getWeekday(newDate);

        // Find the first occurrence of this weekday in the series
        const firstOccurrenceOfDay = mainRule.between(
          mainRule.options.dtstart,
          draggedDate,
          true
        ).find(date => RRuleRecurrenceHandler.getWeekday(date) === draggedDay);

        const isFirstOfDay = firstOccurrenceOfDay &&
          firstOccurrenceOfDay.getTime() === draggedDate.getTime();

        if (isFirstOfDay) {
          // If it's the first occurrence of this weekday, just update the weekday in the original series
          const updatedOptions = { ...mainRule.options };
          if (updatedOptions.byweekday) {
            const updatedWeekdays = [...updatedOptions.byweekday];
            const draggedDayIndex = updatedWeekdays.indexOf(draggedDay);
            if (draggedDayIndex !== -1) {
              updatedWeekdays[draggedDayIndex] = newDay;
              updatedOptions.byweekday = updatedWeekdays.sort((a: number, b: number) => a - b);
            }
          } else {
            updatedOptions.byweekday = [newDay];
          }

          const updatedSet = new RRuleSet();
          updatedSet.rrule(new RRule(updatedOptions));

          // Copy all existing EXDATEs
          ruleSet.exdates().forEach(d => updatedSet.exdate(d));

          const updatedRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(updatedSet.rrules(), updatedSet.exdates());
          const originalDueDate = this.formatDate(mainRule.options.dtstart, true, true);
          const validatedUpdatedRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(updatedRRule, new Date(originalDueDate));

          return {
            originalAction: 'patch',
            originalDueDate: originalDueDate,
            originalNewRRule: validatedUpdatedRRule,
            newAction: 'none'
          };
        }
      }

      // If not first occurrence or not weekly, proceed with splitting the series
      // Create truncated rule for original series
      let truncatedOptions = { ...mainRule.options };
      const untilDate = new Date(draggedDate.getTime());
      untilDate.setMinutes(untilDate.getMinutes() - 1); // End one minute before dragged date
      truncatedOptions.until = untilDate;
      delete truncatedOptions.count; // Remove count since we're using until

      let truncatedSet = new RRuleSet();
      truncatedSet.rrule(new RRule(truncatedOptions));

      // Copy EXDATEs that occur before dragged date
      const exdatesBefore = ruleSet.exdates()
        .filter(d => d < draggedDate) // Only include exdates before dragged date
        .map(d => new Date(d.getTime()));
      exdatesBefore.forEach(d => truncatedSet.exdate(d));

      // Create new series starting from new date
      const newRule = (this.constructor as typeof RRuleRecurrenceHandler).offsetRule(mainRule, draggedDate, newDate, scope);

      // Calculate remaining count for the new series if original had count
      let remainingCount: number | undefined = undefined;
      if (mainRule.options.count) {
        // Count events in original pattern up to (but not including) dragged date
        const untilForCount = new Date(draggedDate.getTime());
        untilForCount.setMinutes(untilForCount.getMinutes() - 1); // End one minute before dragged date
        const originalRuleWithUntil = new RRule({
          ...mainRule.options,
          until: untilForCount,
          count: undefined     // Remove count since we're using until
        });
        const eventsBeforeDraggedDate = originalRuleWithUntil.all().length;
        remainingCount = Math.max(1, Number(mainRule.options.count) - eventsBeforeDraggedDate);
      }

      const newSet = new RRuleSet();
      if (remainingCount !== undefined) {
        const newRuleOptions = { ...newRule.options, count: remainingCount };

        const originalRuleCount = Number(mainRule.options.count) - remainingCount;

        const newOriginalOptions = { ...mainRule.options }
        newOriginalOptions.count = originalRuleCount;
        delete newOriginalOptions.until

        truncatedSet = undefined
        truncatedSet = new RRuleSet();
        truncatedSet.rrule(new RRule(newOriginalOptions));

        newSet.rrule(new RRule(newRuleOptions));
      } else if (mainRule.options.until && !remainingCount) {
        // If original had until, keep it for new series
        const newRuleOptions = { ...newRule.options, until: mainRule.options.until };
        newSet.rrule(new RRule(newRuleOptions));
      } else {
        // If original was infinite, keep new series infinite
        newSet.rrule(newRule);
      }

      // Handle EXDATEs for new series
      const timeDiff = newDate.getTime() - draggedDate.getTime();
      const exdatesAfter = ruleSet.exdates()
        .filter(d => d >= draggedDate)
        .map(d => new Date(d.getTime() + timeDiff));
      exdatesAfter.forEach(d => newSet.exdate(d));

      const originalDueDate = this.formatDate(mainRule.options.dtstart, true, true);
      const originalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(truncatedSet.rrules(), truncatedSet.exdates());
      const validatedOriginalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(originalNewRRule, new Date(originalDueDate));

      const newDueDate = this.formatDate(newDate, true, true);
      const newRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(newSet.rrules(), newSet.exdates());
      const validatedNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(newRRule, new Date(newDueDate));

      return {
        originalAction: 'patch',
        originalDueDate: originalDueDate,
        originalNewRRule: validatedOriginalNewRRule,
        newAction: 'create',
        newDueDate: newDueDate,
        newRRule: validatedNewRRule
      };
    } 
    // else {
    //   // For 'all' scope
    //   // Parse the rule set to get the base rule
    //   const ruleSet = rrulestr(ruleString, { forceset: true }) as RRuleSet;
    //   const rules = ruleSet.rrules();
    //   const exdates = ruleSet.exdates();
      
    //   if (rules.length === 0) {
    //     throw new Error('No rules found in rule string');
    //   }
      
    //   // Get the main rule and create a copy of its options
    //   const mainRule = rules[0];
    //   const options = { ...mainRule.options };
      
    //   // Create a new dtstart with the same date but updated time
    //   const oldDtstart = options.dtstart;
    //   const newDtstart = new Date(oldDtstart.getTime());
    //   newDtstart.setUTCHours(newDate.getUTCHours());
    //   newDtstart.setUTCMinutes(newDate.getUTCMinutes());
    //   newDtstart.setUTCSeconds(newDate.getUTCSeconds());
      
    //   // Update the dtstart in the options
    //   options.dtstart = newDtstart;
      
    //   // Also update the byhour/byminute/bysecond for completeness
    //   options.byhour = [newDate.getUTCHours()];
    //   options.byminute = [newDate.getUTCMinutes()];
    //   options.bysecond = [newDate.getUTCSeconds()];
      
    //   // Create a new rule set with the updated options
    //   const newRuleSet = new RRuleSet();
    //   newRuleSet.rrule(new RRule(options));
      
    //   // Add any existing exdates
    //   exdates.forEach(exdate => newRuleSet.exdate(exdate));
      
    //   // Format the new rule as a string
    //   const newRuleStr = RRuleRecurrenceHandler.formatRuleSetToString(
    //     newRuleSet.rrules(),
    //     newRuleSet.exdates()
    //   );
      
    //   // Validate and return
    //   const validatedNewRRule = RRuleRecurrenceHandler.validateRRule(newRuleStr, options.dtstart);
      
    //   return {
    //     originalAction: 'patch',
    //     originalDueDate: this.formatDate(newDate, true, true),
    //     originalNewRRule: validatedNewRRule,
    //     newAction: 'none'
    //   };
    // }
  }

  public handleDeletion(
    originalRuleStr: string,
    deletedDate: Date,
    scope: Scope,
    originalDateTime: Date
  ): RecurrenceHandlerResult {
    if (isNaN(deletedDate.getTime())) {
      throw new Error('Invalid date format provided');
    }

    // Parse the original ruleset
    let ruleSet = rrulestr(originalRuleStr, { forceset: true }) as RRuleSet;
    const mainRule = ruleSet.rrules()[0];

    // Fix: Ensure mainRule has the correct time from originalDateTime
    mainRule.options.dtstart = new Date(originalDateTime.getTime());

    // Fix: Reconstruct the ruleset with the updated rule
    const fixedRuleSet = new RRuleSet();
    fixedRuleSet.rrule(new RRule(mainRule.options));
    ruleSet.exdates().forEach(exdate => fixedRuleSet.exdate(exdate));
    ruleSet = fixedRuleSet;

    // Use the explicitly provided originalDateTime
    const originalDueDate = this.formatDate(originalDateTime, true, true);

    switch (scope) {
      case 'this': {
        // Create EXDATE with the exact time from the deleted event
        const exdate = new Date(deletedDate.getTime());
        ruleSet.exdate(exdate);
        const originalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(ruleSet.rrules(), ruleSet.exdates());
        const validatedOriginalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(originalNewRRule, originalDateTime);

        return {
          originalAction: 'patch',
          originalNewRRule: validatedOriginalNewRRule,
          originalDueDate: originalDueDate,
          newAction: 'none'
        };
      }

      case 'thisAndFollowing': {
        // Check if this is the first occurrence using between()
        const firstOccurrence = mainRule.between(mainRule.options.dtstart, deletedDate, true)[0];
        const isFirstInstance = firstOccurrence &&
          firstOccurrence.getTime() === mainRule.options.dtstart.getTime() &&
          deletedDate.getTime() === mainRule.options.dtstart.getTime();

        // If first instance is deleted, delete entire series
        if (isFirstInstance) {
          return {
            originalAction: 'delete',
            originalDueDate: this.formatDate(originalDateTime, true, true),
            newAction: 'none'
          };
        }

        // Create truncated rule
        const truncatedRuleSet = new RRuleSet();
        const truncatedOptions = { ...mainRule.options };

        // Set UNTIL to 5 seconds before deleted instance for precise cutoff
        const untilDate = new Date(deletedDate.getTime() - 5000); // 5 seconds before
        truncatedOptions.until = untilDate;
        delete truncatedOptions.count;

        truncatedRuleSet.rrule(new RRule(truncatedOptions));

        // Copy relevant exceptions
        const exdates = ruleSet.exdates();
        exdates.forEach(exdate => {
          if (exdate < deletedDate) {
            truncatedRuleSet.exdate(exdate);
          }
        });

        const originalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(truncatedRuleSet.rrules(), truncatedRuleSet.exdates());
        const validatedOriginalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(originalNewRRule, originalDateTime);

        return {
          originalAction: 'patch',
          originalNewRRule: validatedOriginalNewRRule,
          originalDueDate: originalDueDate,
          newAction: 'none'
        };
      }

      case 'all':
        return {
          originalAction: 'delete',
          originalDueDate: originalDueDate,
          newAction: 'none'
        };

      default:
        throw new Error(`Invalid scope: ${scope}`);
    }
  }

  public handleRecurringEdit(
    originalRuleStr: string,
    clickedDate: Date,
    newDate: Date,
    newRuleStr: string,
    scope: Scope
  ): RecurrenceHandlerResult {
    console.log('=== handleRecurringEdit ===');
    console.log('Original rule string:', originalRuleStr);
    console.log('Clicked date:', clickedDate.toISOString());
    console.log('New date:', newDate.toISOString());
    console.log('New rule string:', newRuleStr);
    console.log('Scope:', scope);

    const originalRuleSet = rrulestr(originalRuleStr, { forceset: true }) as RRuleSet;
    const originalMainRule = originalRuleSet.rrules()[0];

    // Helper function to check if a date is the first occurrence
    const isFirstOccurrence = (date: Date): boolean => {
      const startDate = originalMainRule.options.dtstart;
      const endDate = new Date(startDate.getTime());
      endDate.setDate(endDate.getDate() + 1); // Look at just a 1-day window

      const firstOccurrence = originalMainRule.between(startDate, endDate, true)[0];
      return firstOccurrence &&
        firstOccurrence.getTime() === startDate.getTime() &&
        date.getTime() === startDate.getTime();
    };

    switch (scope) {
      case 'all': {
        // For 'all' scope, we delete the old series and create a new one with the new rule
        const validatedNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(newRuleStr, newDate);
        const originalDueDate = this.formatDate(originalMainRule.options.dtstart, true, true);
        return {
          originalAction: 'delete',
          originalDueDate,
          newAction: 'create',
          newDueDate: originalDueDate,
          newRRule: validatedNewRRule
        };
      }

      case 'this': {
        // For 'this' scope:
        // 1. Exclude the clicked date from original series
        // 2. Create a new event at the new date, which may be recurring based on newRuleStr
        const newRuleSet = new RRuleSet();
        newRuleSet.rrule(new RRule(originalMainRule.options));

        // Copy over any existing exdates from the original ruleSet
        originalRuleSet.exdates().forEach(existingExdate => {
          newRuleSet.exdate(existingExdate);
        });

        // Add the new exdate for the clicked event
        newRuleSet.exdate(new Date(clickedDate.getTime()));

        // Format and validate the original series with the new exclusion
        const originalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(newRuleSet.rrules(), newRuleSet.exdates());
        const originalDueDate = this.formatDate(new Date(originalMainRule.options.dtstart.getTime()), true, true);
        const validatedOriginalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(originalNewRRule, new Date(originalDueDate));

        // For the new event, validate the new rule string if provided
        const validatedNewRRule = newRuleStr ?
          (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(newRuleStr, newDate) :
          undefined;

        return {
          originalAction: 'patch',
          originalDueDate: originalDueDate,
          originalNewRRule: validatedOriginalNewRRule,
          newAction: 'create',
          newDueDate: this.formatDate(newDate, true, true),
          newRRule: validatedNewRRule  // Allow the new event to be recurring if specified
        };
      }

      case 'thisAndFollowing': {
        // Check if this is the first occurrence
        if (isFirstOccurrence(clickedDate)) {
          // If it's the first occurrence, delete original series and create new one
          const validatedNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(newRuleStr, newDate);

          return {
            originalAction: 'delete',
            originalDueDate: this.formatDate(clickedDate, true, true),
            newAction: 'create',
            newDueDate: this.formatDate(newDate, true, true),
            newRRule: validatedNewRRule
          };
        }

        // Create truncated rule for the original series
        const truncatedOptions = { ...originalMainRule.options };

        // Set until to 1 minute before clicked date for precise cutoff
        const untilDate = new Date(clickedDate.getTime());
        untilDate.setMinutes(untilDate.getMinutes() - 1);
        truncatedOptions.until = untilDate;

        // Remove count if it exists since we're using until
        delete truncatedOptions.count;

        const truncatedSet = new RRuleSet();
        truncatedSet.rrule(new RRule(truncatedOptions));

        // Add any existing exceptions that occur before the clicked date
        const exdatesBefore = originalRuleSet.exdates()
          .filter(d => d < clickedDate)
          .map(d => new Date(d.getTime()));
        exdatesBefore.forEach(d => truncatedSet.exdate(d));

        // Create new rule set from the provided new rule string
        const newRuleSet = rrulestr(newRuleStr, { forceset: true }) as RRuleSet;
        const newMainRule = newRuleSet.rrules()[0];

        // Calculate remaining count if needed
        const remainingCount = (this.constructor as typeof RRuleRecurrenceHandler).calculateRemainingCount(originalMainRule.options, clickedDate);
        if (remainingCount !== undefined) {
          const newRuleOptions = { ...newMainRule.options, count: remainingCount };
          newRuleSet.rrule(new RRule(newRuleOptions));
        }

        const originalDueDate = this.formatDate(new Date(originalMainRule.options.dtstart.getTime()), true, true);
        const originalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(truncatedSet.rrules(), truncatedSet.exdates());
        const validatedOriginalNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(originalNewRRule, new Date(originalDueDate));

        const newDueDate = this.formatDate(newDate, true, true);
        const newRRule = (this.constructor as typeof RRuleRecurrenceHandler).formatRuleSetToString(newRuleSet.rrules(), newRuleSet.exdates());
        const validatedNewRRule = (this.constructor as typeof RRuleRecurrenceHandler).validateRRule(newRRule, new Date(newDueDate));

        return {
          originalAction: 'patch',
          originalDueDate: originalDueDate,
          originalNewRRule: validatedOriginalNewRRule,
          newAction: 'create',
          newDueDate: newDueDate,
          newRRule: validatedNewRRule
        };
      }

      default:
        throw new Error(`Invalid scope: ${scope}`);
    }
  }
} 