import { Box, IconButton, Stack } from "@mui/material";
import { DragDropContext, Draggable, DragStart, Droppable, DropResult } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { Board } from "@common-models/board";
import { DBBoardsThunks, DBWorkspaceThunks, boardsSelectors } from "@common-reducers/DBServiceThunks";
import Lottie from "lottie-react";
import NoResults from '@common-assets/lottie/no-results.json';
import BoardListItem from "./board-list-item";
import classes from "./boards-panel.module.scss";
import { useCallback, useRef, useState, useEffect } from "react";
import { getCurrentBoards, getIsLoadingBoards, isCurrentWorkspaceOwnerSelector } from "@common-reducers/BoardsSelectors";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { boardsActions } from "@common-reducers/BoardsReducer";
import BTInputField from "@common-components/bt-input-field/bt-input-field";
import SearchIcon from '@mui/icons-material/Search';
import { ClearIcon } from '@mui/x-date-pickers/icons';
import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { namespace } from "@common-config/constants";
import { useRouter } from "@common-reducers/hooks/use-router";
import { addNewBoardThunk } from "@common-reducers/BoardsThunks";
import { taskActions } from "@common-reducers/TasksReducer";
import getRandomEmoji from 'get-random-emoji';
import RowStack from "@common/components/common/row-stack/RowStack";

interface BoardsListProps {
    drawerIsOpen: boolean;
}

function BoardsList({ drawerIsOpen }: BoardsListProps) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [draggedBoard, setDraggedBoard] = useState<any>({});
    const searchInputRef = useRef<HTMLInputElement>();

    // Board state
    const boards: Board[] = useSelector(getCurrentBoards, isEqual);
    const boardsFilter = useAppSelector((state) => state.BoardsReducer.boardsFilterName);
    const isBoardsLoading = useAppSelector(getIsLoadingBoards);
    const isCurrentWorkspaceOwner = useAppSelector(isCurrentWorkspaceOwnerSelector);
    const deletedBoardName = useAppSelector(state => state.TasksReducer.deletedBoardName);
    const boardsList: Board[] = useSelector((state) => boardsSelectors.selectAll(state));

    // Board initialization logic
    const createFirstBoard = useCallback(async () => {
        dispatch(taskActions.setCreatingFirstBoardStatus("creating"));
        const res: any = await dispatch(addNewBoardThunk({
            boardName: "new board",
            emoji: getRandomEmoji(),
            template: ''
        }));
        const boardId = res.payload;
        dispatch(taskActions.setCreatingFirstBoardStatus(""));
        router.navigate(`/tasks/?boardID=${boardId}`);
    }, [dispatch, router]);

    useEffect(() => {
        if (!isBoardsLoading && boardsList.length === 0 && !deletedBoardName && isCurrentWorkspaceOwner) {
            createFirstBoard();
        }
    }, [createFirstBoard, isBoardsLoading, boardsList.length, deletedBoardName, isCurrentWorkspaceOwner]);

    useEffect(() => {
        console.log("BoardsList: run only once");
        console.log("get boards");
        dispatch(DBBoardsThunks.find({}));
        dispatch(DBWorkspaceThunks.find({}));
    }, []); // Must remain empty!

    function filterBoards({ target }: any) {
        dispatch(boardsActions.setBoardsFilterName(target.value));
    }

    function clearSearch() {
        dispatch(boardsActions.setBoardsFilterName(''));
    }

    function onBeforeDragStart(initial: DragStart) {
        setDraggedBoard(boards[initial.source.index]);
    }

    function updateBoardsOnDragEnd({ destination, source }: DropResult) {
        setDraggedBoard({});
        if (source.index === destination?.index) {
            return;
        }

        const clonedBoards = [...boards];
        const [removed] = clonedBoards.splice(source.index, 1);
        clonedBoards.splice(destination?.index, 0, removed);

        const changes = clonedBoards.reverse().map((board, index) => {
            return {
                id: board._id,
                changes: {
                    order: index
                }
            };
        });

        dispatch(DBBoardsThunks.patchMany(changes));
    }

    return (
        <>
            <RowStack justifyContent={"center"} >
                <BTInputField
                    onClick={drawerIsOpen ? () => { } : undefined}
                    className={classes.search_input}
                    type="text"
                    ref={searchInputRef}
                    value={boardsFilter ?? ""}
                    onChange={filterBoards}
                    placeholder={drawerIsOpen ? t('boardsPanel.searchPlaceholder') : ""}
                    startAdornment={drawerIsOpen ? <SearchIcon /> : ""}
                    endAdornment={
                        boardsFilter && (
                            <IconButton size='small' style={{ fontSize: '12px' }} onClick={clearSearch}>
                                <ClearIcon style={{ fontSize: '14px' }} />
                            </IconButton>
                        )
                    }
                />
                <div style={{ opacity: drawerIsOpen ? 0 : 1 }}
                    className={classes.customSearchIcon}>
                    <BiSearch />
                </div>
            </RowStack>

            {(boardsFilter === "" || boards.length > 0) ? (
                <div style={{ marginTop: 16, overflow: 'auto', flexGrow: '1' }} className={classes.scrollContainer}>
                    <DragDropContext
                        onDragEnd={updateBoardsOnDragEnd}
                        onBeforeDragStart={onBeforeDragStart}
                    >
                        <Droppable
                            droppableId="list"
                            isCombineEnabled
                            renderClone={(provided, snapshot) => (
                                <BoardListItem
                                    provided={provided}
                                    isDragging={snapshot.isDragging}
                                    boardId={draggedBoard._id}
                                    key={`boardItem${draggedBoard._id}`}
                                />
                            )}
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {boards.map((board, index) => (
                                        <Draggable
                                            index={index}
                                            draggableId={board._id}
                                            key={`${board._id}-${index}`}
                                        >
                                            {(provided, snapshot) => (
                                                <Box data-testid='board-panel-item'>
                                                    <BoardListItem
                                                        provided={provided}
                                                        isDragging={false}
                                                        boardId={board._id}
                                                        key={`boardItem${board._id}`}
                                                    />
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            ) : (
                <Stack px={5} sx={{ height: "100vh" }} justifyContent={"center"} alignItems={"center"}>
                    <Lottie autoPlay={true} loop={true} animationData={NoResults} style={{ width: '200px', height: '200px' }} />
                    <Box fontSize="16px" textAlign={"center"}>
                        We've looked everywhere but couldn't find matching boards. Maybe it's hiding?
                        <br /> Try a different search term or add new board!✨
                    </Box>
                </Stack>
            )}
        </>
    );
}

export default BoardsList; 