import { collapseAllNodeEvents, expandAllNodeEvents, resetEventFilters, setFocusedNodeId, setNodeEventAccordion, setShowChat, setShowEvents } from '@common-reducers/ChatbotReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { EventType, EventStatus, NodeEvent, CreditUsageEvent, OutgoingMessageEvent, IncomingMessageEvent, SessionStartedEvent, SessionEndedEvent } from '@common/models/node-event';
import {
  ShowChart as ActivityIcon,
  Error as AlertCircleIcon,
  ArrowForward as ArrowRightIcon,
  UnfoldLess as CollapseAllIcon,
  UnfoldMore as ExpandAllIcon,
  HighlightOff as HighlightOffIcon,
  Message as MessageSquareIcon,
  Send as SendIcon,
  Chat as ChatIcon,
  EventNote as EventIcon,
  Input as InputIcon,
  StopCircle as StopCircleIcon,
  CreditCard as CreditCardIcon,
  PlayCircleOutline as PlayCircleOutlineIcon
} from '@mui/icons-material';
import { Box, Button, ButtonGroup, CardContent, IconButton, Stack, Tooltip, Typography, Chip } from '@mui/material';
import { useEffect, useRef, useState, useMemo } from 'react';
import { MessageEvent } from './MessageEvent';
import { EventAccordion } from './EventAccordion';
import React from 'react';

interface NodeEventsListProps {
  events: NodeEvent[];
}

const EVENT_TYPE_CONFIG: Record<EventType, { icon: React.ReactNode; label: string; color: string; bgColor: string }> = {
  [EventType.NODE_ENTRY]: {
    icon: <InputIcon />,
    label: 'Node Entry',
    color: '#2196f3',
    bgColor: '#e3f2fd'
  },
  [EventType.NODE_EXIT]: {
    icon: <ArrowRightIcon sx={{ transform: 'rotate(180deg)' }} />,
    color: '#27ae60',
    bgColor: 'rgba(39, 174, 96, 0.1)',
    label: 'Exit'
  },
  [EventType.PROCESS]: {
    icon: <ActivityIcon />,
    color: '#9b59b6',
    bgColor: 'rgba(155, 89, 182, 0.1)',
    label: 'Process'
  },
  [EventType.ERROR]: {
    icon: <AlertCircleIcon />,
    color: '#e74c3c',
    bgColor: 'rgba(231, 76, 60, 0.1)',
    label: 'Error'
  },
  [EventType.DECISION]: {
    icon: <MessageSquareIcon />,
    color: '#f1c40f',
    bgColor: 'rgba(241, 196, 15, 0.1)',
    label: 'Decision'
  },
  [EventType.OUTGOING_MESSAGE]: {
    icon: <SendIcon />,
    color: '#2ecc71',
    bgColor: 'rgba(46, 204, 113, 0.1)',
    label: 'Sent'
  },
  [EventType.INCOMING_MESSAGE]: {
    icon: <ChatIcon />,
    color: '#e67e22',
    bgColor: 'rgba(230, 126, 34, 0.1)',
    label: 'Received'
  },
  [EventType.SESSION_STARTED]: {
    icon: <EventIcon />,
    color: '#e67e22',
    bgColor: 'rgba(230, 126, 34, 0.1)',
    label: 'Session Started'
  },
  [EventType.SESSION_ENDED]: {
    icon: <StopCircleIcon />,
    label: 'Session Ended',
    color: '#f44336',
    bgColor: '#ffebee'
  },
  [EventType.CREDIT_USAGE]: {
    icon: <CreditCardIcon />,
    label: 'Credit Usage',
    color: '#9c27b0',
    bgColor: '#f3e5f5'
  }
};

const NodeEventsList = ({ events }: NodeEventsListProps) => {
  const [accordionState, setAccordionState] = useState<Record<string, boolean>>({});
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const focusedNodeId = useAppSelector(state => state.ChatbotReducer.focusedNodeId);
  const showEvents = useAppSelector(state => state.ChatbotReducer.showEvents);
  const showChat = useAppSelector(state => state.ChatbotReducer.showChat);

  // Calculate total credits used
  const totalCreditsUsed = useMemo(() => {
    return events.reduce((total, event) => {
      if (event.type === EventType.CREDIT_USAGE) {
        const creditData = event.data as CreditUsageEvent['data'];
        return total + (event.creditsUsed || creditData.amount || 0);
      }
      return total + (event.creditsUsed || 0);
    }, 0);
  }, [events]);

  // Calculate total tokens used
  const totalTokensUsed = useMemo(() => {
    return events.reduce((total, event) => {
      if (event.metadata?.tokenUsage?.totalTokens) {
        return total + event.metadata.tokenUsage.totalTokens;
      }
      return total;
    }, 0);
  }, [events]);

  // Calculate input and output tokens
  const { totalInputTokens, totalOutputTokens } = useMemo(() => {
    return events.reduce((acc, event) => {
      if (event.metadata?.tokenUsage) {
        return {
          totalInputTokens: acc.totalInputTokens + (event.metadata.tokenUsage.inputTokens || 0),
          totalOutputTokens: acc.totalOutputTokens + (event.metadata.tokenUsage.outputTokens || 0)
        };
      }
      return acc;
    }, { totalInputTokens: 0, totalOutputTokens: 0 });
  }, [events]);

  // Initialize accordion state when events change
  useEffect(() => {
    // Initialize new events with collapsed state
    const newState = { ...accordionState };
    events.forEach(event => {
      if (newState[event._id] === undefined) {
        newState[event._id] = false;
      }
    });
    setAccordionState(newState);
  }, [events]);

  useEffect(() => {
    dispatch(setFocusedNodeId(null));
    dispatch(resetEventFilters());
  }, []);

  useEffect(() => {
    // Scroll to bottom when new events are added or filters change
    // Use a small timeout to ensure the DOM has been updated
    const timeoutId = setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
      }
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, [events.length, showEvents, showChat]);

  const handleExpandAll = () => {
    const newState = { ...accordionState };
    events.forEach(event => {
      newState[event._id] = true;
    });
    setAccordionState(newState);
  };

  const handleCollapseAll = () => {
    const newState = { ...accordionState };
    events.forEach(event => {
      newState[event._id] = false;
    });
    setAccordionState(newState);
  };

  const handleAccordionChange = (eventId: string, isExpanded: boolean) => {
    setAccordionState(prev => ({
      ...prev,
      [eventId]: isExpanded
    }));
    
    // If expanding, ensure the accordion is visible by scrolling to it
    if (isExpanded && scrollContainerRef.current) {
      const accordionElement = document.getElementById(`event-accordion-${eventId}`);
      if (accordionElement) {
        accordionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const getEventIcon = (type: EventType) => {
    const config = EVENT_TYPE_CONFIG[type];
    if (!config) {
      return <EventIcon sx={{ width: 16, height: 16, color: '#9e9e9e' }} />;
    }
    
    // Clone the icon element with the right size and color
    return React.cloneElement(config.icon as React.ReactElement, {
      sx: {
        width: 16,
        height: 16,
        color: config.color
      }
    });
  };

  // Type guard for CreditUsageEvent
  const isCreditUsageEvent = (event: NodeEvent): event is CreditUsageEvent => {
    return event.type === EventType.CREDIT_USAGE;
  };

  // Type guard for message events
  const isMessageEvent = (event: NodeEvent): event is OutgoingMessageEvent | IncomingMessageEvent => {
    return event.type === EventType.OUTGOING_MESSAGE || event.type === EventType.INCOMING_MESSAGE;
  };

  // Format date for display
  const formatDate = (date: Date): string => {
    return new Date(date).toLocaleString();
  };

  // Format number with K/M suffixes
  const formatNumber = (num: number): string => {
    if (num === 0) return '0';
    if (num < 1000) return num.toString();
    if (num < 1000000) return `${(num / 1000).toFixed(1)}K`.replace('.0K', 'K');
    return `${(num / 1000000).toFixed(1)}M`.replace('.0M', 'M');
  };

  // Type guard for session events
  const isSessionEvent = (event: NodeEvent): event is SessionStartedEvent | SessionEndedEvent => {
    return event.type === EventType.SESSION_STARTED || event.type === EventType.SESSION_ENDED;
  };

  const renderEvent = (event: NodeEvent) => {
    // Session events are rendered differently
    if (isSessionEvent(event)) {
      return renderSessionEvent(event);
    }

    // Credit usage events are rendered differently
    if (isCreditUsageEvent(event)) {
      return renderCreditUsageEvent(event);
    }

    // Message events are rendered differently
    if (isMessageEvent(event)) {
      return renderMessageEvent(event);
    }

    // Render other event types
    const config = EVENT_TYPE_CONFIG[event.type];
    
    return (
      <EventAccordion
        key={event._id}
        id={event._id}
        isExpanded={!!accordionState[event._id]}
        onExpandChange={(isExpanded) => handleAccordionChange(event._id, isExpanded)}
        icon={getEventIcon(event.type)}
        type={event.type}
        nodeType={event.nodeType}
        timestamp={event.timestamp}
        nodeId={event.nodeId}
        data={event.data}
        isFocused={focusedNodeId === event.nodeId}
        typeLabel={config.label}
        typeBgColor={config.bgColor}
        typeColor={config.color}
        creditsUsed={event.creditsUsed}
        metadata={event.metadata}
      />
    );
  };

  // Render credit usage events
  const renderCreditUsageEvent = (event: CreditUsageEvent) => {
    const config = EVENT_TYPE_CONFIG[event.type];
    const creditData = event.data;
    const tokenUsage = event.metadata?.tokenUsage;
    
    return (
      <Box 
        key={event._id}
        sx={{ 
          my: 2, 
          p: 2, 
          borderRadius: 2,
          backgroundColor: 'background.paper',
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CreditCardIcon color="secondary" />
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {config.label}: {creditData.reason}
            {creditData.modelName && ` (${creditData.modelName})`}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
          {/* Display credits - always 1 per LLM call */}
          <Chip
            label={`${formatNumber(creditData.amount)} credit${creditData.amount !== 1 ? 's' : ''}`}
            size="small"
            sx={{
              backgroundColor: 'rgba(156, 39, 176, 0.1)',
              color: 'rgb(156, 39, 176)',
              mr: 1
            }}
          />
          
          {/* Display token usage if available */}
          {tokenUsage && (
            <>
              <Tooltip title={`Input tokens: ${formatNumber(tokenUsage.inputTokens || 0)}`}>
                <Chip
                  label={`In: ${formatNumber(tokenUsage.inputTokens || 0)}`}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(76, 175, 80, 0.1)',
                    color: 'rgb(76, 175, 80)',
                    mr: 1
                  }}
                />
              </Tooltip>
              
              <Tooltip title={`Output tokens: ${formatNumber(tokenUsage.outputTokens || 0)}`}>
                <Chip
                  label={`Out: ${formatNumber(tokenUsage.outputTokens || 0)}`}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(3, 169, 244, 0.1)',
                    color: 'rgb(3, 169, 244)',
                    mr: 1
                  }}
                />
              </Tooltip>
              
              <Tooltip title={`Total tokens: ${formatNumber(tokenUsage.totalTokens || 0)}`}>
                <Chip
                  label={`Total: ${formatNumber(tokenUsage.totalTokens || 0)}`}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(255, 152, 0, 0.1)',
                    color: 'rgb(255, 152, 0)',
                  }}
                />
              </Tooltip>
            </>
          )}
        </Box>
        
        <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
          {formatDate(event.timestamp)}
        </Typography>
      </Box>
    );
  };

  // Render message events
  const renderMessageEvent = (event: OutgoingMessageEvent | IncomingMessageEvent) => {
    const config = EVENT_TYPE_CONFIG[event.type];
    
    return (
      <MessageEvent
        key={event._id}
        message={event.data?.message}
        timestamp={event.timestamp}
        type={event.type}
        color={config.color}
        bgColor={config.bgColor}
      />
    );
  };

  // Render session events
  const renderSessionEvent = (event: SessionStartedEvent | SessionEndedEvent) => {
    const config = EVENT_TYPE_CONFIG[event.type];
    const isSessionStartEvent = event.type === EventType.SESSION_STARTED;
    const tokenUsage = event.metadata?.tokenUsage;
    
    return (
      <Box 
        key={event._id}
        sx={{ 
          my: 2, 
          p: 2, 
          borderRadius: 2,
          backgroundColor: 'background.paper',
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isSessionStartEvent ? <PlayCircleOutlineIcon color="success" /> : <StopCircleIcon color="error" />}
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {config.label}: {isSessionStartEvent ? 'New conversation started' : 'Conversation ended'}
            {event.data?.sender && ` by ${event.data.sender}`}
          </Typography>
        </Box>
        
        {event.creditsUsed && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <Chip
              label={`${formatNumber(event.creditsUsed)} credit${event.creditsUsed !== 1 ? 's' : ''}`}
              size="small"
              sx={{
                backgroundColor: 'rgba(156, 39, 176, 0.1)',
                color: 'rgb(156, 39, 176)',
                mr: 1
              }}
            />
            
            {/* Display token usage if available */}
            {tokenUsage && (
              <>
                <Tooltip title={`Input tokens: ${formatNumber(tokenUsage.inputTokens || 0)}`}>
                  <Chip
                    label={`In: ${formatNumber(tokenUsage.inputTokens || 0)}`}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(76, 175, 80, 0.1)',
                      color: 'rgb(76, 175, 80)',
                      mr: 1
                    }}
                  />
                </Tooltip>
                
                <Tooltip title={`Output tokens: ${formatNumber(tokenUsage.outputTokens || 0)}`}>
                  <Chip
                    label={`Out: ${formatNumber(tokenUsage.outputTokens || 0)}`}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(3, 169, 244, 0.1)',
                      color: 'rgb(3, 169, 244)',
                      mr: 1
                    }}
                  />
                </Tooltip>
                
                <Tooltip title={`Total tokens: ${formatNumber(tokenUsage.totalTokens || 0)}`}>
                  <Chip
                    label={`Total: ${formatNumber(tokenUsage.totalTokens || 0)}`}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(255, 152, 0, 0.1)',
                      color: 'rgb(255, 152, 0)',
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Box>
        )}
        
        <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
          {formatDate(event.timestamp)}
        </Typography>
      </Box>
    );
  };

  return (
    <CardContent sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack direction="column" spacing={1} sx={{ mb: 2 }}>
        {/* Header row with title and filter buttons */}
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
          <Stack direction="column" spacing={0.5}>
            <Typography variant="h6" component="h2">
              Session Events
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {events.length} events
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <ButtonGroup 
              size="small" 
              variant="outlined"
              aria-label="view toggle"
            >
              <Button
                variant={showEvents ? "contained" : "outlined"}
                onClick={() => dispatch(setShowEvents(!showEvents))}
                sx={{
                  bgcolor: showEvents ? '#4fc3f7' : 'transparent',
                  color: showEvents ? 'white' : '#4fc3f7',
                  '&:hover': {
                    bgcolor: showEvents ? '#03a9f4' : 'rgba(3, 169, 244, 0.04)'
                  }
                }}
                startIcon={<ActivityIcon />}
              >
                Events
              </Button>
              <Button
                variant={showChat ? "contained" : "outlined"}
                onClick={() => dispatch(setShowChat(!showChat))}
                sx={{
                  bgcolor: showChat ? '#4fc3f7' : 'transparent',
                  color: showChat ? 'white' : '#4fc3f7',
                  '&:hover': {
                    bgcolor: showChat ? '#03a9f4' : 'rgba(3, 169, 244, 0.04)'
                  }
                }}
                startIcon={<ChatIcon />}
              >
                Chat
              </Button>
            </ButtonGroup>
            <IconButton 
              size="small" 
              onClick={handleExpandAll}
              title="Expand all"
            >
              <ExpandAllIcon />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={handleCollapseAll}
              title="Collapse all"
            >
              <CollapseAllIcon />
            </IconButton>
          </Stack>
        </Stack>
        
        {/* Usage statistics row */}
        {(totalCreditsUsed > 0 || totalTokensUsed > 0) && (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ flexWrap: 'wrap' }}>
            {totalCreditsUsed > 0 && (
              <Chip
                label={`${formatNumber(totalCreditsUsed)} credit${totalCreditsUsed !== 1 ? 's' : ''}`}
                size="small"
                sx={{
                  backgroundColor: 'rgba(156, 39, 176, 0.1)',
                  color: 'rgb(156, 39, 176)',
                  fontWeight: 600,
                  '& .MuiChip-label': {
                    px: 2
                  }
                }}
              />
            )}
            {totalTokensUsed > 0 && (
              <>
                <Tooltip title={`Input tokens: ${formatNumber(totalInputTokens)}`}>
                  <Chip
                    label={`In: ${formatNumber(totalInputTokens)}`}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(76, 175, 80, 0.1)',
                      color: 'rgb(76, 175, 80)',
                      fontWeight: 600,
                      '& .MuiChip-label': {
                        px: 2
                      }
                    }}
                  />
                </Tooltip>
                
                <Tooltip title={`Output tokens: ${formatNumber(totalOutputTokens)}`}>
                  <Chip
                    label={`Out: ${formatNumber(totalOutputTokens)}`}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(3, 169, 244, 0.1)',
                      color: 'rgb(3, 169, 244)',
                      fontWeight: 600,
                      '& .MuiChip-label': {
                        px: 2
                      }
                    }}
                  />
                </Tooltip>
                
                <Tooltip title={`Total tokens: ${formatNumber(totalTokensUsed)}`}>
                  <Chip
                    label={`Total: ${formatNumber(totalTokensUsed)}`}
                    size="small"
                    sx={{
                      backgroundColor: 'rgba(255, 152, 0, 0.1)',
                      color: 'rgb(255, 152, 0)',
                      fontWeight: 600,
                      '& .MuiChip-label': {
                        px: 2
                      }
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Stack>
        )}
      </Stack>

      {/* Single list with filtered events */}
      <Box 
        ref={scrollContainerRef}
        sx={{ 
          overflowY: 'auto', 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          // Add padding to ensure content isn't cut off
          px: 1,
          // Add space at the bottom to ensure the last item is fully visible
          pb: 4,
          // Ensure the container doesn't shrink
          minHeight: '200px',
          // Add a subtle border to visually separate from header
          borderTop: '1px solid rgba(0, 0, 0, 0.05)'
        }}
      >
        {/* Filter events based on the filter toggles */}
        {[...events]
          .filter(event => {
            // Show message events if showChat is true
            if (event.type === EventType.INCOMING_MESSAGE || event.type === EventType.OUTGOING_MESSAGE) {
              return showChat;
            }
            // Show other events if showEvents is true
            return showEvents;
          })
          // Reverse to show newest at the bottom
          .reverse()
          .map(event => renderEvent(event))
        }
      </Box>
    </CardContent>
  );
};

export default NodeEventsList; 