import MessageTemplateEditor from '@common-components/message-template-editor/message-template-editor';
import { Template } from '@common-models/template';
import { Stack } from '@mui/system';
import { setCurrentTemplate, setNewCampaign } from '@common-reducers/CampaignReducer';
import { DBTemplateThunks } from "@common-reducers/DBServiceThunks";
import { useGetSignedUploadUrlMutation } from '@common-reducers/backend-api/backend-api';
import { useAnalyticsService } from '@common-reducers/hooks/use-analytics-service';
import { AnalyticsService } from '@common-services/analytics-service';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import axios from 'axios';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import CampaignTabHeader from '../campaign-tab-header';

export default function BulkSetMessage() {
  const analyticsService: AnalyticsService = useAnalyticsService();
  const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign)
  const dispatch = useAppDispatch()
  const [getSignedUploadUrl] = useGetSignedUploadUrlMutation();
  const [upload, setUpload] = useState<HTMLInputElement | null>(null);


  const currentTemplate = useMemo(() => newCampaign?.template ?? {}, [newCampaign]);


  const newTemplate = async (newTemplate) => {

    analyticsService.event("bulk_new_template", {
      category: "bulk",
      action: "bulk_new_template",
    });

    const res = await dispatch(DBTemplateThunks.create(newTemplate));
    dispatch(setCurrentTemplate(res.payload));
  }


  const saveMessageTemplate = useCallback((template: Partial<Template>) => {
    if (template._id) {
      dispatch(
        DBTemplateThunks.patch({
          entity: template
        })
      );
    }
  }, [dispatch]);


  const onChangeUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if ((e?.target?.files?.length ?? 0) > 0) {
      const file = e!.target!.files![0];

      if (currentTemplate) {
        const uploadUrlResponse: {
          url: string;
          publicUrl: string;
        } = await getSignedUploadUrl(file.type).unwrap();

        await axios({
          method: "PUT",
          url: uploadUrlResponse.url,
          data: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        const asset = {
          url: uploadUrlResponse.publicUrl,
          name: file.name,
          type: file.type,
        };
        saveMessageTemplate({
          ...currentTemplate,
          asset,
        });
      }

      setTimeout(() => {
        if (upload) {
          upload.value = "";
        }
      });
    }
  };



  useEffect(() => {
    dispatch(setNewCampaign({
      ...newCampaign,
      template: currentTemplate,
    }))
  }, [currentTemplate, dispatch, newCampaign]);

  return (
    <Stack sx={{
      alignItems: 'center',
      width: '66%',
    }}>
      <CampaignTabHeader
        title={'Write your message'}
        subTitle='Add text, emojis, links, labels and files'
      />

      <Stack sx={{
        width: '100%',
      }}>
        <input
          type="file"
          ref={(ref) => {
            setUpload(ref);
          }}
          style={{ display: "none" }}
          onChange={onChangeUploadFile}
        />
        <MessageTemplateEditor
          isFromCampaign={true}
          value={currentTemplate}
          onSaveNew={newTemplate}
          onChange={(templateLocalState) => dispatch(setNewCampaign({ ...newCampaign, template: templateLocalState }))}
        />
      </Stack>
    </Stack>
  )
}