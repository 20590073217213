import { Subscription, SubscriptionObject } from "@common-models/subscription";
import { User } from "@common-models/user";
import { Workspace } from "@common-models/workspace";
import { createSelector } from "@reduxjs/toolkit";

import {
  logsSelectByQuery,
  subscriptionSelectByQuery,
  userSelectByQuery,
  workspaceSelectOneObjectByQuery,
  workspaceUserSelectOneObjectByQuery
} from "@common-reducers/DBServiceThunks";
import { CommonRootState } from "@common/types/common-root-state-type";
import moment from "moment";
import { GenericState } from "./DBServiceReducersInterfaces";

import {
  sessionSelectOneObjectByQuery,
  userSelectOneObjectByQuery
} from "@common-reducers/DBServiceThunks";

export const selectCurrentUser = createSelector(
  [
    (state) => state.DBUserReducer,
    (state) => state.UserReducer?.userId,
  ],
  (state: GenericState<User>, userId: string) => {
    const user = userSelectOneObjectByQuery(state, { _id: userId });
    return user;
  }
);

// Other code such as selectors can use the imported `CommonRootState` type
export const selectUser = createSelector(
  [(state) => state],
  (state) => {
    const [user] = userSelectByQuery(state.DBUserReducer, {
      _id: state.UserReducer.userId,
    });
    return user;
  }
);

export const isLoggedInSelector = createSelector(
  [(state) => state, (state) => selectUser(state)],
  (state: CommonRootState, user: User) => {
    return !!user;
  }
);






export const selectCurrentUserEmail = createSelector(
  [
    (state) => state.DBUserReducer,
    (state) => selectCurrentUser(state),
  ],
  (state: GenericState<User>, user: User) => {
    return user?.email;
  }
);

export const selectUserObject = createSelector(
  [selectCurrentUser],
  (user: User) => {
    return user ? new User(user) : undefined;
  }
);

export const selectIsDarkMode = createSelector([selectUser], (user) => {
  return user?.isDarkMode;
});

export const selectDefaultChatCountryCode = createSelector(
  [selectUser],
  (user) => {
    return user?.defaultChatCountryCode;
  }
);

export const selectCurrentWorkspace = createSelector(
  [(state) => state, selectUserObject],
  (state: CommonRootState, user) => {
    const currentWorkspace = workspaceSelectOneObjectByQuery(
      state.DBWorkspaceReducer,
      { _id: user?.currentWorkspace }
    );
    return currentWorkspace;
  }
);

//create selector
export const selectUserEngagementScore = createSelector(
  [(state) => state, selectUserObject],
  (state: CommonRootState, user) => {
    const userLogs = logsSelectByQuery(state.DBLogReducer, {
      triggerById: user?._id,
    });

    const totalUserEngagementScore = userLogs.reduce(
      (accumulator, currentObject) => {
        return accumulator + (currentObject.userEngagementPoints || 0);
      },
      0
    );

    return totalUserEngagementScore;
  }
);
export const isUserWorkSpaceOwner = createSelector(
  [(state) => state, selectUserObject],
  (state: CommonRootState, user) => {
    let isOwner = false;
    const currentWorkspace = workspaceSelectOneObjectByQuery(
      state.DBWorkspaceReducer,
      { _id: user?.currentWorkspace }
    );

    if (currentWorkspace && user) {
      isOwner = currentWorkspace.owner === user._id;
    }
    return isOwner;
  }
);

export const selectIsWorkspaceChanging = createSelector(
  [(state) => state, selectUserObject, selectCurrentWorkspace],
  (state: CommonRootState, user, currentWorkspace) => {
    return currentWorkspace?._id !== user?.currentWorkspace;
  }
);

export const isCurrentUserWorkSpaceOwner = createSelector(
  (state) => state,
  selectUserObject,
  selectCurrentWorkspace,
  (state: CommonRootState, user: User, currentWorkspace: Workspace) => {
    let isCurrentUserWorkSpaceOwner = false;
    if (user) {
      const workspaceOwner = workspaceUserSelectOneObjectByQuery(
        state.DBWorkspaceUserReducer,
        { userId: user?._id, workspaceId: currentWorkspace?._id }
      );
      isCurrentUserWorkSpaceOwner = !!workspaceOwner;
    }
    return isCurrentUserWorkSpaceOwner;
  }
);

export const selectCurrentUserWorkspace = createSelector(
  (state) => state,
  selectUserObject,
  (state: CommonRootState, user: User) => {
    const workspace = workspaceSelectOneObjectByQuery(
      state.DBWorkspaceReducer,
      { owner: user?._id }
    );
    return workspace;
  }
);

export const subscriptionObjectListSelector = createSelector(
  (state: CommonRootState) =>
    subscriptionSelectByQuery(state.DBSubscriptionReducer, {}),
  (subscriptionList) =>
    (subscriptionList ?? []).map(
      (subscription) => new SubscriptionObject(subscription)
    )
);

export const workspaceSubscriptionListSelector = createSelector(
  [
    (state) => state,
    (state) => selectCurrentUser(state),
    selectCurrentWorkspace,
  ],
  (state, user: User, currentWorkspace: Workspace) => {
    let subscriptionListObjects = [];

    const subscriptionList = currentWorkspace
      ? subscriptionSelectByQuery(state.DBSubscriptionReducer, {
        $or: [
          {
            _id: {
              $in: currentWorkspace?.subscriptionList
            },
          },
          {
            subscription_id: currentWorkspace?.chargebeeSubscriptionId
          },
          {
            workspaceId: currentWorkspace?._id
          }
        ]

      })
      : [];

    subscriptionListObjects = (subscriptionList ?? []).map(
      (subscription) => new SubscriptionObject(subscription)
    );

    subscriptionListObjects.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );

    return subscriptionListObjects;
  }
);

export const didUserHaveTrialSubscription = createSelector(
  [
    (state) => state,
    (state) => selectCurrentUser(state),
    selectCurrentWorkspace,
  ],
  (state, user: User, currentWorkspace: Workspace) => {
    const subscriptions = currentWorkspace
      ? subscriptionSelectByQuery(state.DBSubscriptionReducer, {
        _id: {
          $in: currentWorkspace?.subscriptionList,
        },
      })
      : [];

    const hasExpiredTrialSubscription = subscriptions.some(
      (subscription) =>
        subscription.type === "trial" &&
        moment(subscription.expiredDate).isBefore(moment())
    );

    return hasExpiredTrialSubscription;
  }
);

export const didUserHaveRealSubscription = createSelector(
  [
    (state) => state,
    (state) => selectCurrentUser(state),
    selectCurrentWorkspace,
  ],
  (state, user: User, currentWorkspace: Workspace) => {
    const subscriptions = currentWorkspace
      ? subscriptionSelectByQuery(state.DBSubscriptionReducer, {
        _id: {
          $in: currentWorkspace?.subscriptionList,
        },
      })
      : [];

    const hasRealSubscription = subscriptions.some(subscription => subscription.type !== "trial");

    return hasRealSubscription;
  }
);


export const latestWorkspaceSubscriptionSelector = createSelector(
  [
    (state) => state,
    (state) => workspaceSubscriptionListSelector(state),
  ],
  (
    state: CommonRootState,
    subscriptionList: Subscription[]
  ): SubscriptionObject | undefined => {
    const sortedSubscriptionList = (subscriptionList ?? []).map(
      (subscription) => new SubscriptionObject(subscription)
    );
    sortedSubscriptionList.sort((a, b) =>
      moment(b.updatedAt).diff(moment(a.updatedAt))
    );

    const [latestWrokspaceSubscription] = sortedSubscriptionList;
    return latestWrokspaceSubscription;
  }
);

export const activeSubscriptionSelector = createSelector(
  [
    (state) => state,
    (state) => workspaceSubscriptionListSelector(state),
  ],
  (
    state: CommonRootState,
    subscriptionList: Subscription[]
  ): SubscriptionObject | undefined => {
    const sortedSubscriptionList = (subscriptionList ?? [])
      .map((subscription) => new SubscriptionObject(subscription))
      .filter((subscription) => subscription?.isActive);

    sortedSubscriptionList.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );

    // Then, prioritize "chargebee" subscriptions
    sortedSubscriptionList.sort((a, b) => {
      if (a.type === 'chargebee' && b.type !== 'chargebee') return -1;
      if (a.type !== 'chargebee' && b.type === 'chargebee') return 1;
      return 0;
    });

    const [activeSubscription] = sortedSubscriptionList;
    return activeSubscription;
  }
);

// active subscription type selector
export const activeSubscriptionTypeSelector = createSelector(
  activeSubscriptionSelector,
  (activeSubscription: SubscriptionObject) => activeSubscription?.type
);

export const latestChargebeeSubscriptionSelector = createSelector(
  [
    (state) => state,
    (state) => subscriptionObjectListSelector(state),
  ],
  (
    state,
    subscriptionList: SubscriptionObject[]
  ): SubscriptionObject | undefined => {
    const sortedSubscriptionList = (subscriptionList ?? []).filter(
      (subscription) => subscription?.type === "chargebee"
    );

    sortedSubscriptionList.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );

    const [latestChargebeeSubscription] = sortedSubscriptionList;
    return latestChargebeeSubscription;
  }
);

export const hasActiveSubscriptionSelector = createSelector(
  [activeSubscriptionSelector],
  (activeSubscription: SubscriptionObject) => activeSubscription !== undefined
);

export const hasInactiveSubscriptionSelector = createSelector(
  [
    (state) => subscriptionSelectByQuery(state, {}),
    hasActiveSubscriptionSelector,
  ],
  (subscriptionList: Subscription[], hasActiveSubscription: boolean) =>
    hasActiveSubscription && subscriptionList.length > 0
);

export const messageLimitSelector = createSelector(
  activeSubscriptionSelector,
  (activeSubscription: SubscriptionObject) =>
    activeSubscription?.messageLimit ?? 1
);

export const isOverLimitSelector = createSelector(
  (state: CommonRootState, messageCount) => messageCount,
  messageLimitSelector,
  (messageCount: number, messageLimit: number) => messageCount > messageLimit
);



export const selectUserId = createSelector(
  [(state) => state],
  (state) => {
    const [user] = userSelectByQuery(state.DBUserReducer, {
      _id: state.UserReducer.userId,
    });
    return user?._id;
  }
);



export const selectCurrentUserSession = createSelector(
  (state) => state,
  (state: CommonRootState) => {
    const session = sessionSelectOneObjectByQuery(
      state.DBSessionReducer,
      {
        owner: selectUserId(state),
        //status: { $in: ["qrcode", "connected"] },
      },
      { updatedAt: -1 }
    );
    return session;
  }
);

export const selectCurrentSessionQrCode = createSelector(
  selectCurrentUserSession,
  (session) => session?.qrcode
);

export const selectCurrentSessionId = createSelector(
  selectCurrentUserSession,
  (session) => session?._id
);

export const selectCurrentSessionStatus = createSelector(
  selectCurrentUserSession,
  (session) => session?.status
);

export const selectIsCurrentUserSessionConnected = createSelector(
  selectCurrentUserSession,
  (session) => session?.active && session?.status === "connected"
);



export const selectIsWaSocketsLimitReached = createSelector([
  (state) => state.AddScheduledMessageButtonReducer.isWaSocketsLimitReached,
  selectIsCurrentUserSessionConnected
],
  (isWaSocketsLimitReached, isCurrentUserSessionConnected) => !isCurrentUserSessionConnected && isWaSocketsLimitReached
);

export const selectCurrentSessionProgress = createSelector(
  selectCurrentUserSession,
  (session) => {
    const statusProgressMap = {
      'disconnected': 0,
      'initializing': 20,
      'browser_started': 40,
      'navigating': 60,
      'unpaired': 80,
      'syncing': 90,
      'qrcode': 100,
      'connected': 100
    };
    
    return {
      status: session?.status || 'disconnected',
      step: statusProgressMap[session?.status || 'disconnected'] || 0
    };
  }
);

export const getUserStartDayOfWeek = createSelector([selectUser], (user) => {
  if(user?.preferences?.calendarStartDay || user?.preferences?.calendarStartDay === 0){
    return user?.preferences?.calendarStartDay;
  }else{
    const newStartDay = getSystemFirstDayOfWeek();
    return newStartDay;
  }
});

export const getSystemFirstDayOfWeek = () => {
  const systemLocale = navigator.language || "en-us"; // Get system locale
  moment.locale(systemLocale); // Set Moment.js locale
  const firstDay = moment.localeData().firstDayOfWeek(); // Get first day of the week
  return firstDay === 0 ? 0 : 1; // Return 0 for Sunday, 1 for Monday
};
