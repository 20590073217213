import { useProcessDocumentMutation } from '@common-reducers/backend-api/backend-api';
import {
  DBKnowledgeBaseDocumentThunks,
  DBKnowledgeBaseThunks,
  knowledgeBaseDocumentSelectByQuery,
  knowledgeBaseSelectOneObjectByQuery
} from '@common-reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { KnowledgeBaseDocument } from '@common/models/knowledge-base-document';
import { selectKnowledgeBaseTotalSize } from '@common/reducers/KnowledgeBaseSelectors';
import { formatDistanceToNow } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TestQueryDialog from '../TestQueryDialog';
import './knowledge-base-detail.module.css';

// Shadcn UI Components
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Progress } from '@/components/ui/progress';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toast, Toaster } from 'sonner';

// Lucide Icons
import {
  AlertCircle,
  Bot,
  BrainCircuit,
  CheckCircle2,
  ChevronLeft,
  Download,
  FileText,
  MoreVertical,
  Pencil,
  RefreshCw,
  Trash2,
  Upload
} from 'lucide-react';

// Types
interface Document {
  id: string;
  name: string;
  type: 'pdf' | 'word' | 'excel' | 'text' | 'html';
  size: number;
  uploadedAt: string;
  status: 'completed' | 'processing' | 'error';
  chunks?: number;
  progress?: number;
  error?: string;
}

// Utility function to format bytes into human readable format
const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const getStatusIcon = (status: Document['status'] | 'failed') => {
  switch(status) {
    case 'completed':
      return <CheckCircle2 className="w-4 h-4 text-emerald-500" />;
    case 'processing':
      return <RefreshCw className="w-4 h-4 text-blue-500 animate-spin" />;
    case 'error':
    case 'failed':
      return <AlertCircle className="w-4 h-4 text-red-500" />;
    default:
      return null;
  }
};

const KnowledgeBaseDetailContent = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  // State
  const [docTypeFilter, setDocTypeFilter] = useState('all');
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [testQueryDialogOpen, setTestQueryDialogOpen] = useState(false);
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [newKnowledgeBaseName, setNewKnowledgeBaseName] = useState('');
  const [currentTestDocument, setCurrentTestDocument] = useState<KnowledgeBaseDocument | null>(null);
  const [deletingDocumentId, setDeletingDocumentId] = useState<string | null>(null);
  
  // API hooks
  const [processDocument] = useProcessDocumentMutation();

  useEffect(() => {
    if (id) {
      Promise.all([
        dispatch(DBKnowledgeBaseThunks.find({ _id: id })),
        dispatch(DBKnowledgeBaseDocumentThunks.find({ knowledgeBaseId: id }))
      ]).finally(() => {
        setIsInitialLoading(false);
      });
    }
  }, [dispatch, id]);

  const knowledgeBase = useAppSelector(state => 
    id ? knowledgeBaseSelectOneObjectByQuery(state.DBKnowledgeBaseReducer, { _id: id }) : null
  );

  const documents = useAppSelector(state => {
    if (!id) return [];
    const query = {
      knowledgeBaseId: id,
      ...(docTypeFilter !== 'all' && { type: docTypeFilter })
    };
    return knowledgeBaseDocumentSelectByQuery(state.DBKnowledgeBaseDocumentReducer, query);
  });

  // Get the total size of documents for this knowledge base
  const totalSize = useAppSelector(state => 
    id ? selectKnowledgeBaseTotalSize(state, id) : 0
  );

  const handleBack = () => {
    navigate('/knowledge-base');
  };

  const handleDocTypeChange = (value: string) => {
    setDocTypeFilter(value);
  };

  const handleTestQuery = (document: KnowledgeBaseDocument) => {
    setCurrentTestDocument(document);
    setTestQueryDialogOpen(true);
  };

  const handleDownloadDocument = async (documentId: string, documentName: string) => {
    try {
      // TODO: Implement document download
      // const response = await dispatch(DBKnowledgeBaseDocumentThunks.download(documentId)).unwrap();
      // window.open(response.downloadUrl, '_blank');
      toast.success("Download started", {
        description: `Downloading ${documentName}`,
      });
    } catch (error) {
      console.error('Failed to download document:', error);
      toast.error("Download failed", {
        description: "Failed to download document. Please try again.",
      });
    }
  };

  const handleDeleteDocument = async (documentId: string, documentName: string) => {
    // If already deleting this document, prevent multiple deletion attempts
    if (deletingDocumentId === documentId) return;
    
    // Set the deleting document ID to show loading state
    setDeletingDocumentId(documentId);
    
    try {
      await dispatch(DBKnowledgeBaseDocumentThunks.delete({ 
        entity: { _id: documentId },
      }))
      
      // Refresh documents list
      if (id) {
        dispatch(DBKnowledgeBaseDocumentThunks.find({ knowledgeBaseId: id }));
        // Also refresh the knowledge base to update stats
        dispatch(DBKnowledgeBaseThunks.find({ _id: id }));
      }
      
      toast.success("Document deleted", {
        description: `${documentName} has been deleted`,
      });
    } catch (error) {
      console.error('Failed to delete document:', error);
      toast.error("Delete failed", {
        description: "Failed to delete document. Please try again.",
      });
    } finally {
      // Clear the deleting document ID
      setDeletingDocumentId(null);
    }
  };

  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
    setSelectedFile(null);
    setUploadProgress(0);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleUploadDocument = async () => {
    if (!selectedFile || !id) return;

    // Validate file size (max 10MB)
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    if (selectedFile.size > MAX_FILE_SIZE) {
      toast.error("Upload failed", {
        description: "File size exceeds 10MB limit",
      });
      return;
    }

    try {
      setUploadProgress(1);

      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const base64Content = (e.target?.result as string).split(',')[1];
          const fileType = selectedFile.name.split('.').pop()?.toLowerCase();
          
          let documentType: 'text' | 'pdf' | 'word' | 'html';
          switch (fileType) {
            case 'pdf':
              documentType = 'pdf';
              break;
            case 'doc':
            case 'docx':
              documentType = 'word';
              break;
            case 'txt':
              documentType = 'text';
              break;
            case 'html':
              documentType = 'html';
              break;
            default:
              throw new Error(`Unsupported file type: ${fileType}`);
          }

          // Process the document using the knowledge-base.processDocument action
          await processDocument({
            knowledgeBaseId: id,
            documentContent: base64Content,
            documentType,
            documentName: selectedFile.name,
            metadata: {
              mimeType: selectedFile.type,
              createdAt: new Date().toISOString()
            }
          }).unwrap();

          toast.success("Upload successful", {
            description: "Document uploaded successfully",
          });
          
          dispatch(DBKnowledgeBaseDocumentThunks.find({ knowledgeBaseId: id }));
          handleUploadDialogClose();
        } catch (error: any) {
          console.error('Failed to process document:', error);
          toast.error("Processing failed", {
            description: error.message || 'Failed to process document. Please try again.',
          });
        } finally {
          setUploadProgress(0);
        }
      };

      reader.onerror = () => {
        toast.error("Upload failed", {
          description: "Failed to read file. Please try again.",
        });
        setUploadProgress(0);
      };

      reader.readAsDataURL(selectedFile);
    } catch (error: any) {
      console.error('Failed to upload document:', error);
      toast.error("Upload failed", {
        description: error.message || 'Failed to upload document. Please try again.',
      });
      setUploadProgress(0);
    }
  };

  const handleEditName = () => {
    if (knowledgeBase) {
      setNewKnowledgeBaseName(knowledgeBase.name);
      setEditNameDialogOpen(true);
    }
  };

  const handleSaveName = async () => {
    if (!id || !newKnowledgeBaseName.trim()) return;

    try {
      await dispatch(DBKnowledgeBaseThunks.patch({
        entity: {
          _id: id,
          name: newKnowledgeBaseName.trim()
        }
      })).unwrap();
      
      // Refresh knowledge base data
      dispatch(DBKnowledgeBaseThunks.find({ _id: id }));
      
      toast.success("Name updated", {
        description: "Knowledge base name has been updated successfully",
      });
      
      setEditNameDialogOpen(false);
    } catch (error: any) {
      console.error('Failed to update knowledge base name:', error);
      toast.error("Update failed", {
        description: error.message || 'Failed to update knowledge base name. Please try again.',
      });
    }
  };

  if (isInitialLoading || !knowledgeBase) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Loading knowledge base...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="p-8">
        {/* Header */}
        <div 
          className="flex items-center gap-2 text-sm text-gray-600 mb-6 cursor-pointer hover:text-gray-900 transition-colors"
          onClick={handleBack}
        >
          <ChevronLeft className="w-4 h-4" />
          <span>Back to Knowledge Bases</span>
        </div>

        <div className="flex justify-between items-start mb-8">
          <div>
            <div className="flex items-center gap-3 mb-2">
              <h1 className="text-2xl font-semibold text-gray-900">{knowledgeBase.name}</h1>
              <Button 
                variant="ghost" 
                size="icon" 
                className="h-8 w-8"
                onClick={handleEditName}
              >
                <Pencil className="w-4 h-4 text-gray-400" />
              </Button>
            </div>
            <p className="text-sm text-gray-600">{knowledgeBase.description}</p>
          </div>
          <div className="flex gap-3">
            <Button 
              variant="outline" 
              className="flex items-center gap-2"
            >
              <Download className="w-4 h-4" />
              Export
            </Button>
            <Button 
              className="bg-emerald-600 hover:bg-emerald-700 text-white flex items-center gap-2"
              onClick={handleUploadDialogOpen}
            >
              <Upload className="w-4 h-4" />
              Upload Documents
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Main Content */}
          <div className="md:col-span-2">
            {/* Document List */}
            <Card className="shadow-sm">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
                <CardTitle className="text-lg font-semibold">Documents</CardTitle>
                <Select 
                  defaultValue={docTypeFilter} 
                  onValueChange={handleDocTypeChange}
                >
                  <SelectTrigger className="w-[150px]">
                    <SelectValue placeholder="All Types" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Types</SelectItem>
                    <SelectItem value="pdf">PDF</SelectItem>
                    <SelectItem value="word">Word</SelectItem>
                    <SelectItem value="text">Text</SelectItem>
                    <SelectItem value="html">HTML</SelectItem>
                  </SelectContent>
                </Select>
              </CardHeader>
              <CardContent className="p-0">
                <ScrollArea className="h-[600px] relative">
                  {uploadProgress > 0 && (
                    <div className="absolute inset-x-0 top-0 z-10 bg-white/80 p-4">
                      <Progress value={uploadProgress} className="h-2" />
                      <p className="text-center text-sm text-gray-600 mt-2">
                        Processing document...
                      </p>
                    </div>
                  )}
                  <div className="divide-y divide-gray-100">
                    {documents.length === 0 ? (
                      <div className="p-8 text-center text-gray-500">
                        <FileText className="w-12 h-12 mx-auto mb-4 text-gray-300" />
                        <p>No documents found</p>
                        <Button 
                          variant="outline" 
                          size="sm" 
                          className="mt-4"
                          onClick={handleUploadDialogOpen}
                        >
                          Upload your first document
                        </Button>
                      </div>
                    ) : (
                      documents.map(doc => (
                        <div 
                          key={doc._id} 
                          className={`flex items-center justify-between p-4 hover:bg-gray-50 ${
                            deletingDocumentId === doc._id ? 'bg-gray-50 opacity-70' : ''
                          }`}
                        >
                          <div className="flex items-center gap-3">
                            <FileText className="w-5 h-5 text-gray-400" />
                            <div>
                              <div className="flex items-center gap-2">
                                <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                                {deletingDocumentId === doc._id && (
                                  <span className="text-xs text-red-500 animate-pulse">Deleting...</span>
                                )}
                              </div>
                              <div className="flex items-center gap-2 mt-1">
                                <span className="text-xs text-gray-500">
                                  {formatBytes(doc.metadata?.contentLength || 0)}
                                </span>
                                <span className="text-gray-300">•</span>
                                <span className="text-xs text-gray-500">
                                  {doc.createdAt ? formatDistanceToNow(new Date(doc.createdAt), { addSuffix: true }) : 'Unknown'}
                                </span>
                                {doc.status === 'processing' && doc.progress && (
                                  <>
                                    <span className="text-gray-300">•</span>
                                    <span className="text-xs text-gray-500">
                                      Processing: {doc.progress}%
                                    </span>
                                  </>
                                )}
                                {doc.status === 'completed' && doc.chunks && (
                                  <>
                                    <span className="text-gray-300">•</span>
                                    <span className="text-xs text-gray-500">
                                      {doc.chunks} chunks
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-4">
                            <Badge 
                              className={
                                doc.status === 'completed' 
                                  ? 'bg-emerald-50 text-emerald-700'
                                  : doc.status === 'processing'
                                    ? 'bg-blue-50 text-blue-700'
                                    : 'bg-red-50 text-red-700'
                              }
                            >
                              <div className="flex items-center gap-1">
                                {getStatusIcon(doc.status)}
                                <span>{doc.status}</span>
                              </div>
                            </Badge>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button 
                                  variant="ghost" 
                                  size="icon" 
                                  className="h-8 w-8"
                                  disabled={deletingDocumentId === doc._id}
                                >
                                  {deletingDocumentId === doc._id ? (
                                    <RefreshCw className="w-4 h-4 text-gray-400 animate-spin" />
                                  ) : (
                                    <MoreVertical className="w-4 h-4 text-gray-400" />
                                  )}
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent align="end">
                                <DropdownMenuItem 
                                  onClick={() => {
                                    handleTestQuery(doc);
                                  }}
                                  disabled={deletingDocumentId === doc._id}
                                >
                                  <BrainCircuit className="w-4 h-4 mr-2" />
                                  Test Query
                                </DropdownMenuItem>
                                <DropdownMenuItem 
                                  onClick={() => {
                                    handleDownloadDocument(doc._id, doc.name);
                                  }}
                                  disabled={deletingDocumentId === doc._id}
                                >
                                  <Download className="w-4 h-4 mr-2" />
                                  Download
                                </DropdownMenuItem>
                                <DropdownMenuItem 
                                  className="text-red-600"
                                  onClick={() => {
                                    handleDeleteDocument(doc._id, doc.name);
                                  }}
                                  disabled={deletingDocumentId === doc._id}
                                >
                                  <Trash2 className="w-4 h-4 mr-2" />
                                  Delete
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            {/* Associated Bots */}
            <Card className="shadow-sm">
              <CardHeader>
                <CardTitle className="text-lg font-semibold">Associated Bots</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-3">
                  {(knowledgeBase.associatedBots ?? []).length === 0 ? (
                    <p className="text-sm text-gray-500">No bots associated with this knowledge base</p>
                  ) : (
                    (knowledgeBase.associatedBots ?? []).map((botId, index) => (
                      <div key={index} className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <Bot className="w-4 h-4 text-gray-400" />
                          <span className="text-sm text-gray-900">{botId}</span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </CardContent>
            </Card>

            {/* KB Info */}
            <Card className="shadow-sm">
              <CardHeader>
                <CardTitle className="text-lg font-semibold">Details</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <p className="text-sm text-gray-500">Created</p>
                    <p className="text-sm font-medium text-gray-900">
                      {new Date(knowledgeBase.createdAt || '').toLocaleDateString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Total Documents</p>
                    <p className="text-sm font-medium text-gray-900">
                      {knowledgeBase.documentCount || documents.length}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Total Size</p>
                    <p className="text-sm font-medium text-gray-900">
                      {formatBytes(totalSize)}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {/* Upload Dialog */}
      <Dialog open={uploadDialogOpen} onOpenChange={setUploadDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Upload Document</DialogTitle>
            <DialogDescription>
              Upload a document to add to your knowledge base. Supported formats: PDF, Word, Text, HTML.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4">
            <input
              type="file"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              id="file-upload-input"
              accept=".pdf,.doc,.docx,.txt,.html"
            />
            <label htmlFor="file-upload-input">
              <Button
                variant="outline"
                className="w-full justify-center"
                asChild
              >
                <span className="flex items-center gap-2">
                  <Upload className="w-4 h-4" />
                  {selectedFile ? selectedFile.name : 'Choose File'}
                </span>
              </Button>
            </label>
            {selectedFile && (
              <p className="text-xs text-gray-500 mt-2">
                Size: {formatBytes(selectedFile.size)}
              </p>
            )}
          </div>
          <DialogFooter className="mt-4">
            <Button 
              variant="outline" 
              onClick={handleUploadDialogClose}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleUploadDocument}
              disabled={!selectedFile || uploadProgress > 0}
              className="bg-emerald-600 hover:bg-emerald-700 text-white"
            >
              {uploadProgress > 0 ? 'Processing...' : 'Upload'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Test Query Dialog */}
      {currentTestDocument && (
        <TestQueryDialog 
          open={testQueryDialogOpen} 
          onOpenChange={(open) => {
            setTestQueryDialogOpen(open);
            if (!open) {
              setCurrentTestDocument(null);
            }
          }}
          document={currentTestDocument}
        />
      )}

      {/* Edit Name Dialog */}
      <Dialog open={editNameDialogOpen} onOpenChange={setEditNameDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Edit Knowledge Base Name</DialogTitle>
            <DialogDescription>
              Update the name of your knowledge base.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4">
            <Input
              value={newKnowledgeBaseName}
              onChange={(e) => setNewKnowledgeBaseName(e.target.value)}
              placeholder="Enter new name"
              className="w-full"
            />
          </div>
          <DialogFooter className="mt-4">
            <Button 
              variant="outline" 
              onClick={() => setEditNameDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSaveName}
              disabled={!newKnowledgeBaseName.trim() || newKnowledgeBaseName === knowledgeBase?.name}
              className="bg-emerald-600 hover:bg-emerald-700 text-white"
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Toaster position="bottom-right" />
    </div>
  );
};

export default KnowledgeBaseDetailContent; 