import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { setHoveredDate } from '@common-reducers/WhatsAppReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import moment from 'moment';
import classes from "./message-calendar.module.scss";
import { memo } from 'react';
import { getIsDayCellHovered } from '@common/reducers/CalendarSelectors';

interface P {
    date: Date;
    label: string;
}

export const CustomDayCell = memo(({ date, label }: P) => {
    const dispatch = useAppDispatch()

    const isHovered = useAppSelector(state => getIsDayCellHovered(state, date))

    const isToday = moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')


    const handleMouseEnter = () => {
        dispatch(setHoveredDate(date))
    };
    const handleMouseLeave = () => {
        dispatch(setHoveredDate(undefined))
    };


    return (
        <div className={classes.customDayCell} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span className={classes.dayLabel}
                style={{
                    width: '30px',
                    height: '30px',
                    padding: '2px 3px 0px 0px',
                    margin: '1px',
                    position: 'relative',
                }}>
                {isToday && <CalendarIcon />}
                {label}
            </span>
            <IconButton size="small" className={classes.addButton} sx={{
                opacity: isHovered ? '1 !important' : 0,
                transition: 'opacity 0.3s ease-out'
            }}>
                <AddIcon fontSize="small" />
            </IconButton>
        </div>
    );
});



const CalendarIcon = ({ color = '#f70000' }) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="21pt" height="21pt" viewBox="0 0 1280.000000 1264.000000"
        preserveAspectRatio="xMidYMid meet"
        style={{
            position: 'absolute',
            top: '-1px',
            left: '3px',
        }}>
        <g transform="translate(0.000000,1264.000000) scale(0.100000,-0.100000)"
            fill={color} stroke="none">
            <path d="M5925 12625 c-248 -21 -380 -39 -378 -51 1 -7 -56 -18 -148 -29 -83
   -9 -190 -25 -237 -35 -244 -53 -454 -105 -602 -151 -91 -28 -210 -64 -264 -81
   -465 -138 -1038 -415 -1493 -719 -234 -157 -585 -429 -773 -600 -215 -196
   -538 -518 -659 -659 -284 -330 -532 -685 -692 -995 -77 -148 -267 -563 -324
   -705 -107 -271 -232 -718 -289 -1030 -50 -277 -61 -391 -61 -650 0 -246 7
   -324 27 -318 19 7 37 72 78 278 21 107 43 218 48 245 8 39 9 25 4 -65 -4 -63
   -7 -219 -7 -347 0 -255 6 -282 45 -216 17 27 29 79 46 188 l23 150 -6 -191
   c-3 -129 -1 -221 7 -283 13 -91 13 -92 26 -61 10 23 34 227 34 286 0 2 7 4 15
   4 25 0 64 152 126 491 32 179 72 384 89 455 35 146 79 298 85 292 3 -2 -2 -39
   -10 -82 -18 -92 -17 -86 -5 -86 6 0 10 8 10 18 1 12 5 9 16 -11 8 -15 19 -25
   25 -22 5 4 25 37 44 73 20 40 34 59 35 47 0 -12 4 -25 9 -30 5 -6 5 -59 0
   -120 -11 -153 4 -275 33 -275 13 0 42 71 108 268 67 195 71 204 80 177 5 -14
   15 -1 40 56 75 165 285 573 365 707 101 171 155 249 427 613 292 390 419 544
   672 809 125 131 146 145 66 45 -143 -181 -214 -315 -166 -315 18 0 124 82 321
   249 593 504 995 778 1507 1025 559 271 1128 428 1798 498 168 17 793 17 960 0
   325 -34 662 -97 926 -172 1721 -488 3064 -1775 3618 -3467 119 -365 196 -746
   238 -1178 16 -171 16 -682 0 -860 -63 -686 -245 -1327 -557 -1960 -413 -840
   -1042 -1558 -1830 -2090 -883 -595 -1871 -899 -3025 -931 -917 -26 -1785 174
   -2670 616 -141 70 -106 38 50 -46 651 -351 1351 -559 2140 -636 180 -17 819
   -17 1000 0 1357 132 2511 667 3474 1612 103 102 234 237 289 300 137 156 146
   164 71 60 -18 -25 -32 -53 -31 -62 3 -23 -225 -278 -400 -449 -389 -378 -806
   -687 -1263 -932 -248 -133 -705 -304 -1061 -396 -432 -112 -759 -157 -1319
   -181 -140 -6 -292 -15 -337 -21 -203 -24 -781 41 -1163 132 -885 211 -1709
   627 -2425 1225 -119 100 -403 375 -586 569 -147 155 -313 363 -449 563 -35 51
   -65 90 -68 87 -15 -15 245 -368 409 -556 46 -53 119 -137 162 -187 110 -127
   430 -438 572 -558 576 -480 1211 -831 1915 -1058 206 -67 518 -145 715 -181
   77 -13 163 -29 190 -34 30 -5 39 -9 23 -10 -53 -2 -386 60 -586 110 -385 95
   -769 234 -1117 405 -851 418 -1542 989 -2093 1732 -58 78 -107 140 -110 137
   -6 -5 68 -110 177 -250 496 -637 1112 -1160 1806 -1532 882 -472 1890 -706
   2925 -679 135 3 283 8 330 11 l85 4 -88 -14 c-162 -25 -410 -38 -687 -38 -620
   1 -1087 74 -1620 253 -488 164 -1043 444 -1460 736 -1013 709 -1779 1701
   -2169 2807 -65 186 -84 177 -22 -10 311 -944 925 -1853 1713 -2538 43 -37 78
   -70 78 -72 0 -3 -26 16 -57 43 -353 296 -662 622 -938 989 -226 300 -383 560
   -602 995 -85 168 -161 312 -169 320 -12 12 -14 12 -14 -5 0 -24 19 -87 54
   -179 14 -37 24 -72 21 -77 -17 -27 300 -594 496 -886 353 -526 832 -1029 1354
   -1423 146 -109 419 -290 580 -384 164 -95 184 -110 70 -50 -272 141 -644 395
   -930 634 -449 374 -826 792 -1143 1268 -116 172 -235 372 -228 379 8 8 -54
   126 -79 148 -11 11 -36 56 -54 100 -18 44 -57 125 -86 180 -107 200 -264 636
   -350 969 -110 428 -165 790 -185 1231 -14 292 -17 314 -18 135 -5 -434 80
   -1004 223 -1501 165 -571 420 -1128 741 -1616 52 -79 92 -149 89 -154 -12 -18
   130 -212 375 -513 17 -21 4 -14 -40 23 -74 61 -87 54 -41 -22 l31 -52 -46 50
   c-76 81 -272 329 -376 475 -323 454 -602 985 -785 1495 -28 77 -56 154 -62
   170 l-11 30 4 -35 c2 -19 32 -114 66 -210 307 -859 797 -1636 1440 -2281 204
   -204 313 -302 540 -484 246 -197 366 -285 570 -420 863 -573 1830 -901 2872
   -975 216 -15 656 -12 879 6 751 60 1493 262 2168 590 577 280 1163 678 1588
   1078 57 53 105 96 107 96 17 0 -37 -70 -102 -131 -399 -381 -986 -784 -1515
   -1039 -844 -408 -1717 -611 -2677 -625 -975 -13 -1900 191 -2784 615 -901 432
   -1681 1072 -2289 1880 -674 895 -1082 1897 -1217 2990 -29 238 -51 380 -60
   385 -30 18 -16 -313 25 -588 155 -1055 576 -2051 1219 -2887 636 -827 1487
   -1494 2446 -1918 1108 -490 2280 -647 3510 -471 721 103 1501 368 2144 729
   801 448 1448 1019 2014 1775 431 575 781 1295 987 2030 155 554 223 1053 239
   1755 1 74 2 -25 0 -220 -1 -237 -8 -407 -18 -510 -108 -1043 -415 -1944 -957
   -2809 -60 -96 -108 -176 -105 -178 7 -8 73 81 154 207 491 769 804 1643 926
   2580 48 370 65 930 37 1265 -60 737 -217 1389 -481 2000 -28 66 -65 160 -82
   209 -154 458 -537 1096 -958 1596 -147 174 -459 487 -625 624 -287 238 -632
   480 -835 585 -416 214 -583 292 -850 396 -74 29 -207 82 -295 117 -479 195
   -1020 322 -1635 384 -230 23 -864 28 -1095 9z m-1790 -502 c-685 -272 -1356
   -675 -1885 -1132 -142 -123 -401 -375 -540 -526 -91 -99 -89 -91 9 30 261 322
   768 749 1256 1057 338 214 800 451 1080 554 90 33 156 47 80 17z m-814 -577
   c-7 -8 -53 -39 -104 -69 -389 -232 -858 -607 -1152 -919 -107 -114 -412 -408
   -371 -358 15 19 88 100 161 180 391 426 844 798 1349 1107 66 40 122 73 124
   73 3 0 -1 -6 -7 -14z m-2171 -2051 c0 -2 -18 -34 -40 -70 -51 -83 -137 -254
   -209 -416 -31 -69 -66 -135 -77 -147 -39 -42 -261 -553 -324 -747 -21 -67 -40
   -103 -40 -78 0 38 140 422 225 619 101 234 264 541 421 797 25 40 44 59 44 42z
   m-436 -952 c-82 -196 -218 -605 -267 -803 -37 -150 -82 -396 -116 -628 -11
   -73 -24 -139 -29 -145 -5 -7 -12 -21 -15 -32 -12 -41 5 96 39 316 37 245 84
   449 163 710 74 248 249 709 258 682 2 -6 -13 -51 -33 -100z m-537 -1355 c-3
   -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m128 -357 c-4 -25 -10 -40
   -12 -33 -3 7 -2 39 3 70 5 36 9 48 12 33 2 -13 1 -45 -3 -70z m2181 -5032
   c596 -523 1291 -920 2044 -1169 354 -117 757 -206 1173 -260 140 -18 197 -29
   312 -59 57 -15 -83 -6 -291 19 -1347 163 -2586 767 -3509 1711 l-120 123 136
   -130 c74 -72 189 -177 255 -235z m3177 -1136 c-7 -2 -21 -2 -30 0 -10 3 -4 5
   12 5 17 0 24 -2 18 -5z"/>
            <path d="M11489 2413 c-428 -544 -921 -999 -1494 -1378 -798 -529 -1692 -868
   -2615 -994 -200 -28 -228 -33 -212 -38 20 -8 331 36 527 73 949 183 1837 577
   2620 1165 349 261 677 566 963 893 128 147 276 335 269 342 -2 3 -29 -26 -58
   -63z"/>
        </g>
    </svg>
);
