import UserControlPanel from "@common-components/user-control-panel/user-control-panel";
import WorkspaceDropDown from "@common-components/workspace-dropdown/workspace-dropdown";
import { isBoardPanelDrawerOpen } from "@common-reducers/BoardsSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import NewMessageFab from "@common/components/new-message-fab/new-message-fab";
import ExtensionSidebarContactList from '@common/components/scheduled-contact-list/extension-sidebar-contact-list';
import ExtensionSidebarSearchBox from '@common/components/scheduled-contact-list/extension-sidebar-search-box';
import ToggleCalendarAgenda from "@common/components/toggle-calendar-agenda/toggle-calendar-agenda";
import { isExtensionContext } from "@common/services/detect-context";
import { useSafeLocation } from "@common/services/utils";
import { Drawer, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  ReactElement,
  memo,
} from "react";
import BoardsList from "./boards-list";
import NewBoardButton2 from "./new-board-button2";
import SideBarHeader from './side-bar-header';

function BoardsPanel({ activeExtensionView }: { activeExtensionView?: string }): ReactElement {
  console.log("boards panel rendering")

  const drawerIsOpen = useAppSelector((state) => isBoardPanelDrawerOpen(state))
  const direction = useAppSelector((state) => state.UserReducer.direction);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(786))
  const location = useSafeLocation();
  const isExtension = isExtensionContext();

  return (
    <>
      <Drawer
        sx={{
          width: isDesktop ? drawerIsOpen ? "300px" : '80px' : "80px",
          transition: '0.2s',
          [`& .MuiDrawer-paper`]: {
            background: "var(--boards-panel-background)",
            transition: '0.2s',
            borderRight: "1px solid var(--separator-line)",
            width: isDesktop ? drawerIsOpen ? "300px" : '80px' : '100%',
            overflow: 'visible',
          },
        }}
        variant={isDesktop ? "permanent" : 'temporary'}
        open={drawerIsOpen}
        anchor={direction === "ltr" ? 'left' : 'right'}
      >
        <Stack justifyContent={'space-between'} height={'100%'}>
          <Stack>
            <SideBarHeader drawerIsOpen={drawerIsOpen} direction={direction} />
            {(activeExtensionView === "calendar" || location.pathname.includes("/calendar")) &&
              <Stack >
                <ToggleCalendarAgenda />

                <ExtensionSidebarSearchBox />

                <ExtensionSidebarContactList />
              </Stack>
            }

            {location.pathname.includes("/tasks") &&
              <>
                <Stack sx={{ width: "100%" }} mt={2} spacing={2} >
                  <WorkspaceDropDown drawerIsOpen={drawerIsOpen} />
                  <NewBoardButton2 drawerIsOpen={drawerIsOpen} />
                  <BoardsList drawerIsOpen={drawerIsOpen} />
                </Stack>

              </>
            }
          </Stack>

          {!isExtension &&
            <UserControlPanel isSideMenuOpen={drawerIsOpen} />
          }
        </Stack>
      </Drawer>
      {(activeExtensionView === "calendar" || location.pathname.includes("/calendar")) && (
        <NewMessageFab />
      )}
    </>
  );
}

export default memo(BoardsPanel);