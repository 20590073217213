import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import ConnectedEngines from "@common/components/connected-engines/connected-engines";
import { isExtensionContext } from "@common/services/detect-context";
import BoardsPanel from "@common/views/boards-panel/BoardsPanel";
import { Close } from '@mui/icons-material';
import { IconButton, Stack } from "@mui/material";
import { ReactNode } from "react";
import classes from "./app-view.module.scss";
import { mainSliceActions } from "@common/reducers/MainReducer";

interface AppViewProps {
  children: ReactNode;
  title?: string;
  activeExtensionView?: string;
  onClose?: () => void;
  showHeader?: boolean;
}

const AppView = ({ 
  children, 
  title = "", 
  activeExtensionView, 
  onClose, 
  showHeader = true 
}: AppViewProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else if (isExtensionContext()) {
      dispatch(mainSliceActions.toggleCalendarView(false));
    }
  };

  return (
    <div className={classes.pageContainer}>
      <BoardsPanel
        activeExtensionView={activeExtensionView}
      />
      <Stack sx={{
        flex: 1,
        transition: 'all 0.2s',
      }}>
        {showHeader && (
          <div className={classes.titleContainer}>
            <h4 className={classes.pageTitle}>{title}</h4>
            <div className={classes.headerActions}>
              <ConnectedEngines />
              {isExtensionContext() && (
                <IconButton onClick={handleClose}>
                  <Close sx={{ color: 'var(--primary)', fontSize: '24px !important', }} />
                </IconButton>
              )}
            </div>
          </div>
        )}

        <div className={`${classes.contentContainer} ${!showHeader ? classes.noHeaderContent : ''}`}>
          {children}
        </div>
      </Stack>
    </div>
  );
};

export default AppView; 