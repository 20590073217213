import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToolDefinition } from '@common/types/tool-types';
import { getAvailableToolsThunk } from '@common-services/tools.service';

interface ToolsState {
  availableTools: ToolDefinition[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ToolsState = {
  availableTools: [],
  isLoading: false,
  error: null,
};

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setAvailableTools: (state, action: PayloadAction<ToolDefinition[]>) => {
      state.availableTools = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableToolsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAvailableToolsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.availableTools = action.payload;
      })
      .addCase(getAvailableToolsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch tools';
      });
  },
});

export const { setAvailableTools } = toolsSlice.actions;

export default toolsSlice.reducer; 