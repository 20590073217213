import { SideBarItem } from "@common-components/sidebar/sidebar";
import ThemeAsset from "@common-components/theme/theme-asset";
import { appConfig } from "@common-config/app.config";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { useRouter } from "@common-reducers/hooks/use-router";
import { selectCurrentUser } from "@common-reducers/UserSelectors";
import { SidebarLinkButton } from "@common/components/sidebar/sidebar-link-button";
import { isExtensionContext } from "@common/services/detect-context";
import { useSafeLocation } from "@common/services/utils";
import { Book, CalendarMonth, Campaign, SmartToy, Task } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import classes from "./boards-panel.module.scss";

interface SideBarHeaderProps {
    drawerIsOpen: boolean;
    direction: string;
}

export default function SideBarHeader({ drawerIsOpen, direction }: SideBarHeaderProps) {
    const router = useRouter();
    const location = useSafeLocation();
    const active = location.pathname === '/calendar' ? SideBarItem.Calendar :
        location.pathname === '/launch-campaign' ? SideBarItem.Campaigns :
            location.pathname === '/tasks' ? SideBarItem.Tasks :
                location.pathname === '/bot-list-view' ? SideBarItem.Agents :
                    location.pathname === '/knowledge-base' ? SideBarItem.KnowledgeBase :
                        undefined;
    const user = useAppSelector(state => selectCurrentUser(state));
    const isExtension = isExtensionContext();

    const navigateToHome = () => {
        router.navigate(`/tasks`);
    }

    const handleSupportClick = (e: React.MouseEvent) => {
        e.preventDefault();
        router.navigate('/support');
    }

    return (
        <Box >
            <Box className={classes.panel_header}>
                <ThemeAsset
                    assetName='logo.svg'
                    onClick={navigateToHome}
                    style={{ width: drawerIsOpen ? 'initial' : 32 }}
                    className={classes.logo}
                    alt={`${appConfig.DEFAULT_TITLE}-logo`}
                />
            </Box>

            {!isExtension && (
                <Stack >
                    <SidebarLinkButton
                        to="/calendar"
                        icon={CalendarMonth}
                        title="Message Planner"
                        active={SideBarItem.Calendar}
                        currentActive={active}
                        analyticsAction="switch_to_calendar"
                    />

                    <SidebarLinkButton
                        to="/launch-campaign"
                        icon={Campaign}
                        title="Launch Campaign"
                        active={SideBarItem.Campaigns}
                        currentActive={active}
                        analyticsAction="switch_to_campaigns"
                    />

                    <SidebarLinkButton
                        to="/tasks"
                        icon={Task}
                        title="Tasks"
                        active={SideBarItem.Tasks}
                        currentActive={active}
                        analyticsAction="switch_to_tasks"
                    />

                    {(user?.role === "support" || user?.role === "admin") &&
                        <SidebarLinkButton
                            to="/bot-list-view"
                            icon={SmartToy}
                            title="Agents"
                            active={SideBarItem.Agents}
                            currentActive={active}
                            analyticsAction="switch_to_agents"
                        />
                    }

                    {(user?.role === "support" || user?.role === "admin") &&
                        <SidebarLinkButton
                            to="/knowledge-base"
                            icon={Book}
                            title="Knowledge Base"
                            active={SideBarItem.KnowledgeBase}
                            currentActive={active}
                            analyticsAction="switch_to_knowledge_base"
                        />
                    }

                    {(user?.role === "support" || user?.role === "admin") &&
                        <Stack>
                            <button type="button"
                                style={{ paddingInline: "16px", textDecoration: 'none', color: 'inherit', background: 'none', border: 'none', cursor: 'pointer' }}
                                onClick={handleSupportClick}
                            >
                                Support
                            </button>
                        </Stack>
                    }
                </Stack>
            )}
            <Divider />

        </Box>
    );
} 