import { backendApi } from '../reducers/backend-api/backend-api';
import { ToolDefinition, GetToolsResponse } from '../types/tool-types';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Create a new endpoint for tools actions
const toolsApi = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableTools: builder.mutation<GetToolsResponse, void>({
      query: () => ({
        url: 'actions',
        method: 'POST',
        body: {
          cmd: 'tools.getAvailable'
        }
      })
    })
  })
});

// Export the generated hooks for use in React components
export const { useGetAvailableToolsMutation } = toolsApi;

/**
 * Thunk to get all available tools from the backend
 * This can be dispatched from both React and non-React contexts
 */
export const getAvailableToolsThunk = createAsyncThunk(
  'tools/getAvailable',
  async (_, { dispatch }) => {
    try {
      const result = await dispatch(
        toolsApi.endpoints.getAvailableTools.initiate()
      );
      
      if ('data' in result && result.data.success) {
        return result.data.tools;
      }
      
      return [];
    } catch (error) {
      console.error('Error getting available tools:', error);
      return [];
    }
  }
);

// For backward compatibility, provide a function with the same name
// that dispatches the thunk
export const getAvailableTools = async (): Promise<ToolDefinition[]> => {
  // This is just a placeholder that will be replaced in the actual implementation
  // with proper store access
  console.warn('getAvailableTools is deprecated, use getAvailableToolsThunk instead');
  return [];
}; 