import { getCurrentBotSelector } from '@common-reducers/ChatbotSelectors';
import { botDeploymentSelectOneObjectByQuery, DBBotDeploymentThunks } from '@common-reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { CheckCircleOutlineRounded, ErrorOutlineRounded, History } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { useEffect } from 'react';

const commonAlertStyles = {
    backgroundColor: 'transparent',
    padding: '0 10px',
    width: 'max-content',
    '& .MuiPaper-root': {
        display: 'flex',
        alignItems: 'center',
        minHeight: 'unset',
        padding: '0'
    },
    '& .MuiAlert-icon': {
        padding: 0,
        marginRight: '2px',
        fontSize: '14px',
        height: '20px',
        marginTop: '2px'
    },
    '& .MuiAlert-message': {
        padding: '0',
        width: 'max-content',
        fontWeight: '600',
        lineHeight: '30px',
        display: 'flex',
        alignItems: 'center'
    }
};

export const BotStatusForEditor: React.FC = () => {
    const dispatch = useAppDispatch();
    const isDraft = useAppSelector(state => state.ChatbotReducer.isDraft);
    const currentBot = useAppSelector(getCurrentBotSelector);
    const activeDeployment = useAppSelector(state =>
        botDeploymentSelectOneObjectByQuery(state.DBBotDeploymentReducer, { _id: currentBot?.activeDeploymentId }));

    useEffect(() => {
        // fetch active deployment from db
        if (currentBot?.activeDeploymentId) {
            dispatch(DBBotDeploymentThunks.find({ _id: currentBot?.activeDeploymentId }))
        }
    }, [currentBot?.activeDeploymentId]);

    return (
        <>
            {!isDraft && !currentBot?.activeDeploymentId && (
                <Alert
                    icon={<ErrorOutlineRounded sx={{ color: '#a96b0b' }} />}
                    severity="warning"
                    sx={{ ...commonAlertStyles, color: '#a96b0b' }}
                >
                    No active deployment
                </Alert>
            )}

            {!isDraft && currentBot?.activeDeploymentId && (
                <Alert
                    icon={<CheckCircleOutlineRounded sx={{ color: '#729da0' }} />}
                    severity="success"
                    sx={{ ...commonAlertStyles, color: '#729da0' }}
                >
                    Active deployment: v{activeDeployment?.version}
                </Alert>
            )}

            {isDraft && currentBot?.activeDeploymentId && (
                <Alert
                    icon={<ErrorOutlineRounded sx={{ color: '#4856df' }} />}
                    severity="info"
                    sx={{
                        ...commonAlertStyles,
                        color: '#4856df',
                        '& .MuiAlert-message': {
                            ...commonAlertStyles['& .MuiAlert-message'],
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px'
                        }
                    }}
                >
                    Draft changes
                    <span style={{
                        color: '#899098',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: '400',
                        fontSize: '12px',
                        marginLeft: '4px',
                        gap: '2px'
                    }}>
                        <History sx={{ fontSize: 16 }} /> Active v{activeDeployment?.version}
                    </span>
                </Alert>
            )}

            {isDraft && !currentBot?.activeDeploymentId && (
                <Alert
                    icon={<ErrorOutlineRounded sx={{ color: '#4856df' }} />}
                    severity="info"
                    sx={{ ...commonAlertStyles, color: '#4856df' }}
                >
                    Draft changes
                </Alert>
            )}
        </>
    );
}; 