import { parseOldUnits, toRuleSet } from "@common-components/schedule-picker/recurrence-utils";
import { UserMessage } from "@common-models/user-message";
import { WASession } from "@common-models/wasession";
import { sessionSelectOneObjectByQuery, userMessagesSelectByQuery, waContactsCacheSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { defaultEventcolor, Color } from "@common/views/calendar/message-color";
import { WaContact } from "@common/models/waContact";
import { CommonRootState } from "@common/types/common-root-state-type";
import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { RRule, RRuleSet } from "rrule";
import { selectCurrentUserSession, selectUserId, selectUserObject } from "./UserSelectors";
import { ParsedOptions } from "rrule/dist/esm/types";

/**
 * Selector to get the current month start string from the calendar state
 * This ensures all components use the same month string format
 */
export const getViewMonthStartStr = createSelector(
    [(state: CommonRootState) => state.CalendarReducer.currentDate],
    (currentDate) => {
        return moment(currentDate).startOf("month").toISOString();
    }
);

/**
 * Custom equality function for comparing arrays of events
 * This is more efficient than deep comparison for large arrays
 */
export const eventsEqualityFn = (prev: UserMessage[], next: UserMessage[]): boolean => {
    // Always return false during initial load to ensure we get events
    if (!prev || !next) return false;

    // If the arrays have different lengths, they're definitely different
    if (prev.length !== next.length) {
        console.log(`Events length changed: ${prev.length} -> ${next.length}`);
        return false;
    }

    // If both arrays are empty, they're equal
    if (prev.length === 0 && next.length === 0) return true;

    // Check a sample of events instead of the entire array
    // This is more efficient for large arrays
    const sampleSize = Math.min(5, prev.length);
    const sampleIndices = [0, prev.length - 1];

    // Add some indices in the middle for larger arrays
    if (prev.length > 10) {
        sampleIndices.push(
            Math.floor(prev.length / 4),
            Math.floor(prev.length / 2),
            Math.floor(3 * prev.length / 4)
        );
    }

    // Check the sample events
    for (let i = 0; i < Math.min(sampleSize, sampleIndices.length); i++) {
        const idx = sampleIndices[i];
        if (prev[idx]?.id !== next[idx]?.id ||
            prev[idx]?.dueDate !== next[idx]?.dueDate ||
            prev[idx]?.messageColor !== next[idx]?.messageColor) {
            console.log(`Events changed at index ${idx}`);
            return false;
        }
    }

    // If all samples match, consider the arrays equal
    return true;
};

export const hasActiveSocketSession = createSelector(
    [
        (state) => state,
        (state) => selectUserId(state),
    ],
    (state: CommonRootState, userId) => {
        const session = sessionSelectOneObjectByQuery(state.DBSessionReducer, {
            status: { $in: ["connected"] },
            owner: userId
        });
        return !!session;
    }
);


export const getIsContactChecked = createSelector(
    [
        (state, wid) => state,
        (state, wid) => wid,
    ],
    (state, wid) => {
        const importedContactsArray = state.WhatsAppReducer.importedContactsArray

        const isChecked = importedContactsArray?.filter(contact => contact?.whatsappId === wid).length > 0

        return isChecked
    }
);

export const calculatedWaContactName = (waContact: any): string => {
    const fullName = [waContact?.firstName, waContact?.lastName].filter(Boolean).join(' ');
    return waContact?.name || waContact?.displayName || waContact?.shortName || fullName || waContact?.mobile;
}
export const extractPhoneNumber = (id: string, withoutPlus?: boolean): string => {
    if (typeof id !== 'string') return "";

    const phoneNumber = id.split('@')[0];
    return phoneNumber ? (withoutPlus ? phoneNumber : `+${phoneNumber}`) : '';
};



export const getWaContactDisplayLabel = (contact: any): string => {

    return contact?.formattedTitle
        ?? calculatedWaContactName(contact)
        ?? contact?.waName
        ?? contact?.displayName
        ?? contact?.notifyName
        ?? contact?.notify
        ?? contact?.verifiedName
        ?? contact?.pushname
        ?? extractPhoneNumber(contact?.id);
}

export const selectContactLabelByWhatsappId = createSelector(
    [
        (state) => state,
        (state: CommonRootState, whatsappId: string) => whatsappId,
    ],
    (state: CommonRootState, whatsappId: string) => {
        const contact = selectWhatsappContactById(state, whatsappId);
        return getWaContactDisplayLabel(contact);
    }
);

// Selector to select a whatsapp contact by id
export const selectWhatsappContactById = createSelector(
    [
        (state) => state.WhatsAppReducer.contactMap,
        (state: CommonRootState, whatsappId: string) => whatsappId,
        (state) => state,
    ],
    (contactMap, whatsappId: string, state) => {
        return waContactsCacheSelectOneObjectByQuery(state.DBWaContactsCacheReducer, {
            'id._serialized': whatsappId,
        });
    }
);

// Selector to select a whatsapp contact name by id
export const selectWhatsappContactName = createSelector(
    [
        selectWhatsappContactById,
    ],
    (contact) => {
        //console.log('contact', contact)
        return calculatedWaContactName(contact);
    }
);

export const selectIsGroup = createSelector(
    [
        (whatsappId: string) => whatsappId,
    ],
    (whatsappId: string) => {
        return whatsappId?.includes && whatsappId?.includes('@g.us');
    }
)

// Selector to select a profile picture by id
export const selectProfilePic = createSelector(
    [
        (state) => state,
        (state: CommonRootState, whatsappId: string) => whatsappId,
    ],
    (state, whatsappId: string) => {


        const profilePicUrlFromRedisContacts = waContactsCacheSelectOneObjectByQuery(state.DBWaContactsCacheReducer, {
            'id._serialized': whatsappId,
        });

        return profilePicUrlFromRedisContacts?.imgUrl

    }
);


export const getIsSocketConnectedToWhatsApp = createSelector(
    [
        (state) => selectCurrentUserSession(state),
    ],
    (session: WASession) => {
        return session?.active && session?.status === "connected";
    }
)

/**
 * Selector to check if messages are currently loading
 */
export const getIsMessagesLoading = createSelector(
    [(state: CommonRootState) => state.DBUserMessageReducer?.itemsLoading],
    (loading) => {
        return loading === true;
    }
);

// Create a more specific selector for messages to reduce unnecessary recalculations
export const getFilteredMessages = createSelector(
    [
        (state: CommonRootState) => state.DBUserMessageReducer,
        (state: CommonRootState) => getIsMessagesLoading(state)
    ],
    (dbUserMessageReducer, isLoading) => {
        // Add debug logging to see if this selector is being called
        console.log('getFilteredMessages called - input changed, loading:', isLoading);

        // If messages are loading, return empty array to prevent calculations with partial data
        if (isLoading) {
            console.log('Messages are loading, returning empty array');
            return [];
        }

        // Check if the reducer is properly initialized
        if (!dbUserMessageReducer || !dbUserMessageReducer.entities) {
            console.warn('DBUserMessageReducer not properly initialized');
            return [];
        }

        const messages = userMessagesSelectByQuery(dbUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        });
        console.log(`Found ${messages.length} filtered messages`);

        // Log some sample messages for debugging
        if (messages.length > 0) {
            console.log('Sample message:', JSON.stringify(messages[0], null, 2));
        }

        return messages;
    }
);

// Create a selector for user preferences to avoid recalculations when only messages change
export const getUserCalendarPreferences = createSelector(
    [(state: CommonRootState) => selectUserObject(state)],
    (selectedUser) => {
        // Add debug logging
        console.log('getUserCalendarPreferences called - input changed');
        const calendarStartDayFromDB = ((selectedUser?.preferences?.calendarStartDay ?? 0) + 8) % 7; // 0 is Monday in RBC
        return { calendarStartDayFromDB };
    }
);

// Create a memoized selector for the date range to avoid recalculations
export const getDateRange = createSelector(
    [
        (state: CommonRootState) => getViewMonthStartStr(state),
        (state: CommonRootState) => getUserCalendarPreferences(state).calendarStartDayFromDB,
    ],
    (viewMonthStartStr, calendarStartDayFromDB) => {
        console.log('getDateRange called - input changed:', viewMonthStartStr);
        const monthStart = moment(viewMonthStartStr).startOf("month");
        const monthEnd = moment(viewMonthStartStr).endOf("month");

        // Calculate view range boundaries
        const diffStart = (monthStart.day() - calendarStartDayFromDB + 7) % 7;
        const rangeStart = monthStart.clone().subtract(diffStart, "days").utc().toDate();

        const lastDayOfWeek = (calendarStartDayFromDB + 6) % 7;
        const diffEnd = (lastDayOfWeek - monthEnd.day() + 7) % 7;
        const rangeEnd = monthEnd.clone().add(diffEnd, "days").utc().toDate();

        return {
            rangeStart,
            rangeEnd,
            rangeStartTime: rangeStart.getTime(),
            rangeEndTime: rangeEnd.getTime()
        };
    }
);

// Selector for non-recurring events
export const getNonRecurringEvents = createSelector(
    [
        (state: CommonRootState) => getFilteredMessages(state),
        (state: CommonRootState) => getDateRange(state),
    ],
    (messages, dateRange) => {
        // Add debug logging
        console.log('getNonRecurringEvents called - input changed');

        // Filter non-recurring messages
        const nonRecurringMessages = messages.filter(message => !message.isRecurring);

        // Filter messages that fall within the date range
        const events = nonRecurringMessages.filter(message => {
            const dueDate = new Date(message.dueDate).getTime();
            return dueDate >= dateRange.rangeStartTime && dueDate <= dateRange.rangeEndTime;
        }).map(message => ({
            ...message,
            messageColor: message.messageColor || defaultEventcolor,
        }));

        console.log(`Found ${events.length} non-recurring events`);
        return events;
    }
);

// Helper function for processing recurring events
// This is outside the selector to prevent unnecessary recreations
const processRecurringEvents = (
    recurringMessages: UserMessage[],
    dateRange: ReturnType<typeof getDateRange>
): UserMessage[] => {
    console.log(`Processing ${recurringMessages.length} recurring messages`);
    console.log(`Date range: ${dateRange.rangeStart.toISOString()} to ${dateRange.rangeEnd.toISOString()}`);

    if (recurringMessages.length === 0) {
        return [];
    }

    const events: UserMessage[] = [];

    // Process each recurring message
    recurringMessages.forEach(message => {
        try {
            if (!message.rruleset) {
                return;
            }

            // Parse the rruleset
            const rruleSet = new RRuleSet();

            // Handle old format
            if (message.rruleset.indexOf("RRULE:") === -1) {
                // Handle old format with recurenceUnit and recurrenceQuantity
                if (message.recurenceUnit) {
                    const ruleset = parseOldUnits(message.recurenceUnit, message.recurrenceQuantity || 1);
                    ruleset.rrules().forEach(rule => {
                        rruleSet.rrule(rule);
                    });
                } else {
                    // Try to parse as RFC string directly
                    try {
                        const ruleset = toRuleSet(message.rruleset);
                        ruleset.rrules().forEach(rule => {
                            rruleSet.rrule(rule);
                        });
                    } catch (error) {
                        console.error(`Error parsing rruleset: ${error}`);
                    }
                }
            } else {
                // Handle new format
                const lines = message.rruleset.split("\n").filter(line => line.trim() !== "");

                lines.forEach(line => {
                    if (line.startsWith("RRULE:")) {
                        const rruleString = line.substring(6);
                        const rule = RRule.fromString(rruleString);
                        rruleSet.rrule(rule);
                    }
                    // Add support for other rrule components if needed
                });
            }

            // Get occurrences within the date range
            const occurrences = rruleSet.between(
                dateRange.rangeStart,
                dateRange.rangeEnd,
                true
            );

            console.log(`Message ${message._id} has ${occurrences.length} occurrences in range`);

            // Create an event for each occurrence
            occurrences.forEach(date => {
                const eventDate = new Date(date);

                // Create a new event based on the original message
                const event = {
                    ...message,
                    dueDate: eventDate.toISOString(),
                    originalId: message._id, // Store the original message ID
                    isRecurringInstance: true, // Mark as a recurring instance
                    messageColor: message.messageColor || defaultEventcolor,
                };

                events.push(event);
            });
        } catch (error) {
            console.error(`Error processing recurring message ${message._id}:`, error);
        }
    });

    console.log(`Generated ${events.length} recurring events`);
    return events;
};

// Selector for recurring events
export const getRecurringEvents = createSelector(
    [
        (state: CommonRootState) => getFilteredMessages(state),
        (state: CommonRootState) => getDateRange(state),
    ],
    (messages, dateRange) => {
        // Add debug logging
        console.log('getRecurringEvents called - input changed');
        const recurringMessages = messages.filter(message => message.isRecurring);
        console.log(`Found ${recurringMessages.length} recurring messages`);

        // Only process if we have recurring messages
        if (recurringMessages.length === 0) {
            return [];
        }

        const events = processRecurringEvents(recurringMessages, dateRange);
        console.log(`Generated ${events.length} recurring events`);
        return events;
    }
);

// Main selector that combines non-recurring and recurring events
// export const getCalendarEvents = createSelector(
//     [
//         (state: CommonRootState) => getNonRecurringEvents(state),
//         (state: CommonRootState) => getRecurringEvents(state),
//         (state: CommonRootState) => getIsMessagesLoading(state)
//     ],
//     (nonRecurringEvents, recurringEvents, isLoading) => {
//         // Add performance profiling
//         const startTime = performance.now();
//         console.log('getCalendarEvents result function called - inputs changed, loading:', isLoading);

//         // If messages are loading, return empty array
//         if (isLoading) {
//             console.log('Messages are loading, returning empty events array');
//             return [];
//         }

//         // Combine all events
//         const events = [...nonRecurringEvents, ...recurringEvents];

//         // Log performance metrics
//         const endTime = performance.now();
//         console.log(`getCalendarEvents execution time: ${endTime - startTime}ms for ${events.length} events`);

//         return events;
//     }
// );


export const getUserMessagesWithoutCampaignId = createSelector(
    [(state: CommonRootState) => state],
    (state) => {
        let messages: UserMessage[] = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        })
        return messages.filter(message => !message.campaignId);
    }
);


export const getCalendarEvents = createSelector(
    [
        (state: CommonRootState) => state,
        (state: CommonRootState) => getViewMonthStartStr(state)
    ],
    (state, viewMonthStartStr) => {
        let messages: UserMessage[] = getUserMessagesWithoutCampaignId(state)
        const selectedUser = selectUserObject(state);
        const calendarStartDayFromDB = ((selectedUser?.preferences?.calendarStartDay ?? 0) + 8) % 7; // 0 is Monday in RBC

        let rangeStart: Date;
        let rangeEnd: Date;

        const monthStart = moment(viewMonthStartStr).startOf("month");
        const monthEnd = moment(viewMonthStartStr).endOf("month");

        const diffStart = (monthStart.day() - calendarStartDayFromDB + 7) % 7;
        rangeStart = monthStart.clone().subtract(diffStart, "days").utc().toDate();

        const lastDayOfWeek = (calendarStartDayFromDB + 6) % 7;
        const diffEnd = (lastDayOfWeek - monthEnd.day() + 7) % 7;
        rangeEnd = monthEnd.clone().add(diffEnd, "days").utc().toDate();

        let nonRecurring = messages.filter(
            (message) =>
                !message.isRecurring &&
                moment(message.dueDate).isBetween(rangeStart, rangeEnd, undefined, '[]')
        );

        let recurring: UserMessage[] = [];

        messages
            .filter((message) => message.isRecurring)
            .forEach((m) => {
                let originalRuleset: RRuleSet = m.rruleset
                    ? toRuleSet(m.rruleset) // Make sure toRuleSet parses the string into an RRuleSet
                    : parseOldUnits(m.recurenceUnit, m.recurrenceQuantity); // Assume this returns an RRuleSet

                // Use moment.js to parse m.dueDate and convert it to a Date object, ensuring UTC
                let correctDtStart = moment.utc(m.dueDate).toDate();

                // Assuming you have a way to reconstruct or access the rules (and exdates if any) from the original RRuleSet:
                // Create a new RRuleSet
                let ruleset = new RRuleSet();

                // For each original rule, re-create it with the correct dtstart
                originalRuleset.rrules().forEach(rule => {
                    let newRuleOptions = { ...rule.origOptions, dtstart: correctDtStart }; // Safely copy options and set dtstart
                    ruleset.rrule(new RRule(newRuleOptions)); // Add corrected rule to ruleset
                });

                // If there are exclusion dates, add them to the new ruleset as well
                originalRuleset.exdates().forEach(exdate => {
                    ruleset.exdate(exdate);
                });

                // Get excluded dates from the ruleset
                const excludedDates = ruleset.exdates().map(ed => moment(ed).startOf('minute').valueOf());
                // Get in-range dates that are not excluded
                const inRangeDates = ruleset.between(rangeStart, rangeEnd) // Use updated ruleset
                    .filter((date) => {
                        const isDateBeforeRuleset = moment(date).isBefore(correctDtStart);
                        const dateValue = moment(date).startOf('minute').valueOf();
                        return !excludedDates.includes(dateValue) && !isDateBeforeRuleset; // Compare values consistently
                    });

                // Create message objects for each in-range date that is not excluded
                const rec = inRangeDates.map((d) => {
                    const due = moment(d).startOf("minute").toISOString();
                    return { ...m, dueDate: due } as UserMessage;
                });

                // Add the non-excluded recurring messages to the array
                recurring = [...recurring, ...rec];
            });

        // colors
        nonRecurring = nonRecurring.map((v) => ({
            ...v,
            messageColor: v.messageColor || defaultEventcolor,
        }));

        recurring = recurring.map((v) => {
            if (v.calendarColors) {
                const { defaultColor, ranges, specific } = v.calendarColors;

                const specificColor = specific.find((s) => s.messageTime === v.dueDate);
                if (specificColor) return { ...v, messageColor: specificColor.color };

                const sorted = [...ranges]?.sort((a, b) =>
                    moment(a.fromTime).isBefore(moment(b.fromTime)) ? 1 : -1
                );
                const rangeColor = sorted?.find((s) =>
                    moment(s.fromTime).isSameOrBefore(moment(v.dueDate))
                );
                if (rangeColor) return { ...v, messageColor: rangeColor.color };

                if (defaultColor) return { ...v, messageColor: defaultColor };
            }
            return { ...v, messageColor: defaultEventcolor };
        });

        const events = [...nonRecurring, ...recurring];

        return events;

    }
)


export const shouldGetMoreContactsSelector = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const isSockectConnected = getIsSocketConnectedToWhatsApp(state)
        const totalContacts = state.DBWaContactsCacheReducer.total

        return !(!isSockectConnected && totalContacts === 0)
    }
)

// Memoized selectors
export const selectFilteredContacts = createSelector(
    [(state) => state.DBWaContactsCacheReducer.entities, (_, filter: string) => filter,
    (state) => state
    ],
    (entities, filter, state) => {
        const contacts = Object.values(entities) as WaContact[];
        if (!filter) return contacts;

        const lowerFilter = filter.toLowerCase();
        return contacts.filter(contact => {
            if (!contact) return false;

            const searchableFields = [
                contact.name,
                contact.id?.user,
                contact.pushname,
                contact.shortName
            ].map(field => field?.toLowerCase() ?? '');

            return searchableFields.some(field => field.includes(lowerFilter));
        });
    }
);

// Type for minimal contact data needed for rendering
export interface MinimalContact {
    id: { _serialized: string };
    name?: string;
    pushname: string;
    shortName?: string;
    isGroup: boolean;
    verifiedName?: undefined;
}

// Optimized selector that returns only fields needed for rendering
export const selectFilteredContactsMinimal = createSelector(
    [(state) => state.DBWaContactsCacheReducer.entities, (_, filter: string) => filter],
    (entities, filter) => {
        const contacts = Object.values(entities) as WaContact[];
        const mapToMinimal = (contact: WaContact): MinimalContact => ({
            id: { _serialized: contact?.id?._serialized },
            name: contact?.name,
            pushname: contact?.pushname,
            shortName: contact?.shortName,
            isGroup: contact?.isGroup,
            verifiedName: contact?.verifiedName
        });

        if (!filter) {
            return contacts.map(mapToMinimal);
        }

        const lowerFilter = filter.toLowerCase();
        return contacts
            .filter(contact => {
                if (!contact) return false;

                const searchableFields = [
                    contact.name,
                    contact.id?.user,
                    contact.pushname,
                    contact.shortName
                ].map(field => field?.toLowerCase() ?? '');

                return searchableFields.some(field => field.includes(lowerFilter));
            })
            .map(mapToMinimal);
    }
);

export const getWhatsappContactNameById = createSelector(
    [
        (state, whatsappId, _contactName) => state,
        (_, whatsappId: string, _contactName) => whatsappId,
        (_, _contactName) => _contactName
    ],
    (state, whatsappId, _contactName) => {
        const whatsappContact = selectWhatsappContactById(state, whatsappId);

        const _displayName = whatsappContact?.displayName ?? whatsappContact?.name ?? ''
        const displayName = _displayName?.indexOf('+') === 0 ? (whatsappContact?.name ?? whatsappContact?.displayName) : _displayName
        const contactName = _contactName ?? displayName;

        return contactName;
    }
);
