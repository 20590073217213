import { FormControl, InputLabel, Select, SelectProps } from '@mui/material'
import React, { PropsWithChildren, useMemo } from 'react'
import randomstring from 'randomstring';
import BTThemeProvider from '@common-components/bt-theme-provider';

type BTDropDownProps = {
    fullWidth?: boolean;
}

export default function BTDropDown({ label, children, sx, fullWidth = true, ...props }: PropsWithChildren<BTDropDownProps> & SelectProps) {
    const labelId = useMemo(() => randomstring.generate(16), []);
    return ( 
        <BTThemeProvider>
            <FormControl fullWidth={fullWidth}>
                {label && <InputLabel
                    sx={{
                        '&.MuiInputLabel-root': {
                            color: 'var(--text-color)'
                        },
                    }}
                    id={labelId}>{label}</InputLabel>}
                <Select
                    labelId={labelId}
                    label={label}
                    sx={{
                        '&:hover:not(.Mui-disabled):before': {
                            borderColor: 'var(--secondary)',
                        },
                        '&:before': {
                            borderColor: 'var(--secondary)',
                        },
                        '.MuiSelect-select': {
                            color: 'var(--text-color)',
                        },
                        '.MuiSvgIcon-root': {
                            color: 'var(--text-color)',
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'var(--secondary)',
                        },
                        '.MuiOutlinedInput-input': {
                            '&.Mui-disabled': {
                                color: 'var(--text-color)',
                                WebkitTextFillColor: 'var(--text-color)',
                            }
                        },
                        '&.Mui-disabled': {
                            color: 'var(--text-color)',

                            '&.MuiSelect-select': {
                                color: 'var(--text-color)',
                            },

                            '.MuiSvgIcon-root': {
                                color: 'var(--text-color)',
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--secondary)',
                            },
                        },
                        ...sx
                    }}
                    {...props}
                >
                    {children}
                </Select>
            </FormControl>
        </BTThemeProvider>
    )
}