import { getCampaignRealStatus } from '@common-reducers/CampaignThunks';
import { campaignsSelectByQuery, DBMessageLogThunks, messageLogSelectByQuery } from '@common-reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { Campaign } from '@common/models/campaign-model';
import CampaignMessageLogRow from '@extension/context/content/components/bt-bulk-dashboard/campaign-message-log-row';
import InProgressCampaignIndicator from '@extension/context/content/components/bt-bulk-dashboard/in-progress-campaign-indicator';
import LazyLoadingVirtuoso from '@extension/context/content/components/bt-bulk-dashboard/lazy-loading-virtuoso';
import { StatusBadge } from '@extension/context/content/components/bt-bulk-dashboard/status-labels';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import HistoryIcon from '@mui/icons-material/History';
import RepeatIcon from '@mui/icons-material/Repeat';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Chip, Dialog, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface CampaignLogsDialogProps {
    open: boolean;
    onClose: () => void;
    campaignId: string;
}

const CampaignLogsDialog = ({ open, onClose, campaignId }: CampaignLogsDialogProps) => {
    const dispatch = useAppDispatch();
    const campaign = useAppSelector((state) => campaignsSelectByQuery(state.DBCampaignReducer, {
        _id: campaignId,
    })[0]);

    const logs = useAppSelector((state) => 
        messageLogSelectByQuery(state.DBMessageLogReducer, { campaignId })
    );

    console.log('CampaignLogsDialog render:', { campaignId, logsCount: logs?.length });

    useEffect(() => {
        if (open && campaignId) {
            console.log('Fetching logs for campaign:', campaignId);
            dispatch(DBMessageLogThunks.find({
                campaignId: campaignId,
                $sort: {
                    createdAt: -1
                },
                $limit: -1
            }));
        }
    }, [campaignId, open, dispatch]);

    const realStatus = getCampaignRealStatus(campaign?.campaignStatus, !!campaign.contactCount);

    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <Box sx={{ p: 3 }}>
                <Stack spacing={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Campaign Logs</Typography>
                        {realStatus && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {StatusBadge(realStatus)}
                                {campaign?.contactCount && (realStatus === 'Running' || realStatus === 'Paused') && (
                                    <InProgressCampaignIndicator
                                        campaignId={campaign?._id}
                                        sx={{
                                            height: '20px',
                                            borderRadius: '6px',
                                        }}
                                    />
                                )}
                            </Box>
                        )}
                    </Stack>

                    {logs && logs.length > 0 ? (
                        <Stack sx={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderRadius: '12px' }}>
                            <LazyLoadingVirtuoso
                                isFullWidth={true}
                                height={500}
                                rowHeightString='80px'
                                filterObj={{
                                    campaignId: campaignId,
                                    $sort: { createdAt: -1 },
                                    $limit: -1
                                }}
                                DBThunks={DBMessageLogThunks}
                                DBReducer={state => state.DBMessageLogReducer}
                                selectByQuery={messageLogSelectByQuery}
                                selector={(state) => messageLogSelectByQuery(state.DBMessageLogReducer, { campaignId })}
                                Component={CampaignMessageLogRow}
                            />
                        </Stack>
                    ) : (
                        <Box sx={{ textAlign: 'center', py: 4 }}>
                            <Typography color="text.secondary">No logs found for this campaign</Typography>
                        </Box>
                    )}
                </Stack>
            </Box>
        </Dialog>
    );
};

interface CampaignsTabProps {
    supportedUserId: string | null;
}

const getStatusColor = (status: string) => {
    switch (status) {
        case 'completed':
        case 'complete-send':
            return 'success';
        case 'aborted':
            return 'error';
        default:
            return 'default';
    }
};

const CampaignRow = ({ campaign }: { campaign: Campaign }) => {
    const [isLogsOpen, setIsLogsOpen] = useState(false);

    const handleViewLogs = () => {
        setIsLogsOpen(true);
    };

    const handleCloseLogs = () => {
        setIsLogsOpen(false);
    };

    const getScheduleDisplay = (schedule: any) => {
        if (!schedule) return null;

        const parts = [];
        if (schedule.dueDate) {
            parts.push(new Date(schedule.dueDate).toLocaleDateString());
        }
        if (schedule.isNow) {
            parts.push('Immediate');
        }
        if (schedule.cancelIfReceived) {
            parts.push('Cancel if received');
        }
        return parts.join(' • ');
    };

    return (
        <Paper 
            elevation={0} 
            sx={{ 
                p: 2, 
                mb: 2, 
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 2
            }}
        >
            <Stack spacing={1}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="h6">
                            {campaign.template?.name || 'Unnamed Campaign'}
                        </Typography>
                        <IconButton 
                            size="small" 
                            onClick={handleViewLogs}
                            sx={{ ml: 1 }}
                            title="View Logs"
                        >
                            <HistoryIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Chip 
                        label={campaign.campaignStatus} 
                        color={getStatusColor(campaign.campaignStatus)}
                        size="small"
                    />
                </Box>
                
                <Typography color="text.secondary">
                    {campaign.template?.message || 'No message content'}
                </Typography>
                
                {campaign.template?.poll?.question && (
                    <Box sx={{ mt: 1 }}>
                        <Typography color="text.secondary">
                            Poll: {campaign.template.poll.question}
                        </Typography>
                        {campaign.template.poll.options && campaign.template.poll.options.length > 0 && (
                            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                {campaign.template.poll.options.map((option, index) => (
                                    <Chip key={index} label={option} size="small" variant="outlined" />
                                ))}
                            </Box>
                        )}
                    </Box>
                )}

                <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                    {campaign.schedule && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {campaign.schedule.isNow ? (
                                <RepeatIcon fontSize="small" color="action" />
                            ) : (
                                <CalendarTodayIcon fontSize="small" color="action" />
                            )}
                            <Typography variant="body2" color="text.secondary">
                                {getScheduleDisplay(campaign.schedule)}
                            </Typography>
                        </Box>
                    )}
                    
                    {campaign.contactCount !== undefined && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <GroupIcon fontSize="small" color="action" />
                            <Typography variant="body2" color="text.secondary">
                                {campaign.contactCount} contacts
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {campaign.createdAt && (
                        <Typography variant="body2" color="text.secondary">
                            Created: {new Date(campaign.createdAt).toLocaleString()}
                        </Typography>
                    )}
                    {campaign.updatedAt && campaign.createdAt && (
                        <Typography variant="body2" color="text.secondary">
                            • Updated: {new Date(campaign.updatedAt).toLocaleString()}
                        </Typography>
                    )}
                </Box>
            </Stack>

            <CampaignLogsDialog
                open={isLogsOpen}
                onClose={handleCloseLogs}
                campaignId={campaign._id}
            />
        </Paper>
    );
};

export function CampaignsTab({ supportedUserId }: CampaignsTabProps) {
    const [searchTerm, setSearchTerm] = useState('');

    const campaigns = useAppSelector((state) =>
        campaignsSelectByQuery(state.DBCampaignReducer, {
            ...(supportedUserId ? { owner: supportedUserId } : {}),
            ...(searchTerm ? {
                $or: [
                    { 'template.name': { $regex: searchTerm, $options: 'i' } },
                    { 'template.message': { $regex: searchTerm, $options: 'i' } },
                    { 'template.poll.question': { $regex: searchTerm, $options: 'i' } },
                ]
            } : {})
        })
    );

    const handleNewCampaign = () => {
        // TODO: Implement new campaign creation
        console.log('New campaign clicked');
    };

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            <Stack spacing={2}>
                {/* Search and Add Campaign Row */}
                <Stack 
                    direction="row" 
                    spacing={2} 
                    sx={{ 
                        width: '100%',
                        alignItems: 'center'
                    }}
                >
                    {/* Search Field */}
                    <Paper
                        sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                        }}
                        elevation={0}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search campaigns..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Paper>

                    {/* New Campaign Button */}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleNewCampaign}
                        sx={{ 
                            minWidth: '200px',
                            textTransform: 'none'
                        }}
                    >
                        New Campaign
                    </Button>
                </Stack>

                {/* Campaigns List */}
                <Paper 
                    elevation={0} 
                    sx={{ 
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: 2,
                        maxHeight: '600px',
                        overflow: 'auto'
                    }}
                >
                    <Box sx={{ p: 2 }}>
                        {campaigns.length > 0 ? (
                            campaigns.map((campaign) => (
                                <CampaignRow key={campaign._id} campaign={campaign} />
                            ))
                        ) : (
                            <Typography color="text.secondary" align="center" py={4}>
                                No campaigns found
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Stack>
        </Box>
    );
} 