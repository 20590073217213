import { DBUserMessageThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import MessageCalendar from "@common-views/calendar/message-calendar";
import SelectedEvent from "@common-views/calendar/selected-event";
import { UserMessage } from "@common/models/user-message";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { isExtensionContext } from "@common/services/detect-context";
import AddScheduledMessage from "@extension/context/content/components/add-scheduled-message-button/add-scheduled-message";
import { getChatsAndContactsThunk } from "@extension/context/content/thunks/WhatsappThunks";
import { useEffect, useState } from "react";
import AgendaView from "../agenda-view/agenda-view";
import QrModal from "../qr-view/qr-modal";
import AppView from "@common/components/app-view";

const CalendarView = () => {
  const [selectedEvent, setSelectedEvent] = useState<UserMessage & Partial<DOMRect>>(null);
  const dispatch = useAppDispatch();
  const calendarMode = useAppSelector((state) => state.CalendarReducer.calendarMode);
  const isAddScheduledMessageModalOpen = useAppSelector((state) => state.AddScheduledMessageButtonReducer.open);

  const handleDeleteMessage = () => {
    if (selectedEvent) {
      if (selectedEvent.isRecurring) {
        // Handle recurring message deletion
        setSelectedEvent(null);
      } else {
        dispatch(DBUserMessageThunks.delete({ entity: selectedEvent }));
        setSelectedEvent(null);
      }
    }
  };

  useEffect(() => {
    try {
      dispatch(getChatsAndContactsThunk({ shouldForceUpdate: false }))
      dispatch(getChatsAndContactsThunk({ shouldForceUpdate: true }))
    } catch (e) {
      console.error("[getChatsAndContactsThunk] error", e)
    }
  }, [dispatch]);

  let activeExtensionView = ""
  if (isExtensionContext()) {
    activeExtensionView = "calendar"
  }

  return (
    <>
      <AppView title="Messages Planner" activeExtensionView={activeExtensionView} showHeader={true}>
        {calendarMode === 'calendar' ? (
          <MessageCalendar />
        ) : (
          <AgendaView onEventSelect={(event) => dispatch(addScheduledMessageButtonActions.editCalendarMessage(event))} />
        )}
      </AppView>

      {selectedEvent && (
        <SelectedEvent
          showSendNowButton={isExtensionContext()}
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onDelete={handleDeleteMessage}
        />
      )}
      {isAddScheduledMessageModalOpen && <AddScheduledMessage />}
      <QrModal />
    </>
  );
};

export default CalendarView;
