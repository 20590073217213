import { HighlightOff } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import classes from "./task-subscribers-dialog.module.scss";

import BTInputField from '@common-components/bt-input-field/bt-input-field';
import { getFilteredUserListForSubscriptionManagement, getTaskSubscribedUsersList } from '@common-reducers/ExtraDataThunks';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { forwardRef, useState } from 'react';
import SubscriptionManagementEntityRow from './subscription-management-entity-row';

type Props = {
    onClose: () => void;
}

const ManageSubscribersModal = forwardRef<HTMLDivElement, Props>(({
    onClose,
}: Props, ref) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [isUpdateingSubscribers, setIsUpdatingSubscribers] = useState<boolean>(false)
    const filteredUserList = useAppSelector((state) => getFilteredUserListForSubscriptionManagement(state, (searchTerm ?? '')))
    const subscribedUsersList = useAppSelector((state) => getTaskSubscribedUsersList(state))



    return (
        <Stack className={classes.modal} ref={ref}>
            {/* Header Section */}
            <div className={classes.modalHeader}>
                <h2>Task Subscribers</h2>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    className={classes.closeButton}
                >
                    <HighlightOff fontSize="medium" />
                </IconButton>
            </div>
            
            {/* Content Section */}
            <div className={classes.modalContent} style={{ height: isUpdateingSubscribers ? '400px' : 'auto' }}>
                {!isUpdateingSubscribers ? (
                    <>
                        {/* Available Users Section */}
                        <Stack spacing={1.5}>
                            <div className={classes.sectionHeader}>
                                <h3>Add Subscribers</h3>
                                <span>{filteredUserList.length} available</span>
                            </div>
                            
                            <BTInputField 
                                className={classes.search_input}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '10px',
                                        transition: 'all 0.2s ease',
                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                        border: '1px solid transparent',
                                        '&:hover, &.Mui-focused': {
                                            backgroundColor: 'white',
                                            border: '1px solid var(--primary-color-light)',
                                        }
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: '12px 16px',
                                    }
                                }}
                                type="text"
                                onChange={({ target }: any) => setSearchTerm(target.value)}
                                placeholder="Search board members" 
                            />
                                
                            <div className={classes.userListContainer} style={{ padding: filteredUserList.length ? '8px' : 0 }}>
                                {filteredUserList.length > 0 ? (
                                    filteredUserList.map((user, index) => (
                                        <SubscriptionManagementEntityRow 
                                            key={index} 
                                            user={user} 
                                            index={index} 
                                            setIsUpdatingSubscribers={setIsUpdatingSubscribers} 
                                        />
                                    ))
                                ) : (
                                    <div className={classes.emptyState}>
                                        <p>No more users available</p>
                                        <p>Click the 'Share Board' button to invite new board members</p>
                                    </div>
                                )}
                            </div>
                        </Stack>
                        
                        {/* Current Subscribers Section */}
                        <Stack spacing={1.5}>
                            <div className={classes.sectionHeader}>
                                <h3>Current Subscribers</h3>
                                <span>{subscribedUsersList.length} subscribed</span>
                            </div>
                            
                            <div className={classes.userListContainer} style={{ padding: subscribedUsersList.length ? '8px' : 0 }}>
                                {subscribedUsersList.length > 0 ? (
                                    subscribedUsersList.map((user, index) => (
                                        <SubscriptionManagementEntityRow 
                                            key={index} 
                                            user={user} 
                                            index={index} 
                                            setIsUpdatingSubscribers={setIsUpdatingSubscribers} 
                                        />
                                    ))
                                ) : (
                                    <div className={classes.emptyState}>
                                        <p>No subscribers yet</p>
                                        <p>Add subscribers from the list above</p>
                                    </div>
                                )}
                            </div>
                        </Stack>
                    </>
                ) : (
                    <div className={classes.loadingState}>
                        <CircularProgress 
                            thickness={4}
                            sx={{
                                width: '48px !important',
                                height: '48px !important',
                                color: 'var(--primary-color)',
                                mb: 3
                            }} 
                        />
                        <p>Updating subscribers...</p>
                        <p>This may take a moment</p>
                    </div>
                )}
            </div>
        </Stack>
    )
})

export default ManageSubscribersModal;