import { EventType } from '@common/models/node-event';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography, Tooltip, Divider, Paper, Grid } from '@mui/material';
import { NodeTypeChip } from './NodeTypeChip';

// Format number with K/M suffixes
const formatNumber = (num: number): string => {
  if (num === 0) return '0';
  if (num < 1000) return num.toString();
  if (num < 1000000) return `${(num / 1000).toFixed(1)}K`.replace('.0K', 'K');
  return `${(num / 1000000).toFixed(1)}M`.replace('.0M', 'M');
};

interface EventAccordionProps {
  id: string;
  isExpanded: boolean;
  onExpandChange: (isExpanded: boolean) => void;
  icon: React.ReactNode;
  type: EventType;
  nodeType: string;
  timestamp: Date;
  nodeId: string;
  data: any;
  isFocused: boolean;
  typeLabel: string;
  typeBgColor: string;
  typeColor: string;
  creditsUsed?: number;
  metadata: any;
}

// Helper function to render different data types
const renderValue = (value: any): React.ReactNode => {
  if (value === null || value === undefined) {
    return <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>null</Typography>;
  }
  
  if (typeof value === 'boolean') {
    return <Typography variant="body2" color={value ? 'success.main' : 'error.main'} sx={{ fontWeight: 'bold' }}>{value.toString()}</Typography>;
  }
  
  if (typeof value === 'number') {
    return <Typography variant="body2">{value}</Typography>;
  }
  
  if (typeof value === 'string') {
    // Check if it's a JSON string
    try {
      const parsed = JSON.parse(value);
      if (typeof parsed === 'object') {
        return (
          <Box sx={{ pl: 2, borderLeft: '2px solid #eee' }}>
            {renderObject(parsed)}
          </Box>
        );
      }
    } catch (e) {
      // Not a JSON string, continue
    }
    
    // Handle long strings
    if (value.length > 300) {
      return (
        <Box sx={{ 
          maxWidth: '100%', 
          wordBreak: 'break-word',
          maxHeight: '200px',
          overflow: 'auto',
          backgroundColor: '#f5f5f5',
          p: 1,
          borderRadius: 1,
          fontSize: '0.875rem',
          fontFamily: 'monospace'
        }}>
          {value}
        </Box>
      );
    }
    
    return <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>{value}</Typography>;
  }
  
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>[]</Typography>;
    }
    
    // For large arrays, show a summary with expandable details
    if (value.length > 10) {
      return (
        <Box>
          <Typography variant="body2" color="text.secondary">
            Array with {value.length} items
          </Typography>
          <Box 
            sx={{ 
              pl: 2, 
              borderLeft: '2px solid #eee',
              maxHeight: '200px',
              overflow: 'auto',
              mt: 1
            }}
          >
            {value.map((item, index) => (
              <Box key={index} sx={{ mb: 1 }}>
                <Typography variant="caption" color="text.secondary">[{index}]</Typography>
                <Box sx={{ pl: 2 }}>
                  {typeof item === 'object' ? renderObject(item) : renderValue(item)}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      );
    }
    
    return (
      <Box sx={{ pl: 2, borderLeft: '2px solid #eee' }}>
        {value.map((item, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            <Typography variant="caption" color="text.secondary">[{index}]</Typography>
            <Box sx={{ pl: 2 }}>
              {typeof item === 'object' ? renderObject(item) : renderValue(item)}
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
  
  if (typeof value === 'object') {
    return renderObject(value);
  }
  
  return <Typography variant="body2">{String(value)}</Typography>;
};

// Helper function to render objects
const renderObject = (obj: Record<string, any>): React.ReactNode => {
  if (!obj) {
    return <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>null</Typography>;
  }
  
  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>{ }</Typography>;
  }
  
  // For large objects, create a scrollable container
  if (keys.length > 15) {
    return (
      <Box sx={{ 
        maxHeight: '300px', 
        overflow: 'auto',
        border: '1px solid #eee',
        borderRadius: 1,
        p: 1
      }}>
        {keys.map((key) => (
          <Box key={key} sx={{ mb: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <Typography variant="caption" color="primary" sx={{ fontWeight: 'bold' }}>
                  {key}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {renderValue(obj[key])}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    );
  }
  
  return (
    <Box>
      {keys.map((key) => (
        <Box key={key} sx={{ mb: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <Typography variant="caption" color="primary" sx={{ fontWeight: 'bold' }}>
                {key}:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {renderValue(obj[key])}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export const EventAccordion = ({
  id,
  isExpanded,
  onExpandChange,
  icon,
  type,
  nodeType,
  timestamp,
  nodeId,
  data,
  isFocused,
  typeLabel,
  typeBgColor,
  typeColor,
  creditsUsed,
  metadata
}: EventAccordionProps) => {
  return (
    <Accordion
      id={`event-accordion-${id}`}
      expanded={isExpanded}
      onChange={(_, expanded) => onExpandChange(expanded)}
      sx={{
        my: 1,
        boxShadow: 1,
        border: isFocused ? `2px solid ${typeColor}` : 'none',
        '&:before': {
          display: 'none',
        },
        width: '100%',
        overflow: 'visible',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: typeBgColor,
          color: typeColor,
          '&.Mui-expanded': {
            minHeight: 48,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
          {icon}
          <Chip
            label={typeLabel}
            size="small"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              color: typeColor,
              fontWeight: 600,
              '& .MuiChip-label': {
                px: 2
              }
            }}
          />
          <NodeTypeChip nodeType={nodeType} />
          {creditsUsed && (
            <Chip
              label={`${formatNumber(creditsUsed)} credit${creditsUsed !== 1 ? 's' : ''}`}
              size="small"
              sx={{
                backgroundColor: 'rgba(156, 39, 176, 0.1)',
                color: 'rgb(156, 39, 176)',
                mr: 1
              }}
            />
          )}
          {type === EventType.CREDIT_USAGE && metadata?.tokenUsage && (
            <>
              <Tooltip title={`Input tokens: ${formatNumber(metadata.tokenUsage.inputTokens || 0)}`}>
                <Chip
                  label={`In: ${formatNumber(metadata.tokenUsage.inputTokens || 0)}`}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(76, 175, 80, 0.1)',
                    color: 'rgb(76, 175, 80)',
                    mr: 1
                  }}
                />
              </Tooltip>
              
              <Tooltip title={`Output tokens: ${formatNumber(metadata.tokenUsage.outputTokens || 0)}`}>
                <Chip
                  label={`Out: ${formatNumber(metadata.tokenUsage.outputTokens || 0)}`}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(3, 169, 244, 0.1)',
                    color: 'rgb(3, 169, 244)',
                    mr: 1
                  }}
                />
              </Tooltip>
              
              <Tooltip title={`Total tokens: ${formatNumber(metadata.tokenUsage.totalTokens || 0)}`}>
                <Chip
                  label={`Total: ${formatNumber(metadata.tokenUsage.totalTokens || 0)}`}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(255, 152, 0, 0.1)',
                    color: 'rgb(255, 152, 0)',
                  }}
                />
              </Tooltip>
            </>
          )}
          <Typography variant="caption" color="text.secondary" sx={{ ml: 'auto' }}>
            {new Date(timestamp).toLocaleString()}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails 
        sx={{ 
          p: 2, 
          backgroundColor: '#fafafa',
          maxHeight: '400px',
          overflow: 'auto',
        }}
      >
        <Paper 
          elevation={0} 
          sx={{ 
            p: 2,
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Typography variant="subtitle2" gutterBottom>Event Details</Typography>
          <Divider sx={{ mb: 2 }} />
          {renderObject(data)}
          
          {metadata && Object.keys(metadata).length > 0 && (
            <>
              <Typography variant="subtitle2" sx={{ mt: 3, mb: 1 }}>Metadata</Typography>
              <Divider sx={{ mb: 2 }} />
              {renderObject(metadata)}
            </>
          )}
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
}; 