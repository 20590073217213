import BTInputField from "@common-components/bt-input-field/bt-input-field";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { setExtensionSidebarSearchToken } from "@common/reducers/WhatsAppReducer";
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from "@mui/material";


export default function ExtensionSidebarSearchBox() {
    const dispatch = useAppDispatch();
    const searchToken = useAppSelector((state) => state.WhatsAppReducer.extensionSidebarSearchToken);


    return (
        <Stack  direction="row" justifyContent="space-between" sx={{padding: "0 16px"}}>

            <BTInputField
                placeholder={
                    // selectedTab === 1 ? 'Select by Message ID' :
                    "Search scheduled messages"}
                value={searchToken}
                onChange={(event) => dispatch(setExtensionSidebarSearchToken(event.target.value))}
                startAdornment={<SearchIcon sx={{ fontSize: "20px", color: 'var(--primary)' }} />}
                sx={{
                    flex: 'auto',
                    backgroundColor: 'var(--search-input-background)',
                    height: "35px",
                    "& .MuiInputBase-input": {
                        padding: "2px 0",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        padding: 0
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        boxShadow: "none"
                    },
                    "&::placeholder": {
                        paddingLeft: "15px",
                        fontSize: "14px"
                    }
                }} />
                
        </Stack >
    )
}