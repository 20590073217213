import { appConfig } from "@common-config/app.config";

export interface VznLyticsEvent {
  event_name?: string;
  label?: string;
  triggered_by?: "click" | "view" | "scroll";
  category?: string;
  sub_category?: string;
  value?: any;
  entity_id?: string;
  entity_display_name?: string;
  entity_type?: string;
  data?: any;
  email?: string;
  analytics_data?: {
    mauticId?: string;
    [key: string]: any;
  };
}

export class VznLytics {
  static Endpoint = appConfig.VZNLYTICS_ENDPOINT;

  private getUrlParams(): string {
    if (typeof window !== 'undefined' && window.location) {
      return new URLSearchParams(window.location.search).toString();
    }
    return '';
  }

  event(event: VznLyticsEvent) {
    try {
      const urlParams = this.getUrlParams();
      const url = urlParams ? `${VznLytics.Endpoint}/events?${urlParams}` : `${VznLytics.Endpoint}/events`;

      console.log('[VznLytics] Sending event:', {
        event_name: event.event_name,
        category: event.category,
        value: event.value
      });

      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(event)
      })
      .then(response => {
        if (!response.ok) {
          console.error('[VznLytics] Error response:', response.status);
        }
      })
      .catch(error => {
        console.error('[VznLytics] Network error:', error.message);
      });
    } catch (e) {
      console.error('[VznLytics] Error in VznLytics.event:', e instanceof Error ? e.message : 'Unknown error');
    }
  }
}
