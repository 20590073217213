import { Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';

interface MessageStats {
    sent: number;
    delivered: number;
    read: number;
    played: number;
    failed: number;
    pending: number;
    total: number;
}

type StatusFilter = 'sent' | 'delivered' | 'read' | 'played' | 'failed' | 'pending' | null;

interface CampaignMessageLogStatusFilterProps {
    messageStats: MessageStats;
    activeFilter: StatusFilter;
    onFilterClick: (filter: StatusFilter) => void;
}

const LogStatusFilterBox = ({
    theme,
    logStatusLabel,
    logStatusValue,
    color,
    isActive,
    onClick
}: {
    theme: string;
    logStatusLabel: string;
    logStatusValue: number;
    color: string;
    isActive: boolean;
    onClick: () => void;
}) => {

    return (
        <Paper
            elevation={0}
            onClick={onClick}
            sx={{
                p: 1,
                textAlign: 'center',
                backgroundColor: isActive ? `${color}30` : `${color}15`,
                border: `1px solid ${color}30`,
                borderRadius: 1.5,
                cursor: 'pointer',
                marginLeft:'0px',
                transition: 'background-color 0.2s',

                '&:hover': {
                    backgroundColor: `${color}20`,
                }
            }}
        >
            <Typography variant="h6" sx={{
                color: theme === 'dark' ? '#ffffff' : '#000',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                lineHeight: 1.2
            }}>
                {logStatusValue}
            </Typography>
            <Typography variant="caption" sx={{
                color: theme === 'dark' ? '#ffffff' : '#000',
                fontSize: '0.75rem'
            }}>
                {logStatusLabel}
            </Typography>
        </Paper>
    );
};

export const CampaignMessageLogStatusFilter = ({ messageStats, activeFilter, onFilterClick }: CampaignMessageLogStatusFilterProps) => {
    const theme = useTheme();
    
    return (
        <Stack direction="row" spacing={1} sx={{ mb: 1,width:'100%',justifyContent:'space-between' }}>
            <Grid item xs={2} sx={{width:'15%'}}>
                <LogStatusFilterBox
                    theme={theme.palette.mode}
                    logStatusLabel="Sent"
                    logStatusValue={messageStats.sent}
                    color="#1976d2"
                    isActive={activeFilter === 'sent'}
                    onClick={() => onFilterClick('sent')}
                />
            </Grid>
            <Grid item xs={2} sx={{width:'15%'}}>
                <LogStatusFilterBox
                    theme={theme.palette.mode}
                    logStatusLabel="Delivered"
                    logStatusValue={messageStats.delivered}
                    color="#2e7d32"
                    isActive={activeFilter === 'delivered'}
                    onClick={() => onFilterClick('delivered')}
                />
            </Grid>
            <Grid item xs={2} sx={{width:'15%'}}>
                <LogStatusFilterBox
                    theme={theme.palette.mode}
                    logStatusLabel="Read"
                    logStatusValue={messageStats.read}
                    color="#9c27b0"
                    isActive={activeFilter === 'read'}
                    onClick={() => onFilterClick('read')}
                />
            </Grid>
            <Grid item xs={2} sx={{width:'15%'}}>  
                <LogStatusFilterBox
                    theme={theme.palette.mode}
                    logStatusLabel="Played"
                    logStatusValue={messageStats.played}
                    color="#673ab7"
                    isActive={activeFilter === 'played'}
                    onClick={() => onFilterClick('played')}
                />
            </Grid>
            <Grid item xs={2} sx={{width:'15%'}}>
                <LogStatusFilterBox
                    theme={theme.palette.mode}
                    logStatusLabel="Failed"
                    logStatusValue={messageStats.failed}
                    color="#d32f2f"
                    isActive={activeFilter === 'failed'}
                    onClick={() => onFilterClick('failed')}
                />
            </Grid>
        </Stack>
    );
};

export default CampaignMessageLogStatusFilter;
