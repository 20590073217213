import CustomSourceHandle from '@common-components/bt-chatbot/custom-source-handle';
import RowStack from '@common-components/common/row-stack/RowStack';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { getHandleId, getSourceHandleStyle } from '@common-services/utils';
import { HelpRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Position } from 'reactflow';
import classes from "./webhook-node.module.scss";
import { nodeTypes } from '../bt-nodes-def';

const toolTipText = `Connect a node to the default option if no response routing codes are used, or if the response code doesn't match any other response routes.`;

type Props = {
  id: string;
}

export default function WebhookNodeContainer({ id }: Props) {
  const data = useAppSelector(state => getNodeDataByNodeId(state, id))

  const responseRoutes = data?.webhook?.responseRouting?.responseRoutes
  const nodeColor = nodeTypes.webhook.color

  return (
    <>
      <div className={classes.response_routes} style={{
        height: responseRoutes?.length > 0 ? 'auto' : 0,
        margin: responseRoutes?.length > 0 ? '10px' : 0,
      }}>

        {(!responseRoutes || responseRoutes.length === 0) &&
          <CustomSourceHandle type="source" position={Position.Right} id={getHandleId(data?.handles, 'source', 0)}
            className={classes.source_handle} connectioncount={1} style={getSourceHandleStyle(nodeColor)} />
        }

        {responseRoutes && responseRoutes.length > 0 &&
          <RowStack className={classes.response_route} style={{ backgroundColor: '#EAEAEB', }}>
            Default
            <Tooltip title={toolTipText}><HelpRounded sx={{ color: '#333333', width: '15px', margin: '0 0 0 3px', }} /></Tooltip>
            <CustomSourceHandle type="source" position={Position.Right} id={getHandleId(data.handles, 'source', 0)}
              className={classes.source_handle} connectioncount={1} style={getSourceHandleStyle(nodeColor)} />
          </RowStack>
        }

        {responseRoutes && responseRoutes.map((responseRoute, i) => {
          const statusCode = responseRoute.statusCode
          return (
            <div className={classes.response_route} key={i}>
              {statusCode}
              <CustomSourceHandle
                key={i}
                type="source"
                position={Position.Right}
                id={responseRoute.handleId}
                className={classes.source_handle}
                style={getSourceHandleStyle(nodeColor)}
                connectioncount={1} />
            </div>
          )
        })}
      </div>
    </>
  )
}