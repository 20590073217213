import React from 'react';
import KnowledgeBaseSettings from './knowledge-base-settings';
import FreshdeskSettings from './freshdesk-settings';
import { GmailSettings } from './gmail-settings';
import { LinkedInSettings } from './linkedin-settings';
import DriveSettings from './drive-settings';
import { ToolSettingsComponentProps } from './tool-settings-dialog';

interface ToolSettingsFactoryProps extends ToolSettingsComponentProps {
  toolName: string;
}

const ToolSettingsFactory: React.FC<ToolSettingsFactoryProps> = ({
  toolName,
  initialSettings,
  onSaveSettings
}) => {
  // Return the appropriate tool settings component based on the tool name
  switch (toolName.toLowerCase()) {
    case 'knowledge-base':
      return <KnowledgeBaseSettings initialSettings={initialSettings} onSaveSettings={onSaveSettings} />;
    case 'freshdesk':
      return <FreshdeskSettings initialSettings={initialSettings} onSaveSettings={onSaveSettings} />;
    case 'gmail':
      return <GmailSettings initialSettings={initialSettings} onSaveSettings={onSaveSettings} />;
    case 'linkedin':
      return <LinkedInSettings initialSettings={initialSettings} onSaveSettings={onSaveSettings} />;
    case 'drive':
      return <DriveSettings initialSettings={initialSettings} onSaveSettings={onSaveSettings} />;
    default:
      return (
        <div className="p-4 text-center text-muted-foreground">
          No settings available for this tool.
        </div>
      );
  }
};

export default ToolSettingsFactory; 