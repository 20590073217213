import { Stack } from '@mui/material';
import { DBMessageLogThunks, messageLogSelectByQuery } from "@common-reducers/DBServiceThunks";
import CampaignMessageLogSentRow from './campaign-message-log-sent-row';
import LazyLoadingVirtuoso from '../../lazy-loading-virtuoso';

interface CampaignSentLogsProps {
    campaignId: string;
    filterSelector: (state: any) => any;
}

export const CampaignSentLogs = ({ campaignId, filterSelector }: CampaignSentLogsProps) => {

    return (
        <Stack
            spacing={1}
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.05)',
                borderRadius: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
                height: '100%',
                maxHeight: 'calc(100vh - 420px)',
                p: 1.5
            }}
        >
                <LazyLoadingVirtuoso
                    isFullWidth={true}
                    height={400}
                    rowHeightString='72px'
                    filterObj={{ campaignId }}
                    DBThunks={DBMessageLogThunks}
                    DBReducer={state => state.DBMessageLogReducer}
                    selectByQuery={messageLogSelectByQuery}
                    selector={filterSelector}
                    Component={CampaignMessageLogSentRow}
                    featchData={false}
                />
        </Stack>
    );
};

export default CampaignSentLogs;