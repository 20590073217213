import { setActiveBulkView, setCampaignIdForLogsView } from '@common-reducers/CampaignReducer';
import { clearAllPendingCampaignMessagesThunk } from '@common-reducers/CampaignThunks';
import { campaignSelectors, DBCampaignThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { CampaignStatus } from '@common/models/campaign-model';
import { Grading, InfoOutlined, PauseOutlined, PlayArrowOutlined, Stop } from '@mui/icons-material';
import { Box, Menu, Modal } from '@mui/material';
import { useState } from 'react';
import classes from './bulk_item_row_menu.module.scss';
import BulkDBFinish from './finish/bulk-db-finish';

type Props = {
    campaignId: string;
    handleCloseMenu: () => void;
    anchorEl: HTMLElement | null;
    shouldShowViewLogs?: boolean;
}

export default function CampaignRowMenu({ campaignId, handleCloseMenu, anchorEl, shouldShowViewLogs = true }: Props) {
    const dispatch = useAppDispatch();
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

    const campaign = useAppSelector(state => campaignSelectors.selectById(state, campaignId))


    const menuListProps = {
        sx: {
            padding: 0,
            '& .MuiList-root': {
                padding: 0,
            },
        }
    };

    const modalStyle = {
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const modalContentStyle = {
        backgroundColor: 'var(--background-default)',
        padding: '7px',
        borderRadius: '8px',
        width: '700px',
    };

    const handleViewLogsClick = () => {
        dispatch(setCampaignIdForLogsView(campaign._id));
        dispatch(setActiveBulkView("campaignLogsView"));
        handleCloseMenu();
    };

    const handleChangeCampaignStatus = (type: CampaignStatus) => {
        dispatch(DBCampaignThunks.patch({
            entity: {
                ...campaign,
                campaignStatus: type,
            }
        }));


        handleCloseMenu();
    };

    const handleAbortCampaign = () => {

        dispatch(clearAllPendingCampaignMessagesThunk({ campaignId: campaign._id }));
        //dispatch(getMessagesAckAndCalculateStatisticsThunk(campaign._id));

        handleCloseMenu();
    };

    const handleSummaryClick = () => {
        setIsSummaryModalOpen(true);
        handleCloseMenu();
    };

    const handleModalClose = () => {
        setIsSummaryModalOpen(false);
    };

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                MenuListProps={menuListProps}
            >
                <Box className={classes.menu_wrapper}>
                    {(shouldShowViewLogs ?? true) && (
                        <button onClick={handleViewLogsClick} className={classes.menu_list_item}>
                            <Grading />
                            <span style={{ marginInlineStart: 5 }}>View logs</span>
                        </button>
                    )}

                    {campaign?.campaignStatus === 'running' && (
                        <button
                            onClick={() => handleChangeCampaignStatus('paused')}
                            className={classes.menu_list_item}
                        >
                            <PauseOutlined />
                            <span style={{ marginInlineStart: 5 }}>Pause campaign</span>
                        </button>
                    )}

                    {campaign?.campaignStatus === 'paused' && (
                        <button
                            onClick={() => handleChangeCampaignStatus('running')}
                            className={classes.menu_list_item}
                        >
                            <PlayArrowOutlined />
                            <span style={{ marginInlineStart: 5 }}>Resume campaign</span>
                        </button>
                    )}

                    {!(['complete-send', 'aborted'].includes(campaign?.campaignStatus)) && (
                        <button
                            onClick={() => handleAbortCampaign()}
                            className={classes.menu_list_item}
                        >
                            <Stop style={{ color: 'red' }} />
                            <span style={{ marginInlineStart: 5, color: 'red' }}>Abort campaign</span>
                        </button>
                    )}

                    <button onClick={handleSummaryClick} className={classes.menu_list_item}>
                        <InfoOutlined />
                        <span style={{ marginInlineStart: 5 }}>Campaign Summary</span>
                    </button>
                </Box>
            </Menu>

            <Modal
                open={isSummaryModalOpen}
                onClose={handleModalClose}
                sx={modalStyle}
            >
                <div style={modalContentStyle}>
                    <BulkDBFinish campaignId={campaign?._id} />
                </div>
            </Modal>
        </>
    )
}