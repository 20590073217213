import RowStack from '@common-components/common/row-stack/RowStack';
import { setIsReadOnly, setSlugBotId } from '@common/reducers/ChatbotReducer';
import { handleSelectChatbotThunk } from '@common/reducers/ChatbotThunks';
import { DBBotThunks } from '@common/reducers/DBServiceThunks';
import { useAppDispatch } from '@common/reducers/hooks/store.hook';
import { useWebSelector } from '@website/store/web-store';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import classes from "./bt-chatbot.module.scss";
import ToolSettingsDialog from './bt-nodes/agent/tools/settings/tool-settings-dialog';
import ToolSettingsFactory from './bt-nodes/agent/tools/settings/tool-settings-factory';
import ChatbotFlow from './chatbot-flow';
import ChatbotToolBar from './chatbot-toolbar';

export default function BTChatBotView() {
    const { botId } = useParams();
    
    const dispatch = useAppDispatch();
    
    // Get tool settings modal state from Redux using the web store selector
    const { isOpen, toolName, initialSettings } = useWebSelector(state => {
        return state.ToolSettingsModalReducer;
    });

    const prepareFlow = useCallback(async () => {
        await dispatch(setIsReadOnly(false));
        
        if (botId) {
            // await dispatch(DBBotThunks.find({ _id: botId }));
            await dispatch(setSlugBotId(botId));
            await dispatch(handleSelectChatbotThunk(botId));
        } else {
        }
        
    }, [botId, dispatch]);

    useEffect(() => {
        prepareFlow();
    }, [botId, prepareFlow]);


    
    return (
        <>
            <ReactFlowProvider>
                <RowStack className={classes.wrapper}>
                    <ChatbotToolBar />
                    <ChatbotFlow />
                </RowStack>
            </ReactFlowProvider>
            
            {/* Global Tool Settings Modal */}
            {toolName && (
                <ToolSettingsDialog
                    open={isOpen}
                    toolName={toolName}
                    initialSettings={initialSettings}
                >
                    <ToolSettingsFactory 
                        toolName={toolName} 
                        initialSettings={initialSettings}
                    />
                </ToolSettingsDialog>
            )}
        </>
    );
}