'use client';

import React from 'react';
import AppView from '@common/components/app-view';
import SessionDetailsContent from './SessionDetailsContent';

const SessionDetailsView = () => {
  return (
    <AppView title="Session Details" activeExtensionView="bots" showHeader={false}>
      <SessionDetailsContent />
    </AppView>
  );
};

export default SessionDetailsView; 