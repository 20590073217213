import { 
  Alert, 
  Box, 
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Typography, 
  Divider, 
  Paper, 
  alpha,
  useTheme
} from '@mui/material';
import React from 'react';
import { ValidationResult, ValidationResultType } from '@common/hooks/useDeploymentValidation';
import { AlertTriangle, AlertCircle, Info } from 'lucide-react';

interface EnhancedActivationDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isDeployment?: boolean;
    loading?: boolean;
    validationResult: ValidationResult;
}

export const EnhancedActivationDialog: React.FC<EnhancedActivationDialogProps> = ({
    open,
    onClose,
    onConfirm,
    isDeployment = false,
    loading = false,
    validationResult
}) => {
    const theme = useTheme();
    const action = isDeployment ? 'deploy' : 'activate';
    const buttonText = isDeployment ? 'Deploy' : 'Activate';

    const renderIssueContent = (issueType: ValidationResultType, index: number) => {
        switch (issueType) {
            case 'EMPTY_FLOW':
                return (
                    <Paper 
                        key={`issue-${index}`} 
                        elevation={0} 
                        sx={{ 
                            mb: 3, 
                            p: 2.5, 
                            borderRadius: 2,
                            border: `1px solid ${alpha(theme.palette.error.main, 0.2)}`,
                            backgroundColor: alpha(theme.palette.error.main, 0.05)
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                            <AlertCircle 
                                size={20} 
                                color={theme.palette.error.main} 
                                style={{ marginRight: theme.spacing(1.5), marginTop: '2px' }} 
                            />
                            <Typography variant="subtitle1" fontWeight={600} color="error.main">
                                Error: Empty Flow
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ ml: 4 }}>
                            This flow has no nodes. Add at least one trigger node and configure the flow before deploying.
                        </Typography>
                    </Paper>
                );

            case 'NO_TRIGGER':
                return (
                    <Paper 
                        key={`issue-${index}`} 
                        elevation={0} 
                        sx={{ 
                            mb: 3, 
                            p: 2.5, 
                            borderRadius: 2,
                            border: `1px solid ${alpha(theme.palette.warning.main, 0.2)}`,
                            backgroundColor: alpha(theme.palette.warning.main, 0.05)
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                            <AlertTriangle 
                                size={20} 
                                color={theme.palette.warning.main} 
                                style={{ marginRight: theme.spacing(1.5), marginTop: '2px' }} 
                            />
                            <Typography variant="subtitle1" fontWeight={600} color="warning.main">
                                Warning: No Trigger Node Found
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ ml: 4 }}>
                            This flow has no trigger nodes. Add a trigger node to specify when the agent should be activated.
                        </Typography>
                    </Paper>
                );

            case 'INACTIVE_TRIGGER':
                return (
                    <Paper 
                        key={`issue-${index}`} 
                        elevation={0} 
                        sx={{ 
                            mb: 3, 
                            p: 2.5, 
                            borderRadius: 2,
                            border: `1px solid ${alpha(theme.palette.warning.main, 0.2)}`,
                            backgroundColor: alpha(theme.palette.warning.main, 0.05)
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                            <AlertTriangle 
                                size={20} 
                                color={theme.palette.warning.main} 
                                style={{ marginRight: theme.spacing(1.5), marginTop: '2px' }} 
                            />
                            <Typography variant="subtitle1" fontWeight={600} color="warning.main">
                                Warning: Inactive Trigger Configuration
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ ml: 4 }}>
                            The trigger node is configured to not receive any messages. Add contacts to the allow list or switch to block list mode.
                        </Typography>
                    </Paper>
                );

            case 'OVERLAP':
                return (
                    <Paper 
                        key={`issue-${index}`} 
                        elevation={0} 
                        sx={{ 
                            mb: 3, 
                            p: 2.5, 
                            borderRadius: 2,
                            border: `1px solid ${alpha(theme.palette.warning.main, 0.2)}`,
                            backgroundColor: alpha(theme.palette.warning.main, 0.05)
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                            <AlertTriangle 
                                size={20} 
                                color={theme.palette.warning.main} 
                                style={{ marginRight: theme.spacing(1.5), marginTop: '2px' }} 
                            />
                            <Typography variant="subtitle1" fontWeight={600} color="warning.main">
                                Warning: Overlapping Agent Triggers
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ ml: 4, mb: 1.5 }}>
                            There are other active agents that might handle the same messages:
                        </Typography>
                        <Box 
                            sx={{ 
                                ml: 4, 
                                mb: 2, 
                                p: 1.5, 
                                borderRadius: 1.5, 
                                backgroundColor: alpha(theme.palette.background.paper, 0.7),
                                border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                                maxHeight: '120px', 
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: alpha(theme.palette.text.secondary, 0.2),
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                }
                            }}
                        >
                            {validationResult.overlappingBots?.map(bot => (
                                <Typography key={bot._id} variant="body2" color="text.secondary" sx={{ py: 0.5 }}>
                                    • {bot.name}
                                </Typography>
                            ))}
                        </Box>
                        <Typography variant="body1" sx={{ ml: 4 }}>
                            Having multiple active agents that can be triggered by the same senders might lead to unexpected behavior.
                        </Typography>
                    </Paper>
                );

            case 'EMPTY_MESSAGES':
                return (
                    <Paper 
                        key={`issue-${index}`} 
                        elevation={0} 
                        sx={{ 
                            mb: 3, 
                            p: 2.5, 
                            borderRadius: 2,
                            border: `1px solid ${alpha(theme.palette.warning.main, 0.2)}`,
                            backgroundColor: alpha(theme.palette.warning.main, 0.05)
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
                            <AlertTriangle 
                                size={20} 
                                color={theme.palette.warning.main} 
                                style={{ marginRight: theme.spacing(1.5), marginTop: '2px' }} 
                            />
                            <Typography variant="subtitle1" fontWeight={600} color="warning.main">
                                Warning: Empty Message Content Detected
                            </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ ml: 4, mb: 1.5 }}>
                            Your agent contains message nodes with no content. These nodes will be skipped during execution:
                        </Typography>
                        <Box 
                            sx={{ 
                                ml: 4, 
                                mb: 2, 
                                p: 1.5, 
                                borderRadius: 1.5, 
                                backgroundColor: alpha(theme.palette.background.paper, 0.7),
                                border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                                maxHeight: '120px', 
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: alpha(theme.palette.text.secondary, 0.2),
                                    borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                }
                            }}
                        >
                            {validationResult.emptyMessageNodes?.map(node => (
                                <Typography key={node.id} variant="body2" color="text.secondary" sx={{ py: 0.5 }}>
                                    • {node.type === 'sendMessage' ? 'Send Message' : 
                                       node.type === 'question' ? 'Question' : 
                                       node.type === 'messageContact' ? 'Message Contact' : node.type} 
                                    {node.label ? ` (${node.label})` : ` (${node.id})`}
                                </Typography>
                            ))}
                        </Box>
                    </Paper>
                );

            default:
                return null;
        }
    };

    const renderWarningContent = () => {
        // If there are no issues, show a simple confirmation
        if (!validationResult.issues || validationResult.issues.length === 0) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                    <Info size={24} color={theme.palette.info.main} style={{ marginRight: theme.spacing(2) }} />
                    <Typography variant="body1">
                        {`Are you sure you want to ${action} this bot?`}
                    </Typography>
                </Box>
            );
        }

        // If there's only one issue, render it
        if (validationResult.issues.length === 1) {
            return renderIssueContent(validationResult.issues[0].type, 0);
        }

        // If there are multiple issues, render them all with dividers
        return (
            <>
                <Typography 
                    variant="h6" 
                    gutterBottom 
                    sx={{ 
                        fontWeight: 600, 
                        mb: 3, 
                        display: 'flex', 
                        alignItems: 'center',
                        color: theme.palette.text.primary
                    }}
                >
                    <AlertTriangle 
                        size={20} 
                        style={{ marginRight: theme.spacing(1.5) }} 
                        color={theme.palette.warning.main}
                    />
                    Multiple issues detected
                </Typography>
                {validationResult.issues.map((issue, index) => (
                    <React.Fragment key={`issue-wrapper-${index}`}>
                        {renderIssueContent(issue.type, index)}
                        {index < validationResult.issues.length - 1 && (
                            <Divider sx={{ my: 2.5, opacity: 0.6 }} />
                        )}
                    </React.Fragment>
                ))}
                <Paper 
                    elevation={0} 
                    sx={{ 
                        mt: 3, 
                        p: 2, 
                        borderRadius: 2,
                        backgroundColor: alpha(theme.palette.info.main, 0.05),
                        border: `1px solid ${alpha(theme.palette.info.main, 0.2)}`
                    }}
                >
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Info 
                            size={18} 
                            color={theme.palette.info.main} 
                            style={{ marginRight: theme.spacing(1.5), flexShrink: 0 }} 
                        />
                        {`It's recommended to address these issues before ${isDeployment ? 'deploying' : 'activating'} your bot. Do you want to proceed anyway?`}
                    </Typography>
                </Paper>
            </>
        );
    };

    // Show confirm button for all cases except when the only issue is EMPTY_FLOW
    const showConfirmButton = !(
        validationResult.issues?.length === 1 && 
        validationResult.issues[0].type === 'EMPTY_FLOW'
    );

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            PaperProps={{
                elevation: 5,
                sx: {
                    borderRadius: 2,
                    overflow: 'hidden',
                    width: '550px',
                    maxWidth: '90vw'
                }
            }}
        >
            <DialogTitle sx={{ 
                py: 2.5, 
                px: 3,
                borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                backgroundColor: theme.palette.background.paper,
                fontWeight: 600,
                marginBottom: '20px'
            }}>
                {isDeployment ? 'Deploy Bot' : 'Activate Bot'}
            </DialogTitle>
            <DialogContent sx={{ 
                p: 3, 
                maxHeight: '60vh', 
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: alpha(theme.palette.text.secondary, 0.2),
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                }
            }}>
                {renderWarningContent()}
            </DialogContent>
            <DialogActions sx={{ 
                px: 3, 
                py: 2,
                borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                backgroundColor: alpha(theme.palette.background.default, 0.5)
            }}>
                <Button 
                    onClick={onClose} 
                    color="inherit" 
                    disabled={loading}
                    sx={{ 
                        fontWeight: 500,
                        px: 2.5,
                        borderRadius: 1.5
                    }}
                >
                    {!showConfirmButton ? 'Close' : 'Cancel'}
                </Button>
                {showConfirmButton && (
                    <Button 
                        onClick={onConfirm} 
                        variant="contained" 
                        color="primary"
                        disabled={loading}
                        sx={{
                            minWidth: '100px',
                            height: '36px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 500,
                            px: 2.5,
                            borderRadius: 1.5,
                            boxShadow: 2
                        }}
                    >
                        {loading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : buttonText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}; 