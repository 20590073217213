import React, { useState } from 'react';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { getCurrentBotSelector } from '@common-reducers/ChatbotSelectors';
import { Modal, Tooltip } from '@mui/material';
import BTChatbotModal from '../../bot-components/bt-chatbot-modal';
import classes from './llm-dialog.module.scss';
import { SettingsOutlined, SaveOutlined } from '@mui/icons-material';
import { nodeTypes } from '../bt-nodes-def';

interface LLMNodeFooterProps {
    id: string;
}

function LLMNodeFooter({ id }: LLMNodeFooterProps) {
    const [modalOpen, setModalOpen] = useState(false);
    const currentBot = useAppSelector(state => getCurrentBotSelector(state));
    
    // Get the variable name for this LLM node if it exists
    const variableName = currentBot?.botSettings?.variables?.[id];
    
    // Get the LLM node color from the node definitions
    const nodeColor = nodeTypes['LLM'].color;
    
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className={classes.llm_footer} style={{ 
            backgroundColor: '#F5F6FA' 
        }}>
            {variableName ? (
                <div className={classes.variable_info}>
                    <SaveOutlined className={classes.variable_icon} style={{ color: nodeColor }} />
                    <span>Response is stored as </span>
                    <Tooltip title="Click to edit variable configuration" arrow placement="top">
                        <code 
                            className={classes.variable_code} 
                            onClick={handleOpenModal}
                            style={{ 
                                backgroundColor: `${nodeColor}20`,
                                color: nodeColor,
                                borderColor: `${nodeColor}30`
                            }}
                        >
                            @{variableName}
                        </code>
                    </Tooltip>
                </div>
            ) : (
                <Tooltip title="Configure response storage" arrow placement="top">
                    <div 
                        className={classes.set_variable_prompt}
                        onClick={handleOpenModal}
                    >
                        <SettingsOutlined className={classes.settings_icon} style={{ color: nodeColor }} />
                        <span style={{ color: nodeColor }}>Configure response variable</span>
                    </div>
                </Tooltip>
            )}
            
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                sx={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <BTChatbotModal type="setLLM" onClose={handleCloseModal} id={id} />
            </Modal>
        </div>
    );
}

export default React.memo(LLMNodeFooter); 