import { Cancel as CancelIcon, Check, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import RowStack from "@common-components/common/row-stack/RowStack";
import ChatBotTimer from "./chatbot-timer-settings";
import FallbackMessage from "./fallback-message-settings";

import { useAppSelector } from "@common-reducers/hooks/store.hook";
import BtEditableBox from "@common/components/tasks/bt-editable-box/bt-editable-box";
import { getCurrentBotSelector } from '@common/reducers/ChatbotSelectors';
import { checkBotSettingsValidation } from "@common/services/utils";
import BTbotbutton from "./bt-bot-button";
import { DeployButtonWrapper } from './deploy-button-wrapper';
import { SaveButton } from './save-button';


const inputTheme = {
    padding: "0 10px",
    maxWidth: "280px",
    fontSize: "22px",
    fontWeight: "600",
    height: '33px',
    borderRadius: "8px",
    border: "1px solid #23a455",
    backgroundColor: "#e9ffe9",
    "& .MuiInputBase-input": {
        padding: "0",
    },
};
const textTheme = {
    fontSize: "22px",
    padding: "0 10px",
    borderRadius: "8px",
    border: "1px dashed transparent",
    fontWeight: "600",
    "&:hover": {
        border: "1px dashed #23a455",
    },
    height: '33px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '280px',
    boxSizing: 'border-box',
    lineHeight: '34px',
};


export type onChangeParams = {
    type: 'isFallbackMessage'
    | 'fallbackMessageTrigger'
    | 'fallbackMessage'
    | 'chatbotTimerCountdown'
    | 'isChatbotTimer'
    | 'chatbotTimerEndMessage'
    | 'chatbotTimerMessageBeforeEnd',
    event?: any,
    value?: any,
}


type Props = {
    onClose: () => void;
    id?: string;
}

const BotSettings = ({ onClose, id }: Props) => {

    const currentBot = useAppSelector(state => getCurrentBotSelector(state));
    const isDraft = useAppSelector(state => state.ChatbotReducer.isDraft);

    const [localCurrentBot, setLocalCurrentBot] = useState(currentBot)
    const [editBotName, setEditBotName] = useState(false)


    const checkBlockSaveNewBotSettings = React.useCallback(() => {
        return checkBotSettingsValidation(localCurrentBot?.botSettings);
    }, [localCurrentBot?.botSettings]);

    const [blockSaveNewBotSettings, setBlockSaveNewBotSettings] = useState(() =>
        checkBotSettingsValidation(currentBot?.botSettings)
    );

    const onChange = React.useCallback(({ type, event, value }: onChangeParams) => {
        let newValue;
        switch (type) {
            case 'isFallbackMessage':
                newValue = !localCurrentBot?.botSettings?.isFallbackMessage;
                break;
            case 'fallbackMessageTrigger':
                newValue = event.target.value;
                break;
            case 'fallbackMessage':
                newValue = value;
                break;
            case 'chatbotTimerCountdown':
                newValue = parseInt(value.replace(/\D/g, ''), 10);
                break;
            case 'isChatbotTimer':
                newValue = !localCurrentBot?.botSettings?.isChatbotTimer;
                break;
            case 'chatbotTimerEndMessage':
                newValue = value;
                break;
            case 'chatbotTimerMessageBeforeEnd':
                newValue = parseInt(value.replace(/\D/g, ''), 10);
                break;
        }
        setLocalCurrentBot(prevBot => ({
            ...prevBot,
            botSettings: {
                ...prevBot.botSettings,
                [type]: newValue
            }
        }));
    }, []);

    const handleEditName = React.useCallback((value) => {
        if (typeof value === 'string' && value.trim() !== '' && value !== localCurrentBot?.name) {
            setLocalCurrentBot(prevBot => ({
                ...prevBot,
                name: value
            }));
        }
    }, [localCurrentBot?.name]);

    const Header = () => {
        return (
            <RowStack sx={{ display: "flex", justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BtEditableBox
                        inputSx={inputTheme}
                        boxSx={textTheme}
                        text={localCurrentBot?.name}
                        forceEditMode={editBotName}
                        onBlur={(e) => {
                            setEditBotName(false)
                        }}
                        onValueUpdate={(value) => {
                            handleEditName(value);
                        }} />
                    {!editBotName &&
                        <IconButton onClick={(event) => { setEditBotName(true) }} disableRipple>
                            <EditIcon sx={{ color: 'black', fontSize: '21px', padding: '2px' }} />
                        </IconButton>
                    }
                    {editBotName &&
                        <IconButton onClick={(event) => { setEditBotName(false) }} disableRipple>
                            <Check sx={{ color: 'black', fontSize: '21px', padding: '2px' }} />
                        </IconButton>
                    }
                </div>
                <IconButton onClick={onClose} disableRipple>
                    <CloseIcon sx={{ color: 'black' }} />
                </IconButton>
            </RowStack>
        )
    }

    const Footer = () => {
        return (
            <RowStack sx={{ justifyContent: 'FLEX-END', marginTop: '40px' }} gap={3}>
                <BTbotbutton
                    styleType="empty"
                    onClick={onClose}
                >
                    Cancel
                </BTbotbutton>
                <SaveButton
                    disabled={blockSaveNewBotSettings || !isDraft}
                    localCurrentBot={localCurrentBot} 
                    onAfterSave={() => onClose()}
                    shouldClose={true}
                    label="Save"
                />
            </RowStack>
        )
    }

    useEffect(() => {
        setBlockSaveNewBotSettings(checkBlockSaveNewBotSettings());
    }, [checkBlockSaveNewBotSettings]);

    return (
        <Stack sx={{
            color: 'black',
            gap: '30px',
            '& > *:not(:last-child)': {
                borderBottom: '1px solid rgb(227, 227, 227)',
            },
        }} onClick={(e) => e.stopPropagation()}>
            {Header()}
            <FallbackMessage
                isFallbackMessage={localCurrentBot?.botSettings?.isFallbackMessage}
                onChange={onChange}
                fallbackMessage={localCurrentBot?.botSettings?.fallbackMessage}
                fallbackMessageTrigger={localCurrentBot?.botSettings?.fallbackMessageTrigger}
            />
            <ChatBotTimer
                isChatbotTimer={localCurrentBot?.botSettings?.isChatbotTimer}
                chatbotTimerCountdown={localCurrentBot?.botSettings?.chatbotTimerCountdown}
                chatbotTimerEndMessage={localCurrentBot?.botSettings?.chatbotTimerEndMessage}
                chatbotTimerMessageBeforeEnd={localCurrentBot?.botSettings?.chatbotTimerMessageBeforeEnd}
                onChange={onChange}
            />
            {Footer()}
        </Stack>
    );
}

export default BotSettings;