import { getNodeDataByNodeId } from '@/common/reducers/ChatbotSelectors';
import { CommonRootState } from '@/common/types/common-root-state-type';
import { getHandleId, getTargetHandleStyle } from '@common-services/utils';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Handle, NodeProps, Position } from 'reactflow';
import { CustomNodeData, nodeTypes } from '../bt-nodes-def';
import NodeContainer from '../node-container';
import styles from './agent-node.module.scss';
import SelectToolsDialog from './tools/select-tools-dialog';

export default function AgentNode({ id }: NodeProps<CustomNodeData>) {
    const data = useSelector((state: CommonRootState) => getNodeDataByNodeId(state, id));
    const type = data?.type;
   

    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                id={getHandleId(data?.handles, 'target', 0)}
                style={{ ...getTargetHandleStyle(nodeTypes?.[type]?.color), zIndex: 3 }}
            />
            <Box className={styles.agentNode}>
                <NodeContainer
                    type={type}
                    title={nodeTypes[type]?.title || 'Agent'}
                    color={nodeTypes[type]?.color || '#000000'}
                    nodeId={id}
                >
                </NodeContainer>
            </Box>
            {/* <CustomSourceHandle
                type="source"
                position={Position.Right}
                id={getHandleId(data.handles, 'source', 0)}
                style={{ ...getSourceHandleStyle(nodeTypes?.[type]?.color), zIndex: 3 }}
                connectioncount={1}
            /> */}

            {/* Select Tools Dialog */}
            <SelectToolsDialog />
        </>
    );
} 