import { AppConfig } from "./app.config";

let appConfigLocal = {}
try {
  const localConfig = require('./app.config.local');
  appConfigLocal = localConfig.appConfigLocal;
} catch (ex) {
  console.log('Local config not found...')
}

const appConfigDev: AppConfig = {
  DEFAULT_TITLE: 'Blueticks',
  asset_key: 'blueticks',
  google_client_id: '143723739146-oa75527ckplc3kb9nk8q41redtoq6t18.apps.googleusercontent.com',
  GTM_ID: '',
  GA_ID: '',
  CLARITY_ID:'',
  TIKTOK_ID:'',
  FB_PIXEL_ID: '',
  AW_ID: '',
  WS_ID: '',
  HJ_ID: 0,
  HJ_SV: 0,
  pricingOptions: 'extension',
  EXTENTION_ID: "faalgfonbpmhjboamhgopbjnbolehpcl",
  // WS_ENDPOINT: "ws://localhost:3310",
  API_ENDPOINT: "http://localhost:3310",
  //API_ENDPOINT: "https://blueticks-staging.herokuapp.com",
  // API_ENDPOINT: "https://blueticks-staging.herokuapp.com",
  APP_ENDPOINT: "http://localhost:3000",
  VZNLYTICS_ENDPOINT: "https://analytics.blueticks.co",
  GATEWAY_ENDPOINT: "http://localhost:3001",
  NOTIFIER_ENDPOINT: "http://localhost:3310",
  //NOTIFIER_ENDPOINT: "http://blueticks-api-dev.herokuapp.com",

  // GATEWAY_ENDPOINT: "https://gateway.blueticks.co",
  // NOTIFIER_ENDPOINT: "https://blueticks-notifier-staging.herokuapp.com",
  WS_ENDPOINT: "http://blueticks-api-dev.herokuapp.com",
  // API_ENDPOINT: "https://blueticks-staging.herokuapp.com",
  PRICING_PAGE: "http://localhost:3000",
  // APP_ENDPOINT: "https://app.blueticks.co",
  HOMEPAGE: "https://blueticks.co",
  LOGO:"/img/tudu-logo.svg",
  LOGO2:"/img/tudo-logo-icon.svg",
  PAYPAL_CLIENT_ID:
    "ARQG3WFWCo5c_cl0idcg5fPastyJNcC3Gpzgh-7y7wHqliqw8heanLqZZLDQK-JNc5Bt2tHNJUkVx5pC",
  ENV: "dev",
  BLUESNAP_ENDPOINT: "https://sandbox.bluesnap.com",
  BLUESNAP_STORE_ID: "296929",
  BLUESNAP_REDIRECT_ENC:
    "qOU7VjlOZpEXrX8r1i62voZwKP3M6kkP%2Br1vzvsSupdiE0Qg2F3CyOGQycNZgCn8K%2Fiy2kZ%2BIvFADXUZTrmv3PI4fjyTt8EwZwOPRxwLX1QmAv5wdoRog4pA4aYqzoCxhfs1hP7gmhsxfqUumgZTT7vPVFc44W6Y45Y4AkQkilLhQKTU05HCFjx8eh30pkIzYgQYco52LMwbGjdPK%2FNJ6TC43C8TZTPnUSjELG7cJNyPc91K8YHmJqVijKcmtkWq",
  // BLUESNAP_ENDPOINT: "https://bluesnap.com",
  // BLUESNAP_STORE_ID: "648495",
  // BLUESNAP_REDIRECT_ENC:
  //   "y3BO7mSu04vXEh55QSFI51vnnO%2FrL2uZ10%2FPUF2Fh8urn%2BFdpIn4tyxpPqhcCnm%2BVOdueGkdnZpbpSjw%2Fj215nog250lMwUyqKry1OkfDap8daL1Nxv4dJeDeSXd9Ai6vUKYhCKeZaQ%2ByyfLkDPwJwlx1BGnPQYlRYkYiZBknRcVCl6kAgHv5ZTKEc3YMGPO4kc9iJJQnqXsotsbI3Z5jgJ5rXvslcQJXPihvCycO2%2FJfYH%2F2cr5s6ZLhSZZZaZY",
  extensionStoreLink: "https://chrome.google.com/webstore/detail/blueticks/adgnjhngogijkkppficiiepmjebijinl",
  ...appConfigLocal,
};
export default appConfigDev;
