import { CalendarColors, UserMessage } from "@common-models/user-message";
import { DBUserMessageThunks, userMessagesSelectByQuery } from "@common-reducers/DBServiceThunks";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { accessFormPopupActions } from "./AccessFormPopupReducer";
import { addScheduledMessageButtonActions } from "./AddScheduledMessageButtonReducer";
import { selectUserObject } from "./UserSelectors";
import { CalendarEvent, confirmationDialogOption, EventPL } from "@common-views/calendar/message-calendar";
import { scheduleMessage } from "@common/thunks/UserMessagesThunks";
import { CommonRootState } from "@common/types/common-root-state-type";
import { RRuleRecurrenceHandler } from "@common/services/utils/rrule-recurrence-handler";



type HandleNewMessageDialogThunkParams = {
    slotInfo: any;
};

export const handleNewMessageDialogThunk = createAsyncThunk<any, HandleNewMessageDialogThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ slotInfo }, { getState, dispatch }) => {
        const state = getState();
        const user = selectUserObject(state);
        const currentChat = state.WhatsAppReducer.currentChat;
        let slotStart = moment(state.WhatsAppReducer.hoveredDate ?? slotInfo?.start);

        slotStart = slotStart.isSame(moment(), 'day')
            ? slotStart.set({ hour: moment().hour(), minute: moment().minute(), second: moment().second() })
            : slotStart.set({ hour: 12, minute: 0, second: 0 });

        if (!user) {
            dispatch(accessFormPopupActions.setStatus("Signup"));
            return;
        }

        dispatch(
            addScheduledMessageButtonActions.editMessage({
                dueDate: slotStart.startOf("minute").toISOString(),
                message: "",
                isRecurring: false,
                cancelIfReceived: false,
                contactList: [{
                    ...currentChat,
                    id: currentChat?.id?._serialized,
                    name: currentChat?.name ?? currentChat?.displayName,
                }],
                calendarMessage: true,
            } as UserMessage)
        );
    }
);


type ColorsRecurringDialogCloseThunkParams = {
    value?: confirmationDialogOption;
    event: EventPL;
    eventColor: string;
};

export const onColorsRecurringDialogCloseThunk = createAsyncThunk<any, ColorsRecurringDialogCloseThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ value, eventColor, event }, { getState, dispatch }) => {
        if (!value) return;

        const state = getState();
        const messages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        });

        let original = messages.find((m) => m._id === event._id);
        let colors = original.calendarColors ?? ({ specific: [], ranges: [] } as CalendarColors);

        if (value === "This message") {
            colors = {
                ...colors,
                specific: [...colors.specific, { messageTime: event.dueDate, color: eventColor }],
            };
        } else if (value === "This and following messages") {
            colors = {
                ...colors,
                ranges: [...colors.ranges, { fromTime: event.dueDate, color: eventColor }],
            };
        } else {
            colors = { defaultColor: eventColor, specific: [], ranges: [] };
        }

        original = { ...original, calendarColors: colors };
        dispatch(scheduleMessage(original));
    }
);

type HandleNewMessageThunkParams = {
    slotStart: moment.Moment;
};

export const handleNewMessageThunk = createAsyncThunk<any, HandleNewMessageThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ slotStart }, { getState, dispatch }) => {
        const state = getState();
        const user = selectUserObject(state);
        const currentChat = state.WhatsAppReducer.currentChat;

        if (!user) {
            dispatch(accessFormPopupActions.setStatus("Signup"));
            return;
        }

        dispatch(
            addScheduledMessageButtonActions.editMessage({
                dueDate: slotStart.startOf("minute").toISOString(),
                message: "",
                isRecurring: false,
                cancelIfReceived: false,
                contactList: [{
                    ...currentChat,
                    id: currentChat?.id?._serialized,
                    name: currentChat?.name ?? currentChat?.displayName,
                }],
                calendarMessage: true,
            } as UserMessage)
        );
    }
);

type HandleClickWeedTimeSlotThunkParams = {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    date: any;
};

export const handleClickWeedTimeSlotThunk = createAsyncThunk<any, HandleClickWeedTimeSlotThunkParams, { state: CommonRootState }>(
    "campaigns/removeHandleAndConnectedEdgesByHandleIdArrayThunk",
    async ({ event, date }, { getState, dispatch }) => {
        const state = getState();
        const user = selectUserObject(state);
        const currentChat = state.WhatsAppReducer.currentChat;

        event.stopPropagation();
        const dueDate = moment(date);

        if (!user) {
            dispatch(accessFormPopupActions.setStatus("Signup"));
            return;
        }

        dispatch(
            addScheduledMessageButtonActions.editMessage({
                dueDate: dueDate.startOf("minute").toISOString(),
                message: "",
                isRecurring: false,
                cancelIfReceived: false,
                contactList: [{
                    ...currentChat,
                    id: currentChat?.id?._serialized,
                    name: currentChat?.name ?? currentChat?.displayName,
                }],
                calendarMessage: true,
            } as UserMessage)
        );
    }
);


const handler = new RRuleRecurrenceHandler();


export interface DeleteRecurringDialogCloseThunkParams {
    value: confirmationDialogOption;
    event: CalendarEvent | EventPL;
}

const mapDialogOptionToScope = (value: confirmationDialogOption): 'all' | 'this' | 'thisAndFollowing' => {
    if (value === "All messages") {
        return "all";
    }
    if (value === "This message") {
        return "this";
    }
    return "thisAndFollowing";
};

export const onDeleteRecurringDialogCloseThunk = createAsyncThunk<any, DeleteRecurringDialogCloseThunkParams, { state: CommonRootState }>(
    "calendar/onDeleteRecurringDialogClose",
    async ({ value, event }, { getState, dispatch }) => {
        if (!value) return;

        const state = getState();
        const messages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        });

        const original = messages.find((m) => m._id === event._id);
        if (!original?.rruleset) {
            console.log('[DELETE_RECURRING] No original message or rruleset found');
            return;
        }

        try {
            const scope = mapDialogOptionToScope(value);
            
            // Extract the original due date from the original message
            const originalDateTime = new Date(original.dueDate);

            const result = handler.handleDeletion(
                original.rruleset,           // originalRuleStr
                new Date(event.dueDate),     // deletedDate
                scope,                       // scope
                originalDateTime             // Add the originalDateTime parameter
            );

            if (result.originalAction === 'delete') {
                console.log('[DELETE_RECURRING] Deleting original message');
                dispatch(DBUserMessageThunks.delete({ entity: original }));
            } else if (result.originalAction === 'patch') {
                console.log('[DELETE_RECURRING] Patching original message with new rule:', result.originalNewRRule);
                const patchedMessage = result.originalNewRRule
                    ? { ...original, rruleset: result.originalNewRRule, isRecurring: true, dueDate: result.originalDueDate }
                    : { ...original, rruleset: undefined, isRecurring: false, dueDate: result.originalDueDate };
                dispatch(scheduleMessage(patchedMessage));
            }
        } catch (error) {
            console.error('[DELETE_RECURRING] Error handling deletion:', error);
            throw error;
        }
    }
);



export interface RecurringDialogCloseThunkParams {
    value: confirmationDialogOption;
    event: CalendarEvent | EventPL;
}

export const onRecurringDialogCloseThunk = createAsyncThunk<any, RecurringDialogCloseThunkParams, { state: CommonRootState }>(
    "calendar/onRecurringDialogClose",
    async ({ value, event }, { getState, dispatch }) => {
        if (!value) return;

        const state = getState();
        const messages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            $or: [{ deleted: { $exists: false } }, { deleted: false }],
            status: { $in: ['pending', 'sending'] },
        });

        const original = messages.find((m) => m._id === event._id);
        if (!original?.rruleset) {
            console.log('[RECURRING_DIALOG] No original message or rruleset found');
            return;
        }

        try {
            const scope = mapDialogOptionToScope(value);

            // Extract the original due date from the original message
            const originalDateTime = new Date(original.dueDate);

            const result = handler.handleDragAndDrop(
                original.rruleset,                                    // originalRuleStr
                new Date(state.CalendarReducer.selectedEventDate),   // draggedDate (original event date)
                new Date(event.dueDate),                              // newDate (where event was dropped)
                scope,                                               // scope
                originalDateTime                                    // Add the originalDateTime parameter
            );

            if (result.originalAction === 'delete') {
                console.log('[RECURRING_DIALOG] Deleting original message');
                dispatch(DBUserMessageThunks.delete({ entity: original }));
            } else if (result.originalAction === 'patch') {
                console.log('[RECURRING_DIALOG] Patching original message with new rule:', result.originalNewRRule);
                const patchedMessage = result.originalNewRRule
                    ? { ...original, rruleset: result.originalNewRRule, isRecurring: true, dueDate: result.originalDueDate }
                    : { ...original, rruleset: undefined, isRecurring: false, dueDate: result.originalDueDate };
                dispatch(scheduleMessage(patchedMessage));
            }

            if (result.newAction === 'create') {
                console.log('[RECURRING_DIALOG] Creating new message');
                const newMessage: UserMessage = {
                    ...event,
                    _id: undefined,  // Remove _id to create new message
                    dueDate: result.newDueDate,
                    rruleset: result.newRRule,
                    isRecurring: !!result.newRRule
                };
                dispatch(scheduleMessage(newMessage));
            }
        } catch (error) {
            console.error('[RECURRING_DIALOG] Error handling drag and drop:', error);
            throw error;
        }
    }
); 