import CustomSourceHandle from '@common-components/bt-chatbot/custom-source-handle';
import { getHandleId, getSourceHandleStyle } from '@common-services/utils';
import { NodeProps, Position } from 'reactflow';
import { CustomNodeData, nodeTypes } from '../bt-nodes-def';
import TriggerNodeContainer from './trigger-node-container';
import classes from "./trigger-node.module.scss";

export default function TriggerNode({ id, data }: NodeProps<CustomNodeData>) {
    const type = data?.type;
    const nodeColor = nodeTypes?.[type]?.color;

    return (
        <>
            <TriggerNodeContainer id={id} />
            <CustomSourceHandle
                type="source"
                position={Position.Right}
                id={getHandleId(data.handles, 'source', 0)}
                className={classes.trigger_source_handle}
                style={getSourceHandleStyle(nodeColor)}
                connectioncount={1}
            />
        </>
    );
} 