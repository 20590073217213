import { automationSessionSelectByQuery, botDeploymentSelectOneObjectByQuery, botSelectByQuery } from '@common-reducers/DBServiceThunks';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { memo } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface DeploymentCardProps {
    deploymentId: string;
    isSelected: boolean;
    onSelect: () => void;
}

export const DeploymentCard = memo(({ deploymentId, isSelected, onSelect }: DeploymentCardProps) => {
    const deployment = useAppSelector(state => 
        botDeploymentSelectOneObjectByQuery(state.DBBotDeploymentReducer, { _id: deploymentId })
    );
    
    const sessionCount = useAppSelector(state =>
        (automationSessionSelectByQuery(state.DBAutomationSessionReducer, {
            botDeploymentId: deploymentId
        }) ?? []).length
    );
    
    const activeSessions = useAppSelector(state =>
        (automationSessionSelectByQuery(state.DBAutomationSessionReducer, {
            botDeploymentId: deploymentId,
            status: { $in: ['open', 'processing'] }
        }) ?? []).length
    );
    
    const isActive = useAppSelector(state => {
        const bots = botSelectByQuery(state.DBBotReducer, {}) || [];
        const bot = bots.find(b => b.activeDeploymentId === deploymentId);
        return !!bot;
    });

    if (!deployment) return null;

    const deploymentDate = deployment.createdAt 
        ? formatDistanceToNow(new Date(deployment.createdAt), { addSuffix: true })
        : 'Unknown date';

    return (
        <div 
            className={cn(
                "p-4 rounded-lg border cursor-pointer transition-colors",
                isSelected 
                    ? "bg-emerald-50 border-emerald-200" 
                    : "bg-white border-gray-200 hover:bg-gray-50"
            )}
            onClick={onSelect}
        >
            <div className="flex justify-between items-start mb-2">
                <div className="flex items-center gap-2">
                    <span className="text-sm font-medium">Version {deployment.version || '?'}</span>
                    {isActive && (
                        <Badge variant="outline" className="bg-emerald-50 text-emerald-700 border-emerald-200">
                            Active
                        </Badge>
                    )}
                </div>
                <span className="text-xs text-gray-500">{deploymentDate}</span>
            </div>
            
            <div className="flex gap-4 mt-2">
                <div className="text-xs text-gray-500">
                    <span className="font-medium text-gray-700">{sessionCount}</span> sessions
                </div>
                {activeSessions > 0 && (
                    <div className="text-xs text-gray-500">
                        <span className="font-medium text-emerald-600">{activeSessions}</span> active
                    </div>
                )}
            </div>
        </div>
    );
}); 