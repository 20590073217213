import { ReactComponent as AttachFileSVG } from "@common-assets/attachFileSVG.svg"
import BTTextField from '@common-components/atoms/text-field/bt-text-field'
import AttachmentPreview from "@common-components/attachment-preview/attachment-preview"
import RowStack from "@common-components/common/row-stack/RowStack"
import { useGetSignedUploadUrlMutation } from '@common-reducers/backend-api/backend-api'
import { insertTextAtCursor, messageFormatBtn, uploadFilePut } from '@common-services/utils'
import { useAppSelector } from "@common/reducers/hooks/store.hook"
import { orangeStyle, textFieldSX } from '@common/reusable/commonStyles'
import { Asset } from "@common/types/interface"
import { DeleteOutline, Download, FormatBold, FormatItalic, StrikethroughS } from '@mui/icons-material'
import { Button, CircularProgress, ClickAwayListener, IconButton, Menu } from '@mui/material'
import { Stack } from '@mui/system'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import React, { ChangeEvent, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { IoMdCloseCircle } from 'react-icons/io'
import { MdInsertEmoticon } from 'react-icons/md'
import SelectVariable from './select-variable'

type Props = {
    _message?: { message: string, asset: Asset };
    onChange: (data: { message: string, asset: Asset }) => void;
    type?: string;
}

function BotMessageEditor({
    _message,
    onChange,
    type,
}: Props) {
    const isReadOnly = useAppSelector(state => state.ChatbotReducer.isReadOnly);
    const [loadingAttachment, setLoadingAttachment] = useState(false);

    const messageTextArea = React.createRef<HTMLInputElement>();
    const emojiButtonRef = useRef();

    const [getSignedUploadUrl] = useGetSignedUploadUrlMutation();

    // const [localMessageState, setLocalMessageState] = useState(_message ?? { message: '', asset: undefined });
    const [isEmojiPicker, seIsEmojiPicker] = useState<boolean>(false);


    const showFormatButtons = useMemo(() => {
        if (type === 'LLM' || type === 'agent') {
            return false;
        }
        return true;
    }, [type])


    // useEffect(() => {
    //     if (localMessageState) {
    //         onChange(localMessageState)
    //     }
    // }, [localMessageState])


    const removeAttachment = useCallback(() => {
        onChange({
            ..._message,
            asset: undefined,
        })
    }, [_message, onChange]);

    const handleSelectedFiles = useCallback(async (acceptedFiles: File[]) => {
        removeAttachment();
        setLoadingAttachment(true);
        const [attachment] = acceptedFiles;

        // Handle no attachment
        if (!attachment) {
            setLoadingAttachment(false);
            return;
        }

        const uploadUrlResponse: { url: string; publicUrl: string } = await getSignedUploadUrl(attachment?.type).unwrap();

        try {
            await uploadFilePut(attachment, uploadUrlResponse.url);

            let updatedMessage;

            updatedMessage = {
                ..._message,
                message: _message.message,
                asset: {
                    url: uploadUrlResponse.publicUrl,
                    name: attachment.name,
                    type: attachment.type,
                }
            };
            onChange(updatedMessage)
        } catch (e) {
            console.error(e);
        }

        setLoadingAttachment(false);
    }, [removeAttachment, getSignedUploadUrl, _message, onChange])

    const onDrop = useCallback(acceptedFiles => { handleSelectedFiles(acceptedFiles) }, [handleSelectedFiles])

    const { getRootProps, getInputProps, inputRef, isDragActive } = useDropzone({ onDrop, noClick: true })
    const rootProps = getRootProps();
    const inputProps = getInputProps();

    const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        handleSelectedFiles(Array.from(e.target.files))
    };


    const onEmojiClick = (emoji: EmojiClickData, event: MouseEvent) => {
        onChange({
            ..._message,
            message: insertTextAtCursor(messageTextArea, emoji.emoji),
        })
    };

    const botMessageEditorPlaceholder = (type: string) => {
        if (type === 'LLM') {
            return 'Type a prompt';
        }
        return 'Type a message';
    }

    const messageBox = () => {
        return (
            <Stack direction='column' sx={{
                backgroundColor: '#F5F6FA',
                borderRadius: '8px',
                border: '0px',
            }}>
                <BTTextField
                    disabled={isReadOnly}
                    className="nodrag"
                    placeholder={botMessageEditorPlaceholder(type)}
                    fullWidth={true}
                    ref={messageTextArea}
                    multiline
                    rows={4}
                    inputProps={{
                        dir: "auto",
                    }}
                    variant="filled"
                    value={_message.message}
                    onChange={(event) => onChange({
                        ..._message,
                        message: event.target.value,
                    })}
                    sx={{
                        ...textFieldSX, color: 'rgba(0, 50, 15, 0.65)',
                        "& .MuiInputBase-root": {
                            backgroundColor: 'transparent !important',
                        },
                        "& .MuiInputBase-root:before": {
                            borderBottom: '1px solid rgb(232, 234, 242) !important',
                        },
                        "& .MuiInputBase-root:after": {
                            borderBottom: '1px solid rgb(232, 234, 242) !important',
                        },
                    }}
                />
            </Stack>
        )
    }

    const fontFormatButtons = () => {
        return (
            <Stack direction="row" sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
                height: '41px',
            }}>
                {showFormatButtons &&
                    <RowStack>
                        <IconButton
                            disabled={isReadOnly}
                            sx={{
                                color: 'var(--primary)',
                                padding: '5px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            onClick={() =>
                                onChange({
                                    ..._message,
                                    message: messageFormatBtn(messageTextArea, "*"),
                                })
                            }
                        >
                            <FormatBold />
                        </IconButton>
                        <IconButton
                            disabled={isReadOnly}
                            sx={{
                                color: 'var(--primary)',
                                padding: '5px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            onClick={() =>
                                onChange({
                                    ..._message,
                                    message: messageFormatBtn(messageTextArea, "_"),
                                })
                            }
                        >
                            <FormatItalic />
                        </IconButton>
                        <IconButton
                            disabled={isReadOnly}
                            sx={{
                                color: 'var(--primary)',
                                padding: '5px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            onClick={() =>
                                onChange({
                                    ..._message,
                                    message: messageFormatBtn(messageTextArea, "~"),
                                })
                            }
                        >
                            <StrikethroughS />
                        </IconButton>
                        <IconButton
                            disabled={isReadOnly}
                            sx={{
                                color: 'var(--primary)',
                                padding: '5px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            ref={emojiButtonRef}
                            onClick={() => seIsEmojiPicker(!isEmojiPicker)}
                            focusRipple={true}
                        >
                            {isEmojiPicker ? <IoMdCloseCircle /> : <MdInsertEmoticon />}
                        </IconButton>
                        <Menu open={isEmojiPicker} anchorEl={emojiButtonRef.current} onClose={() => seIsEmojiPicker(false)}>
                            <EmojiPicker
                                onEmojiClick={onEmojiClick}
                            />
                        </Menu>
                        <IconButton
                            disabled={isReadOnly}
                            sx={{
                                color: 'var(--primary)',
                                textTransform: 'none',
                                letterSpacing: 0,
                                fontWeight: 600,
                                fontSize: '14px',
                                marginRight: '12px',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                            onClick={() => {
                                inputRef?.current.click();
                            }}>
                            <AttachFileSVG style={{ alignSelf: 'center', marginInlineEnd: '5px', }} />
                            Add file
                        </IconButton>
                    </RowStack>
                }
                <SelectVariable onSelect={(item) => {
                    onChange({
                        ..._message,
                        message: insertTextAtCursor(messageTextArea, item, true),
                    })
                }} />
            </Stack>
        )
    }

    async function downloadAttachment(attachmentUrl: string, attachmentName: string) {
        try {
            const response = await fetch(attachmentUrl);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadUrl;
            downloadLink.setAttribute('download', attachmentName || 'download');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed', error);
        }
    }

    const attachmentBox = () => <Stack spacing={2}>
        {_message.asset &&
            <div style={{ position: "relative", }}>
                <Stack alignItems="center" justifyContent="center" sx={{ marginBottom: '15px', }}>
                    <RowStack sx={{ mb: 2 }}>
                        <Button
                            variant="outlined"
                            startIcon={<DeleteOutline />}
                            onClick={removeAttachment}
                            style={{
                                ...orangeStyle,
                                marginRight: '10px',
                            }}
                        >
                            REMOVE ATTACHMENT
                        </Button>
                        <IconButton onClick={() => downloadAttachment(_message.asset?.url, _message.asset?.name)}>
                            <Download />
                        </IconButton>
                    </RowStack>

                    <AttachmentPreview
                        attachmentName={_message.asset?.name}
                        attachmentType={_message.asset?.type}
                        attachmentUrl={_message.asset?.url}
                        previewImageWidth={'450px'}
                    />
                </Stack>
            </div>
        }
    </Stack >



    return (
        <Stack sx={{
            borderRadius: type === 'LLM' ? '8px 8px 0 0' : '8px',
            background: 'rgb(245, 246, 250)',
            padding: '0px 10px',
            minHeight: '150px',
            boxSizing: 'border-box',
        }} {...rootProps}>
            <input
                type="file"
                style={{ display: "none" }}
                onChange={onChangeFile}

                {...inputProps}

            />
            {loadingAttachment &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    direction: 'ltr',
                    alignItems: 'center',
                    width: '100%',
                    height: '125px',
                }}>
                    <CircularProgress />
                </div>
            }
            {!loadingAttachment &&
                messageBox()
            }
            {fontFormatButtons()}
            {attachmentBox()}
        </Stack>
    )
}

export default memo(BotMessageEditor, (prevProps, nextProps) => {
    return JSON.stringify(prevProps._message) === JSON.stringify(nextProps._message);
});