import { Box, Card, CardContent, Container, Grid, Paper, Tab, Tabs, Typography, Button } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import CampaignIcon from '@mui/icons-material/Campaign';
import HistoryIcon from '@mui/icons-material/History';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ForumIcon from '@mui/icons-material/Forum';
import Timeline from '@mui/icons-material/Timeline';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { MessagesTab } from './tabs/messages/MessagesTab';
import { MessageLogsTab } from './tabs/message-logs/MessageLogsTab';
import { SessionsTab } from './tabs/sessions/SessionsTab';
import { CampaignsTab } from './tabs/campaigns/CampaignsTab';
import { SubscriptionsTab } from './tabs/subscriptions/SubscriptionsTab';
import { TimeAgo } from './components/time-ago';
import moment from 'moment';
import { DBMessageLogThunks, DBUserMessageThunks, DBSessionThunks, DBCampaignThunks, DBSubscriptionThunks, subscriptionSelectByQuery } from '@common-reducers/DBServiceThunks';
import { UserActionsMenu } from './components/user-actions-menu';
import { SessionStatusPill } from './components/session-status-pill';
import { SubscriptionStatusChip } from './components/subscription-status-chip';
import { SubscriptionObject } from '@common/models/subscription';
import { selectCurrentWorkspace } from '@common-reducers/UserSelectors';
import { Workspace } from '@common/models/workspace';

const formatTimestamp = (timestamp: string | Date | moment.Moment | undefined): string => {
    if (!timestamp) return '';
    if (moment.isMoment(timestamp)) {
        return timestamp.toISOString();
    }
    if (timestamp instanceof Date) {
        return timestamp.toISOString();
    }
    return timestamp;
};

const TAB_NAMES = ['messages', 'campaigns', 'sessions', 'message-logs', 'subscriptions'];

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function UserOverview() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const supportedUserId = searchParams.get('supportedUserId');
    
    // Get initial tab from URL or default to messages (0)
    const initialTab = (() => {
        const tabName = searchParams.get('tab');
        const tabIndex = TAB_NAMES.indexOf(tabName || '');
        return tabIndex >= 0 ? tabIndex : 0;
    })();
    const [tabValue, setTabValue] = useState(initialTab);
    const [isLoading, setIsLoading] = useState(false);

    const totalMessages = useAppSelector((state) => state.DBUserMessageReducer.total || 0);
    const totalSessions = useAppSelector((state) => 
        Object.values(state.DBSessionReducer.entities).filter(session => session?.status === 'connected').length || 0
    );
    const totalCampaigns = useAppSelector((state) => state.DBCampaignReducer.total || 0);

    const user = useAppSelector((state) => state.DBUserReducer.entities[supportedUserId]);
    const latestSession = useAppSelector((state) => {
        const userSessions = Object.values(state.DBSessionReducer.entities)
            .filter(session => session?.owner === supportedUserId)
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
        return userSessions[0];
    });

    const allSubscriptions = useAppSelector(state => subscriptionSelectByQuery(state.DBSubscriptionReducer, {})) || [];
    const currentWorkspace = useAppSelector(selectCurrentWorkspace) as Workspace;
    const latestSubscription = useMemo(() => {
        console.log('Current Workspace:', currentWorkspace);
        console.log('All Subscriptions:', allSubscriptions);
        
        const workspaceSubscriptions = allSubscriptions.filter(sub => 
            sub.owner === supportedUserId && 
            sub.workspaceId === currentWorkspace?._id
        );
        console.log('Workspace Subscriptions:', workspaceSubscriptions);
        
        const activeSubscriptions = workspaceSubscriptions.filter(sub => 
            sub.status.toLowerCase() === 'active'
        );
        console.log('Active Workspace Subscriptions:', activeSubscriptions);

        const sortedSubscriptions = activeSubscriptions
            .map(sub => new SubscriptionObject(sub))
            .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
        
        return sortedSubscriptions[0];
    }, [allSubscriptions, supportedUserId, currentWorkspace]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // Update URL with new tab
        const params = new URLSearchParams(location.search);
        params.set('tab', TAB_NAMES[newValue]);
        navigate({ search: params.toString() }, { replace: true });
    };

    // Update tab value if URL changes
    useEffect(() => {
        const tabFromUrl = TAB_NAMES.indexOf(searchParams.get('tab') || '');
        if (tabFromUrl >= 0 && tabFromUrl !== tabValue) {
            setTabValue(tabFromUrl);
        } else if (tabFromUrl === -1 && tabValue !== 0) {
            // If invalid tab in URL, default to messages
            setTabValue(0);
            const params = new URLSearchParams(location.search);
            params.set('tab', TAB_NAMES[0]);
            navigate({ search: params.toString() }, { replace: true });
        }
    }, [location.search, navigate, searchParams, tabValue]);

    // Load all data when component mounts
    useEffect(() => {
        if (supportedUserId) {
            setIsLoading(true);
            Promise.all([
                dispatch(DBUserMessageThunks.find({
                    owner: supportedUserId,
                    $sort: { createdAt: -1 }
                })),
                dispatch(DBMessageLogThunks.find({
                    userId: supportedUserId,
                    $sort: { createdAt: -1 },
                    $limit: -1
                })),
                dispatch(DBSessionThunks.find({
                    owner: supportedUserId,
                    $sort: { updatedAt: -1 }
                })),
                dispatch(DBCampaignThunks.find({
                    owner: supportedUserId
                })),
                dispatch(DBSubscriptionThunks.find({}))
            ]).finally(() => {
                setIsLoading(false);
            });
        }
    }, [supportedUserId, dispatch]);

    const getBillingDuration = (subscription: any) => {
        if (subscription.type === 'chargebee') {
            const [, , interval] = (subscription.plan_id as string).split('-');
            return interval === 'Monthly' ? 'Monthly' : 'Annual';
        }

        if (subscription.plan?.intervalUnit === 'year') {
            return 'Annual';
        }
        if (subscription.plan?.intervalUnit === 'month') {
            return 'Monthly';
        }

        return subscription.plan?.intervalUnit || 'N/A';
    };

    const getPlanName = (subscription: any) => {
        if (subscription.type === 'chargebee') {
            const [planName] = (subscription.plan_id as string).split('-');
            return planName;
        }
        return subscription.plan?.planType || 'N/A';
    };

    if (!supportedUserId) {
        return (
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    No user ID provided
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            User Overview
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Box>
                                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                                        {user?.email}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                        <Typography variant="caption" color="text.secondary">
                                            ID: {supportedUserId}
                                        </Typography>
                                        {user?.createdAt && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    User since:
                                                </Typography>
                                                <TimeAgo timestamp={formatTimestamp(user.createdAt)} showDate={true} />
                                            </Box>
                                        )}
                                        {currentWorkspace && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Workspace:
                                                </Typography>
                                                <Typography variant="body2">
                                                    {currentWorkspace.name}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <UserActionsMenu userId={supportedUserId} currentEmail={user?.email} />
                            </Box>
                            {latestSession && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Session Status:
                                    </Typography>
                                    <SessionStatusPill status={latestSession.status} />
                                </Box>
                            )}
                            {latestSubscription && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Subscription:
                                    </Typography>
                                    <SubscriptionStatusChip status={latestSubscription.status} />
                                    <Typography variant="body2">
                                        {getPlanName(latestSubscription)} ({getBillingDuration(latestSubscription)})
                                    </Typography>
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<Timeline />}
                                    onClick={() => {
                                        const url = new URL(`/session-timeline/${supportedUserId}`, window.location.origin);
                                        window.open(url.toString(), '_blank');
                                    }}
                                    size="small"
                                >
                                    View Timeline
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Summary Cards */}
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <MessageIcon color="primary" sx={{ mb: 1, fontSize: 30 }} />
                                <Typography variant="h6">Total Messages</Typography>
                                <Typography variant="h4">{totalMessages}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardContent>
                                <CampaignIcon color="primary" sx={{ mb: 1, fontSize: 30 }} />
                                <Typography variant="h6">Active Campaigns</Typography>
                                <Typography variant="h4">{totalCampaigns}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>

            {/* Tabs Section */}
            <Paper elevation={3}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{ 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            textTransform: 'none',
                            fontSize: '1rem'
                        }
                    }}
                >
                    <Tab label="Messages" icon={<MessageIcon />} iconPosition="start" />
                    <Tab label="Campaigns" icon={<CampaignIcon />} iconPosition="start" />
                    <Tab label="Sessions" icon={<ForumIcon />} iconPosition="start" />
                    <Tab label="Message Logs" icon={<HistoryIcon />} iconPosition="start" />
                    <Tab label="Subscriptions" icon={<SubscriptionsIcon />} iconPosition="start" />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <MessagesTab supportedUserId={supportedUserId} />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <CampaignsTab supportedUserId={supportedUserId} />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <SessionsTab supportedUserId={supportedUserId} />
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                    <MessageLogsTab supportedUserId={supportedUserId} />
                </TabPanel>

                <TabPanel value={tabValue} index={4}>
                    <SubscriptionsTab supportedUserId={supportedUserId} />
                </TabPanel>
            </Paper>
        </Container>
    );
} 