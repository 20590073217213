import { createAsyncThunk } from "@reduxjs/toolkit";
import { 
  backendApiEndpoints, 
  GetCheckoutPageUrlParams 
} from "./backend-api/backend-api";
import { CommonRootState } from "@common/types/common-root-state-type";
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// Interfaces for credit package purchase
export interface CreditPackagePricing {
  price: number;
  perCredit: number;
  billingCycle: 'monthly' | 'annual';
  id: string; // The Chargebee item price ID
}

export interface CreditPackage {
  credits: number;
  monthly?: CreditPackagePricing;
  annual?: CreditPackagePricing;
  popular?: boolean;
  savePercentage?: number;
  description?: string;
  name?: string;
}

export interface PurchaseCreditPackageParams {
  packageId: string; // The Chargebee item price ID
  quantity?: number;
}

// Type guard to check if the result has an error
function isErrorResult(result: any): result is { error: FetchBaseQueryError | SerializedError } {
  return result && 'error' in result;
}

// Type guard to check if the result has data
function isDataResult<T>(result: any): result is { data: T } {
  return result && 'data' in result;
}

/**
 * Determines if an add-on is for monthly or annual billing
 */
function getBillingCycle(addonId: string): 'monthly' | 'annual' {
  const lowerCaseId = addonId.toLowerCase();
  return lowerCaseId.includes('monthly') ? 'monthly' : 'annual';
}

/**
 * Organizes raw add-ons into structured credit packages with monthly/annual options
 */
function organizeAddons(addons: any[]): CreditPackage[] {
  // Group add-ons by credit amount
  const packagesByCredits: Record<number, CreditPackage> = {};
  
  // First pass: group by credits
  addons.forEach(addon => {
    const credits = addon.credits;
    const billingCycle = getBillingCycle(addon.id);
    
    if (!packagesByCredits[credits]) {
      packagesByCredits[credits] = {
        credits: credits,
        name: addon.name?.replace(/ (USD|Monthly|Yearly).*$/i, '') || `${credits} Credits`,
        description: addon.description || '',
        popular: addon.popular || false,
        savePercentage: addon.savePercentage || null
      };
    }
    
    // Add pricing info based on billing cycle
    if (billingCycle === 'monthly') {
      packagesByCredits[credits].monthly = {
        price: addon.price,
        perCredit: addon.perCredit,
        billingCycle: 'monthly',
        id: addon.id
      };
    } else {
      packagesByCredits[credits].annual = {
        price: addon.price,
        perCredit: addon.perCredit,
        billingCycle: 'annual',
        id: addon.id
      };
    }
  });
  
  // Convert to array and sort by credits
  return Object.values(packagesByCredits).sort((a, b) => a.credits - b.credits);
}

// Thunk to initiate the checkout process for credit packages
export const purchaseCreditPackageThunk = createAsyncThunk<
  { checkoutUrl: string }, 
  PurchaseCreditPackageParams,
  { state: CommonRootState; rejectValue: string }
>(
  'credits/purchaseCreditPackage',
  async (params, thunkApi) => {
    try {
      const { packageId, quantity = 1 } = params;
      console.log(`Initiating checkout for package ID: ${packageId}, quantity: ${quantity}`);
      
      // Use the proper getCheckoutPageUrl endpoint
      const result = await thunkApi.dispatch(
        backendApiEndpoints.getCheckoutPageUrl.initiate({
          item_price_id: packageId,
          quantity: quantity
        } as GetCheckoutPageUrlParams)
      );
      
      // Handle the RTK Query result
      if (isErrorResult(result)) {
        console.error('Error from checkout endpoint:', result.error);
        const errorMessage = typeof result.error === 'object' && 'message' in result.error 
          ? result.error.message 
          : 'Failed to fetch checkout URL';
        return thunkApi.rejectWithValue(errorMessage as string);
      }
      
      if (!isDataResult(result) || !result.data) {
        return thunkApi.rejectWithValue('Invalid response from checkout URL endpoint');
      }
      
      const response = result.data;
      console.log('Checkout API response:', response);
      
      if (!response || !response.checkoutPageUrl) {
        console.error('No checkout URL in response:', response);
        return thunkApi.rejectWithValue('No checkout URL returned from server');
      }
      
      console.log('Successfully retrieved checkout URL:', response.checkoutPageUrl);
      return {
        checkoutUrl: response.checkoutPageUrl
      };
    } catch (error) {
      console.error('Error initiating credit package purchase:', error);
      return thunkApi.rejectWithValue(error.message || 'Failed to initiate purchase');
    }
  }
);

// Thunk to get available credit packages from the backend
export const fetchCreditPackagesThunk = createAsyncThunk<
  CreditPackage[],
  void,
  { state: CommonRootState; rejectValue: string }
>(
  'credits/fetchPackages',
  async (_, thunkApi) => {
    try {
      console.log('Fetching credit packages from Chargebee...');
      
      // Use a direct API call to the backend with the correct endpoint
      const result = await thunkApi.dispatch(
        backendApiEndpoints.createEntity.initiate({
          service: 'actions',
          entity: {
            cmd: 'chargebee.getAddOns'
          }
        })
      );
      
      // Handle the RTK Query result with proper type checking
      if (isErrorResult(result)) {
        console.error('Error from add-ons endpoint:', result.error);
        const errorMessage = typeof result.error === 'object' && 'message' in result.error 
          ? result.error.message 
          : 'Failed to fetch credit packages';
        return thunkApi.rejectWithValue(errorMessage as string);
      }
      
      if (!isDataResult(result) || !result.data) {
        return thunkApi.rejectWithValue('Invalid response from add-ons endpoint');
      }
      
      const response = result.data;
      console.log('Response from Chargebee add-ons API:', response);
      
      // Check if we have valid add-ons
      if (response && Array.isArray(response) && response.length > 0) {
        console.log(`Found ${response.length} credit packages:`, response);
        
        // Organize the add-ons into structured packages
        const organizedPackages = organizeAddons(response);
        console.log('Organized packages:', organizedPackages);
        
        if (organizedPackages.length > 0) {
          return organizedPackages;
        }
      }
      
      // Fallback to static packages if API call fails or returns empty
      console.warn('No add-ons found or invalid response format, using fallback data');
      const fallbackData: CreditPackage[] = [
        { 
          credits: 100, 
          monthly: { price: 7.99, perCredit: 0.08, id: 'small-monthly', billingCycle: 'monthly' },
          annual: { price: 81.50, perCredit: 0.068, id: 'small-annual', billingCycle: 'annual' }
        },
        { 
          credits: 200, 
          monthly: { price: 14.99, perCredit: 0.07, id: 'medium-monthly', billingCycle: 'monthly' },
          annual: { price: 152.90, perCredit: 0.064, id: 'medium-annual', billingCycle: 'annual' },
          popular: true 
        },
        { 
          credits: 500, 
          monthly: { price: 34.99, perCredit: 0.07, id: 'large-monthly', billingCycle: 'monthly' },
          annual: { price: 356.90, perCredit: 0.059, id: 'large-annual', billingCycle: 'annual' }
        },
        { 
          credits: 1000, 
          monthly: { price: 64.99, perCredit: 0.06, id: 'extra-monthly', billingCycle: 'monthly' },
          annual: { price: 662.90, perCredit: 0.055, id: 'extra-annual', billingCycle: 'annual' }
        }
      ];
      return fallbackData;
    } catch (error) {
      console.error('Error fetching credit packages:', error);
      
      // Return specific error message
      if (error.message) {
        return thunkApi.rejectWithValue(error.message);
      }
      
      // Return fallback data on error
      const fallbackData: CreditPackage[] = [
        { 
          credits: 100, 
          monthly: { price: 7.99, perCredit: 0.08, id: 'small-monthly', billingCycle: 'monthly' },
          annual: { price: 81.50, perCredit: 0.068, id: 'small-annual', billingCycle: 'annual' }
        },
        { 
          credits: 200, 
          monthly: { price: 14.99, perCredit: 0.07, id: 'medium-monthly', billingCycle: 'monthly' },
          annual: { price: 152.90, perCredit: 0.064, id: 'medium-annual', billingCycle: 'annual' },
          popular: true 
        },
        { 
          credits: 500, 
          monthly: { price: 34.99, perCredit: 0.07, id: 'large-monthly', billingCycle: 'monthly' },
          annual: { price: 356.90, perCredit: 0.059, id: 'large-annual', billingCycle: 'annual' }
        },
        { 
          credits: 1000, 
          monthly: { price: 64.99, perCredit: 0.06, id: 'extra-monthly', billingCycle: 'monthly' },
          annual: { price: 662.90, perCredit: 0.055, id: 'extra-annual', billingCycle: 'annual' }
        }
      ];
      return fallbackData;
    }
  }
); 