import { Chip } from '@mui/material';

const NODE_TYPE_CONFIG: Record<string, { color: string; bgColor: string }> = {
    'question': {
        color: '#2ecc71',
        bgColor: 'rgba(46, 204, 113, 0.1)'
    },
    'sendMessage': {
        color: '#1abc9c',
        bgColor: 'rgba(26, 188, 156, 0.1)'
    },
    'LLM': {
        color: '#1abc9c',
        bgColor: 'rgba(26, 188, 156, 0.1)'
    },
    'action': {
        color: '#e67e22',
        bgColor: 'rgba(230, 126, 34, 0.1)'
    },
    'condition': {
        color: '#8e44ad',
        bgColor: 'rgba(142, 68, 173, 0.1)'
    },
    'default': {
        color: '#7f8c8d',
        bgColor: 'rgba(127, 140, 141, 0.1)'
    }
};

interface NodeTypeChipProps {
    nodeType: string;
    size?: 'small' | 'medium';
}

export const NodeTypeChip = ({ nodeType, size = 'small' }: NodeTypeChipProps) => {
    const config = NODE_TYPE_CONFIG[nodeType.toLowerCase()] || NODE_TYPE_CONFIG.default;
    
    return (
        <Chip
            label={nodeType}
            size={size}
            sx={{
                backgroundColor: config.bgColor,
                color: config.color,
                fontWeight: 600,
                '& .MuiChip-label': {
                    px: 2
                }
            }}
        />
    );
}; 