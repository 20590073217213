import BTButton from "@common-components/bt-button/bt-button";
import { FormHelperText, Stack, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { mainSliceActions } from "@common-reducers/MainReducer";
import { clearAuthError, setSignupState } from "@common-reducers/UserReducer";
import { useAuthenticateMutation, useRegisterMutation } from "@common-reducers/backend-api/backend-api";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { useRouter } from "@common-reducers/hooks/use-router";
import { AnalyticsService } from "@common-services/analytics-service";
import { isExtensionContext } from "@common-services/detect-context";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from "@common-config/constants";
import * as EmailValidator from "email-validator";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "@common/theme";
import accessFormclasses from "../access-form.module.scss";
import ContinueWithDevider from "../continue-with-devider";
import FormHeader from "../form-header/header";
import Input from "../form-input/form-input";
import FormPatial from "../form-patial";
import GoogleLoginWrapper from "../google-auth-button/google-login-wrapper";
import classes from "./signup-form.module.scss";
import SignupStatus from "./signup-status";




export const SignupForm = () => {
  const { t } = useTranslation(namespace);
  const analyticsService: AnalyticsService = useAnalyticsService();
  const [authenticate] = useAuthenticateMutation();

  const dispatch = useAppDispatch()
  const authError = useAppSelector((state) => state.UserReducer.authError);
  const NewSignup = useAppSelector((state) => state.UserReducer.signup);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [emailError, setEmailError] = useState('');

  const [register, { isLoading }] = useRegisterMutation();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { email, password, firstName, lastName } = NewSignup
  const impersonate = new URLSearchParams(window.location.search).get('impersonate');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSignupState({
      ...NewSignup,
      [event.target.name]: event.target.value
    }))
    dispatch(clearAuthError());
  };


  const onAcceptTermsChange = () => {
    setAcceptedTerms(!acceptedTerms);
  };


  const signup = async () => {
    if (!(email && password)) {
      setShowValidation(true);
      setEmailError('Email is required');
      return;
    }

    if (!EmailValidator.validate(email)) {
      setShowValidation(true);
      setEmailError('Invalid email address');
      return;
    }

    const registerResponse = await register({
      email: email.toLowerCase(),
      password,
      fullName: `${firstName} ${lastName}`,
      firstName,
      lastName
    });

    if ('error' in registerResponse) {
      console.log(registerResponse)
      return;
    }

    if (registerResponse?.data?.email) {

      analyticsService.event("register_new_user", {
        category: "access",
        action: "register new user",
        email: email,
      });

      if (!isExtensionContext()) {
        const query = new URLSearchParams(window.location.search);
        const redirect = query.get('redirect');

        if (redirect) {
          router.navigate(redirect);
        } else {
          router.navigate('/calendar');
        }
      }
    };

  }



  function handleFormState(target: string) {
    if (target === '') {

      analyticsService.event("switch_to_log_in", {
        category: "access",
        action: "switch to log in",
      });


    }
    dispatch(mainSliceActions.setFormState(target));
    if (!isExtensionContext()) {
      router.navigate('/login');
    }
  }

  const errorFormColor = '#f72371';

  useEffect(() => {
    document.title = t('signupForm.title');
    let userEmail = new URLSearchParams(window.location.search).get('email');
    if (userEmail) {
      userEmail = userEmail.replace(/ /g, '+');
      dispatch(setSignupState({
        email: userEmail,
        password: '',
        firstName: '',
        lastName: ''
      }))
    }

  }, []);

  useEffect(() => {
    if (authError === "email: value already exists.") {
      authenticate({ strategy: "local", email, password, impersonate }).unwrap();
    }
  }, [authError])

  return (
    <FormPatial imagePath="/img/signup-illustrator.png">
      <Box sx={{
        width: "100%",
        maxWidth: "400px"
      }} className={accessFormclasses.FormContainer}>
        <Box sx={{ margin: '0 0 5px 0' }}>
          <FormHeader screenTitle={t('signupForm.formTitle')} />
        </Box>
        <form className={classes.loginForm}
          onKeyDown={(e) => e.key === "Enter" && signup()}>
          <Stack>
            {/* <Box className={classes.GroupedInputs}>
                <Input
                  onChange={handleOnChange}
                  name="firstName"
                  type="text"
                  style={{
                    width: 120,
                    borderColor: showValidation && !firstName ?
                      errorFormColor : '', marginBottom: 5
                  }}
                  label={t('signupForm.firstNameLabel')}
                  errorMessage={showValidation && !firstName ? t('signupForm.firstNameError') : ''}
                  placeholder={t('signupForm.firstNamePlaceholder')} />

                <Input
                  onChange={handleOnChange}
                  name="lastName"
                  type="text"
                  style={{
                    width: 120, borderColor: showValidation &&
                      !lastName ? errorFormColor : '', marginBottom: 5
                  }}
                  label={t('signupForm.lastNameLabel')}
                  errorMessage={showValidation && !lastName ? t('signupForm.lastNameError') : ''}
                  placeholder={t('signupForm.lastNamePlaceholder')} />
              </Box> */}

            <Input
              onChange={handleOnChange}
              name="email"
              type="text"
              value={email}
              style={{
                borderColor: showValidation &&
                  !email ? errorFormColor : '', marginBottom: 5
              }}
              label={t('signupForm.emailLabel')}
              errorMessage={showValidation && !email ? t('signupForm.emailError') : ''}
              placeholder={t('signupForm.emailPlaceholder')} />
            <Input
              onChange={handleOnChange}
              name="password"
              style={{
                borderColor: showValidation &&
                  !password ? errorFormColor : ''
              }}
              type="password"
              label={t('signupForm.passwordLabel')}
              errorMessage={showValidation && !password ? t('signupForm.passwordError') : ''}
              placeholder={t('signupForm.passwordPlaceholder')}
            />
          </Stack>


          {authError ? (
            <FormHelperText error={true}>{authError}</FormHelperText>
          ) : null}

          <Box className={classes.FormFooter}>
            {/* <Box className={classes.termsContainer}
              style={{
                margin: !isInPopup() ? isMobile ? 0 : "16px 0 16px -9px" : "0 0 0 -9px",visibility:"hidden",
              }}
              onClick={onAcceptTermsChange}>
              <BTCheckbox color="primary" checked={acceptedTerms} />
              <span className={classes.FormLabelText}>
                  {t('signupForm.termsAndConditions')}
                <Link
                  component="a"
                  target="_blank"
                  href="https://blueticks.co/terms"
                  onClick={() => {
                    analyticsService.event("switch_to_terms_conditions", {
                      category: "access",
                      action: "switch to terms conditions",
                    });
                  }}
                >
                    <span className={classes.ButtonLink}>{t('signupForm.termsLink')}</span>
                </Link>
              </span>
            </Box> */}

            <BTButton

              loading={isLoading}
              sx={{ width: 1, padding: '15px 0', transition: '0.3s all' }}
              onClick={signup}
            >
              <SignupStatus isLoading={isLoading} />
            </BTButton>


            <ContinueWithDevider label={t('signupForm.continueWith')} />
            <Stack className={classes.provider_wrapper} direction="row" justifyContent="center">
              <GoogleLoginWrapper />
            </Stack>
            <Box
              className={classes.FormLabelText}
              style={{ margin: '16px 0 0 0', cursor: "pointer" }}
            >

              {t('signupForm.existingAccount')} <span
                className={classes.ButtonLink}
                onClick={() => handleFormState("")}>
                {t('signupForm.loginLink')}
              </span>
            </Box>
          </Box>
        </form>
      </Box>
    </FormPatial>
  );
};




