import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

interface RoomJoinEvent {
  room: string;
  socketId: string;
  timestamp: Date;
}

export interface NotifierState {
  isConnected: boolean;
}

const initialState: NotifierState = {
  isConnected: false,
};

const NotifierSlice = createSlice({
  name: "notifier",
  initialState,
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
  },
});

export const { setConnectionStatus } = NotifierSlice.actions;

export const NotifierActions = NotifierSlice.actions;

export default NotifierSlice.reducer;
