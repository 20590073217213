import { useState, useCallback, useEffect, useRef } from 'react';
import { appConfig } from '@/common/config/app.config';
import { openOAuthPopup, createOAuthMessageHandler, refreshOAuthToken } from '../utils/oauth-popup';

export interface OAuthConfig {
  // The tool name (e.g., 'gmail', 'drive', 'linkedin')
  toolName: string;
  
  // The OAuth endpoint path (e.g., '/auth/gmail')
  authEndpoint: string;
  
  // The refresh endpoint path (e.g., '/auth/gmail/refresh')
  refreshEndpoint: string;
  
  // The success message type (e.g., 'gmail_auth_success')
  successMessageType: string;
  
  // The error message type (e.g., 'gmail_auth_error')
  errorMessageType: string;
  
  // Additional data to send with the auth request
  additionalAuthData?: Record<string, any>;
}

export interface OAuthState {
  // Whether the user is connected to the service
  isConnected: boolean;
  
  // Whether the token is expired
  isExpired: boolean;
  
  // Whether the connection is in progress
  isConnecting: boolean;
  
  // The user's email address
  email: string | null;
  
  // Any error message
  error: string | null;
}

export interface OAuthTokens {
  accessToken?: string;
  refreshToken?: string;
  tokenExpiry?: number;
  email?: string;
  [key: string]: any;
}

export interface UseOAuthResult {
  // The current state of the OAuth connection
  state: OAuthState;
  
  // Connect to the service
  connect: (additionalData?: Record<string, any>) => void;
  
  // Disconnect from the service
  disconnect: () => void;
  
  // Refresh the token
  refreshToken: () => Promise<boolean>;
  
  // The current tokens
  tokens: OAuthTokens;
  
  // Update the tokens
  updateTokens: (newTokens: OAuthTokens) => void;
}

/**
 * A hook for managing OAuth authentication
 * @param config The OAuth configuration
 * @param initialTokens The initial tokens
 * @param onTokensChanged Callback for when tokens change
 * @returns The OAuth state and methods
 */
export function useOAuth(
  config: OAuthConfig,
  initialTokens: OAuthTokens = {},
  onTokensChanged?: (tokens: OAuthTokens) => void
): UseOAuthResult {
  // Use refs to prevent unnecessary re-renders
  const configRef = useRef(config);
  const tokensRef = useRef<OAuthTokens>(initialTokens);
  const onTokensChangedRef = useRef(onTokensChanged);
  const popupRef = useRef<{ close: () => void } | null>(null);
  
  // Update refs when props change
  useEffect(() => {
    configRef.current = config;
  }, [config]);
  
  useEffect(() => {
    onTokensChangedRef.current = onTokensChanged;
  }, [onTokensChanged]);
  
  // State for tokens and connection state
  const [tokens, setTokens] = useState<OAuthTokens>(initialTokens);
  const [state, setState] = useState<OAuthState>(() => ({
    isConnected: !!(initialTokens.accessToken && initialTokens.email),
    isExpired: !!(initialTokens.tokenExpiry && new Date(initialTokens.tokenExpiry).getTime() < Date.now()),
    isConnecting: false,
    email: initialTokens.email || null,
    error: null
  }));
  
  // Update tokens ref when tokens change
  useEffect(() => {
    tokensRef.current = tokens;
  }, [tokens]);
  
  // Update the state when tokens change, but only when necessary
  useEffect(() => {
    const isConnected = !!(tokens.accessToken && tokens.email);
    const isExpired = !!(tokens.tokenExpiry && new Date(tokens.tokenExpiry).getTime() < Date.now());
    const email = tokens.email || null;
    
    // Only update state if something has actually changed
    setState(prev => {
      if (
        prev.isConnected !== isConnected ||
        prev.isExpired !== isExpired ||
        prev.email !== email
      ) {
        return {
          ...prev,
          isConnected,
          isExpired,
          email
        };
      }
      return prev;
    });
  }, [tokens]);
  
  // Call the onTokensChanged callback when tokens change, but only once per change
  useEffect(() => {
    if (onTokensChangedRef.current) {
      onTokensChangedRef.current(tokens);
    }
  }, [tokens]);
  
  // Cleanup function for when the component unmounts
  useEffect(() => {
    return () => {
      // Close any open popup when the component unmounts
      if (popupRef.current) {
        popupRef.current.close();
        popupRef.current = null;
      }
    };
  }, []);
  
  // Connect to the service
  const connect = useCallback((additionalData: Record<string, any> = {}) => {
    console.log('Initiating OAuth connection', { 
      toolName: configRef.current.toolName,
      additionalData
    });
    
    // Close any existing popup
    if (popupRef.current) {
      popupRef.current.close();
      popupRef.current = null;
    }
    
    setState(prev => {
      // Only update if not already connecting
      if (!prev.isConnecting) {
        return { ...prev, isConnecting: true, error: null };
      }
      return prev;
    });
    
    try {
      const authUrl = `${appConfig.API_ENDPOINT}${configRef.current.authEndpoint}`;
      const queryParams = new URLSearchParams({
        ...configRef.current.additionalAuthData,
        ...additionalData
      });
      
      const fullUrl = `${authUrl}?${queryParams.toString()}`;
      console.log('Opening OAuth popup with URL', { 
        url: fullUrl,
        toolName: configRef.current.toolName
      });
      
      const messageHandler = createOAuthMessageHandler(
        configRef.current.successMessageType,
        configRef.current.errorMessageType,
        (data) => {
          // Handle successful authentication
          console.log('OAuth success callback received', {
            hasAccessToken: !!data.accessToken,
            hasEmail: !!data.email,
            hasRefreshToken: !!data.refreshToken,
            hasTokenExpiry: !!data.tokenExpiry
          });
          
          const newTokens = {
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            tokenExpiry: data.tokenExpiry,
            email: data.email,
            ...data
          };
          
          // Calculate token expiry if not provided
          if (!newTokens.tokenExpiry && data.expiresIn) {
            newTokens.tokenExpiry = Date.now() + (data.expiresIn * 1000);
          }
          
          setTokens(newTokens);
          setState(prev => ({ 
            ...prev, 
            isConnecting: false, 
            isConnected: true,
            isExpired: false,
            email: data.email,
            error: null
          }));
          
          // Clear the popup reference
          popupRef.current = null;
        },
        (error) => {
          // Handle authentication error
          console.error('OAuth error callback received', { error });
          
          setState(prev => ({ 
            ...prev, 
            isConnecting: false, 
            error 
          }));
          
          // Clear the popup reference
          popupRef.current = null;
        }
      );
      
      // Store the current connecting state to avoid closure issues
      const isConnectingRef = { current: true };
      
      const popup = openOAuthPopup({
        url: fullUrl,
        title: `Connect ${configRef.current.toolName}`,
        onMessage: messageHandler,
        onClose: () => {
          // Only update state if we're still connecting
          if (isConnectingRef.current) {
            console.log('OAuth popup closed while connecting');
            setState(prev => {
              if (prev.isConnecting) {
                return { 
                  ...prev, 
                  isConnecting: false, 
                  error: 'Authentication canceled' 
                };
              }
              return prev;
            });
            isConnectingRef.current = false;
          }
          
          // Clear the popup reference
          popupRef.current = null;
        }
      });
      
      // Store the popup reference
      popupRef.current = popup;
      
      // Return the popup controller
      return popup;
    } catch (error) {
      console.error('Error opening OAuth popup', {
        error: error instanceof Error ? error.message : String(error)
      });
      
      setState(prev => ({ 
        ...prev, 
        isConnecting: false, 
        error: error instanceof Error ? error.message : String(error) 
      }));
      
      // Clear the popup reference
      popupRef.current = null;
      
      return null;
    }
  }, []);
  
  // Disconnect from the service
  const disconnect = useCallback(() => {
    console.log('Disconnecting from OAuth service', {
      toolName: configRef.current.toolName
    });
    
    setTokens({});
    setState(prev => ({
      ...prev,
      isConnected: false,
      isExpired: false,
      isConnecting: false,
      email: null,
      error: null
    }));
  }, []);
  
  // Refresh the token
  const refreshTokenFn = useCallback(async (): Promise<boolean> => {
    const currentTokens = tokensRef.current;
    
    if (!currentTokens.refreshToken) {
      console.error('Cannot refresh token: No refresh token available');
      setState(prev => ({ 
        ...prev, 
        error: 'No refresh token available' 
      }));
      return false;
    }
    
    console.log('Refreshing OAuth token', {
      toolName: configRef.current.toolName,
      endpoint: configRef.current.refreshEndpoint
    });
    
    setState(prev => {
      // Only update if not already connecting
      if (!prev.isConnecting) {
        return { ...prev, isConnecting: true, error: null };
      }
      return prev;
    });
    
    try {
      const data = await refreshOAuthToken(
        configRef.current.refreshEndpoint,
        currentTokens.refreshToken,
        configRef.current.additionalAuthData
      );
      
      console.log('Token refresh successful', {
        hasAccessToken: !!data.accessToken,
        hasRefreshToken: !!data.refreshToken,
        expiresIn: data.expiresIn
      });
      
      const newTokens = {
        ...currentTokens,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken || currentTokens.refreshToken,
        tokenExpiry: data.tokenExpiry || (data.expiresIn ? Date.now() + (data.expiresIn * 1000) : undefined)
      };
      
      setTokens(newTokens);
      setState(prev => ({ 
        ...prev, 
        isConnecting: false, 
        isConnected: true,
        isExpired: false,
        error: null
      }));
      
      return true;
    } catch (error) {
      console.error('Error refreshing token', {
        error: error instanceof Error ? error.message : String(error)
      });
      
      setState(prev => ({ 
        ...prev, 
        isConnecting: false, 
        error: error instanceof Error ? error.message : String(error) 
      }));
      return false;
    }
  }, []);
  
  // Update the tokens
  const updateTokens = useCallback((newTokens: OAuthTokens) => {
    console.log('Updating OAuth tokens', {
      toolName: configRef.current.toolName,
      hasAccessToken: !!newTokens.accessToken,
      hasRefreshToken: !!newTokens.refreshToken,
      hasEmail: !!newTokens.email
    });
    
    setTokens(prev => {
      // Only update if something has changed
      const hasChanged = Object.keys(newTokens).some(key => prev[key] !== newTokens[key]);
      if (hasChanged) {
        return { ...prev, ...newTokens };
      }
      return prev;
    });
  }, []);
  
  return {
    state,
    connect,
    disconnect,
    refreshToken: refreshTokenFn,
    tokens,
    updateTokens
  };
} 