import React from 'react';
import AppView from '@common/components/app-view/app-view';
import CreditsUsageComponent from '@common/components/credits-usage/credits-usage';
import { useTranslation } from 'react-i18next';
import { namespace } from '@common-config/constants';
import { AccountPage } from '@/common/components/account-page/account-page';

const CreditsUsageView = () => {
  const { t } = useTranslation(namespace);

  // Create the page content with the title
  const pageContent = (
    <div className="p-0">
      <h1 className="text-xl font-semibold text-gray-900 px-4 pt-4 mb-2">{t('credits.title', 'Credits & Usage')}</h1>
      <CreditsUsageComponent />
    </div>
  );

  return (
    <AccountPage page={pageContent} />
  );
};

export default CreditsUsageView; 