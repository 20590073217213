import { NodeType } from '@common-models/bot-node';
import { duplicateNodeThunk, handleNodeDeletionThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { ContentCopy as CopyIcon, DeleteOutline as DeleteIcon, EditOutlined as EditIcon } from '@mui/icons-material';
import { Menu, MenuItem, Modal } from '@mui/material';
import { useState } from 'react';
import { useReactFlow } from 'reactflow';
import { nodeTypes } from '../bt-nodes/bt-nodes-def';
import BTChatbotModal from './bt-chatbot-modal';

interface Props {
    type: NodeType;
    open: boolean;
    onClose: () => void;
    anchorEl: null | HTMLElement;
    id: string;
}

export default function NodeMenu({ type, open, onClose, anchorEl, id }: Props) {
    const dispatch = useAppDispatch();
    const { setNodes, setEdges } = useReactFlow();

    const [modalOpen, setModalOpen] = useState(false);

    const modalType = () => {
        switch (type) {
            case 'condition':
                return 'setCondition';
            case 'question':
                return 'setQuestion';
            case 'webhook':
                return 'setWebhook';
            case 'googleSheets':
                return 'setGoogleSheet';
            case 'trigger':
                return 'setTrigger';
            case 'agent':
                return 'setAgent';
            case 'LLM':
                return 'setLLM';
            default:
                return 'setSettings';
        }
    };

    const duplicateNode = async () => {
        const resultAction = await dispatch(duplicateNodeThunk({ id, type }));
        if (duplicateNodeThunk.fulfilled.match(resultAction)) {
            setNodes((nodes) => [...nodes, resultAction.payload]);
        }
        onClose();
    };

    const deleteNode = () => {
        dispatch(handleNodeDeletionThunk({ deletedNodeId: id, setNodes, setEdges }));
        onClose();
    };

    const handleOpenModal = () => {
        onClose();
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    transform: 'translate(12px,-20px)',
                    '& .MuiList-root': {
                        padding: 0,
                    },
                    '& .MuiPaper-root': {
                        width: '150px',
                        padding: '0px 15px',
                        backgroundColor: 'white',
                        boxShadow: 'rgba(130, 130, 130, 0.15) 12px 10px 45px -12px',
                        color: '#212121;',
                    },
                    '& .MuiMenuItem-root': {
                        padding: '10px 0px',
                        borderBottom: '1px solid rgb(232, 234, 242)',
                        fontWeight: 600,
                        fontSize: '12px',
                        '&:last-child': {
                            borderBottom: 'none',
                        },
                        '&:hover': {
                            color: '#23a455',
                            backgroundColor: 'transparent',
                        },
                    },
                }}
            >
                {nodeTypes?.[type]?.isEditable && (
                    <MenuItem onClick={handleOpenModal} disableRipple>
                        <EditIcon style={{ marginRight: '10px' }} />Edit
                    </MenuItem>
                )}
                <MenuItem onClick={deleteNode} disableRipple>
                    <DeleteIcon style={{ marginRight: '10px' }} />Delete
                </MenuItem>
                <MenuItem onClick={duplicateNode} disableRipple>
                    <CopyIcon style={{ marginRight: '10px' }} />Duplicate
                </MenuItem>
            </Menu>
            <Modal
                id={modalOpen ? 'simple-popover' : undefined}
                open={modalOpen}
                onClose={handleCloseModal}
                sx={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <BTChatbotModal type={modalType()} onClose={handleCloseModal} id={id} />
            </Modal>
        </>
    );
}