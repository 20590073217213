import { ChangeEvent, useEffect } from 'react';

import { AccountPage } from "@common-components/account-page/account-page";
import BTButton from "@common-components/bt-button/bt-button";
import BTtextFieldWithLabel from "@common-components/bt-input-field-with-label/bt-input-field-with-label";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { Alert, Snackbar } from '@mui/material';
import { useChangePasswordMutation } from "@common-reducers/backend-api/backend-api";
import { useCallback, useState } from "react";
import { SideBarItem } from "@common-components/sidebar/sidebar";
import { User } from "@common/models/user";
import classes from "../account-view/account-view.module.scss";
import { Stack } from '@mui/system';
import { namespace } from '@common/config/constants';
import { useTranslation } from 'react-i18next';
export interface AccountSecurityViewProps { }

export interface AccountSecurityViewState {
  user?: User | null | undefined;

}

const AccountSecurityView = () => {
  const { t } = useTranslation(namespace);
  // const user = useSelector(selectUserObject);
  const [inputValues, setInputValues] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordVerify: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [snackBarSeverity, setSnackBarSeverity] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);


  const [changePassword] = useChangePasswordMutation();

  const handleCloseSnakbar = () => {
    setOpenSnackbar(false);
  }
  const changePasswordClick = useCallback(async (fields) => {
    setIsLoading(true);
    if (fields.newPassword === fields.newPasswordVerify) {
      if (fields.newPassword.length < 6) {
        setSnackbarMessage(t('accountSecurityView.passwordLengthError'))
        setSnackBarSeverity("error");
        setOpenSnackbar(true);
        setIsLoading(false);
      } else {
        const res: any = await changePassword({
          oldPassword: fields.oldPassword,
          newPassword: fields.newPassword
        })
        if (res?.data?.error) {
          setSnackBarSeverity("error");
          setSnackbarMessage(res?.data?.error)
          setOpenSnackbar(true);

          setIsLoading(false);
        } else {
          setIsLoading(false);
          setSnackbarMessage(t('accountSecurityView.passwordChangeSuccess'))
          setSnackBarSeverity("success");
          setOpenSnackbar(true);
          setIsLoading(false);
          setInputValues({
            oldPassword: "",
            newPassword: "",
            newPasswordVerify: "",
          })
        }
      }

    } else if (fields.newPassword !== fields.newPasswordVerify) {
      setSnackBarSeverity("error");
      setSnackbarMessage(t('accountSecurityView.passwordMismatchError'))
      setOpenSnackbar(true);
      setIsLoading(false);
    }
  }, [t]);

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  }, [inputValues]);

  useEffect(() => {
    document.title = t('accountSecurityView.pageTitle');
  }, [t]);

  const accountSecurityView = () => {
    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>{t('accountSecurityView.pageTitle')}</h4>
        </div>
        <div className={classes.accountSettingBody}>
          <Stack spacing={2} className={classes.inputsContainer}>
            <BTtextFieldWithLabel
              label={t('accountSecurityView.currentPasswordLabel')}
              placeholder={t('accountSecurityView.currentPasswordPlaceholder')}
              name="oldPassword"
              type="password"
              value={inputValues.oldPassword}
              onChange={handleChangeInput}
              disabled={isLoading}
              sx={{
                backgroundColor: 'var(--background-default)',
              }}
            />
            <BTtextFieldWithLabel
              label={t('accountSecurityView.changePasswordLabel')}
              placeholder={t('accountSecurityView.newPasswordPlaceholder')}
              name="newPassword"
              type="password"
              value={inputValues.newPassword}
              onChange={handleChangeInput}
              disabled={isLoading}
              sx={{
                backgroundColor: 'var(--background-default)',
              }}
            />{" "}
            <BTtextFieldWithLabel
              label={t('accountSecurityView.verifyPasswordLabel')}
              placeholder={t('accountSecurityView.newPasswordAgainPlaceholder')}
              name="newPasswordVerify"
              type="password"
              value={inputValues.newPasswordVerify}
              onChange={handleChangeInput}
              disabled={isLoading}
              sx={{
                backgroundColor: 'var(--background-default)',
              }}
            />
            <BTButton loading={isLoading}
              sx={{ maxWidth: '200px', alignSelf: "end" }} onClick={() => { changePasswordClick(inputValues) }} >
              {t('accountSecurityView.changePasswordButton')}
            </BTButton>

          </Stack>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnakbar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert onClose={handleCloseSnakbar} severity={snackBarSeverity} variant="filled" sx={{
            "& .MuiButtonBase-root": {
              color: '#fff;'
            }
          }}>
           {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  };

  return (
    <BTThemeProvider>
      <AccountPage
        page={accountSecurityView()}
      ></AccountPage>
    </BTThemeProvider>
  );
};

export default AccountSecurityView;
