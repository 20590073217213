import BulkTemplateDialog from '@extension/context/content/components/bt-bulk-dashboard/bulk-template-dialog'
import BTThemeProvider from '@common-components/bt-theme-provider'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { closeBulkDashboard, setActiveBulkView, setActiveTab } from '@common-reducers/CampaignReducer'
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import { useEffect } from 'react'
import BulkAudiencesList from './audiences/bulk-audiences-list'
import BtBulkMangementTabs from './bt-bulk-management-tabs'
import BulkCampaignDialog from './bulk-campaign-dialog'
import CampaignTabHeader from './campaign-tab-header'
import { isExtensionContext } from '@common/services/detect-context'

export default function BTBulkManagement() {
    const dispatch = useAppDispatch()
    const activeTab = useAppSelector((state) => state.CampaignReducer.activeTab);

    const dispatchCloseBulkDashboard = () => {
        dispatch(closeBulkDashboard());
        // dispatch(setActiveBulkView(""))
        // window.location.href = "/"
    }

    useEffect(() => {
        if (activeTab === "audiences"
            || activeTab === "timeAndDate"
            || activeTab === "messageAndFiles"
            || activeTab === "finish"

        ) {
            dispatch(setActiveTab("manageAudiences"))
        }
    }, [])

    return (
        <BTThemeProvider>
            <Stack
                sx={{
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    direction: 'ltr',
                }}>
                {isExtensionContext() &&
                    <Box style={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <IconButton onClick={() => { dispatchCloseBulkDashboard() }}>
                            <Close sx={{ color: 'var(--primary)' }} />
                    </IconButton>
                    </Box>
                }
                <Box sx={{
                    alignItems: 'center',
                    paddingTop: '20px',
                }}>
                    <BtBulkMangementTabs />

                    {activeTab === 'manageAudiences' &&
                        <BulkAudiencesList />
                    }

                    {activeTab === 'manageTemplates' &&
                        <>
                            <CampaignTabHeader title={'Manage Templates'} subTitle='Create, Edit, duplicate, and delete templates' />
                            <BulkTemplateDialog />
                        </>
                    }
                    {activeTab === 'manageCampaigns' &&
                        <>
                            <CampaignTabHeader title={'Manage Campaigns'} subTitle='Create, pause, resume, abort, and view your campaign logs.' />
                            <BulkCampaignDialog />
                        </>
                    }
                </Box>
            </Stack>
        </BTThemeProvider >
    )
}