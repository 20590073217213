import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { View } from "react-big-calendar";


export interface CalendarDashboardState {
    contactPicMap?: Record<string, string>, /// [waId]:profilePicURL
    selectedEventDate?: string,  // Store the original date of selected event
    calendarMode: 'calendar' | 'agenda' | null;
    currentDate: Date;
    view: View;
    calendarSidebarFilters: {
        showMessages: boolean;
        showCampaigns: boolean;
    }
}

const initialState: CalendarDashboardState = {
    contactPicMap: {},
    selectedEventDate: null,
    calendarMode: 'calendar',
    currentDate: moment().toDate(),
    view: 'month',
    calendarSidebarFilters: {
        showMessages: true,
        showCampaigns: true,
    }
};

const CalendarSlice = createSlice({
    name: "Calendars",
    initialState,
    reducers: {
        setContactPicMap: (state, { payload }: PayloadAction<[string, string]>) => {
            state.contactPicMap = { ...state.contactPicMap, [payload[0]]: payload[1] }
        },
        setSelectedEventDate: (state, { payload }: PayloadAction<string>) => {
            state.selectedEventDate = payload;
        },
        setCalendarMode: (state, { payload }: PayloadAction<'calendar' | 'agenda' | null>) => {
            state.calendarMode = payload;
        },
        setCurrentDate: (state, { payload }: PayloadAction<Date>) => {
            state.currentDate = payload;
        },
        setView: (state, { payload }: PayloadAction<View>) => {
            state.view = payload;
        },
        setCalendarSidebarFilters: (state, { payload }: PayloadAction<{ showMessages: boolean, showCampaigns: boolean }>) => {
            state.calendarSidebarFilters = payload;
        }
    },
});

export const {
    setContactPicMap,
    setSelectedEventDate,
    setCalendarMode,
    setCurrentDate,
    setView,
    setCalendarSidebarFilters
} = CalendarSlice.actions;
export default CalendarSlice.reducer;


