import MessageEditor from '@common-components/message-editor/message-editor';
import { Template } from '@common-models/template';
import { Close, KeyboardArrowLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { closeBulkDashboard, setCurrentTemplate } from '@common-reducers/CampaignReducer';
import { handleActiveBulkViewNavigationThunk } from '@common-reducers/CampaignThunks';
import { DBTemplateThunks } from "@common-reducers/DBServiceThunks";
import { useAnalyticsService } from '@common-reducers/hooks/use-analytics-service';
import { AnalyticsService } from '@common-services/analytics-service';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useCallback } from 'react';
import BulkButton from './bulk-button';

export default function NewTemplateView() {
    const dispatch = useAppDispatch()
    const analyticsService: AnalyticsService = useAnalyticsService();

    const currentTemplate = useAppSelector((state) => state.CampaignReducer.currentTemplate)
    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);


    const isFromWhatsapp = activeBulkView === 'campaignNewTemplateViewFromWhatsappView'

    const dispatchCloseBulkDashboard = () => {
        dispatch(closeBulkDashboard());
    }

    const newTemplate = async (newTemplate) => {

        analyticsService.event("bulk_new_template", {
            category: "bulk",
            action: "bulk_new_template",
        });

        const res = await dispatch(DBTemplateThunks.create(newTemplate));
        dispatch(setCurrentTemplate(res.payload as Template));
    }

    const saveMessageTemplate = useCallback((template: Partial<Template>) => {
        if (template._id) {
            dispatch(
                DBTemplateThunks.patch({
                    entity: template
                })
            );
        }
    }, [dispatch]);



    const handleClickBakToTemplates = () => {

        dispatch(handleActiveBulkViewNavigationThunk({ navigate: "fromNewTemplate" }))
    }
    return (
        <Stack sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }}>
            <Box sx={{
                width: '800px',
                marginTop: '15px',
            }}>
                {isFromWhatsapp &&
                    <Box style={{ position: 'absolute', top: 5, right: 5 }}>  {/*  close button */}
                        <IconButton onClick={dispatchCloseBulkDashboard}>
                            <Close sx={{ color: 'var(--primary)', fontSize: '24px !important', }} />
                        </IconButton>
                    </Box>
                }
                {!isFromWhatsapp &&
                    <BulkButton
                        onClick={handleClickBakToTemplates}
                        buttonString="Back"
                        Icon={KeyboardArrowLeft}
                    />
                }
                <Box sx={{ height: '90%', width: '100%', maxWidth: '800px' }}>
                    <MessageEditor
                        showTemplateButtons
                        value={currentTemplate}
                        onChange={(template: Template) => {
                            saveMessageTemplate(template);
                        }}
                        onSaveNew={newTemplate}
                        isPreview={false}
                        isDisplayRemoveAttachmentButttom={true}
                        editorContext='scheduleCampaign'
                    />
                </Box>
            </Box>
        </Stack>
    )
}