import { BulkView, setActiveBulkView } from "@common-reducers/CampaignReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useEffect } from "react";
import BTBulkDashboard from "./bt-bulk-dashboard";
import BTBulkManagement from "./bt-bulk-management";
import BulkLogsView from "./bulk-logs-view/bulk-logs-view";
import BulkTaskViewContainer from "./bulk-task-view-container";
import NewTemplateView from "./new-template-view";

export interface CampaignsViewProps {
    isWebsite?: boolean;
}

export default function CampaignsView({ isWebsite = false }: CampaignsViewProps) {
    const dispatch = useAppDispatch()
    const location = window.location.href

    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);




    useEffect(() => {
        if (location.includes('supportedUserId')) {
            dispatch(setActiveBulkView('campaignManagementView' as BulkView))
        }
    }, [location]);



    // create a boolean flag that is true only if user.bulkVersion doesnt exist or  if user.bulkVersion exists and is not "migrated"
    // const isEnterMigrationEvent = !user?.bulkVersion || (user?.bulkVersion && user?.bulkVersion !== "migrated")

    return (
        <div style={{
            height: '100vh',
            direction: 'ltr',
            backgroundColor: 'var(--background-default)',
        }}>

            {
                activeBulkView === "newCampaignView" &&
                <BTBulkDashboard />
            }

            {
                activeBulkView === "campaignManagementView" &&
                <BTBulkManagement />
            }

            {
                (activeBulkView === "campaignBoardFromManagementView"
                    || activeBulkView === "campaignBoardFromNewCampaignView") &&
                <BulkTaskViewContainer />
            }

            {
                (activeBulkView === "campaignNewTemplateViewFromManagement"
                    || activeBulkView === "campaignNewTemplateViewFromNewCampaignView"
                    || activeBulkView === 'campaignNewTemplateViewFromWhatsappView') &&
                <NewTemplateView />
            }

            {
                (activeBulkView === "campaignLogsView") &&
                <BulkLogsView />
            }


        </div>
    )
}