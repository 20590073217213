import { Subscription } from "@common-models/subscription";
import { UserInterface } from "@common-models/user";
import {
  DBBoardsThunks,
  DBSessionThunks,
  DBSubscriptionThunks,
  DBUserThunks,
  UserReducerActions,
  WaContactsCacheReducerActions
} from "@common-reducers/DBServiceThunks";
import { MODAL, modalActions } from "@common-reducers/ModalReducer";
import { initializeNotifierConnectionThunk } from "@common-reducers/NotifierThunks";
import {
  setAuthenticating,
  userSlice,
} from "@common-reducers/UserReducer";
import {
  clearStateThunk,
  loadUserMessagesAndCampaignsThunk,
  updateUserPhoneNumberThunk,
  validateSubscriptionsThunk,
} from "@common-reducers/UserThunks";
import { getWorkspaceDataThunk } from "@common-reducers/WorkspaceThunks";
import { loadLabelsAndContactLabelsThunk } from "@common-reducers/labels-reducer/labels-thunks/crud";
import { isExtensionContextOrWhatsappView, isWebPageButNotWhatsappPage } from "@common-services/detect-context";
import { setUserAuthenticated } from '../ConnectionReadinessSlice';
import { tryInitializeConnection } from '../NotifierThunks';

export const authenticateOnQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const result = await queryFulfilled;
  const user: UserInterface = result.data.user;
  if (window?.clarity) {
    window.clarity("identify", user.email);
  }

  dispatch(clearStateThunk());
  dispatch(
    UserReducerActions.upsertOne({
      ...result.data.user,
      impersonating: result.data?.authentication?.payload?.impersonating,
    })
  );
  document.querySelector("body").className = result.data.user.isDarkMode ? "dark" : "";

  dispatch(
    userSlice.actions.setUserDirection(
      result.data.user.direction !== undefined
        ? result.data.user.direction
        : "ltr"
    )
  );

  if (!window.location.href.includes("web.whatsapp.com")) {
    document.body.setAttribute("dir", result.data.user.direction !== undefined ? result.data.user.direction : "ltr");
  }

  // Mark user as authenticated and try to initialize connection
  dispatch(setUserAuthenticated(true));
  console.log('[tryInitializeConnection] User authenticated Trying to initialize connection');
  dispatch(tryInitializeConnection());

  if (isWebPageButNotWhatsappPage()) {

    const emailConfirmationToken = new URL(
      (window as any).location.href
    ).searchParams.get("emailConfirm");

    if (
      emailConfirmationToken &&
      emailConfirmationToken === user?.emailConfirmationToken
    ) {
      dispatch(
        DBUserThunks.patch({
          entity: { _id: user._id, isEmailConfirmed: true, emailConfirmationToken: null },
        })
      );
      dispatch(modalActions.setComponentToRender(MODAL.EMAIL_CONFIRMATION_THANKS));
    }
  }

  // Essential for the app to work, must await before continuing
  const subscriptionListRes = await dispatch(DBSubscriptionThunks.find({}));
  const subscriptionList: Subscription[] = subscriptionListRes.payload;

  dispatch(validateSubscriptionsThunk(subscriptionList));

  await dispatch(
    getWorkspaceDataThunk({
      workspaceId: user?.currentWorkspace?.toString(),
    })
  );

  dispatch(DBSessionThunks.find({}));
  dispatch(setAuthenticating(false));

  if (isExtensionContextOrWhatsappView()) {

    dispatch(loadUserMessagesAndCampaignsThunk())

    dispatch(loadLabelsAndContactLabelsThunk())
    dispatch(updateUserPhoneNumberThunk())

    //loadWAStore()
    //dispatch(rebuildContactNodesThunk());
    dispatch(DBBoardsThunks.find({ isWABoard: true }));
  }
};


export const waCommRequestOnQueryStarted = async (id, { dispatch, queryFulfilled }) => {
  const result = await queryFulfilled;
  console.log("result", result);
  const type = result?.data?.type;

  switch (type) {
    case "requestMissingContactsImages": {
      const contactsWithNewProfilePicArray = result?.data?.contactsWithNewProfilePicArray ?? [];
      if (contactsWithNewProfilePicArray.length > 0) {
        const updatedEntities = contactsWithNewProfilePicArray.map((entity) => ({
          id: entity._id,
          changes: entity
        }));
        dispatch(WaContactsCacheReducerActions.updateMany(updatedEntities));
      }
    }
  }
}
