import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { handleNewMessageDialogThunk } from '@common/reducers/CalendarThunks';
import { Fab, Tooltip } from '@mui/material';
import { FaPlus } from 'react-icons/fa';


const NewMessageFab: React.FC = () => {
  const dispatch = useAppDispatch();

  const openAddScheduledMessageDialog = () => {
    const currentTime = new Date();
    dispatch(handleNewMessageDialogThunk({ slotInfo: currentTime }));
  };

  return (
    <Tooltip title="New message" placement="left">
      <Fab
        onClick={openAddScheduledMessageDialog}
        sx={{
          position: 'fixed',
          bottom: '2rem',
          right: '2rem',
          background: 'linear-gradient(66.67deg, #259ae9 0%, #6dd1f1 100%)',
          color: '#fff',
          '&:hover': {
            background: 'linear-gradient(66.67deg, #2089d1 0%, #5bc0e0 100%)',
          },
          zIndex: 1000,
        }}
        data-testid="new-message-fab"
      >
        <FaPlus style={{ height: '1rem', width: '1rem' }} />
      </Fab>
    </Tooltip>
  );
};

export default NewMessageFab; 