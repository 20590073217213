import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { getCurrentBotSelector, getOverlappingBots, hasMessageSendingNodes } from "@common/reducers/ChatbotSelectors";
import { analyzeBotTriggerScope } from "@common/utils/trigger-analysis";
import { useMemo } from "react";
import { Bot } from "../models/bot";

export type ValidationResultType = 
  'EMPTY_FLOW' | 
  'NO_TRIGGER' | 
  'INACTIVE_TRIGGER' | 
  'OVERLAP' | 
  'EMPTY_MESSAGES' |  // New validation type
  'VALID';

export type ValidationResult = {
    type: ValidationResultType;
    message: string;
    overlappingBots?: any[]; // Type this properly based on your Bot type
    emptyMessageNodes?: { id: string, type: string, label?: string }[]; // New field for empty message nodes
    issues?: { type: ValidationResultType, message: string }[]; // Array to hold multiple issues
};

export function useDeploymentValidation(bot: Bot): ValidationResult {
    console.log('useDeploymentValidation', bot);
    const nodes = useAppSelector(state => state.ChatbotReducer.nodes);
    
    const triggerScope = useMemo(() => 
        analyzeBotTriggerScope(bot?.flowJSON), [bot?.flowJSON]
    );

    const overlappingBots = useAppSelector(state => 
        getOverlappingBots(state, bot?._id)
    );

    // Check if the bot has message-sending nodes
    const hasMessageNodes = useAppSelector(hasMessageSendingNodes);

    // Determine validation result
    const validationResult = useMemo((): ValidationResult => {
        // Collect all issues
        const issues: { type: ValidationResultType, message: string }[] = [];
        
        // Check if there are any nodes at all
        if (!nodes || nodes.length === 0) {
            return {
                type: 'EMPTY_FLOW',
                message: 'This flow has no nodes. Add at least one trigger node and configure the flow before deploying.',
                issues: [{ 
                    type: 'EMPTY_FLOW', 
                    message: 'This flow has no nodes. Add at least one trigger node and configure the flow before deploying.' 
                }]
            };
        }

        // Check for trigger nodes
        const hasTriggerNodes = nodes.some(node => node.type === 'trigger');
        if (!hasTriggerNodes) {
            issues.push({
                type: 'NO_TRIGGER',
                message: 'This flow has no trigger nodes. Add a trigger node to specify when the bot should be activated.'
            });
        }

        // Check for inactive triggers
        if (triggerScope.type === 'NO_MESSAGES') {
            const triggerNodes = nodes.filter(node => node.type === 'trigger');
            const hasInactiveTrigger = triggerNodes.some(node => 
                node.data.triggerType === 'allowList' && (!node.data.contacts || node.data.contacts.length === 0)
            );

            if (hasInactiveTrigger) {
                issues.push({
                    type: 'INACTIVE_TRIGGER',
                    message: 'The trigger node is configured to not receive any messages. Add contacts to the allow list or switch to block list mode.'
                });
            }
        }

        // Check for empty message nodes
        const emptyMessageNodes = nodes.filter(node => {
            if (node.type === 'sendMessage' || node.type === 'messageContact') {
                return (!node.data?.text || node.data.text.trim() === '') && 
                       (!node.data?.asset || Object.keys(node.data.asset).length === 0);
            }
            if (node.type === 'question') {
                return !node.data?.question || node.data.question.trim() === '';
            }
            return false;
        }).map(node => ({
            id: node.id,
            type: node.type,
            label: node.data?.label || node.id
        }));

        if (emptyMessageNodes.length > 0) {
            issues.push({
                type: 'EMPTY_MESSAGES',
                message: 'Your bot contains message nodes with empty content. These nodes will be skipped during execution.'
            });
        }

        // Check for overlapping bots
        if (triggerScope.type !== 'NO_MESSAGES' && hasMessageNodes && overlappingBots.length > 0) {
            issues.push({
                type: 'OVERLAP',
                message: 'There are other active bots that might handle the same messages.'
            });
        }

        // If no issues, return valid
        if (issues.length === 0) {
            return {
                type: 'VALID',
                message: '',
                issues: []
            };
        }

        // Determine the primary issue type (most severe first)
        let primaryType: ValidationResultType;
        if (issues.some(issue => issue.type === 'EMPTY_FLOW')) {
            primaryType = 'EMPTY_FLOW';
        } else if (issues.some(issue => issue.type === 'NO_TRIGGER')) {
            primaryType = 'NO_TRIGGER';
        } else if (issues.some(issue => issue.type === 'INACTIVE_TRIGGER')) {
            primaryType = 'INACTIVE_TRIGGER';
        } else if (issues.some(issue => issue.type === 'OVERLAP')) {
            primaryType = 'OVERLAP';
        } else if (issues.some(issue => issue.type === 'EMPTY_MESSAGES')) {
            primaryType = 'EMPTY_MESSAGES';
        } else {
            primaryType = 'VALID';
        }

        // Return the validation result with all data
        return {
            type: primaryType,
            message: issues[0]?.message || '',
            issues: issues,
            overlappingBots: overlappingBots.length > 0 ? overlappingBots : undefined,
            emptyMessageNodes: emptyMessageNodes.length > 0 ? emptyMessageNodes : undefined
        };
    }, [nodes, triggerScope, overlappingBots, hasMessageNodes]);

    return validationResult;
} 