import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Activity, Calendar, Clock } from 'lucide-react';

interface BotStatsProps {
  stats?: {
    activeSessions?: number;
    todaySessions?: number;
    avgDuration?: string;
    latestDeployment?: {
      createdAt?: string;
    };
  };
}

export const BotStats: React.FC<BotStatsProps> = ({ stats }) => {
  // Format the deployment date with graceful handling
  let deploymentDate = 'Never';
  if (stats?.latestDeployment?.createdAt) {
    const date = new Date(stats.latestDeployment.createdAt);
    if (!isNaN(date.getTime())) {
      deploymentDate = date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <Card>
        <CardContent className="p-6">
          <div className="flex items-center">
            <div className="mr-4 p-2 bg-emerald-100 rounded-full">
              <Activity className="h-5 w-5 text-emerald-600" />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Active Sessions</p>
              <h3 className="text-2xl font-bold text-gray-900">
                {stats?.activeSessions !== undefined ? stats.activeSessions : '--'}
              </h3>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <div className="flex items-center">
            <div className="mr-4 p-2 bg-blue-100 rounded-full">
              <Calendar className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Today's Sessions</p>
              <h3 className="text-2xl font-bold text-gray-900">
                {stats?.todaySessions !== undefined ? stats.todaySessions : '--'}
              </h3>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent className="p-6">
          <div className="flex items-center">
            <div className="mr-4 p-2 bg-purple-100 rounded-full">
              <Clock className="h-5 w-5 text-purple-600" />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Average Duration</p>
              <h3 className="text-2xl font-bold text-gray-900">
                {stats?.avgDuration || '--'}
              </h3>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}; 