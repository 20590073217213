import { Task } from "@common-models/task";
import AutomationReducer from "@common-reducers/AutomationReducer";
import MediaManagerReducer from "@common-reducers/MediaManagerReducer";

import { appConfig } from "@common-config/app.config";
import AccessFormPopupReducer from "@common-reducers/AccessFormPopupReducer";
import AddScheduledMessageButtonReducer from "@common-reducers/AddScheduledMessageButtonReducer";
import backendApi, {
  initAppData,
  middleware
} from "@common-reducers/backend-api/backend-api";
import BoardsReducer from "@common-reducers/BoardsReducer";
import BoardViewReducer from "@common-reducers/BoardviewReducers";
import BulkActionsReducer from "@common-reducers/BulkActionsReducer";
import CampaignReducer from "@common-reducers/CampaignReducer";
import ChatListReducer from "@common-reducers/ChatListReducer";
import { ContactTableReducer } from "@common-reducers/ContactTableReducer";
import CreditUsageReducer from "@common-reducers/CreditUsageReducer";
import CreditPurchaseReducer from "@common-reducers/CreditPurchaseReducer";
import {
  DBAutomationActionsReducer,
  DBAutomationConditionsReducer,
  DBAutomationReducer,
  DBAutomationTriggerReducer,
  DBBoardsReducer,
  DBBoardUserReducer,
  DBBoardViewReducer,
  DBBotDeploymentReducer,
  DBAutomationSessionReducer,
  DBBotReducer,
  DBBulkListReducer,
  DBCampaignReducer,
  DBContactReducer,
  DBContactsLabelsReducer,
  DBEntitySubscribersReducer,
  DBFeatureReducer, DBFormReducer, DBGanttReducer,
  DBInvoiceReducer, DBKanbanReducer, DBLabelReducer,
  DBLogReducer,
  DBMessageLogReducer,
  DBMyWorksReducer,
  DBNewTabFocusReducer,
  DBNotificationsReducer,
  DBQuickLaunchReducer,
  DBSessionEventsReducer,
  DBSessionReducer,
  DBSubscriptionReducer, DBTaskColumnOptionReducer, DBTaskColumnReducer, DBTaskgroupsReducer,
  DBTasksExtraDataFilesReducer,
  DBTasksExtraDataReducer,
  DBTasksReducer,
  DBTemplateReducer,
  DBTimeTrackingSessionReducer,
  DBTracingsReducer,
  DBUserMessageReducer,
  DBUserReducer,
  DBViewFiltersReducer,
  DBWaChatReducer,
  DBWaContactsCacheReducer,
  DBWebhooksReducer,
  DBWorkloadReducer,
  DBWorkspaceReducer,
  DBWorkspaceUserReducer,
  DBNodeEventsReducer,
  DBKnowledgeBaseReducer,
  DBKnowledgeBaseDocumentReducer,
  DBKnowledgeBaseEntryReducer,
} from "@common-reducers/DBServiceThunks";
import ExtraDataReducer from "@common-reducers/ExtraDataReducer";
import GanttReducer from "@common-reducers/GanttReducers";
import ImportItemsReducer from "@common-reducers/ImportItemsReducer";
import KanbanReducer from "@common-reducers/KanbanReducers";
import LabelsReducer from "@common-reducers/LabelsReducer";
import MainReducer, {
  initDarkLightModeAccordingToWhatsAppMode,
  initStorageWrapper,
} from "@common-reducers/MainReducer";
import ModalReducer from "@common-reducers/ModalReducer";
import PickerDialogReducer from "@common-reducers/PickerDialogReducer";
import ProfilePicReducer from "@common-reducers/ProfilePicReducer";
import ShareDialogReducer from "@common-reducers/ShareDialogReducer";
import SnippetsReducer from "@common-reducers/SnippetsReducer";
import SubscriptionPopupReducer from "@common-reducers/SubscriptionPopupReducer";
import TasksReducer from "@common-reducers/TasksReducer";
import UndoRedoReducer from "@common-reducers/UndoRedoReducer";
import UninstallReducer from "@common-reducers/UninstallReducer";
import UserReducer from "@common-reducers/UserReducer";

import {
  initAuth
} from "@common-reducers/AuthThunks";
import WhatsAppReducer from "@common-reducers/WhatsAppReducer";
import WorkspaceReducer from "@common-reducers/WorkspaceReducer";
import { isBackground } from "@common-services/detect-context";
import {
  configureStore
} from "@reduxjs/toolkit";
import devToolsEnhancer from "remote-redux-devtools";
import "symbol-observable";

import BtQuickPanelReducer from "@common-reducers/BtQuickPanelReducer";
import CalendarReducer from "@common-reducers/CalendarReducer";
import LabelReducer from "@common-reducers/labels-reducer";
import MyWorkReducer from "@common-reducers/MyWorkReducer";
import NotifierReducerReducer from "@common-reducers/NotifierReducer";
import PaymentFormReducer from "@common-reducers/PaymentFormReducer";
import RecycleBinReducer from "@common-reducers/RecycleBinReducer";
import StickyHeaderReducer from "@common-reducers/StickyHeaderReducer";
import WhatsAppViewReducer from "@common-reducers/WhatsAppViewReducer";
import ChatbotReducer from "@common/reducers/ChatbotReducer";
import connectedEnginesReducer from '@common/reducers/ConnectedEnginesSlice';
import ConnectionReadinessSlice from "@common/reducers/ConnectionReadinessSlice";
import UserMessageReducer from "@common/reducers/UserMessageReducer";
import { isEqual } from "lodash";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ToolsReducer from '@common-reducers/ToolsReducer';
import ToolSettingsModalReducer from '@common/reducers/ToolSettingsModalReducer';
// Define the SessionEvents slic


let reducerMap = {
  DBKnowledgeBaseReducer,
  DBKnowledgeBaseDocumentReducer,
  DBKnowledgeBaseEntryReducer,
  DBSessionReducer,
  DBBulkListReducer,
  DBContactReducer,
  DBTemplateReducer,
  DBLabelReducer,
  DBCampaignReducer,
  DBTaskgroupsReducer,
  DBTasksExtraDataReducer,
  DBTasksExtraDataFilesReducer,
  DBMessageLogReducer,
  DBWaChatReducer,
  DBLogReducer,
  DBViewFiltersReducer,
  DBBoardsReducer,
  DBTasksReducer,
  DBInvoiceReducer,
  DBTaskColumnReducer,
  DBTaskColumnOptionReducer,
  DBUserReducer,
  DBWorkspaceReducer,
  DBKanbanReducer,
  DBBoardViewReducer,
  DBFeatureReducer,
  DBMyWorksReducer,
  DBGanttReducer,
  DBFormReducer,
  DBWorkloadReducer,
  DBWorkspaceUserReducer,
  DBBoardUserReducer,
  DBSubscriptionReducer,
  DBTimeTrackingSessionReducer,
  DBAutomationReducer,
  DBAutomationTriggerReducer,
  DBAutomationConditionsReducer,
  DBAutomationActionsReducer,
  DBNewTabFocusReducer,
  DBWebhooksReducer,
  UndoRedoReducer,
  ChatListReducer,
  TasksReducer,
  MyWorkReducer,
  KanbanReducer,
  BoardViewReducer,
  GanttReducer,
  BulkActionsReducer,
  BoardsReducer,
  WhatsAppReducer,
  PickerDialogReducer,
  MainReducer,
  DBTracingsReducer,
  MediaManagerReducer,
  AutomationReducer,
  ExtraDataReducer,
  ShareDialogReducer,
  LabelsReducer,
  SubscriptionPopupReducer,
  CampaignReducer,
  UserReducer,
  ChatbotReducer,
  CalendarReducer,
  ContactTableReducer,
  DBUserMessageReducer,
  ProfilePicReducer,
  SnippetsReducer,
  AddScheduledMessageButtonReducer,
  AccessFormPopupReducer,
  backendApi,
  UninstallReducer,
  ModalReducer,
  ImportItemsReducer,
  WorkspaceReducer,
  WhatsAppViewReducer,
  NotifierReducerReducer,
  DBQuickLaunchReducer,
  BtQuickPanelReducer,
  PaymentFormReducer,
  RecycleBinReducer,
  LabelReducer,
  DBEntitySubscribersReducer,
  DBNotificationsReducer,
  DBContactsLabelsReducer,
  StickyHeaderReducer,
  DBBotReducer,
  DBSessionEventsReducer,
  DBWaContactsCacheReducer,
  UserMessageReducer,
  DBBotDeploymentReducer,
  ConnectionReadinessSlice,
  DBAutomationSessionReducer,
  DBNodeEventsReducer,
  ConnectedEnginesReducer: connectedEnginesReducer,
  ToolsReducer,
  ToolSettingsModalReducer,
  CreditUsageReducer,
  creditPurchase: CreditPurchaseReducer,
};

if (isBackground()) {
  reducerMap = {} as any;
}

let enhancers = [];
if (true || !appConfig.isProd) {
  enhancers.push(
    devToolsEnhancer({
      realtime: true,
      port: 8000,
      stateSanitizer: (state) => ({
        DBTasksReducer: state.DBTasksReducer,
        // ...state,
        // WhatsAppReducer: {
        //   ...state.WhatsAppReducer,
        //   contactList: waContactSelectors.selectAll(state).slice(0, 100),
        //   contactMap: {},
        // },
      }),
    })
  );
}

const ignoreLargePathList = [
  "WhatsAppReducer.contactList",
  "WhatsAppReducer.contactMap",
];

const store = configureStore({
  /* Uncomment this to debug extension */
  // devTools: false,
  // enhancers,
  reducer: reducerMap,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { ignoredPaths: ignoreLargePathList },
      serializableCheck: false,
    }).concat(middleware),
});

export interface ScheduleTaskData {
  task: Task;
  to: string;
}

export default store;

console.log("init: app functions");

export type WebRootState = ReturnType<typeof store.getState>;

export const useWebThunkDispatch = () => useDispatch<typeof store.dispatch>();
export const useWebDispatch = () => useDispatch<typeof store.dispatch>();
export const useWebSelector: TypedUseSelectorHook<WebRootState> = (selector) => useSelector(selector, isEqual);

store.dispatch(initAuth());
store.dispatch(initAppData());
store.dispatch(initStorageWrapper());
store.dispatch(initDarkLightModeAccordingToWhatsAppMode());
