import UserControlPanel from "@common-components/user-control-panel/user-control-panel";

import { appConfig } from "@common-config/app.config";
import { namespace } from "@common-config/constants";
import { isCurrentUserMemberOrOwner } from "@common-reducers/BoardsSelectors";
import { workspacesSelectByQueryOnlyLength } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { isUserWorkSpaceOwner, selectUserObject } from "@common-reducers/UserSelectors";
import { logoutWrapperThunk } from "@common-reducers/UserThunks";
import { AnalyticsService } from "@common-services/analytics-service";
import { User } from "@common/models/user";
import { Code, ExitToAppOutlined, FactCheckOutlined, GppGoodOutlined, PersonOutlineOutlined, Receipt, ShoppingCart, BarChart } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MainBackToButton from "../back-t0-buttons/main-back-to-button.tsx";
import { SidebarLinkButton } from "./sidebar-link-button";
import classes from "./sidebar.module.scss";

export enum SideBarItem {
  AccountSettings,
  Language,
  Notifications,
  Security,
  Workspace,
  AdminWorkspace,
  AccountUsers,
  AdminBilling,
  UserManagement,
  AdminAPI,
  Subscriptions,
  Invoices,
  Devices,
  Calendar,
  Campaigns,
  Tasks,
  LaunchCampaign,
  CampaignsManagement,
  Agents,
  KnowledgeBase,
  CreditsUsage,
}

// Map routes to sidebar items
const ROUTE_TO_SIDEBAR_ITEM: Record<string, SideBarItem> = {
  '/account': SideBarItem.AccountSettings,
  '/account-language': SideBarItem.Language,
  '/account-notification': SideBarItem.Notifications,
  '/account-security': SideBarItem.Security,
  '/account-workspace': SideBarItem.Workspace,
  '/admin-workspace': SideBarItem.AdminWorkspace,
  '/user-management': SideBarItem.UserManagement,
  '/admin-api': SideBarItem.AdminAPI,
  '/admin-billing': SideBarItem.AdminBilling,
  '/account-subscription': SideBarItem.Subscriptions,
  '/account-invoices': SideBarItem.Invoices,
  '/devices': SideBarItem.Devices,
  '/calendar': SideBarItem.Calendar,
  '/campaigns': SideBarItem.Campaigns,
  '/tasks': SideBarItem.Tasks,
  '/launch-campaign': SideBarItem.LaunchCampaign,
  '/campaigns-management': SideBarItem.CampaignsManagement,
  '/bot-list-view': SideBarItem.Agents,
  '/credits-usage': SideBarItem.CreditsUsage,
};

export interface SideBarProps {
  readOnly?: boolean;
}

export interface SideBarState {
  user?: User | null | undefined;
}

const SideBar = ({ readOnly }: SideBarProps) => {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector((state) => state.UserReducer.accessToken);
  const isMemberOrOwner = useAppSelector(isCurrentUserMemberOrOwner);
  const isWorkSpaceOwner = useAppSelector(isUserWorkSpaceOwner);
  const user = useAppSelector(selectUserObject);
  const location = useLocation();
  const workspacesCount = useAppSelector((state) =>
    workspacesSelectByQueryOnlyLength(state.DBWorkspaceReducer, { deleted: { $ne: true } })
  );


  const isAdmin = (user?.role === "support" || user?.role === "admin");

  // Get active item based on current route - improved to handle path variations
  const getActiveSidebarItem = () => {
    const path = location.pathname;
    
    // Normalize the path - remove trailing slashes and handle both with/without leading slash
    const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
    const pathWithoutLeadingSlash = normalizedPath.startsWith('/') ? normalizedPath.substring(1) : normalizedPath;
    
    // Try exact match first
    if (ROUTE_TO_SIDEBAR_ITEM[normalizedPath]) {
      return ROUTE_TO_SIDEBAR_ITEM[normalizedPath];
    }
    
    // Then try without leading slash
    if (ROUTE_TO_SIDEBAR_ITEM[`/${pathWithoutLeadingSlash}`]) {
      return ROUTE_TO_SIDEBAR_ITEM[`/${pathWithoutLeadingSlash}`];
    }
    
    // Check if the path ends with any of our known routes
    for (const route in ROUTE_TO_SIDEBAR_ITEM) {
      if (normalizedPath.endsWith(route)) {
        return ROUTE_TO_SIDEBAR_ITEM[route];
      }
    }
    
    return undefined;
  };

  const active = getActiveSidebarItem();

  return (
    <div className={classes.sidebar} style={{ position: "relative" }}>
      <div className={classes.sidebarHeader}>
        <span>{t('sideBar.myAccount')}</span>
      </div>
      {readOnly &&
        <span style={{ fontSize: "14px", padding: "10px" }}>
          Support mode <br />
          Name:{user?.firstName} {user?.lastName}
          <br />
          email: {user.email}

        </span>}
      <Divider sx={{ borderColor: "var(--separator-line)", }}></Divider>

      <div className={classes.menuContainer}>

        {!readOnly &&
          <MainBackToButton to="/calendar" text="Message Planner" />
        }

        <SidebarLinkButton
          to="/account"
          icon={PersonOutlineOutlined}
          title="sideBar.personalInformation"
          active={SideBarItem.AccountSettings}
          currentActive={active}
          analyticsAction="switch_to_personal_information"
          marginTop="30px"
        />
        {/* <Link to="/account-language">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Language ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <img src={worldIcon} />
              <span className={classes.menuTitle}>Language & region</span>
            </div>
          </div>
        </Link>
        <Link to="/account-notification">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Notifications ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <img src={bellIcon} />
              <span className={classes.menuTitle}>Notifications</span>
            </div>
          </div>
        </Link>
          */}
        {!readOnly &&
          <SidebarLinkButton
            to="/account-security"
            icon={GppGoodOutlined}
            title="sideBar.security"
            active={SideBarItem.Security}
            currentActive={active}
            analyticsAction="switch_to_account_security"
          />
        }


        {/* <Link to="/devices" style={{
          width: 'calc(100% - 32px)',
          margin: "auto",
          borderRadius: "8px",
        }}
          onClick={() => {
            analyticsService.event('switch_to_devices', {
              category: "myAccountSidebar",
              action: 'switch to devices',
            })
          }}>
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Devices ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
            <DevicesIcon />
              <span className={classes.menuTitle}>Devices</span>
            </div>
          </div>
        </Link> */}

        {workspacesCount > 1 && <SidebarLinkButton
          to="/account-workspace"
          icon={FactCheckOutlined}
          title="sideBar.workspaces"
          active={SideBarItem.Workspace}
          currentActive={active}
          analyticsAction="switch_to_account_workspace"
        />}
        {/* <Link to="/admin-workspace">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.AdminWorkspace ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <img src={workspaceIcon} />
              <span className={classes.menuTitle}>Admin Workspace</span>
            </div>
          </div>
        </Link> */}

        {isMemberOrOwner && <SidebarLinkButton
          to="/user-management"
          icon={FactCheckOutlined}
          title="sideBar.userManagement"
          active={SideBarItem.UserManagement}
          currentActive={active}
          analyticsAction="switch_to_user_management"
        />}
        {appConfig.asset_key === 'blueticks' && <SidebarLinkButton
          to="/admin-api"
          icon={Code}
          title="sideBar.api"
          active={SideBarItem.AdminAPI}
          currentActive={active}
          analyticsAction="switch_to_admin_api"
          testId="admin-api-view-button"
        />}
        {(isWorkSpaceOwner || user?.role === "support" || user?.role === "admin") &&
          <SidebarLinkButton
            to="/admin-billing"
            icon={Receipt}
            title="sideBar.adminBilling"
            active={SideBarItem.AdminBilling}
            currentActive={active}
            analyticsAction="switch_to_admin_billing"
            testId="admin-billing-view-button"
          />
        }
        {isWorkSpaceOwner && !readOnly && <SidebarLinkButton
          to="/account-subscription"
          icon={ShoppingCart}
          title="sideBar.buySubscription"
          active={SideBarItem.Subscriptions}
          currentActive={active}
          analyticsAction="switch_to_account_subscription"
          testId="buy-subscription-view-button"
        />}
        {/* Credits Usage Link */}
        {isAdmin && isWorkSpaceOwner && !readOnly && <SidebarLinkButton
          to="/credits-usage"
          icon={BarChart}
          title="sideBar.creditsUsage"
          active={SideBarItem.CreditsUsage}
          currentActive={active}
          analyticsAction="switch_to_credits_usage"
          testId="credits-usage-view-button"
        />}
        {/* <Link to="/account-invoices">
          <div
            className={[
              classes.menuItem,
              active === SideBarItem.Invoices ? classes.active : "",
            ].join(" ")}
          >
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <AttachMoney />
              <span className={classes.menuTitle}>Invoices</span>
            </div>
          </div>
        </Link> */}
        {
          !readOnly &&
          <div style={{
            width: "calc(100% - 32px)",
            margin: "auto",
            borderRadius: "8px",
          }}
            className={classes.menuItem}
            onClick={() => {
              dispatch(logoutWrapperThunk({ accessToken }))
            }}
          >
            <ExitToAppOutlined />
            <span className={classes.menuTitle}>{t('sideBar.logout')}</span>
          </div>
        }
      </div >

      <div style={{ marginTop: "auto" }} className={classes.menuContainer}>
        {/* <Link to={`${appConfig.APP_ENDPOINT}/privacy-policy`}>
          <div className={classes.menuItem}>
            <div className={[classes.row, classes.alignCenter].join(" ")}>
              <VerifiedUser></VerifiedUser>
              <span className={classes.menuTitle}>Privacy Policy</span>
            </div>
          </div>
        </Link> */}
        {/* <Link component="a" href="/faq.html">
                <div className={classes.menuItem}>
                    <div className={[classes.row, classes.alignCenter].join(' ')}>
                        <HelpOutline></HelpOutline>
                        <span className={classes.menuTitle}>FAQ</span>
                    </div>
                </div>
            </Link> */}
        {/* <div
            style={{ fontSize: 12 }}
            className={[classes.menuItem, classes.textOnly].join(" ")}
            >
            <FontAwesomeIcon icon={faCopyright as IconProp} />
            <span className={classes.menuTitle}>
                Copyrights 2020, All Rights Resereved
            </span>
            </div> */}
      </div>
      <UserControlPanel isSideMenuOpen={true} readOnly={readOnly} />
    </div >
  );
};

export default SideBar;

