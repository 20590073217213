import { ReactComponent as WaGroupSVG } from '@common-assets/waGroup.svg';
import { ReactComponent as WaPersonSVG } from '@common-assets/waPerson.svg';
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar';
import { selectIsGroup, selectProfilePic, selectWhatsappContactName } from '@common-reducers/WhatsAppSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { DBWaContactsCacheThunks } from '@common/reducers/DBServiceThunks';
import { isExtensionContext } from '@common/services/detect-context';
import { getProfilePicUrlThunk } from '@extension/context/content/thunks/WhatsappThunks';
import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import { memo, useCallback, useEffect, useMemo } from 'react';

export interface ContactAvatarProps {
    contactId: string;
    size?: number;
    textSize?: number;
    sx?: SxProps<Theme>,
    profilePicURL?: string;
    dontFetchProfilePicFromDB?: boolean;
}

const ContactAvatar = memo(({
    contactId,
    size = 55,
    textSize = 16,
    sx,
    profilePicURL,
    dontFetchProfilePicFromDB = false,
}: ContactAvatarProps) => {
    const dispatch = useAppDispatch();

    // Memoize selectors for better performance
    const contactData = useAppSelector(useMemo(() => (state) => ({
        name: selectWhatsappContactName(state, contactId),
        profilePic: selectProfilePic(state, contactId),
        btProfilePic: state.CalendarReducer.contactPicMap[contactId],
        isGroup: selectIsGroup(contactId),
    }), [contactId]));


    // Memoize the profile pic fetch function
    const fetchProfilePic = useCallback(() => {
        if (contactData.profilePic !== undefined || profilePicURL !== undefined || dontFetchProfilePicFromDB) return;

        const timer = setTimeout(() => {
            const isExtension = isExtensionContext()

            if (isExtension) {
                dispatch(getProfilePicUrlThunk(contactId));
            }
        }, 200);

        return () => clearTimeout(timer);
    }, [contactData.profilePic, profilePicURL, dontFetchProfilePicFromDB, dispatch, contactId]);

    useEffect(() => {
        fetchProfilePic();
    }, [fetchProfilePic]);

    // Memoize the final profile pic URL
    const finalProfilePic = useMemo(() =>
        profilePicURL ?? contactData.profilePic,
        [contactData.profilePic, profilePicURL]
    );

    // Memoize the avatar content
    const avatarContent = useMemo(() => {
        if (finalProfilePic) {
            return (
                <PersonAvatar
                    textSize={textSize}
                    size={size}
                    profileImg={finalProfilePic}
                    bgcolor='#DFE5E7'
                />
            );
        }

        if (contactData.isGroup) {
            return <WaGroupSVG height={size} width={size} />;
        }

        return <WaPersonSVG height={size} width={size} />;
    }, [finalProfilePic, contactData.isGroup, size, textSize]);

    return (
        <Tooltip title={contactData.name}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx
            }}>
                {avatarContent}
            </Box>
        </Tooltip>
    );
});

ContactAvatar.displayName = 'ContactAvatar';

export default ContactAvatar;

