import BtEditableBox from "@common-components/tasks/bt-editable-box/bt-editable-box";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { resetFlowState, setCurrentBot, setIsDraft, setIsInitialLoad } from "@common/reducers/ChatbotReducer";
import { getCurrentBotSelector, getIsDraftOlderThanDeploymentSelector } from "@common/reducers/ChatbotSelectors";
import { Check, Edit as EditIcon, Settings as SettingsIcon, FileUploadOutlined as UploadIcon } from '@mui/icons-material';
import { IconButton, Modal, Stack, ThemeProvider, Tooltip, createTheme } from "@mui/material";
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BTbotbutton from "./bot-components/bt-bot-button";
import BTChatbotModal from "./bot-components/bt-chatbot-modal";
import { DeployButtonWrapper } from './bot-components/deploy-button-wrapper';
import { SaveButton } from './bot-components/save-button';
import { BotStatusForEditor } from "./bot-status-for-editor";
import ConnectedEngines from "../connected-engines/connected-engines";

const inputTheme = {
    padding: "0 10px",
    maxWidth: "280px",
    fontSize: "22px",
    fontWeight: "600",
    height: '33px',
    borderRadius: "8px",
    border: "1px solid #23a455",
    backgroundColor: "#e9ffe9",
    "& .MuiInputBase-input": {
        padding: "0",
    },
};

const textTheme = {
    fontSize: "22px",
    padding: "0 10px",
    borderRadius: "8px",
    border: "1px dashed transparent",
    fontWeight: "600",
    "&:hover": {
        border: "1px dashed #23a455",
    },
    height: '33px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '280px',
    boxSizing: 'border-box',
    lineHeight: '34px',
};

const BotHeader = React.memo(() => {
    const dispatch = useAppDispatch();
    const currentBot = useAppSelector(state => getCurrentBotSelector(state));
    const nodes = useAppSelector(state => state.ChatbotReducer.nodes);
    const edges = useAppSelector(state => state.ChatbotReducer.edges);
    const isDraft = useAppSelector(state => state.ChatbotReducer.isDraft);
    const isDraftOlderThanDeployment = useAppSelector(state => getIsDraftOlderThanDeploymentSelector(state));

    const [modalOpen, setModalOpen] = useState(false);
    const [editBotName, setEditBotName] = useState(false);


    const exportBotAsJSON = useCallback(() => {
        const jsonString = JSON.stringify({ nodes, edges }, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const fileName = `${currentBot?.name || 'bot-flow'}.json`;
        saveAs(blob, fileName);
    }, [nodes, edges, currentBot?.name]);


    const theme = useMemo(() => createTheme({
        components: {
            MuiTooltip: {
                defaultProps: {
                    PopperProps: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    },
                },
            },
        },
    }), []); // Theme doesn't depend on any props, so memoize it

    const handleSettingsClick = useCallback(async () => {
        setModalOpen(true);
        await dispatch(setCurrentBot(currentBot));
    }, [dispatch, currentBot]);

    const handleBotNameUpdate = useCallback(async (value: string) => {
        if (currentBot) {
            dispatch(setCurrentBot({
                ...currentBot,
                name: value === '' ? currentBot?.name : value
            }));
        }
    }, [currentBot, dispatch, isDraft]);

    const handleEditModeToggle = useCallback(() => {
        setEditBotName(prev => !prev);
    }, []);

    const botStatus = useCallback(() => {
        if (isDraft && currentBot?.activeDeploymentId) {
            return 'active with draft changes'
        }
        if (isDraft && !currentBot?.activeDeploymentId) {
            return 'not active with draft changes'
        }
        if (!isDraft && currentBot?.activeDeploymentId) {
            return 'active'
        }
        if (!isDraft && !currentBot?.activeDeploymentId) {
            return 'not active'
        }
    }, [isDraft, currentBot?.activeDeploymentId])


    const shouldDisplaySaveDraftButton = useMemo(() => {
        return ['active with draft changes', 'not active with draft changes'].includes(botStatus())
    }, [botStatus]);

    const shouldDisplayDeployButton = useMemo(() => {
        return !['active'].includes(botStatus()) || isDraftOlderThanDeployment
    }, [botStatus, isDraftOlderThanDeployment]);


    // Handle initial load state and cleanup
    useEffect(() => {
        // Reset flow state on mount
        dispatch(resetFlowState());

        // Set initial load to false after a small delay to allow initial node positioning
        const timer = setTimeout(() => {
            dispatch(setIsInitialLoad(false));
        }, 500);

        // Cleanup function that runs on unmount
        return () => {
            clearTimeout(timer);
            dispatch(resetFlowState());
        };
    }, [dispatch]); // Add dispatch to dependencies

    return (
        <ThemeProvider theme={theme}>
            <Stack direction="row" sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                backgroundColor: 'white',
                marginBottom: '10px',
            }}>
                <Stack direction="row" spacing={2} style={{
                    fontSize: '22px',
                    fontWeight: '600',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <BtEditableBox
                        inputSx={inputTheme}
                        boxSx={textTheme}
                        text={currentBot?.name}
                        forceEditMode={editBotName}
                        onBlur={() => setEditBotName(false)}
                        onValueUpdate={handleBotNameUpdate}
                    />
                    {!editBotName && (
                        <IconButton onClick={handleEditModeToggle} disableRipple>
                            <EditIcon sx={{ color: 'black', fontSize: '21px', padding: '2px' }} />
                        </IconButton>
                    )}
                    {editBotName && (
                        <IconButton onClick={handleEditModeToggle} disableRipple>
                            <Check sx={{ color: 'black', fontSize: '21px', padding: '2px' }} />
                        </IconButton>
                    )}
                    {currentBot && (
                        <>
                            <BotStatusForEditor />
                        </>
                    )}
                </Stack>

                <Stack direction="row" spacing={2}>
                    {shouldDisplaySaveDraftButton &&
                        <SaveButton
                            disabled={!isDraft}
                            sx={{
                                minWidth: '120px',
                                padding: 0,
                                '& .MuiButton-label': {
                                    marginBottom: '3px'
                                }
                            }}
                        />
                    }

                    {shouldDisplayDeployButton &&
                        <DeployButtonWrapper
                            onAfterDeploy={() => dispatch(setIsDraft(false))}
                        />
                    }

                    <Tooltip placement="top" title="Settings">
                        <BTbotbutton
                            styleType='empty'
                            onClick={handleSettingsClick}
                            sx={{
                                minWidth: '40px',
                                padding: 0
                            }}
                        >
                            <SettingsIcon />
                        </BTbotbutton>
                    </Tooltip>

                    <Tooltip placement="top" title="Export flow to a JSON file. Media files will not be exported.">
                        <BTbotbutton
                            styleType='empty'
                            onClick={exportBotAsJSON}
                            sx={{
                                minWidth: '40px',
                                padding: 0
                            }}
                        >
                            <UploadIcon />
                        </BTbotbutton>
                    </Tooltip>

                    <ConnectedEngines />

                </Stack>

                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div>
                        <BTChatbotModal type="setSettings" onClose={() => setModalOpen(false)} />
                    </div>
                </Modal>
            </Stack>
        </ThemeProvider>
    );
});

BotHeader.displayName = 'BotHeader';

export default BotHeader;


