import { FormState } from "@common-components/access-form/access-form";
import BTThemeProvider from "@common-components/bt-theme-provider";
import ConditionalWrapper from "@common-components/conditional-wrapper/ConditionalWrapper";
import GuardedRoute from "@common-components/guarded-route/guarded-route";
import InviteUserModal from "@common-components/invite/InviteUserModal";
import TrialModal from "@common-components/modals/trial-modal";
import PaymentThankYouPopup from "@common-components/payment-form/payment-thank-you-popup";
import NotificationsDrawer from "@common-components/tasks/extraData/notifications/notifications-drawers";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";
import { globalHistory } from "@common-services/global-history";
import AccountInvoiceView from "@common-views/account-invoice-view/account-invoice-view";
import AccountLangView from "@common-views/account-lang/account-lang";
import AccountNotificationsView from "@common-views/account-notification/account-notification";
import AccountSecurityView from "@common-views/account-security/account-security";
import AccountSubscriptionView from "@common-views/account-subscription-view/account-subscription-view";
import AccountView from "@common-views/account-view/account-view";
import AccountWorkspacesView from "@common-views/account-workspaces/account-workspaces";
import AdminAPIView from "@common-views/admin-api/admin-api";
import AdminBillingView from "@common-views/admin-billing/admin-billing";
import ApiPlaygroundView from "@common-views/api-playground-view/api-playground-view";
import CalendarView from "@common-views/calendar-view/calendar-view";
import ChatView from '@common-views/chat-view/chat-view';
import CreditsUsageView from "@common-views/credits-usage-view/credits-usage-view";
import ForbiddenAccessView from "@common-views/forbidden-access-view/forbidden-access-view";
import FormPreviewView from "@common-views/form-preview/form-preview-view";
import InvoiceView from "@common-views/invoice-view/invoice-view";
import JoinBoard from "@common-views/join-board/join-board";
import JoinTaskBoard from "@common-views/join-task-board/join-task-board";
import JoinWorkSpace from "@common-views/join-workspace/join-workspace";
import LoginView from "@common-views/login-view/login-view";
import PageNotFound from "@common-views/page-not-found/page-not-found";
import QrView from "@common-views/qr-view/qr-view";
import ResetPasswordView from "@common-views/reset-password-view/reset-password-view";
import { SessionTimeline } from '@common-views/session-timeline/session-timeline';
import SubscriptionView from "@common-views/subscription-view/subscription-view";
import Support from "@common-views/support/support";
import TaskView from "@common-views/task-view/task-view";
import ThankYouView from "@common-views/thank-you-view/thank-you-view";
import { UnifiedAdminBilling } from "@common-views/unified-admin-billing/unified-admin-billing";
import UninstallView from "@common-views/uninstall-view/uninstall-view";
import UnsubscribeView from '@common-views/unsubscribe-view/unsubscribe-view';
import UserManagementView from "@common-views/user-management/user-management";
import UserOverview from "@common-views/user-overview/user-overview";
import WebHookGuideView from "@common-views/webbook-guide-view/webbook-guide-view";
import WebHookGuideMake from "@common-views/webhook-guide-make/webbook-guide-make";
import WebHookGuideZapier from "@common-views/webhook-guide-zaiper/webbook-guide-zapier";
import WorkspaceView from "@common-views/workspace-view/workspace-view";
import { CommonRootState } from "@common/types/common-root-state-type.d";
import { useEffect } from "react";
import { unstable_HistoryRouter as HistoryRouter, Navigate, Route, Routes } from "react-router-dom";

import { injectPixels } from "@common/site/inject-pixels";

import "@common/color-scheme.css";
import BTChatBotView from "@common/components/bt-chatbot/bt-chatbot-view";
import "@common/site/site.module.scss";
import "../styles/globals.css";
import { CampaignManagementView } from "@common/views/campaign-management-view/campaign-management-view";
import LaunchCampaignView from "@common/views/launch-campaign-view/launch-campaign-view";
import CampaignsView from "@extension/context/content/components/bt-bulk-dashboard/campaigns-view";
import BotListView from "@common/components/bt-chatbot/bot-list-view/BotListView";
import { BotDetailView } from "@common/components/bt-chatbot/bot-display/BotDetailView";
import SessionDetailsView from "@common/components/bt-chatbot/bot-display/components/SessionDetailsView";
import KnowledgeBaseView from "@common-views/knowledge-base-view/knowledge-base-view";
import KnowledgeBaseDetail from "@common-components/knowledge-base/detail/KnowledgeBaseDetail";

export default function WebsiteComponent() {
  const analyticsService: AnalyticsService = useAnalyticsService();

  useEffect(() => {
    injectPixels()
    analyticsService.pageView(window.location.pathname);
  }, []);

  const sitePages = () => {
    const supportedUserId = new URLSearchParams(window.location.search).get('supportedUserId');
    const readOnly = supportedUserId ? true : false;
    return (
      <BTThemeProvider>
        <div className="min-h-screen bg-background font-sans antialiased">
          <HistoryRouter history={globalHistory}>
            <Routes>
              <Route path="/account" element={
                <GuardedRoute
                  element={<AccountView readOnly={readOnly} />}
                  auth={true}
                />
              } />
              <Route path="/account-subscription" element={
                <GuardedRoute
                  element={<AccountSubscriptionView />}
                  auth={true}
                />
              } />
              <Route path="/account-invoices" element={
                <GuardedRoute
                  element={<AccountInvoiceView />}
                  auth={true}
                />
              } />
              <Route
                path="/new-workspace"
                element={
                  <GuardedRoute element={<WorkspaceView />} auth={true} />
                }
              />
              <Route
                path="/edit-workspace"
                element={
                  <GuardedRoute element={<WorkspaceView editMode />} auth={true} />
                }
              />
              <Route
                path="/form-preview/:shareId"
                element={<FormPreviewView />}
              />
              <Route
                path="/admin-billing-old"
                element={
                  <GuardedRoute element={<AdminBillingView readOnly={readOnly} />} auth={true} />
                }
              />
              <Route
                path="/user-management"
                element={
                  <GuardedRoute element={<UserManagementView readOnly={readOnly} />} auth={true} />
                }
              />
              <Route
                path="/admin-billing"
                element={
                  <GuardedRoute element={<UnifiedAdminBilling />} auth={true} />
                }
              />
              <Route
                path="/admin-api"
                element={
                  <GuardedRoute element={<AdminAPIView />} auth={true} />
                }
              />
              <Route
                path="/credits-usage"
                element={
                  <GuardedRoute element={<CreditsUsageView />} auth={true} />
                }
              />
              <Route
                path="/login"
                element={
                  <GuardedRoute
                    element={<LoginView initialState={FormState.Login} />}
                    redirect='/calendar'
                    auth={false}
                    redirectBack={false}
                  />
                } />
              <Route path="/signup" element={
                <GuardedRoute
                  element={<LoginView initialState={FormState.Signup} />}
                  redirect='/calendar'
                  auth={false}
                />
              } />
              <Route path="/fyp" element={
                <GuardedRoute
                  element={<LoginView initialState={FormState.ForgotPassword} />}
                  redirect='/calendar'
                  auth={false}
                />
              } />
              <Route path="/pricing" element={
                <GuardedRoute
                  element={<SubscriptionView />}
                  auth={true}
                />
              } />
              <Route path="/tasks" element={
                <GuardedRoute
                  element={<TaskView />}
                  auth={true}
                />
              } />
              <Route path="/mywork" element={
                <GuardedRoute
                  element={<TaskView />}
                  auth={true}
                />
              } />

              <Route
                path="/reset-password"
                element={<ResetPasswordView />}
              />
              <Route
                path="/account-language"
                element={
                  <GuardedRoute element={<AccountLangView />} auth={true} />
                }
              />
              <Route
                path="/forbidden-access"
                element={<ForbiddenAccessView />}
              />
              <Route
                path="/account-notification"
                element={
                  <GuardedRoute
                    element={<AccountNotificationsView />}
                    auth={true}
                  />
                }
              />
              <Route
                path="/account-security"
                element={
                  <GuardedRoute element={<AccountSecurityView />} auth={true} />
                }
              />
              <Route
                path="/thank-you"
                element={<ThankYouView />}
              />
              <Route
                path="/account-workspace"
                element={
                  <GuardedRoute
                    element={<AccountWorkspacesView readOnly={readOnly} />}
                    auth={true}
                  />
                }
              />
              <Route
                path="/trialModal"
                element={<TrialModal />}
              />
              <Route
                path="/qr-view"
                element={
                  <GuardedRoute
                    element={<QrView />}
                    auth={true}
                  />
                }
              />
              <Route
                path="/support"
                element={<GuardedRoute element={<Support />} auth={true} />}
              />
              <Route
                path="/notifications-drawer"
                element={
                  <GuardedRoute element={<NotificationsDrawer />} auth={true} role="admin" />
                }
              />
              <Route path="/api-playground" element={<ApiPlaygroundView />} />
              <Route path="/webhook-guide" element={<WebHookGuideView />} />
              <Route path="/webhook-make-guide" element={<WebHookGuideMake />} />
              <Route path="/webhook-zapier-guide" element={<WebHookGuideZapier />} />
              <Route path="/thank-you" element={<ThankYouView />} />
              <Route path="/uninstall" element={<UninstallView />} />
              <Route path="/sharetable" element={<InviteUserModal />} />
              <Route
                path="/join-task-board/:shareId"
                element={
                  <GuardedRoute element={<JoinTaskBoard />} auth={true} />
                }
              />
              <Route
                path="/invoices/:invoiceId"
                element={
                  <GuardedRoute element={<InvoiceView />} auth={true} />
                }
              />
              <Route path="/join-board/:boardId" element={
                <GuardedRoute
                  element={<JoinBoard />}
                  auth={true}
                />
              } />
              <Route path="/join-workspace/:workspaceId" element={
                <GuardedRoute
                  element={<JoinWorkSpace />}
                  auth={true}
                />
              } />
              <Route path="*" element={<Navigate to="./login" replace />} />

              <Route
                path="/calendar"
                element={
                  <GuardedRoute element={<CalendarView />} auth={true} />
                }
              />
              <Route
                path="/bot-build-view/:botId"
                element={
                  <GuardedRoute element={<BTChatBotView />} auth={true} />
                }
              />
              <Route
                path="/bot-list-view"
                element={
                  <GuardedRoute element={<BotListView />} auth={true} />
                }
              />
              <Route
                path="/bot-detail-view/:botId"
                element={
                  <GuardedRoute element={<BotDetailView />} auth={true} />
                }
              />
              <Route
                path="/bot-detail-view/:botId/session/:sessionId"
                element={
                  <GuardedRoute element={<SessionDetailsView />} auth={true} />
                }
              />
              <Route
                path="/chat"
                element={
                  <GuardedRoute element={<ChatView />} auth={true} />
                }
              />
              <Route
                path="/page-not-found"
                element={<PageNotFound />}
              />
              <Route
                path="/user-overview"
                element={
                  <GuardedRoute element={<UserOverview />} auth={true} />
                }
              />
              <Route path="/launch-campaign" element={
                <GuardedRoute
                  element={<LaunchCampaignView />}
                  auth={true}
                />
              } />
              <Route path="/campaigns" element={
                <GuardedRoute
                  element={<CampaignsView isWebsite={true} />}
                  auth={true}
                />
              } />

              <Route path="/campaigns-management" element={
                <GuardedRoute
                  element={<CampaignManagementView />}
                  auth={true}
                />
              } />
              <Route
                path="/unsubscribe/:subscriptionID"
                element={<UnsubscribeView />}
              />

              <Route
                path="/session-timeline/:userId"
                element={
                  <GuardedRoute
                    element={<SessionTimeline />}
                    auth={true}
                    role="admin"
                  />
                }
              />

              <Route path="/knowledge-base" element={
                <GuardedRoute
                  element={<KnowledgeBaseView />}
                  auth={true}
                />
              } />
              <Route path="/knowledge-base/:id" element={
                <GuardedRoute
                  element={<KnowledgeBaseDetail />}
                  auth={true}
                />
              } />
            </Routes>
            <ConditionalWrapper shouldLoadSelector={(state: CommonRootState) => state.PaymentFormReducer.paymentSuccess}>
              <PaymentThankYouPopup />
            </ConditionalWrapper>
          </HistoryRouter>
        </div>
      </BTThemeProvider>
    );
  };

  return sitePages();
}
