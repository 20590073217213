import axios, { AxiosResponse } from 'axios';
import { Poll } from 'whatsapp-web.js/src/structures';
import MessageMedia from 'whatsapp-web.js/src/structures/MessageMedia';
import { getWaMessageType, initWaMessageOptions, replaceMentionsAndExtractIds as prepareMessageMentions } from './process-message-utils';
import { UserMessage, WaMessage } from './wa-interfaces';
import { decode } from 'html-entities';


/**
 * Decodes a 32-byte Uint8Array back to a string
 * @param {Uint8Array} buffer - The 32-byte Uint8Array to decode
 * @returns {string} The decoded string
 */
export function decodeUint8Array32ToString(buffer: Uint8Array | undefined) {
    // Create a decoder
    const decoder = new TextDecoder();
    
    // Find the actual length of the string data (stop at first zero byte or end)
    let length = 0;
    while (length < buffer?.length && buffer[length] !== 0) {
      length++;
    }
    
    // Slice the buffer to only include the actual data (exclude trailing zeros)
    const dataSlice = buffer?.slice(0, length);
    
    // Decode the byte array to a string
    return decoder.decode(dataSlice);
  }

/**
 * Encodes a string into a 32-byte Uint8Array
 * @param {string} input - The string to encode
 * @returns {Uint8Array} A 32-byte Uint8Array containing the encoded string
 */
export function encodeStringToUint8Array32(input) {
    // Create a new Uint8Array of size 32
    const buffer = new Uint8Array(32);

    // Convert the string to a UTF-8 encoded array
    const encoder = new TextEncoder();
    const encodedString = encoder.encode(input);

    // Copy as many bytes as possible, up to 32 bytes
    const bytesToCopy = Math.min(encodedString.length, 32);

    // Copy the bytes to the buffer
    buffer.set(encodedString.slice(0, bytesToCopy));

    // If the string was shorter than 32 bytes, the remaining bytes will be 0

    return buffer;
}


export async function prepareMessageForWa(message: UserMessage): Promise<WaMessage> {

    const waMessage: WaMessage = {};

    waMessage.type = getWaMessageType(message as any);
    waMessage.chatId = message.contactList[0].id;
    waMessage.messageId = message._id.toString();


    switch (waMessage.type) {
        case 'text':
        case 'newsletterText':
        case 'image':
        case 'serverImage':
        case 'attachmentWithoutImage':
        case 'serverAttachmentWithoutImage':
        case 'newsletterImage':
        case 'newsletterServerImage':
        case 'newsletterAttachmentWithoutImage':
        case 'newsletterServerAttachmentWithoutImage':
        case 'newsletterAttachmentWithImage':
            const messageText = message.message;
            const messageOptions = {};

            const { messageTextWithMentions, messageOptionsWithMentionedIds } = await prepareMessageMentions(messageText, messageOptions);

            waMessage.content = messageTextWithMentions;
            waMessage.options = prepareTextMessageWithReply(message?.replyData, messageOptionsWithMentionedIds);

            if (message.sendLinkPreview && message.linkPreview) {
                waMessage.options.linkPreview = {
                    title: message.linkPreview.title,
                    description: message.linkPreview.description,
                    canonicalUrl: message.linkPreview.url,
                    thumbnail: message.linkPreview.thumbnail,
                    doNotPlayInline: true
                };
            }
            
            if (message.asset) {
                if (
                    waMessage.type === 'serverImage' || 
                    waMessage.type === 'serverAttachmentWithoutImage' || 
                    waMessage.type === 'newsletterServerImage' ||
                    waMessage.type === 'newsletterServerAttachmentWithoutImage') {
                    const attachment = await MessageMedia.fromUrl(message.asset.url, { unsafeMime: true });
                    attachment.filename = message.asset.name;
                    waMessage.options.attachment = attachment;
                    waMessage.options.media = {
                        data: attachment.data,
                        mimetype: attachment.mimetype,
                        filename: attachment.filename
                    };
                    waMessage.options.caption = waMessage.content as string;
                    waMessage.content = '';
                } else {
                    const attachment = await prepareMessageAttachment(message.asset);
                    waMessage.options.attachment = attachment;
                    waMessage.options.media = {
                        file: attachment.file,
                        mimetype: attachment.mimetype,
                        filename: attachment.filename
                    };
                    waMessage.options.caption = waMessage.content as string;
                    waMessage.content = '';
                }
            }
            break;

        case 'poll':
        case 'newsletterPoll':
            waMessage.content = '';
            const pollMessageOptions = await preparePollMessageOptions(message);

            waMessage.options = pollMessageOptions;
            break;

        default:
            console.warn(`Unsupported message type: ${waMessage.type}`);
            break;
    }

    waMessage.messageSecret = encodeStringToUint8Array32(message._id.toString());

    return waMessage; // Return the original message
}

const prepareMediaOptions = async (asset: any) => {
    const base64FromUrl = await axios.get(asset.url, { responseType: 'arraybuffer' })
        .then(response => Buffer.from(response.data, 'binary').toString('base64'));

    return {
        data: base64FromUrl,
        mimeType: asset.type,
        filename: asset.name
    }
}

const prepareMessageAttachment = async (asset: any) => {

    const response: AxiosResponse<Blob> = await axios.get(
        asset.url,
        { responseType: 'blob' }
    );

    const assetFile = new File([response.data], asset.name, {
        type: asset.type,
    });

    const attachment = {
        file: assetFile,
        filename: assetFile.name,
        mimetype: assetFile.type,
    };
    return attachment;
};

const preparePollMessageOptions = async (message: UserMessage) => {

    const pollOptions = message.poll.options
        .filter(o => o.text && o.text !== '')
        .sort((a, b) => a.order - b.order)
        .map((option) => {
            return option.text;
        });


    const poll = new Poll(
        message?.poll?.question,
        pollOptions,
        { allowMultipleAnswers: message?.poll?.allowMultiple },
    );

    const options: any = {};
    const internalOptions: any = initWaMessageOptions(options);
    internalOptions.poll = poll;

    return internalOptions;
};

const prepareTextMessageWithReply = (replyData: any, options: any) => {

    if (replyData) {
        options = { ...options, quotedMessageId: replyData?._serialized, };
    }

    return options;
};
