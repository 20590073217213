import { automationSessionSelectOneObjectByQuery, botDeploymentSelectOneObjectByQuery, DBAutomationSessionThunks } from '@common-reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { selectProfilePic } from '@common-reducers/WhatsAppSelectors';
import { isExtensionContext } from '@common-services/detect-context';
import { setSlugBotId } from '@common/reducers/ChatbotReducer';
import ContactAvatar from '@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar';
import { requestProfilePicThunk } from '@extension/context/content/thunks/WhatsappThunks';
import { formatDistanceToNow } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActiveSessionChip from './active-session-chip';

// Shadcn UI Components
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Badge } from '@/components/ui/badge';

// Lucide Icons
import { Clock, Trash2 } from 'lucide-react';

interface SessionRowProps {
    sessionId: string;
}

export const SessionRow = ({ sessionId }: SessionRowProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { botId } = useParams<{ botId: string }>();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [duration, setDuration] = useState<string>('');


    const session = useAppSelector(state => {
        const result = automationSessionSelectOneObjectByQuery(state.DBAutomationSessionReducer, { _id: sessionId });
        return result;
    });

    // Get deployment information to show version
    const deployment = useAppSelector(state =>
        session ? botDeploymentSelectOneObjectByQuery(state.DBBotDeploymentReducer, { _id: session.botDeploymentId }) : null
    );

    // Get profile picture
    const profilePic = useAppSelector(state => session ? selectProfilePic(state, session.contactWaId) : undefined);
    const isExtension = isExtensionContext();

    // Format phone number
    const formattedPhone = useMemo(() => {
        if (!session) return '';
        const phoneNumber = session.contactWaId.split('@')[0];
        try {
            const parsedNumber = parsePhoneNumber(`+${phoneNumber}`);
            return parsedNumber.formatInternational();
        } catch (error) {
            return `+${phoneNumber}`;
        }
    }, [session]);

    const isActive = session.status === 'open';

    useEffect(() => {
        dispatch(setSlugBotId(botId))
    }, [botId, dispatch]);

    // Request profile picture if needed
    useEffect(() => {
        if (!session || profilePic !== undefined) return;

        dispatch(requestProfilePicThunk(session.contactWaId));
    }, [dispatch, session, isExtension, profilePic]);

    // Calculate and update duration
    useEffect(() => {
        if (!session?.createdAt) return;

        const updateDuration = () => {
            const start = moment(session.createdAt);
            const end = session.status === 'open' ? moment() : moment(session.closedAt ?? session.updatedAt);
            const duration = moment.duration(end.diff(start));
            setDuration(duration.humanize());
        };

        // Initial update
        updateDuration();

        // Only set up interval for open sessions
        let intervalId: NodeJS.Timeout | null = null;
        if (session.status === 'open') {
            intervalId = setInterval(updateDuration, 10000); // Update every 10 seconds
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [session]);

    if (!session) {
        console.log('SessionRow - session is null for sessionId:', sessionId);
        return null;
    }

    const startedTime = formatDistanceToNow(new Date(session.createdAt), { addSuffix: true });

    const handleDeleteClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        await dispatch(DBAutomationSessionThunks.delete({
            entity: session,
        }));
        setDeleteDialogOpen(false);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleClick = () => {
        navigate(`/bot-detail-view/${botId}/session/${sessionId}`);
    };

    const displayName = session.answers?.displayName || formattedPhone;

    return (
        <>
            <div
                onClick={handleClick}
                className="flex items-center p-4 border-b border-gray-200 hover:bg-gray-50 cursor-pointer transition-colors relative"
            >
                <ContactAvatar
                    sx={{ mr: 2 }}
                    contactId={session.contactWaId}
                    size={49}
                    textSize={12}
                />
                <div className="flex flex-col space-y-1 flex-1">
                    <h4 className="text-base font-medium">
                        {displayName}
                    </h4>
                    {session.answers?.displayName && (
                        <p className="text-sm text-gray-500">
                            {formattedPhone}
                        </p>
                    )}
                    <div className="flex space-x-4 items-center">
                        <p className="text-sm text-gray-500">
                            Started {startedTime}
                        </p>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="flex items-center gap-1 text-gray-500">
                                        <Clock className="h-4 w-4" />
                                        <span className="text-sm">{duration}</span>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Session Duration</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    {session.status && isActive &&
                        <ActiveSessionChip isActive={isActive} />
                    }
                    {deployment && (
                        <Badge variant="outline" className="bg-gray-100 text-xs">
                            v{deployment.version}
                        </Badge>
                    )}
                    <Button
                        onClick={handleDeleteClick}
                        variant="destructive"
                        size="sm"
                        className="h-8"
                    >
                        <Trash2 className="h-4 w-4 mr-1" />
                        Delete
                    </Button>
                </div>
            </div>

            <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                <DialogContent className="z-[1201]">
                    <DialogHeader>
                        <DialogTitle>Delete Session</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete this session? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={handleDeleteCancel}>
                            Cancel
                        </Button>
                        <Button variant="destructive" onClick={handleDeleteConfirm}>
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            <style>
                {`
                    /* Target all dialog backdrops and give them proper z-index */
                    .fixed.inset-0[aria-hidden="true"] {
                        z-index: 1200 !important; 
                    }
                `}
            </style>
        </>
    );
}; 