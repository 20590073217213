import { isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { globalHistory } from "@common-services/global-history";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { Subscription } from "@common-models/subscription";
import { DBSessionThunks, SessionReducerActions } from "@common-reducers/DBServiceThunks";
import { BLUETICKS_TOKEN_KEY } from "@common/reusable/common";
import { MessageServiceActions } from "@common/services/messageServiceActions";
import { getPendingUserMessages } from "@common/thunks/UserMessagesThunks";
import { CommonRootState } from "@common/types/common-root-state-type";
import { accessFormPopupActions } from "./AccessFormPopupReducer";
import { getLastUpdatedUserCampaigns } from "./CampaignThunks";
import { toggleSocketModal } from "./MainReducer";

import { selectCurrentSessionId, selectUserId } from "./UserSelectors";
import { backendApiEndpoints } from "./backend-api/backend-api";

//import { googleLogout } from '@react-oauth/google';
import {
  authenticate,
  backendApi,
  cancelSubscription,
  changePlan,
  updateChargeDate,
} from "@common-reducers/backend-api/backend-api";

import { sleep } from "@common-services/utils/sleep";

import {
  DBBoardsThunks,
  DBSubscriptionThunks,
  DBUserThunks,
  SubscriptionReducerActions,
  boardsReducerActions,
  userSelectByQuery,
} from "@common-reducers/DBServiceThunks";
import { User } from "@common/models/user";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import moment from "moment";
import { DBReducerMap } from "./DBServiceThunks";
import { mainSliceActions } from "./MainReducer";
import { modalActions } from "./ModalReducer";
import { notifierDisconnectThunk } from "./NotifierThunks";
import { taskActions } from "./TasksReducer";
import { logout, setUserDirection } from "./UserReducer";
import { selectCurrentWorkspace } from "./UserSelectors";


export const logoutWrapperThunk = createAsyncThunk<void, { accessToken: string | null }, { state: CommonRootState }>(
  "logoutThunk",
  ({ accessToken }, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(logoutThunk());


    if (accessToken) {
      window.localStorage.removeItem(BLUETICKS_TOKEN_KEY);
      const url = new URL(window.location.href);
      url.searchParams.delete("accessToken");
      window?.history?.replaceState(null, "", url);
    }
    //never navigate to /login in whatsApp!
    if (!isExtensionContextOrWhatsappView()) {
      globalHistory?.push("/login");
    }
  }
);

export const openSocketModalThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
  "openSocketModalThunk",
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    const state = thunkApi.getState();

    const userId = selectUserId(state);
    if (!userId) {
      dispatch(accessFormPopupActions.setStatus("Signup"));
    } else {
      const sessionId = selectCurrentSessionId(state);
      dispatch(SessionReducerActions.removeOne(sessionId));
      dispatch(toggleSocketModal(true));
    }
  }
);

export const createNewSessionThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
  "createNewSessionThunk",
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    await dispatch(SessionReducerActions.clearState());
    await dispatch(backendApiEndpoints.createSession.initiate());
    await dispatch(DBSessionThunks.find({}));
  }
);

// Validate that the user does not have redundant subscriptions
export const validateSubscriptionsThunk = createAsyncThunk<void, Subscription[], { state: CommonRootState }>(
  "validateSubscriptionsThunk",
  async (subscriptionList: Subscription[], thunkApi) => {
    const { dispatch } = thunkApi;


    const hasActiveChargebee = subscriptionList.some(sub =>
      sub.type === 'chargebee' && sub.status.toLowerCase() === 'active'
    );

    // Get all active Bluesnap subscriptions
    const activeBluesnapSubs = subscriptionList.filter(sub =>
      sub.type === 'bluesnap' && sub.origStatus.toUpperCase() === 'ACTIVE'
    );

    // Check if there's at least one active Bluesnap subscription
    const hasActiveBluesnap = activeBluesnapSubs.length > 0;

    // If both conditions are met, return the active Bluesnap subscriptions
    if (hasActiveChargebee && hasActiveBluesnap) {
      for (const sub of activeBluesnapSubs) {
        await dispatch(backendApiEndpoints.cancelSubscription.initiate({
          subscriptionID: sub._id,
          reason: 'Redundant subscription'
        }));
      }
    }
  }
);


// create loadUserMessagesAndCampaignsThunk
export const loadUserMessagesAndCampaignsThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
  "loadUserMessagesAndCampaignsThunk",
  async (_, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(getLastUpdatedUserCampaigns())
    dispatch(getPendingUserMessages())
  }
);


export const switchWorkspace = createAsyncThunk<
  void,
  { workspaceId: string },
  { state: CommonRootState }
>("switchWorkspace", async ({ workspaceId }, thunkApi) => {
  const { dispatch, getState } = thunkApi;
  const state: CommonRootState = getState();
  const userId = state.UserReducer.userId;
  const currentWorkspace = selectCurrentWorkspace(state);

  if (currentWorkspace?._id === workspaceId) {
    return;
  }

  dispatch(modalActions.cleanComponentToRender());
  await dispatch(boardsReducerActions.clearState());
  await dispatch(SubscriptionReducerActions.clearState());



  await dispatch(
    DBUserThunks.patch({
      entity: {
        _id: userId,
        currentWorkspace: workspaceId,
      },
    })
  );

  await dispatch(refreshUser());
  await dispatch(DBSubscriptionThunks.find({}));

  await dispatch(DBBoardsThunks.find({ deleted: false }));
});

export const cancelSubscriptionThunk = createAsyncThunk<
  void,
  { subscriptionID: string; reason: string },
  { state: CommonRootState }
>("cancelSubscriptionThunk", async ({ subscriptionID, reason }, thunkApi) => {
  const { dispatch } = thunkApi;

  const res = await dispatch(
    cancelSubscription.initiate({
      subscriptionID,
      reason,
    })
  );

  if ("data" in res) {
    dispatch(refreshUser());
  }
});

export const updateChargeDateThunk = createAsyncThunk<
  void,
  { subscriptionId: string; nextChargeDate: string },
  { state: CommonRootState }
>("updateChargeDate", async ({ subscriptionId, nextChargeDate }, thunkApi) => {
  const { dispatch } = thunkApi;

  await dispatch(
    updateChargeDate.initiate({
      subscriptionId,
      nextChargeDate,
    })
  );


});

export const toggleDarkMode = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("toggleDarkMode", (_, thunkApi) => {
  const state = thunkApi.getState();
  const { dispatch } = thunkApi;
  const [user] = userSelectByQuery(state.DBUserReducer, {
    _id: state.UserReducer.userId,
  });
  const isDarkMode = !user?.isDarkMode;

  console.log(`Dark mode: ${isDarkMode}`);

  dispatch(
    DBUserThunks.patch({
      entity: {
        ...user,
        isDarkMode,
      },
    })
  );

  document.querySelector("body").className = isDarkMode ? "dark" : "";
});

export const toggleDomDirection = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("toggleDomDirection", (_, thunkApi) => {
  const state = thunkApi.getState();
  const { dispatch } = thunkApi;
  const [user] = userSelectByQuery(state.DBUserReducer, {
    _id: state.UserReducer.userId,
  });
  const direction = user?.direction === "rtl" ? "ltr" : "rtl";
  if (!window.location.href.includes("web.whatsapp.com")) {
    document.body.setAttribute("dir", direction);
  }

  dispatch(setUserDirection(direction));

  dispatch(
    DBUserThunks.patch({
      entity: {
        ...user,
        direction,
      },
    })
  );
});

export const changeDefaultChatCountryCode = createAsyncThunk<
  void,
  { defaultChatCountryCode: string },
  { state: CommonRootState }
>(
  "changeDefaultChatCountryCode",
  async ({ defaultChatCountryCode }, thunkApi) => {
    const state = thunkApi.getState();
    const { dispatch } = thunkApi;

    const [user] = userSelectByQuery(state.DBUserReducer, {
      _id: state.UserReducer.userId,
    });

    if (user.defaultChatCountryCode !== defaultChatCountryCode) {

      dispatch(DBUserThunks.patch({
        entity: {
          _id: state.UserReducer.userId,
          defaultChatCountryCode,
        },
      })
      );
    }
  }
);

export const changePaymentPlan = createAsyncThunk<
  { changeSuccessful: boolean },
  { productId: string },
  { state: CommonRootState }
>("changePaymentPlan", async ({ productId }, thunkApi) => {
  const { dispatch } = thunkApi;
  let res;
  try {
    res = await dispatch(
      changePlan.initiate({
        contractId: productId,
      })
    ).unwrap();
    const { changeSuccessful } = res;
    if (changeSuccessful) {
      await dispatch(refreshUser());
    }
  } catch (e) {
    res = e;
  }
  return res;
});

export const googleLoginThunk = createAsyncThunk<
  void,
  { accessToken?: string },
  { state: CommonRootState }
>("googleLoginThunk", async ({ accessToken }, thunkApi) => {
  console.log("googleLoginThunk called")
  let result;
  if (!accessToken) {
    const res = await MessageServiceActions.postMessageToBackgroundWithResponse<{
      accessToken: string;
    }>("launchWebAuthFlow", {});

    accessToken = res.accessToken;
    result = await thunkApi.dispatch(
      authenticate.initiate({
        strategy: "google",
        accessToken,
      })
    );
  } else {
    result = await thunkApi.dispatch(
      authenticate.initiate({
        strategy: "google-token",
        accessToken,
      })
    );
  }
  return result
});

export const clearStateThunk = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("clearStateThunk", async (_, thunkApi) => {
  const { dispatch } = thunkApi;

  const clearStatePromises = [];

  DBReducerMap.forEach((reducer, name) => {
    //console.log(`Clearing state for ${name} reducer`);
    clearStatePromises.push(dispatch(reducer.slice.actions.clearState()));
  });

  await Promise.all(clearStatePromises);
});

export const logoutThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
  "logoutThunk",
  (_, thunkApi) => {
    const { dispatch } = thunkApi;
    try {
      dispatch(logout());
      dispatch(backendApi.util.resetApiState());
      MessageServiceActions.postMessageWithResponse("logout", {
        data: {
          source: "logoutThunk",
        }
      });
      MessageServiceActions.postMessageToBackgroundWithResponse("logout", {
        data: {
          source: "logoutThunk",
        }
      });
      MessageServiceActions.sendMessageToTab("logout", {
        data: {
          source: "logoutThunk",
        }
      });

      localStorage.removeItem(BLUETICKS_TOKEN_KEY);

      dispatch(taskActions.setCurrentBoardView({
        type: 'board',
        id: undefined
      }))

      // Disconnect socket before clearing state
      dispatch(notifierDisconnectThunk());

      dispatch(clearStateThunk());

      // Close UI elements
      dispatch(mainSliceActions.closeCalendarView());
      dispatch(taskActions.setIsTasksPanelOpen(false));
      dispatch(notifierDisconnectThunk);

      //googleLogout();
    } catch (e) {
      console.error(e);
    }
  }
);

export interface PollRequestOptions {
  user: User;
  timeout?: number;
}

export const refreshUser = createAsyncThunk<void, void, { state: CommonRootState }>(
  "refreshUser",
  async (_, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();
    const accessToken = state.UserReducer.accessToken;
    await thunkApi
      .dispatch(
        authenticate.initiate({
          strategy: "jwt",
          accessToken,
        })
      )
      .unwrap();
  }
);


export const updateUserPhoneNumberThunk = createAsyncThunk("updateUserPhoneNumberThunk", async (_, thunkApi) => {
  const { dispatch } = thunkApi;
  const state = thunkApi.getState() as CommonRootState;
  if (!state?.UserReducer?.userId) {
    console.log("[updateUserPhoneNumberThunk] No user id found");
    return;
  }

  const [user] = userSelectByQuery(state.DBUserReducer, {
    _id: state.UserReducer.userId,
  });

  if (user?.phone) {
    console.log("[updateUserPhoneNumberThunk] Phone number already set");
    return;
  }

  const waMeInfo = await ContentMessageServiceActions.getWaMeInfo();
  dispatch(DBUserThunks.patch({
    entity: {
      _id: state.UserReducer.userId,
      phone: waMeInfo?.wid?.user,
    },
  }));
}
);

