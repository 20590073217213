'use client';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  automationSessionSelectOneObjectByQuery,
  botDeploymentSelectOneObjectByQuery,
  botSelectOneObjectByQuery,
  DBAutomationSessionThunks,
  DBBotDeploymentThunks,
  DBBotThunks,
  DBNodeEventsThunks
} from '@common-reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { setCurrentSessionId } from '@common/reducers/ChatbotReducer';
import { getFilteredEventListBySessionId } from '@common/reducers/ChatbotSelectors';
import { prepareFlowVisualizationThunk } from '@common/reducers/ChatbotThunks';
import { AutomationSession } from '@common/models/automation-session';
import {
  SmartToy as BotIcon,
  CheckCircle as CheckCircleIcon,
  AccessTime as ClockIcon,
  Memory as DeploymentIcon,
  Storage as StorageIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import 'reactflow/dist/style.css';
import ActiveSessionChip from './active-session-chip';
import FlowVisualization from './flow-visualization';
import NodeEventsList from './NodeEventsList';

// Extend the AutomationSession interface to include nodeStates
declare module '@common/models/automation-session' {
  interface AutomationSession {
    nodeStates?: Record<string, any>;
  }
}

// Session State Modal Component
interface SessionStateModalProps {
  open: boolean;
  onClose: () => void;
  sessionState: Record<string, any> | undefined;
}

interface ExpandedKeysState {
  [key: string]: boolean;
}

const SessionStateModal: React.FC<SessionStateModalProps> = ({ open, onClose, sessionState }) => {
  const [expandedKeys, setExpandedKeys] = useState<ExpandedKeysState>({});

  // Function to toggle expansion of nested objects
  const toggleExpand = (key) => {
    setExpandedKeys(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  // Recursive function to render JSON with collapsible sections
  const renderJsonValue = (value, path = '') => {
    if (value === null) return <span className="text-gray-500">null</span>;
    if (value === undefined) return <span className="text-gray-500">undefined</span>;
    
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      const keys = Object.keys(value);
      if (keys.length === 0) return <span className="text-gray-500">{'{}'}</span>;
      
      const isExpanded = expandedKeys[path] !== false; // Default to expanded
      
      return (
        <div>
          <span 
            onClick={() => toggleExpand(path)} 
            style={{ cursor: 'pointer', color: '#2196f3' }}
          >
            {isExpanded ? '▼' : '►'} {'{'}
          </span>
          
          {isExpanded && (
            <div style={{ marginLeft: '20px' }}>
              {keys.map(key => (
                <div key={key}>
                  <span style={{ color: '#7c4dff' }}>{key}</span>: {renderJsonValue(value[key], `${path}.${key}`)}
                </div>
              ))}
            </div>
          )}
          
          {isExpanded && <div>{'}'}</div>}
          {!isExpanded && <span>{'}'}</span>}
        </div>
      );
    }
    
    if (Array.isArray(value)) {
      if (value.length === 0) return <span className="text-gray-500">[]</span>;
      
      const isExpanded = expandedKeys[path] !== false; // Default to expanded
      
      return (
        <div>
          <span 
            onClick={() => toggleExpand(path)} 
            style={{ cursor: 'pointer', color: '#2196f3' }}
          >
            {isExpanded ? '▼' : '►'} {'['}
          </span>
          
          {isExpanded && (
            <div style={{ marginLeft: '20px' }}>
              {value.map((item, index) => (
                <div key={index}>
                  <span style={{ color: '#7c4dff' }}>{index}</span>: {renderJsonValue(item, `${path}[${index}]`)}
                </div>
              ))}
            </div>
          )}
          
          {isExpanded && <div>{']'}</div>}
          {!isExpanded && <span>{']'}</span>}
        </div>
      );
    }
    
    // Render primitive values
    if (typeof value === 'string') return <span style={{ color: '#4caf50' }}>"{value}"</span>;
    if (typeof value === 'number') return <span style={{ color: '#ff9800' }}>{value}</span>;
    if (typeof value === 'boolean') return <span style={{ color: '#e91e63' }}>{value.toString()}</span>;
    
    return <span>{String(value)}</span>;
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Session State
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ 
          backgroundColor: '#f5f5f5', 
          p: 2, 
          borderRadius: 1,
          fontFamily: 'monospace',
          fontSize: '0.875rem',
          overflowX: 'auto'
        }}>
          {sessionState ? (
            renderJsonValue(sessionState, 'root')
          ) : (
            <Typography color="text.secondary">No session state available</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button 
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(sessionState, null, 2));
          }}
          color="primary"
        >
          Copy to Clipboard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SessionDetailsContent = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const dispatch = useAppDispatch();
  const [stateModalOpen, setStateModalOpen] = useState(false);

  // Get session from Redux store
  const session = useAppSelector(state =>
    automationSessionSelectOneObjectByQuery(state.DBAutomationSessionReducer, { _id: sessionId })
  );

  // Get events from Redux store
  const events = useAppSelector(state => getFilteredEventListBySessionId(state, sessionId));

  // Get bot deployment and bot info
  const botDeployment = useAppSelector(state =>
    botDeploymentSelectOneObjectByQuery(state.DBBotDeploymentReducer, { _id: session?.botDeploymentId })
  );

  const bot = useAppSelector(state =>
    botSelectOneObjectByQuery(state.DBBotReducer, { _id: botDeployment?.botId })
  );

  const prepareFlowVisualization = async () => {
    // fetch from db the bot and botdeployment
    await dispatch(DBBotThunks.find({ _id: session?.botId }));
    await dispatch(DBBotDeploymentThunks.find({ _id: session?.botDeploymentId }));
    await dispatch(prepareFlowVisualizationThunk(sessionId));
  }

  // Fetch session data and events
  useEffect(() => {
    if (sessionId) {
      dispatch(DBAutomationSessionThunks.find({
        _id: sessionId,
        $paginate: false
      }));

      dispatch(DBNodeEventsThunks.find({
        sessionId: sessionId,
        $sort: {
          timestamp: 1
        },
        $paginate: false
      }));

      dispatch(setCurrentSessionId(sessionId));
    }
  }, [sessionId, dispatch]);

  useEffect(() => {
    if (session) {
      prepareFlowVisualization()
    }
  }, [session]);

  if (!session) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Typography color="text.secondary">Loading session details...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 3 }, height: '100%' }}>
      {/* Header Section */}
      <Box sx={{ mb: 3 }}>
        <Card sx={{ bgcolor: 'background.paper', borderRadius: 1 }}>
          <CardContent>
            <Box sx={{ 
              display: 'grid', 
              gridTemplateColumns: { 
                xs: 'repeat(1, 1fr)', 
                sm: 'repeat(2, 1fr)', 
                md: 'repeat(3, 1fr)', 
                lg: 'repeat(5, 1fr)' 
              }, 
              gap: 2 
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    bgcolor: 'rgba(39, 174, 96, 0.1)',
                    borderRadius: '6px',
                    p: '12px',
                    width: 32,
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <BotIcon sx={{ color: '#27AE60', width: 24, height: 24 }} />
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">Agent</Typography>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {bot?.name || 'Unnamed Agent'}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    bgcolor: 'rgba(45, 156, 219, 0.1)',
                    borderRadius: '6px',
                    p: '12px',
                    width: 32,
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <DeploymentIcon sx={{ color: '#2D9CDB', width: 24, height: 24 }} />
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">Deployment</Typography>
                  <Typography variant="body2" sx={{ fontWeight: 500, color: '#2D9CDB' }}>
                    {botDeployment?.version || '1'}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    bgcolor: 'rgba(155, 81, 224, 0.1)',
                    borderRadius: '6px',
                    p: '12px',
                    width: 32,
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <ClockIcon sx={{ color: '#9B51E0', width: 24, height: 24 }} />
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">Started</Typography>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {formatDistanceToNow(new Date(session?.createdAt || new Date()), { addSuffix: true })}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    bgcolor: 'rgba(39, 174, 96, 0.1)',
                    borderRadius: '6px',
                    p: '12px',
                    width: 32,
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CheckCircleIcon sx={{ color: '#27AE60', width: 24, height: 24 }} />
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">Status</Typography>
                  <Box>
                    <ActiveSessionChip isActive={session?.status === 'open'} />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    bgcolor: 'rgba(255, 152, 0, 0.1)',
                    borderRadius: '6px',
                    p: '12px',
                    width: 32,
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <StorageIcon sx={{ color: '#FF9800', width: 24, height: 24 }} />
                </Box>
                <Box>
                  <Typography variant="caption" color="text.secondary">Session State</Typography>
                  <Box>
                    <Button 
                      variant="outlined" 
                      size="small" 
                      color="primary"
                      onClick={() => setStateModalOpen(true)}
                      sx={{ mt: 0.5 }}
                    >
                      View State
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Main Content */}
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' }, 
        gap: 3, 
        height: { xs: 'auto', md: 'calc(100% - 100px)' }
      }}>
        {/* Flow Visualization */}
        <Box sx={{ height: { xs: '400px', md: '100%' } }}>
          <FlowVisualization />
        </Box>

        {/* Events Log */}
        <Card sx={{ height: { xs: '400px', md: '100%' }, overflow: 'hidden' }}>
          <NodeEventsList events={events} />
        </Card>
      </Box>

      {/* Session State Modal */}
      <SessionStateModal 
        open={stateModalOpen} 
        onClose={() => setStateModalOpen(false)} 
        sessionState={session?.nodeStates || {}}
      />
    </Box>
  );
};

export default SessionDetailsContent; 