import { Box, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import {
    ReactFlowProvider,
    useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';
import ChatbotFlow from '../../chatbot-flow';
import { useAppSelector } from '@common-reducers/hooks/store.hook';

// Wrapper component that contains the actual flow visualization
const FlowContent = () => {
    const isReadyToLoadFlow = useAppSelector(state => state.ChatbotReducer.isReadyToLoadFlow);
    const nodes = useAppSelector(state => state.ChatbotReducer.nodes);

    if (!isReadyToLoadFlow) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress size={40} />
            </Box>
        );
    }

    if (isReadyToLoadFlow && (!nodes || nodes.length === 0)) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1" color="text.secondary">
                    No flow data available for this session
                </Typography>
            </Box>
        );
    }

    return <ChatbotFlow fitOnInit={true} />;
};

export default function FlowVisualization() {
    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ 
                height: '100%', 
                boxSizing: 'border-box', 
                display: 'flex', 
                flexDirection: 'column',
                p: 2,
                '&:last-child': { pb: 2 }
            }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Flow Visualization</Typography>
                <Box
                    sx={{
                        bgcolor: 'grey.50',
                        borderRadius: 1,
                        overflow: 'hidden',
                        flex: 1,
                        minHeight: '300px',
                        '& .react-flow__controls-button': {
                            backgroundColor: 'white',
                            borderBottom: '1px solid #eee',
                            '&:hover': {
                                backgroundColor: '#f8f8f8'
                            }
                        },
                        '& .react-flow__renderer': {
                            overflow: 'hidden'
                        },
                        '& .react-flow__edge-path': {
                            stroke: '#b1b1b7',
                            strokeWidth: 2,
                            fill: 'none'
                        },
                        '& .react-flow__edge.selected .react-flow__edge-path': {
                            stroke: '#555'
                        }
                    }}
                >
                    <ReactFlowProvider>
                        <FlowContent />
                    </ReactFlowProvider>
                </Box>
            </CardContent>
        </Card>
    );
}