import BTInputField from "@common-components/bt-input-field/bt-input-field";
import RowStack from "@common-components/common/row-stack/RowStack";
import { CheckBoxOutlined as CheckBoxIcon } from '@mui/icons-material';
import { Checkbox, Stack } from "@mui/material";
import classes from "../bt-chatbot.module.scss";
import { modalTextStyle } from "./bt-chatbot-modal";
import { onChangeParams } from "./bot-settings";

type Props = {
    isChatbotTimer: boolean;
    chatbotTimerCountdown: number;
    chatbotTimerEndMessage: string;
    chatbotTimerMessageBeforeEnd: number;
    onChange: (params: onChangeParams) => void;
}

const ChatBotTimer = ({
    isChatbotTimer,
    chatbotTimerCountdown,
    chatbotTimerEndMessage,
    chatbotTimerMessageBeforeEnd,
    onChange,
}: Props) => {

    const customInputStyle = {
        sx: {
            ".MuiOutlinedInput-notchedOutline": {
                border: "0",
                backgroundColor: 'rgb(245, 246, 250)',
            },
            "&.Mui-focused": {
                ".MuiOutlinedInput-notchedOutline": {
                    border: "0",
                },
            },
            "&: hover": {
                ".MuiOutlinedInput-notchedOutline": {
                    border: "0",
                },
            },
            ".MuiOutlinedInput-input": {
                ...modalTextStyle,
                zIndex: 3,
                lineHeight: '19px',
            },
            height: '40px',
            width: '100%',
            margin: '0px 0px 8px 0px',
        }
    };
    return (
        <Stack sx={{ fontSize: '13px' }}>
            <span style={{ fontSize: '15px', marginBottom: '20px' }}>Chatbot Timer</span>
            <RowStack sx={{ marginBottom: '20px' }}>
                <span>If user does not reply more than</span>
                <BTInputField
                    className={classes.mui_number_field}
                    value={chatbotTimerCountdown ?? 30}
                    type="number"
                    onChange={({ target }: any) => onChange({ type: 'chatbotTimerCountdown', value: target.value })}
                    sx={{
                        ...customInputStyle.sx,
                        height: '30px',
                        width: '40px',
                        margin: '0px 10px',
                        padding: '0px',

                        ".MuiOutlinedInput-notchedOutline": {
                            border: "0px",
                            backgroundColor: 'rgb(245, 246, 250)',
                            padding: '0px'
                        },
                        ".MuiOutlinedInput-input": {
                            ...modalTextStyle,
                            zIndex: 3,
                            lineHeight: '19px',
                            padding: '0px',
                            textAlign: 'center',
                        },
                        "&.Mui-focused": {
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "0",
                                borderBottom: '3px solid #ff9100',
                            },
                            "&: hover": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderBottom: '3px solid #ff9100',
                                },
                            },
                        },
                    }}
                />

                <span>minutes, the chatbot will end automatically.</span>
            </RowStack>
            {
                chatbotTimerCountdown <= 1 &&
                <span style={{ fontSize: '10px', fontWeight: '600', color: 'red' }}>
                    Input value should be a positive integer except 1
                </span>
            }
            {!(chatbotTimerCountdown <= 1) &&
                chatbotTimerCountdown > 1440 &&
                <span style={{ fontSize: '10px', fontWeight: '600', color: 'red' }}>Max chatbot expiration time is 24 hours (1440 minutes)</span>
            }

            <RowStack sx={{ marginBottom: '15px' }}>
                <Checkbox
                    checkedIcon={<CheckBoxIcon />}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            color: 'rgb(35, 164, 85)',
                            marginRight: '10px',
                            fontSize: '1rem',
                        },
                        padding: '0px',
                    }}
                    checked={isChatbotTimer}
                    onChange={() => onChange({ type: 'isChatbotTimer' })}
                    disableRipple />
                <span>Enable exit chatbot notification</span>
            </RowStack>

            {isChatbotTimer && (
                <Stack>

                    <RowStack sx={{ justifyContent: 'space-between' }}>
                        <span style={{ marginBottom: '10px' }}>Exit chatbot notification:</span>
                        <span style={{
                            fontSize: '10px',
                            fontWeight: chatbotTimerEndMessage.length > 1024 && '700',
                            color: chatbotTimerEndMessage.length > 1024 && 'red',
                        }}>
                            {chatbotTimerEndMessage.length}/1024
                        </span>
                    </RowStack>

                    <BTInputField
                        value={chatbotTimerEndMessage ?? ''}
                        type="text"
                        multiline={true}
                        rows={6}
                        onChange={({ target }: any) => onChange({ type: 'chatbotTimerEndMessage', value: target.value })}
                        sx={{ ...customInputStyle.sx, height: 'auto', marginBottom: '20px', p: '10px', }}
                    />
                    <RowStack sx={{ marginBottom: '20px' }}>
                        <span>This exit chatbot notification will show</span>
                        <BTInputField
                            className={classes.mui_number_field}
                            value={chatbotTimerMessageBeforeEnd ?? 5}
                            type="number"
                            onChange={({ target }: any) => onChange({ type: 'chatbotTimerMessageBeforeEnd', value: target.value })}

                            sx={{
                                ...customInputStyle.sx,
                                height: '30px',
                                width: '40px',
                                margin: '0px 10px',
                                padding: '0px',

                                ".MuiOutlinedInput-notchedOutline": {
                                    border: "0px",
                                    backgroundColor: 'rgb(245, 246, 250)',
                                    padding: '0px'
                                },
                                ".MuiOutlinedInput-input": {
                                    ...modalTextStyle,
                                    zIndex: 3,
                                    lineHeight: '19px',
                                    padding: '0px',
                                    textAlign: 'center',
                                },
                                "&.Mui-focused": {
                                    ".MuiOutlinedInput-notchedOutline": {
                                        border: "0",
                                        borderBottom: '3px solid #ff9100',
                                    },
                                    "&: hover": {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderBottom: '3px solid #ff9100',
                                        },
                                    },
                                },
                            }}
                        />
                        <span>minutes before the chatbot ends.</span>
                    </RowStack>
                    {
                        chatbotTimerMessageBeforeEnd <= 0 &&
                        <span style={{ fontSize: '10px', fontWeight: '600', color: 'red' }}>
                            Input value should be positive number
                        </span>
                    }
                    {
                        !(chatbotTimerMessageBeforeEnd <= 0) &&
                        chatbotTimerMessageBeforeEnd >= chatbotTimerCountdown &&
                        <span style={{ fontSize: '10px', fontWeight: '600', color: 'red' }}>
                            Chatbot inactivity timer should be longer than exit notification timer.
                        </span>
                    }
                </Stack>
            )}
        </Stack>
    )
}

export default ChatBotTimer;