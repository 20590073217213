// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!react-big-calendar/lib/addons/dragAndDrop/styles.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "!style-loader!css-loader!./calendar-custom.css";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { DBUserMessageThunks } from "@common-reducers/DBServiceThunks";
import { useAppSelector, useAppThunkDispatch } from "@common-reducers/hooks/store.hook";
import { UserMessage } from "@common/models/user-message";
import AddScheduledMessage from "@extension/context/content/components/add-scheduled-message-button/add-scheduled-message";
import { MessageConfirmationDialog } from "@extension/context/content/components/add-scheduled-message-button/message-confirmation-dialog";
import { Box, DialogContent } from "@mui/material";
import moment from "moment";
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Calendar,
  EventProps,
  momentLocalizer,
  NavigateAction,
  stringOrDate,
  ToolbarProps,
  View
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { CustomDayBackground } from "./custom-day-background";
import { CustomMonthEventWrapper } from './custom-message-event-wrapper';
import CustomToolbar from "./custom-toolbar";
import MessageColor, { Color } from "./message-color";
// import { isTimeToUpdateContacts } from "@common-reducers/WhatsAppSelectors";
import { handleNewMessageDialogThunk, onColorsRecurringDialogCloseThunk, onDeleteRecurringDialogCloseThunk, onRecurringDialogCloseThunk } from "@common-reducers/CalendarThunks";
import { getCalendarEvents } from "@common-reducers/WhatsAppSelectors";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { setCurrentDate, setSelectedEventDate, setView } from "@common/reducers/CalendarReducer";
import { getUserStartDayOfWeek } from "@common/reducers/UserSelectors";
import { getPendingUserMessages, scheduleMessage } from "@common/thunks/UserMessagesThunks";
import { CommonRootState } from "@common/types/common-root-state-type";
import { CustomDayCell } from "./custom-day-cell";
import CustomMonthDayBackground from "./custom-month-day-background";
import SelectedEvent from "./selected-event";



const DnDCalendar = withDragAndDrop<UserMessage>(Calendar);

export interface CalendarEvent extends UserMessage {
  dropDate?: moment.Moment;
}
export type EventPL = UserMessage & Partial<DOMRect>;

export type confirmationDialogOption =
  | "All messages"
  | "This message"
  | "This and following messages";

export const confirmationDialogOptions: confirmationDialogOption[] = [
  "This message",
  "This and following messages",
  "All messages",
];



function MessageCalendar() {
  const localizer = momentLocalizer(moment);
  const [eventPL, setEventPL] = useState<EventPL>(null);
  const [dndEvent, setDndEvent] = useState<CalendarEvent>(null);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [showDndConfirmationDialog, setShowDndConfirmationDialog] =
    useState<boolean>(false);
  const [showEventDialog, setShowEventDialog] = useState<boolean>(false);
  const [showColorsDialog, setShowColorsDialog] = useState<boolean>(false);
  const [showColorsConfirmationDialog, setShowColorsConfirmationDialog] =
    useState<boolean>(false);
  const [eventColor, setEventColor] = useState<string>();

  // Remove loading state - rely on Redux
  const currentDate = useAppSelector((state) => state.CalendarReducer.currentDate);
  const events = useAppSelector(state => getCalendarEvents(state));
  const isAddScheduledMessageModalOpen = useAppSelector((state) => state.AddScheduledMessageButtonReducer.open);
  const view = useAppSelector((state) => state.CalendarReducer.view);
  const startDayAtWeek = useAppSelector(getUserStartDayOfWeek);
  const focusedDate = useAppSelector((state: CommonRootState) => state.MainReducer.viewMonthStart);

  const dispatch = useAppThunkDispatch();

  // Dispatch action to load messages when component mounts
  useEffect(() => {
    dispatch(getPendingUserMessages());
  }, [dispatch]);

  // Create a ref for the container div
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  // Use ResizeObserver for continuous and accurate measurements
  useEffect(() => {
    if (!calendarContainerRef.current) return;

    // Initial measurement
    const rawWidth = calendarContainerRef.current.getBoundingClientRect().width;
    const calculatedWidth = view === 'week'
      ? Math.floor(rawWidth / 7) - 22
      : Math.floor(rawWidth / 7) - 8;
    setContainerWidth(calculatedWidth);

    // Create ResizeObserver for continuous updates
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const rawWidth = entry.contentRect.width;
        const calculatedWidth = view === 'week'
          ? Math.floor(rawWidth / 7) - 22
          : Math.floor(rawWidth / 7) - 8;
        setContainerWidth(calculatedWidth);
      }
    });

    // Start observing the container
    resizeObserver.observe(calendarContainerRef.current);

    // Clean up
    return () => {
      resizeObserver.disconnect();
    };
  }, [view]);

  // Re-measure when view changes
  useEffect(() => {
    if (calendarContainerRef.current) {
      const rawWidth = calendarContainerRef.current.getBoundingClientRect().width;
      const calculatedWidth = view === 'week'
        ? Math.floor(rawWidth / 7) - 22
        : Math.floor(rawWidth / 7) - 8;
      setContainerWidth(calculatedWidth);
    }
  }, [view]);

  // Listen for changes to the focused date from the thunk
  useEffect(() => {
    if (focusedDate) {
      dispatch(setCurrentDate(focusedDate));
    }
  }, [focusedDate]);
  
  useEffect(() => {
    moment.locale('en', {
      week: {
        dow: startDayAtWeek
      }
    });
  }, [startDayAtWeek]);

  const onNavigate = useCallback(
    (newDate: Date, view: View, action?: NavigateAction): void => {
      dispatch(setCurrentDate(newDate));
    },
    []
  );

  const onSelectEvent = (
    event: UserMessage,
    e: SyntheticEvent<HTMLElement, Event>
  ): void => {
    dispatch(addScheduledMessageButtonActions.editCalendarMessage(event));
  };

  const deleteEvent = () => {
    eventPL.isRecurring
      ? setShowDeleteConfirmationDialog(true)
      : dispatch(DBUserMessageThunks.delete({ entity: eventPL }));

    setShowEventDialog(false);
    setShowColorsDialog(false);
  };

  const closeEvent = () => {
    setShowEventDialog(false);
  };

  const onEventDrop = async ({
    event,
    start,
  }: {
    event: UserMessage;
    start: stringOrDate;
  }) => {
    const dropDate = moment.utc(start).startOf("minute");
    console.log('[DND] Event drop detected:', {
      eventId: (event as any)._id,
      isRecurring: event.isRecurring,
      originalDate: event.dueDate,
      newDate: dropDate.toISOString()
    });

    if (dropDate.isSame(event.dueDate)) {
      console.log('[DND] Drop date same as original, ignoring');
      return;
    }

    // Store the original date for the recurring event modification
    if (event.isRecurring) {
      await dispatch(setSelectedEventDate(event.dueDate));
    }

    const updatedEvent = {
      ...event,
      dueDate: dropDate.toISOString()
    };
    console.log('[DND] Setting dndEvent:', updatedEvent);
    setDndEvent(updatedEvent);

    if (event.isRecurring) {
      console.log('[DND] Showing confirmation dialog for recurring event');
      setShowDndConfirmationDialog(true);
    } else {
      console.log('[DND] Updating non-recurring event directly');
      dispatch(scheduleMessage({ ...event, dueDate: dropDate.toISOString() }));
    }
  };

  const onDndRecurringDialogClose = async (value?: confirmationDialogOption) => {
    console.log('[DND_DIALOG] Dialog closed with value:', value);
    console.log('[DND_DIALOG] Current dndEvent:', dndEvent);

    if (value && dndEvent) {
      console.log('[DND_DIALOG] Dispatching thunk with:', {
        value,
        eventId: (dndEvent as any)._id,
        dueDate: dndEvent.dueDate
      });
      await dispatch(onRecurringDialogCloseThunk({ value, event: dndEvent }));
    } else {
      console.log('[DND_DIALOG] No value or dndEvent, skipping update');
    }

    console.log('[DND_DIALOG] Cleaning up state');
    setShowDndConfirmationDialog(false);
    setDndEvent(null);
  };

  const onDeleteRecurringDialogClose = async (value?: confirmationDialogOption) => {
    await dispatch(onDeleteRecurringDialogCloseThunk({ value, event: eventPL }));
    setShowDeleteConfirmationDialog(false);
  };

  const onColorsRecurringDialogClose = async (value?: confirmationDialogOption) => {
    await dispatch(onColorsRecurringDialogCloseThunk({ value, eventColor, event: eventPL }));
    setShowColorsConfirmationDialog(false);
  };


  const onSelectSlot = (slotInfo) => {
    if (view === 'month') dispatch(handleNewMessageDialogThunk(slotInfo))
  };

  const onView = (view: View) => {
    dispatch(setView(view));
  };

  const onRightClick = (pl: EventPL) => {
    setEventPL(pl);
    setShowColorsDialog(true);
  };

  const onCloseColorDialog = () => {
    setShowColorsDialog(false);
  };

  const components = useMemo(
    () => ({
      toolbar: (v: ToolbarProps<UserMessage, any>) => (
        <CustomToolbar toolbarProps={v} />
      ),
      month: {
        event: (v: EventProps<UserMessage>) => (
          <CustomMonthEventWrapper
            eventProps={v}
            onRightClick={onRightClick}
            containerWidth={containerWidth}
            onDelete={(event) => {
              setEventPL(event);
              if (event.isRecurring) {
                setShowDeleteConfirmationDialog(true);
              } else {
                dispatch(DBUserMessageThunks.delete({ entity: event }));
              }
            }}
          />
        ),
        dateHeader: ({ date, label }) => (
          <CustomDayCell date={date} label={label} />
        ),
        dateCellWrapper: ({ value }) => (
          <CustomMonthDayBackground date={value} />
        ),
      },
      week: {
        event: (v: EventProps<UserMessage>) => (
          <CustomMonthEventWrapper
            eventProps={v}
            onRightClick={onRightClick}
            containerWidth={containerWidth}
            onDelete={(event) => {
              setEventPL(event);
              if (event.isRecurring) {
                setShowDeleteConfirmationDialog(true);
              } else {
                dispatch(DBUserMessageThunks.delete({ entity: event }));
              }
            }}
          />
        ),
        timeSlotWrapper: ({ children, value }) => (
          <CustomDayBackground date={value}>{children}</CustomDayBackground>
        ),
      },
      day: {
        event: (v: EventProps<UserMessage>) => (
          <CustomMonthEventWrapper
            eventProps={v}
            onRightClick={onRightClick}
            containerWidth={containerWidth}
            onDelete={(event) => {
              setEventPL(event);
              if (event.isRecurring) {
                setShowDeleteConfirmationDialog(true);
              } else {
                dispatch(DBUserMessageThunks.delete({ entity: event }));
              }
            }}
          />
        ),
        timeSlotWrapper: ({ children, value }) => (
          <CustomDayBackground date={value}>{children}</CustomDayBackground>
        ),
      },
    }),
    [containerWidth]
  );

  const onColorChange = (c: Color) => {
    setEventColor(c);
    if (eventPL.isRecurring) {
      setShowColorsConfirmationDialog(true);
    } else {
      dispatch(scheduleMessage({ ...eventPL, messageColor: c }));
    }

    setShowColorsDialog(false);
  };


  return (
    <>
      <BTThemeProvider>
        <Box sx={{
          flex: 1,
          height: '100%',
          transition: 'all 0.2s',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <DialogContent
            sx={{
              flex: 1,
              padding: '24px !important',
              boxSizing: 'border-box',
              backgroundColor: 'var(--background-default)',
              position: 'relative',
              height: 'auto'
            }}>
            <div
              ref={calendarContainerRef}
              style={{ width: '100%', height: '100%' }}
            >
              <DnDCalendar
                key={view}
                localizer={localizer}
                events={events}
                titleAccessor="message"
                startAccessor={(event: UserMessage) => new Date(event.dueDate)}
                endAccessor={(event: UserMessage) => new Date(event.dueDate)}
                date={currentDate}
                onNavigate={onNavigate}
                onSelectEvent={onSelectEvent}
                view={view}
                onView={onView}
                resizable={false}
                selectable={true}
                onSelectSlot={onSelectSlot}
                onEventDrop={onEventDrop}
                components={components}
                dayLayoutAlgorithm="no-overlap"
                draggableAccessor={() => true}
                dragFromOutsideItem={() => null}
                scrollToTime={moment().startOf('day').toDate()}
                formats={{
                  eventTimeRangeFormat: () => '',
                  timeGutterFormat: (date: Date, culture?: string, localizer?: any) =>
                    localizer.format(date, 'HH:mm', culture)
                }}
                step={30}
                timeslots={1}
                min={moment().startOf('day').toDate()}
                max={moment().endOf('day').toDate()}
                showMultiDayTimes={true}
                longPressThreshold={100}
                style={{
                  height: '100%',
                  backgroundColor: 'var(--background-default)',
                }}
              />
            </div>
            {showEventDialog && (
              <SelectedEvent
                event={eventPL}
                onClose={closeEvent}
                onDelete={deleteEvent}
              />
            )}
          </DialogContent>
        </Box>
        {showDeleteConfirmationDialog && (
          <MessageConfirmationDialog
            title="Delete recurring message"
            options={confirmationDialogOptions}
            onClose={onDeleteRecurringDialogClose}
          />
        )}
        {showDndConfirmationDialog && (
          <MessageConfirmationDialog
            title="Edit recurring message"
            options={confirmationDialogOptions.slice(0, 2)}
            onClose={onDndRecurringDialogClose}
          />
        )}
        {showColorsDialog && (
          <MessageColor
            event={eventPL}
            onClose={onCloseColorDialog}
            onDelete={deleteEvent}
            onChange={onColorChange}
          />
        )}
        {showColorsConfirmationDialog && (
          <MessageConfirmationDialog
            title="Edit recurring message color"
            options={confirmationDialogOptions}
            onClose={onColorsRecurringDialogClose}
          />
        )}
      </BTThemeProvider>
      {isAddScheduledMessageModalOpen && <AddScheduledMessage />}
    </>
  );
}

export default MessageCalendar;
