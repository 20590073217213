import RowStack from '@common-components/common/row-stack/RowStack';
import PersonAvatar from '@common-components/tasks/person-avatar/person-avatar';
import { Circle } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import { notificationsSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import ExtraDataNotification from './extra-data-notification';
import NewDeleteRestoreTaskNotification from './new-delete-task-notification';
import NotificationCreation from './notification-creation';
import classes from "./notifications-drawer.module.scss";
import PersonColumnNotification from './person-column-notification';
import RenameNotification from './rename-notification';
import SubscriptionNotification from './subscription-notification';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { useState } from 'react';
import { Typography } from '@mui/material';


type Props = {
    itemId: string;
    index: number;
}

export default function NotificationRow({
    itemId,
    index,
}: Props) {
    const notification = useAppSelector((state) => notificationsSelectOneObjectByQuery(state.DBNotificationsReducer, {
        _id: itemId,
        deleted: false
    }))

    const [seen, setSeen] = useState<boolean>(notification.seen ?? false)

    const isExtraDataNotification = ['new extra data', 'updated extra data', 'deleted extra data'].includes(notification?.notificationUpdateType)

    const NotificationData = () => {
        if ([
            'new extra data', 'updated extra data', 'deleted extra data', 'extra data reaction'
        ].includes(notification?.notificationUpdateType) === true) {
            return (<ExtraDataNotification notificationId={notification._id} />)
        }
        if (
            [
                'new task', 'delete task', 'restore task',
            ]
                .includes(notification?.notificationUpdateType) === true) {
            return (<NewDeleteRestoreTaskNotification notificationId={notification._id} />)
        }
        if (
            ['task name', 'board name', 'group name', 'column name']
                .includes(notification?.notificationUpdateType) === true) {
            return (<RenameNotification notificationId={notification._id} />)
        }
        if (
            ['person-picker']
                .includes(notification?.notificationUpdateType) === true) {
            return (<PersonColumnNotification notificationId={notification._id} />)
        }
        if (
            ['subscription']
                .includes(notification?.notificationUpdateType) === true) {
            return (<SubscriptionNotification notificationId={notification._id} />)
        }
    }

    return (
        <Stack direction='row' key={`${index}_notifications_${itemId}`} gap={1} className={classes.notification_row_wrapper} sx={{
            background: 'var(--default-background) !important',
            margin: '10px 5px 10px 3px',
            boxShadow: '-1px 2px 0px 2px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            width: 'calc(100% - 50px)',
            position: 'relative',
            borderLeft: seen ? 'none' : '3px solid #0088cc',
        }}>
            {!seen && (
                <Box sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    minWidth: '16px',
                    height: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    backgroundColor: '#0088cc',
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    padding: '0 4px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                }}>
                    <Typography sx={{
                        fontSize: '10px',
                        lineHeight: 1,
                        color: 'var(--default-background)',
                    }}>NEW</Typography>
                </Box>
            )}
            <Box sx={{ width: '10%', }}>
                <PersonAvatar
                    size={40}
                    email={notification?.triggerUser?.email}
                    profileImg={notification?.triggerUser?.profileImg}
                    lastName={notification?.triggerUser?.lastName}
                    firstName={notification?.triggerUser?.firstName}
                    toolTip={true} />
            </Box>
            <Stack sx={{ py: '15px', gap: '8px', width: '90%', }}>
                {NotificationData()}
                <RowStack>
                    <NotificationCreation notificationId={notification._id} />
                    &nbsp;<Circle sx={{ fontSize: '7px', color: 'rgb(0, 55, 80)' }} />&nbsp;
                    <p className={classes.board_name_label}>{notification?.boardName}</p>
                </RowStack>
            </Stack>
        </Stack>
    )
}



export const NotificationStack = ({ children, ...rest }) => {
    return (
        <Stack sx={{ backgroundColor: 'transparent !important' }} direction="row" flexWrap="wrap" alignItems={"center"}>{children}</Stack>
    );
};