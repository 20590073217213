import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { 
  CreditPackage, 
  fetchCreditPackagesThunk, 
  purchaseCreditPackageThunk 
} from "./CreditPurchaseThunk";
import { CommonRootState } from "@common/types/common-root-state-type";

// Define the state interface and export it
export interface CreditPurchaseState {
  isLoading: boolean;
  error: string | null;
  creditPackages: CreditPackage[];
  selectedPackageId: string | null;
  checkoutUrl: string | null;
}

// Define the initial state
const initialState: CreditPurchaseState = {
  isLoading: false,
  error: null,
  creditPackages: [],
  selectedPackageId: null,
  checkoutUrl: null
};

// Create the slice
const creditPurchaseSlice = createSlice({
  name: "creditPurchase",
  initialState,
  reducers: {
    setSelectedPackage: (state, action: PayloadAction<string>) => {
      state.selectedPackageId = action.payload;
    },
    clearCheckoutUrl: (state) => {
      state.checkoutUrl = null;
    },
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    // Handle fetchCreditPackagesThunk
    builder.addCase(fetchCreditPackagesThunk.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchCreditPackagesThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.creditPackages = action.payload;
      
      // Check if we have any packages
      if (action.payload.length === 0) {
        state.error = 'No credit packages are currently available. Please try again later or contact support.';
        return;
      }
      
      // Set default selected package - look for the package with 200 credits (typically the "medium" tier)
      // or fall back to the first package if that's not found
      if (action.payload.length > 0) {
        // Find a package with 200 credits (medium tier)
        const mediumPackage = action.payload.find(pkg => pkg.credits === 200);
        
        // If no 200-credit package, check for a package marked as "popular"
        const popularPackage = !mediumPackage ? action.payload.find(pkg => pkg.popular) : null;
        
        // Select appropriate pricing tier based on monthly billing cycle
        if (mediumPackage && mediumPackage.monthly) {
          state.selectedPackageId = mediumPackage.monthly.id;
        } else if (popularPackage && popularPackage.monthly) {
          state.selectedPackageId = popularPackage.monthly.id;
        } else if (action.payload[0].monthly) {
          // Final fallback - first package in the list
          state.selectedPackageId = action.payload[0].monthly.id;
        }
      }
    });
    builder.addCase(fetchCreditPackagesThunk.rejected, (state, action) => {
      state.isLoading = false;
      // Create more specific error messages for common issues
      if (action.payload && typeof action.payload === 'string') {
        if (action.payload.includes('401')) {
          state.error = 'Authentication error. Please log in again to continue.';
        } else if (action.payload.includes('404')) {
          state.error = 'The credit packages API endpoint was not found. Please contact support.';
        } else if (action.payload.includes('500')) {
          state.error = 'Server error while retrieving credit packages. Please try again later.';
        } else {
          state.error = action.payload;
        }
      } else {
        state.error = 'Failed to fetch credit packages. Please check your network connection.';
      }
    });

    // Handle purchaseCreditPackageThunk
    builder.addCase(purchaseCreditPackageThunk.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.checkoutUrl = null;
    });
    builder.addCase(purchaseCreditPackageThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.checkoutUrl = action.payload.checkoutUrl;
    });
    builder.addCase(purchaseCreditPackageThunk.rejected, (state, action) => {
      state.isLoading = false;
      // Create more specific error messages for common issues
      if (action.payload && typeof action.payload === 'string') {
        if (action.payload.includes('401')) {
          state.error = 'Authentication error. Please log in again to continue.';
        } else if (action.payload.includes('404')) {
          state.error = 'The checkout API endpoint was not found. Please contact support.';
        } else if (action.payload.includes('500')) {
          state.error = 'Server error while initiating checkout. Please try again later.';
        } else if (action.payload.includes('No checkout URL')) {
          state.error = 'Unable to generate checkout URL. Please contact support.';
        } else {
          state.error = action.payload;
        }
      } else {
        state.error = 'Failed to initiate checkout. Please try again later.';
      }
    });
  }
});

// Export actions
export const { setSelectedPackage, clearCheckoutUrl, clearError } = creditPurchaseSlice.actions;

// Helper function to get the credit purchase state safely
const getCreditPurchaseState = (state: any): CreditPurchaseState => {
  return state.creditPurchase || initialState;
};

// Export selectors using the helper function
export const selectCreditPackages = (state: CommonRootState) => 
  getCreditPurchaseState(state).creditPackages || [];

export const selectSelectedPackageId = (state: CommonRootState) => 
  getCreditPurchaseState(state).selectedPackageId;

export const selectIsLoading = (state: CommonRootState) => 
  getCreditPurchaseState(state).isLoading;

export const selectError = (state: CommonRootState) => 
  getCreditPurchaseState(state).error;

export const selectCheckoutUrl = (state: CommonRootState) => 
  getCreditPurchaseState(state).checkoutUrl;

// Export reducer
export default creditPurchaseSlice.reducer; 