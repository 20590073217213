import React, { useState, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { 
  FileText, 
  Loader2,
  Settings,
  Sliders
} from "lucide-react";
import { useAppDispatch, useAppSelector } from '@/common/reducers/hooks/store.hook';
import { KnowledgeBase } from '@/common/models/knowledge-base';
import { DBKnowledgeBaseThunks, knowledgeBaseSelectByQuery } from '@/common/reducers/DBServiceThunks';
import { ToolSettingsComponentProps } from './tool-settings-dialog';

const KnowledgeBaseSettings: React.FC<ToolSettingsComponentProps> = ({
  initialSettings = {},
  onSaveSettings
}) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState('general');
  
  // Knowledge base specific settings
  const [maxResults, setMaxResults] = useState(5);
  const [similarityThreshold, setSimilarityThreshold] = useState(0.7);
  const [knowledgeBaseIds, setknowledgeBaseIds] = useState<string[]>([]);
  
  // Get knowledge bases
  const knowledgeBases = useAppSelector(state => 
    knowledgeBaseSelectByQuery(state.DBKnowledgeBaseReducer, {
      status: 'active'
    })
  );
  
  // Get loading state
  const isLoadingKnowledgeBases = useAppSelector(state =>
    state.DBKnowledgeBaseReducer?.itemsLoading ?? false
  );
  
  // Fetch knowledge bases on mount
  useEffect(() => {
    dispatch(DBKnowledgeBaseThunks.find({}));
  }, [dispatch]);

  // Load initial settings
  useEffect(() => {
    // Set default values first
    setMaxResults(5);
    setSimilarityThreshold(0.7);
    setknowledgeBaseIds([]);
    
    // Override with initialSettings if available
    if (initialSettings) {
      if (initialSettings.maxResults !== undefined) {
        setMaxResults(initialSettings.maxResults);
      }
      if (initialSettings.similarityThreshold !== undefined) {
        setSimilarityThreshold(initialSettings.similarityThreshold);
      }
      if (initialSettings.knowledgeBaseIds) {
        // Ensure knowledgeBaseIds is always treated as an array
        const ids = Array.isArray(initialSettings.knowledgeBaseIds) 
          ? initialSettings.knowledgeBaseIds 
          : [initialSettings.knowledgeBaseIds];
        
        console.log('Loading initial knowledgeBaseIds:', ids);
        setknowledgeBaseIds(ids);
      }
    }
  }, [initialSettings]);

  // Toggle knowledge base selection
  const toggleKnowledgeBase = (id: string) => {
    // Update state with the new IDs
    const newIds = knowledgeBaseIds.includes(id) 
      ? knowledgeBaseIds.filter(kb => kb !== id) 
      : [...knowledgeBaseIds, id];
    
    console.log('Toggling knowledge base:', id);
    console.log('Previous knowledgeBaseIds:', knowledgeBaseIds);
    console.log('New knowledgeBaseIds:', newIds);
    
    // Update the state
    setknowledgeBaseIds(newIds);
    
    // Call onSaveSettings with the updated settings
    if (onSaveSettings) {
      const updatedSettings = {
        maxResults,
        similarityThreshold,
        knowledgeBaseIds: newIds
      };
      
      console.log('Calling onSaveSettings directly after toggle with:', updatedSettings);
      onSaveSettings(updatedSettings);
    }
  };

  // Listen for save event from parent
  useEffect(() => {
    const handleSaveEvent = () => {
      if (onSaveSettings) {
        console.log('KnowledgeBaseSettings: Save event triggered');
        console.log('KnowledgeBaseSettings: Current state:', {
          maxResults,
          similarityThreshold,
          knowledgeBaseIds
        });
        
        onSaveSettings({
          maxResults,
          similarityThreshold,
          knowledgeBaseIds: [...knowledgeBaseIds] // Create a new array to ensure it's not mutated
        });
      } else {
        console.warn('KnowledgeBaseSettings: Save event triggered but onSaveSettings is not available');
      }
    };

    console.log('KnowledgeBaseSettings: Adding save-tool-settings event listener');
    document.addEventListener('save-tool-settings', handleSaveEvent);
    
    return () => {
      console.log('KnowledgeBaseSettings: Removing save-tool-settings event listener');
      document.removeEventListener('save-tool-settings', handleSaveEvent);
    };
  }, [maxResults, similarityThreshold, knowledgeBaseIds, onSaveSettings]);

  // Debug log when knowledgeBaseIds changes
  useEffect(() => {
    console.log('Current knowledgeBaseIds state:', knowledgeBaseIds);
  }, [knowledgeBaseIds]);

  return (
    <div>
      <div className="mb-6">
        <div className="flex border-b">
          <button 
            className={`px-4 py-2 ${activeTab === 'general' ? 'text-primary border-b-2 border-primary font-medium' : 'text-muted-foreground'}`}
            onClick={() => setActiveTab('general')}
          >
            <div className="flex items-center gap-2">
              <Settings className="h-4 w-4" />
              General
            </div>
          </button>
          <button 
            className={`px-4 py-2 ${activeTab === 'advanced' ? 'text-primary border-b-2 border-primary font-medium' : 'text-muted-foreground'}`}
            onClick={() => setActiveTab('advanced')}
          >
            <div className="flex items-center gap-2">
              <Sliders className="h-4 w-4" />
              Advanced
            </div>
          </button>
        </div>
      </div>

      {activeTab === 'general' && (
        <div className="space-y-4 p-4 border rounded-lg bg-card">
          <div>
            <h3 className="text-base font-medium my-2">Knowledge Bases</h3>
            <p className="text-sm text-muted-foreground mb-3">Select knowledge bases to use with this tool</p>
            
            <div className="space-y-3">
              {isLoadingKnowledgeBases ? (
                <div className="flex items-center justify-center p-4">
                  <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
                </div>
              ) : knowledgeBases.length === 0 ? (
                <p className="text-sm text-muted-foreground text-center py-4">
                  No knowledge bases found. Create one to enhance your agent.
                </p>
              ) : knowledgeBases.map((kb: KnowledgeBase) => (
                <div
                  key={kb._id}
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleKnowledgeBase(kb._id)
                  }}
                  className={`p-3 rounded-md border cursor-pointer transition-colors ${
                    knowledgeBaseIds.includes(kb._id) 
                      ? 'bg-green-50 border-green-200 hover:bg-green-100' 
                      : 'bg-white border-border hover:bg-gray-50'
                  }`}
                >
                  <div className="flex justify-between items-start">
                    <div className="flex gap-3">
                      <div className={`p-1 rounded ${knowledgeBaseIds.includes(kb._id) ? 'bg-green-100' : 'bg-gray-100'}`}>
                        <FileText className={`h-4 w-4 ${knowledgeBaseIds.includes(kb._id) ? 'text-green-700' : 'text-gray-500'}`} />
                      </div>
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="text-sm font-medium">{kb.name}</span>
                          <span className={`text-xs px-2 py-0.5 rounded ${kb.status === 'active' ? 'bg-green-50 text-green-700' : 'bg-gray-50 text-gray-700'}`}>
                            {kb.status}
                          </span>
                        </div>
                        <p className="text-xs text-muted-foreground">{kb.description}</p>
                        <p className="text-xs text-gray-400">{kb.documentCount} documents</p>
                      </div>
                    </div>
                    <Switch 
                      checked={knowledgeBaseIds.includes(kb._id)} 
                      onCheckedChange={() => toggleKnowledgeBase(kb._id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      
      {activeTab === 'advanced' && (
        <div className="space-y-4 p-4 border rounded-lg bg-card">
          <div>
            <Label htmlFor="maxResults">Max Results</Label>
            <Input
              id="maxResults"
              type="number"
              value={maxResults}
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                setMaxResults(newValue);
                
                // Call onSaveSettings directly after state update
                if (onSaveSettings) {
                  setTimeout(() => {
                    onSaveSettings({
                      maxResults: newValue,
                      similarityThreshold,
                      knowledgeBaseIds
                    });
                  }, 0);
                }
              }}
              className="mt-1"
              min={1}
              max={20}
            />
            <p className="text-xs text-muted-foreground mt-1">Maximum number of results to return</p>
          </div>
          
          <div>
            <Label htmlFor="similarityThreshold">Similarity Threshold</Label>
            <Input
              id="similarityThreshold"
              type="number"
              step={0.1}
              min={0}
              max={1}
              value={similarityThreshold}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                setSimilarityThreshold(newValue);
                
                // Call onSaveSettings directly after state update
                if (onSaveSettings) {
                  setTimeout(() => {
                    onSaveSettings({
                      maxResults,
                      similarityThreshold: newValue,
                      knowledgeBaseIds
                    });
                  }, 0);
                }
              }}
              className="mt-1"
            />
            <p className="text-xs text-muted-foreground mt-1">Minimum similarity score (0-1) for results</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowledgeBaseSettings; 