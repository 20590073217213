import { MessageLog } from '@common-models/message-log';
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Typography, useTheme } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useMemo } from 'react';
import ContactAvatar from '../bulk-dashboard/contact-table/contact-avatar';

interface CampaignLogStatusHistoryDialogProps {
    open: boolean;
    onClose: () => void;
    messageLog: MessageLog;
}

interface StatusItem {
    status: string;
    timestamp?: string | number;
    color: string;
    users?: Array<{
        id: {
            _serialized: string;
        };
        t: number;
    }>;
    order: number;
}

const STATUS_ORDER = {
    Created: 1,
    Delivered: 2,
    Read: 3,
    Played: 3,
};

export default function CampaignLogStatusHistoryDialog({ open, onClose, messageLog }: CampaignLogStatusHistoryDialogProps) {
    const theme = useTheme();

    const statusHistory = useMemo(() => {
        if (!messageLog) return [];

        const { ack, ackData, message } = messageLog;
        const history: StatusItem[] = [
            {
                status: 'Created',
                timestamp: message?.createdAt,
                color: 'grey',
                order: STATUS_ORDER.Created
            },
        ];

        if (ackData) {
            if (ackData.delivery && Array.isArray(ackData.delivery)) {
                history.push({
                    status: 'Delivered',
                    color: 'info',
                    users: ackData.delivery,
                    order: STATUS_ORDER.Delivered
                });
            }

            // Only show Read if there are read records and no played records
            if (ackData.read?.length && !ackData.played?.length) {
                history.push({
                    status: 'Read',
                    color: 'success',
                    users: ackData.read,
                    order: STATUS_ORDER.Read
                });
            }

            // Only show Played if there are played records
            if (ackData.played?.length) {
                history.push({
                    status: 'Played',
                    color: 'success',
                    users: ackData.played,
                    order: STATUS_ORDER.Played
                });
            }
        }

        return history.sort((a, b) => a.order - b.order);
    }, [messageLog]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: theme.shadows[10]
                }
            }}
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2.5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    bgcolor: theme.palette.background.default
                }}
            >
                <Typography variant="h6" component="h2" sx={{ fontWeight: 600 }}>
                    Message Status History
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: theme.palette.grey[500],
                        '&:hover': {
                            bgcolor: theme.palette.action.hover
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 3 }}>

                {/* <div>
                    {JSON.stringify({ ...messageLog, message: undefined }, null, 2)}
                </div> */}

                <Timeline sx={{ p: 0 }}>
                    {statusHistory.map((item, index) => (
                        <TimelineItem
                            key={index}
                            sx={{
                                '&:before': {
                                    flex: 0.2
                                }
                            }}
                        >
                            <TimelineSeparator>
                                <TimelineDot
                                    color={item.color as any}
                                    sx={{
                                        boxShadow: theme.shadows[3],
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                />
                                {index < statusHistory.length - 1 && (
                                    <TimelineConnector sx={{
                                        bgcolor: theme.palette.divider,
                                        height: item.users ? '100%' : 'auto'
                                    }} />
                                )}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: 0.6, px: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2 }}>
                                    <Typography 
                                        variant="subtitle1" 
                                        component="div"
                                        sx={{
                                            fontWeight: 600,
                                            color: theme.palette.text.primary
                                        }}
                                    >
                                        {item.status}
                                    </Typography>
                                    {item.status === 'Created' && item.timestamp && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: theme.palette.text.secondary,
                                                fontWeight: 500
                                            }}
                                        >
                                            {moment(item.timestamp).format('DD/MM/YYYY HH:mm')}
                                        </Typography>
                                    )}
                                </Box>
                                {item.users && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1.5,
                                            mt: 1.5,
                                            pl: 0.5
                                        }}
                                    >
                                        {item.users.map((user, userIndex) => (
                                            <Box
                                                key={userIndex}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    p: 1,
                                                    borderRadius: 1,
                                                    bgcolor: theme.palette.action.hover,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        bgcolor: theme.palette.action.selected
                                                    }
                                                }}
                                            >
                                                <ContactAvatar
                                                    size={32}
                                                    textSize={14}
                                                    contactId={user.id._serialized}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: theme.palette.text.secondary,
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    {moment(user.t * 1000).format('DD/MM/YYYY HH:mm')}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </DialogContent>
        </Dialog>
    );
}
