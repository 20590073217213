import SendIcon from '@mui/icons-material/Send';
import { Alert, Box, IconButton, Paper, Snackbar, TextField, Typography } from '@mui/material';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { sendMessage } from './chat-thunks';
import { StreamSelector } from './stream-selector';
import {
    ChatContainer,
    InputContainer,
    MarkdownContainer,
    MessageBubble,
    MessagesContainer
} from './styled-components';
import { ChatHistoryEntry, ChatMessage, ChatRole } from './types';

export default function ChatView() {
    const [messages, setMessages] = React.useState<ChatMessage[]>([]);
    const [input, setInput] = React.useState('');
    const [error, setError] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const messagesEndRef = React.useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    React.useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSend = async () => {
        if (!input.trim()) return;

        const query = input;
        setInput('');

        // Prepare chat history with proper types, including both queries and responses
        const chatHistory = messages.reduce((history: ChatHistoryEntry[], msg) => {
            // Add user message
            if (msg.query) {
                history.push({
                    role: 'user' as ChatRole,
                    content: msg.query
                });
            }
            // Add assistant response if available
            if (msg.response?.finalAnswer) {
                history.push({
                    role: 'assistant' as ChatRole,
                    content: msg.response.finalAnswer
                });
            }
            return history;
        }, [] as ChatHistoryEntry[]);

        await sendMessage(query, {
            onMessageUpdate: setMessages,
            onError: setError,
            onLoadingChange: setIsLoading,
            history: chatHistory
        });
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    };

    const renderMessage = (message: ChatMessage, index: number) => {
        return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                <Box sx={{ alignSelf: 'flex-end', maxWidth: '70%' }}>
                    <MessageBubble isUser={true}>
                        <Typography>{message.query}</Typography>
                    </MessageBubble>
                </Box>

                {message.response && (
                    <Box sx={{ alignSelf: 'flex-start', width: '100%', maxWidth: '70%' }}>
                        {message.isStreaming ? (
                            <StreamSelector 
                                text={message.response.finalAnswer || ''} 
                                thinking={message.response.thinking}
                            />
                        ) : (
                            <MessageBubble isUser={false}>
                                <MarkdownContainer>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {message.response.finalAnswer || ''}
                                    </ReactMarkdown>
                                </MarkdownContainer>
                            </MessageBubble>
                        )}
                    </Box>
                )}

                {message.error && (
                    <Box sx={{ alignSelf: 'flex-start', width: '100%' }}>
                        <Alert severity="error" sx={{ mt: 1 }}>
                            {message.error}
                        </Alert>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Box sx={{ p: 3, height: '100%', display: 'flex', justifyContent: 'center' }}>
            <Paper elevation={3} sx={{ width: '100%', maxWidth: '1200px', overflow: 'hidden' }}>
                <ChatContainer>
                    <MessagesContainer>
                        {messages.map((message, index) => renderMessage(message, index))}
                        <div ref={messagesEndRef} />
                    </MessagesContainer>

                    <InputContainer>
                        <TextField
                            fullWidth
                            multiline
                            maxRows={4}
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="Type your message..."
                            disabled={isLoading}
                        />
                        <IconButton 
                            onClick={handleSend} 
                            disabled={isLoading || !input.trim()}
                            color="primary"
                        >
                            <SendIcon />
                        </IconButton>
                    </InputContainer>
                </ChatContainer>
            </Paper>

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError(null)}
            >
                <Alert severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
} 