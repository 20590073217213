import BTbotbutton from '@common-components/bt-chatbot/bot-components/bt-bot-button';
import RowStack from '@common-components/common/row-stack/RowStack';
import { WhatsappIdCell } from '@common-components/tasks/columns-cells/whatsapp-id-cell/whatsapp-id-cell';
import { SearchBox } from '@common-components/tasks/person-picker/search-box';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { TriggerType } from '@common/types/trigger.types';
import WaContactLazyLoadingVirtuoso from '@extension/context/content/components/add-scheduled-message-button/wa-contact-lazy-loading-virtuoso';
import { getChatsAndContactsThunk } from '@extension/context/content/thunks/WhatsappThunks';
import { Add, Close } from '@mui/icons-material';
import { FormControlLabel, IconButton, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import classes from "./trigger-node.module.scss";


interface Contact {
    id: {
        _serialized: string;
    };
}

interface TriggerState {
    type: 'trigger';
    triggerType: TriggerType;
    contacts: string[]; // Array of contact IDs
}

interface Props {
    onClose: () => void;
    id?: string;
}

export default function AddTriggerDialog({ onClose, id }: Props) {
    const dispatch = useAppDispatch();

    const data = useAppSelector(state => getNodeDataByNodeId(state, id)) as TriggerState;
    const [localTriggerState, setLocalTriggerState] = useState<TriggerState>(data ?? {
        type: 'trigger',
        triggerType: 'blockList',
        contacts: []
    });

    const [searchTerm, setSearchTerm] = useState('');

    const allowToAdd = /^[0-9@+]+$/.test(searchTerm);

    const handleTriggerTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalTriggerState(prev => ({
            ...prev,
            triggerType: event.target.value as TriggerType
        }));
    };

    const setPhoneNumberAsContact = (phoneNumber: string) => {
        if (parseInt(phoneNumber)) {
            const contact: Contact = { id: { _serialized: `${phoneNumber}@c.us` } };
            handleContactSelect(contact);
        }
    };

    const handleContactSelect = (contact: Contact | null) => {
        if (contact?.id?._serialized) {
            setLocalTriggerState(prev => ({
                ...prev,
                contacts: [...(prev.contacts || []), contact.id._serialized]
            }));
        }
        setSearchTerm('');
    };

    const handleRemoveContact = (contactId: string) => {
        setLocalTriggerState(prev => ({
            ...prev,
            contacts: (prev.contacts || []).filter(id => id !== contactId)
        }));
    };

    const saveTriggerData = async () => {
        await dispatch(changeNodeDataThunk({
            id,
            dataToPatch: localTriggerState
        }));
        onClose();
    };

    // Load contacts when component mounts
    useEffect(() => {
        dispatch(getChatsAndContactsThunk({ shouldForceUpdate: true }));
    }, [dispatch]);

    return (
        <>
            <RowStack className={classes.add_trigger_dialog_header}>
                <span>Configure Trigger</span>
                <IconButton onClick={onClose}><Close /></IconButton>
            </RowStack>

            <Stack className={classes.add_trigger_dialog_body}>
                <div className={classes.section_title}>Contact List Type</div>
                <RadioGroup
                    value={localTriggerState.triggerType}
                    onChange={handleTriggerTypeChange}
                    sx={{ marginBottom: 2 }}
                >
                    <FormControlLabel
                        value="blockList"
                        control={<Radio />}
                        label={
                            <Typography variant="body2">
                                Block List - Listed contacts cannot trigger the flow
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value="allowList"
                        control={<Radio />}
                        label={
                            <Typography variant="body2">
                                Allow List - Only listed contacts can trigger the flow
                            </Typography>
                        }
                    />
                </RadioGroup>

                <div className={classes.section_title}>Contact List</div>

                <Stack sx={{ alignItems: 'center' }}>
                    <Stack direction="row" justifyContent="center" sx={{ marginTop: 2 }}>
                        <SearchBox
                            variant="outlined"
                            autoComplete="off"
                            value={searchTerm}
                            onChange={(e) => {
                                if (!e.target.value?.includes('+')) {
                                    setSearchTerm(e.target.value);
                                }
                            }}
                            placeholder="Enter name or number"
                            InputProps={{
                                endAdornment: allowToAdd && (
                                    <IconButton onClick={() => setPhoneNumberAsContact(searchTerm)}>
                                        <Add sx={{ fontSize: 16 }} />
                                    </IconButton>
                                ),
                            }}
                            fullWidth
                        />
                    </Stack>
                    {searchTerm !== '' && (
                        <div
                            className="nowheel"
                            style={{
                                zIndex: 1000,
                                backgroundColor: 'var(--default-background)',
                                marginTop: '8px',
                                width: '310px'
                            }}
                        >
                            <WaContactLazyLoadingVirtuoso
                                onClick={handleContactSelect}
                                height={175}
                                searchFilter={searchTerm}
                            />
                        </div>
                    )}
                </Stack>

                <Stack spacing={1} sx={{ alignItems: 'center' }}>
                    {localTriggerState.contacts?.map((contactId) => (
                        <RowStack key={contactId} sx={{ height: '40px' }}>
                            <WhatsappIdCell
                                cellValue={contactId}
                                taskId={id?.substring(0, 24) || ''}
                                columnId={id?.substring(0, 24) || ''}
                                openPicker={() => { }}
                                onUpdate={() => { }}
                                togglePicker={() => { }}
                                closePicker={() => { }}
                                withoutPlus={true}
                            />
                            <IconButton onClick={() => handleRemoveContact(contactId)}>
                                <Close />
                            </IconButton>
                        </RowStack>
                    ))}
                </Stack>

                <RowStack sx={{ justifyContent: 'flex-end', marginTop: '30px' }}>
                    <BTbotbutton
                        styleType="empty"
                        onClick={onClose}
                        sx={{ marginLeft: '13px' }}
                    >
                        Cancel
                    </BTbotbutton>
                    <BTbotbutton
                        styleType="full"
                        onClick={saveTriggerData}
                        sx={{ marginLeft: '13px' }}
                    >
                        Save
                    </BTbotbutton>
                </RowStack>
            </Stack>
        </>
    );
} 