import RowStack from '@common-components/common/row-stack/RowStack';
import { handleTimeAndDateTabOnChangeEventThunk } from '@common-reducers/CampaignThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { Radio } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import { useRef } from 'react';
import CampaignTabHeader from '../campaign-tab-header';
import BlueticksDatePicker from './bt-date-picker';
import BlueticksTimePicker from './bt-time-picker';



export default function BulkSetTimeAndDate() {
  const dispatch = useAppDispatch()
  const newCampaign = useAppSelector(state => state.CampaignReducer.newCampaign)

  const dateAsMomentRef = useRef(moment());

  const isTimeAndDateDefined = !!newCampaign?.schedule?.dueDate

  const switchCampaignScheduleTimingToNow = () => {
    const now = moment();

    // Get local YYYY-MM-DD
    const localDate = now.toISOString();

    dispatch(handleTimeAndDateTabOnChangeEventThunk({
      changeType: "setScheduleDueDateToNow",
      changeObject: localDate,
    }))
    dateAsMomentRef.current = moment(localDate);
  }

  const handleTimingRadioButtonChange = (type: string) => {
    if (type === 'edit') {
      dispatch(handleTimeAndDateTabOnChangeEventThunk({
        changeType: "setScheduleDueDateToNotNow",
        changeObject: { dueDate: moment().toISOString(), isNow: false, },
      }))
    }
    if (type === 'now') {
      switchCampaignScheduleTimingToNow()
    }
  }


  return (
    <Stack sx={{
      alignItems: 'center',
    }}>
      <CampaignTabHeader
        title='When to send?'
        subTitle='Launch campaign now or schedule for later?' />

      <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
        <Stack direction='row' sx={{
          alignItems: 'center',
          width: '300px',
          height: '76px',
          borderRadius: '16px',
          border: '2px solid',
          borderColor: newCampaign?.schedule?.isNow ? '#35BE5F80' : 'var(--campaign-time-and-date-disabled)',
          p: '0px 10px',
          marginBottom: '10px',
        }}>
          <Radio
            checked={newCampaign?.schedule?.isNow}
            onChange={() => handleTimingRadioButtonChange('now')}
            sx={{
              color: 'var(--campaign-time-and-date-disabled)',
              '&.Mui-checked': {
                color: '#35be5f',
              },
            }}
          />
          <div style={{
            fontSize: '22px',
            paddingBottom: "2px",
            color: newCampaign?.schedule?.isNow ? "unset" : 'var(--campaign-time-and-date-disabled)',
          }}>Now</div>
        </Stack>
        <Stack direction='row' sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '300px',
          height: '76px',
          borderRadius: '16px',
          border: '2px solid',
          borderColor: !newCampaign?.schedule?.isNow ? '#35BE5F80' : 'var(--campaign-time-and-date-disabled)',
          p: '0px 10px',
          marginBottom: '10px',
        }}>
          <RowStack>
            <Radio
              checked={!newCampaign?.schedule?.isNow}
              onChange={() => handleTimingRadioButtonChange('edit')}
              sx={{
                color: 'var(--campaign-time-and-date-disabled)',
                '&.Mui-checked': {
                  color: '#35be5f',
                },
              }}
            />
            <BlueticksDatePicker />
          </RowStack>
          <BlueticksTimePicker />
          {/* {patternPicker()} // this is not yet supported */}
        </Stack>
      </Stack>
    </Stack>
  )
}

/*

DONT FORGET WE HAVE THESE COMPONENTS READT FOR ADVANCED TIME SETTINGS
  return (
              <CampaignTimingSpecificHoursDialog
            handleTimeAndDateTabOnChangeEvent={handleTimeAndDateTabOnChangeEvent}
          />
          <CampaignTimingAdvancedOptions
            handleTimeAndDateTabOnChangeEvent={handleTimeAndDateTabOnChangeEvent}
          />
  )
*/













// this is not yet supported  PLEASE DO NOT DELETE - THIS IS THE RRRULE FOR RECURING CAMPAIGNS


// this is not yet supported  PLEASE DO NOT DELETE
// const [anchorElPattern, setAnchorElPattern] = useState(null);
// const [selectedMonthlyRule, setSelectedMonthlyRule] = useState(0);
// const [ends, setEnds] = useState<"never" | "on" | "after">("never");
// const [endsDate, setEndsDate] = useState<moment.Moment>(moment());
// const [endsOccurrences, setEndsOccurrences] = useState<number>(0);
// const [ruleOptions, setRuleOptions] = useState<CherryPickOptions>(initialRuleOptions(moment()));


// const handlePatternClick = (e) => {
//   e.stopPropagation()
//   setAnchorElPattern(anchorElPattern ? null : e.currentTarget);
// };


// this is not yet supported  PLEASE DO NOT DELETE
// const handleIntervalChange = (event: React.ChangeEvent<any>) => {
//   setRuleOptions({
//     ...ruleOptions,
//     interval: event.target.value as number,
//   });
// };
// const handleFrequencyChange = (event: React.ChangeEvent<any>) => {
//   setRuleOptions({
//     ...ruleOptions,
//     freq: event.target.value as Frequency,
//   });
// };
// const handleByweekdayChange = (_, value: number[]) => {
//   setRuleOptions({
//     ...ruleOptions,
//     byweekday: value,
//   });
// };




// this is not yet supported  PLEASE DO NOT DELETE
// const lastValidTime = useRef<moment.Moment>();


// this is not yet supported  PLEASE DO NOT DELETE
// let dueDate = useMemo(() => {
//   if (dueTime) return dueTime;
//   else if (schedule?.dueDate && moment(schedule?.dueDate).isValid())
//     return moment(schedule?.dueDate);

//   return lastValidTime.current;
// }, [dueTime, schedule?.dueDate]);


// this is not yet supported  PLEASE DO NOT DELETE
// let monthlyRules = useMemo(() => {
//   if (ruleOptions.freq === Frequency.MONTHLY) {
//     const options = {
//       freq: ruleOptions.freq,
//       interval: ruleOptions.interval,
//     };

//     const bymonthdayRule = new RRule({
//       ...options,
//       bymonthday: dueDate.date(),
//     });
//     const byweekdayRule = new RRule({
//       ...options,
//       byweekday: getByWeekDay(dueDate),
//     });

//     return [bymonthdayRule, byweekdayRule];
//   }
// }, [dueDate, ruleOptions.freq, ruleOptions.interval]);



// this is not yet supported  PLEASE DO NOT DELETE
// const freqToRecurrenceUnits = (freq: Frequency) => {
//   const recUnitsSortedByFreqAsIndex: RecurrenceUnit[] = ["year", "month", "week", "day", "hour", "minute"]
//   return recUnitsSortedByFreqAsIndex[freq]
// }


// this is not yet supported  PLEASE DO NOT DELETE
// const handleclickSaveOrCancel = (e, type: string) => {
//   if (type === 'save') {

//     const getCount = () => { return endsOccurrences ?? undefined }

//     const rrule = new RRule({
//       freq: ruleOptions?.freq ?? undefined,
//       dtstart: new Date(schedule?.dueDate ?? undefined),
//       count: getCount(),
//       interval: ruleOptions?.interval ?? undefined,
//       byweekday: ruleOptions?.byweekday ?? undefined
//     })

//     setSchedule({
//       ...schedule,
//       isTimeValid: true,
//       isRecurring: ruleOptions?.interval && ruleOptions?.interval !== 0,
//       recurrenceQuantity: ruleOptions?.interval,
//       recurenceUnit: freqToRecurrenceUnits(ruleOptions?.freq),
//       cancelIfReceived: false,
//       rruleset: rrule.toString(),
//     })
//   }

//   handlePatternClick(e)
// }


// this is not yet supported  PLEASE DO NOT DELETE
// const saveOrCancel = () => {
//   return (
//     <Stack direction='row' sx={{
//       justifyContent: 'flex-end',
//       alignItems: 'center',
//       alignSelf: 'flex-end',
//       marginTop: '10px',
//     }}>
//       <BTButton
//         onClick={(e) => handleclickSaveOrCancel(e, "cancel")}
//         sx={{
//           endIcon: { margin: 0 },
//           marginRight: '10px',
//           padding: "8px",
//           width: "80px",
//           borderRadius: "8px",
//           background: "linear-gradient(44.54deg, #42d872 14.2%, #97e89c 85.23%)",
//         }}
//       >
//         <CancelOutlined style={{ marginInlineEnd: '5px', height: '14px', width: '14px' }} />
//         {"Cancel"}
//       </BTButton>
//       <BTButton
//         onClick={(e) => handleclickSaveOrCancel(e, "save")}
//         sx={{
//           endIcon: { margin: 0 },

//           padding: "8px",
//           width: "80px",
//           borderRadius: "8px",
//           background: "linear-gradient(44.54deg, #42d872 14.2%, #97e89c 85.23%)",
//         }}
//       >
//         <SaveOutlined style={{ marginInlineEnd: '5px', height: '14px', width: '14px' }} />
//         {"Save"}
//       </BTButton>
//     </Stack>
//   )
// }

// this is not yet supported  PLEASE DO NOT DELETE
// const patternPicker = () => {
//   const patternString = !!schedule?.rruleset ? RRule.fromString(schedule.rruleset).toText() : 'No pattern specified'
//   return (
//     <Box sx={{ display: 'flex' }}>
//       <>
//         <Button onClick={handlePatternClick} style={{ textTransform: 'capitalize' }}>
//           {patternString}
//         </Button>
//         <Popover open={Boolean(anchorElPattern)} anchorEl={anchorElPattern} onClose={handlePatternClick} >
//           <Stack direction='column' sx={{ m: '15px', }}>
//             <TimeAndDatePattern
//               ruleOptions={ruleOptions}
//               handleIntervalChange={handleIntervalChange}
//               handleFrequencyChange={handleFrequencyChange}
//               handleByweekdayChange={handleByweekdayChange}
//               selectedMonthlyRule={selectedMonthlyRule}
//               setSelectedMonthlyRule={setSelectedMonthlyRule}
//               monthlyRules={monthlyRules}
//               ends={ends}
//               setEnds={setEnds}
//               setEndsDate={setEndsDate}
//               endsDate={endsDate}
//               endsOccurrences={endsOccurrences}
//               setEndsOccurrences={setEndsOccurrences}
//             />
//             {saveOrCancel()}
//           </Stack>
//         </Popover>
//       </>

//     </Box>
//   )
// }

