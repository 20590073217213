import { setCalendarSidebarFilters } from '@common/reducers/CalendarReducer';
import { useAppDispatch, useAppSelector } from '@common/reducers/hooks/store.hook';
import { Campaign, Chat } from '@mui/icons-material';
import { Check } from 'lucide-react';
import classes from './calendar-sidebar-filters.module.scss';

export default function CalendarSidebarFilters() {
    const filters = useAppSelector(state => state.CalendarReducer.calendarSidebarFilters);
    const dispatch = useAppDispatch();

    const toggleFilter = (filter) => {
        dispatch(setCalendarSidebarFilters({
            ...filters,
            [filter]: !filters[filter]
        }));
    };

    return (
        <div className={classes['filter-options']}>
            <div className={classes['filter-header']}>Display Options</div>

            <div className={classes['filter-buttons']}>
                <button
                    className={`${classes['filter-btn']} ${filters.showMessages ? classes['filter-btn-active'] : ''}`}
                    onClick={() => toggleFilter('showMessages')}
                >
                    <span className={`${classes['checkbox']} ${filters.showMessages ? classes['checkbox-active'] : ''}`}>
                        {filters.showMessages && <Check size={10} />}
                    </span>
                    <Chat fontSize='small' sx={{ width: 15, height: 15 }} />
                    <span>Scheduled Messages</span>
                </button>

                <button
                    className={`${classes['filter-btn']} ${filters.showCampaigns ? classes['filter-btn-active'] : ''}`}
                    onClick={() => toggleFilter('showCampaigns')}
                >
                    <span className={`${classes['checkbox']} ${filters.showCampaigns ? classes['checkbox-active'] : ''}`}>
                        {filters.showCampaigns && <Check size={10} />}
                    </span>
                    <Campaign fontSize='small' sx={{ width: 20, height: 20 }} />
                    <span>Scheduled Campaigns</span>
                </button>
            </div>
        </div>
    );
}