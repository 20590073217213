import { AccessTime } from '@mui/icons-material';
import { FormControl, IconButton, Menu, MenuItem } from '@mui/material';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { handleTimeAndDateTabOnChangeEventThunk } from '@common-reducers/CampaignThunks';
import { changeTimeInISOString } from '@common-services/utils';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import moment from 'moment';
import { useEffect, useState } from 'react';



export default function BlueticksTimePicker() {
    const dispatch = useAppDispatch();
    const newCampaign = useAppSelector((state) => state.CampaignReducer.newCampaign);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTime, setCurrentTime] = useState(moment());
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSelectTime = (event) => {
        const selectedTime = event.target.getAttribute('data-time');
        const newISOTime = changeTimeInISOString(newCampaign.schedule.dueDate, selectedTime);
        dispatch(handleTimeAndDateTabOnChangeEventThunk({
            changeType: "setScheduleDueDate",
            changeObject: newISOTime,
        }));
        handleMenuClose();
    };

    const handleInputChange = (newValue) => {
        if (newValue && newValue.isValid()) {
            const formattedTime = newValue.format('HH:mm');
            dispatch(handleTimeAndDateTabOnChangeEventThunk({
                changeType: "setScheduleDueDate",
                changeObject: changeTimeInISOString(newCampaign.schedule.dueDate, formattedTime),
            }));
        }
    };

    const generateTimeOptions = () => {
        const times = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                times.push(timeString);
            }
        }
        return times;
    };

    const timeOptions = generateTimeOptions();

    const isEdit = !newCampaign?.schedule?.isNow;


    useEffect(() => {
        if (!isEdit) {
            // Calculate the seconds until the next minute
            const secondsTillNextMinute = 60 - moment().second();

            // Set a timeout to synchronize the interval with the turn of the minute
            const timeoutId = setTimeout(() => {
                setCurrentTime(moment()); // Update immediately at the minute turn
                // After the turn of the minute, set an interval to update every minute
                const intervalId = setInterval(() => {
                    setCurrentTime(moment());
                }, 60000); // updates every minute

                // Clear the interval on component unmount
                return () => clearInterval(intervalId);
            }, secondsTillNextMinute * 1000); // milliseconds till the next minute

            // Clear the timeout on component unmount
            return () => clearTimeout(timeoutId);
        }
    }, [isEdit]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimeField
                    disabled={!isEdit}
                    value={isEdit ? moment(newCampaign.schedule.dueDate) : currentTime}
                    onChange={handleInputChange}
                    format="HH:mm"
                    sx={{
                        width: '50px',
                        '& .MuiOutlinedInput-root': {
                            '& input': {
                                padding: 0,
                                borderRadius:0,
                                textAlign: 'center',
                                borderBottom:"1px solid"
                            },
                            '& fieldset': {
                                borderColor: 'transparent !important',
                                padding: 0,
                            },
                            // '&:hover fieldset': {
                            //     borderColor: 'transparent',
                            // },
                            // '&.Mui-focused fieldset': {
                            //     borderColor: 'transparent',
                            // },
                        },
                    }}
                />
            </LocalizationProvider>
            <IconButton
                disabled={!isEdit}
                onClick={handleMenuOpen}
                sx={{
                    color: isEdit ? '#35BE5F80 !important' : 'var(--campaign-time-and-date-disabled) !important',
                    position: 'relative',
                    right: '7px',
                }}>
                <AccessTime />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        height: '400px',
                        overflow: 'auto'
                    }
                }}
            >
                {timeOptions.map((time, index) => (
                    <MenuItem key={index} data-time={time} onClick={handleSelectTime}>
                        {time}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};