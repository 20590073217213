import { MoreVert } from '@mui/icons-material';
import { Checkbox, CircularProgress, FormControlLabel, IconButton, Menu, Skeleton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { bulkAudienceSelectBoardThunk, duplicateAudienceBoardForCampaignsThunk } from '@common-reducers/BoardsThunks';
import { setActiveBulkView } from '@common-reducers/CampaignReducer';
import { getIsAudienceRowChecked } from '@common-reducers/CampaignSelectors';
import { handleActiveBulkViewNavigationThunk, toggleAudienceCheckboxThunk } from '@common-reducers/CampaignThunks';
import { DBBoardsThunks, boardsSelectOneFieldByQuery } from "@common-reducers/DBServiceThunks";
import { UndoRedoAction } from '@common-reducers/UndoRedoReducer';
import { doActionThunk } from '@common-reducers/UndoRedoThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { namespace } from '@common/config/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { MdOutlineContentCopy } from 'react-icons/md';
import classes from '../bulk_item_row_menu.module.scss';


type Props = {
    index: any;
    itemId: string;
}

export default function BulkAudienceSelectRow({
    index,
    itemId,
}: Props) {
    const { t } = useTranslation(namespace);
    const dispatch = useAppDispatch()
    const boardId = useAppSelector((state) => boardsSelectOneFieldByQuery(state.DBBoardsReducer, {
        _id: itemId,
    }, '_id'))
    const boardName = useAppSelector((state) => boardsSelectOneFieldByQuery(state.DBBoardsReducer, {
        _id: itemId,
    }, 'name'))
    const taskCount = useAppSelector((state) => boardsSelectOneFieldByQuery(state.DBBoardsReducer, {
        _id: itemId,
    }, 'tasksCount'))

    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);
    const checked = useAppSelector((state) => getIsAudienceRowChecked(state, itemId))
    const [isLoadingDuplicateBoard, setIsLoadingDuplicateBoard] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);

    const numberOfContacts = taskCount;

    const triggerToggleAudienceCheckbox = (event) => {
        dispatch(toggleAudienceCheckboxThunk({
            boardId: boardId
        }))
    }


    const handleOpenMenu = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (event?) => {
        event?.stopPropagation(); // Stop event from propagating further
        setAnchorEl(null);
    };

    const handleDuplicateClick = async (e) => {
        e.stopPropagation()
        handleCloseMenu()
        setIsLoadingDuplicateBoard(true)
        const newBoardId = await dispatch(duplicateAudienceBoardForCampaignsThunk({ oldBoardId: itemId }))

        console.log('newBoardId')
        console.log(newBoardId)

        await dispatch(DBBoardsThunks.find({
            deleted: false,
            type: 'audience',
            $sort: { createdAt: -1 }
        }))

        setIsLoadingDuplicateBoard(false)
    }

    const handleDeleteClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleCloseMenu();
        setIsLoadingDuplicateBoard(true);
        
        // const action: UndoRedoAction<string[], string> = {
        //     type: 'delete-board-list',
        //     data: [boardId],
        //     deletedOrArchived: "deleted"
        // };
        
        // dispatch(doActionThunk({ action }))

        dispatch(DBBoardsThunks.patch({
            entity: {   
                _id: boardId,
                deleted: true,
            },
        }))

            .finally(() => setIsLoadingDuplicateBoard(false));
    };

    const handelEditClick = (e) => {
        e.stopPropagation()

        dispatch(setActiveBulkView(activeBulkView === "campaignManagementView" ? "campaignBoardFromManagementView" : "campaignBoardFromNewCampaignView"))
        dispatch(bulkAudienceSelectBoardThunk({ boardId: itemId }))
    }

    const handleAudienceRowClick = (e: React.MouseEvent) => {
        e.nativeEvent.stopImmediatePropagation()
        e.stopPropagation()
        if (activeBulkView === "campaignManagementView") {
            dispatch(handleActiveBulkViewNavigationThunk({ navigate: "toCampaignBoardView", boardId: itemId }))
        } else {
            triggerToggleAudienceCheckbox(boardId)
        }
    }



    return (

        // GPT:optimize, friendly, remove uncceey dom and css, and make it simpler
        <Stack direction='row' sx={{
            border: '1px solid var(--campaign-border)',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            width: '100%',
            boxSizing: 'border-box',
            overflow: 'hidden',
        }} key={index}
            onClick={(e) => handleAudienceRowClick(e)}>
            <FormControlLabel
                onClick={(e) => {
                    if (activeBulkView !== "campaignManagementView") {
                        e.stopPropagation();
                    }
                }}
                control={
                    activeBulkView !== "campaignManagementView" ? <Checkbox id={`checkbox_${boardName}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            triggerToggleAudienceCheckbox(boardId)
                        }}
                        checked={checked ?? false}
                        sx={{
                            marginInlineStart: '12px',
                            color: (checked ?? false) ? '#42d871!important' : 'grey',
                        }}
                        name={boardName}
                    /> : <Box />
                }
                // <input
                //     style={{
                //         width: '20px',
                //         height: '20px',
                //         margin: '15px 30px 15px 25px',
                //         accentColor: 'rgb(0 165 80)',
                //         display: activeBulkView === "campaignManagementView" ? 'none' : 'block',
                //     }}
                //     type="checkbox"
                //     id={`checkbox_${board.name}`}
                //     onClick={(e) => e.stopPropagation()}
                //     checked={checked ?? false}
                //     onChange={(e) => handleClickAudienceCheckbox(e, board)}
                //     name={board.name}
                // />
                label={
                    <Stack direction='row' spacing={1} alignContent="space-between" sx={{
                        alignItems: 'center',
                        marginInlineStart: activeBulkView === "campaignManagementView" ? '20px' : 0,
                        width: '100%',
                    }}>
                        <Typography variant="body1" style={{
                            fontSize: '1.2em',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '300px', // Set to the maximum width you want the text to take up
                        }}>
                            {boardName}
                        </Typography>
                        {numberOfContacts !== -1 &&
                            <Typography variant="caption" style={{ color: '#52da79', fontSize: '0.8em', fontWeight: 500 }}>
                                {`${numberOfContacts} contacts`}
                            </Typography>
                        }
                        {numberOfContacts === -1 &&
                            <Skeleton sx={{ width: '80px', height: '30px' }} />
                        }
                    </Stack>
                }
                key={index}
            />
            {!isLoadingDuplicateBoard &&
                <Box>
                    <IconButton style={{ color: '#7f9887', width: 'auto', }}
                        onClick={(e) => handleOpenMenu(e)}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={(e) => handleCloseMenu(e)}
                        MenuListProps={{
                            sx: {
                                padding: 0, // Attempting to target the padding directly
                                // If the above doesn't work, you might need a more specific target using global selectors
                                '& .MuiList-root': {
                                    padding: 0, // This is a more targeted approach
                                },
                            },
                        }}
                    >
                        <Box className={classes.menu_wrapper}>
                            <button onClick={(e) => handelEditClick(e)} className={classes.menu_list_item}>
                                <BsPencil />
                                <span style={{ marginLeft: 8 }}>Edit audience</span>
                            </button>
                            <button onClick={(e) => handleDuplicateClick(e)} className={classes.menu_list_item}>
                                <MdOutlineContentCopy />
                                <span style={{ marginInlineStart: 5 }}>Duplicate audience</span>
                            </button>
                            <button 
                                onClick={handleDeleteClick} 
                                className={classes.menu_list_item}
                            >
                                <BsTrash style={{ color: 'red' }} />
                                <span style={{ marginInlineStart: 5, color: 'red' }}>
                                    {t('Delete audience')}
                                </span>
                            </button>
                        </Box>
                    </Menu>
                </Box >
            }
            {
                // activeBulkView === "campaignManagementView" &&
                 isLoadingDuplicateBoard &&
                <Stack direction='row' sx={{
                    overflow: 'hidden',
                    px: '12px',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: '40px',
                }}>
                    <CircularProgress size='20px' style={{ color: '#52da79' }} />
                </Stack>
            }
        </Stack >
    )
}