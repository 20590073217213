import React, { useState, useEffect } from 'react';
import {
  Input
} from '@/components/ui/input';
import {
  Label
} from '@/components/ui/label';
import { 
  Settings, 
  Sliders,
  AlertCircle
} from "lucide-react";
import { ToolSettingsComponentProps } from './tool-settings-dialog';

const FreshdeskSettings: React.FC<ToolSettingsComponentProps> = ({
  initialSettings = {},
  onSaveSettings,
  onValidationChange
}) => {
  const [activeTab, setActiveTab] = useState('general');
  
  // Freshdesk specific settings
  const [domain, setDomain] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [defaultPriority, setDefaultPriority] = useState('medium');
  const [domainError, setDomainError] = useState<string | null>(null);
  
  // Validate domain name
  const validateDomain = (value: string): boolean => {
    // Only allow alphanumeric characters and hyphens for the subdomain
    const subdomainRegex = /^[a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?$/;
    
    // Check if it's a valid subdomain
    if (!value) {
      setDomainError('Subdomain is required');
      return false;
    } else if (value.includes('.freshdesk.com')) {
      setDomainError('Please enter only the subdomain without .freshdesk.com');
      return false;
    } else if (value.includes('http://') || value.includes('https://') || value.includes('/')) {
      setDomainError('Please enter only the subdomain without http:// or https:// or any path');
      return false;
    } else if (!subdomainRegex.test(value)) {
      setDomainError('Please enter a valid subdomain (e.g., example)');
      return false;
    }
    
    setDomainError(null);
    return true;
  };
  
  // Handle domain change
  const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDomain(value);
    const isValid = validateDomain(value);
    
    // Notify parent about validation status
    if (onValidationChange) {
      onValidationChange(!isValid);
    }
  };
  
  // Load initial settings
  useEffect(() => {
    // Set default values first
    setDomain('');
    setApiKey('');
    setDefaultPriority('medium');
    
    // Override with initialSettings if available
    if (initialSettings) {
      if (initialSettings.domain) {
        setDomain(initialSettings.domain);
        const isValid = validateDomain(initialSettings.domain);
        if (onValidationChange) {
          onValidationChange(!isValid);
        }
      }
      if (initialSettings.apiKey) {
        setApiKey(initialSettings.apiKey);
      }
      if (initialSettings.defaultPriority) {
        setDefaultPriority(initialSettings.defaultPriority);
      }
    }
  }, [initialSettings, onValidationChange]);

  // Listen for save event from parent
  useEffect(() => {
    const handleSaveEvent = () => {
      if (onSaveSettings && validateDomain(domain)) {
        onSaveSettings({
          domain,
          apiKey,
          defaultPriority
        });
      }
    };

    document.addEventListener('save-tool-settings', handleSaveEvent);
    
    return () => {
      document.removeEventListener('save-tool-settings', handleSaveEvent);
    };
  }, [domain, apiKey, defaultPriority, onSaveSettings]);

  return (
    <div>
      <div className="mb-6">
        <div className="flex border-b">
          <button 
            className={`px-4 py-2 ${activeTab === 'general' ? 'text-primary border-b-2 border-primary font-medium' : 'text-muted-foreground'}`}
            onClick={() => setActiveTab('general')}
          >
            <div className="flex items-center gap-2">
              <Settings className="h-4 w-4" />
              General
            </div>
          </button>
          <button 
            className={`px-4 py-2 ${activeTab === 'advanced' ? 'text-primary border-b-2 border-primary font-medium' : 'text-muted-foreground'}`}
            onClick={() => setActiveTab('advanced')}
          >
            <div className="flex items-center gap-2">
              <Sliders className="h-4 w-4" />
              Advanced
            </div>
          </button>
        </div>
      </div>

      {activeTab === 'general' && (
        <div className="space-y-4 p-4 border rounded-lg bg-card">
          <div>
            <Label htmlFor="domain">Domain</Label>
            <div className="flex items-center mt-1">
              <Input
                id="domain"
                value={domain}
                onChange={handleDomainChange}
                placeholder="example"
                className={`${domainError ? 'border-red-500' : ''}`}
              />
              <span className="ml-1 text-muted-foreground">.freshdesk.com</span>
            </div>
            {domainError && (
              <div className="flex items-center mt-1 text-red-500 text-xs">
                <AlertCircle className="h-3 w-3 mr-1" />
                {domainError}
              </div>
            )}
            <p className="text-xs text-muted-foreground mt-1">Your Freshdesk subdomain (e.g., if your Freshdesk URL is example.freshdesk.com, enter "example")</p>
          </div>
          
          <div>
            <Label htmlFor="apiKey">API Key</Label>
            <Input
              id="apiKey"
              type="password"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="Enter your API key"
              className="mt-1"
            />
            <p className="text-xs text-muted-foreground mt-1">Your Freshdesk API key</p>
          </div>
        </div>
      )}
      
      {activeTab === 'advanced' && (
        <div className="space-y-4 p-4 border rounded-lg bg-card">
          <div>
            <Label htmlFor="defaultPriority">Default Priority</Label>
            <select
              id="defaultPriority"
              value={defaultPriority}
              onChange={(e) => setDefaultPriority(e.target.value)}
              className="w-full mt-1 px-3 py-2 bg-background border border-input rounded-md focus:outline-none focus:ring-2 focus:ring-ring focus:border-input"
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
              <option value="urgent">Urgent</option>
            </select>
            <p className="text-xs text-muted-foreground mt-1">Default priority for new tickets</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FreshdeskSettings; 