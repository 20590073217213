import {
  ActionReducerMapBuilder,
  AsyncThunk,
  PayloadAction,
  Selector,
  SliceCaseReducers,
  Update,
  ValidateSliceCaseReducers,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import randomstring from "randomstring";
import sift from "sift";
import { sort } from "sift-sort";
import {
  Paginated,
  createEntity,
  createManyEntities,
  deleteEntity,
  findEntity,
  getTotalCount,
  patchEntity,
  updateManyEntities
} from "./backend-api/backend-api";
import { BaseEntity, DBThunkHooks, DBThunkInput, DBThunks, DeleteOptions, GenericState, GetStateFn, PatchOptions } from "./DBServiceReducersInterfaces";
import { CommonRootState } from "@common/types/common-root-state-type";
import { AppThunkDispatch } from "@common/types/app-thunk-dispatch-type";

export const DBserviceAdapter = createEntityAdapter({
  selectId: (item: any) => item?._id ?? item?.tempId ?? null,
});


const publicServices = []


export const _DBReducerMap: Map<string, any> = new Map();
export const createDBServiceSlice = <T extends BaseEntity>({
  service = "",
  initialState,
  hooks,
  customReducers,
  getReducerState,
  customThunks = {},
}: {
  service: string;
  initialState: GenericState<T>;
  hooks?: DBThunkHooks;
  getReducerState: GetStateFn<T>;
  customReducers?:
  | ((builder: ActionReducerMapBuilder<GenericState<T>>) => void)
  | undefined;
  customThunks?: { [key: string]: AsyncThunk<any, any, any> };
}) => {
  const selectors = DBserviceAdapter.getSelectors(getReducerState);
  const CommonRootStateSelectors = DBserviceAdapter.getSelectors();

  const shouldEntityUpdate = (
    state: WritableDraft<GenericState<T>>,
    entity: T
  ): boolean => {
    let existingEntity;
    if (entity._id) {
      existingEntity = state.entities[entity._id];
      if (
        !existingEntity ||
        (entity.version ?? 0) > (existingEntity?.version ?? 0)
      ) {
        return true;
      }
    }
    return false;
  };

  const createSliceFactory = <
    Reducers extends SliceCaseReducers<GenericState<T>>
  >(
    reducers: Reducers &
      ValidateSliceCaseReducers<GenericState<T>, Reducers> = {} as Reducers &
      ValidateSliceCaseReducers<GenericState<T>, Reducers>
  ) => {
    return createSlice({
      name: service,
      initialState,
      reducers: {
        addOne: DBserviceAdapter.addOne,
        addMany: DBserviceAdapter.addMany,
        setAll: DBserviceAdapter.setAll,
        removeOne: DBserviceAdapter.removeOne,
        removeMany: DBserviceAdapter.removeMany,
        removeAll: DBserviceAdapter.removeAll,
        updateOne: DBserviceAdapter.updateOne,
        updateMany: DBserviceAdapter.updateMany,
        upsertOne: DBserviceAdapter.upsertOne,
        upsertMany: DBserviceAdapter.upsertMany,
        clearState: (state) => {
          DBserviceAdapter.removeAll(state);
          state.itemsLoaded = false;
          state.itemsLoadedLocaly = false;
        },
        setItemsLoadedLocaly: (state, action: PayloadAction<boolean>) => {
          state.itemsLoadedLocaly = action.payload;
        },

        conditionalUpdateOne: (state, action: PayloadAction<T>) => {
          const entity = action.payload;
          if (shouldEntityUpdate(state, entity)) {
            DBserviceAdapter.upsertOne(state, entity);
          }
        },
        conditionalUpdateMany: (state, action: PayloadAction<T[]>) => {
          DBserviceAdapter.updateMany(
            state,
            action.payload
              .filter((entity) => shouldEntityUpdate(state, entity))
              .map((entity) => ({
                id: entity._id!,
                changes: entity,
              }))
          );
        },
        setItemsLoaded: (state, action: PayloadAction<boolean>) => {
          console.log(`[${service}] setItemsLoaded`);
          state.itemsLoaded = action.payload;
        },
        setItemsLoading: (state, action: PayloadAction<boolean>) => {
          state.itemsLoading = action.payload;
        },
        setItemsTotalSkipAndLimit: (state, action: PayloadAction<{ total: number, skip: number, limit: number }>) => {

          const { total, skip, limit } = action.payload;
          state.total = total;
          state.skip = skip;
          state.limit = limit;

        },
        setItemsTotal: (state, action: PayloadAction<number>) => {
          state.total = action.payload;
        },
        ...customReducers,
      },
      extraReducers: customReducers,
    });
  };
  const slice = createSliceFactory();

  const checkAuthAndService = (
    service: string,
    operation: string,
    thunkAPI: any
  ): boolean => {
    console.log(`[${service} ${operation}] triggerd`);
    const state = thunkAPI.getState() as CommonRootState;
    if (!publicServices.includes(service) && !state.UserReducer.userId) {
      console.log(`[${service}] Not a public service and user not connected`);
      return false;
    }
    return true;
  };

  const createDBThunks = <T extends BaseEntity>(
    service: string
  ): DBThunks<T> => {
    return {
      find: createAsyncThunk(
        `${service}/find`,
        async (query: any, thunkAPI) => {
          if (!checkAuthAndService(service, 'find', thunkAPI)) {
            return;
          }

          const dispatch = thunkAPI.dispatch as AppThunkDispatch;
          dispatch(slice.actions.setItemsLoading(true));

          // Transform query to handle $in operator
          const transformQuery = (query: any): any => {
            const transformed = { ...query };
            Object.entries(query).forEach(([key, value]) => {
              if (value && typeof value === 'object' && '$in' in value) {
                // Convert {field: {$in: [1,2,3]}} to {field[$in]: []} format
                delete transformed[key];
                // Create array notation that will be properly serialized
                transformed[`${key}[$in][]`] = value.$in;
              }
            });
            return transformed;
          };

          const transformedQuery = {
            ...transformQuery(query),
            breakCache: randomstring.generate(16)
          };

          const getEntityPromise = dispatch(
            findEntity.initiate({
              service,
              query: transformedQuery,
            }))
            .unwrap()
            .then(async (response: Paginated<T> | T[] | undefined) => {
              const data = (response && "data" in response) ? response.data : response;
              const entityList =
                (await hooks?.after?.find?.apply(null, [
                  data ?? [],
                  {
                    dispatch,
                  },
                ])) ?? data ?? [];
              dispatch(slice.actions.addMany(entityList));
              dispatch(slice.actions.updateMany(entityList.map(entity => ({
                id: entity._id,
                changes: entity
              }))));
              dispatch(slice.actions.setItemsLoaded(true));

              if (response && "skip" in response && "limit" in response && "total" in response) {
                const { skip, limit, total } = response;
                dispatch(slice.actions.setItemsTotalSkipAndLimit({ skip, limit, total }));
              }

              dispatch(slice.actions.setItemsLoading(false));

              return entityList;
            })
            .catch((e) => {
              console.error('Error:', JSON.stringify(e, null, 2));
              dispatch(slice.actions.setItemsLoading(false));
            });
          return getEntityPromise;
        }
      ),
      getTotalCount: createAsyncThunk(
        `${service}/getTotalCount`,
        async (_, thunkAPI) => {
          if (!checkAuthAndService(service, 'getTotalCount', thunkAPI)) {
            return;
          }

          const dispatch =
            thunkAPI.dispatch as AppThunkDispatch;

          dispatch(slice.actions.setItemsLoading(true));

          try {
            // Use RTK Query to fetch the total count


            let totalCount = await dispatch(
              getTotalCount.initiate({ service })
            ).unwrap();

            console.log(`[${service}] total count: ${totalCount}`);

            totalCount = Number(totalCount) ?? 0;

            dispatch(slice.actions.setItemsTotal(totalCount ?? 0));
          } catch (e) {
            console.error(e);
          } finally {
            dispatch(slice.actions.setItemsLoading(false));
          }
        }
      ),
      create: createAsyncThunk(
        `${service}/create2`,
        async (entity: T, thunkAPI) => {

          if (!checkAuthAndService(service, 'create2', thunkAPI)) {
            return;
          }


          entity.version = 1;
          const dirtySessionId = randomstring.generate(16);
          entity = { ...entity, dirtySessionId, dirty: true };

          const dispatch =
            thunkAPI.dispatch as AppThunkDispatch;

          if (hooks?.before?.create) {
            entity = await hooks?.before?.create(entity, { dispatch });
          }

          if (!entity?.disableOptimisticUpdates) {
            dispatch(
              slice.actions.addOne({
                ...entity,
                _id: dirtySessionId,
                tempId: dirtySessionId,
                deleted: false,
              })
            );
          }

          let response;
          try {
            let newEntity = await dispatch(
              createEntity.initiate({
                service,
                entity,
              })
            ).unwrap();

            newEntity = {
              ...newEntity,
              dirtySessionId: undefined,
              dirty: false,
            };

            if (hooks?.after?.create) {
              newEntity = await hooks?.after?.create(newEntity, { dispatch });
            }

            if (!entity?.disableOptimisticUpdates) {
              dispatch(
                slice.actions.updateOne({
                  id: dirtySessionId,
                  changes: newEntity,
                })
              );
            }
            else {
              dispatch(
                slice.actions.addOne({
                  id: newEntity._id,
                  ...newEntity,
                })
              )
            }


            response = newEntity;
          } catch (e) {
            console.error(e);
            dispatch(slice.actions.removeOne(dirtySessionId));

            response = e;
          }
          return response;
        }
      ),
      patch: createAsyncThunk(
        `${service}/patch2`,
        async ({ entity, options }: DBThunkInput<T, PatchOptions>, thunkAPI) => {


          if (!checkAuthAndService(service, 'patch2', thunkAPI)) {
            return;
          }

          const dispatch =
            thunkAPI.dispatch as AppThunkDispatch;

          //const localState = getReducerState(getState());
          const originalEntity = selectors.selectById(
            thunkAPI.getState(),
            entity._id
          );
          if (!options?.ignoreOriginalEntity && !originalEntity) {
            console.log(`patch didnt found the entity - entity._id : ${entity._id}  not exists`)
            return;
          }
          if (!options?.ignoreOriginalEntity && !originalEntity._id) {
            console.log(`patch originalEntity didnt have _id. originalEntity:`)
            console.log(originalEntity)
            return;
          }

          const dirtySessionId = randomstring.generate(16);
          entity = {
            ...entity,
            dirtySessionId,
            dirty: true,
            version: (originalEntity?.version ?? 0) + 1,
          };

          if (hooks?.before?.patch) {
            entity = await hooks?.before?.patch(entity, { dispatch });
          }

          if (!options?.disableOptimisticUpdates) {
            console.log(`Update entity dark mode: ${JSON.stringify(entity?.isDarkMode)}`)
            dispatch(
              slice.actions.updateOne({
                id: entity._id,
                changes: entity,
              })
            );
          }

          let newEntity
          try {
            newEntity = await dispatch(
              patchEntity.initiate({
                service,
                entity,
              })
            ).unwrap();

            newEntity = {
              ...newEntity,
              dirtySessionId: undefined,
              dirty: false,
            };
            if (hooks?.after) {
              newEntity = await hooks?.after?.patch(newEntity, { dispatch });
            }
            if (options?.disableOptimisticUpdates) {
              dispatch(slice.actions.updateOne({
                id: newEntity._id,
                changes: newEntity
              }));
            } else {
              dispatch(slice.actions.conditionalUpdateOne(newEntity));
            }
          } catch (e) {
            dispatch(
              slice.actions.updateOne({
                id: originalEntity._id,
                changes: originalEntity,
              })
            );
            thunkAPI.rejectWithValue(e.data);
            return e.data;
          }
          return newEntity;
        }
      ),
      patchMany: createAsyncThunk<void, Update<T>[]>(
        `${service}/patchMany`,
        async (updates: Update<T>[], thunkAPI) => {

          if (!checkAuthAndService(service, 'patchMany', thunkAPI)) {
            return;
          }


          if (updates.length === 0) {
            return;
          }

          const dispatch = thunkAPI.dispatch as AppThunkDispatch;
          const localState = getReducerState(thunkAPI.getState());

          const ids = updates.map(update => update.id)
          const projection = Object.keys(updates[0]?.changes);

          const originalEntities = selectByQuery(localState,
            {
              _id: { $in: ids }
            },
            projection
          );

          if (originalEntities.length !== ids.length) {
            console.log(`patchMany didnt found the entities - some of them may not exists`)
            return;
          }

          dispatch(slice.actions.updateMany(updates));

          const deletedEntitiesPromise = dispatch(updateManyEntities.initiate(
            { service, entities: updates })).unwrap()
            .then(async (response: any) => {

              const entities = response?.map(entity => {
                return {
                  ...entity,
                  dirtySessionId: undefined,
                  dirty: false,
                }
              })
              dispatch(slice.actions.conditionalUpdateMany(entities));
              return entities;
            }).catch((e) => {
              console.error(e);
              const beforeChanges = originalEntities.map(entity => {
                return {
                  id: entity._id,
                  changes: entity
                }
              })
              dispatch(slice.actions.updateMany(beforeChanges));
            });

          return deletedEntitiesPromise;
        }
      ),
      createMany: createAsyncThunk(
        `${service}/createMany`,
        async (entities: T[], thunkAPI) => {
          if (!checkAuthAndService(service, 'createMany', thunkAPI)) {
            return;
          }

          const dispatch = thunkAPI.dispatch as AppThunkDispatch;

          // Initialize responses array
          let responses = [];

          // Prepare entities with initial modifications and optimistic IDs
          const preparedEntities = entities.map(entity => {
            entity.version = 1;
            const dirtySessionId = randomstring.generate(16);
            return { ...entity, dirtySessionId, dirty: true, tempId: dirtySessionId };
          });

          // Run hooks before create if any
          const entitiesAfterBeforeHook = hooks?.before?.create
            ? await Promise.all(preparedEntities.map(entity => hooks.before.create(entity, { dispatch })))
            : preparedEntities;

          // Optimistically add entities to the store
          entitiesAfterBeforeHook.forEach(entity => {
            dispatch(
              slice.actions.addOne({
                ...entity,
                _id: entity.dirtySessionId,
              })
            );
          });

          try {
            // Attempt to create multiple entities
            const newEntities = await dispatch(
              createManyEntities.initiate({
                service,
                entities: entitiesAfterBeforeHook,
              })
            ).unwrap();

            // Post creation updates or hooks
            const entitiesAfterAfterHook = hooks?.after?.create
              ? await Promise.all(newEntities.map(entity => hooks.after.create(entity, { dispatch })))
              : newEntities;

            // Update store with the new entities data, removing dirty flags
            entitiesAfterAfterHook.forEach((newEntity, index) => {
              dispatch(
                slice.actions.updateOne({
                  id: entitiesAfterBeforeHook[index].dirtySessionId,
                  changes: {
                    ...newEntity,
                    dirtySessionId: undefined,
                    dirty: false,
                  },
                })
              );
              responses.push(newEntity);
            });
          } catch (e) {
            console.error(e);

            // Rollback optimistic updates by removing all entities added
            entitiesAfterBeforeHook.forEach(entity => {
              dispatch(slice.actions.removeOne(entity.dirtySessionId));
            });

            // Push error to responses
            responses.push(e);
          }

          return responses;
        }
      ),
      delete: createAsyncThunk(
        `${service}/delete2`,
        async ({ entity, options }: DBThunkInput<T, DeleteOptions>, thunkAPI) => {

          if (!checkAuthAndService(service, 'delete2', thunkAPI)) {
            return;
          }

          const dispatch =
            thunkAPI.dispatch as AppThunkDispatch;

          const originalEntity = selectors.selectById(
            thunkAPI.getState(),
            entity._id
          );
          const dirtySessionId = randomstring.generate(16);
          entity = {
            _id: entity._id,
            dirtySessionId,
            dirty: true,
            deleted: true,
            version: (originalEntity?.version ?? 0) + 1,
          } as any;

          if (hooks?.before?.delete) {
            entity = await hooks?.before?.delete(entity, { dispatch });
          }

          dispatch(slice.actions.removeOne(entity._id));

          const deletedEntityPromise = dispatch(
            deleteEntity.initiate({
              service,
              entity,
            })
          ).unwrap();
          deletedEntityPromise
            .then(async (newEntity: T) => {
              newEntity = {
                ...newEntity,
                dirtySessionId: undefined,
                dirty: false,
              };
              if (hooks?.after?.delete) {
                newEntity = await hooks?.after?.delete(newEntity, { dispatch });
              }
              dispatch(slice.actions.removeOne(newEntity._id));
            })
            .catch((e) => {
              // When an entity is not found, it means it was already removed so don't return it to the entity map

              console.log(entity);
              if (!(e?.data?.name === "NotFound" || options?.inCaseOfErrorRollbackOptimisticUpdateDisabled)) {
                dispatch(slice.actions.addOne(originalEntity));
              }
              console.error(e);
            });

          return deletedEntityPromise;
        }
      ),
      createOptimisticUpdate: createAsyncThunk(
        `${service}/createOptimisticUpdate`,
        async (entity: T, thunkAPI) => {

          if (!checkAuthAndService(service, 'createOptimisticUpdate', thunkAPI)) {
            return;
          }

          const dirtySessionId = randomstring.generate(16);
          entity = { ...entity, dirtySessionId, dirty: true };

          const dispatch = thunkAPI.dispatch as AppThunkDispatch;
          const dirtyEntity = {
            ...entity,
            _id: dirtySessionId,
            tempId: dirtySessionId,
          };

          if (entity._id) {
            dispatch(slice.actions.updateOne({
              id: entity._id,
              changes: dirtyEntity
            }));
          } else {
            dispatch(slice.actions.addOne({ dirtyEntity }));
          }
          return entity;
        }
      ),
      syncOptimisticUpdate: createAsyncThunk(
        `${service}/syncOptimisticUpdate`,
        async (newEntity: T, thunkAPI) => {
          if (!checkAuthAndService(service, 'syncOptimisticUpdate', thunkAPI)) {
            return;
          }

          const dirtySessionId = newEntity.dirtySessionId
          newEntity = {
            ...newEntity,
            dirtySessionId: undefined,
            dirty: false,
          };

          const dispatch = thunkAPI.dispatch as AppThunkDispatch;

          // Remove the dirty entity using its dirtySessionId
          dispatch(slice.actions.removeOne(dirtySessionId));

          // Insert the newEntity using its actual _id
          dispatch(slice.actions.addOne(newEntity));
          return newEntity;
        }
      ),
      undoOptimisticUpdate: createAsyncThunk(
        `${service}/undoOptimisticUpdate`,
        async (dirtySessionId: string, thunkAPI) => {
          if (!checkAuthAndService(service, 'undoOptimisticUpdate', thunkAPI)) {
            return;
          }
          const dispatch = thunkAPI.dispatch as AppThunkDispatch;

          dispatch(slice.actions.removeOne(dirtySessionId));
          return dirtySessionId;
        }
      ),
      ...customThunks,
    };
  };

  const dbThunks: DBThunks<T> = createDBThunks<T>(service);

  type DBReducerState = GenericState<T>;

  const stateSelector: Selector<DBReducerState, DBReducerState> = (
    state: DBReducerState
  ) => state;



  const getFilterObj: Selector = (_: DBReducerState, filterObj: string) => {
    return filterObj
  };

  const getProjection: Selector = (_: DBReducerState, __: string, projectionArray: string[]) => {
    return projectionArray
  };

  const getProjectionField: Selector = (_: DBReducerState, __: string, projectionField: string) => {
    return projectionField
  };

  const getSortObj: Selector = (_: DBReducerState, __: string, ___: string, sortObj: any) => {
    return sortObj
  };

  const selectByQuery = createSelector<
    [Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjection, typeof getSortObj],
    T[]
  >([stateSelector, getFilterObj, getProjection, getSortObj], (state: DBReducerState, filterObj: any, projectionArray: string[], sortObj: any = {}): T[] => {
    if (!filterObj || Object.keys(filterObj).length === 0) {
      //console.log(`filterObj param ${service} is empty`);
      return CommonRootStateSelectors.selectAll(state);
    }
    if (!state) {
      //console.log(`state param ${service} is undefined`);
      return [];
    }
    if (!state.ids || state.ids.length === 0) {
      //console.log(`state.ids ${service} is empty`);
      return [];
    }

    let resultsArray = [];

    const siftFunction = sift(filterObj);
    resultsArray = (state.ids ?? [])
      .filter(id => siftFunction(state.entities[id]))
      .map(id => state.entities[id]) as T[];

    if (Object.keys(sortObj).length !== 0) {
      resultsArray = sort(resultsArray, sortObj);
    }

    if (Array.isArray(projectionArray) && projectionArray.length > 0) {
      resultsArray = resultsArray.map((entity: T | {}) => {
        entity = projectionArray.reduce((a, e) => { a[e] = entity[e]; return a; }, {})
        return entity;
      }) as T[];
    }

    return resultsArray;

  });

  const selectByQueryOnlyLength = createSelector<
    [Selector<DBReducerState, DBReducerState>, typeof getFilterObj],
    number
  >([stateSelector, getFilterObj], (state: DBReducerState, filterObj: any): number => {

    return selectByQuery(state, filterObj).length

  });

  const selectOneObjectById = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj], T>
    ([stateSelector, getFilterObj], (state: DBReducerState, id: string): T => {

      const object = CommonRootStateSelectors.selectById(state, id);
      return !object?.deleted ? object : null;

    });

  const selectOneFieldById = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjectionField], any>
    ([stateSelector, getFilterObj, getProjectionField], (state: DBReducerState, id: string, projectionField: string): any => {

      if (!projectionField) {
        return null;
      }

      const onlyOne = selectOneObjectById(state, id);

      if (!onlyOne) {
        return null;
      }

      if (onlyOne.hasOwnProperty(projectionField)) {
        return onlyOne[projectionField];
      }

      return null;

    });


  const selectOneObjectByQuery = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjection, typeof getSortObj], T>
    ([stateSelector, getFilterObj, getProjection, getSortObj], (state: DBReducerState, filterObj: any, projectionArray: string[], sortObj: any = {}): T => {

      let queryResult = selectByQuery(state, filterObj, projectionArray, sortObj);

      if (queryResult.length >= 1) {
        const onlyOne: T = queryResult[0];
        return onlyOne;

      }

      return null;

    });


  const selectOneFieldByQuery = createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getProjectionField, typeof getSortObj], any>
    ([stateSelector, getFilterObj, getProjectionField, getSortObj], (state: DBReducerState, filterObj: any, projectionField: string, sortObj: any = {}): any => {

      if (!projectionField) {
        return null;
      }

      const onlyOne = selectOneObjectByQuery(state, filterObj, [projectionField], sortObj);

      if (!onlyOne) {
        return null;
      }

      if (onlyOne.hasOwnProperty(projectionField)) {
        return onlyOne[projectionField];
      }

      return null;

    });


  const getField: Selector = (_: DBReducerState, __: string, projection: string) => {
    return projection
  };

  const getOper: Selector = (_: DBReducerState, __: string, ___: string, sortObj: string) => {
    return sortObj
  };

  const selectByQueryOnlyMaxMin =
    createSelector<[Selector<DBReducerState, DBReducerState>, typeof getFilterObj, typeof getField, typeof getOper], number>
      ([stateSelector, getFilterObj, getField, getOper],
        (state: DBReducerState, filterObj: any, field: string, oper: string): number => {

          field = "order";
          oper = "max";

          const array = selectByQuery(state, filterObj)

          if (array.length === 0)
            return null;

          const result = window.Math[oper](...array.map(o => o[field]))

          return result

        });

  // create selectPaginationStatus selector that returns the pagination status of the current slice
  const selectPaginationStatus = createSelector<
    [Selector<DBReducerState, DBReducerState>],
    { itemsLoaded: boolean, itemsLoading: boolean, total: number, skip: number, limit: number }
  >([stateSelector], (state: DBReducerState): { itemsLoaded: boolean, itemsLoading: boolean, total: number, skip: number, limit: number } => {
    return {
      itemsLoaded: state.itemsLoaded,
      itemsLoading: state.itemsLoading,
      total: state.total,
      skip: state.skip,
      limit: state.limit
    }
  }
  );



  const dbReducer = {
    slice,
    dbThunks,
    selectByQuery,
    selectors,
    selectByQueryOnlyLength,
    selectByQueryOnlyMaxMin,
    selectOneObjectByQuery,
    selectOneFieldByQuery,
    selectPaginationStatus,
    selectOneObjectById,
    selectOneFieldById,
    getReducerState
  };
  _DBReducerMap.set(service, dbReducer);

  return dbReducer;
};


