import { sessionSelectOneObjectByQuery } from '@common-reducers/DBServiceThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { ConnectedSocket, selectConnectedSockets } from '@common/reducers/ConnectedEnginesSlice';
import { toggleSocketModal } from "@common/reducers/MainReducer";
import { isExtensionContext } from '@common/services/detect-context';
import Bowser from 'bowser';
import { AlertCircle, ChevronDown, ChevronUp, Chrome, Computer, LucideWifi, QrCode, Server, X, WifiOff } from 'lucide-react';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import SocketDebugTable from '@common/components/debug/socket-debug-table';
import { Box } from '@mui/material';

interface WhatsAppEngine {
  id: string;
  type: 'whatsapp';
  name: string;
  domain: string;
  connectionTime: string;
}

type Engine = ConnectedSocket | WhatsAppEngine;

const styles: Record<string, CSSProperties> = {
  container: {
    position: 'relative' as const,
    display: 'flex',
    alignItems: 'center',
  },
  expandedView: {
    position: 'absolute' as const,
    top: '100%',
    right: '0',
    marginTop: '8px',
    backgroundColor: 'white',
    border: '1px solid #e5e7eb',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    padding: '16px',
    minWidth: '300px',
    zIndex: 1000,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
  },
  title: {
    fontWeight: 500,
    margin: 0,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '4px',
    color: '#6b7280',
  },
  engineList: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '8px',
  },
  engineItem: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '8px',
    backgroundColor: '#f9fafb',
    borderRadius: '6px',
    border: '1px solid #f3f4f6',
  },
  engineInfo: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '2px',
  },
  engineName: {
    fontSize: '14px',
    fontWeight: 500,
    margin: 0,
  },
  engineStatus: {
    fontSize: '12px',
    color: '#6b7280',
    margin: 0,
  },
  alert: {
    display: 'flex',
    gap: '8px',
    padding: '8px',
    borderRadius: '6px',
    marginTop: '8px',
    fontSize: '13px',
    alignItems: 'flex-start',
  },
  warningAlert: {
    backgroundColor: '#fff7ed',
    color: '#9a3412',
    border: '1px solid #ffedd5',
  },
  errorAlert: {
    backgroundColor: '#fee2e2',
    color: '#b91c1c',
    border: '1px solid #fecaca',
  },
  minifiedButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: 'white',
    border: '1px solid #e5e7eb',
    borderRadius: '8px',
    padding: '8px 12px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    cursor: 'pointer',
  },
  statusDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
  },
  statusLabel: {
    fontSize: '14px',
    fontWeight: 500,
  },
  upgradeSection: {
    marginTop: '12px',
    padding: '12px',
    backgroundColor: '#f0fdf4',
    border: '1px solid #dcfce7',
    borderRadius: '6px',
    color: '#166534',
  },
  upgradeTitle: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#166534',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  featureList: {
    fontSize: '13px',
    color: '#166534',
    marginBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  featureItem: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '6px',
  },
  checkIcon: {
    width: '14px',
    height: '14px',
    color: 'currentColor',
    marginTop: '2px',
    flexShrink: 0,
    flexGrow: 0,
  },
  upgradeButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: '#166534',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    padding: '8px 12px',
    fontSize: '13px',
    fontWeight: 500,
    cursor: 'pointer',
    width: '100%',
    justifyContent: 'center',
  },
  browserOption: {
    backgroundColor: '#f0f9ff',
    padding: '16px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    color: '#0369a1',
  },
  optionTitle: {
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#0369a1',
  },
  browserButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: '#0369a1',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    padding: '8px 12px',
    fontSize: '13px',
    fontWeight: 500,
    cursor: 'pointer',
    width: '100%',
    justifyContent: 'center',
  },
  divider: {
    textAlign: 'center',
    color: '#6b7280',
    fontSize: '14px',
    position: 'relative',
    padding: '8px 0',
  },
  dividerLine: {
    borderTop: '1px solid #e5e7eb',
    position: 'absolute',
    top: '50%',
    width: '100%',
    zIndex: 0,
  },
  dividerText: {
    backgroundColor: 'white',
    padding: '0 12px',
    position: 'relative',
    zIndex: 1,
  },
};

const getEngineTypeAndName = (socket: ConnectedSocket) => {
  if (!socket) return null;

  if (socket.connectedFrom === 'extension') {
    return {
      type: 'browser' as const,
      name: `WhatsApp Web (${socket.browserType})`,
      domain: socket.domain
    };
  }
  return {
    type: 'gateway' as const,
    name: 'WhatsApp Server',
    domain: socket.domain
  };
};

const BrowserExtensionSection = ({ styles }) => (
  <div style={styles.browserOption}>
    <div style={styles.optionTitle}>
      <Chrome size={20} />
      Browser Extension
    </div>
    <div style={{ ...styles.featureList, color: '#0369a1' }}>
      <div style={styles.featureItem}>
        <svg style={styles.checkIcon} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
        Free to use
      </div>
      <div style={styles.featureItem}>
        <svg style={styles.checkIcon} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
        Easy installation
      </div>
      <div style={styles.featureItem}>
        <svg style={styles.checkIcon} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
        Browser must stay open
      </div>
    </div>
    <button
      style={styles.browserButton}
      onClick={() => window.open('https://chromewebstore.google.com/detail/blueticks-the-ultimate-wh/adgnjhngogijkkppficiiepmjebijinl', '_blank')}
    >
      <Chrome size={16} />
      Install Extension
    </button>
  </div>
);

const OfflineServerSection = ({ styles, onConnect }) => (
  <div style={styles.upgradeSection}>
    <div style={styles.upgradeTitle}>
      <LucideWifi size={20} />
      Send Messages Offline
    </div>
    <div style={{ ...styles.featureList, color: '#166534' }}>
      <div style={styles.featureItem}>
        <svg style={styles.checkIcon} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
        Messages sent even when browser is closed
      </div>
      <div style={styles.featureItem}>
        <svg style={styles.checkIcon} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
        More reliable delivery
      </div>
      <div style={styles.featureItem}>
        <svg style={styles.checkIcon} viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
        </svg>
        Premium feature
      </div>
    </div>
    <button
      style={styles.upgradeButton}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onConnect();
      }}
      data-action-button="true"
    >
      <QrCode size={20} />
      Connect Offline Server
    </button>
  </div>
);

const NotifierStatusSection = ({ styles, isConnected }) => (
  <Box sx={{
    ...styles.engineItem,
    backgroundColor: isConnected ? '#f0fdf4' : '#fef2f2',
    borderColor: isConnected ? '#dcfce7' : '#fecaca',
    my: 1
  }}>
    {isConnected ? (
      <LucideWifi size={20} color="#22c55e" />
    ) : (
      <WifiOff size={20} color="#ef4444" />
    )}
    <div style={styles.engineInfo}>
      <p style={styles.engineName}>
        Notifier Service
      </p>
      <p style={{
        ...styles.engineStatus,
        color: isConnected ? '#166534' : '#991b1b'
      }}>
        {isConnected ? 'Connected' : 'Not Connected'}
      </p>
    </div>
  </Box>
);

const EngineList = ({ styles, engines }) => (
  <div style={styles.engineList}>
    {engines.map(engine => {
      const engineInfo = 'type' in engine ?
        { type: engine.type, name: engine.name, domain: engine.domain } :
        getEngineTypeAndName(engine);

      return (
        <div key={engine.id} style={styles.engineItem}>
          {engineInfo.type === 'browser' ? (
            <Computer size={20} color="#f97316" />
          ) : (
            <Server size={20} color="#22c55e" />
          )}
          <div style={styles.engineInfo}>
            <p style={styles.engineName}>{engineInfo.name}</p>
            <p style={styles.engineStatus}>Connected from: {engineInfo.domain}</p>
            <p style={styles.engineStatus}>
              Connected at: {new Date(engine.connectionTime).toLocaleString()}
            </p>
          </div>
        </div>
      );
    })}
  </div>
);

const AlertMessage = ({ styles, alert }) => (
  <div
    style={{
      ...styles.alert,
      ...(alert.type === 'error' ? styles.errorAlert : styles.warningAlert)
    }}
  >
    <AlertCircle size={16} />
    <span>{alert.message}</span>
  </div>
);

const ExpandedView = ({ styles, onClose, children, buttonId }) => {
  const isInWhatsApp = window.location.href.includes('web.whatsapp.com');
  const [position, setPosition] = useState({ top: 0, right: 0 });
  const [isPositioned, setIsPositioned] = useState(false);



  const updatePosition = useCallback(() => {
    if (isInWhatsApp) {
      const button = document.querySelector(`[data-connected-engines-button="${buttonId}"]`);
      if (button) {
        const rect = button.getBoundingClientRect();
        setPosition({
          top: rect.bottom + 8,
          right: window.innerWidth - rect.right
        });
        setIsPositioned(true);
      }
    } else {
      setIsPositioned(true);
    }
  }, [isInWhatsApp, buttonId]);

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);

    const positionInterval = setInterval(updatePosition, 100);

    return () => {
      window.removeEventListener('resize', updatePosition);
      clearInterval(positionInterval);
    };
  }, [updatePosition]);

  const expandedContent = (
    <div style={{
      ...styles.expandedView,
      ...(isInWhatsApp ? {
        position: 'fixed',
        top: `${position.top}px`,
        right: `${position.right}px`,
        zIndex: 999999,
        opacity: isPositioned ? 1 : 0,
        transition: 'opacity 0.15s ease-in-out',
      } : {})
    }}>
      <div style={styles.header}>
        <h3 style={styles.title}>WhatsApp Devices</h3>
        <button
          style={styles.closeButton}
          onClick={onClose}
        >
          <X size={16} />
        </button>
      </div>
      {<SocketDebugTable />}
      {children}
    </div>
  );

  if (isInWhatsApp) {
    return createPortal(expandedContent, document.body);
  }

  return expandedContent;
};

const ConnectedEngines = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const connectedSockets = useAppSelector(selectConnectedSockets);
  const isSocketConnected = useAppSelector((state) => state.NotifierReducerReducer?.isConnected);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const instanceId = React.useRef(`connected-engines-${Math.random().toString(36).substr(2, 9)}`).current;

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Don't handle click outside if the click was on a button that needs to trigger other actions
      if (event.target instanceof Element && event.target.closest('[data-action-button]')) {
        return;
      }

      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    if (isExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded]);

  // Get active session
  const activeSession = useAppSelector((state) =>
    sessionSelectOneObjectByQuery(state.DBSessionReducer || {}, {
      status: { $in: ["connected"] }
    })
  );

  // Filter and validate sockets
  const validSockets = connectedSockets.filter(socket => {
    if (!socket) return false;

    // Must have all required fields
    if (!socket.domain || !socket.connectionTime || !socket.device || !socket.browserType) {
      return false;
    }

    // Only accept extension connections from WhatsApp Web
    return socket.connectedFrom === 'extension' && socket.domain.includes('web.whatsapp.com');
  });

  // Add current extension as a socket if we're in extension context
  const currentExtensionSocket: ConnectedSocket | null = isExtensionContext() ? {
    id: 'current-extension',
    device: 'Desktop',
    browserType: Bowser.getParser(window.navigator.userAgent).getBrowser().name,
    connectionTime: new Date().toISOString(),
    connectedFrom: 'extension',
    domain: window.location.href
  } : null;

  // Combine sockets and session information
  const allEngines: Engine[] = [
    ...(currentExtensionSocket ? [currentExtensionSocket] : []),
    ...validSockets,
    ...(activeSession ? [{
      id: activeSession._id,
      type: 'whatsapp' as const,
      name: 'WhatsApp Server',
      domain: 'WhatsApp Web',
      connectionTime: activeSession.updatedAt?.toString() || new Date().toString()
    }] : [])
  ];

  const hasGateway = activeSession !== null;
  const hasBrowser = currentExtensionSocket !== null || validSockets.length > 0;

  const getDeliveryStatus = () => {
    if (!isSocketConnected) {
      return {
        color: '#ef4444',
        text: 'Not Connected',
        alert: {
          type: 'error',
          message: 'The notification service is not connected. Messages may not be delivered.'
        }
      };
    }
    if (allEngines.length === 0) {
      return {
        color: '#ef4444',
        text: 'No WhatsApp Connected',
        alert: {
          type: 'error',
          message: 'Messages cannot be sent. Connect WhatsApp to enable message delivery.'
        }
      };
    }
    if (hasGateway) {
      return {
        color: '#22c55e',
        text: 'WhatsApp Ready (Offline OK)',
        alert: null
      };
    }
    return {
      color: '#f97316',
      text: 'WhatsApp Web Only',
      alert: {
        type: 'warning',
        message: 'Keep WhatsApp Web open in your browser for messages to be sent.'
      }
    };
  };

  const status = getDeliveryStatus();

  return (
    <div style={styles.container} ref={containerRef}>
      {isExpanded && (
        <ExpandedView
          styles={styles}
          onClose={() => setIsExpanded(false)}
          buttonId={instanceId}
        >
          <NotifierStatusSection styles={styles} isConnected={!!isSocketConnected} />
          {allEngines.length === 0 ? (
            <>
              <BrowserExtensionSection styles={styles} />
              <div style={styles.divider}>
                <div style={styles.dividerLine} />
                <span style={styles.dividerText}>or</span>
              </div>
              <OfflineServerSection
                styles={styles}
                onConnect={() => {
                  dispatch(toggleSocketModal(true));
                  setIsExpanded(false);
                }}
              />
              {status.alert && <AlertMessage styles={styles} alert={status.alert} />}
            </>
          ) : (
            <>
              <EngineList styles={styles} engines={allEngines} />
              {!hasGateway && (
                <>
                  {status.alert && <AlertMessage styles={styles} alert={status.alert} />}
                  <div style={{
                    ...styles.divider,
                    marginTop: '16px',
                  }}>
                    <div style={styles.dividerLine} />
                    <span style={styles.dividerText}>or upgrade to</span>
                  </div>
                  <OfflineServerSection
                    styles={styles}
                    onConnect={() => {
                      dispatch(toggleSocketModal(true));
                      setIsExpanded(false);
                    }}
                  />
                </>
              )}
            </>
          )}
        </ExpandedView>
      )}

      <button
        data-connected-engines-button={instanceId}
        data-action-button="true"
        style={styles.minifiedButton}
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      >
        <div
          style={{
            ...styles.statusDot,
            backgroundColor: status.color
          }}
        />
        <span style={styles.statusLabel}>
          {status.text}
        </span>
        {!isSocketConnected && (
          <WifiOff size={16} color="#ef4444" style={{ marginLeft: '4px' }} />
        )}
        {isExpanded ? (
          <ChevronDown size={16} color="#6b7280" />
        ) : (
          <ChevronUp size={16} color="#6b7280" />
        )}
      </button>
    </div>
  );
};

export default ConnectedEngines; 