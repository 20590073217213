export enum EventType {
    NODE_ENTRY = 'NODE_ENTRY',
    NODE_EXIT = 'NODE_EXIT',
    PROCESS = 'PROCESS',
    DECISION = 'DECISION',
    ERROR = 'ERROR',
    OUTGOING_MESSAGE = 'OUTGOING_MESSAGE',
    INCOMING_MESSAGE = 'INCOMING_MESSAGE',
    SESSION_STARTED = 'SESSION_STARTED',
    SESSION_ENDED = 'SESSION_ENDED',
    CREDIT_USAGE = 'CREDIT_USAGE'
  }
  
  export enum EventStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    PENDING = 'PENDING'
  }
  
  export enum ProcessType {
    API_CALL = 'API_CALL',
    LLM_REQUEST = 'LLM_REQUEST',
    DATA_TRANSFORM = 'DATA_TRANSFORM',
    VALIDATION = 'VALIDATION'
  }
  
  // Base event interface
  export interface BaseEvent {
    _id?: string;
    owner?: string;
    timestamp: Date;
    sessionId: string;
    deploymentId: string;
    nodeId: string;
    nodeType: string;
    type: EventType;
    status: EventStatus;
    creditsUsed?: number;
    metadata?: Record<string, any>;
    jsonId?: string;
  }
  
  // Node entry event
  export interface NodeEntryEvent extends BaseEvent {
    type: EventType.NODE_ENTRY;
    data: {
      input: any;
      sourceNodeId?: string;  // undefined for start node
    };
  }
  
  // Node exit event
  export interface NodeExitEvent extends BaseEvent {
    type: EventType.NODE_EXIT;
    data: {
      output: any;
      targetNodeId: string;
    };
  }
  
  // Process event for tracking operations
  export interface ProcessEvent extends BaseEvent {
    type: EventType.PROCESS;
    data: {
      processType: ProcessType;
      input: any;
      output: any;
      duration?: number;      // in milliseconds
      error?: any;
    };
  }
  
  // Decision event for routing
  export interface DecisionEvent extends BaseEvent {
    type: EventType.DECISION;
    data: {
      condition: string | Record<string, any>;
      result: boolean;
      targetNodeId: string;
      alternativePaths?: Array<{
        condition: string | Record<string, any>;
        targetNodeId: string;
      }>;
    };
  }
  
  // Error event
  export interface ErrorEvent extends BaseEvent {
    type: EventType.ERROR;
    data: {
      error: any;
      context: Record<string, any>;
      recoverable: boolean;
      retryCount?: number;
    };
  }
  
  // Outgoing message event
  export interface OutgoingMessageEvent extends BaseEvent {
    type: EventType.OUTGOING_MESSAGE;
    data: {
      message: string;
      recipient: string;
      metadata?: Record<string, any>;
    };
  }
  
  // Incoming message event
  export interface IncomingMessageEvent extends BaseEvent {
    type: EventType.INCOMING_MESSAGE;
    data: {
      message: string;
      sender: string;
      metadata?: Record<string, any>;
    };
  }
  
  // Session started event
  export interface SessionStartedEvent extends BaseEvent {
    type: EventType.SESSION_STARTED;
    data: {
      sender: string;
      timestamp: number;
      initialContext?: Record<string, any>;
    };
  }
  
  // Session ended event
  export interface SessionEndedEvent extends BaseEvent {
    type: EventType.SESSION_ENDED;
    data: {
      sender: string;
      timestamp: number;
    };
  }
  
  export interface CreditUsageEvent extends BaseEvent {
    type: EventType.CREDIT_USAGE;
    data: {
      reason: string;
      amount: number;
      modelName?: string;
      operation?: string;
    };
  }
  
  // Union type of all events
  export type NodeEvent = 
    | NodeEntryEvent 
    | NodeExitEvent 
    | ProcessEvent 
    | DecisionEvent 
    | ErrorEvent
    | OutgoingMessageEvent
    | IncomingMessageEvent
    | SessionStartedEvent
    | SessionEndedEvent
    | CreditUsageEvent;