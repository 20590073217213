import { NodeType } from "@common-models/bot-node";
import { ContactMail, Description, LiveHelpRounded, MessageRounded, PlayArrow, SignpostOutlined, SmartToy, Webhook } from "@mui/icons-material";
import { Edge, Node } from "reactflow";
import CustomEdge from "../custome-edge";
import AgentIcon from './agent/agent-icon';
import AgentNode from './agent/agent-node';
import AgentNodeContainer from './agent/agent-node-container';
import ConditionNode from "./condition/condition-node";
import ConditionNodeContainer from "./condition/condition-node-container";
import GoogleSheetsNode from "./google-sheets/google-sheets-node";
import GoogleSheetsContainer from "./google-sheets/google-sheets-node-container";
import QuestionNode from "./question/question-node";
import QuestionNodeContainer from "./question/question-node-container";
import SendMessageNode from "./send-message/send-message-node";
import SendMessageNodeContainer from "./send-message/send-message-node-container";
import TriggerNode from "./trigger/trigger-node";
import TriggerNodeContainer from "./trigger/trigger-node-container";
import WebhookNode from "./webhook/webhook-node";
import WebhookNodeContainer from "./webhook/webhook-node-container";


export type Handles = {
    inputs: { id: string; type: string }[];
    outputs: { id: string; type: string }[];
};

export type CustomNodeData = {
    label: string | JSX.Element;
    handles?: Handles;
    conditionResult?: {
        yResultHandleId: string;
        nResultHandleId: string;
    }
    type: NodeType;
    text?: string;
    tools?: string[];
    color?: string;
    toolSettings?: any;
    triggerType?: string;
    contacts?: string[];
    defaultHandleId?: string;
    asset?: any;
    prompt?: string;
    question?: string;
    questionAsset?: any;
    answers?: any;
    flowNodeConditions?: any;
    rowData?: any;
    webhook?: any;
    contactId?: string;
    _variableNameToCopy?: {
        oldNodeId: string;
        newNodeId: string;
        newVariableName: string;
    }; // temporary property to store the variable name for duplicate nodes
    // Add any other custom data fields you need
};

export type CustomNode = Node<CustomNodeData> & {
    type: string; // Assuming you also need to specify a 'type' property for the node
    id: string;
};

export type CustomEdgeType = Edge;

export type NodeTypeDef = {
    type: NodeType;
    container: any;
    nodeComponent: any;
    color: string;
    title: string;
    secondary_title?: string;
    nodeIcon: any;
    svgPath?: string;
    isMainNode?: boolean;
    isEditable?: boolean;
    handles?: (id: string) => any;
    extraData?: (id: string) => any;
    isSendingMessageNode: boolean;
}

export const nodeTypes: Record<NodeType, NodeTypeDef> = {
    'trigger': {
        type: 'trigger',
        container: TriggerNodeContainer,
        nodeComponent: TriggerNode,
        color: '#8eba7b',
        title: 'Trigger',
        secondary_title: 'Trigger the flow',
        nodeIcon: PlayArrow,
        isMainNode: true,
        isEditable: true,
        handles: (id: string) => {
            return {
                inputs: [],
                outputs: [{ id: `source-${id}-1`, type: 'source' }],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'trigger',
                triggerType: 'blockList',
                contacts: []
            }
        },
        isSendingMessageNode: false
    },
    'sendMessage': {
        type: 'sendMessage',
        container: SendMessageNodeContainer,
        nodeComponent: SendMessageNode,
        color: '#E95B69',
        title: 'Send a Message',
        secondary_title: 'With no response required from visitor',
        nodeIcon: MessageRounded,
        isMainNode: true,
        isEditable: false,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [{ id: `source-${id}-1`, type: 'source' }],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'sendMessage',
            }
        },
        isSendingMessageNode: true
    },
    'question': {
        type: 'question',
        container: QuestionNodeContainer,
        nodeComponent: QuestionNode,
        color: '#FF9933',
        title: 'Ask a Question',
        secondary_title: 'Ask question and store user input in variable',
        nodeIcon: LiveHelpRounded,
        isMainNode: true,
        isEditable: true,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'question',
            }
        },
        isSendingMessageNode: true
    },
    'webhook': {
        type: 'webhook',
        container: WebhookNodeContainer,
        nodeComponent: WebhookNode,
        color: '#FFB906',
        title: 'Webhook',
        svgPath: "M10 15h5.88c.27-.31.67-.5 1.12-.5.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5c-.44 0-.84-.19-1.12-.5H11.9c-.46 2.28-2.48 4-4.9 4-2.76 0-5-2.24-5-5 0-2.42 1.72-4.44 4-4.9v2.07c-1.16.41-2 1.53-2 2.83 0 1.65 1.35 3 3 3s3-1.35 3-3v-1zm2.5-11c1.65 0 3 1.35 3 3h2c0-2.76-2.24-5-5-5s-5 2.24-5 5c0 1.43.6 2.71 1.55 3.62l-2.35 3.9c-.68.14-1.2.75-1.2 1.48 0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5c0-.16-.02-.31-.07-.45l3.38-5.63C10.49 9.61 9.5 8.42 9.5 7c0-1.65 1.35-3 3-3zm4.5 9c-.64 0-1.23.2-1.72.54l-3.05-5.07C11.53 8.35 11 7.74 11 7c0-.83.67-1.5 1.5-1.5S14 6.17 14 7c0 .15-.02.29-.06.43l2.19 3.65c.28-.05.57-.08.87-.08 2.76 0 5 2.24 5 5s-2.24 5-5 5c-1.85 0-3.47-1.01-4.33-2.5h2.67c.48.32 1.05.5 1.66.5 1.65 0 3-1.35 3-3s-1.35-3-3-3z",
        nodeIcon: Webhook,
        isMainNode: false,
        isEditable: true,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [{ id: `source-${id}-y`, type: 'source' }],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'webhook',
                defaultHandleId: `source-${id}-y`
            }
        },
        isSendingMessageNode: false
    },
    'condition': {
        type: 'condition',
        container: ConditionNodeContainer,
        nodeComponent: ConditionNode,
        color: '#6C7ED6',
        title: 'Condition',
        secondary_title: 'Trigger actions based on logical condition(s)',
        nodeIcon: SignpostOutlined,
        svgPath: "M13 10h5l3-3-3-3h-5V2h-2v2H4v6h7v2H6l-3 3 3 3h5v4h2v-4h7v-6h-7v-2zM6 6h11.17l1 1-1 1H6V6zm12 10H6.83l-1-1 1-1H18v2z",
        isMainNode: false,
        isEditable: true,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [
                    { id: `source-${id}-y`, type: 'source' },
                    { id: `source-${id}-n`, type: 'source' }
                ],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'condition',
                conditionResult: {
                    yResultHandleId: `source-${id}-y`,
                    nResultHandleId: `source-${id}-n`
                },
                // condition data structure
                // flowNodeConditions: {
                //     firstCondition: {
                //         flowConditionType: "GreaterThan",
                //         variable: "@myage",
                //         value: "23"
                //     },
                //     secondCondition: {
                //         flowConditionType: "LessThan",
                //         variable: "@myage",
                //         value: "23"
                //     },
                //     conditionOperator: "AND",
                // }
            }
        },
        isSendingMessageNode: false
    },
    'googleSheets': {
        type: 'googleSheets',
        container: GoogleSheetsContainer,
        nodeComponent: GoogleSheetsNode,
        color: '#35a762',
        title: 'Google Sheets',
        nodeIcon: Description,
        svgPath: "M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z",
        isMainNode: false,
        isEditable: true,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [
                    { id: `source-${id}-y`, type: 'source' },
                    { id: `source-${id}-y`, type: 'source' }
                ],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'googleSheets',
            }
        },
        isSendingMessageNode: false
    },
    'messageContact': {
        type: 'messageContact',
        container: SendMessageNodeContainer,
        nodeComponent: SendMessageNode,
        color: '#008080',
        title: 'Message Contact',
        nodeIcon: ContactMail,
        svgPath: "M21 8V7l-3 2-3-2v1l3 2 3-2zm1-5H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm8-6h-8V6h8v6z",
        isMainNode: false,
        isEditable: false,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [{ id: `source-${id}-1`, type: 'source' }],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'messageContact',
            }
        },
        isSendingMessageNode: false
    },
    'LLM': {
        type: 'LLM',
        container: SendMessageNodeContainer,
        nodeComponent: SendMessageNode,
        color: '#a7abde',
        title: 'LLM',
        secondary_title: 'AI Language Model',
        svgPath: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.93 0 3.5 1.57 3.5 3.5 0 .59-.15 1.15-.4 1.64.93.7 1.4 1.75 1.4 2.86v1.5c0 .83-.67 1.5-1.5 1.5h-6c-.83 0-1.5-.67-1.5-1.5V13c0-1.12.47-2.17 1.4-2.86-.25-.49-.4-1.05-.4-1.64C8.5 6.57 10.07 5 12 5zm-2.5 10h5v1h-5v-1zm0 2h5v1h-5v-1z",
        nodeIcon: SmartToy,
        isMainNode: false,
        isEditable: true,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [{ id: `source-${id}-y`, type: 'source' }],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'LLM',
                defaultHandleId: `source-${id}-y`,
            }
        },
        isSendingMessageNode: false
    },
    'agent': {
        type: 'agent',
        container: AgentNodeContainer,
        nodeComponent: AgentNode,
        color: '#4A90E2',
        title: 'Agent',
        isMainNode: true,
        secondary_title: 'AI Agent for complex tasks',
        nodeIcon: AgentIcon,
        svgPath: "M12 1C5.93 1 1 5.93 1 12s4.93 11 11 11 11-4.93 11-11S18.07 1 12 1zm0 5.5c1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5-3.5-1.57-3.5-3.5 1.57-3.5 3.5-3.5zM12 20c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z",
        isEditable: true,
        handles: (id: string) => {
            return {
                inputs: [{ id: `target-${id}-1`, type: 'target' }],
                outputs: [{ id: `source-${id}-y`, type: 'source' }],
            }
        },
        extraData: (id: string) => {
            return {
                type: 'agent',
                defaultHandleId: `source-${id}-y`,
                tools: [],
                prompt: '',
                asset: null
            }
        },
        isSendingMessageNode: false
    },
};

export const secondaryNodesThatLookLikeMainNodes = ['messageContact', 'LLM'];

export const nodesInDev = ['googleSheets']

export const edgeTypes = {
    'custom-edge': CustomEdge,
};

// export const defaultUserVariables = ['{{phoneNumber}}', '{{name}}']

export const flowConditionTypeArray = [
    'Equal to',
    'Not equal to',
    'Contains',
    'Does not contain',
    'Starts with',
    'Does not start with',
    'Greater than',
    'Less than',
]
