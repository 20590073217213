import BTButton from "@common-components/bt-button/bt-button";
import { Box, Stack, Container } from "@mui/material";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import classes from "./unsubscribe-view.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { useUnsubscribeFromListmonkMutation } from "@common-reducers/backend-api/backend-api";

const UnsubscribeView = () => {
  const { subscriptionID } = useParams();
  const [status, setStatus] = useState<'initial' | 'loading' | 'success' | 'error'>('initial');
  const [unsubscribeFromListmonk] = useUnsubscribeFromListmonkMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleUnsubscribe = async () => {
    try {
      setStatus('loading');
      await unsubscribeFromListmonk({ subscriptionID });
      setStatus('success');
    } catch (error) {
      console.error('Failed to unsubscribe:', error);
      setStatus('error');
      setErrorMessage('Failed to unsubscribe. Please try again later.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Stack spacing={4} alignItems="center" sx={{ py: 4 }}>
        <div className={classes.headerContainer}>
          <img className={classes.headerLogoImage} src="/img/logo.png" alt="Logo" />
        </div>

        <div className={classes.titleContainer}>
          <h3 className={classes.mainTitle}>Email Subscription</h3>
        </div>

        <div className={classes.contentContainer}>
          <Stack spacing={2} alignItems="center" textAlign="center">
            <p>Hi,</p>
            <p>
              Are you sure you want to unsubscribe from our email notifications?
            </p>
         

            <Box className={classes.statusContainer}>
              {status === 'initial' && (
                <BTButton
                  onClick={handleUnsubscribe}
                  sx={{
                    height: '40px',
                    minWidth: '150px'
                  }}
                >
                  Unsubscribe
                </BTButton>
              )}
              {status === 'loading' && (
                <Stack direction="row" spacing={2} alignItems="center">
                  <CircularProgress size={24} />
                  <span>Processing your request...</span>
                </Stack>
              )}
              {status === 'success' && (
                <Stack alignItems="center">
                  <h6>Successfully Unsubscribed</h6>
                  <p>You have been unsubscribed from our email notifications.</p>
                </Stack>
              )}
              {status === 'error' && (
                <Stack alignItems="center">
                  <h6 className={classes.error}>Error</h6>
                  <p>Sorry, we couldn't process your request. Please try again later.</p>
                  {errorMessage && <p className={classes.error}>{errorMessage}</p>}
                </Stack>
              )}
            </Box>
          </Stack>
        </div>
      </Stack>
    </Container>
  );
};

export default UnsubscribeView; 