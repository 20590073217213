import { isEqual } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

// Safe comparison function that handles revoked proxies
const safeIsEqual = (a: any, b: any): boolean => {
    try {
        // First, try to do a simple equality check
        if (a === b) return true;
        
        // If either value is null or undefined, they're not equal
        if (a == null || b == null) return a === b;
        
        // Try to safely clone the objects to avoid proxy issues
        let safeA, safeB;
        
        try {
            safeA = JSON.parse(JSON.stringify(a));
        } catch (error) {
            console.error('Error cloning first object in safeIsEqual:', error);
            return false;
        }
        
        try {
            safeB = JSON.parse(JSON.stringify(b));
        } catch (error) {
            console.error('Error cloning second object in safeIsEqual:', error);
            return false;
        }
        
        // Now use lodash isEqual on the safe copies
        return isEqual(safeA, safeB);
    } catch (error) {
        // If any error occurs (like with revoked proxies), log it and return false
        console.warn('Error in safeIsEqual comparison:', error);
        return false;
    }
};

// Create a "selector creator" that uses our safe comparison function
export const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    safeIsEqual
)