import { CircularProgress, ClickAwayListener, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
    Background,
    BackgroundVariant,
    Connection,
    Controls,
    MiniMap,
    Node as ReactFlowNode,
    NodeTypes,
    useReactFlow,
    XYPosition
} from 'reactflow';
import 'reactflow/dist/style.css';
import { BsTrash } from 'react-icons/bs';

import { addNodeThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { useChatbotFlow } from '@common/hooks/useChatbotFlow';
import {
    setIsLocked,
    setMenuPosition,
    setStartNode,
    updateViewport,
    setIsDragging
} from '@common/reducers/ChatbotReducer';

import RowStack from '@common-components/common/row-stack/RowStack';
import { getCurrentBotSelector } from '@common/reducers/ChatbotSelectors';
import NodeButton from './bot-components/node-button';
import BotHeader from './bot-header';
import classes from "./bt-chatbot.module.scss";
import { edgeTypes, nodesInDev, NodeTypeDef, nodeTypes } from './bt-nodes/bt-nodes-def';


interface MenuPosition {
    x: number;
    y: number;
    position: XYPosition;
}

interface ChatbotFlowProps {
    fitOnInit?: boolean;
}

export default function ChatbotFlow({ fitOnInit = false }: ChatbotFlowProps) {
    const dispatch = useAppDispatch();
    const isReadyToLoadFlow = useAppSelector(state => state.ChatbotReducer.isReadyToLoadFlow);
    const isLocked = useAppSelector(state => state.ChatbotReducer.isLocked);
    const startNode = useAppSelector(state => state.ChatbotReducer.startNode);
    const isReadOnly = useAppSelector(state => state.ChatbotReducer.isReadOnly);
    const currentBot = useAppSelector(state => getCurrentBotSelector(state));
    const loaderProgressMessage = useAppSelector(state => state.ChatbotReducer.loaderProgressMessage);
    const currentSessionId = useAppSelector(state => state.ChatbotReducer.currentSessionId);
    const isDragging = useAppSelector(state => state.ChatbotReducer.isDragging);

    const { nodes, edges, onNodesChange, onEdgesChange, onConnect } = useChatbotFlow();

    const reactFlowInstance = useReactFlow();
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const { getViewport, screenToFlowPosition, setViewport } = useReactFlow();

    const [menuLocalPosition, setLocalMenuPosition] = useState<MenuPosition | null>(null);
    const [hoveredElement, setHoveredElement] = useState<{ id: string, type: 'node' | 'edge' } | null>(null);

    const nodeComponentsByType = useMemo<NodeTypes>(() => {
        return Object.fromEntries(
            Object.entries(nodeTypes).map(([key, value]) => [key, value.nodeComponent])
        );
    }, []);

    const nodeColor = (node: ReactFlowNode) => {
        return nodeTypes?.[node.type]?.color;
    };

    const onMouseUp = useCallback((event: React.MouseEvent) => {
        if (!Object.values(nodeTypes).map(node => node.title.toLowerCase())
            .includes((event.target as HTMLElement).textContent?.toLowerCase() || '')) {
            const classNameCheck = (target: HTMLElement | null) =>
                typeof target?.className === 'string' ? target.className : '';

            const isreactFlowPane = classNameCheck(event?.target as HTMLElement).includes('react-flow__pane');
            const isHandle = classNameCheck(event?.target as HTMLElement).includes('react-flow__handle');

            if (!isLocked && isreactFlowPane && !isHandle && !isReadOnly) {
                // Get flow wrapper bounds
                const wrapperBounds = reactFlowWrapper.current?.getBoundingClientRect();
                const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
                
                // Calculate position relative to the flow wrapper
                const menuX = wrapperBounds ? event.clientX - wrapperBounds.left : event.clientX;
                const menuY = wrapperBounds ? event.clientY - wrapperBounds.top : event.clientY;

                setLocalMenuPosition({
                    x: menuX,
                    y: menuY,
                    position
                });
            } else {
                setLocalMenuPosition(null);
            }
        }
    }, [isLocked, isReadOnly, screenToFlowPosition]);

    const closeMenu = () => {
        setLocalMenuPosition(null);
    };

    function isConnection(variable: any): variable is Connection {
        return (
            variable &&
            typeof variable === 'object' &&
            'source' in variable &&
            'target' in variable &&
            'sourceHandle' in variable &&
            'targetHandle' in variable
        );
    }

    const clickAddNodeFromMenu = async (e: React.MouseEvent, type: string) => {
        e.stopPropagation();
        const addNodeWrapper = (node: Partial<ReactFlowNode>) => {
            reactFlowInstance.addNodes(node as ReactFlowNode);
        };
        const newEdgeConnection = await dispatch(addNodeThunk({
            type,
            addNodes: addNodeWrapper,
            startingNode: startNode
        }));

        if (isConnection(newEdgeConnection?.payload)) {
            onConnect(newEdgeConnection.payload);
        }
        dispatch(setStartNode(null));
        closeMenu();
    };

    const handleOnConnect = (connection: Connection) => {
        dispatch(setStartNode(null));
        onConnect(connection);
    };

    const hasInitiallyFitted = useRef(false);
    const initialViewportApplied = useRef(false);

    const updateCenter = useCallback(() => {
        if (reactFlowWrapper.current) {
            const viewport = getViewport();
            dispatch(updateViewport(viewport));
        }
    }, [dispatch, getViewport]);

    useEffect(() => {
        if (isReadyToLoadFlow && 
            currentBot?.viewport && 
            !initialViewportApplied.current) {
            
            setViewport(currentBot.viewport);
            initialViewportApplied.current = true;
            console.log("Applied initial saved viewport:", currentBot.viewport);
        }
    }, [isReadyToLoadFlow, currentBot?.viewport, setViewport]);

    useEffect(() => {
        if (!hasInitiallyFitted.current && 
            isReadyToLoadFlow && 
            nodes.length > 0 && 
            (fitOnInit || isReadOnly)) {
            
            const timer = setTimeout(() => {
                console.log("Fitting view on initial load");
                
                reactFlowInstance.fitView({
                    padding: 0.2,
                    includeHiddenNodes: false,
                    minZoom: 0,
                    maxZoom: 2
                });
                
                hasInitiallyFitted.current = true;
                
                updateCenter();
            }, 300);
            
            return () => clearTimeout(timer);
        }
    }, [isReadyToLoadFlow, nodes.length, fitOnInit, isReadOnly, reactFlowInstance, updateCenter]);

    useEffect(() => {
        if (currentBot?.id) {
            hasInitiallyFitted.current = false;
            initialViewportApplied.current = false;
        }
    }, [currentBot?.id]);

    useEffect(() => {
        if (currentSessionId) {
            hasInitiallyFitted.current = false;
            initialViewportApplied.current = false;
        }
    }, [currentSessionId]);

    const onConnectStart = useCallback((event: React.MouseEvent, params: any) => {
        if (isLocked) return;
        dispatch(setStartNode({ id: params.handleId, type: params.handleType, nodeId: params.nodeId }));
    }, [dispatch, isLocked]);

    const handleLockClick = (isUnlocked: boolean) => {
        dispatch(setIsLocked(!isUnlocked));
    };

    useEffect(() => {
        dispatch(setMenuPosition(menuLocalPosition));
    }, [dispatch, menuLocalPosition]);

    // Add these new handlers for drag and drop
    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);

    const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation(); // Prevent context menu from opening
        
        
        if (!reactFlowWrapper.current || isLocked || isReadOnly) {
            return;
        }
        
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const nodeType = event.dataTransfer.getData('application/reactflow');
        
        if (!nodeType) {
            return;
        }
        
        // Calculate position in flow coordinates
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        
        // Define addNodeWrapper to override the position
        const addNodeWrapper = (node: Partial<ReactFlowNode>) => {
            node.position = position;
            reactFlowInstance.addNodes(node as ReactFlowNode);
        };
        
        // Get current viewport
        const viewport = reactFlowInstance.getViewport();
        
        // Add the node
        dispatch(addNodeThunk({
            type: nodeType,
            addNodes: addNodeWrapper,
            fromToolbar: true,
            viewport: viewport
        }));
    }, [dispatch, isLocked, isReadOnly, reactFlowInstance]);

    // Fix the onDragLeave callback to use the correct Node type
    const onDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        // Only handle actual leaves from the container (not child elements)
        if (event.currentTarget.contains(event.relatedTarget as Element)) {
            return;
        }
    }, []);

    // Add handlers for node dragging
    const onNodeDragStart = useCallback(() => {
        dispatch(setIsDragging(true));
    }, [dispatch]);
    
    const onNodeDragStop = useCallback(() => {
        dispatch(setIsDragging(false));
        // We call updateCenter to save viewport after dragging finishes
        updateCenter();
    }, [dispatch, updateCenter]);

    // Add these handlers for hover events
    const onNodeMouseEnter = useCallback((event: React.MouseEvent, node: ReactFlowNode) => {
        if (isLocked || isReadOnly) return;
        setHoveredElement({ id: node.id, type: 'node' });
    }, [isLocked, isReadOnly]);
    
    const onNodeMouseLeave = useCallback(() => {
        setHoveredElement(null);
    }, []);
    
    const onEdgeMouseEnter = useCallback((event: React.MouseEvent, edge: any) => {
        if (isLocked || isReadOnly) return;
        setHoveredElement({ id: edge.id, type: 'edge' });
    }, [isLocked, isReadOnly]);
    
    const onEdgeMouseLeave = useCallback(() => {
        setHoveredElement(null);
    }, []);
    
    // Handler to delete the hovered element
    const deleteElement = useCallback(() => {
        if (!hoveredElement || isLocked || isReadOnly) return;
        
        if (hoveredElement.type === 'node') {
            // Delete node and its connected edges
            const nodeToDelete = nodes.find(node => node.id === hoveredElement.id);
            if (nodeToDelete) {
                reactFlowInstance.deleteElements({ nodes: [nodeToDelete] });
            }
        } else {
            // Delete edge
            const edgeToDelete = edges.find(edge => edge.id === hoveredElement.id);
            if (edgeToDelete) {
                reactFlowInstance.deleteElements({ edges: [edgeToDelete] });
            }
        }
        
        setHoveredElement(null);
        updateCenter(); // Update viewport after deletion
    }, [hoveredElement, isLocked, isReadOnly, nodes, edges, reactFlowInstance, updateCenter]);

    // Add keyboard event listener for delete key
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Delete' && hoveredElement && !isLocked && !isReadOnly) {
                deleteElement();
            }
        };
        
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [deleteElement, hoveredElement, isLocked, isReadOnly]);

    if (!isReadyToLoadFlow) {
        return (
            <Stack sx={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: 'calc(100% - 250px)',
            }}>
                <CircularProgress sx={{
                    height: '55px !important',
                    width: '55px !important',
                }} />
                <RowStack sx={{ alignItem: 'flex-end', marginTop: '25px', }}>
                    <span style={{
                        fontSize: '26px',
                        paddingBottom: '11px'
                    }}>{loaderProgressMessage || 'Loading'}</span>
                    <div className={classes.bot_loader}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </RowStack>
            </Stack>
        );
    }

    return (
        <Stack
            sx={{
                width: isReadOnly ? '100%' : 'calc(100% - 250px)',
                height: '100%',
                cursor: isDragging ? 'grabbing' : 'default',
                position: 'relative',
            }}
            id='flow-wrapper'
            ref={reactFlowWrapper}
            onMouseUp={onMouseUp}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onContextMenu={(e) => e.preventDefault()}
        >
            {!isReadOnly && <BotHeader />}
            <ReactFlow
                onLoad={updateCenter}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={handleOnConnect}
                onConnectStart={onConnectStart}
                onContextMenu={(e) => e.preventDefault()}
                defaultViewport={{ x: 100, y: 400, zoom: 0.8 }}
                onMoveEnd={updateCenter}
                proOptions={{ hideAttribution: true }}
                nodeTypes={nodeComponentsByType}
                edgeTypes={edgeTypes}
                edgesUpdatable={!isLocked && !isReadOnly}
                edgesFocusable={!isLocked && !isReadOnly}
                nodesDraggable={!isLocked && !isReadOnly}
                nodesConnectable={!isLocked && !isReadOnly}
                nodesFocusable={!isLocked && !isReadOnly}
                elementsSelectable={!isLocked}
                style={{ width: '100%', height: '100%' }}
                minZoom={0}
                fitView={false}
                onNodeDragStart={onNodeDragStart}
                onNodeDragStop={onNodeDragStop}
                onNodeMouseEnter={onNodeMouseEnter}
                onNodeMouseLeave={onNodeMouseLeave}
                onEdgeMouseEnter={onEdgeMouseEnter}
                onEdgeMouseLeave={onEdgeMouseLeave}
            >
                <Controls className={classes.custom_controls} onInteractiveChange={handleLockClick} />
                <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
                <Background color="#EEF0F6" variant={'lines' as BackgroundVariant} gap={50} size={1} />
            </ReactFlow>
            
            {menuLocalPosition && (
                <ClickAwayListener onClickAway={closeMenu}>
                    <div style={{
                        position: 'absolute',
                        top: menuLocalPosition.y,
                        left: menuLocalPosition.x,
                        backgroundColor: 'white',
                        maxHeight: '270px',
                        overflowY: 'auto',
                        zIndex: 1000,
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}>
                        <Stack sx={{ margin: '5px 10px', }}>
                            {Object.values(nodeTypes)
                                .filter(node => !nodesInDev.includes(node.type))
                                .map((node: NodeTypeDef, index) => (
                                    <div onClick={(e) => clickAddNodeFromMenu(e, node.type)} key={node.type}>
                                        <NodeButton
                                            isMinimal={true}
                                            bgColor={node.color}
                                            title={node.title}
                                            secondary_title={node.secondary_title}
                                            NodeIcon={node.nodeIcon}
                                            isLast={index === (Object.values(nodeTypes).length - 1)}
                                        />
                                    </div>
                                ))}
                        </Stack>
                    </div>
                </ClickAwayListener>
            )}
        </Stack>
    );
}