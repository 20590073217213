import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { ToolDefinition } from '../../../../../types/tool-types';
import { useGetAvailableToolsMutation } from '../../../../../services/tools.service';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { setAvailableTools } from '@common-reducers/ToolsReducer';
import { CommonRootState } from '@/common/types/common-root-state-type';
import { useSelector } from 'react-redux';
import { getNodeDataByNodeId } from '@/common/reducers/ChatbotSelectors';
import { closeToolsDialog } from '@/common/reducers/ToolSettingsModalReducer';
import { changeNodeDataThunk } from '@/common/reducers/ChatbotThunks';

export default function SelectToolsDialog() {
  // Use the selectedTools prop directly for initial state, but maintain local state for UI interactions

  const open = useSelector((state: CommonRootState) => state.ToolSettingsModalReducer.isToolsDialogOpen);
  const nodeId = useSelector((state: CommonRootState) => state.ToolSettingsModalReducer.nodeId);
  const nodeData = useSelector((state: CommonRootState) => getNodeDataByNodeId(state, nodeId));

  const [selected, setSelected] = useState<string[]>([]);
  const [tools, setTools] = useState<ToolDefinition[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useAppDispatch();
  
  // Use the RTK Query hook
  const [getAvailableTools, { isLoading }] = useGetAvailableToolsMutation();

  const fetchTools = useCallback(async () => {
    setError(null);
    
    try {
      // Use the RTK Query hook to fetch tools
      const result = await getAvailableTools();
      
      if ('data' in result && result.data.success) {
        const fetchedTools = result.data.tools;
        setTools(fetchedTools);
        
        // Store the tools in the Redux store
        dispatch(setAvailableTools(fetchedTools));
      } else {
        setError('Failed to load tools. Please try again later.');
      }
    } catch (error) {
      console.error('Error fetching tools:', error);
      setError('Failed to load tools. Please try again later.');
    }
  }, [getAvailableTools, dispatch]);

  // Reset selected state when dialog opens or selectedTools changes
  useEffect(() => {
    if (open) {
      fetchTools();
      // Initialize selected state from props
      setSelected(nodeData?.tools || []);
    }
  }, [fetchTools, nodeData?.tools, open]);

  const handleToggle = useCallback((toolId: string) => {
    let newSelected = [...selected];
    if (newSelected.includes(toolId)) {
      newSelected = newSelected.filter(id => id !== toolId);
    } else {
      newSelected.push(toolId);
    }
    setSelected(newSelected);
    
  }, [selected]);

  const handleClose = useCallback(() => {
    dispatch(closeToolsDialog());
  }, [dispatch]);

  const handleSave = useCallback((e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    
    // Pass the selected tools to the parent component
    console.log('Saving selected tools:', selected);
    dispatch(changeNodeDataThunk({
      id: nodeId,
      dataToPatch: {
        tools: selected
      }
    }));
    handleClose();
  }, [selected, dispatch, nodeId, handleClose]);

  // Filter tools based on search query
  const filteredTools = tools.filter(tool => 
    tool.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
    tool.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="md" 
      fullWidth
      onClick={(e) => e.stopPropagation()}
      PaperProps={{
        style: {
          borderRadius: '0.5rem',
          overflow: 'hidden'
        },
        onClick: (e) => e.stopPropagation()
      }}
    >
      {/* Dialog Header */}
      <div className="bg-white border-b border-gray-200" onClick={(e) => e.stopPropagation()}>
        <div className="flex items-center justify-between px-6 py-4">
          <h2 className="text-xl font-semibold text-gray-800">Select Tools</h2>
          <button 
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            className="p-1 rounded-full text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-colors duration-200"
          >
            <CloseIcon style={{ fontSize: '20px' }} />
          </button>
        </div>
      </div>
      
      {/* Search Input */}
      <div className="px-6 py-4 border-b border-gray-200" onClick={(e) => e.stopPropagation()}>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon style={{ fontSize: '18px' }} className="text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search tools..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-sm"
          />
        </div>
      </div>
      
      {/* Dialog Content */}
      <div className="max-h-[400px] overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        {isLoading ? (
          <div className="flex justify-center items-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
          </div>
        ) : error ? (
          <div className="p-4 text-red-500 text-center">
            {error}
          </div>
        ) : filteredTools.length === 0 ? (
          <div className="p-4 text-gray-500 text-center">
            {searchQuery ? 'No tools match your search.' : 'No tools available.'}
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {filteredTools.map((tool) => {
              const isSelected = selected.indexOf(tool.id) !== -1;
              
              return (
                <div 
                  key={tool.id}
                  className={`p-4 hover:bg-gray-50 cursor-pointer transition-colors duration-200 ${isSelected ? 'bg-indigo-50' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggle(tool.id);
                  }}
                >
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleToggle(tool.id);
                        }}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label className={`font-medium ${isSelected ? 'text-indigo-700' : 'text-gray-700'}`}>
                        {tool.name}
                      </label>
                      <p className="text-gray-500 mt-1">{tool.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      
      {/* Dialog Footer */}
      <div className="bg-gray-50 px-4 py-3 flex justify-end space-x-2 border-t border-gray-200" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleSave();
          }}
          disabled={isLoading}
          className={`px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md ${
            isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          } transition-colors duration-200`}
        >
          Save
        </button>
      </div>
    </Dialog>
  );
} 