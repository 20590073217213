import { nodeTypes } from "@common-components/bt-chatbot/bt-nodes/bt-nodes-def";
import { Bot } from "@common-models/bot";
import { BotDeployment } from "@common-models/bot-deployment";
import { EventType } from "@common/models/node-event";
import { automationSessionSelectByQuery, botDeploymentSelectByQuery, botDeploymentSelectOneObjectByQuery, botSelectByQuery, botSelectOneObjectByQuery, nodeEventsSelectByQuery } from "@common-reducers/DBServiceThunks";
import { createDeepEqualSelector } from "@common-services/deep-equal-selector";
import { ChatbotState } from "./ChatbotReducer";
import { analyzeBotTriggerScope, canBotsOverlap } from '../utils/trigger-analysis';
import moment from "moment";

// Check if a bot's flow contains message-sending nodes
export const hasBotMessageSendingNodes = (flowJSON?: string): boolean => {
    if (!flowJSON) return false;

    try {
        const flowData = JSON.parse(flowJSON);
        return flowData?.nodes?.some((node: any) => {
            const nodeType = nodeTypes[node.type];
            return nodeType?.isSendingMessageNode;
        }) ?? false;
    } catch {
        return false;
    }
};

// Get all active bots that have message-sending nodes (excluding a specific bot)
export const getActiveBotsWithMessageNodes = createDeepEqualSelector(
    [
        (state: { DBBotReducer: any }) => state.DBBotReducer,
        (state: any, excludeBotId?: string) => excludeBotId,
    ],
    (DBBotReducer, excludeBotId): Bot[] => {
        const allBots = botSelectByQuery(DBBotReducer, {}) ?? [];
        return allBots.filter(bot => {
            if (bot._id === excludeBotId) return false;
            const hasActiveDeployment = !!bot?.activeDeploymentId;
            return hasBotMessageSendingNodes(bot?.flowJSON) && hasActiveDeployment;
        });
    }
);

// Check if there are any overlapping active bots (bots that can send messages)
export const hasOverlappingMessageBots = createDeepEqualSelector(
    [
        (state: any, botId?: string) => getActiveBotsWithMessageNodes(state, botId),
    ],
    (activeBotsWithMessageNodes) => {
        return activeBotsWithMessageNodes.length > 0;
    }
);

// Get warning message if there are overlapping bots
export const getOverlapWarningDetails = createDeepEqualSelector(
    [
        (state: any, botId?: string) => getOverlappingBots(state, botId),
    ],
    (overlappingBots): { alreadyActiveMessageSendingBotNames: string[]; warningMessage: string } => {
        const overlappingBotNames = overlappingBots.map(bot => bot?.name);
        const numberOfOverlappingBots = overlappingBots.length;

        let warningMessage = '';
        if (numberOfOverlappingBots > 0) {
            warningMessage = numberOfOverlappingBots === 1
                ? 'Another bot is already active and can receive messages from the same contacts'
                : `${numberOfOverlappingBots} bots are already active and can receive messages from the same contacts`;
        }

        return {
            alreadyActiveMessageSendingBotNames: overlappingBotNames,
            warningMessage
        };
    }
);

// Get node by node ID (optimized)
export const getNodeByNodeId = createDeepEqualSelector(
    [
        (state: { ChatbotReducer: ChatbotState }) => state.ChatbotReducer.nodes,
        (state: { ChatbotReducer: ChatbotState }, id: string) => id,
    ],
    (nodes, id) => {
        const node = nodes?.find(node => node.id === id);
        return node ? node : undefined; // Don't create a new object unnecessarily
    }
);

// Get node data by node ID (optimized)
export const getNodeDataByNodeId = createDeepEqualSelector(
    [
        // Select only the nodes array directly
        (state: { ChatbotReducer: ChatbotState }) => state.ChatbotReducer.nodes,
        (state: { ChatbotReducer: ChatbotState }, id: string) => id,
    ],
    (nodes, id) => {
        // Find the node directly without going through another selector
        const node = nodes?.find(node => node.id === id);
        // Return the data directly without creating a new object
        return node?.data;
    }
);

export const getEdgeDataByEdgeId = createDeepEqualSelector(
    [
        (state: { ChatbotReducer: ChatbotState }) => state.ChatbotReducer.edges,
        (state: { ChatbotReducer: ChatbotState }, id: string) => id,
    ],
    (edges, id) => {
        const edge = edges?.find(edge => edge.id === id);
        if (!edge) return null;
        return { ...edge }; // Return a shallow copy of the edge
    }
);

export const isAlreadyActiveChatbot = createDeepEqualSelector(
    [
        (state) => state.DBBotReducer,
        (state) => getCurrentBotSelector(state) ?? {},
    ],
    (DBBotReducer, currentBot) => {
        try {
            if (!DBBotReducer) {
                return false;
            }

            const bots = botSelectByQuery(DBBotReducer, {}) || [];

            if (!Array.isArray(bots)) {
                console.warn("botSelectByQuery did not return an array:", bots);
                return false;
            }

            if (!currentBot || !currentBot._id) {
                return false;
            }

            return bots.some(bot =>
                bot &&
                bot.activeDeploymentId &&
                bot._id !== currentBot._id
            );
        } catch (error) {
            console.error("Error in isAlreadyActiveChatbot:", error);
            return false;
        }
    }
);

export const getCurrentBotId = createDeepEqualSelector(
    [
        (state) => getCurrentBotSelector(state),
    ],
    (currentBot) => {
        try {
            if (!currentBot) return '';

            // Check if _id exists and is a string
            if (typeof currentBot._id !== 'string') {
                console.warn("Invalid _id in currentBot:", currentBot._id);
                return '';
            }

            return currentBot._id;
        } catch (error) {
            console.error("Error in getCurrentBotId:", error);
            return '';
        }
    }
);

export const getHandleConnections = createDeepEqualSelector(
    [
        (state) => state.ChatbotReducer.edges,
        (state, id: string) => id,
    ],
    (edges, id) => {
        // Create a new array to avoid modifying the original
        return edges?.filter(edge => edge.sourceHandle === id || edge.targetHandle === id).map(edge => ({ ...edge }));
    }
);

export const hasMessageSendingNodes = createDeepEqualSelector(
    [
        (state: { ChatbotReducer: ChatbotState }) => state.ChatbotReducer.nodes,
    ],
    (nodes) => {
        return nodes?.some(node => {
            const nodeType = nodeTypes[node.type];
            return nodeType?.isSendingMessageNode;
        }) ?? false;
    }
);

export const getAutomationSessionIdsByBotDeploymentIdSelector = createDeepEqualSelector(
    [
        (state) => state.DBAutomationSessionReducer,
        (state, botDeploymentId: string) => botDeploymentId,
    ],
    (DBAutomationSessionReducer, botDeploymentId) => {
        return automationSessionSelectByQuery(DBAutomationSessionReducer, {
            botDeploymentId: botDeploymentId,
            status: 'open'
        }).map(session => session._id);
    }
);

export const getBotDeploymentIdsByBotIdSelector = createDeepEqualSelector(
    [
        (state) => state.DBBotDeploymentReducer,
        (state, botId: string) => botId,
    ],
    (DBBotDeploymentReducer, botId) => {
        const botDeploymentsByBotId = botDeploymentSelectByQuery(DBBotDeploymentReducer, {
            botId: botId,
        });
        console.log('getBotDeploymentIdsByBotIdSelector - botDeploymentsByBotId:', botDeploymentsByBotId);
        const sortedBotDeployments = botDeploymentsByBotId?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        const deploymentIds = sortedBotDeployments?.map(deployment => deployment._id);
        console.log('getBotDeploymentIdsByBotIdSelector - deploymentIds:', deploymentIds);
        return deploymentIds || [];
    }
);

export const getOverlappingBots = createDeepEqualSelector(
    [
        (state: { DBBotReducer: any }) => state.DBBotReducer,
        (state: any, botId: string) => botId,
    ],
    (DBBotReducer, botId) => {
        try {
            if (!DBBotReducer || !botId) {
                return [];
            }

            let allBots = [];
            try {
                allBots = botSelectByQuery(DBBotReducer, {}) || [];

                // Ensure allBots is an array
                if (!Array.isArray(allBots)) {
                    console.warn("botSelectByQuery did not return an array:", allBots);
                    return [];
                }
            } catch (error) {
                console.error("Error in botSelectByQuery:", error);
                return [];
            }

            // Find the bot to check
            const botToCheck = allBots.find(bot => bot && bot._id === botId);
            if (!botToCheck) {
                return [];
            }

            // First analyze the bot's trigger scope
            let botScope;
            try {
                botScope = analyzeBotTriggerScope(botToCheck.flowJSON);
            } catch (error) {
                console.error("Error analyzing bot trigger scope:", error);
                return [];
            }

            // A bot that can't receive messages can't overlap with others
            if (botScope.type === 'NO_MESSAGES') {
                return [];
            }

            // A bot must have message sending nodes to be considered for overlap
            try {
                if (!hasBotMessageSendingNodes(botToCheck.flowJSON)) {
                    return [];
                }
            } catch (error) {
                console.error("Error checking if bot has message sending nodes:", error);
                return [];
            }

            // Filter overlapping bots
            const overlappingBots = allBots.filter(bot => {
                try {
                    // Skip invalid bots
                    if (!bot || !bot._id) {
                        return false;
                    }

                    // Skip self and inactive bots
                    if (bot._id === botId || !bot.activeDeploymentId) {
                        return false;
                    }

                    // Skip bots that can't send messages
                    try {
                        if (!hasBotMessageSendingNodes(bot.flowJSON)) {
                            return false;
                        }
                    } catch (error) {
                        console.error("Error checking if bot has message sending nodes:", error);
                        return false;
                    }

                    // Analyze the other bot's trigger scope
                    let otherBotScope;
                    try {
                        otherBotScope = analyzeBotTriggerScope(bot.flowJSON);
                    } catch (error) {
                        console.error("Error analyzing other bot trigger scope:", error);
                        return false;
                    }

                    // Skip bots that can't receive messages
                    if (otherBotScope.type === 'NO_MESSAGES') {
                        return false;
                    }

                    // Check for actual message overlap
                    try {
                        return canBotsOverlap(botScope, otherBotScope);
                    } catch (error) {
                        console.error("Error checking if bots can overlap:", error);
                        return false;
                    }
                } catch (error) {
                    console.error("Error processing bot in filter:", error);
                    return false;
                }
            });

            return overlappingBots;
        } catch (error) {
            console.error("Error in getOverlappingBots:", error);
            return [];
        }
    }
);

export const getCurrentBotSelector = createDeepEqualSelector(
    [
        // Only select the specific pieces needed instead of entire state
        (state) => state?.ChatbotReducer?.isDraft,
        (state) => state?.ChatbotReducer?.isReadOnly,
        (state) => state?.ChatbotReducer?.currentBot,
        (state) => state?.ChatbotReducer?.slugBotId,
        (state) => state?.DBBotReducer,
    ],
    (isDraft, isReadOnly, currentBot, slugBotId, DBBotReducer) => {
        try {
            let result = null;

            if (isDraft || isReadOnly) {
                // Get the current bot
                // Return null if no current bot
                if (!currentBot) return null;

                // Try to safely clone the object
                try {
                    result = JSON.parse(JSON.stringify(currentBot));
                } catch (error) {
                    console.error("Error cloning currentBot:", error);

                    // Fallback: try to create a minimal safe copy with just the essential properties
                    try {
                        result = {
                            _id: currentBot._id,
                            name: currentBot.name,
                            // Add other essential properties as needed
                        };
                    } catch (innerError) {
                        console.error("Error creating minimal bot copy:", innerError);
                        return null;
                    }
                }
            } else {
                if (!slugBotId || !DBBotReducer) {
                    return null;
                }

                try {
                    // Get the bot from the DB
                    const bot = botSelectOneObjectByQuery(DBBotReducer, { _id: slugBotId });
                    if (!bot) return null;

                    // Try to safely clone the object
                    try {
                        result = JSON.parse(JSON.stringify(bot));
                    } catch (error) {
                        console.error("Error cloning bot from DB:", error);

                        // Fallback: try to create a minimal safe copy
                        try {
                            result = {
                                _id: bot._id,
                                name: bot.name,
                                // Add other essential properties as needed
                            };
                        } catch (innerError) {
                            console.error("Error creating minimal bot copy:", innerError);
                            return null;
                        }
                    }
                } catch (error) {
                    console.error("Error in botSelectOneObjectByQuery:", error);
                    return null;
                }
            }

            return result;
        } catch (error) {
            return null;
        }
    }
);


export type SessionFilter = 'all' | 'active' | 'inactive';

export const getFilteredSessionIdsByBotDeploymentIdSelector = createDeepEqualSelector(
    [
        (state) => state.DBAutomationSessionReducer,
        (state: any, deploymentId: string) => deploymentId,
        (state: any, deploymentId: string, filter: SessionFilter) => filter,
    ],
    (DBAutomationSessionReducer, deploymentId, filter) => {
        const sessions = automationSessionSelectByQuery(DBAutomationSessionReducer, {
            botDeploymentId: deploymentId,
        }, null, { createdAt: -1 });

        const filteredSessions = sessions
            .filter(session => {
                if (filter === 'all') return true;
                if (filter === 'active') return session.status === 'open';
                if (filter === 'inactive') return session.status === 'closed';
                return true;
            })
            .map(session => session._id);
        return filteredSessions;
    }
);

export const getIsFromNodeEventSelector = createDeepEqualSelector(
    [
        (state) => state.ChatbotReducer,
        (state) => state.DBNodeEventsReducer,
        (state, id, sessionId) => id,
        (state, id, sessionId) => sessionId,
    ],
    (ChatbotState, NodeEventsState, id, sessionId) => {
        const isReadOnly = ChatbotState.isReadOnly;
        if (!isReadOnly || !sessionId?.length) return true;
        const nodeEntryEvents = nodeEventsSelectByQuery(NodeEventsState, {
            jsonId: id,
            sessionId,
            type: EventType.NODE_ENTRY
        });
        return nodeEntryEvents?.length > 0;
    }
);

export const getFilteredEventListBySessionId = createDeepEqualSelector(
    [
        (state) => state.DBNodeEventsReducer,
        (state) => state.ChatbotReducer,
        (state, sessionId: string) => sessionId,
    ],
    (NodeEventsState, ChatbotState, sessionId) => {
        let events = nodeEventsSelectByQuery(NodeEventsState, {
            sessionId: sessionId
        }, null, {
            createdAt: -1
        });

        // Apply node focus filter
        const focusedNodeId = ChatbotState?.focusedNodeId;
        if (focusedNodeId) {
            events = events.filter(event => event.jsonId === focusedNodeId);
        }

        // Apply event type filters
        const { showEvents, showChat } = ChatbotState;
        return events.filter(event => {
            const isMessageEvent = event.type === EventType.OUTGOING_MESSAGE || event.type === EventType.INCOMING_MESSAGE;
            const isSessionEvent = event.type === EventType.SESSION_STARTED || event.type === EventType.SESSION_ENDED;

            if (isMessageEvent) {
                return showChat;
            }

            // Session events are shown when either chat or events are visible
            if (isSessionEvent) {
                return showChat || showEvents;
            }

            return showEvents;
        });
    }
);

export const getEffectiveDeploymentIdSelector = createDeepEqualSelector(
    [
        (state) => state.DBBotReducer,
        (state, botId: string) => botId,
        (state, botId: string, manuallySelectedDeploymentId: string | null) => manuallySelectedDeploymentId,
    ],
    (state, botId, manuallySelectedDeploymentId) => {
        // If manuallySelectedDeploymentId is explicitly set to null, it means "All Versions" is selected
        if (manuallySelectedDeploymentId === null) {
            return null;
        }

        const bot = botSelectOneObjectByQuery(state, { _id: botId });
        const deploymentIds = getBotDeploymentIdsByBotIdSelector(state, botId);

        // Calculate the effective selected deployment ID based on priority:
        // 1. Manually selected deployment
        // 2. Active deployment (if valid)
        // 3. Latest deployment (first in sorted list)
        // 4. null if none available
        return manuallySelectedDeploymentId ||
            (bot?.activeDeploymentId && deploymentIds.includes(bot.activeDeploymentId) ? bot.activeDeploymentId :
                (deploymentIds.length > 0 ? deploymentIds[0] : null));
    }
);

export const getIsDraftOlderThanDeploymentSelector = createDeepEqualSelector(
    [
        // Use getCurrentBotSelector as an input selector instead of calling it inside
        getCurrentBotSelector,
        // Only select the specific reducer needed for deployment lookup
        (state) => state.DBBotDeploymentReducer,
    ],
    (currentBot, DBBotDeploymentReducer) => {
        if (!currentBot?.activeDeploymentId) return false;

        const activeDeployment = botDeploymentSelectOneObjectByQuery(
            DBBotDeploymentReducer,
            { _id: currentBot.activeDeploymentId }
        );

        if (!activeDeployment || !currentBot.updatedAt || !activeDeployment.updatedAt) return false;

        const draftLastUpdateTime = moment(currentBot.updatedAt).valueOf() - 2000;
        const deploymentLastUpdateTime = moment(activeDeployment.updatedAt).valueOf();

        return deploymentLastUpdateTime < draftLastUpdateTime;
    }
);





