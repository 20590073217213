/**
 * Tool Settings Validator
 * 
 * This module provides validation functions for each tool type to determine
 * if the tool has been properly configured.
 */

/**
 * Validates knowledge base tool settings
 * @param settings The knowledge base tool settings
 * @returns Validation result with isValid flag and error message
 */
const validateKnowledgeBase = (settings: any): { isValid: boolean; message: string } => {
  // Debug log to understand the structure
  console.log('Validating knowledge base settings:', JSON.stringify(settings, null, 2));
  
  if (!settings || Object.keys(settings).length === 0) {
    return { 
      isValid: false, 
      message: "Knowledge Base requires configuration. Please select at least one knowledge base." 
    };
  }

  // Check if knowledgeBaseIds exists and has at least one entry
  if (!settings.knowledgeBaseIds || !Array.isArray(settings.knowledgeBaseIds) || settings.knowledgeBaseIds.length === 0) {
    return { 
      isValid: false, 
      message: "Please select at least one knowledge base." 
    };
  }

  return { isValid: true, message: "" };
};

/**
 * Validates Freshdesk tool settings
 * @param settings The Freshdesk tool settings
 * @returns Validation result with isValid flag and error message
 */
const validateFreshdesk = (settings: any): { isValid: boolean; message: string } => {
  if (!settings || Object.keys(settings).length === 0) {
    return { 
      isValid: false, 
      message: "Freshdesk requires configuration. Please set up your Freshdesk integration." 
    };
  }

  // Check for required fields
  if (!settings.apiKey || !settings.domain) {
    return { 
      isValid: false, 
      message: "Freshdesk requires an API key and domain." 
    };
  }

  return { isValid: true, message: "" };
};

/**
 * Validates Gmail tool settings
 * @param settings The Gmail tool settings
 * @returns Validation result with isValid flag and error message
 */
const validateGmail = (settings: any): { isValid: boolean; message: string } => {
  if (!settings || Object.keys(settings).length === 0) {
    return { 
      isValid: false, 
      message: "Gmail requires configuration. Please connect your Gmail account." 
    };
  }

  // Check if Gmail account is connected
  if (!settings.connected || !settings.email) {
    return { 
      isValid: false, 
      message: "Please connect your Gmail account." 
    };
  }

  return { isValid: true, message: "" };
};

/**
 * Validates LinkedIn tool settings
 * @param settings The LinkedIn tool settings
 * @returns Validation result with isValid flag and error message
 */
const validateLinkedIn = (settings: any): { isValid: boolean; message: string } => {
  if (!settings || Object.keys(settings).length === 0) {
    return { 
      isValid: false, 
      message: "LinkedIn requires configuration. Please connect your LinkedIn account." 
    };
  }

  // Check if LinkedIn account is connected
  if (!settings.connected || !settings.profileId) {
    return { 
      isValid: false, 
      message: "Please connect your LinkedIn account." 
    };
  }

  return { isValid: true, message: "" };
};

/**
 * Validates Google Drive tool settings
 * @param settings The Drive tool settings
 * @returns Validation result with isValid flag and error message
 */
const validateDrive = (settings: any): { isValid: boolean; message: string } => {
  if (!settings || Object.keys(settings).length === 0) {
    return { 
      isValid: false, 
      message: "Google Drive requires configuration. Please connect your Google Drive account." 
    };
  }

  // Check if Google Drive account is connected
  if (!settings.connected || !settings.email) {
    return { 
      isValid: false, 
      message: "Please connect your Google Drive account." 
    };
  }

  return { isValid: true, message: "" };
};

/**
 * Default validation for tools without specific validation logic
 * @param settings The tool settings
 * @returns Validation result with isValid flag and error message
 */
const validateDefault = (settings: any): { isValid: boolean; message: string } => {
  if (!settings || Object.keys(settings).length === 0) {
    return { 
      isValid: false, 
      message: "This tool requires configuration." 
    };
  }

  return { isValid: true, message: "" };
};

/**
 * Main validation function that routes to the appropriate validator based on tool name
 * @param toolName The name of the tool to validate
 * @param settings The settings for the tool
 * @returns Validation result with isValid flag and error message
 */
export const validateToolSettings = (
  toolName: string, 
  settings: any
): { isValid: boolean; message: string } => {
  // Convert tool name to lowercase for case-insensitive comparison
  const normalizedToolName = toolName.toLowerCase();
  
  switch (normalizedToolName) {
    case 'knowledge-base':
      return validateKnowledgeBase(settings);
    case 'freshdesk':
      return validateFreshdesk(settings);
    case 'gmail':
      return validateGmail(settings);
    case 'linkedin':
      return validateLinkedIn(settings);
    case 'drive':
      return validateDrive(settings);
    default:
      return validateDefault(settings);
  }
}; 