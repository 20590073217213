import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { PlusCircle, Clock, AlertCircle, ArrowUpRight, Download } from 'lucide-react';
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { useTranslation } from "react-i18next";
import { namespace } from "@common-config/constants";
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import moment from 'moment';
import { 
  fetchCreditUsage, 
  exportCreditUsage, 
  resetExportUrl,
  selectCreditsData,
  selectUsageData,
  selectBotUsageData,
  selectIsLoading,
  selectError,
  selectExportUrl
} from '@common-reducers/CreditUsageReducer';
import { activeSubscriptionSelector } from '@common-reducers/UserSelectors';
import AddCreditsModal from './add-credits-modal';

// Add a safe access helper function at the beginning of the file, after imports
const getValueOrDefault = (data: any, path: string, defaultValue: any) => {
  if (!data) return defaultValue;
  
  const keys = path.split('.');
  let value = data;
  
  for (const key of keys) {
    if (value === undefined || value === null) return defaultValue;
    value = value[key];
  }
  
  return value === undefined || value === null ? defaultValue : value;
};

const CreditsUsageComponent: React.FC = () => {
  const { t } = useTranslation(namespace);
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string>("overview");
  const [timeframe, setTimeframe] = useState<string>("30days");
  const [isAddCreditsModalOpen, setIsAddCreditsModalOpen] = useState<boolean>(false);
  
  // Use selectors to get data from the Redux store
  const creditsData = useAppSelector(selectCreditsData);
  const usageData = useAppSelector(selectUsageData);
  const botUsageData = useAppSelector(selectBotUsageData);
  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);
  const exportUrl = useAppSelector(selectExportUrl);
  const activeSubscription = useAppSelector(activeSubscriptionSelector);

  // Fetch credit usage data
  useEffect(() => {
    dispatch(fetchCreditUsage(timeframe));
  }, [dispatch, timeframe]);

  // Debug logging in development mode
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Credits Data from Backend:', creditsData);
      console.log('Active Subscription:', activeSubscription);
      
      if (activeSubscription) {
        console.log('Subscription Type:', activeSubscription.type);
        console.log('Subscription Status:', activeSubscription.status);
        console.log('Subscription Plan ID:', activeSubscription.plan_id);
        console.log('Next Billing At:', activeSubscription.next_billing_at);
        
        // Log all entitlements for debugging
        console.log('Entitlement List:', activeSubscription.entitlementList);
        
        if (activeSubscription.entitlementList && activeSubscription.entitlementList.length > 0) {
          console.log('Entitlements Detail:');
          activeSubscription.entitlementList.forEach((ent: any, index: number) => {
            console.log(`Entitlement #${index + 1}:`, {
              feature_id: ent.feature_id,
              entity_type: ent.entity_type,
              entity_id: ent.entity_id,
              value: ent.value,
              name: ent.name
            });
          });
        } else {
          console.log('No entitlements found in the subscription');
        }
      } else {
        console.log('No active subscription found');
      }
    }
  }, [creditsData, activeSubscription]);

  // Handle export URL opening
  useEffect(() => {
    if (exportUrl) {
      window.open(exportUrl, '_blank');
      dispatch(resetExportUrl());
    }
  }, [exportUrl, dispatch]);

  // Calculate status color based on percentage
  const getStatusColor = (percentage: number): string => {
    if (percentage < 50) return "text-green-600";
    if (percentage < 80) return "text-amber-500";
    return "text-red-500";
  };

  // Get progress bar color based on percentage
  const getProgressColor = (percentage: number): string => {
    if (percentage < 50) return "bg-green-500";
    if (percentage < 80) return "bg-amber-500";
    return "bg-red-500";
  };

  // Handle export button click
  const handleExport = (): void => {
    dispatch(exportCreditUsage(timeframe));
  };

  // Handle add credits button click
  const handleAddCredits = (): void => {
    setIsAddCreditsModalOpen(true);
  };

  // Get formatted next billing date using moment.js
  const getFormattedNextBillingDate = () => {
    if (activeSubscription?.next_billing_at) {
      // Convert seconds to moment object
      return moment.unix(activeSubscription.next_billing_at).format('DD/MM/YYYY');
    }
    return 'Not available';
  };

  return (
    <div className="px-4 pb-4">
      {/* Add Credits Modal */}
      <AddCreditsModal 
        open={isAddCreditsModalOpen} 
        onOpenChange={setIsAddCreditsModalOpen} 
      />

      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-sm text-gray-600">{t('credits.subtitle', 'Monitor your AI agent credits and usage')}</p>
        </div>
        <div className="flex gap-2">
          <Button 
            variant="outline" 
            className="flex items-center gap-1 text-sm"
            onClick={handleExport}
            disabled={isLoading}
          >
            <Download className="w-3 h-3" />
            {t('credits.exportReport', 'Export')}
          </Button>
          <Button 
            className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-1 text-sm"
            onClick={handleAddCredits}
          >
            <PlusCircle className="w-3 h-3" />
            {t('credits.addCredits', 'Add Credits')}
          </Button>
        </div>
      </div>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {/* Credits Overview Card */}
      <Card className="mb-4 border-none shadow-sm">
        <CardContent className="p-4">
          {isLoading ? (
            <div className="flex justify-center items-center h-32">
              <p>{t('credits.loading', 'Loading...')}</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <h3 className="font-medium text-gray-700">{t('credits.remaining', 'Credits Remaining')}</h3>
                  <span className={`font-semibold ${getStatusColor(getValueOrDefault(creditsData, 'percentageUsed', 0))}`}>
                    {getValueOrDefault(creditsData, 'creditsRemaining', 0)} / {getValueOrDefault(creditsData, 'totalCredits', 0)}
                  </span>
                </div>
                <div className="relative h-2 w-full bg-gray-100 rounded-full overflow-hidden">
                  <div 
                    className={`absolute top-0 left-0 h-full ${getProgressColor(getValueOrDefault(creditsData, 'percentageUsed', 0))} transition-all`}
                    style={{ width: `${getValueOrDefault(creditsData, 'percentageUsed', 0)}%` }}
                  />
                </div>
                <div className="flex justify-between items-center text-sm text-gray-500">
                  <span>{getValueOrDefault(creditsData, 'percentageUsed', 0).toFixed(1)}% {t('credits.used', 'used')}</span>
                  <span className="flex items-center gap-1">
                    <Clock className="w-3 h-3" />
                    {t('credits.renewsIn', 'Renews in')} {getValueOrDefault(creditsData, 'daysRemaining', 0)} {t('credits.days', 'days')}
                  </span>
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="font-medium text-gray-700">{t('credits.planDetails', 'Plan Details')}</h3>
                <p className="text-sm text-gray-600">{t('credits.renewsOn', 'Credits renew on')} {getValueOrDefault(creditsData, 'renewalDate', 'Unknown')}</p>
                <p className="text-sm text-gray-500">{t('credits.monthlyRenewal', 'Credits are renewed monthly regardless of billing cycle')}</p>
                <div className="flex items-center mt-2">
                  <Button 
                    variant="link" 
                    className="p-0 h-auto text-blue-600 flex items-center gap-1 text-sm"
                    onClick={handleAddCredits}
                  >
                    {t('credits.manageSubscription', 'Manage subscription')}
                    <ArrowUpRight className="w-3 h-3" />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Usage Analysis Tabs */}
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <div className="flex justify-between items-center mb-4">
          <TabsList>
            <TabsTrigger value="overview" className="data-[state=active]:bg-blue-50 data-[state=active]:text-blue-700">
              {t('credits.overview', 'Overview')}
            </TabsTrigger>
            <TabsTrigger value="bybot" className="data-[state=active]:bg-blue-50 data-[state=active]:text-blue-700">
              {t('credits.byBot', 'By Bot')}
            </TabsTrigger>
          </TabsList>
          
          <Select value={timeframe} onValueChange={setTimeframe}>
            <SelectTrigger className="w-[150px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="7days">{t('credits.last7Days', 'Last 7 days')}</SelectItem>
              <SelectItem value="30days">{t('credits.last30Days', 'Last 30 days')}</SelectItem>
              <SelectItem value="90days">{t('credits.last90Days', 'Last 90 days')}</SelectItem>
              <SelectItem value="12months">{t('credits.last12Months', 'Last 12 months')}</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <TabsContent value="overview" className="mt-0">
          <Card className="border-none shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-md font-medium">{t('credits.usageOverTime', 'Usage Over Time')}</CardTitle>
            </CardHeader>
            <CardContent>
              {isLoading ? (
                <div className="h-60 flex items-center justify-center">
                  <p>{t('credits.loading', 'Loading...')}</p>
                </div>
              ) : usageData.length === 0 ? (
                <div className="h-60 flex items-center justify-center">
                  <p className="text-gray-500">{t('credits.noData', 'No usage data available for this period')}</p>
                </div>
              ) : (
                <div className="h-60">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={usageData}
                      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                      <XAxis dataKey="name" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                      <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} tickFormatter={(value) => `${value}`} />
                      <Tooltip 
                        formatter={(value) => [`${value} ${t('credits.credits', 'credits')}`, t('credits.usage', 'Usage')]}
                        contentStyle={{ borderRadius: '8px' }}
                      />
                      <Line
                        type="monotone"
                        dataKey="usage"
                        stroke="#3b82f6"
                        activeDot={{ r: 6 }}
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="bybot" className="mt-0">
          <Card className="border-none shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-md font-medium">{t('credits.usageByBot', 'Usage By Bot')}</CardTitle>
            </CardHeader>
            <CardContent>
              {isLoading ? (
                <div className="h-60 flex items-center justify-center">
                  <p>{t('credits.loading', 'Loading...')}</p>
                </div>
              ) : botUsageData.length === 0 ? (
                <div className="h-60 flex items-center justify-center">
                  <p className="text-gray-500">{t('credits.noData', 'No bot usage data available for this period')}</p>
                </div>
              ) : (
                <div className="h-60">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={botUsageData}
                      layout="vertical"
                      margin={{ top: 5, right: 20, left: 100, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" horizontal={false} />
                      <XAxis type="number" stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                      <YAxis 
                        dataKey="name" 
                        type="category" 
                        stroke="#888888" 
                        fontSize={12} 
                        tickLine={false} 
                        axisLine={false} 
                        width={100}
                      />
                      <Tooltip 
                        formatter={(value) => [`${value} ${t('credits.credits', 'credits')}`, t('credits.usageByBot', 'Bot Usage')]}
                        contentStyle={{ borderRadius: '8px' }}
                      />
                      <Bar 
                        dataKey="credits" 
                        fill="#3b82f6" 
                        radius={[0, 4, 4, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      {/* Warning for low credits */}
      {!isLoading && getValueOrDefault(creditsData, 'percentageUsed', 0) > 80 && (
        <Alert className="mt-4 bg-amber-50 border-amber-200">
          <AlertCircle className="h-4 w-4 text-amber-500" />
          <AlertTitle className="text-amber-800">{t('credits.lowCreditsWarning', 'Low Credits Warning')}</AlertTitle>
          <AlertDescription className="text-amber-700">
            {t('credits.lowCreditsMessage', 'You are running low on credits. Consider upgrading your plan or purchasing additional credits to ensure uninterrupted service.')}
          </AlertDescription>
        </Alert>
      )}
      
      {/* Debug information moved to the bottom of the page */}
      {process.env.NODE_ENV === 'development' && (
        <div className="mt-8 text-xs border p-2 bg-gray-50 rounded overflow-auto">
          <p><strong>Debug - Subscription:</strong> {activeSubscription ? activeSubscription.planName || activeSubscription.plan_id : "None"}</p>
          <p>
            <strong>Next Billing At:</strong> {getFormattedNextBillingDate()} 
            {activeSubscription?.next_billing_at ? ` (${activeSubscription.next_billing_at})` : ''}
          </p>
          <p><strong>Credit Renewal Logic:</strong> Credits renew monthly on the same day of the month as the billing date</p>
          
          {/* Entitlements information */}
          {activeSubscription?.entitlementList && activeSubscription.entitlementList.length > 0 && (
            <div>
              <p><strong>Available Entitlements:</strong></p>
              <ul className="pl-4">
                {activeSubscription.entitlementList.map((ent: any, index: number) => (
                  <li key={index}>
                    {ent.feature_id || ent.name}: {ent.value || 'N/A'}
                    {ent.entity_type && ` (${ent.entity_type})`}
                  </li>
                ))}
              </ul>
            </div>
          )}
          
          {/* Credit usage details */}
          <div className="mt-2 border-t pt-2">
            <p><strong>Credit Usage Data:</strong></p>
            <p>
              - Total Credits: {getValueOrDefault(creditsData, 'totalCredits', 0)}<br/>
              - Credits Used: {getValueOrDefault(creditsData, 'creditsUsed', 0)}<br/>
              - Credits Remaining: {getValueOrDefault(creditsData, 'creditsRemaining', 0)}<br/>
              - Percentage Used: {getValueOrDefault(creditsData, 'percentageUsed', 0).toFixed(1)}%<br/>
              - Renewal Date: {getValueOrDefault(creditsData, 'renewalDate', 'Unknown')}<br/>
              - Days Remaining: {getValueOrDefault(creditsData, 'daysRemaining', 0)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditsUsageComponent; 