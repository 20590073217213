import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { handleMentionsFormatInMessage } from '@common-services/utils';
import WhatsappContactBox from "@common-views/whatsapp-view/whatsapp-contact-box";
import { userMessagesSelectOneObjectByQuery } from "@common/reducers/DBServiceThunks";
import { focusContactInCalendarThunk } from "@common/thunks/UserMessagesThunks";
import { Box, ButtonBase } from "@mui/material";

type Props = {
    index: number;
    messageId: string;
}

export default function CalendarSidebarMessageRow({ index, messageId }: Props) {
    const dispatch = useAppDispatch()

    const message = useAppSelector(state => userMessagesSelectOneObjectByQuery(state.DBUserMessageReducer, { _id: messageId }))
    const isDrawerOpen = useAppSelector((state) => state.MainReducer.isToggleableNavOpen);
    const highlightedContactId = useAppSelector((state) => state.WhatsAppReducer.highlightedContactId);


    return (
        <ButtonBase
            key={`contact${index}`}
            onClick={() => dispatch(focusContactInCalendarThunk(message?.contactWAId))}
            sx={{
                width: '100%',
                display: 'block',
                textAlign: 'left',
                borderBottom: '1px solid var(--border-list)',
                backgroundColor: message?.contactWAId === highlightedContactId ? 'var(--background-default-hover)' : 'transparent',
                transition: 'background-color 0.3s',
                '&:hover': {
                    backgroundColor: 'var(--background-default-hover)',
                }
            }}
        >
            <Box sx={{ padding: "16px 11px" }}>
                <WhatsappContactBox
                    whatsappId={message?.contactWAId}
                    contactName={message?.contactList?.[0]?.name}
                    message={handleMentionsFormatInMessage(message?.message)}
                    messageScheduledCount={message?.messageScheduledCount}
                    messageApiCount={message?.messageApiCount}
                    messageCampaignCount={message?.messageCampaignCount}
                    dueDate={message?.dueDate}
                    justifyContentType={isDrawerOpen ? 'space-between' : 'center'}
                    showMessageDetails={isDrawerOpen}
                    profilePicURL={message?.contactList?.[0]?.profilePicUrl}
                />
            </Box>
        </ButtonBase>
    )
}