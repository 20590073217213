import React, { useEffect, useState, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Plus, Search, BookOpen, FileText, Clock, Database, Bot, ArrowUpRight, Trash2, MoreVertical } from 'lucide-react';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { DBKnowledgeBaseThunks, knowledgeBaseSelectByQuery } from '@common-reducers/DBServiceThunks';
import { formatDistanceToNow } from 'date-fns';
import { KnowledgeBase } from '@common/models/knowledge-base';
import { selectKnowledgeBaseDocumentCount, selectKnowledgeBaseTotalSize } from '@common/reducers/KnowledgeBaseSelectors';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { toast, Toaster } from 'sonner';

// Utility function for merging class names
function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

// Utility function to format bytes into human readable format
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// Memoized search input component to prevent losing focus
const SearchInput = memo(({ 
    value, 
    onChange 
}: { 
    value: string; 
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void 
}) => {
    return (
        <div className="relative w-[600px] flex-auto">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
                type="text"
                placeholder="Search knowledge bases..."
                value={value}
                onChange={onChange}
                className="w-full h-12 pl-12 pr-6 bg-white border border-gray-200 rounded-xl text-lg shadow-sm hover:shadow-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
        </div>
    );
});
SearchInput.displayName = 'SearchInput';

// Memoized status filter component
const StatusFilter = memo(({ 
    value, 
    onChange 
}: { 
    value: string; 
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void 
}) => {
    return (
        <select
            value={value}
            onChange={onChange}
            className="w-[240px] h-12 bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 px-6 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
            <option value="all">All Knowledge Bases</option>
        </select>
    );
});
StatusFilter.displayName = 'StatusFilter';

// Knowledge Base Card Component
const KnowledgeBaseCard = ({ kb, onClick }: { kb: KnowledgeBase, onClick: (kb: KnowledgeBase) => void }) => {
  const dispatch = useAppDispatch();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  // Format the last updated date
  let updatedDate = 'Unknown';
  if (kb.lastUpdated) {
    try {
      updatedDate = formatDistanceToNow(new Date(kb.lastUpdated), { addSuffix: true });
    } catch (e) {
      console.error('Error formatting date:', e);
    }
  }

  const documentCount = useAppSelector(state => 
    kb._id ? selectKnowledgeBaseDocumentCount(state, kb._id) : 0
  );

  // Get the total size of documents for this knowledge base
  const totalSize = useAppSelector(state => 
    kb._id ? selectKnowledgeBaseTotalSize(state, kb._id) : 0
  );

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!kb._id) return;
    
    setIsDeleting(true);
    try {
      await dispatch(DBKnowledgeBaseThunks.delete({
        entity: { _id: kb._id }
      })).unwrap();
      
      toast.success("Knowledge base deleted", {
        description: `${kb.name} has been deleted successfully`
      });
      
      // Refresh the knowledge base list
      dispatch(DBKnowledgeBaseThunks.find({}));
    } catch (error: any) {
      console.error('Failed to delete knowledge base:', error);
      toast.error("Delete failed", {
        description: error.message || "Failed to delete knowledge base. Please try again."
      });
    } finally {
      setIsDeleting(false);
      setIsDeleteDialogOpen(false);
    }
  };

  return (
    <>
      <div 
        onClick={() => onClick(kb)}
        className="bg-white shadow-sm hover:shadow-lg transition-all duration-200 rounded-2xl overflow-hidden border border-gray-100 cursor-pointer relative group"
      >
        <div className="p-8">
          {/* Header with knowledge base info */}
          <div className="flex items-start justify-between gap-4 mb-4">
            <div className="flex items-start gap-4 min-w-0 flex-1">
              <div className="p-3 bg-blue-50 rounded-xl self-center flex-shrink-0">
                <BookOpen className="w-6 h-6 text-blue-600" />
              </div>
              <div className="min-w-0 flex-1 overflow-hidden">
                <div className="text-xl font-medium text-gray-900 truncate">{kb.name}</div>
                <p className="text-base text-gray-500 truncate">{kb.description}</p>
              </div>
            </div>
            <div className="flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity">
              <DropdownMenu>
                <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                  <Button variant="ghost" size="icon" className="h-8 w-8">
                    <MoreVertical className="w-4 h-4 text-gray-500" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem 
                    className="text-red-600 cursor-pointer"
                    onClick={handleDeleteClick}
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-8 mb-6">
            <div className="flex items-center gap-3">
              <FileText className="w-5 h-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Documents</p>
                <p className="text-lg font-medium text-gray-900">{documentCount}</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Database className="w-5 h-5 text-gray-400" />
              <div>
                <p className="text-sm text-gray-500">Total Size</p>
                <p className="text-lg font-medium text-gray-900">{formatBytes(totalSize)}</p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between pt-6 border-t border-gray-100">
            <div>
              <p className="text-sm text-gray-500">Last updated</p>
              <p className="text-base font-medium text-gray-900">
                {updatedDate}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-base font-medium text-blue-600">{kb.associatedBots.length} bots</span>
              <ArrowUpRight className="w-5 h-5 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Delete Knowledge Base</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete "{kb.name}"? This action cannot be undone and will delete all associated documents.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-4">
            <Button 
              variant="outline" 
              onClick={() => setIsDeleteDialogOpen(false)}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button 
              variant="destructive"
              onClick={handleDeleteConfirm}
              disabled={isDeleting}
              className="bg-red-600 hover:bg-red-700 text-white"
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const KnowledgeBaseContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  // Fetch knowledge bases on component mount
  useEffect(() => {
    dispatch(DBKnowledgeBaseThunks.find({}));
  }, [dispatch]);

  // Select and filter knowledge bases from store
  const knowledgeBases = useAppSelector(state => {
    if (!state.DBKnowledgeBaseReducer?.ids) {
      return [];
    }
    
    const query = {
      ...(searchQuery && {
        $or: [
          { name: { $regex: searchQuery, $options: 'i' } },
          { description: { $regex: searchQuery, $options: 'i' } }
        ]
      })
    };
    return knowledgeBaseSelectByQuery(state.DBKnowledgeBaseReducer, query);
  });

  // Get loading state
  const isLoading = useAppSelector(state => 
    state.DBKnowledgeBaseReducer?.itemsLoading ?? false
  );

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleCreateNew = useCallback(async () => {
    setIsCreating(true);
    try {
      const newKnowledgeBase = {
        name: 'New Knowledge Base',
        description: 'Add a description for your knowledge base',
        status: 'active' as const,
        documentCount: 0,
        totalSize: 0,
        documentTypes: {
          pdf: 0,
          word: 0,
          excel: 0
        },
        associatedBots: [],
        lastUpdated: new Date()
      };

      const result = await dispatch(DBKnowledgeBaseThunks.create(newKnowledgeBase)).unwrap();
      if (result._id) {
        navigate(`/knowledge-base/${result._id}`);
      }
    } catch (error) {
      console.error('Failed to create knowledge base:', error);
    } finally {
      setIsCreating(false);
    }
  }, [dispatch, navigate]);

  const handleKnowledgeBaseClick = useCallback((kb: KnowledgeBase) => {
    navigate(`/knowledge-base/${kb._id}`);
  }, [navigate]);

  return (
    <>
      <div className="h-full overflow-auto bg-gradient-to-br from-gray-50 to-gray-100 p-12">
        {/* Header */}
        <div className="max-w-[1600px] mx-auto space-y-12">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-4xl font-semibold text-gray-900">Knowledge Bases</h1>
              <p className="text-lg text-gray-600 mt-2">Manage your business knowledge and documentation</p>
            </div>
            <button
              onClick={handleCreateNew}
              disabled={isCreating}
              className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg text-base font-medium transition-all duration-200 shadow-sm hover:shadow-lg flex items-center gap-3"
            >
              <Plus className="w-5 h-5" />
              {isCreating ? 'Creating...' : 'New Knowledge Base'}
            </button>
          </div>

          {/* Filters */}
          <div className="flex justify-between gap-2">
            <SearchInput value={searchQuery} onChange={handleSearchChange} />
          </div>

          {/* Loading State */}
          {isLoading && (
            <div className="flex justify-center items-center min-h-[400px]">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
          )}

          {/* Empty State */}
          {!isLoading && knowledgeBases.length === 0 && (
            <div className="flex justify-center items-center min-h-[400px]">
              <p className="text-gray-500">No knowledge bases found. Create one to get started.</p>
            </div>
          )}

          {/* Knowledge Base Grid */}
          {!isLoading && knowledgeBases.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
              {knowledgeBases.map((kb) => (
                <KnowledgeBaseCard 
                  key={kb._id} 
                  kb={kb} 
                  onClick={handleKnowledgeBaseClick} 
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Toaster position="bottom-right" />
    </>
  );
};

export default KnowledgeBaseContent; 