import React from 'react';
import { Box } from '@mui/material';
import KnowledgeBaseList from '@common-components/knowledge-base/list/KnowledgeBaseList';

const KnowledgeBaseView = () => {
  return (
    <Box sx={{ 
      width: '100%',
      height: '100%',
      bgcolor: 'background.default'
    }}>
      <KnowledgeBaseList />
    </Box>
  );
};

export default KnowledgeBaseView; 