// DB Service Thunks
import {
    botSelectOneObjectByQuery,
    DBAutomationSessionThunks,
    DBBotDeploymentThunks,
    DBBotThunks
} from '@common-reducers/DBServiceThunks';

// Redux Hooks and Thunks
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { setIsReadyToLoadFlow, setSlugBotId } from '@common/reducers/ChatbotReducer';
import { getEffectiveDeploymentIdSelector } from '@common/reducers/ChatbotSelectors';
import { useCalculateBotStatsMutation } from '@common/reducers/backend-api/backend-api';

// React and Router
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import AppView from '@common/components/app-view';
import { BotStats } from './components/BotStats';
import { DeploymentsSidebar } from './components/DeploymentsSidebar';
import { SessionsList } from './components/SessionsList';

// Shadcn UI Components
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

// Lucide Icons
import { ArrowLeft, Edit, Loader2 } from 'lucide-react';

type SessionFilter = 'all' | 'active' | 'inactive';

export const BotDetailView = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { botId } = useParams<{ botId: string }>();
    const [sessionFilter, setSessionFilter] = useState<SessionFilter>('all');
    const [manuallySelectedDeploymentId, setManuallySelectedDeploymentId] = useState<string | null>(null);
    const [calculateBotStats] = useCalculateBotStatsMutation();

    // Get loading state and bot data from selectors
    const isBotsLoading = useAppSelector(state => state.DBBotReducer.itemsLoading);
    const isDeploymentsLoading = useAppSelector(state => state.DBBotDeploymentReducer.itemsLoading);
    const isSessionsLoading = useAppSelector(state => state.DBAutomationSessionReducer.itemsLoading);
    const bot = useAppSelector(state => botSelectOneObjectByQuery(state.DBBotReducer, { _id: botId }));

    // Use the selector to get the effective deployment ID
    const selectedDeploymentId = useAppSelector(state =>
        botId ? getEffectiveDeploymentIdSelector(state, botId, manuallySelectedDeploymentId) : null
    );


    const state = useAppSelector(state => state);
    console.log('state', state);

    const handleFilterChange = (newFilter: SessionFilter) => {
        setSessionFilter(newFilter);
    };

    const fetchBotData = useCallback(async (botId: string) => {
        console.log('Fetching bot data for botId:', botId);
        await dispatch(setSlugBotId(botId))

        console.log('Fetching bot details for botId:', botId);
        const botResult = await dispatch(DBBotThunks.find({
            _id: botId,
            $paginate: false
        }));
        console.log('Bot details result:', botResult);

        // Find deployments for this bot
        console.log('Fetching deployments for botId:', botId);
        const deploymentResult = await dispatch(DBBotDeploymentThunks.find({
            botId: botId,
            $paginate: false
        }));
        console.log('Deployments result:', deploymentResult);

        // Get sessions for all deployments of this bot
        console.log('Fetching sessions for botId:', botId);
        if (!botId) {
            console.error('botId is undefined or null, cannot fetch sessions');
            return;
        }

        const sessionResult = await dispatch(DBAutomationSessionThunks.find({
            botId: botId,
            $paginate: false
        }));
        console.log('Sessions result:', sessionResult);

        // After fetching the bot, also calculate its stats
        if (botId) {
            try {
                await calculateBotStats([botId]);
            } catch (error) {
                console.error('Failed to calculate bot stats:', error);
            }
        }

        console.log('Finished fetching data for botId:', botId);
    }, [dispatch, calculateBotStats]);

    // Simple effect to fetch the bot data
    useEffect(() => {
        if (botId) {
            console.log('BotDetailView: botId changed to', botId);
            fetchBotData(botId);
            dispatch(setSlugBotId(botId))
        }
    }, [botId, dispatch, fetchBotData]);

    const handleEditClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (bot?._id) {
            await dispatch(setIsReadyToLoadFlow(false));
            await navigate(`/bot-build-view/${bot._id}`);
        }
    };

    const handleBackClick = () => {
        navigate('/bot-list-view');
    };

    if (isBotsLoading || isDeploymentsLoading || isSessionsLoading) {
        return (
            <AppView title="Bot Details" showHeader={false}>
                <div className="flex justify-center items-center h-full w-full">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
                </div>
            </AppView>
        );
    }

    if (!bot) {
        return (
            <AppView title="Bot Details" showHeader={false}>
                <div className="min-h-screen bg-gray-50 flex items-center justify-center">
                    <div className="max-w-md text-center">
                        <h2 className="text-2xl font-semibold text-gray-900 mb-2">
                            Agent not found
                        </h2>
                        <p className="text-gray-500 mb-6">
                            The agent you're looking for doesn't exist or has been deleted
                        </p>
                        <Button
                            onClick={handleBackClick}
                            className="bg-emerald-600 hover:bg-emerald-700"
                        >
                            Back to Agents
                        </Button>
                    </div>
                </div>
            </AppView>
        );
    }

    return (
        <AppView title="Bot Details" showHeader={false}>
            <div className="min-h-screen bg-gray-50">
                <div className="p-6 flex flex-col h-[calc(100vh-48px)]">
                    {/* Header */}
                    <div className="flex justify-between items-center mb-6">
                        <div className="flex items-center">
                            <Button
                                variant="ghost"
                                size="icon"
                                onClick={handleBackClick}
                                className="mr-2"
                            >
                                <ArrowLeft className="h-5 w-5" />
                            </Button>
                            <h1 className="text-2xl font-semibold text-gray-900">{bot.name}</h1>
                        </div>
                        <Button
                            onClick={handleEditClick}
                            className="bg-emerald-600 hover:bg-emerald-700"
                            size="sm"
                        >
                            <Edit className="h-4 w-4 mr-2" />
                            Edit Agent
                        </Button>
                    </div>

                    {/* Stats */}
                    <div className="mb-6">
                        <BotStats stats={bot.botStats} />
                    </div>

                    {/* Content Container */}
                    <div className="flex gap-6 flex-1 overflow-hidden">
                        {/* Sessions List */}
                        <Card className="flex-1 border border-gray-200 shadow-sm overflow-hidden">
                            <SessionsList
                                filter={sessionFilter}
                                onSessionFilterChange={handleFilterChange}
                                selectedDeploymentId={selectedDeploymentId}
                                onDeploymentSelect={setManuallySelectedDeploymentId}
                            />
                        </Card>

                        {/* Deployments List */}
                        <Card className="w-80 border border-gray-200 shadow-sm overflow-hidden">
                            <DeploymentsSidebar
                                botId={botId}
                                selectedDeploymentId={selectedDeploymentId}
                                onDeploymentSelect={setManuallySelectedDeploymentId}
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </AppView>
    );
}; 