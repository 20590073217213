import { ReactElement, useCallback, useMemo, useState } from "react";
import classes from "./editor.module.scss";
import { Editable, withReact, Slate } from "slate-react";
import isHotkey from "is-hotkey";
import {
  Editor,
  createEditor,
  Descendant,
} from "slate";
import { withHistory } from "slate-history";
import {
  CustomElement,
  CustomEditor,
  CustomText,
  EmptyText,
} from "./customTypes";
import { withImages } from "./WithImages";
import { withLinks } from "./WithLinks";
import { Element, Leaf, MentionElement } from "./EditorElements";

const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

declare module "slate" {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText | EmptyText;
  }
}

interface Props {
  update: Descendant[];
  bgColor?: string;
}

export default function ReaderUpdate({ update, bgColor }: Props): ReactElement {
  // editor state

  // Updated renderElement function
  const renderElement = useCallback((props) => {
    const { element, attributes, children } = props;

    // Check if the element is a mention
    if (element.type === 'mention') {
      // Apply a 5px margin at the bottom
      return <MentionElement {...props} />;
    }

    // For other elements, render them as usual
    return <div style={{ margin: '10px 0px' }}>
      <Element {...props} bgColor={bgColor} />
    </div>;
  }, [bgColor]);

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(
    () =>
      withLinks(
        withImages(withHistory(withReact(createEditor())), {})
      ),
    []
  );

  // console.log(update);

  const [headerState, setHeaderState] = useState({
    isBold: false,
    isItalic: false,
  });

  const RenderUpdate = useCallback(() => {
    // Move handleHotKeys function inside the useCallback
    function handleHotKeys(event: any) {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event as any)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          handleMark(mark, editor);
        }
      }
    }

    // Move handleMark function inside the useCallback
    function handleMark(format: string, editor: Editor) {
      const isActive = isMarkActive(editor, format);

      if (isActive) {
        setHeaderState({ ...headerState, isBold: !isActive });
        Editor.removeMark(editor, format);
      } else {
        setHeaderState({ ...headerState, isBold: !isActive });
        Editor.addMark(editor, format, true);
      }
    }

    // Move isMarkActive function inside the useCallback
    function isMarkActive(editor: Editor, format: string) {
      const marks: any = Editor.marks(editor);
      return marks ? marks[format] === true : false;
    }

    return (
      <Slate editor={editor} value={update} onChange={(value) => value}>
        <Editable
          readOnly={true}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          autoFocus
          onKeyDown={handleHotKeys}
          style={{
            userSelect: 'text',
            WebkitUserSelect: 'text',
          }}
        />
      </Slate>
    );
  }, [editor, update, renderElement, renderLeaf, headerState]);

  return (
    <div className={classes.readOnly_wrapper} style={{ backgroundColor: bgColor }}>
      <RenderUpdate />
    </div>
  );
}
