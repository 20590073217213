import { memo } from 'react';
import { FileText, Film, File, FileType2, Music } from 'lucide-react';

interface AssetPreviewProps {
  type: string;
  url: string;
}

const previewContainerStyle = {
  width: '27px',
  height: '27px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginInlineEnd: '3px',
  backgroundColor: '#e3e3e3',
  borderRadius: '4px',
  color: '#666',
};

const getImagePreview = (url: string) => (
  <img
    alt={url}
    src={url}
    style={{
      width: '27px',
      height: '27px',
      objectFit: 'cover',
      objectPosition: 'center',
      marginInlineEnd: '3px',
      borderRadius: '4px',
    }}
  />
);

const getVideoPreview = () => (
  <div style={previewContainerStyle}>
    <Film size={18} />
  </div>
);

const getAudioPreview = () => (
  <div style={previewContainerStyle}>
    <Music size={18} />
  </div>
);

const getApplicationPreview = (type: string) => {
  let Icon = File;

  if (type.includes('pdf')) {
    Icon = FileText;
  } else if (type.includes('word') || type.includes('doc') || type.includes('docx')) {
    Icon = FileType2;
  }

  return (
    <div style={previewContainerStyle}>
      <Icon size={18} />
    </div>
  );
};

export const MessageAssetPreview = memo(({ type, url }: AssetPreviewProps) => {
  return (
    <>
      {type && url && type.includes('image') && getImagePreview(url)}
      {type && url && type.includes('video') && getVideoPreview()}
      {type && url && type.includes('audio') && getAudioPreview()}
      {type && url && type.includes('application') && getApplicationPreview(type)}
    </>
  );
}); 