import { memo } from 'react';
import { EventProps } from 'react-big-calendar';
import { UserMessage } from '@common/models/user-message';
import { CustomMonthEvent } from './custom-message-event';
import { EventPL } from './message-calendar';

interface Props {
  eventProps: EventProps<UserMessage>;
  onRightClick: (pl: EventPL) => void;
  onDelete?: (event: UserMessage) => void;
  containerWidth: number;
}

export const CustomMonthEventWrapper = memo((props: Props) => {
  const { containerWidth, eventProps, onRightClick, onDelete } = props;
  
  // Calculate event width based on container width
  // We subtract padding and margins to ensure proper fit
  // const eventWidth = containerWidth ? Math.floor(containerWidth / 7) - 22 : 'auto';

  return (
    <div
      style={{
        width: containerWidth,
        maxWidth: containerWidth,
        display: 'flex',
        textAlign: 'left',
        padding: '2px',
        borderRadius: '4px',
        position: 'relative',
      }}
    >
      <CustomMonthEvent
        eventProps={eventProps}
        onRightClick={onRightClick}
        onDelete={onDelete}
      />
    </div>
  );
}); 