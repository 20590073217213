import { getWaContactDisplayLabel, MinimalContact } from '@common-reducers/WhatsAppSelectors';
import { WaContact } from "@common/models/waContact";
import { isExtensionContext } from '@common/services/detect-context';
import ContactAvatar from '@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar';
import { Box } from '@mui/system';

type Props = {
    waContact?: WaContact | MinimalContact;
    index?: number;
    onClick: (contact: any) => any;
}

export default function WaContactLazyLoadingVirtuosoRow({
    waContact,
    index,
    onClick,
}: Props) {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px',
                cursor: 'pointer',
                width: '290px',
                '&:hover': {
                    backgroundColor: 'var(--button-hovered-color)',
                },
            }}
            onClick={() => onClick(waContact)}
        >
            <ContactAvatar
                size={24}
                textSize={12}
                contactId={waContact.id._serialized}
                dontFetchProfilePicFromDB={!isExtensionContext()}
            />
            <Box ml={2}
                sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{getWaContactDisplayLabel(waContact)}</Box>
        </Box>
    )
}