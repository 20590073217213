export type RecurrenceUnit =
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "year";

export interface Schedule {
  isNow?: boolean;
  dueDate?: string;
  isTimeValid?: boolean;
  isRecurring?: boolean;
  recurrenceQuantity?: number;
  recurenceUnit?: RecurrenceUnit;
  cancelIfReceived?: boolean;
  rruleset?: string;
  calendarMessage?: boolean;
}

export enum MessageState {
  pending,
  sending,
  editing,
}

export interface Asset {
  name: string;
  type: string;
  url: string;
}

export interface Contact {
  id: string;
  name?: string;
  displayName?: string;
  imageUrl?: string;
  mobile?: string;
}

export type ActiveNewTaskRow = {
  groupId: string;
  parentTaskId?: string
}

export type PreservedGroup = {
  _id: string,
  boardId?: string,
  deleted: boolean
}
export type PreservedBoard = {
  _id: string,
  deleted: boolean
}

export type ActionsReduerData = {
  group: PreservedGroup,
}


export interface CheckAuthParams {
  wid?: string;
  accessToken?: string;
  email?: string;
}

export interface SubscriptionSuccessOptions {
  shareASaleUrl: string;
}

export enum GlobalTasksPanelState {
  closed,
  openSingle,
  openAll,
}

export type MenuOptionType =
  | "socketConnectionStatus"
  | "openChatByPhoneNumber"
  | "broadcastBulkCampaign"
  | "scheduledMessages"

export interface MenuOption {
  icon?: JSX.Element;
  text?: string;
  type: MenuOptionType;
}
export const EmittedPathList = ["create", "patch", "delete"];
export type EmittedPath = typeof EmittedPathList[number];
export interface UpdateEvent<T = any> {
  path: string;
  method: string;
  entity: T;
  callback?: (response: any) => void;
  _messageId?: number;
  _roomId?: string;
}

export interface QREvent {
  owner: string;
  qr: string;
}


export type Id = string;
export type NullableId = Id | null;

export interface Asset {
  name: string;
  type: string;
  url: string;
}