import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { onConnect, onEdgesChange, onNodesChange, setCurrentBot, updateEdges, updateNodes } from '@common/reducers/ChatbotReducer';
import { getCurrentBotSelector } from '@common/reducers/ChatbotSelectors';
import { Connection, Edge, EdgeChange, Node, NodeChange } from 'reactflow';

export function useChatbotFlow() {
    const dispatch = useAppDispatch();
    const nodes = useAppSelector(state => state.ChatbotReducer.nodes);
    const edges = useAppSelector(state => state.ChatbotReducer.edges);
    const currentBot = useAppSelector(state => getCurrentBotSelector(state));
    const isDraft = useAppSelector(state => state.ChatbotReducer.isDraft);

    const setNodes = (nodes: Node[]) => {
        dispatch(updateNodes(nodes));
    };

    const setEdges = (edges: Edge[]) => {
        dispatch(updateEdges(edges));
    };

    const handleNodesChange = async (changes: NodeChange[]) => {
        if (!isDraft) {
            try {
                await dispatch(setCurrentBot(currentBot));
            } catch (error) {
                console.error('Error in handleNodesChange when calling setCurrentBot:', error);
            }
        }
        dispatch(onNodesChange(changes));
    };

    const handleEdgesChange = async (changes: EdgeChange[]) => {
        if (!isDraft) {
            try {
                await dispatch(setCurrentBot(currentBot));
            } catch (error) {
                console.error('Error in handleEdgesChange when calling setCurrentBot:', error);
            }
        }
        dispatch(onEdgesChange(changes));
    };

    const handleConnect = (connection: Connection) => {
        if (!isDraft) {
            try {
                dispatch(setCurrentBot(currentBot));
            } catch (error) {
                console.error('Error in handleConnect when calling setCurrentBot:', error);
            }
        }
        dispatch(onConnect(connection));
    };

    return {
        nodes,
        edges,
        onNodesChange: handleNodesChange,
        onEdgesChange: handleEdgesChange,
        onConnect: handleConnect,
        setNodes,
        setEdges,
    };
} 