import { DBMessageLogThunks } from "@common-reducers/DBServiceThunks";
import { isExtensionContext } from "@common/services/detect-context";
import { AppThunkDispatch, CommonRootState } from "@common/types/common-root-state-type";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWaMessagesAckDataByWaMessagesKeys } from "./backend-api/backend-api";

export const getMessagesAckAndCalculateStatisticsThunk = createAsyncThunk<
    { campaignMessageLogs: any[]; messagesKeys: any[]; messageLogWithoutWaKeys: any[]; realMessagesAckData: any[] },
    string,
    { state: CommonRootState }
>(
    "campaigns/getMessagesAckAndCalculateStatisticsThunk",
    async (campaignId, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;

        const campaignMessageLogs = await dispatch(
            DBMessageLogThunks.find({
                campaignId,
                $sort: { createdAt: -1 },
                $limit: -1,
                $getMostRecentPerMessageId: true,
            })
        ).unwrap();

        const messageLogWithWaKeys = campaignMessageLogs
            .filter(log => log.waMessageKey)
            .map(log => ({
                messageId: log.messageId,
                waMessageKey: log.waMessageKey,
                waMessageKeyPart2: log.waMessageKeyPart2
            }));

        const messageLogWithoutWaKeys = campaignMessageLogs
            .filter(log => !log.waMessageKey)
            .map(log => ({
                messageId: log.messageId
            }));

        
        // const missingMessageLoghWaKeys =  getMissingMessageLoghWaKeysByMessagesIds(messageLogWithoutWaKeys);
        const missingMessageLoghWaKeys = []

        const messageLogWithWaKeysCombined : any[] =  [...messageLogWithWaKeys, ...missingMessageLoghWaKeys];


        if (!messageLogWithWaKeysCombined || messageLogWithWaKeysCombined.length === 0) {
            console.log('No messages keys found for campaign:', campaignId);
            return;
        }

        // Get message acknowledgment data
        let realMessagesAckData = [];
        if (isExtensionContext()) {
            realMessagesAckData = await ContentMessageServiceActions.getWaMessagesAckDataByWaMessagesKeys(messageLogWithWaKeysCombined);
        } else {
            realMessagesAckData = await dispatch(getWaMessagesAckDataByWaMessagesKeys.initiate({messageKeys: messageLogWithWaKeysCombined})).unwrap();
        }

        const newMessagesLogsWithUpdatedAckToCreate = [];
        console.log('realMessagesAckData', realMessagesAckData);


        // Process general acknowledgment updates
        for (const ackData of realMessagesAckData) {
            const campaignMessageLog = campaignMessageLogs.find(log => log.messageId === ackData.messageId);
            const isAckMessageHaBeenChangedOrNotExists = !campaignMessageLog.ackData || (campaignMessageLog?.ackData?.deliveryRemaining > ackData?.deliveryRemaining 
                || campaignMessageLog?.ackData?.readRemaining > ackData?.readRemaining
                || campaignMessageLog?.ackData?.playedRemaining > ackData?.playedRemaining);
            console.log('isAckMessageHaBeenChangedOrNotExists', isAckMessageHaBeenChangedOrNotExists);
            

            if ((campaignMessageLog && isAckMessageHaBeenChangedOrNotExists)) {
                
                    const { _id, ...rest } = campaignMessageLog;
                    newMessagesLogsWithUpdatedAckToCreate.push({ ...rest, ackData, });
                
            }
        }
        
        if (newMessagesLogsWithUpdatedAckToCreate.length > 0) {
            await dispatch(DBMessageLogThunks.createMany(newMessagesLogsWithUpdatedAckToCreate));
        }

        return { campaignMessageLogs, messagesKeys: messageLogWithWaKeys, messageLogWithoutWaKeys, realMessagesAckData };
    }
);
