import { MoreVert } from '@mui/icons-material';
import { Box, ClickAwayListener, IconButton } from '@mui/material';
import { memo, useState } from 'react';
import CampaignRowMenu from './campaign-row-menu';


interface CampaignActionsMenuProps {
    campaignId: string;
    shouldShowViewLogs?: boolean;
}

const CampaignActionsMenu: React.FC<CampaignActionsMenuProps> = ({ campaignId, shouldShowViewLogs = true }) => {
    console.log("campaignId", campaignId)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => setAnchorEl(null);

    return (
        <Box  >
            <IconButton onClick={handleOpenMenu} style={{ color: '#7f9887', width: 'fit-content' }}>
                <MoreVert />
            </IconButton>

            <CampaignRowMenu
                campaignId={campaignId}
                handleCloseMenu={handleCloseMenu}
                anchorEl={anchorEl}
                shouldShowViewLogs={shouldShowViewLogs}
            />
        </Box>
    );
};

export default memo(CampaignActionsMenu);