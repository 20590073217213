import BTBulkManagement from "@extension/context/content/components/bt-bulk-dashboard/bt-bulk-management"
import { Box } from "@mui/material"
import { setActiveBulkView } from "@common-reducers/CampaignReducer"
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook"
import { useEffect } from "react"
import BTBulkDashboard from "@extension/context/content/components/bt-bulk-dashboard/bt-bulk-dashboard"
import BulkLogsView from "@extension/context/content/components/bt-bulk-dashboard/bulk-logs-view/bulk-logs-view"

export const CampaignManagementView = () => {
    const dispatch = useAppDispatch()
    const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);

    useEffect(() => {
        dispatch(setActiveBulkView('campaignManagementView'))
    }, [])

    return <Box>
        {
            activeBulkView === "campaignManagementView" &&
            <BTBulkManagement />
        }

        {
            (activeBulkView === "campaignLogsView") &&
            <BulkLogsView/>
        }
        {
            activeBulkView === "newCampaignView" &&
            <BTBulkDashboard />
        }
    </Box>
}