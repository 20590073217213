import { getHandleConnections } from '@common-reducers/ChatbotSelectors';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import React, { useEffect, useState } from 'react';
import { Handle } from 'reactflow';


const CustomSourceHandle = (props) => {
    const connections = useAppSelector(state => getHandleConnections(state, props.id))
    const [isConnectable, setIsConnectable] = useState(true);

    useEffect(() => {
        setIsConnectable(connections?.length < props.connectioncount);
    }, [connections, props.connectioncount]);

    return (
        <Handle
            {...props}
            isConnectable={isConnectable}
        />
    );
};

export default CustomSourceHandle;
