import { CustomNode, nodeTypes } from "@common-components/bt-chatbot/bt-nodes/bt-nodes-def";
import { Bot } from "@common-models/bot";
import {
  automationSessionSelectOneObjectByQuery,
  botDeploymentSelectOneObjectByQuery,
  botSelectOneObjectByQuery,
  DBBotDeploymentThunks,
  DBBotThunks,
  nodeEventsSelectByQuery
} from "@common-reducers/DBServiceThunks";
import { getChatbotTempId } from "@common-services/utils";
import { initialEdges, initialNodes } from "@common/components/bt-chatbot/initial-nodes";
import { CommonRootState } from "@common/types/common-root-state-type";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Connection, Edge, Node, Viewport, XYPosition } from "reactflow";
import {
  initialBot,
  setCurrentBot,
  setFocusedNodeId,
  setIsReadOnly,
  setIsReadyToLoadFlow,
  setSlugBotId,
  updateEdges,
  updateNodes,
  setLoaderProgressMessage
} from "./ChatbotReducer";
import { getCurrentBotSelector } from "./ChatbotSelectors";
import { NodeType } from "../models/bot-node";

interface SaveBotThunkParams {
  viewport: Viewport;
  localCurrentBot?: Bot;
  shouldDeploy?: boolean;
}

export const saveBotThunk = createAsyncThunk<Bot | undefined, SaveBotThunkParams, { state: CommonRootState }>(
  "campaigns/saveBot",
  async (params: SaveBotThunkParams, thunkAPI) => {
    console.log("[THUNK] saveBotThunk - saving bot", params?.shouldDeploy ? "with deployment" : "as draft");
    const { localCurrentBot, shouldDeploy } = params;
    const { dispatch } = thunkAPI;
    const state = thunkAPI.getState();

    try {
      // Set initial status message
      if (shouldDeploy) {
        dispatch(setLoaderProgressMessage('Preparing bot for deployment...'));
      } else {
        dispatch(setLoaderProgressMessage('Saving bot draft...'));
      }

      const nodes = state.ChatbotReducer.nodes;
      const edges = state.ChatbotReducer.edges;
      const currentBot = localCurrentBot ?? getCurrentBotSelector(state);

      const flowJSON = JSON.stringify({ nodes, edges }, null, 2);

      // First create/update bot
      let bot = currentBot;
      if (!currentBot?._id) {
        // Update status message
        dispatch(setLoaderProgressMessage('Creating new bot...'));

        // Create new bot
        const createRes = await dispatch(DBBotThunks.create({
          ...currentBot,
          flowJSON,
          botSettings: currentBot?.botSettings,
        })) as { payload: Bot };
        bot = createRes.payload;
      } else {
        // Update status message
        dispatch(setLoaderProgressMessage('Updating existing bot...'));

        // Update existing bot
        const patchRes = await dispatch(DBBotThunks.patch({
          entity: {
            _id: currentBot?._id,
            ...currentBot,
            flowJSON,
            botSettings: currentBot?.botSettings,
          }
        })) as { payload: Bot };
        bot = patchRes.payload;
      }

      if (shouldDeploy && bot._id) {
        // Update status message for deployment
        dispatch(setLoaderProgressMessage('Initiating bot deployment...'));

        // Create deployment
        await dispatch(DBBotDeploymentThunks.create({
          botId: bot._id,
          flowJSON,
          $shouldSetAsActiveBotDeployment: true
        }));

        // Final deployment status message
        dispatch(setLoaderProgressMessage('Bot deployed successfully!'));
      } else {
        // Final save status message
        dispatch(setLoaderProgressMessage('Bot saved successfully!'));
      }

      // Short delay to show the success message
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Refresh the bot list
      await dispatch(DBBotThunks.find({ $paginate: false }));

      // Update current bot and flow data
      await dispatch(setCurrentBot(bot));
      await dispatch(changeFlowDataThunk());
      await dispatch(setIsReadyToLoadFlow(true));

      // Clear the status message
      dispatch(setLoaderProgressMessage(null));

      return bot;
    } catch (error) {
      // Error message
      dispatch(setLoaderProgressMessage(`Error: ${error.message || 'Failed to save/deploy bot'}`));

      // Short delay to show the error message
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Clear the status message and restore UI
      dispatch(setLoaderProgressMessage(null));
      dispatch(setIsReadyToLoadFlow(true));

      throw error;
    }
  }
);

interface MenuPosition {
  position: XYPosition;
  x: number;
  y: number;
  zoom?: number;
}

interface AddNodeParams {
  type: string;
  position: XYPosition & { zoom?: number };
  menuPosition?: Partial<MenuPosition>;
  startingNode?: { id: string; type: string; nodeId: string };
  isOnlyNode?: boolean;
  existingNodes?: Node[];
  fromToolbar?: boolean;
}

/**
 * Finds a suitable position for a new node that doesn't overlap with existing nodes
 */
const findSuitablePosition = (
  currentPosition: XYPosition,
  existingNodes: Node[],
  nodeSize = { width: 200, height: 100 },
  gridSize = 20
): XYPosition => {
  // Start with the current viewport center
  let position = { ...currentPosition };

  // Check if position overlaps with any existing node
  const hasOverlap = (pos: XYPosition) => {
    return existingNodes.some(node => {
      return (
        pos.x < node.position.x + nodeSize.width &&
        pos.x + nodeSize.width > node.position.x &&
        pos.y < node.position.y + nodeSize.height &&
        pos.y + nodeSize.height > node.position.y
      );
    });
  };

  // If there's no overlap, return the position
  if (!hasOverlap(position)) {
    return position;
  }

  // Try positions in a spiral pattern until we find a suitable one
  const spiralOffsets = [];
  const maxAttempts = 20; // Limit the number of attempts

  for (let i = 1; i <= maxAttempts; i++) {
    // Try right
    spiralOffsets.push({ x: i * gridSize, y: 0 });
    // Try down
    spiralOffsets.push({ x: 0, y: i * gridSize });
    // Try left
    spiralOffsets.push({ x: -i * gridSize, y: 0 });
    // Try up
    spiralOffsets.push({ x: 0, y: -i * gridSize });
    // Try diagonals
    spiralOffsets.push({ x: i * gridSize, y: i * gridSize });
    spiralOffsets.push({ x: -i * gridSize, y: i * gridSize });
    spiralOffsets.push({ x: i * gridSize, y: -i * gridSize });
    spiralOffsets.push({ x: -i * gridSize, y: -i * gridSize });
  }

  for (const offset of spiralOffsets) {
    const newPos = {
      x: position.x + offset.x,
      y: position.y + offset.y
    };

    if (!hasOverlap(newPos)) {
      return newPos;
    }
  }

  // If we couldn't find a non-overlapping position, return the original with a default offset
  return {
    x: position.x + 250,
    y: position.y + 100
  };
};

const addNodeHelper = ({ type, position, menuPosition, startingNode, existingNodes, fromToolbar }: AddNodeParams): { newEdgeConnection: Connection | undefined; newNode: Partial<CustomNode> } => {
  const id = getChatbotTempId();

  // Get the initial position based on context
  let nodePosition: XYPosition;

  if (menuPosition && !fromToolbar) {
    // If we have a menu position (right-click), use that exact position
    // For right-clicks, we respect the exact position chosen by the user
    nodePosition = {
      x: menuPosition.position.x,
      y: menuPosition.position.y
    };
  } else if (fromToolbar) {
    // For toolbar clicks, use the center of the viewport
    nodePosition = {
      x: position.x,
      y: position.y
    };
  } else {
    // For other cases, find a suitable position that doesn't overlap
    nodePosition = findSuitablePosition(
      { x: position.x, y: position.y },
      existingNodes || []
    );
  }

  const handles = nodeTypes?.[type]?.handles(id) ?? { inputs: [], outputs: [] };
  const extraData = nodeTypes?.[type]?.extraData(id) ?? {};

  // Find the highest z-index among existing nodes and add 1
  const highestZIndex = Math.max(
    0, // Default if no nodes exist
    ...(existingNodes || []).map(node =>
      node.style && typeof node.style.zIndex === 'number'
        ? node.style.zIndex
        : 0
    )
  );

  // Set z-index to be higher than all existing nodes
  const zIndex = highestZIndex + 1;

  let newEdgeConnection: Connection | undefined;
  if (startingNode) {
    if (startingNode.type === 'source') {
      newEdgeConnection = {
        source: startingNode.nodeId,
        sourceHandle: startingNode.id,
        target: id,
        targetHandle: handles.inputs[0]?.id,
      };
    } else if (startingNode.type === 'target' && type !== 'question') {
      newEdgeConnection = {
        source: id,
        sourceHandle: handles.outputs[0]?.id,
        target: startingNode.nodeId,
        targetHandle: startingNode.id,
      };
    }
  }

  return {
    newEdgeConnection,
    newNode: {
      id,
      position: nodePosition,
      data: {
        label: `Node ${id}`,
        handles,
        ...extraData,
      },
      type,
      style: { zIndex }, // Apply the z-index to ensure the node appears on top
    }
  };
};

interface AddNodeThunkParams {
  type: string;
  addNodes: (node: Partial<CustomNode>) => void;
  startingNode?: { id: string; type: string; nodeId: string };
  fromToolbar?: boolean;
  viewport?: Viewport;
}

export const addNodeThunk = createAsyncThunk<Connection | undefined, AddNodeThunkParams, { state: CommonRootState }>(
  "campaigns/addNode",
  async (params: AddNodeThunkParams, thunkAPI) => {
    console.log("[THUNK] addNodeThunk - adding node", params.type);
    const state = thunkAPI.getState();
    const { type, addNodes, startingNode, fromToolbar = false, viewport } = params;

    // Get the current viewport center from the state
    const currentReactFlowPosition = state.ChatbotReducer.currentReactFlowPosition;
    const menuPosition = state.ChatbotReducer.menuPosition;
    const existingNodes = state.ChatbotReducer.nodes;

    // Calculate position based on context
    let position = currentReactFlowPosition;
    if (fromToolbar && viewport) {
      // Calculate the center of the current viewport
      position = {
        x: -viewport.x / viewport.zoom + window.innerWidth / 2 / viewport.zoom,
        y: -viewport.y / viewport.zoom + window.innerHeight / 2 / viewport.zoom
      };
    }

    const newNode = await addNodeHelper({
      type,
      position: position,
      menuPosition,
      startingNode,
      existingNodes,
      fromToolbar,
    });

    await addNodes(newNode.newNode);

    return newNode.newEdgeConnection;
  }
);

export const changeFlowDataThunk = createAsyncThunk<void, void, { state: CommonRootState }>(
  "campaigns/changeFlowData",
  async (_, thunkAPI) => {
    console.log("[THUNK] changeFlowDataThunk - updating flow data");
    const { dispatch } = thunkAPI;
    const state = thunkAPI.getState();
    const currentBot = getCurrentBotSelector(state);

    let nodes: Node[];
    let edges: Edge[];

    if (currentBot?.flowJSON) {
      // Parse the flow data from JSON
      const flowData = JSON.parse(currentBot?.flowJSON);
      nodes = flowData.nodes;
      edges = flowData.edges;
    } else {
      // For new bots or bots without flow data
      nodes = initialNodes;
      edges = [];
    }

    dispatch(updateNodes(nodes));
    dispatch(updateEdges(edges));
  }
);

export const handleSelectChatbotThunk = createAsyncThunk<void, string, { state: CommonRootState }>(
  "campaigns/handleSelectChatbot",
  async (id: string, thunkAPI) => {
    console.log("[THUNK] handleSelectChatbotThunk - selecting chatbot with ID:", id);
    const { dispatch } = thunkAPI;
    await dispatch(setIsReadyToLoadFlow(false));

    // Just fetch the bot - it contains all the flow data
    let selectedBot: Bot | undefined;
    if (id) {
      const res = await dispatch(DBBotThunks.find({ $paginate: false, _id: id }));
      selectedBot = res.payload?.[0];
    }

    await dispatch(setCurrentBot(selectedBot));
    await dispatch(changeFlowDataThunk());

    await dispatch(setIsReadyToLoadFlow(true));
  }
);

interface ChangeNodeDataParams {
  id?: string;
  dataToPatch: {
    webhook?: any;
    handles?: any;
    text?: string;
    asset?: any;
    contactId?: string;
    type?: string;
    question?: string;
    questionAsset?: any;
    answers?: { [key: string]: any };
    flowNodeConditions?: any;
    rowData?: any;
    prompt?: string;
    tools?: string[];
    toolSettings?: { [toolName: string]: any };
  };
}

export const changeNodeDataThunk = createAsyncThunk<void, ChangeNodeDataParams, { state: CommonRootState }>(
  "campaigns/changeNodeData",
  async (params: ChangeNodeDataParams, thunkAPI) => {
    console.log("[THUNK] changeNodeDataThunk - changing node data", params.id);
    const { dispatch } = thunkAPI;
    const state = thunkAPI.getState();
    const { id, dataToPatch } = params;

    const node = state.ChatbotReducer.nodes.find(n => n.id === id);
    if (!node) return;

    const updatedNode = {
      ...node,
      data: {
        ...node.data,
        ...dataToPatch,
      },
    };

    dispatch(updateNodes(
      state.ChatbotReducer.nodes.map(n => n.id === id ? updatedNode : n)
    ));
  }
);

export const removeHandleAndConnectedEdgesByHandleIdArrayThunk = createAsyncThunk<string[], string[], { state: CommonRootState }>(
  "campaigns/removeHandleAndConnectedEdgesByHandleIdArray",
  async (handleIds: string[], thunkAPI) => {
    console.log("[THUNK] removeHandleAndConnectedEdgesByHandleIdArrayThunk - removing handles and connected edges", handleIds);
    const { dispatch } = thunkAPI;
    const state = thunkAPI.getState();

    const edgesToRemove = state.ChatbotReducer.edges.filter(edge =>
      handleIds.includes(edge.sourceHandle) || handleIds.includes(edge.targetHandle)
    );

    const edgeIds = edgesToRemove.map(edge => edge.id);

    dispatch(updateEdges(
      state.ChatbotReducer.edges.filter(edge => !edgeIds.includes(edge.id))
    ));

    return edgeIds;
  }
);

/**
 * Helper function to properly duplicate a node with all its internal logic and settings
 * This handles specific requirements for each node type including handleIds and botSettings
 */
const createDuplicateNode = (sourceNode: CustomNode, newId: string, state: CommonRootState): CustomNode => {
  // Get the node type definition from nodeTypes
  const nodeType = sourceNode.type as NodeType;
  const nodeTypeDef = nodeTypes[nodeType];
  const currentBot = getCurrentBotSelector(state);

  if (!nodeTypeDef) {
    throw new Error(`Node type ${nodeType} not found in nodeTypes definitions`);
  }

  // Generate new handles using the node type's handle generator
  const newHandles = nodeTypeDef.handles ? nodeTypeDef.handles(newId) : { inputs: [], outputs: [] };

  // Generate new extra data using the node type's extraData generator
  const newExtraData = nodeTypeDef.extraData ? nodeTypeDef.extraData(newId) : {};

  // Create a new node with the correct structure
  const newNode: CustomNode = {
    id: newId,
    type: sourceNode.type,
    position: {
      x: sourceNode.position.x + 100,
      y: sourceNode.position.y + 100
    },
    data: {
      ...newExtraData,
      label: sourceNode.data.label,
      handles: newHandles,
    },
  };

  // Copy specific data based on node type
  switch (nodeType) {
    case 'trigger':
      if (sourceNode.data.triggerType) {
        newNode.data.triggerType = sourceNode.data.triggerType;
      }
      if (sourceNode.data.contacts) {
        newNode.data.contacts = [...sourceNode.data.contacts];
      }
      break;

    case 'sendMessage':
      if (sourceNode.data.text) {
        newNode.data.text = sourceNode.data.text;
      }
      if (sourceNode.data.asset) {
        newNode.data.asset = JSON.parse(JSON.stringify(sourceNode.data.asset));
      }
      break;

    case 'messageContact':
      if (sourceNode.data.text) {
        newNode.data.text = sourceNode.data.text;
      }
      if (sourceNode.data.asset) {
        newNode.data.asset = JSON.parse(JSON.stringify(sourceNode.data.asset));
      }
      // Copy contactId for messageContact nodes
      if (sourceNode.data.contactId) {
        newNode.data.contactId = sourceNode.data.contactId;
      }
      break;

    case 'question':
      if (sourceNode.data.question) {
        newNode.data.question = sourceNode.data.question;
      }
      if (sourceNode.data.questionAsset) {
        newNode.data.questionAsset = JSON.parse(JSON.stringify(sourceNode.data.questionAsset));
      }

      // Handle answers mapping to new handle IDs
      if (sourceNode.data.answers) {
        const newAnswers = {};

        // Create a mapping from old output index to new handle ID
        const outputIndexToNewHandleId = {};
        sourceNode.data.handles.outputs.forEach((output, index) => {
          if (newHandles.outputs[index]) {
            outputIndexToNewHandleId[output.id] = newHandles.outputs[index].id;
          }
        });

        // Map answers to new handle IDs
        Object.entries(sourceNode.data.answers).forEach(([oldHandleId, answerValue]) => {
          const newHandleId = outputIndexToNewHandleId[oldHandleId];
          if (newHandleId) {
            newAnswers[newHandleId] = answerValue;
          }
        });

        newNode.data.answers = newAnswers;
      }

      // Handle variable name for question nodes
      if (currentBot?.botSettings?.variables?.[sourceNode.id]) {
        const oldVariableName = currentBot.botSettings.variables[sourceNode.id];
        // Create a new variable name like "copy of oldVariableName"
        const newVariableName = `copy of ${oldVariableName}`;

        // We'll need to update the bot settings later with this new variable
        newNode.data._variableNameToCopy = {
          oldNodeId: sourceNode.id,
          newNodeId: newId,
          newVariableName
        };
      }
      break;

    case 'webhook':
      if (sourceNode.data.webhook) {
        // Deep copy the webhook configuration
        const webhookCopy = JSON.parse(JSON.stringify(sourceNode.data.webhook));

        // Update any handle references in the webhook configuration
        if (webhookCopy.responseRouting && webhookCopy.responseRouting.responseRoutes) {
          webhookCopy.responseRouting.responseRoutes = webhookCopy.responseRouting.responseRoutes.map(route => {
            // Update handle IDs if needed
            if (route.handleId) {
              // Find the corresponding new handle ID
              const outputIndex = sourceNode.data.handles.outputs.findIndex(
                output => output.id === route.handleId
              );

              if (outputIndex >= 0 && newHandles.outputs[outputIndex]) {
                route.handleId = newHandles.outputs[outputIndex].id;
              }
            }
            return route;
          });
        }

        newNode.data.webhook = webhookCopy;
      }

      // Handle variable name for webhook nodes
      if (currentBot?.botSettings?.variables?.[sourceNode.id]) {
        const oldVariableName = currentBot.botSettings.variables[sourceNode.id];
        // Create a new variable name like "copy of oldVariableName"
        const newVariableName = `copy of ${oldVariableName}`;

        // We'll need to update the bot settings later with this new variable
        newNode.data._variableNameToCopy = {
          oldNodeId: sourceNode.id,
          newNodeId: newId,
          newVariableName
        };
      }

      newNode.data.defaultHandleId = `source-${newId}-y`;
      break;

    case 'condition':
      if (sourceNode.data.flowNodeConditions) {
        newNode.data.flowNodeConditions = JSON.parse(JSON.stringify(sourceNode.data.flowNodeConditions));
      }
      newNode.data.conditionResult = {
        yResultHandleId: `source-${newId}-y`,
        nResultHandleId: `source-${newId}-n`
      };
      break;

    case 'googleSheets':
      if (sourceNode.data.rowData) {
        newNode.data.rowData = JSON.parse(JSON.stringify(sourceNode.data.rowData));
      }
      break;

    case 'LLM':
      if (sourceNode.data.prompt) {
        newNode.data.prompt = sourceNode.data.prompt;
      }
      newNode.data.defaultHandleId = `source-${newId}-y`;
      break;

    case 'agent':
      if (sourceNode.data.prompt) {
        newNode.data.prompt = sourceNode.data.prompt;
      }
      if (sourceNode.data.tools) {
        newNode.data.tools = [...sourceNode.data.tools];
      }
      if (sourceNode.data.toolSettings) {
        newNode.data.toolSettings = JSON.parse(JSON.stringify(sourceNode.data.toolSettings));
      }
      if (sourceNode.data.asset) {
        newNode.data.asset = JSON.parse(JSON.stringify(sourceNode.data.asset));
      }
      newNode.data.defaultHandleId = `source-${newId}-y`;
      break;
  }

  // Copy any style or className properties
  if (sourceNode.style) {
    newNode.style = { ...sourceNode.style };
  }
  if (sourceNode.className) {
    newNode.className = sourceNode.className;
  }

  return newNode;
};

export const duplicateNodeThunk = createAsyncThunk<Node, { id: string; type: string }, { state: CommonRootState }>(
  "campaigns/copyNode",
  async (params: { id: string; type: string }, thunkAPI) => {
    console.log("[THUNK] copyNodeThunk - copying node", params.id);
    const { id, type } = params;
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;

    const sourceNode = state.ChatbotReducer.nodes.find(n => n.id === id);
    if (!sourceNode) throw new Error("Source node not found");

    const newId = getChatbotTempId();
    const newNode = createDuplicateNode(sourceNode as CustomNode, newId, state);

    // Handle variable copying for nodes that store variables in botSettings
    if (newNode.data._variableNameToCopy) {
      const { oldNodeId, newNodeId, newVariableName } = newNode.data._variableNameToCopy;

      // Get current bot
      const currentBot = getCurrentBotSelector(state);

      // Create a new bot with the updated variables
      const updatedBot = {
        ...currentBot,
        botSettings: {
          ...currentBot.botSettings,
          variables: {
            ...currentBot.botSettings?.variables,
            [newNodeId]: newVariableName
          }
        }
      };

      // Update the bot with the new variable
      await dispatch(setCurrentBot(updatedBot));

      // Remove the temporary property
      delete newNode.data._variableNameToCopy;
    }

    return newNode as Node;
  }
);

interface ToggleBotActivationParams {
  bot: Bot;
  shouldActivate: boolean;
  findLatestDeployment?: boolean;
}

export const toggleBotActivationThunk = createAsyncThunk<Bot | undefined, ToggleBotActivationParams, { state: CommonRootState }>(
  "campaigns/toggleBotActivation",
  async (params: ToggleBotActivationParams, thunkAPI) => {

    const { bot, shouldActivate, findLatestDeployment = false } = params;
    const { dispatch } = thunkAPI;
    const state = thunkAPI.getState();

    const patchData = {
      _id: bot._id,
      isActive: shouldActivate,
      activeDeploymentId: null,
    } as any;
    if (findLatestDeployment) {
      patchData.$findLatestDeployment = true;
    }

    // Update the bot
    const patchRes = await dispatch(DBBotThunks.patch({
      entity: patchData,
    }));

    // Ensure the response has the correct activeDeploymentId state
    const updatedBot: Bot = (patchRes.payload as Bot)

    // Refresh the bot list
    await dispatch(DBBotThunks.find({ $paginate: false }));

    // If this is the current bot, update it
    if (state.ChatbotReducer.currentBot?._id === bot._id) {
      await dispatch(setCurrentBot(updatedBot));
    }

    return updatedBot;
  }
);


interface HandleNodeDeletionParams {
  deletedNodeId: string;
  setNodes: (updater: (nodes: Node[]) => Node[]) => void;
  setEdges: (updater: (edges: Edge[]) => Edge[]) => void;
}

export const handleNodeDeletionThunk = createAsyncThunk<void, HandleNodeDeletionParams, { state: CommonRootState }>(
  "campaigns/handleNodeDeletion",
  async (params: HandleNodeDeletionParams, thunkAPI) => {
    console.log("[THUNK] handleNodeDeletionThunk - handling node deletion", params.deletedNodeId);
    const { deletedNodeId, setNodes, setEdges } = params;
    const { dispatch } = thunkAPI;
    const state = thunkAPI.getState();
    const nodes = state.ChatbotReducer.nodes;
    const edges = state.ChatbotReducer.edges;
    const currentBot = getCurrentBotSelector(state);

    // Get the node being deleted
    const deletedNode = nodes.find(node => node.id === deletedNodeId);

    // If the deleted node is a question or webhook node and we have a current bot with variables
    if (deletedNode && currentBot?.botSettings?.variables &&
      (deletedNode.type === 'question' || deletedNode.type === 'webhook')) {

      // Create a new bot with the variable removed
      const updatedBot = {
        ...currentBot,
        botSettings: {
          ...currentBot.botSettings,
          variables: {
            ...currentBot.botSettings.variables
          }
        }
      };

      // Delete the variable associated with this node
      delete updatedBot.botSettings.variables[deletedNodeId];

      // Update the current bot
      await dispatch(setCurrentBot(updatedBot));
    }

    // Get remaining nodes and edges after deletion
    const remainingNodes = nodes.filter(node => node.id !== deletedNodeId);
    const remainingEdges = edges.filter(edge =>
      edge.source !== deletedNodeId && edge.target !== deletedNodeId
    );

    // Update nodes and edges
    setNodes(() => remainingNodes);
    setEdges(() => remainingEdges);
  }
);


export const createAndReRouteToNewBotEditorThunk = createAsyncThunk<void, { navigate: (path: string) => void }, { state: CommonRootState }>(
  "campaigns/createAndReRouteToNewBotEditor",
  async (params: { navigate: (path: string) => void }, thunkAPI) => {
    console.log("[THUNK] createAndReRouteToNewBotEditorThunk - creating new bot");
    const { navigate } = params;
    const { dispatch } = thunkAPI;
    const initialBotForCreate = {
      ...initialBot,
      name: "New Bot",
      flowJSON: JSON.stringify({ nodes: initialNodes, edges: initialEdges }),
    }
    /// create new bot and then use its id to navigate to the bot-build-view
    const newBot = await dispatch(DBBotThunks.create(initialBotForCreate)) as { payload: Bot };
    await dispatch(setIsReadOnly(false));
    await dispatch(setSlugBotId(newBot.payload._id));
    await dispatch(changeFlowDataThunk());
    navigate(`/bot-build-view/${newBot.payload._id}`);
  }
);


export const prepareFlowVisualizationThunk = createAsyncThunk<void, string, { state: CommonRootState }>(
  "campaigns/prepareFlowVisualization",
  async (sessionId: string, thunkAPI) => {
    console.log("[THUNK] prepareFlowVisualizationThunk - preparing for session:", sessionId);
    const { dispatch } = thunkAPI;
    const state = thunkAPI.getState();

    try {
      console.log("Preparing flow visualization for session:", sessionId);
      dispatch(setLoaderProgressMessage('Loading session flow...'));

      // Reset flow state first
      await dispatch(setIsReadyToLoadFlow(false));

      const session = automationSessionSelectOneObjectByQuery(state.DBAutomationSessionReducer, { _id: sessionId });
      if (!session) {
        console.error("Session not found:", sessionId);
        dispatch(setLoaderProgressMessage('Session not found'));
        return;
      }

      console.log("Found session:", session);

      const botDeployment = botDeploymentSelectOneObjectByQuery(state.DBBotDeploymentReducer, { _id: session.botDeploymentId });
      if (!botDeployment) {
        console.error("Bot deployment not found for session:", session.botDeploymentId);
        dispatch(setLoaderProgressMessage('Bot deployment not found'));
        return;
      }

      console.log("Found bot deployment:", botDeployment);

      const bot = botSelectOneObjectByQuery(state.DBBotReducer, { _id: botDeployment.botId });
      if (!bot) {
        console.error("Bot not found for deployment:", botDeployment.botId);
        dispatch(setLoaderProgressMessage('Bot not found'));
        return;
      }

      console.log("Found bot:", bot.name);

      // Set read-only mode
      await dispatch(setIsReadOnly(true));

      // Set the current bot with the deployment's flow JSON
      await dispatch(setCurrentBot({
        ...bot,
        flowJSON: botDeployment.flowJSON,
      }));

      // Update the flow data
      await dispatch(changeFlowDataThunk());

      // Clear any loading message
      dispatch(setLoaderProgressMessage(null));

      // Finally, set ready to load flow
      await dispatch(setIsReadyToLoadFlow(true));

      console.log("Flow visualization prepared successfully");
    } catch (error) {
      console.error("Error preparing flow visualization:", error);
      dispatch(setLoaderProgressMessage('Error loading flow visualization'));

      // Short delay to show the error message
      await new Promise(resolve => setTimeout(resolve, 2000));

      // Clear the message
      dispatch(setLoaderProgressMessage(null));

      // Still set ready to load flow to avoid UI being stuck
      await dispatch(setIsReadyToLoadFlow(true));
    }
  }
);

export const focusNodeEventsThunk = createAsyncThunk(
  'chatbot/focusNodeEvents',
  async (nodeId: string, { dispatch, getState }) => {
    console.log("[THUNK] focusNodeEventsThunk - focusing on node:", nodeId);
    const state = getState() as CommonRootState;
    const sessionId = state.ChatbotReducer.currentSessionId;

    if (!sessionId) return;

    // Get all events for the current session
    const events = nodeEventsSelectByQuery(
      state.DBNodeEventsReducer,
      { sessionId },
      null,
      { createdAt: -1 }
    );

    const nodeEvents = events.filter(event => event.jsonId === nodeId);
    if (nodeEvents.length > 0) {
      // Set the focused node ID to trigger scrolling
      dispatch(setFocusedNodeId(nodeId));

    }
  }
);