import React from 'react'
import { Badge } from '@/components/ui/badge'

interface Props {
    isActive: boolean;
}

export default function ActiveSessionChip({ isActive }: Props) {
    return (
        <Badge
            variant="secondary"
            className={`px-2 py-0.5 text-xs font-medium rounded ${
                isActive 
                    ? 'bg-gray-900 text-white hover:bg-gray-900' 
                    : 'bg-gray-500 text-white hover:bg-gray-500'
            }`}
        >
            {isActive ? "Active" : "Inactive"}
        </Badge>
    )
}