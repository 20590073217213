import { appConfig } from '@/common/config/app.config';

export interface OAuthPopupOptions {
  url: string;
  title?: string;
  width?: number;
  height?: number;
  onMessage?: (event: MessageEvent) => void;
  onClose?: () => void;
  originValidation?: string | RegExp | ((origin: string) => boolean);
}

export interface OAuthPopupResult {
  close: () => void;
}

/**
 * Opens an OAuth popup window and handles the communication with it
 * @param options Configuration options for the popup
 * @returns Object with methods to control the popup
 */
export function openOAuthPopup(options: OAuthPopupOptions): OAuthPopupResult {
  const {
    url,
    title = 'OAuth Authentication',
    width = 600,
    height = 600,
    onMessage,
    onClose,
    originValidation = appConfig.API_ENDPOINT
  } = options;

  // Calculate the position to center the popup
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2;

  // Open the popup
  const popup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  );

  if (!popup) {
    console.error('OAuth popup was blocked by the browser');
    throw new Error('Popup blocked. Please allow popups for this site.');
  }

  console.log('OAuth popup opened successfully', { url });

  // Set up a timer to check if the popup is closed
  let checkClosedTimer: number | null = null;
  let hasCalledOnClose = false;
  
  const checkClosed = () => {
    // Check if popup exists and is not closed
    if (!popup || popup.closed) {
      console.log('OAuth popup detected as closed');
      cleanup();
      if (onClose && !hasCalledOnClose) {
        hasCalledOnClose = true;
        console.log('Calling onClose callback');
        onClose();
      }
    }
  };

  // Use a shorter interval for more responsive detection
  checkClosedTimer = window.setInterval(checkClosed, 300);

  // Set up message listener
  const messageListener = (event: MessageEvent) => {
    console.log('Received message from popup', { 
      origin: event.origin,
      hasData: !!event.data,
      dataType: event.data ? typeof event.data : 'undefined',
      dataKeys: event.data && typeof event.data === 'object' ? Object.keys(event.data) : []
    });
    
    // Validate the origin of the message
    const isValidOrigin = validateOrigin(event.origin, originValidation);
    
    if (!isValidOrigin) {
      console.warn('Received message from untrusted origin:', event.origin);
      return;
    }

    if (onMessage) {
      console.log('Calling onMessage callback with data', {
        hasAccessToken: event.data && event.data.accessToken ? 'yes' : 'no',
        hasError: event.data && event.data.error ? 'yes' : 'no',
        type: event.data && event.data.type ? event.data.type : 'none'
      });
      
      onMessage(event);
      
      // If this was a successful auth message, we can close the popup
      if (event.data && 
          (event.data.accessToken || 
           (event.data.type && (
             event.data.type.includes('auth_success') || 
             event.data.type.includes('_auth_success')
           ))
          )) {
        console.log('Received successful auth message, closing popup');
        cleanup();
      }
    }
  };

  window.addEventListener('message', messageListener);

  // Add a focus event listener to detect when the main window regains focus
  // This is often a sign that the popup was closed
  const focusListener = () => {
    console.log('Main window regained focus, checking if popup is closed');
    if (popup && popup.closed) {
      checkClosed();
    }
  };
  
  window.addEventListener('focus', focusListener);

  // Cleanup function
  const cleanup = () => {
    console.log('Cleaning up OAuth popup resources');
    if (checkClosedTimer !== null) {
      window.clearInterval(checkClosedTimer);
      checkClosedTimer = null;
    }
    
    window.removeEventListener('message', messageListener);
    window.removeEventListener('focus', focusListener);
    
    if (popup && !popup.closed) {
      console.log('Closing popup window');
      popup.close();
    }
  };

  // Return methods to control the popup
  return {
    close: cleanup
  };
}

/**
 * Validates the origin of a message
 * @param origin The origin to validate
 * @param validation Validation criteria
 * @returns Whether the origin is valid
 */
function validateOrigin(
  origin: string,
  validation: string | RegExp | ((origin: string) => boolean)
): boolean {
  // For development, accept localhost origins
  if (process.env.NODE_ENV === 'development' && 
      (origin.includes('localhost') || origin.includes('127.0.0.1'))) {
    return true;
  }
  
  if (typeof validation === 'string') {
    return origin === validation || origin.startsWith(validation);
  } else if (validation instanceof RegExp) {
    return validation.test(origin);
  } else if (typeof validation === 'function') {
    return validation(origin);
  }
  
  return false;
}

/**
 * Creates a message handler for OAuth responses
 * @param successType The type of success message
 * @param errorType The type of error message
 * @param onSuccess Callback for successful authentication
 * @param onError Callback for authentication errors
 * @returns A message handler function
 */
export function createOAuthMessageHandler(
  successType: string,
  errorType: string,
  onSuccess: (data: any) => void,
  onError: (error: string) => void
) {
  return (event: MessageEvent) => {
    const { data } = event;
    console.log('OAuth message handler processing event', {
      hasData: !!data,
      dataType: data ? typeof data : 'undefined',
      dataKeys: data && typeof data === 'object' ? Object.keys(data) : []
    });
    
    // Handle direct token response (from backend)
    if (data && data.accessToken) {
      console.log('Received direct token response');
      
      // Calculate token expiry if not provided
      if (!data.tokenExpiry && data.expiresIn) {
        data.tokenExpiry = Date.now() + (data.expiresIn * 1000);
      }
      
      onSuccess({
        ...data,
        type: successType // Add the success type for consistency
      });
      return;
    }
    
    // Handle error response (from backend)
    if (data && data.error) {
      console.log('Received error response', { error: data.error });
      onError(data.error);
      return;
    }
    
    // Handle typed messages (from our own implementation)
    if (data && data.type) {
      if (data.type === successType) {
        console.log('Received success message with type', { type: data.type });
        onSuccess(data);
      } else if (data.type === errorType) {
        console.log('Received error message with type', { 
          type: data.type,
          error: data.error || 'Authentication failed'
        });
        onError(data.error || 'Authentication failed');
      }
    }
  };
}

/**
 * Handles refreshing an OAuth token
 * @param endpoint The endpoint to refresh the token
 * @param refreshToken The refresh token
 * @param additionalData Additional data to send with the request
 * @returns Promise with the refreshed token data
 */
export async function refreshOAuthToken(
  endpoint: string,
  refreshToken: string,
  additionalData: Record<string, any> = {}
): Promise<any> {
  console.log('Refreshing OAuth token', { endpoint });
  
  try {
    const response = await fetch(`${appConfig.API_ENDPOINT}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken,
        ...additionalData
      }),
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Token refresh failed', { 
        status: response.status, 
        statusText: response.statusText,
        errorText
      });
      throw new Error(`Failed to refresh token: ${response.statusText}`);
    }
    
    const data = await response.json();
    console.log('Token refresh successful', { 
      hasAccessToken: !!data.accessToken,
      hasRefreshToken: !!data.refreshToken,
      expiresIn: data.expiresIn
    });
    
    return data;
  } catch (error) {
    console.error('Error refreshing token', { 
      error: error instanceof Error ? error.message : String(error)
    });
    throw error;
  }
} 