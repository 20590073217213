import React, { useEffect, useState, useRef } from 'react'
import RowStack from '../common/row-stack/RowStack';
import CampaignInformation from '@extension/context/content/components/bt-bulk-dashboard/campaign-information';
import { Stack } from '@mui/material';
import { InProgressCampaignIndicator } from '@extension/context/content/components/bt-bulk-dashboard/in-progress-campaign-indicator';
import { StatusBadge } from '@extension/context/content/components/bt-bulk-dashboard/status-labels';
import { getCampaignRealStatus, getNumberOfContactsByCampaignIdThunk } from '@common/reducers/CampaignThunks';
import { RealCampaignStatus } from '@extension/context/content/components/bt-bulk-dashboard/campaign-dialog-row';
import CampaignRowMenu from '@extension/context/content/components/bt-bulk-dashboard/campaign-row-menu';
import { useAppDispatch, useAppSelector } from '@common/reducers/hooks/store.hook';
import { campaignsSelectOneObjectByQuery } from '@common/reducers/DBServiceThunks';

type Props = {
    index: number;
    campaignId: string;
}

export default function CalendarSidebarCampaignRow({ index, campaignId }: Props) {
    const dispatch = useAppDispatch();

    const campaign = useAppSelector(state => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId }))
    const realStatus = getCampaignRealStatus(campaign?.campaignStatus, !!campaign?.contactCount) as RealCampaignStatus;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const rowRef = useRef<HTMLDivElement>(null);
    
    // Track if the menu is open
    const isMenuOpen = Boolean(anchorEl);

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (event: React.MouseEvent) => {
        if (!isMenuOpen && rowRef.current) {
            setAnchorEl(rowRef.current);
        }
    };

    useEffect(() => {
        if (campaign) {
            dispatch(getNumberOfContactsByCampaignIdThunk(campaign?._id))
        }
    }, [campaign, dispatch]);

    return (
        <Stack 
            ref={rowRef}
            key={`campaign${index}`} 
            onClick={handleOpenMenu} 
            sx={{
                cursor: 'pointer',
                p: '16px 11px',
                borderBottom: '1px solid var(--border-list)',
                position: 'relative', // Important for menu positioning
            }}
        >
            <CampaignInformation
                campaignId={campaign?._id}
                width={'278px'}
                showSentProgress={false}
            />
            {
                campaign?.campaignStatus === 'running' &&
                <RowStack justifyContent={'flex-end'} sx={{ paddingTop: '11px', }}>
                    <InProgressCampaignIndicator
                        key={campaign?._id}
                        campaignId={campaign?._id}
                        showProgressBar={true}
                        onClick={() => { }}
                    />
                </RowStack>
            }
            {
                campaign?.campaignStatus === 'pending' && realStatus &&
                <RowStack justifyContent={'flex-end'} sx={{ paddingTop: '11px', }}>
                    {StatusBadge(realStatus)}
                </RowStack>
            }
            <CampaignRowMenu
                campaignId={campaign?._id}
                handleCloseMenu={handleCloseMenu}
                anchorEl={isMenuOpen ? rowRef.current : null}
                shouldShowViewLogs={false}
            />
        </Stack>
    )
}