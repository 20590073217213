import CampaignMessageListContainer from './campaign-message-list-container';
interface CampaignMessagesWaitingToBeSentProps {
    logs: any[];
}

export const CampaignMessagesWaitingToBeSent = ({ logs }: CampaignMessagesWaitingToBeSentProps) => { 
    
    return (
        <CampaignMessageListContainer
            isDataFromWaiting={true}
            logsData={logs}
        />
    );
};

export default CampaignMessagesWaitingToBeSent; 