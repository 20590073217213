import { fetchRule } from "@common-components/schedule-picker/recurrence-utils";
import { ContactInfo } from "@common-models/contact-info";
import { Person } from "@common-models/person";
import { UserMessage } from "@common-models/user-message";
import { DBBoardsThunks, DBUserMessageThunks, DBUserThunks, DBWaContactsCacheThunks, WaChatReducerActions, WaContactsCacheReducerActions, waContactsCacheSelectOneObjectByQuery, webhooksSelectByQuery } from "@common-reducers/DBServiceThunks";
import { AnalyticsService } from "@common-services/analytics-service";
import { blobMap } from '@common-services/blob-service'; // Adjust import for blobMap if needed
import { isExtensionContext } from "@common-services/detect-context";
import { postMessageWithResponse } from "@common-services/post-message-to-whatsapp-functions";
import { accessFormPopupActions } from "@common/reducers/AccessFormPopupReducer";
import { addScheduledMessageButtonActions } from "@common/reducers/AddScheduledMessageButtonReducer";
import { boardsActions } from "@common/reducers/BoardsReducer";
import { getBoardByChat } from "@common/reducers/BoardsSelectors";
import { selectIsWhatsappInitialized } from "@common/reducers/ConnectionReadinessSlice";
import { notifierSubscribeThunk } from "@common/reducers/NotifierThunks";
import { getProfilePicThunk } from "@common/reducers/ProfilePicReducer";
import { updateBoardPersonListThunk } from "@common/reducers/TaskThunks";
import { selectUser, selectUserId } from "@common/reducers/UserSelectors";
import { privateSetCurrentChat, setAppState, setImportedContactsArray } from "@common/reducers/WhatsAppReducer";
import { selectFilteredContacts, selectProfilePic } from "@common/reducers/WhatsAppSelectors";
import { btWaCommRequest, createEntity, isWaSocketsLimitReached, triggerBackendChatbotEvent } from "@common/reducers/backend-api/backend-api";
import { CommonRootState } from "@common/types/common-root-state-type";
import { MessageState } from "@common/types/interface";
import { ContentMessageServiceActions } from "@extension-context-content/services/contentMessageServiceActions";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Chat, GetProfilePicResponse, WhatsappChatMessage, WhatsappContact } from "@wa-communication-layer/whatsapp";
import moment from "moment";
import randomstring from 'randomstring';
import { RRule } from "rrule";

export class Deferred<T = any> {
    promise: Promise<T>;
    reject!: () => void;
    resolve!: (data: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}


const analyticsService = new AnalyticsService();



// if (process.env.NODE_ENV === "development" && window.location.hostname.includes("localhost")) {
//     new MockWapi();
// }


export const setAppStateThunk = createAsyncThunk<
    void,
    string
>("setAppStateThunk", async (state, { dispatch }) => {
    console.log("setAppStateThunk", state);
    dispatch(setAppState(state));
});



export const handleNewMessageThunk = createAsyncThunk<
    void,
    WhatsappChatMessage,
    {}
>("handleNewMessageThunk", async (message, thunkApi) => {
    const { getState } = thunkApi;
    const dispatch = thunkApi.dispatch;
    const state = getState() as CommonRootState;

    const webhookList = webhooksSelectByQuery(state.DBWebhooksReducer, {})

    try {
        dispatch(triggerBackendChatbotEvent.initiate({
            data: {
                ...message,
                userId: state.UserReducer.userId
            }
        }))
    } catch (error) {
        console.error("Error triggering backend chatbot event:", error);
    }

    if (webhookList?.length > 0) {
        dispatch(createEntity.initiate({
            service: 'actions',
            entity: {
                cmd: "sendMessageToWebhook",
                message,
            }
        }))
        analyticsService.event("message_api_receive", {
            category: "messages",
            action: "api-receive",
            label: "regular",
        });
    }

    const userMessageList = Object.keys(
        state.DBUserMessageReducer.entities
    ).map((id) => state.DBUserMessageReducer.entities[id]);
    const contactMessageList = userMessageList.filter(_message => (_message.contactList ?? []).find(
        (contact) => contact.id === message.from?._serialized
    ));
    if (contactMessageList) {
        for (const scheduledMessage of contactMessageList) {
            if (scheduledMessage.cancelIfReceived) {
                dispatch<any>(DBUserMessageThunks.delete({
                    entity: scheduledMessage,
                    options: { inCaseOfErrorRollbackOptimisticUpdateDisabled: true },
                }));
            }
        }
    }
});

export const subscribeCurrentBoardToSocket = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("subscribeCurrentBoardToSocket", async (_, thunkApi) => {

    const { dispatch, getState } = thunkApi;
    const state = getState();

    const chatBoard = getBoardByChat(state, state.WhatsAppReducer?.currentChat);
    if (chatBoard) {
        dispatch(notifierSubscribeThunk({ entityType: 'boards', entityId: chatBoard._id }))
    }
});

export const setCurrentChatThunk = createAsyncThunk<
    void,
    { chat: Chat }
>("setCurrentChatThunk", async ({ chat }, thunkApi) => {

    const { dispatch, getState } = thunkApi;
    const state = getState()


    dispatch(privateSetCurrentChat(chat))

    const chatBoard = getBoardByChat(state, chat);
    if (chatBoard) {
        dispatch(notifierSubscribeThunk({ entityType: 'boards', entityId: chatBoard._id }))
    }
});

export const checkWaSocketsLimitThunk = createAsyncThunk(
    'checkWaSocketsLimit',
    async (_, thunkApi) => {
        const { dispatch, getState } = thunkApi;
        const state = getState() as CommonRootState;
        const userId = selectUserId(state)

        if (state?.AddScheduledMessageButtonReducer?.isWaSocketsLimitReached !== null) {
            return state?.AddScheduledMessageButtonReducer?.isWaSocketsLimitReached;
        }

        if (userId) {
            let res = await dispatch(isWaSocketsLimitReached.initiate({})).unwrap();
            dispatch(addScheduledMessageButtonActions.setIsSocketsLimitReached(res));
        }
    }
);



export const calculateNextDuedate = (dueDate: moment.Moment, message: UserMessage) => {
    if (!!message.rruleset) {
        const rule: RRule = fetchRule(message.rruleset);

        // Create a new Date object for the current time in UTC
        let currentDateUTC = moment.utc();

        // Get max of current date in UTC and due date in UTC
        let maxDateUTC = dueDate.isAfter(currentDateUTC) ? dueDate : currentDateUTC;

        let nextUTC = rule.after(maxDateUTC.toDate());
        if (nextUTC) {
            // Convert UTC date back to moment object
            dueDate = moment.utc(nextUTC);
        } else {
            return null;
        }
    } else {
        const diffUnits = moment.utc().diff(dueDate, message.recurenceUnit, true);

        if (diffUnits > 0) {
            const periods = Math.floor(diffUnits / message.recurrenceQuantity);
            dueDate.add((periods + 1) * message.recurrenceQuantity, message.recurenceUnit);
        }
    }

    return dueDate;
}




export const createWAContactBoardThunk = createAsyncThunk<
    void,
    { clearInput?: boolean },
    { state: CommonRootState }
>("getCurrentChatThunk", async (options, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const currentChat: Chat = state.WhatsAppReducer.currentChat
    const userWA: WhatsappContact = state.WhatsAppReducer.me

    const user = selectUser(state)

    function createParticipant(participant: Partial<WhatsappContact>) {
        return {
            waId: participant?.id?._serialized ?? "",
            name: participant?.displayName ?? "",
            isMyContact: participant?.isMyContact,
            isWAContact: participant?.isWAContact,
        };
    }

    let personList = [];
    let _IdByWaChatIds: string[]

    if (currentChat?.isGroup) {
        personList = (currentChat?.participants ?? []).map(createParticipant);
        _IdByWaChatIds = [currentChat?.id?._serialized]
    } else {
        personList.push(createParticipant(currentChat.contact));
        personList.push(createParticipant(userWA));
        _IdByWaChatIds = [currentChat?.id?._serialized, userWA?.id?._serialized]
    }

    const board = {
        name: currentChat?.formattedTitle ?? currentChat?.displayName ?? currentChat?.name ?? currentChat.id?.user ?? 'New WA board',
        owner: user._id,
        personList: personList,
        isGroup: currentChat?.isGroup ?? false,
        isWABoard: true,
        _IdByWaChatIds: _IdByWaChatIds,
    };

    dispatch(boardsActions.setNewWaBoardStatus("creating WA board"));
    const waBoard: any = await dispatch(DBBoardsThunks.create(board));

    const personProfilePicPromiseList = currentChat.participants.map(participant =>
        dispatch(getProfilePicThunk(participant.id?._serialized)).unwrap()
    )

    Promise.all(personProfilePicPromiseList).then((responseList) => {
        const personList: Person[] = responseList.filter((response: any) => !response.error)
            .map((response: GetProfilePicResponse) => {
                const participant: Partial<WhatsappContact> | undefined = currentChat.participants.find((participant) => participant?.id?._serialized === response.waId)
                return {
                    ...participant,
                    profilePic: response.profilePic,
                }
            })

        dispatch(updateBoardPersonListThunk({
            boardId: waBoard.payload?._id,
            personList
        }));
    })
});


export const handleContactSelectionChangedThunk = createAsyncThunk<
    void,
    { contact: ContactInfo; isSelected: boolean },
    { state: CommonRootState }
>("scheduleMessage", async ({ contact, isSelected }, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const importedContactsArray = state.WhatsAppReducer.importedContactsArray;

    const _importedContactsArray = [...importedContactsArray];
    const chatIndex = _importedContactsArray.findIndex(_contact => _contact.whatsappId === contact.whatsappId);
    if (chatIndex > -1) {
        _importedContactsArray.splice(chatIndex, 1);
    }
    if (isSelected) {
        _importedContactsArray.push(contact);
    }
    dispatch(setImportedContactsArray(_importedContactsArray))
})

export const toggleContactSelectionThunk = createAsyncThunk<
    void,
    { contact: ContactInfo },
    { state: CommonRootState }
>("toggleContactSelectionThunk", async ({ contact }, thunkApi) => {
    const state: CommonRootState = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const isContactChecked = state.WhatsAppReducer.importedContactsArray.find(_contact => _contact.whatsappId === contact.id._serialized) !== undefined;
    dispatch(handleContactSelectionChangedThunk({ contact, isSelected: !isContactChecked }))
});



export const getProfilePicUrlThunk = createAsyncThunk<
    void,
    string,
    { state: CommonRootState }
>("getProfilePicUrlThunk", async (contactWaId, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const state = thunkApi.getState();
    // const session: WASession = selectCurrentUserSession(state)

    let profilePic = selectProfilePic(state, contactWaId);

    if (profilePic === undefined) {
        if (isExtensionContext()) {
            profilePic = await ContentMessageServiceActions.getProfilePicUrl([contactWaId]);

            if (typeof profilePic === 'string') {
            } else if (Array.isArray(profilePic) && (profilePic[0] as any)?.profilePic?.eurl) {
                profilePic = (profilePic[0] as any).profilePic.eurl;
            } else {
                profilePic = undefined;
            }

            if (profilePic) {
                const contact = waContactsCacheSelectOneObjectByQuery(state.DBWaContactsCacheReducer, { waId: contactWaId })

                dispatch(WaContactsCacheReducerActions.upsertOne({
                    _id: contact._id,
                    imgUrl: profilePic ?? '',
                }));
            }
        }
    }
});

export const getWaVersionThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("getWaVersionThunk", async (_, thunkApi) => {
    const dispatch = thunkApi.dispatch;
    const state = thunkApi.getState();
    const user = selectUser(state)

    const version: string = await postMessageWithResponse({ cmd: "getWaVersion" });

    if (version) {
        if (!user?.waVersion || user.waVersion !== version) {
            dispatch(DBUserThunks.patch({
                entity: {
                    _id: user._id,
                    waVersion: version
                }
            }))
        }
    }
});




export const requestMissingContactsImagesThunk = createAsyncThunk<
    void,
    { startIndex, endIndex, debouncedSearchFilter },
    { state: CommonRootState }
>(
    "campaigns/getBoardEntitiesForImportModal",
    async ({ startIndex, endIndex, debouncedSearchFilter }, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const state: CommonRootState = thunkAPI.getState();
        const filteredList = selectFilteredContacts(state, debouncedSearchFilter);

        let contactsToUpdate = []
        contactsToUpdate = filteredList
            .slice(startIndex, endIndex + 1)
            .filter(contact => {
                const needsUpdate = moment(contact?.profilePicLastUpdate).isBefore(moment().subtract(1, 'day')) || !contact?.profilePicLastUpdate
                return needsUpdate;
            })
            .map(contact => (contact.id?._serialized));

        if (contactsToUpdate.length > 0) {
            dispatch(btWaCommRequest.initiate({
                type: 'requestMissingContactsImages',
                contactIds: contactsToUpdate
            }))
        }

    })



export const getMediaFromClipboardToSend = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>(
    'media/sendMedia', async (_, thunkApi) => {
        const { getState, dispatch } = thunkApi;
        const state = getState();

        const user = selectUser(state);
        if (!user) {
            dispatch(accessFormPopupActions.setStatus('Login'));
            return;
        }

        try {
            const mediaObj = await ContentMessageServiceActions.getMedia();
            const currentChat = state.WhatsAppReducer.currentChat;
            if (!currentChat) {
                throw new Error('No chat selected');
            }

            let image: File | undefined;
            let imageId: string | undefined;

            if (mediaObj?.mediaBlobList?.[0]) {
                const mediaDescriptor = mediaObj.mediaBlobList[0];
                const blobData = mediaDescriptor.file instanceof Blob || mediaDescriptor.file instanceof File
                    ? mediaDescriptor.file
                    : mediaDescriptor.file?.blob || mediaDescriptor.file?._blob;

                if (!blobData) {
                    throw new Error('Invalid media format');
                }

                image = new File([blobData], mediaDescriptor.filename || '', { type: mediaDescriptor.mimetype });
                imageId = randomstring.generate(10);
                blobMap.set(imageId, image);
            }

            const contact = {
                ...currentChat,
                name: currentChat?.displayName ?? currentChat?.contact?.displayName ?? 'Unknown',
                id: currentChat?.id?._serialized
            };

            // DO NOT REMOVE THE setTimeout - WITHOUT IT SOMETIMES THE WHATSAPP IS STUCK
            setTimeout(() => {
                console.log("open editMessage with image")
                dispatch(addScheduledMessageButtonActions.editMessage({
                    message: mediaObj?.caption,
                    imageId,
                    contactList: [contact],
                    dueDate: moment().toISOString(),
                    isRecurring: false,
                    state: MessageState.editing
                }));
            }, 1000);
        } catch (error) {
            console.error('Failed to process media:', error);
            throw error;
        }
    }
);


type GetChatsAndContactsThunkParams = {
    shouldForceUpdate?: boolean
    shouldRefreshContacts?: boolean
}

export const waitForWhatsappInitializationThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("waitForConnectedThunk", async (_, thunkApi) => {
    const state = thunkApi.getState() as CommonRootState;

    const checkWhatsappInitialization = async () => {
        const isWhatsappInitialized = selectIsWhatsappInitialized(state);
        if (isWhatsappInitialized) {
            return true;
        }
        return false;
    };

    while (true) {
        const isInitialized = await checkWhatsappInitialization();
        if (isInitialized) {
            return;
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
    }
});

export const getChatsAndContactsThunk = createAsyncThunk<
    void,
    GetChatsAndContactsThunkParams,
    { state: CommonRootState }
>(
    'whatsapp/getChatsAndContactsThunk', async (getChatsAndContactsThunkParams: GetChatsAndContactsThunkParams, thunkApi) => {
        const { getState, dispatch } = thunkApi
        const state = getState()
        const user = selectUser(state)
        const shouldForceUpdate = getChatsAndContactsThunkParams.shouldForceUpdate ?? false
        const shouldRefreshContacts = getChatsAndContactsThunkParams.shouldRefreshContacts ?? false

        if (!user) {
            dispatch(accessFormPopupActions.setStatus('Login'));
            return;
        }

        if (isExtensionContext()) {

            if (!state.DBWaContactsCacheReducer.itemsLoadedLocaly || !state.DBWaChatReducer.itemsLoadedLocaly) {
                await dispatch(waitForWhatsappInitializationThunk());
                const chatsAndContacts: any = await ContentMessageServiceActions.getChatsAndContacts();

                dispatch(WaContactsCacheReducerActions.setAll(chatsAndContacts?.contactList ?? []));
                dispatch(WaChatReducerActions.setAll(chatsAndContacts?.chatList ?? []));

                dispatch(WaContactsCacheReducerActions.setItemsLoadedLocaly(true));
                dispatch(WaChatReducerActions.setItemsLoadedLocaly(true));
            }
        }
        if (!isExtensionContext()) {
            const isLoadingContactsCache = state.DBWaContactsCacheReducer.itemsLoading
            const hasContactsCache = state.DBWaContactsCacheReducer.ids.length > 0
            // if the contacts cache is not loading and there are no contacts, then we need to fetch the contacts
            if (!isLoadingContactsCache && (!hasContactsCache || shouldRefreshContacts)) {
                await dispatch(DBWaContactsCacheThunks.find({ $forceContactsUpdate: shouldForceUpdate }))
            }
        }



    }
);

export const getWaLinkPreviewThunk = createAsyncThunk<
    any,
    string,
    { state: CommonRootState }
>(
    'whatsapp/getLinkPreview', async (link, thunkApi) => {
        try {
            const preview = await ContentMessageServiceActions.getWaLinkPreview(link);
            return preview;
        } catch (error) {
            console.error('Failed to get link preview:', error);
            throw error;
        }
    }
);

// Map to store pending profile picture requests
let pendingProfilePicRequests = new Map<string, boolean>();
let debounceTimer: NodeJS.Timeout | null = null;
const DEBOUNCE_DELAY = 500;

export const requestProfilePicThunk = createAsyncThunk<
    void,
    string,
    { state: CommonRootState }
>(
    "whatsapp/requestProfilePic",
    async (contactWaId, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        const state = thunkAPI.getState();

        // Add the contact ID to pending requests
        pendingProfilePicRequests.set(contactWaId, true);

        // Clear existing timer
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        // Set new timer
        debounceTimer = setTimeout(async () => {
            // Get all pending contact IDs
            const contactIds = Array.from(pendingProfilePicRequests.keys());
            
            // Clear pending requests
            pendingProfilePicRequests.clear();
            
            // Only proceed if we have contacts to update
            if (contactIds.length > 0) {
                // Filter out contacts that already have profile pics
                const contactsToUpdate = contactIds.filter(id => {
                    const profilePic = selectProfilePic(state, id);
                    return profilePic === undefined;
                });

                if (contactsToUpdate.length > 0) {
                    dispatch(btWaCommRequest.initiate({
                        type: 'requestMissingContactsImages',
                        contactIds: contactsToUpdate
                    }));
                }
            }
        }, DEBOUNCE_DELAY);
    }
);


