import { SvgIcon } from "@mui/material";
import classes from "../bt-chatbot.module.scss";
import chroma from 'chroma-js';
import React from 'react'

type Props = {
    bgColor: string;
    title: string;
    secondary_title: string;
    NodeIcon: any;
    isMinimal?: boolean;
    isLast?: boolean;
    isSecondaryNode?: boolean;
    svgPath?: string;
}

export default function NodeButton({
    bgColor,
    title,
    secondary_title,
    NodeIcon,
    isMinimal,
    isLast,
    svgPath,
    isSecondaryNode,
}: Props) {

    const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;


    return (
        <>
            {isMinimal && // for select menu on drag end of new edge
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '19px',
                    padding: '10px 0px',
                    borderBottom: isLast ? 0 : '1px solid rgb(232, 234, 242)',
                }}>
                    <div style={{
                        backgroundColor: `${bgColor}c9`,
                        color: 'white',
                        width: '46px',
                        height: '46px',
                        borderRadius: '23px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '10px',
                        zIndex: -1,
                    }} >
                        <NodeIcon sx={{ fontSize: 28 }} />
                    </div>
                    {title}
                </div>
            }
            {isSecondaryNode &&// for secondary nodes in toolbar
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '19px',
                    padding: '10px 0px',
                    color: '#666666',
                }}>
                    <div style={{
                        backgroundColor: '#F4F4F4',
                        color: 'white',
                        width: '46px',
                        height: '46px',
                        borderRadius: '23px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '15px',
                    }} >
                        <SvgIcon sx={{ fontSize: 25, transform: 'scale(1.2)' }}>
                            <svg viewBox="0 0 24 24">
                                <defs>
                                    <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" stopColor={chroma(bgColor).brighten(1).hex()} />
                                        <stop offset="100%" stopColor={chroma(bgColor).darken(0.25).hex()} />
                                    </linearGradient>
                                </defs>
                                <path fill={`url(#${gradientId})`} d={svgPath ?? ''} />
                            </svg>
                        </SvgIcon>
                    </div>
                    {title}
                </div>
            }
            {!isMinimal && !isSecondaryNode && // for main nodes in toolbar
                <div className={classes.toolbar_node_button_container} style={{ backgroundColor: bgColor }}>
                    <div style={{
                        width: '82px',
                        height: '82px',
                        borderRadius: '41px',
                        position: 'absolute',
                        backgroundColor: 'white',
                        opacity: '0.1',
                        top: '-7px',
                        left: '-19px',
                    }}></div>
                    <div style={{
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                        backgroundColor: '#ffffff42',
                        width: '46px',
                        height: '46px',
                        borderRadius: '23px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }} >
                        <NodeIcon sx={{ fontSize: 28 }} />
                    </div>
                    <div className={classes.toolbar_node_button_title}>{title}</div>
                    <div className={classes.toolbar_node_button_secondary_title}>
                        {secondary_title}
                    </div>
                </div>
            }
        </>
    )
}