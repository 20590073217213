import _ from 'lodash';

export interface QueuedMessage {
  messageId: number;
  path: string;
  method: string;
  entity: any;
}

interface MessageQueue {
  messages: QueuedMessage[];
  lastMessageId: number;
}

export interface MissingMessagesRequest {
  room: string;
  fromId: number;
}

export class MessageRecoveryService {
  public lastMessageIds: Map<string, number> = new Map();
  private messageQueues: Map<string, MessageQueue> = new Map();
  private onRequestMissingMessages?: (request: MissingMessagesRequest) => void;
  private onProcessMessage?: (message: QueuedMessage) => boolean;
  private pendingRequests: Map<string, { fromId: number }> = new Map();
  private sendAggregatedRequests: _.DebouncedFunc<() => void>;
  private debugMode: boolean = true;

  constructor() {
    // Initialize debounced function for sending requests
    this.sendAggregatedRequests = _.debounce(() => {
      if (!this.onRequestMissingMessages || this.pendingRequests.size === 0) return;

      this.debugLog(`Sending aggregated requests for ${this.pendingRequests.size} rooms`);
      
      // Convert pending requests to array format and send
      Array.from(this.pendingRequests.entries()).forEach(([room, range]) => {
        this.debugLog(`Requesting messages for room ${room} From: ${range.fromId}`);
        this.onRequestMissingMessages({
          room,
          fromId: range.fromId
        });
      });

      // Clear pending requests
      this.pendingRequests.clear();
    }, 100, { maxWait: 1000 }); // 100ms delay, max 1s wait
  }

  private debugLog(message: string): void {
    if (this.debugMode) {
      console.log(`[Recovery] ${message}`);
    }
  }

  /**
   * Set handlers for external events
   */
  public setHandlers(handlers: {
    onRequestMissingMessages: (request: MissingMessagesRequest) => void;
    onProcessMessage: (message: QueuedMessage) => boolean;
  }) {
    this.onRequestMissingMessages = handlers.onRequestMissingMessages;
    this.onProcessMessage = handlers.onProcessMessage;
  }

  /**
   * Handle socket reconnection
   */
  public handleReconnect() {
    this.debugLog('[Recovery] Socket reconnected, checking for missed messages');
    this.lastMessageIds.forEach((lastId, roomId) => {
      if (lastId !== -1) {
        this.debugLog(`[Recovery] Requesting missed messages for room ${roomId} since ID ${lastId}`);
        this.requestMissingMessages(roomId, lastId);
      }
    });
  }

  /**
   * Handle heartbeat check for missed messages
   */
  public handleHeartbeat() {
    this.debugLog('[Recovery] Heartbeat triggered, checking for missed messages');
    this.lastMessageIds.forEach((lastId, roomId) => {
      if (lastId !== -1) {
        this.debugLog(`[Recovery] Heartbeat requesting missed messages for room ${roomId} since ID ${lastId}`);
        this.requestMissingMessages(roomId, lastId);
      }
    });
  }

  /**
   * Handle missed messages
   */
  public handleMissedMessages(messages: QueuedMessage[], roomId: string) {
    if (!messages) {
      this.debugLog(`[Recovery] Received undefined messages for room ${roomId}`);
      return;
    }
    
    // Initialize room if not already initialized
    if (!this.messageQueues.has(roomId)) {
      this.initializeRoom(roomId);
    }
    
    this.debugLog(`[Recovery] Received missed messages: ${messages.length}`);
    
    // Sort messages by ID
    const sortedMessages = [...messages].sort((a, b) => a.messageId - b.messageId);
    
    // Queue all messages for processing
    sortedMessages.forEach(message => {
      this.debugLog(`[Recovery] Queueing message ${message.messageId} for room ${roomId}`);
      this.queueMessage(roomId, message);
    });

    // Process all queued messages
    this.processQueuedMessages();
  }

  /**
   * Handle an entity update message
   */
  public handleEntityUpdate(roomId: string, message: QueuedMessage): void {
    if (this.shouldProcessMessage(roomId, message.messageId)) {
      this.processMessage(roomId, message);
    } else {
      // Queue message for later processing
      this.debugLog(`[Recovery] Queuing message ${message.messageId} for room ${roomId}`);
      this.queueMessage(roomId, message);
      this.requestMissingMessages(roomId, message.messageId);
    }
  }

  /**
   * Initialize recovery for a room
   */
  public initializeRoom(roomId: string, initialMessageId: number = -1) {
    this.lastMessageIds.set(roomId, initialMessageId);
    this.messageQueues.set(roomId, { messages: [], lastMessageId: -1 });
    this.debugLog(`[Recovery] Initialized room ${roomId} with message ID ${initialMessageId}`);
  }

  /**
   * Update the last processed message ID for a room
   */
  public updateLastMessageId(roomId: string, messageId: number): void {
    const previousId = this.lastMessageIds.get(roomId) ?? -1;
    this.lastMessageIds.set(roomId, messageId);
    this.debugLog(`[Recovery] Updated last message ID for room ${roomId}: ${previousId} -> ${messageId}`);

    // Process any queued messages that might now be ready
    this.processQueuedMessages();
  }

  /**
   * Process an incoming message
   */
  public shouldProcessMessage(roomId: string, messageId: number): boolean {
    const lastId = this.lastMessageIds.get(roomId) ?? -1;
    const queue = this.messageQueues.get(roomId);

    this.debugLog(`[Recovery] Should process message ${messageId} for room ${roomId} (last: ${lastId})`);
    
    if (!queue) {
      this.initializeRoom(roomId, messageId);
      return true;
    }
    
    // Always process if we don't have a last ID
    if (lastId === -1) {
      this.debugLog(`[Recovery] First message for room ${roomId}: ${messageId}`);
      return true;
    }

    // Ignore old or duplicate messages
    if (messageId <= lastId) {
      this.debugLog(`[Recovery] Skipping old/duplicate message ${messageId} for room ${roomId} (last: ${lastId})`);
      return false;
    }

    // If there's a gap and we're not already waiting for messages
    if (messageId > lastId + 1) {
      this.debugLog(`[Recovery] Detected message gap in room ${roomId}: ${lastId} -> ${messageId}`);
      queue.lastMessageId = lastId;
      this.requestMissingMessages(roomId, lastId);
      return false;
    }

    // Update last message ID and process the message
    this.debugLog(`[Recovery] Processing message ${messageId} for room ${roomId}`);
    return true;
  }

  private queueMessage(roomId: string, message: QueuedMessage) {
    const queue = this.messageQueues.get(roomId);
    if (!queue) return;

    queue.messages.push(message);
    queue.messages.sort((a, b) => a.messageId - b.messageId);
    this.debugLog(`[Recovery] Queued message ${message.messageId} for room ${roomId}. Queue size: ${queue.messages.length}`);
  }

  /**
   * Process all queued messages across all rooms
   */
  private processQueuedMessages() {
    // Process each room that has queued messages
    for (const [roomId, queue] of this.messageQueues.entries()) {
      if (!queue || queue.messages.length === 0) continue;

      const lastId = this.lastMessageIds.get(roomId) ?? -1;
      this.debugLog(`[Recovery] Processing queue for room ${roomId} (last: ${lastId}, queued: ${queue.messages.length})`);

      // Sort messages by ID to ensure oldest to newest order
      const sortedMessages = [...queue.messages].sort((a, b) => a.messageId - b.messageId);
      
      // Process each message in order
      for (const message of sortedMessages) {
        const messageId = message.messageId;
        this.debugLog(`[Recovery] Checking message ${messageId} for room ${roomId}`);

        // Remove old or duplicate messages
        if (messageId <= lastId) {
          this.debugLog(`[Recovery] Removing old/duplicate message ${messageId} from queue (last: ${lastId})`);
          queue.messages = queue.messages.filter(m => m.messageId !== messageId);
          continue;
        }

        // Check for gaps
        if (messageId > lastId + 1) {
          this.debugLog(`[Recovery] Detected gap in room ${roomId}: ${lastId} -> ${messageId}`);
          queue.lastMessageId = lastId;
          this.requestMissingMessages(roomId, lastId);
          break; // Stop processing this room's queue until gap is filled
        }

        // Process message if it's the next in sequence
        if (messageId === lastId + 1) {
          this.debugLog(`[Recovery] Processing message ${messageId} for room ${roomId}`);
          if (!this.processMessage(roomId, message)) {
            this.debugLog(`[Recovery] Failed to process message ${messageId}, stopping queue processing for room ${roomId}`);
            break;
          }
        }
      }

      // Clear waiting state if queue is empty
      if (queue.messages.length === 0) {
        queue.lastMessageId = -1;
        this.debugLog(`[Recovery] Queue cleared for room ${roomId}`);
      }
    }
  }

  /**
   * Process a single message and update state if successful
   * @returns boolean indicating if processing was successful
   */
  private processMessage(roomId: string, message: QueuedMessage): boolean {
    if (!this.onProcessMessage?.(message)) {
      return false;
    }

    // Update state only after successful processing
    const queue = this.messageQueues.get(roomId);
    if (queue) {
      queue.messages = queue.messages.filter(m => m.messageId !== message.messageId);
    }
    this.updateLastMessageId(roomId, message.messageId);
    return true;
  }

  private requestMissingMessages(roomId: string, fromId: number) {
    // Update or add the request range for this room
    const existing = this.pendingRequests.get(roomId);
    if (existing) {
      // Expand the range to include both requests
      this.debugLog(`[Recovery] Expanding request range for room ${roomId}: ${existing.fromId} to ${fromId}`);
      this.pendingRequests.set(roomId, {
        fromId: Math.min(existing.fromId, fromId)
      });
    } else {
      this.debugLog(`[Recovery] Setting new request range for room ${roomId}: ${fromId}`);
      this.pendingRequests.set(roomId, { fromId });
    }

    // Trigger debounced send
    this.sendAggregatedRequests();
  }
} 