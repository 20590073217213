import { ContactInfo, contactMatches } from "@common-models/contact-info";
import { isExtensionContext } from "@common-services/detect-context";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { contactSelectByQuery } from "@common-reducers/DBServiceThunks";
import { CommonRootState } from "@common/types/common-root-state-type";
import { Chat, WhatsappContact } from "@wa-communication-layer/whatsapp";

export type ThemeType = "light" | "dark";


export interface UserInfo {
  email: string;
  id: string;
}
export interface WhatsAppState {
  currentChat: Chat | null;
  chatList: Chat[];
  chatMap: { [key: string]: Chat };
  theme: ThemeType;
  errorState: boolean;
  completeDetailsClosed: boolean;
  userInfo?: UserInfo;
  invalidWANumber?: string;
  me?: WhatsappContact;
  wapiInitialized: boolean;
  assetMap: Record<string, File>,
  currentExtensionVersion: string,
  importedContactsArray: ContactInfo[];
  showWizard?: boolean;
  hoveredDate?: any;
  isReply?: any;
  mentions?: { id: string, display: string }[];
  redisContactsUpdateTimestamp?: string;
  addMsgInCalendarContact?: any;
  extensionSidebarSearchToken?: string;
  highlightedContactId?: string;
  appState?: string;
}

const initialState: WhatsAppState = {
  theme: "light",
  currentChat: null,
  chatMap: {},
  chatList: [],
  errorState: false,
  completeDetailsClosed: false,
  wapiInitialized: false,
  assetMap: {},
  currentExtensionVersion: "",
  importedContactsArray: [],
  showWizard: false,
  hoveredDate: undefined,
  isReply: null,
  mentions: null,
  redisContactsUpdateTimestamp: undefined,
  addMsgInCalendarContact: null,
  extensionSidebarSearchToken: undefined,
  highlightedContactId: undefined,
  appState: undefined,
};

const WhatsAppSlice = createSlice({
  name: "whatsApp",
  initialState,
  reducers: {
    setAsset: (state, action: PayloadAction<{ url: string, file: File }>) => {
      state.assetMap[action.payload.url] = action.payload.file;
    },

    privateSetCurrentChat(state, action: PayloadAction<Chat>) {
      console.log("privateSetCurrentChat");
      state.currentChat = action.payload;
    },

    setWAchats(state, action: PayloadAction<Chat[]>) {
      console.log("setWAchats");
      state.chatMap = action.payload.reduce((chatMap, chat) => {
        const chatId = chat?.id?._serialized;
        if (chatId) {
          chatMap[chatId] = chat;
        }
        return chatMap;
      }, {} as Record<string, Chat>);
      state.chatList = Object.keys(state.chatMap).map(
        (chatId) => state.chatMap[chatId]
      ) ?? [];
    },
    setTheme(state, action: PayloadAction<ThemeType>) {
      state.theme = action.payload;
    },
    setErrorState(state, action: PayloadAction<boolean>) {
      state.errorState = action.payload;
    },
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setInvalidWANumber: (state, action: PayloadAction<string>) => {
      state.invalidWANumber = action.payload;
    },
    setMe: (state, action: PayloadAction<WhatsappContact>) => {
      state.me = action.payload;
    },
    setWapiInitialized: (state, action: PayloadAction<boolean>) => {
      state.wapiInitialized = action.payload;
    },
    setCurrentExtensionVersion(state, action: PayloadAction<string>) {
      state.currentExtensionVersion = action.payload
    },
    setImportedContactsArray(state, action: PayloadAction<any[]>) {
      state.importedContactsArray = action.payload
    },
    setShowWizard(state, action: PayloadAction<boolean>) {
      state.showWizard = action.payload
    },
    setHoveredDate: (state, action: PayloadAction<any>) => {
      state.hoveredDate = action.payload;
    },
    setAddMsgInCalendarContact: (state, action: PayloadAction<any>) => {
      state.addMsgInCalendarContact = action.payload;
    },
    setIsReply: (state, action: PayloadAction<any>) => {
      state.isReply = action.payload;
    },
    setMentions: (state, action: PayloadAction<any>) => {
      state.mentions = action.payload;
    },
    setExtensionSidebarSearchToken: (state, action: PayloadAction<any>) => {
      state.extensionSidebarSearchToken = action.payload;
    },
    setHighlightedContactId: (state, action: PayloadAction<string | undefined>) => {
      state.highlightedContactId = action.payload;
    },
    setAppState: (state, action: PayloadAction<string>) => {
      state.appState = action.payload;
    },
  },
});

export const selectMergedContactMap = createSelector(
  (state: CommonRootState) => {
    const contactList = (state?.DBWaContactsCacheReducer?.ids ?? []).map(id => state?.DBWaContactsCacheReducer?.entities[id]);
    const importedContactList: ContactInfo[] = contactSelectByQuery(state.DBContactReducer, {
      $or: [
        { deleted: { $exists: false } },
        { deleted: false }
      ],
    });

    let _mergedContactMap: Record<string, ContactInfo> = {};
    for (const waContact of contactList) {
      if (waContact?.id?._serialized && !waContact.id._serialized.includes('@lid')) {
        _mergedContactMap[waContact.id._serialized] = {
          ...waContact,
          whatsappId: waContact.id._serialized
        };
      }
    }
    for (const contact of importedContactList) {
      if (contact?.whatsappId) {
        _mergedContactMap[contact.whatsappId] = {
          ...(_mergedContactMap[contact.whatsappId] ?? {}),
          ...contact
        };
      }
    }
    return _mergedContactMap;
  },
  (mergedContactMap) => mergedContactMap
);

export const selectMergedContactMapWithNamesOnly = createSelector([
  (state) => state
], (state) => {
  const mergedContactMap = selectMergedContactMap(state);
  // create from mergedContactMap a new map of all the contacts that has the 'name' field not undefined
  // and the value is the contact itself
  const mergedContactMapWithNamesOnly = Object
    .keys(mergedContactMap)
    .filter(whatsappId => mergedContactMap[whatsappId].name && mergedContactMap[whatsappId].name !== '')
    .reduce((mergedContactMapWithNamesOnly, whatsappId) => {
      mergedContactMapWithNamesOnly[whatsappId] = mergedContactMap[whatsappId];
      return mergedContactMapWithNamesOnly;
    }, {} as Record<string, ContactInfo>);


  return mergedContactMapWithNamesOnly
});

export const selectMergedContactList = createSelector([
  (state) => selectMergedContactMap(state)
], (mergedContactMap: Record<string, ContactInfo>) => {
  return Object
    .keys(mergedContactMap)
    .map(whatsappId => mergedContactMap[whatsappId])
});


export const getContactListForMentions = createSelector([
  (state) => selectMergedContactList(state),
  (state) => state
], (mergedContactList, state) => {
  const mentions = state.WhatsAppReducer.mentions;
  if (mentions) {
    return mentions
  }

  // const contactListForMentions = mergedContactList
  //   .filter(contact => ['c.us', 'g.us'].includes(contact.id._serialized.split('@')[1]))
  //   .map(contact => {
  //     return ({
  //       id: contact.id._serialized,
  //       display: contact.name ?? contact.id._serialized.split('@')[0]
  //     })
  //   })

  // return contactListForMentions
});

export const selectFilteredMergedContactList = createSelector([
  (state) => selectMergedContactMap(state),
  (state: CommonRootState, filterValue: string) => filterValue,
], (mergedContactMap: Record<string, ContactInfo>, filterValue: string) => {
  return Object
    .keys(mergedContactMap)
    .map(whatsappId => mergedContactMap[whatsappId])
    .filter(contact => contactMatches(filterValue, contact));
});


export const getContactsForRedisUpdate = createSelector([
  (state) => selectMergedContactMap(state),
], (mergedContactMap: Record<string, ContactInfo>) => {
  if (isExtensionContext()) {
    return Object
      .keys(mergedContactMap)
      .map(whatsappId => mergedContactMap[whatsappId])
  }
});


export const selectedSelectedContactCount = createSelector([
  (state) => state.WhatsAppReducer.importedContactsArray,
], (importedContactsArray) => {
  return importedContactsArray.length;
});

export const selectFilteredContactList = createSelector([
  (state) => state,
  (state) => selectMergedContactList(state),
  (state) => state.ContactTableReducer.filterValue,
  (state) => state.ContactTableReducer.showSelected,
  (state) => state.CampaignReducer.contactListPanelState.selectedListId,
], (state: CommonRootState, mergedContactList: ContactInfo[], filterValue: string, showSelected: boolean, selectedListId) => {

  const sortFilteredContactsByName = (contacts: any[]) => {
    try {
      return contacts.slice().sort((a, b) => {
        const aName = a.name || a.shortName || a.pushname || a._serialized?.split('@')[0] || '';
        const bName = b.name || b.shortName || b.pushname || b._serialized?.split('@')[0] || '';

        // Ensure that both aName and bName are strings for comparison
        return aName.localeCompare(bName);
      });
    } catch (err) {
      return contacts
    }
  }


  const importedContactsArray = state.WhatsAppReducer.importedContactsArray;
  const selectedIdList = importedContactsArray.map(contact => contact.whatsappId);
  const filteredContactList = mergedContactList
    .filter((contact: ContactInfo) => {
      return (filterValue ? contactMatches(filterValue, contact) : true) &&
        (showSelected ? selectedIdList.indexOf(contact.id?._serialized ?? contact.whatsappId ?? '') > -1 : true)
    });

  return sortFilteredContactsByName(filteredContactList)
});






export const {
  privateSetCurrentChat,
  setWAchats,
  setTheme,
  setErrorState,
  setUserInfo,
  setInvalidWANumber,
  setMe,
  setWapiInitialized,
  setCurrentExtensionVersion,
  setImportedContactsArray,
  setShowWizard,
  setHoveredDate,
  setAddMsgInCalendarContact,
  setIsReply,
  setMentions,
  setExtensionSidebarSearchToken,
  setHighlightedContactId,
  setAppState,
} = WhatsAppSlice.actions;
export const WhatsappActions = WhatsAppSlice.actions;

export default WhatsAppSlice.reducer;
