import { changeNodeDataThunk } from '@/common/reducers/ChatbotThunks';
import { useAppDispatch } from '@/common/reducers/hooks/store.hook';
import { closeToolSettingsModal } from '@/common/reducers/ToolSettingsModalReducer';
import { CommonRootState } from '@/common/types/common-root-state-type';
import {
  Button
} from '@/components/ui/button';
import {
  Loader2,
  Wrench,
  X
} from "lucide-react";
import React, { ReactElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { validateToolSettings } from './tool-settings-validator';
// Define a common interface for all tool settings components
export interface ToolSettingsComponentProps {
  initialSettings?: any;
  onSaveSettings?: (settings: any) => void;
  onValidationChange?: (hasErrors: boolean) => void;
}

interface ToolSettingsDialogProps {
  open: boolean;
  toolName: string;
  initialSettings?: any;
  children?: ReactElement<ToolSettingsComponentProps>;
}

const ToolSettingsDialog: React.FC<ToolSettingsDialogProps> = ({
  open,
  toolName,
  initialSettings = {},
  children
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [currentSettings, setCurrentSettings] = useState(initialSettings);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const nodeId = useSelector((state: CommonRootState) => state.ToolSettingsModalReducer.nodeId);

  // Reset validation errors when dialog opens
  useEffect(() => {
    if (open) {
      console.log(`Tool Settings Dialog opened for: ${toolName}`);
      console.log('Initial settings:', initialSettings);
      setCurrentSettings(initialSettings);
      setHasValidationErrors(false);
      setValidationMessage('');

      // Validate initial settings
      const validation = validateToolSettings(toolName, initialSettings);
      setHasValidationErrors(!validation.isValid);
      if (!validation.isValid) {
        setValidationMessage(validation.message);
      }
    }
  }, [open, toolName, initialSettings]);

  // Add debug logging for validation errors
  useEffect(() => {
    console.log(`Validation errors: ${hasValidationErrors}`);
    if (hasValidationErrors) {
      console.log(`Validation message: ${validationMessage}`);
    }
  }, [hasValidationErrors, validationMessage]);

  const handleSave = () => {
    console.log('Save button clicked. Current settings:', currentSettings);

    // Validate settings before saving
    const validation = validateToolSettings(toolName, currentSettings);
    if (!validation.isValid) {
      setHasValidationErrors(true);
      setValidationMessage(validation.message);
      console.log(`Cannot save: ${validation.message}`);
      return;
    }

    setLoading(true);

    try {
      // Dispatch a custom event to notify the knowledge-base-settings component
      console.log('Dispatching save-tool-settings event');
      document.dispatchEvent(new CustomEvent('save-tool-settings'));

      // Small delay to ensure the event handlers have time to process
      setTimeout(() => {
        // Call the onSave callback with the current settings
        console.log(`Calling onSave for tool: ${toolName} with settings:`, currentSettings);

        // The parent component (AgentNode) already handles organizing settings by tool name,
        // so we just pass the settings directly
        dispatch(changeNodeDataThunk({
          id: nodeId,
          dataToPatch: {
            toolSettings: {
              [toolName]: currentSettings
            }
          }
        }));

        dispatch(closeToolSettingsModal());
        
        setLoading(false);
      }, 50);
    } catch (err) {
      console.error('Failed to save settings', err);
      setLoading(false);
    }
  };

  const handleSettingsChange = (settings: any) => {
    console.log('Settings changed in dialog:', settings);
    setCurrentSettings(settings);

    // Validate the new settings
    const validation = validateToolSettings(toolName, settings);
    console.log(`Validation result for ${toolName}:`, validation);
    setHasValidationErrors(!validation.isValid);
    setValidationMessage(validation.message);
  };

  const handleClose = () => {
    console.log('Closing dialog');
    dispatch(closeToolSettingsModal());
  };

  // Only render if open is true
  if (!open) {
    console.log('Dialog is closed, not rendering');
    return null;
  }

  console.log('Rendering dialog for', toolName);

  // Create the modal content
  const modalContent = (
    <div className="fixed inset-0 bg-black/50 z-[9999] flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-3xl mx-4 overflow-hidden">
        {/* Dialog Header */}
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <div className="flex items-center space-x-2">
            <Wrench className="h-5 w-5 text-indigo-600" />
            <h2 className="text-xl font-semibold text-gray-800">
              {toolName} Settings
            </h2>
          </div>
          <button
            onClick={handleClose}
            className="p-1 rounded-full text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-colors duration-200"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {/* Dialog Content */}
        <div className="p-6 max-h-[70vh] overflow-y-auto">
          {React.cloneElement(children as React.ReactElement<ToolSettingsComponentProps>, {
            initialSettings,
            onSaveSettings: handleSettingsChange,
            onValidationChange: (hasErrors) => {
              console.log(`Validation change from child component: ${hasErrors}`);
              setHasValidationErrors(hasErrors);
            }
          })}

          {/* Validation Error Message */}
          {hasValidationErrors && validationMessage && (
            <div className="mt-4 p-3 bg-amber-50 border border-amber-200 rounded-md text-amber-700 text-sm">
              {validationMessage}
            </div>
          )}
        </div>

        {/* Dialog Footer */}
        <div className="flex justify-end space-x-2 px-6 py-4 bg-gray-50 border-t border-gray-200">
          <Button
            variant="outline"
            onClick={(event) => {
              event.stopPropagation();
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || hasValidationErrors}
            onClick={(event) => {
              event.stopPropagation();
              handleSave();
            }}
          >
            {loading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Saving...
              </>
            ) : (
              'Save Settings'
            )}
          </Button>
        </div>
      </div>
    </div>
  );

  // Use createPortal to render the modal directly in the document body
  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
};

export default ToolSettingsDialog; 