import { CommonRootState } from '@common/types/common-root-state-type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isExtensionContext, isExtensionContextOrWhatsappView } from '@common-services/detect-context';

interface ConnectionReadinessState {
    isUserAuthenticated: boolean;
    isWhatsappInitialized: boolean;
}

const initialState: ConnectionReadinessState = {
    isUserAuthenticated: false,
    isWhatsappInitialized: false,
};

const connectionReadinessSlice = createSlice({
    name: 'connectionReadiness',
    initialState,
    reducers: {
        setUserAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isUserAuthenticated = action.payload;
        },
        setWhatsappInitialized: (state, action: PayloadAction<boolean>) => {
            state.isWhatsappInitialized = action.payload;
        },
        resetConnectionState: (state) => {
            state.isUserAuthenticated = false;
            state.isWhatsappInitialized = false;
        },
    },
});

export const {
    setUserAuthenticated,
    setWhatsappInitialized,
    resetConnectionState
} = connectionReadinessSlice.actions;

export default connectionReadinessSlice.reducer;

// Selector to check if we're ready to connect
export const selectIsReadyToConnect = (state: CommonRootState) => {
    // Always need user authentication
    if (!state.ConnectionReadinessSlice?.isUserAuthenticated) {
        return false;
    }

    // Only check WhatsApp initialization in extension context
    if (isExtensionContextOrWhatsappView()) {
        return state.ConnectionReadinessSlice?.isWhatsappInitialized;
    }

    // In web context, we only need user authentication
    return true;
};

export const selectIsWhatsappInitialized = (state) => {

    if (isExtensionContext()) {
        return state.ConnectionReadinessSlice.isWhatsappInitialized;
    }
    return false;
}; 