import { getIsFromNodeEventSelector, getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { MoreVert } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import NodeMenu from '../../bot-components/node-menu';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import classes from "./trigger-node.module.scss";
import { useParams } from 'react-router-dom';
import { nodeTypes } from '../bt-nodes-def';
import { focusNodeEventsThunk } from '@common-reducers/ChatbotThunks';
import { TriggerType } from '@common/types/trigger.types';

interface TriggerState {
    type: 'trigger';
    triggerType: TriggerType;
    contacts: string[];
}

interface Props {
    id: string;
}



export default function TriggerNodeContainer({ id }: Props) {
    const dispatch = useAppDispatch();
    const { sessionId } = useParams<{ sessionId: string }>();
    const isFromNodeEvent = useAppSelector(state => getIsFromNodeEventSelector(state, id, sessionId));
    const isReadOnly = useAppSelector(state => state.ChatbotReducer.isReadOnly);
    const data = useAppSelector(state => getNodeDataByNodeId(state, id)) as TriggerState;

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const nodeType = nodeTypes?.[data?.type]

    const handleOpenNodeMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMenuOpen((prev => !prev))
        setAnchorEl(event.currentTarget);
    };

    const handleCloseNodeMenu = () => {
        setMenuOpen(false);
        setAnchorEl(null);
    }

    const handleNodeClick = () => {
        if (isReadOnly && isFromNodeEvent) {
            dispatch(focusNodeEventsThunk(id));
        }
    };

    const getTriggerSummary = (data: TriggerState): { mainText: string; subText: string } => {
        const contactCount = data?.contacts?.length || 0;
        return {
            mainText: data?.triggerType === 'allowList' ? 'Allow only:' : 'Allow all except:',
            subText: `${contactCount} contact${contactCount !== 1 ? 's' : ''}`
        };
    };

    const borderColor = !isFromNodeEvent && isReadOnly ? '#939393' : nodeType?.color;

    const content = (
        <Stack className={classes.trigger_container}
            sx={{
                border: `6px solid ${borderColor}`,
                cursor: (!isFromNodeEvent && isReadOnly) ? 'default' : 'pointer',

                '&:hover': {
                    borderColor: `darken(${borderColor}, 10%)`,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                }

            }}
            onClick={handleNodeClick}
        >
            <IconButton sx={{
                p: 0,
                color: '#666666',
                marginLeft: '5px',
                position: 'absolute',
                right: '30px',
                top: '10px',
            }}
                onClick={handleOpenNodeMenu} disabled={isReadOnly}>
                <MoreVert />
            </IconButton>
            <NodeMenu type={data?.type} open={menuOpen} onClose={handleCloseNodeMenu} anchorEl={anchorEl} id={id} />
            <div className={classes.trigger_title}>Trigger</div>
            <div className={classes.trigger_description}>This node triggers the flow</div>
            <Stack spacing={0.5} sx={{ mt: 1, px: 2 }}>
                <Typography variant="caption" sx={{ color: '#666666' }}>
                    {getTriggerSummary(data).mainText}
                </Typography>
                <Typography variant="caption" sx={{ color: '#666666' }}>
                    {getTriggerSummary(data).subText}
                </Typography>
            </Stack>
        </Stack>
    );

    if (isReadOnly && isFromNodeEvent) {
        return (
            <Tooltip title="Click to view related events in the timeline" arrow placement="top">
                {content}
            </Tooltip>
        );
    }

    return content;
} 