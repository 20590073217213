import { getChatbotTempId } from "@common/services/utils";

export const initialNodes = [{
    id: getChatbotTempId(),
    data: {
        label: 'Trigger',
        handles: {
            inputs: [],
            outputs: [
                {
                    id: getChatbotTempId(),
                    type: 'source'
                },
            ],
        },
        type: 'trigger',
        triggerType: 'blockList',
        contacts: []
    },
    position: { x: 250, y: 25 },
    type: 'trigger',
}];

export const initialEdges = []; 