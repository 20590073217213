import { getHandleId, getTargetHandleStyle } from '@common-services/utils'
import { Handle, Position } from 'reactflow'
import { nodeTypes } from '../bt-nodes-def'
import NodeContainer from '../node-container'


export default function WebhookNode({ id, data }) {

    const nodeColor = nodeTypes.webhook.color


    return (
        <>
            <Handle type="target" position={Position.Left} id={getHandleId(data.handles, 'target', 0)}
                style={{
                    zIndex: 3,
                    ...getTargetHandleStyle(nodeColor),
                }} />
            <NodeContainer type="webhook" id={id} />
        </>
    )
}