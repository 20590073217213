import BotMessageEditor from '@common-components/bt-chatbot/bot-components/bot-message-editor';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { openToolsDialog, openToolSettingsModal } from '@common/reducers/ToolSettingsModalReducer';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useWebDispatch } from '@website/store/web-store';
import React, { useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import { validateToolSettings } from './tools/settings/tool-settings-validator';

interface Props {
    id: string;
}

interface AgentNodeData {
    type: string;
    prompt?: string;
    asset?: any;
    tools?: string[];
    toolSettings?: Record<string, any>;
}

export default function AgentNodeContainer({ id }: Props) {
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => getNodeDataByNodeId(state, id)) as AgentNodeData;

    const webDispatch = useWebDispatch();

    // Calculate validation status for each tool
    const toolValidationStatus = useMemo(() => {
        const validationResults: Record<string, { isValid: boolean; message: string }> = {};

        if (data.tools && data.tools.length > 0) {
            data.tools.forEach(toolName => {
                const toolSettings = data.toolSettings?.[toolName] || {};
                console.log(`Validating tool ${toolName} with settings:`, toolSettings);
                validationResults[toolName] = validateToolSettings(toolName, toolSettings);
                console.log(`Validation result for ${toolName}:`, validationResults[toolName]);
            });
        }

        return validationResults;
    }, [data.tools, data.toolSettings]);

    const type = data?.type;


    // Function to handle tool selection
    const handleToolsSelected = (tools: string[]) => {
        console.log('Tools selected:', tools);

        // Save to backend via redux
        dispatch(changeNodeDataThunk({
            id,
            dataToPatch: {
                tools,
                toolSettings: data.toolSettings || {},
                prompt: data.prompt || ''
            }
        }));
    };

    // Function to handle prompt change
    const handlePromptChange = (value: { message: string; asset: any }) => {
        // Save to backend via redux
        dispatch(changeNodeDataThunk({
            id,
            dataToPatch: {
                tools: data.tools || [],
                toolSettings: data.toolSettings || {},
                prompt: value.message
            }
        }));
    };

    // Function to handle tool click
    const handleToolClick = (toolName: string, e?: React.MouseEvent) => {
        if (e) {
            e.stopPropagation();
        }
        console.log('Tool clicked:', toolName);

        // Register a callback for when settings are saved


        // Dispatch the action to open the modal using the web store dispatch
        webDispatch(openToolSettingsModal({
            toolName,
            nodeId: id,
            initialSettings: data.toolSettings?.[toolName] || {},
        }));
    };

    // Function to handle opening the tools dialog
    const handleOpenToolsDialog = (e?: React.MouseEvent) => {
        if (e) {
            e.stopPropagation();
        }
        console.log('Opening tools dialog with selected tools:', data.tools);
        dispatch(openToolsDialog({
            nodeId: id,
        }));
    };

    // Function to handle removing a tool
    const handleRemoveTool = (toolName: string, e?: React.MouseEvent) => {
        if (e) {
            e.stopPropagation();
        }
        const updatedTools = (data.tools || []).filter(tool => tool !== toolName);
        handleToolsSelected(updatedTools);
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <div className="p-4">
                {/* System Prompt */}
                <div className="mb-4">
                    <Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
                        System Prompt
                    </Typography>
                    <BotMessageEditor
                        _message={{ message: data.prompt || '', asset: data.asset }}
                        onChange={handlePromptChange}
                        type={type}
                    />
                </div>

                {/* Tools Section */}
                <div className="mb-4">
                    <Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
                        Tools
                    </Typography>
                    <div className="flex justify-end mb-2">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOpenToolsDialog();
                            }}
                            className="flex items-center text-xs text-blue-600 hover:text-blue-800"
                        >
                            <AddIcon fontSize="small" className="mr-1" />
                            Add Tools
                        </button>
                    </div>

                    {data.tools && data.tools.length > 0 ? (
                        <div className="space-y-2">
                            {data.tools.map((tool) => {
                                const validationResult = toolValidationStatus[tool] || { isValid: true, message: '' };

                                return (
                                    <div
                                        key={tool}
                                        className="flex items-center justify-between p-2 bg-gray-50 rounded border border-gray-200"
                                    >
                                        <div className="flex items-center">
                                            <span className="text-sm font-medium text-gray-700">{tool}</span>
                                            {!validationResult.isValid && (
                                                <Tooltip title={validationResult.message || "This tool requires configuration"}>
                                                    <WarningIcon
                                                        fontSize="small"
                                                        className="ml-2 text-amber-500"
                                                    />
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div className="flex space-x-1">
                                            <Tooltip title="Configure Tool">
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleToolClick(tool);
                                                    }}
                                                    className={`p-1 rounded-full hover:bg-gray-100 ${!validationResult.isValid ? 'text-amber-500 animate-pulse' : 'text-gray-500 hover:text-blue-600'}`}
                                                >
                                                    <SettingsIcon fontSize="small" />
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Remove Tool">
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRemoveTool(tool);
                                                    }}
                                                    className="p-1 text-gray-500 hover:text-red-600 rounded-full hover:bg-gray-100"
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-16 text-gray-400 text-xs text-center px-2">
                            No tools selected. Click "Add Tools" to select tools for this agent.
                        </div>
                    )}
                </div>
            </div>
        </Stack>
    );
} 