import { KeyboardArrowLeft } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { currentBoardExistsSelector, getSelectedBoardId } from '@common-reducers/BoardsSelectors'
import { handleActiveBulkViewNavigationThunk } from '@common-reducers/CampaignThunks'
import { isTempId } from "@common-reducers/DBServiceThunks"
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook'
import TaskView from '@common-views/task-view/task-view'
import { useMemo } from 'react'
import BulkButton from './bulk-button'


export default function BulkTaskViewContainer() {
    const dispatch = useAppDispatch()

    const selectedBoardId = useAppSelector(getSelectedBoardId);
    const isCurrentBoardExists = useAppSelector(currentBoardExistsSelector);


    const isBoardReadyToDisplay = useMemo(() => {
        const isReady = selectedBoardId && !isTempId(selectedBoardId) && isCurrentBoardExists;
        return isReady
    }, [selectedBoardId, isCurrentBoardExists])




    return (
        <>
            {isBoardReadyToDisplay &&
                <Stack direction='column' sx={{
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'var(--default-background) !important',
                }}>
                    <Box sx={{ margin: '10px 0px', }}>
                        <BulkButton
                            onClick={() => {
                                dispatch(handleActiveBulkViewNavigationThunk({ navigate: "fromCampaignBoardView" }))
                            }}
                            buttonString="Back"
                            Icon={KeyboardArrowLeft}
                        />
                    </Box>

                    <TaskView
                        isDisplaySharedUsersAvatar={false}
                        isDisplayShareButton={false}
                        isDisplayMenuButton={false}
                        isDisplayDarkBrightModeButton={false}
                        isDisplayTabs={false}
                        isDisplayTasksPanelHeader={true}
                        isDisplayNewGroupButton={false}
                        isDisplaySearchField={true}
                        isDisplayPersonButton={false}
                        isDisplayColumnsButton={true}
                        isDisplayImportButton={true}
                        isDisplayExportButton={false}
                        isDisplayBoardPanel={false}
                        isDisplayUpgradeButton={false}
                        isDisplayActivityButton={false}
                        isDisplayNotificationsButton={false}
                        isFromCampaign={true}
                        taskViewTheme="lean"
                    />
                </Stack>
            }
            {!isBoardReadyToDisplay &&
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100vh', backgroundColor: 'var(--default-background) !important', }}>
                    <CircularProgress />
                </Stack>
            }
        </>
    )
}