import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { UserMessage } from '@common/models/user-message';
import { ContactCell } from '@common/views/user-overview/tabs/messages/components/ContactCell';
import { MessageCell } from '@common/views/user-overview/tabs/messages/components/MessageCell';
import ContactAvatar from '@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar';
import { Check, CheckCircle, Error, Schedule } from '@mui/icons-material';
import { Box, Divider, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { Virtuoso } from 'react-virtuoso';
import { MessageAssetPreview } from '../calendar/message-asset-preview';
import { getAgendaFlattenedData } from '@common/reducers/CalendarSelectors';


interface AgendaViewProps {
  onEventSelect: (event: UserMessage & Partial<DOMRect>) => void;
}

const getStatusIcon = (message: UserMessage) => {
  if (!message.status) return <Schedule fontSize="small" color="disabled" />;

  switch (message.status.toLowerCase()) {
    case 'sent':
      return <Check fontSize="small" color="success" />;
    case 'delivered':
      return <CheckCircle fontSize="small" color="success" />;
    case 'failed':
      return <Error fontSize="small" color="error" />;
    default:
      return <Schedule fontSize="small" color="disabled" />;
  }
};

const AgendaView = ({ onEventSelect }: AgendaViewProps) => {
  const flattenedData = useAppSelector(state => getAgendaFlattenedData(state))


  const handleMessageClick = (message: UserMessage, event: React.MouseEvent) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    onEventSelect({
      ...message,
      left: rect.left,
      right: rect.right,
      top: rect.top,
      bottom: rect.bottom
    });
  };

  const ItemContent = (index: number) => {
    const item = flattenedData[index];

    if (item.type === 'header') {
      return (
        <>
          <ListItem>
            <Typography variant="h6" sx={{
              py: 1,
              color: 'text.secondary',
              fontWeight: 500,
              fontSize: '0.875rem'
            }}>
              {moment(item.date).format('dddd, MMMM D, YYYY')}
            </Typography>
          </ListItem>
          <Divider />
        </>
      );
    }

    const message = item.message;
    return (
      <>
        <ListItem
          data-message-id={message.id}
          alignItems="flex-start"
          onClick={(e) => handleMessageClick(message, e)}
          sx={{
            '&:hover': {
              bgcolor: 'action.hover',
            },
            cursor: 'pointer',
            px: 2,
            py: 1,
            borderLeft: message.messageColor ? `4px solid ${message.messageColor}` : undefined
          }}
        >
          <ListItemAvatar>
            <ContactAvatar contactId={message.contactList?.[0]?.id} size={40} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="span" variant="body2" color="text.primary">
                  {moment(message.dueDate).format('h:mm A')}
                </Typography>
                <ContactCell contact={message.contact} />
                {message?.asset?.url && message?.asset?.type && (
                  <MessageAssetPreview
                    url={message.asset.url}
                    type={message.asset.type}
                  />
                )}
                <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  {getStatusIcon(message)}
                </Box>
              </Stack>
            }
            secondary={
              <Box sx={{ mt: 0.5 }}>
                <MessageCell
                  message={message}
                  hideMediaIcon={true}
                  onMediaClick={(msg) => {
                    const parentElement = document.querySelector(`[data-message-id="${msg.id}"]`);
                    if (parentElement) {
                      const rect = parentElement.getBoundingClientRect();
                      onEventSelect({
                        ...msg,
                        left: rect.left,
                        right: rect.right,
                        top: rect.top,
                        bottom: rect.bottom
                      });
                    }
                  }}
                />
              </Box>
            }
          />
        </ListItem>
        <Divider />
      </>
    );
  };

  return (
    <Stack sx={{ flex: 1, overflow: 'hidden', height: '100%' }}>
      <Virtuoso
        style={{ height: '100%' }}
        totalCount={flattenedData.length}
        itemContent={ItemContent}
        overscan={200}
      />
    </Stack>
  );
};

export default AgendaView; 