import RowStack from '@common-components/common/row-stack/RowStack';
import { theme } from '@common/theme';
import { CircularProgress, FormControlLabel, Switch } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
    sx?: SxProps;
    checked: boolean;
    onChange: (e?: any) => void;
    isOptional?: boolean;
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    bot?: any; // Type this properly based on your bot type
}

export default function BotToggleSwitch({
    sx,
    checked,
    onChange,
    isOptional,
    label,
    disabled,
    loading,
    bot
}: Props) {
    // const [dialogOpen, setDialogOpen] = useState(false);
    // const validationResult = useDeploymentValidation(bot);

    const handleChange = (e: any) => {
        e.stopPropagation();
        // Only show dialog when activating and there are warnings
        if (onChange) {
            onChange(e);
        }
    };

    // const handleConfirm = () => {
    //     setDialogOpen(false);
    //     onChange();
    // };

    // const handleCancel = () => {
    //     setDialogOpen(false);
    // };

    return (
        <>
            <RowStack>
                {isOptional && <div style={{
                    fontStyle: 'italic',
                    fontWeight: 400,
                    fontSize: '12px',
                    marginRight: '20px',
                }}>Optional</div>}
                <FormControlLabel
                    sx={{
                        '& .MuiFormControlLabel-label': {
                            color: 'rgb(102, 102, 102)',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            lineHeight: '18px',
                            marginLeft: '5px',
                            minWidth: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        },
                        position: 'relative',
                    }}
                    label={loading ? (
                        <CircularProgress size={12} sx={{ ml: '3px' }} />
                    ) : label}
                    disabled={disabled || loading}
                    control={
                        <Switch
                            sx={{
                                width: 42,
                                height: 26,
                                padding: 0,
                                '& .MuiButtonBase-root': {
                                    margin: '2px !important',
                                },
                                '& .MuiSwitch-switchBase': {
                                    padding: 0,
                                    margin: 2,
                                    transitionDuration: '300ms',
                                    '&.Mui-checked': {
                                        transform: 'translateX(16px)',
                                        color: '#fff',
                                        '& + .MuiSwitch-track': {
                                            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                                            opacity: 1,
                                            border: 0,
                                        },
                                        '&.Mui-disabled + .MuiSwitch-track': {
                                            opacity: 0.5,
                                        },
                                    },
                                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                                        color: '#33cf4d',
                                        border: '6px solid #fff',
                                    },
                                    '&.Mui-disabled .MuiSwitch-thumb': {
                                        color:
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[100]
                                                : theme.palette.grey[600],
                                    },
                                    '&.Mui-disabled + .MuiSwitch-track': {
                                        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                                    },
                                },
                                '& .MuiSwitch-thumb': {
                                    boxSizing: 'border-box',
                                    width: 22,
                                    height: 22,
                                },
                                '& .MuiSwitch-track': {
                                    borderRadius: 26 / 2,
                                    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                                    opacity: 1,
                                    transition: theme.transitions.create(['background-color'], {
                                        duration: 500,
                                    }),
                                },
                            }}
                            checked={checked}
                            onChange={handleChange}
                            disabled={disabled || loading}
                        />
                    }
                />
            </RowStack>

            {/* <EnhancedActivationDialog
                open={dialogOpen}
                onClose={handleCancel}
                onConfirm={handleConfirm}
                loading={loading}
                validationResult={validationResult}
            /> */}
        </>
    );
}