import React, { useEffect, useCallback } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Loader2, CheckCircle, XCircle, RefreshCw, AlertTriangle } from 'lucide-react';
import { ToolSettingsComponentProps } from './tool-settings-dialog';
import { useOAuth, OAuthTokens } from '@/common/hooks/useOAuth';

export const LinkedInSettings: React.FC<ToolSettingsComponentProps> = ({
  initialSettings = {},
  onSaveSettings,
  onValidationChange
}) => {
  // Handle token updates
  const handleTokensChange = useCallback((tokens: OAuthTokens) => {
    if (onSaveSettings) {
      onSaveSettings({
        ...initialSettings,
        ...tokens
      });
    }
  }, [initialSettings, onSaveSettings]);

  // Configure the OAuth hook for LinkedIn
  const oauth = useOAuth(
    {
      toolName: 'LinkedIn',
      authEndpoint: '/auth/linkedin',
      refreshEndpoint: '/auth/linkedin/refresh',
      successMessageType: 'linkedin_auth_success',
      errorMessageType: 'linkedin_auth_error',
    },
    initialSettings as OAuthTokens,
    handleTokensChange
  );
  
  // Update validation state when connection status changes
  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(!oauth.state.isConnected);
    }
  }, [oauth.state.isConnected, onValidationChange]);
  
  // Handle connecting to LinkedIn
  const handleConnect = useCallback(() => {
    // Get botId from initialSettings or use a default value
    const botId = initialSettings.botId || initialSettings.id || 'default-bot-id';
    
    if (!botId) {
      console.error('Bot ID is required to connect to LinkedIn');
      return;
    }
    
    console.log('Connecting to LinkedIn with botId:', botId);
    oauth.connect({ 
      botId,
      tool: 'linkedin' // Explicitly specify the tool for the backend
    });
  }, [initialSettings, oauth]);
  
  // Format error message for display
  const getErrorMessage = (error: string | null) => {
    if (!error) return null;
    
    if (error === 'Authentication canceled') {
      return 'Authentication was canceled. Please try again.';
    }
    
    if (error.includes('access_denied')) {
      return 'Access was denied. Please grant the required permissions.';
    }
    
    if (error.includes('popup')) {
      return 'Popup was blocked. Please allow popups for this site.';
    }
    
    return error;
  };
  
  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="text-lg">LinkedIn</CardTitle>
        <CardDescription>
          Connect to LinkedIn to access your professional network.
        </CardDescription>
      </CardHeader>
      
      <CardContent>
        {oauth.state.isConnected && !oauth.state.isExpired && oauth.state.email && (
          <div className="flex items-center space-x-2 text-sm">
            <CheckCircle className="h-5 w-5 text-green-500" />
            <span>Connected as <strong>{oauth.state.email}</strong></span>
          </div>
        )}
        
        {oauth.state.isConnected && oauth.state.isExpired && oauth.state.email && (
          <div className="flex items-center space-x-2 text-sm">
            <AlertTriangle className="h-5 w-5 text-amber-500" />
            <span>Token expired for <strong>{oauth.state.email}</strong></span>
          </div>
        )}
        
        {!oauth.state.isConnected && (
          <div className="flex items-center space-x-2 text-sm">
            <XCircle className="h-5 w-5 text-gray-400" />
            <span>Not connected to LinkedIn</span>
          </div>
        )}
        
        {oauth.state.error && (
          <div className="mt-2 p-2 bg-red-50 border border-red-200 rounded-md text-sm text-red-600">
            <div className="flex items-start">
              <AlertTriangle className="h-4 w-4 mr-2 mt-0.5 flex-shrink-0" />
              <span>Error: {getErrorMessage(oauth.state.error)}</span>
            </div>
          </div>
        )}
      </CardContent>
      
      <CardFooter className="flex justify-between">
        {!oauth.state.isConnected ? (
          <Button 
            onClick={handleConnect} 
            disabled={oauth.state.isConnecting}
            className="w-full"
          >
            {oauth.state.isConnecting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Connecting...
              </>
            ) : (
              'Connect LinkedIn'
            )}
          </Button>
        ) : (
          <div className="flex w-full space-x-2">
            {oauth.state.isExpired && (
              <Button 
                onClick={() => oauth.refreshToken()} 
                disabled={oauth.state.isConnecting} 
                variant="outline"
                className="flex-1"
              >
                {oauth.state.isConnecting ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <RefreshCw className="mr-2 h-4 w-4" />
                )}
                Refresh Token
              </Button>
            )}
            
            <Button 
              onClick={() => oauth.disconnect()} 
              variant="destructive"
              className="flex-1"
            >
              Disconnect
            </Button>
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

export default LinkedInSettings; 