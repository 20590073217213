import RowStack from '@common-components/common/row-stack/RowStack';
import { cleanMessageLogsFromStateExceptRunningCampaignsThunk, getCampaignRealStatus, getNumberOfContactsByCampaignIdThunk } from '@common-reducers/CampaignThunks';
import { campaignSelectors, DBCampaignThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { Stack } from '@mui/material';
import { memo, useEffect } from 'react';
import CampaignActionsMenu from './campaign-actions-menu';
import CampaignInformation from './campaign-information';
import InProgressCampaignIndicator from './in-progress-campaign-indicator';
import { StatusBadge } from './status-labels';

export type RealCampaignStatus = 'Pending' | 'Running' | 'Paused' | 'Completed' | 'Aborted';

type Props = {
    itemId: string;
    index: number;
};

function CampaignDialogRow({ itemId: campaignId, index }: Props) {
    const dispatch = useAppDispatch();
    const campaign = useAppSelector(state => campaignSelectors.selectById(state, campaignId));
    const realStatus = getCampaignRealStatus(campaign?.campaignStatus, !!campaign.contactCount) as RealCampaignStatus;

    // Add polling for campaign status updates
    /*
    useEffect(() => {
        if (!campaignId) return;
            
        // Don't start polling if campaign is in a final state
        if (realStatus === 'Completed' || realStatus === 'Aborted') {
            return;
        }

        const pollInterval = setInterval(async () => {
            try {
                // Fetch latest campaign data
                const result = await dispatch(DBCampaignThunks.find({ _id: campaignId }));
                const updatedCampaign = result.payload?.[0];
                
                // Get the updated status
                const updatedStatus = getCampaignRealStatus(updatedCampaign?.campaignStatus, !!updatedCampaign?.contactCount) as RealCampaignStatus;
                
                // If campaign is in a final state, stop polling
                if (updatedStatus === 'Completed' || updatedStatus === 'Aborted') {
                    clearInterval(pollInterval);
                }
            } catch (error) {
                console.error('Error fetching campaign data:', error);
                clearInterval(pollInterval);
            }
        }, 5000); // Poll every 5 seconds

        return () => clearInterval(pollInterval);
    }, [campaignId, dispatch]);

    // Add effect to clean message logs when component mounts
    useEffect(() => {
        if (campaignId) {
            dispatch(cleanMessageLogsFromStateExceptRunningCampaignsThunk());
        }
    }, [campaignId, dispatch]);
    */

    useEffect(() => {
        if (campaign) {
            dispatch(getNumberOfContactsByCampaignIdThunk(campaign?._id))
        }
    }, [campaign])

    return (
        <Stack sx={{
            borderBottom: '1px solid var(--campaign-border)',
            padding: '10px 10px 10px 10px',
        }}>
            <RowStack key={index}
                sx={{
                    justifyContent: 'space-between',
                    height: campaign?.campaignStatistics ? "90px" : "110px",
                }}>
                <CampaignInformation
                    campaignId={campaign?._id}
                    width={'500px'}
                    showSentProgress={false}
                />
                <RowStack sx={{ justifyContent: 'space-around' }}>
                    <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', gap: "5px" }}>
                        {/* Campaign Status */}
                        {realStatus && StatusBadge(realStatus)}

                        {campaign?.contactCount && (realStatus === 'Running' || realStatus === 'Paused') &&
                            (
                                <InProgressCampaignIndicator
                                    campaignId={campaign?._id}
                                    sx={{
                                        height: '20px',
                                        borderRadius: '6px',
                                        marginTop: '10px',
                                    }}
                                />
                            )}
                    </Stack>
                    {/* Campaign Actions Menu */}
                    <CampaignActionsMenu campaignId={campaign?._id} />
                </RowStack>
            </RowStack>

            {/* { (false) &&
                <RowStack sx={{ gap: '25px', justifyContent: 'space-between' }}>
                    <RowStack>
                        {campaign?.contactCount &&
                            <Alert severity="info" sx={alertStyle}>
                                Total <span style={{ fontWeight: 600 }}>{campaign?.contactCount}</span>
                            </Alert>
                        }
    
                        <Tooltip title="Sync campaign statistics">
                            <IconButton onClick={async () => {
                                await dispatch(cleanMessageLogsFromStateExceptRunningCampaignsThunk())

                                 await dispatch(calculateCampaignStatisticsThunk({ 
                                    campaignId: campaignId.toString()
                                }));
                            }}><Sync /></IconButton>
                        </Tooltip>
                    </RowStack>

                    <Alert icon={<CheckRounded fontSize="inherit" />} sx={alertStyle} severity="success">
                        <span style={{ fontWeight: 600 }}>
                            {0 + campaign?.campaignStatistics?.['1'] + campaign?.campaignStatistics?.['2']}
                        </span> Sent
                    </Alert>

                    <Alert icon={<AccessTimeRounded fontSize="inherit" />} sx={alertStyle} severity="warning">
                        <span style={{ fontWeight: 600 }}>
                            {(campaign?.campaignStatistics?.['0'] ?? 0)
                                //  + numberOfPendingWithoutAck
                            }
                        </span> Pending
                    </Alert>

                    <Alert icon={<PriorityHighRounded fontSize="inherit" />} sx={{
                        ...alertStyle,
                        padding: '2px 12px 2px 3px',
                        '& .MuiAlert-icon': {
                            marginRight: 0,
                            padding: 0,
                        },
                    }} severity="error">
                        <span style={{ fontWeight: 600 }}>
                            {(campaign?.campaignStatistics?.['-1'] ?? 0)
                                //  + numberOfErrorsWithoutAck
                            }
                        </span>{` error(s)`}
                    </Alert>
                </RowStack>
            } */}

        </Stack>
    );
}

export default memo(CampaignDialogRow);