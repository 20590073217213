import { setActiveBulkView, setActiveTab } from '@common-reducers/CampaignReducer';
import { DBCampaignThunks, campaignsSelectByQuery } from "@common-reducers/DBServiceThunks";
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { cleanMessageLogsFromStateExceptRunningCampaignsThunk } from '@common/reducers/CampaignThunks';
import { AddOutlined } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import BulkButton from './bulk-button';
import BulkSearchField from './bulk-search-field';
import CampaignDialogRow from './campaign-dialog-row';
import LazyLoadingVirtuoso from './lazy-loading-virtuoso';
import { isExtensionContext } from '@common/services/detect-context';

export default function BulkCampaignDialog() {
    // selectors
    const dispatch = useAppDispatch()

    const [searchTerm, setSearchTerm] = useState("");

    const handleNewCampaignClick = () => {
        dispatch(setActiveTab('audiences'));
        dispatch(setActiveBulkView("newCampaignView"))
    };


    const cleanDBMessageLogState = async () => {
        dispatch(cleanMessageLogsFromStateExceptRunningCampaignsThunk())
    }

    useEffect(() => {
        cleanDBMessageLogState()
    }, [])


    return (
        <>

            <Stack sx={{ width: '100%', }}>
                <Stack direction='row' sx={{
                    width: '100%',
                }}>
                    <BulkSearchField
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        placeHolderString="Search campaigns..."
                    />
                    <BulkButton
                        onClick={handleNewCampaignClick}
                        buttonString='New Campaign'
                        Icon={AddOutlined}
                        buttonWidth='230px'
                    />
                </Stack>

                <Stack sx={{ border: '1px solid var(--campaign-border)', borderRadius: '12px', marginTop: '20px', }}>
                    <LazyLoadingVirtuoso
                        isFullWidth={true}
                        height={isExtensionContext() ? 500 : 480}
                        rowHeightString='136px'
                        filterObj={{
                            $or: [
                                { 'template.name': { $regex: searchTerm, $options: 'i' } },
                                { 'template.message': { $regex: searchTerm, $options: 'i' } },
                                { 'template.poll.question': { $regex: searchTerm, $options: 'i' } },
                            ],
                        }}
                        DBThunks={DBCampaignThunks}
                        DBReducer={state => state.DBCampaignReducer}
                        selectByQuery={campaignsSelectByQuery}
                        Component={CampaignDialogRow}
                    />
                </Stack>
            </Stack>
        </>
    )
}