import { ManageAccountsOutlined, MoreVert } from '@mui/icons-material';
import { AvatarGroup, IconButton, Menu, MenuItem, Modal } from '@mui/material';
import { getTaskSubscribedUsersList } from '@common-reducers/ExtraDataThunks';
import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { useMemo, useState } from 'react';
import PersonAvatar from '../person-avatar/person-avatar';
import ManageSubscribersModal from './manage-subscribers-modal';
import classes from "./task-subscribers-dialog.module.scss";




export default function TaskSubscribersDialog() {
    const [isSubscriptionManagerModalOpen, setIsSubscriptionManagerModalOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const subscribedUsersList = useAppSelector((state) => getTaskSubscribedUsersList(state))



    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const memoizedSubscribedUsersList = useMemo(() => {
        return subscribedUsersList.sort((a, b) => a.toString().localeCompare(b.toString()));
    }, [subscribedUsersList]);


    return (
        <div className={classes.members}>
            <AvatarGroup 
                max={5}
                className={classes.avatarGroup}
            >
                {memoizedSubscribedUsersList.map((person, index) => (
                    <div style={{ opacity: person?.status === "active" ? "1" : '0.5' }} key={index} className={classes.member}>
                        <PersonAvatar
                            size={28}
                            email={person.email}
                            profileImg={person?.profile?.profileImg}
                            lastName={(person?.profile?.lastName === 'undefined' || person?.profile?.lastName === undefined) ? ' ' : person?.profile.lastName}
                            firstName={person?.profile?.firstName}
                            toolTip={true}
                        />
                    </div>
                ))}
            </AvatarGroup>
            <IconButton 
                onClick={handleMenuClick} 
                className={classes.menuButton}
                aria-label="Manage subscribers"
            >
                <MoreVert sx={{ fontSize: 18, color: 'var(--text-primary)' }} />
            </IconButton>
            <Menu
                id="subscribers-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                disableScrollLock
                autoFocus={false}
                MenuListProps={{
                    'aria-labelledby': 'subscribers-menu-button',
                    disablePadding: true
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    elevation: 6,
                    sx: { 
                        minWidth: '240px',
                        mt: 1.5,
                        borderRadius: '14px',
                        overflow: 'hidden',
                        boxShadow: '0 18px 35px -8px rgba(0, 0, 0, 0.15), 0 4px 15px -3px rgba(0, 0, 0, 0.08)',
                        animation: 'dropIn 0.2s forwards',
                        '@keyframes dropIn': {
                            '0%': { opacity: 0, transform: 'translateY(-8px) scale(0.96)' },
                            '70%': { opacity: 1, transform: 'translateY(2px) scale(1.01)' },
                            '100%': { transform: 'translateY(0) scale(1)' }
                        }
                    }
                }}
                transitionDuration={150}
                keepMounted={false}
            >
                <div className={classes.menuHeader}>
                    <h4>Subscriber Options</h4>
                </div>
                
                <MenuItem 
                    onClick={() => {
                        setIsSubscriptionManagerModalOpen(true)
                        handleMenuClose()
                    }}
                    className={classes.menuItem}
                >
                    <div className={classes.menuItemContent}>
                        <div className={classes.menuItemIcon}>
                            <ManageAccountsOutlined 
                                sx={{ 
                                    fontSize: 18, 
                                    color: 'var(--primary-color)'
                                }} 
                            />
                        </div>
                        <div className={classes.menuItemText}>
                            <p>Manage subscribers</p>
                            <p>Add or remove task subscribers</p>
                        </div>
                    </div>
                </MenuItem>
            </Menu>
            <Modal
                id="subscription-manager"
                open={isSubscriptionManagerModalOpen}
                onClose={() => { setIsSubscriptionManagerModalOpen(false) }}
                sx={{
                    backdropFilter: 'blur(5px)',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                }}
            >
                <ManageSubscribersModal onClose={() => { setIsSubscriptionManagerModalOpen(false) }} />
            </Modal>
        </div>
    )
}