import React from 'react';
import BotMessageEditor from '@common-components/bt-chatbot/bot-components/bot-message-editor';
import RowStack from '@common-components/common/row-stack/RowStack';
import { WhatsappIdCell } from '@common-components/tasks/columns-cells/whatsapp-id-cell/whatsapp-id-cell';
import { SearchBox } from '@common-components/tasks/person-picker/search-box';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import WaContactLazyLoadingVirtuoso from '@extension/context/content/components/add-scheduled-message-button/wa-contact-lazy-loading-virtuoso';
import { getChatsAndContactsThunk } from '@extension/context/content/thunks/WhatsappThunks';
import { Cancel } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import LLMNodeFooter from '@common-components/bt-chatbot/bt-nodes/LLM/llm-node-footer';

interface MessageNodeData {
    type: string;
    contactId?: string;
    text?: string;
    asset?: any;
}

interface Contact {
    id: {
        _serialized: string;
    };
}

interface Props {
    id: string;
}

function SendMessageNodeContainer({ id }: Props) {
    const dispatch = useAppDispatch();

    const [searchTerm, setSearchTerm] = useState('');
    const [isContactPickerOpen, setIsContactPickerOpen] = useState(false);

    const data = useAppSelector(state => getNodeDataByNodeId(state, id)) as MessageNodeData;

    const allowToAdd = /^[0-9@+]+$/.test(searchTerm);

    const onChange = async (value: { message: string; asset: any }) => {
        await dispatch(changeNodeDataThunk({
            id,
            dataToPatch: { text: value.message, asset: value.asset }
        }));
    };

    const setPhoneNumberAsContact = (phoneNumber: string) => {
        if (parseInt(phoneNumber)) {
            const contact: Contact = { id: { _serialized: `${phoneNumber}@c.us` } };
            setNewContactValue(contact);
        }
    };

    const setNewContactValue = async (contact: Contact | null) => {
        setIsContactPickerOpen(false);
        await dispatch(changeNodeDataThunk({
            id,
            dataToPatch: { contactId: contact?.id?._serialized || '' }
        }));
        setSearchTerm('');
    };

    useEffect(() => {
        try {
            dispatch(getChatsAndContactsThunk({ shouldForceUpdate: true }))
        } catch (e) {
            console.error("[getChatsAndContactsThunk] error", e)
        }
    }, [dispatch]);

    return (
        <>
            {data?.type === 'messageContact' && (
                <Stack sx={{
                    width: '100%',
                    marginBottom: '10px',
                    justifyContent: 'center',
                }}>
                    <RowStack sx={{ height: '40px', }}>
                        <WhatsappIdCell
                            cellValue={data?.contactId}
                            taskId={id.substring(0, 24)}
                            columnId={id.substring(0, 24)}
                            openPicker={() => setIsContactPickerOpen(true)}
                            onUpdate={() => { }}
                            togglePicker={() => { }}
                            closePicker={() => { }}
                        />
                        {data?.contactId && data?.contactId !== '' && (
                            <IconButton onClick={() => setNewContactValue(null)}>
                                <Cancel />
                            </IconButton>
                        )}
                    </RowStack>
                    {isContactPickerOpen && (
                        <>
                            <Stack direction="row" justifyContent={'center'}>
                                <SearchBox
                                    variant="outlined"
                                    autoComplete='off'
                                    value={searchTerm}
                                    onChange={(e) => {
                                        if (!e.target.value?.includes('+')) {
                                            setSearchTerm(e.target.value);
                                        }
                                    }}
                                    placeholder="Enter name or number"
                                    InputProps={{
                                        endAdornment: allowToAdd ? (
                                            <IconButton onClick={() => setPhoneNumberAsContact(searchTerm)}>
                                                <AddIcon sx={{ fontSize: 16 }} />
                                            </IconButton>
                                        ) : <SearchIcon sx={{ fontSize: 16 }} />,
                                    }}
                                    fullWidth
                                />
                            </Stack>
                            {searchTerm !== '' && (
                                <div
                                    className="nowheel"
                                    style={{
                                        zIndex: 1000,
                                        backgroundColor: 'var(--default-background)',
                                    }}
                                >
                                    <WaContactLazyLoadingVirtuoso
                                        onClick={setNewContactValue}
                                        height={175}
                                        searchFilter={searchTerm}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Stack>
            )}
            <BotMessageEditor
                type={data?.type}
                _message={{ message: data?.text, asset: data?.asset }}
                onChange={onChange}
            />
            {data?.type === 'LLM' && 
                <LLMNodeFooter 
                    id={id}
                />
            }
        </>
    );
}

export default React.memo(SendMessageNodeContainer);