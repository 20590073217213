import { addNodeThunk } from "@common-reducers/ChatbotThunks";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { setCurrentBot } from "@common/reducers/ChatbotReducer";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Node, useReactFlow } from "reactflow";
import NodeButton from "./bot-components/node-button";
import classes from "./bt-chatbot.module.scss";
import { nodesInDev, NodeTypeDef, nodeTypes } from "./bt-nodes/bt-nodes-def";


export default function ChatbotToolBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLocked = useAppSelector(state => {
    try {
      return state.ChatbotReducer?.isLocked ?? false;
    } catch (error) {
      return false;
    }
  });

  const reactFlowInstance = useReactFlow();
  const nodeTypesArray = Object.values(nodeTypes);


  const clickAddNode = useCallback((type: string) => {
    if (isLocked) {
      return;
    }

    const addNodeWrapper = (node: Partial<Node>) => {
      reactFlowInstance.addNodes(node as Node);
    };

    // Get the current viewport directly from the reactFlowInstance
    const viewport = reactFlowInstance.getViewport();

    dispatch(addNodeThunk({
      type,
      addNodes: addNodeWrapper,
      fromToolbar: true,
      viewport: viewport // Pass the current viewport
    }));
  }, [isLocked, dispatch, reactFlowInstance]);

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string, element: HTMLElement) => {
    if (isLocked) {
      event.preventDefault();
      return;
    }

    // Set the node type as drag data
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';

    // Create a clone of the actual button element
    const originalRect = element.getBoundingClientRect();
    const clone = element.cloneNode(true) as HTMLElement;

    // Style the clone to make it semi-transparent but preserve the original appearance
    Object.assign(clone.style, {
      position: 'fixed',
      top: '-9999px',
      left: '-9999px',
      width: `${originalRect.width}px`,
      height: `${originalRect.height}px`,
      opacity: '0.8',
      pointerEvents: 'none',
      userSelect: 'none',
      transform: 'translate(-50%, -50%)',
    });

    // Add the clone to the document body
    document.body.appendChild(clone);

    // Set the drag image and offset
    event.dataTransfer.setDragImage(clone, originalRect.width / 2, originalRect.height / 2);

    // Clean up the clone after dragging ends
    const cleanup = () => {
      if (clone && document.body.contains(clone)) {
        document.body.removeChild(clone);
      }
      
      window.removeEventListener('dragend', cleanup);
    };

    window.addEventListener('dragend', cleanup);
  };

  // useEffect(() => {
  //   if (chatbotsLoaded && !onMountCheckExecuted.current) {
  //     onMountCheckExecuted.current = true;
  //     dispatch(setIsReadyToLoadFlow(true));
  //   }
  // }, [chatbotsLoaded, dispatch]);

  const handleBackToList = () => {
    dispatch(setCurrentBot(null));
    navigate('/bot-list-view');
  };

  return (
    <Stack className={classes.toolbar_wrapper}>
      <div className={classes.toolbar_scrollable_content}>
        {Object.values(nodeTypes)
          .filter(node => node.isMainNode)
          .map((node: NodeTypeDef, index) => {
            return (
              <div
                style={{
                  width: "100%",
                  cursor: isLocked ? 'not-allowed' : 'pointer',
                }}
                onClick={() => clickAddNode(node.type)}
                draggable={!isLocked}
                onDragStart={(event) => onDragStart(event, node.type, event.currentTarget)}
                key={index}
              >
                <NodeButton
                  bgColor={node.color}
                  title={node.title}
                  secondary_title={node.secondary_title}
                  NodeIcon={node.nodeIcon}
                />
              </div>
            );
          })}

        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {nodeTypesArray
            .filter(node => !node.isMainNode && !nodesInDev.includes(node.type))
            .map((node: NodeTypeDef, index) => {
              const isLastOddButton = (index === nodeTypesArray.length - 1) && (nodeTypesArray.length % 2 !== 0);

              return (
                <div
                  key={index}
                  onClick={() => clickAddNode(node.type)}
                  draggable={!isLocked}
                  onDragStart={(event) => onDragStart(event, node.type, event.currentTarget)}
                  style={{
                    flex: '0 0 50%',
                    boxSizing: 'border-box',
                    padding: '10px',
                    textAlign: isLastOddButton ? 'left' : 'center',
                    cursor: isLocked ? 'not-allowed' : 'pointer',
                  }}
                >
                  <NodeButton
                    bgColor={node.color}
                    title={node.title}
                    secondary_title={node.secondary_title}
                    NodeIcon={node.nodeIcon}
                    isSecondaryNode={true}
                    svgPath={node.svgPath}
                  />
                </div>
              );
            })}
        </div>
      </div>

      <Button
        variant="text"
        startIcon={<ArrowBack />}
        onClick={handleBackToList}
        sx={{
          mt: 'auto',
          mb: 2,
          color: 'grey.700',
          '&:hover': {
            bgcolor: 'grey.100'
          }
        }}
      >
        Back to List
      </Button>
    </Stack>
  );
}