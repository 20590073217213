import { CircularProgress } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { useReactFlow } from "reactflow";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { setIsReadyToLoadFlow, setLoaderProgressMessage } from "@common/reducers/ChatbotReducer";
import { saveBotThunk } from "@common-reducers/ChatbotThunks";
import BTbotbutton from "./bt-bot-button";

interface SaveButtonProps {
    disabled?: boolean;
    onBeforeSave?: () => boolean | Promise<boolean>;
    onAfterSave?: () => void;
    localCurrentBot?: any; // Type properly based on your bot type
    shouldClose?: boolean;
    sx?: any;
    label?: string;
}

export const SaveButton = ({
    disabled = false,
    onBeforeSave,
    onAfterSave,
    localCurrentBot,
    shouldClose = false,
    sx = {},
    label = 'Save Draft'
}: SaveButtonProps) => {
    const dispatch = useAppDispatch();
    const reactFlowInstance = useReactFlow();
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = useCallback(async () => {
        try {
            setIsLoading(true);
            
            if (onBeforeSave) {
                const canProceed = await onBeforeSave();
                if (!canProceed) {
                    setIsLoading(false);
                    return false;
                }
            }
            
            const viewport = reactFlowInstance.getViewport();
            dispatch(setIsReadyToLoadFlow(false));
            dispatch(setLoaderProgressMessage('Saving bot draft...'));
            
            const result = await dispatch(saveBotThunk({
                viewport,
                shouldDeploy: false,
                localCurrentBot
            }));
            
            if (result.type.endsWith('/rejected')) {
                console.error('Save failed:', result.payload);
                dispatch(setLoaderProgressMessage(`Error: ${result.payload || 'Failed to save bot'}`));
                setTimeout(() => dispatch(setLoaderProgressMessage(null)), 2000);
                return false;
            }
            
            if (shouldClose && onAfterSave) {
                onAfterSave();
            }
            
            return true;
        } catch (error) {
            console.error('Save operation failed:', error);
            dispatch(setLoaderProgressMessage(`Error: ${error.message || 'Failed to save bot'}`));
            setTimeout(() => dispatch(setLoaderProgressMessage(null)), 2000);
            return false;
        } finally {
            dispatch(setIsReadyToLoadFlow(true));
            setIsLoading(false);
        }
    }, [dispatch, reactFlowInstance, localCurrentBot, onBeforeSave, onAfterSave, shouldClose]);

    return (
        <BTbotbutton
            onClick={handleSave}
            disabled={disabled || isLoading}
            startIcon={isLoading ? null : <SaveIcon />}
            sx={{
                minWidth: '120px',
                position: 'relative',
                '& .MuiCircularProgress-root': {
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                },
                ...sx
            }}
        >
            {isLoading ? (
                <CircularProgress size={20} sx={{ color: 'inherit' }} />
            ) : (
                label
            )}
        </BTbotbutton>
    );
}; 