import React, { useState, useCallback } from 'react';
import { useQueryKnowledgeBaseMutation } from '@common-reducers/backend-api/backend-api';
import { KnowledgeBaseDocument } from '@common/models/knowledge-base-document';

// Shadcn UI Components
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { Textarea } from '@/components/ui/textarea';
import { toast } from 'sonner';

interface TestQueryDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  document: KnowledgeBaseDocument;
}

export const TestQueryDialog: React.FC<TestQueryDialogProps> = ({
  open,
  onOpenChange,
  document
}) => {
  // State
  const [queryText, setQueryText] = useState('');
  const [queryResult, setQueryResult] = useState('');
  const [isQuerying, setIsQuerying] = useState(false);
  
  // API hooks
  const [queryKnowledgeBase, { isLoading: isQueryLoading }] = useQueryKnowledgeBaseMutation();

  const handleRunQuery = useCallback(async () => {
    if (!document || !queryText.trim()) return;
    
    setIsQuerying(true);
    setQueryResult('');
    try {
      const response = await queryKnowledgeBase({
        knowledgeBaseIds: [document.knowledgeBaseId],
        documentIds: [document._id],
        query: queryText.trim(),
        limit: 20,
        similarityThreshold: 0.3
      }).unwrap();
      
      // Store the raw response for rendering in the UI
      setQueryResult(JSON.stringify(response, null, 2));
    } catch (error: any) {
      console.error('Failed to run query:', error);
      toast.error("Query failed", {
        description: error.message || 'Failed to process query. Please try again.',
      });
      setQueryResult('');
    } finally {
      setIsQuerying(false);
    }
  }, [document, queryText, queryKnowledgeBase]);

  return (
    <Dialog 
      open={open} 
      onOpenChange={onOpenChange}
    >
      <DialogContent 
        className="max-w-5xl w-full centered-dialog"
      >
        <DialogHeader>
          <DialogTitle>Test Query</DialogTitle>
          <DialogDescription>
            Test a query against the document: {document?.name}
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 my-4">
          <div>
            <label htmlFor="query-input" className="text-sm font-medium text-gray-700 mb-1 block">
              Enter your query
            </label>
            <Textarea
              id="query-input"
              placeholder="Ask a question about the document..."
              value={queryText}
              onChange={(e) => setQueryText(e.target.value)}
              className="min-h-[80px]"
              disabled={isQuerying || isQueryLoading}
            />
          </div>
          
          {(isQuerying || isQueryLoading) && (
            <div className="py-2">
              <Progress value={undefined} className="h-2" />
              <p className="text-center text-sm text-gray-600 mt-2">
                Processing query...
              </p>
            </div>
          )}
          
          {queryResult && (
            <div>
              <label className="text-sm font-medium text-gray-700 mb-1 block">
                Response
              </label>
              <div className="space-y-4 max-h-[400px] overflow-y-auto pr-2">
                {(() => {
                  try {
                    const data = JSON.parse(queryResult);
                    if (data.results && data.results.length > 0) {
                      return (
                        <>
                          <div className="bg-blue-50 p-3 rounded-md text-sm">
                            <p className="font-medium text-blue-700">Query Information</p>
                            <div className="grid grid-cols-2 gap-2 mt-1">
                              <div>
                                <span className="text-blue-600">Total Results:</span> {data.debug.totalResults}
                              </div>
                              <div>
                                <span className="text-blue-600">Similarity Threshold:</span> {data.debug.threshold * 100}%
                              </div>
                            </div>
                          </div>
                          
                          {data.results.map((result: any, index: number) => (
                            <div key={index} className="border border-gray-200 rounded-md overflow-hidden">
                              <div className="bg-gray-50 p-3 flex justify-between items-center border-b border-gray-200">
                                <div className="font-medium">Result #{index + 1}</div>
                                <div className="text-sm">
                                  <span className="font-medium">Similarity:</span> 
                                  <span className={`ml-1 ${result.score > 70 ? 'text-emerald-600' : result.score > 50 ? 'text-amber-600' : 'text-red-600'}`}>
                                    {(result.score * 100)?.toFixed(2)}%
                                  </span>
                                </div>
                              </div>
                              <div className="p-3">
                                <div className="text-xs text-gray-500 mb-2">
                                  <span className="font-medium">Source:</span> {result.metadata.documentName} 
                                  {result.metadata.chunkIndex !== undefined && (
                                    <span className="ml-1">(Chunk {result.metadata.chunkIndex})</span>
                                  )}
                                </div>
                                <div className="bg-white rounded border border-gray-100">
                                  <div className="font-mono text-xs p-2 overflow-x-auto" style={{ 
                                    maxWidth: '100%', 
                                    wordBreak: 'break-word',
                                    whiteSpace: 'pre-wrap'
                                  }}>
                                    {(() => {
                                      // Check if content looks like financial data with numbers and percentages
                                      const isFinancialData = /(\d+,\d+|\d+\.\d+|\d+%|Total|Revenue|Growth|Profit|Income|Expense)/i.test(result.content);
                                      
                                      if (isFinancialData) {
                                        // Split by newlines and format each line
                                        return result.content.split('\n').map((line: string, i: number) => (
                                          <div key={i} className="py-1 border-b border-gray-50 last:border-0">
                                            {line}
                                          </div>
                                        ));
                                      }
                                      
                                      // Regular content
                                      return result.content;
                                    })()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      );
                    } else {
                      return (
                        <div className="bg-gray-50 rounded-md p-4 text-center text-gray-600">
                          No relevant information found in the document for this query.
                        </div>
                      );
                    }
                  } catch (e) {
                    return (
                      <div className="bg-gray-50 rounded-md p-3 text-sm text-gray-800 whitespace-pre-wrap">
                        {queryResult}
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          )}
        </div>
        <DialogFooter className="mt-4">
          <Button 
            variant="outline" 
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleRunQuery}
            disabled={!queryText.trim() || isQuerying || isQueryLoading}
            className="bg-emerald-600 hover:bg-emerald-700 text-white"
          >
            {isQuerying || isQueryLoading ? 'Processing...' : 'Run Query'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TestQueryDialog; 