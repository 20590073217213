import { TriggerData, TriggerScope, TriggerType } from "../types/trigger.types";

const isValidTriggerType = (type: any): type is TriggerType => {
    return type === 'allowList' || type === 'blockList';
};

const isValidContactId = (contact: any): contact is string => {
    return typeof contact === 'string' && contact.trim().length > 0;
};

const validateTriggerData = (data: any): data is TriggerData => {
    if (!data || typeof data !== 'object') return false;
    if (data.type !== 'trigger') return false;
    if (!isValidTriggerType(data.triggerType)) return false;
    
    // For blockList, treat null/undefined contacts as empty array
    if (data.triggerType === 'blockList' && !data.contacts) {
        data.contacts = [];
        return true;
    }
    
    if (!Array.isArray(data.contacts)) return false;
    
    // Filter out invalid contacts
    const validContacts = data.contacts.filter(isValidContactId);
    
    // For allowList, if all contacts are invalid, treat as empty allowList
    if (data.triggerType === 'allowList' && validContacts.length === 0) {
        return false;
    }
    
    // Update contacts array with only valid contacts
    data.contacts = validContacts;
    return true;
};

const deduplicateContacts = (contacts: string[]): string[] => {
    return [...new Set(contacts)];
};

export const analyzeBotTriggerScope = (flowJSON?: string): TriggerScope => {
    try {
        const flowData = JSON.parse(flowJSON || '{}');
        const triggerNodes = flowData.nodes?.filter((node: any) => 
            node.type === 'trigger' && node.data
        ) ?? [];

        if (!triggerNodes.length) {
            return { type: 'NO_MESSAGES' };
        }

        // For each trigger node, analyze its scope
        const nodeScopes = triggerNodes.map(node => {
            const data = node.data;
            
            // Validate trigger data
            if (!validateTriggerData(data)) {
                return { type: 'NO_MESSAGES' as const };
            }

            // Deduplicate contacts
            data.contacts = deduplicateContacts(data.contacts);

            // If it's a block list with no contacts - ALL messages
            if (data.triggerType === 'blockList' && data.contacts.length === 0) {
                return { type: 'ALL_MESSAGES' as const };
            }

            // If it's an allow list with no contacts - NO messages
            if (data.triggerType === 'allowList' && data.contacts.length === 0) {
                return { type: 'NO_MESSAGES' as const };
            }

            return { 
                type: 'SOME_MESSAGES' as const,
                triggerData: data
            };
        });

        // If any node can receive all messages, the bot can receive all messages
        if (nodeScopes.some(scope => scope.type === 'ALL_MESSAGES')) {
            return { type: 'ALL_MESSAGES' };
        }

        // If all nodes are NO_MESSAGES, the bot receives no messages
        if (nodeScopes.every(scope => scope.type === 'NO_MESSAGES')) {
            return { type: 'NO_MESSAGES' };
        }

        // Otherwise return SOME_MESSAGES with the data
        const someMessagesScope = nodeScopes.find(scope => scope.type === 'SOME_MESSAGES')!;
        return {
            type: 'SOME_MESSAGES',
            triggerData: someMessagesScope.triggerData
        };
    } catch (error) {
        console.error('Error analyzing bot trigger scope:', error);
        return { type: 'NO_MESSAGES' };
    }
};

export const canBotsOverlap = (scope1: TriggerScope, scope2: TriggerScope): boolean => {
    // If either bot can't receive messages, no overlap
    if (scope1.type === 'NO_MESSAGES' || scope2.type === 'NO_MESSAGES') {
        return false;
    }
    
    // If either bot can receive all messages, they overlap
    if (scope1.type === 'ALL_MESSAGES' || scope2.type === 'ALL_MESSAGES') {
        return true;
    }

    // At this point, both are SOME_MESSAGES
    const data1 = scope1.triggerData!;
    const data2 = scope2.triggerData!;

    // Handle null/undefined contacts
    if (!data1.contacts || !data2.contacts) {
        return false;
    }

    // Both are allow lists - check for common contacts
    if (data1.triggerType === 'allowList' && data2.triggerType === 'allowList') {
        return data1.contacts.some(contact => data2.contacts.includes(contact));
    }

    // Both are block lists - they overlap if there's at least one contact not in both lists
    if (data1.triggerType === 'blockList' && data2.triggerType === 'blockList') {
        const allBlockedContacts = new Set([...data1.contacts, ...data2.contacts]);
        return allBlockedContacts.size < data1.contacts.length + data2.contacts.length;
    }

    // One is allow list, one is block list
    const allowList = data1.triggerType === 'allowList' ? data1 : data2;
    const blockList = data1.triggerType === 'blockList' ? data1 : data2;
    
    // For there to be an overlap:
    // 1. There must be at least one contact in the allowList
    // 2. That contact must NOT be in the blockList
    // This means if ALL contacts in the allowList are blocked, there's no overlap
    return allowList.contacts.some(contact => !blockList.contacts.includes(contact));
}; 