import { AccountPage } from "@common-components/account-page/account-page";
import BTButton from "@common-components/bt-button/bt-button";
import BTThemeProvider from "@common-components/bt-theme-provider";
import { Checkbox, Switch } from "@mui/material";
import { selectUserObject } from "@common-reducers/UserSelectors";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SideBarItem } from "@common-components/sidebar/sidebar";
import { User } from "@common/models/user";
import classes from "../account-view/account-view.module.scss";

export interface AccountNotificationsViewProps { }

export interface AccountNotificationsViewState {
  user?: User | null | undefined;
  loading: boolean;
}

const AccountNotificationsView = () => {
  const user = useSelector(selectUserObject);
  const [desktopAllow, setDesktopAllow] = useState<boolean>();

  const accountNotificationsView = () => {
    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.pageTitle}>Notification</h4>
        </div>
        <div className={classes.accountSettingBody}>
          <div className={classes.notificationTableContainer}>
            <div
              className={
                classes.notificationTableRow +
                " " +
                classes.notificationTableRowHeader
              }
            >
              <h6>Notifications</h6>
              <span style={{ opacity: desktopAllow ? 1 : 0.5 }}>Desktop</span>
              <span>Email</span>
              <span>WhatsApp</span>
            </div>
            <div
              className={
                classes.notificationTableRow +
                " " +
                classes.notificationTableRowNoti
              }
            >
              <h6>When someone adds me to a new board</h6>
              <Checkbox disabled={desktopAllow ? false : true} defaultChecked />
              <Checkbox defaultChecked />
              <Checkbox defaultChecked />
            </div>
            <div
              className={
                classes.notificationTableRow +
                " " +
                classes.notificationTableRowNoti
              }
            >
              <h6>When someone adds me as owner to item</h6>
              <Checkbox disabled={desktopAllow ? false : true} defaultChecked />
              <Checkbox defaultChecked />
              <Checkbox defaultChecked />
            </div>
            <div
              className={
                classes.notificationTableRow +
                " " +
                classes.notificationTableRowNoti
              }
            >
              <h6>On chat replies of items I own</h6>
              <div className={classes.emptyNoDesk}></div>

              <Checkbox defaultChecked />
              <Checkbox defaultChecked />
            </div>
            <div
              className={
                classes.notificationTableRow +
                " " +
                classes.notificationTableRowNoti
              }
            >
              <h6>On chat replies of items in boards I'm a member of</h6>
              <div className={classes.emptyNoDesk}></div>
              <Checkbox defaultChecked />
              <Checkbox defaultChecked />
            </div>
            <div className={classes.DesktopAllowContainer}>
              <Switch
                onChange={(e) => {
                  if (e.target.checked) {
                    setDesktopAllow(true);
                  } else {
                    setDesktopAllow(false);
                  }
                }}
              />
              <h6>Desktop notifications</h6>
            </div>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <BTButton
              // onClick={saveUserFields}

              >
                Save changes
              </BTButton>

            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <BTThemeProvider>
      <AccountPage
        page={accountNotificationsView()}
      ></AccountPage>
    </BTThemeProvider>
  );
};

export default AccountNotificationsView;
function UseState(): [any, any] {
  throw new Error("Function not implemented.");
}
