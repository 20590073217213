import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { getBotDeploymentIdsByBotIdSelector, getFilteredSessionIdsByBotDeploymentIdSelector, SessionFilter } from '@common/reducers/ChatbotSelectors';
import { useParams } from 'react-router-dom';
import { SessionFilters } from './SessionFilters';
import { SessionRow } from './SessionRow';

interface SessionsListProps {
    filter: SessionFilter;
    onSessionFilterChange: (filter: SessionFilter) => void;
    selectedDeploymentId: string | null;
    onDeploymentSelect: (deploymentId: string | null) => void;
}

const EmptyState = ({ message }: { message: string }) => (
    <div className="flex flex-col items-center justify-center h-full w-full flex-1 text-center p-8">
        <h3 className="text-base font-semibold mb-2">
            No Sessions Found
        </h3>
        <p className="text-sm text-gray-500">
            {message}
        </p>
    </div>
);

export const SessionsList = ({ 
    filter, 
    onSessionFilterChange, 
    selectedDeploymentId, 
    onDeploymentSelect,
}: SessionsListProps) => {
    const { botId } = useParams<{ botId: string }>();
    const isLoading = useAppSelector(state => state.DBAutomationSessionReducer.itemsLoading);
    const deploymentIds = useAppSelector(state => 
        botId ? getBotDeploymentIdsByBotIdSelector(state, botId) : []
    );


    // Get sessions based on filter and selected deployment
    const sessionIds = useAppSelector(state => {
        if (!selectedDeploymentId) {
            // If no deployment is selected (All Versions), get sessions for all deployments
            const allSessions = deploymentIds.flatMap(deploymentId => 
                getFilteredSessionIdsByBotDeploymentIdSelector(state, deploymentId, filter)
            );
            return allSessions;
        }
        // Otherwise get sessions for the selected deployment
        const filteredSessions = getFilteredSessionIdsByBotDeploymentIdSelector(state, selectedDeploymentId, filter);
        console.log('SessionsList - selected deployment sessions:', filteredSessions);
        return filteredSessions;
    });
    
    console.log('SessionsList - final sessionIds:', sessionIds);

    // Render each session directly instead of using Virtuoso for debugging
    const renderSessions = () => {
        if (sessionIds.length === 0) return null;
        return sessionIds.map(sessionId => (
            <SessionRow key={sessionId} sessionId={sessionId} />
        ));
    };

    return (
        <div className="flex flex-col h-full">
            <div className="px-4 pb-3 gap-4 border-b border-gray-200 flex flex-row flex-start items-center">
                <h5 className="text-base font-semibold">
                    Sessions
                </h5>
                <div>
                    <SessionFilters 
                        sessionFilter={filter} 
                        onSessionFilterChange={onSessionFilterChange}
                        selectedDeploymentId={selectedDeploymentId}
                        onDeploymentSelect={onDeploymentSelect}
                    />
                </div>
            </div>
            
            <div className="flex-1 overflow-auto">
                {isLoading ? (
                    <div className="p-3 border-b border-gray-200">
                        <div className="flex justify-between items-center">
                            <div className="flex-1">
                                <div className="w-2/5 h-6 bg-gray-100 rounded mb-1" />
                                <div className="w-3/5 h-5 bg-gray-100 rounded" />
                            </div>
                            <div className="flex items-center gap-3">
                                <div className="w-24 h-5 bg-gray-100 rounded" />
                                <div className="w-24 h-5 bg-gray-100 rounded" />
                                <div className="w-20 h-6 bg-gray-100 rounded" />
                            </div>
                        </div>
                    </div>
                ) : !sessionIds.length ? (
                    <EmptyState 
                        message={`There are no ${filter === 'all' ? '' : `${filter} `}sessions ${selectedDeploymentId ? 'for this version' : ''}`}
                    />
                ) : (
                    <div className="h-full overflow-auto">
                        {renderSessions()}
                    </div>
                )}
            </div>
        </div>
    );
}; 