import { Board } from "@common-models/board";
import { Campaign } from "@common-models/campaign-model";
import { TaskColumn } from "@common-models/task-column";
import { TasksGroup } from "@common-models/task-group";
import { AnalyticsService } from "@common-services/analytics-service";
import { AppThunkDispatch } from "@common/types/common-root-state-type";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { campaignReducerActions, campaignsSelectOneObjectByQuery, DBBoardsThunks, DBCampaignThunks, DBMessageLogThunks, DBTaskColumnThunks, DBTaskgroupsThunks, DBTasksThunks, DBUserMessageThunks, messageLogReducerActions, taskColumnSelectByQuery, userMessageReducerActions, userMessagesSelectByQuery } from "@common-reducers/DBServiceThunks";
import { CommonRootState } from "@common/types/common-root-state-type";
import moment from "moment";
import randomColor from 'randomcolor';
import { duplicateTemplateForCampaigns, removeAllPendingUserMessagesByCampaignId } from "./backend-api/backend-api";
import { boardsActions } from "./BoardsReducer";
import { getCurrentBoard } from "./BoardsSelectors";
import { bulkAudienceSelectBoardThunk } from "./BoardsThunks";
import { BulkDashboardTab, defaultNewCampaign, setActiveBulkView, setActiveTab, setCampaignIdForLogsView, setIsCampaignLaunching, setNewCampaign } from "./CampaignReducer";
import { getRunningCampaigns } from "./CampaignSelectors";
import { selectUser, selectUserId } from "./UserSelectors";
import { loadUserMessagesAndCampaignsThunk } from "./UserThunks";


export interface SaveNewContactsFromWhatsappForNewCampaignAudienceParams {
    displayName: string;
    waContactList: any[];
}
export interface SaveNewContactsFromWhatsappForExistingCampaignAudienceParams {
    waContactList: any[];
}
export interface SaveNewContactsFromWhatsappForExistingCampaignAudienceWithDataMappingParams {
    waContactList: any[];
    importDataMapping: Record<string, string>;
}

// Helper function to split names
const splitName = (contact: any) => {
    let firstName = '';
    let lastName = '';
    if (contact?.name) {
        const nameParts = contact?.name.split(' ');
        firstName = nameParts[0] || '';
        lastName = nameParts.slice(1).join(' ') || ''; // Support for middle and last name
    }
    if (!contact?.name && contact?.verifiedName) {
        const nameParts = contact?.verifiedName.split(' ');
        firstName = nameParts[0] || '';
        lastName = nameParts.slice(1).join(' ') || ''; // Support for middle and last name
    }
    if (contact?.firstName) {
        firstName = contact?.firstName;
    }
    if (contact?.lastName) {
        lastName = contact?.lastName;
    }
    return { firstName, lastName };
};

// Helper function for creating bulk update data
const createBulkUpdateData = (waContactList: any[], ownerId: string, boardId: string, groupId: string, contactColumn: any, firstNameColumn: any, lastNameColumn: any, importDataMapping?: { [key: string]: string }) => {
    return waContactList.map((contact, index) => {
        const { firstName, lastName } = splitName(contact);
        const isGroup = contact?.whatsappId?.includes('@g');
        const contactColumnValue = contact?.whatsappId;
        const displayName = contact?.displayName ?? contact?.name ?? `${firstName} ${lastName}`;

        let customData = {
            [contactColumn?._id]: { value: contactColumnValue },
        };

        if (importDataMapping) {
            if (importDataMapping['First name']) {
                customData[importDataMapping['First name']] = { value: isGroup ? displayName : firstName };
            }
            if (importDataMapping['Last name']) {
                customData[importDataMapping['Last name']] = { value: isGroup ? '' : lastName };
            }
        } else {
            customData[firstNameColumn?._id] = { value: isGroup ? displayName : firstName };
            customData[lastNameColumn?._id] = { value: isGroup ? '' : lastName };
        }

        return {
            owner: ownerId,
            boardId: boardId,
            groupId: groupId,
            text: displayName,
            customData: customData,
            order: index,
        };
    });
};

export const saveNewContactsFromWhatsappForNewCampaignAudienceThunk = createAsyncThunk<string, SaveNewContactsFromWhatsappForNewCampaignAudienceParams, { state: CommonRootState }>(
    "tasks/updateCellValueThunk",
    async (params, thunkAPI) => {
        // Extracted common code for setting up new contacts
        const { displayName, waContactList } = params;
        const state: CommonRootState = thunkAPI.getState();
        const ownerId = selectUserId(state);
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const user = selectUser(state);

        // Unique part for new campaign audience
        const resBoard = await dispatch(DBBoardsThunks.create({
            name: displayName,
            workspaceId: user?.currentWorkspace.toString(),
            type: 'audience',
            owner: ownerId,
        }));
        const board: Board = resBoard.payload;

        const resGroup = await dispatch(DBTaskgroupsThunks.create({
            boardId: board._id,
            name: "Audience",
            owner: ownerId,
            createdBy: ownerId,
            colorAccent: randomColor(),
            order: 0,
        }));
        const group: TasksGroup = resGroup.payload;

        const resContactColumn = await dispatch(DBTaskColumnThunks.create({
            boardId: board._id,
            type: 'whatsapp-id-cell',
            title: 'Contact',
            order: 1,
        }));
        const contactColumn = resContactColumn.payload as TaskColumn;

        const resFirstNameColumn = await dispatch(DBTaskColumnThunks.create({
            boardId: board._id,
            type: 'text-cell',
            title: 'First Name',
            order: 2,
        }));
        const firstNameColumn = resFirstNameColumn.payload as TaskColumn;

        const resLastNameColumn = await dispatch(DBTaskColumnThunks.create({
            boardId: board._id,
            type: 'text-cell',
            title: 'Last Name',
            order: 3,
        }));
        const lastNameColumn = resLastNameColumn.payload as TaskColumn;

        // Use helper function for creating bulk update data
        const bulkUpdateData = createBulkUpdateData(waContactList, ownerId, board._id, group._id, contactColumn, firstNameColumn, lastNameColumn);

        await dispatch(DBTasksThunks.createMany(bulkUpdateData));

        return board._id;
    }
);

export const saveNewContactsFromWhatsappForExistingCampaignAudienceThunk = createAsyncThunk<string, SaveNewContactsFromWhatsappForExistingCampaignAudienceParams, { state: CommonRootState }>(
    "tasks/updateCellValueThunk",
    async (params, thunkAPI) => {
        const { waContactList } = params;
        const state: CommonRootState = thunkAPI.getState();
        const ownerId = selectUserId(state);
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const board = getCurrentBoard(state);

        const resGroup = await dispatch(DBTaskgroupsThunks.create({
            boardId: board._id,
            name: "Whatsapp Audience",
            owner: ownerId,
            createdBy: ownerId,
            colorAccent: randomColor(),
            order: 0,
        }));
        const group: TasksGroup = resGroup.payload;

        const currentBoardColumns = taskColumnSelectByQuery(state.DBTaskColumnReducer, { boardId: board._id });
        const contactColumn = currentBoardColumns.find(column => column.type === 'whatsapp-id-cell');
        const firstNameColumn = currentBoardColumns.find(column => column.title.toLowerCase().includes('first'));
        const lastNameColumn = currentBoardColumns.find(column => column.title.toLowerCase().includes('last'));

        // Use helper function for creating bulk update data
        const bulkUpdateData = createBulkUpdateData(waContactList, ownerId, board._id, group._id, contactColumn, firstNameColumn, lastNameColumn);

        await dispatch(DBTasksThunks.createMany(bulkUpdateData));

        return board._id;
    }
);

export const saveNewContactsFromWhatsappForExistingCampaignAudienceWithDataMappingThunk = createAsyncThunk<string, SaveNewContactsFromWhatsappForExistingCampaignAudienceWithDataMappingParams, { state: CommonRootState }>(
    "tasks/updateCellValueThunk",
    async (params, thunkAPI) => {
        const { waContactList, importDataMapping } = params;
        const state: CommonRootState = thunkAPI.getState();
        const ownerId = selectUserId(state);
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const board = getCurrentBoard(state);

        const resGroup = await dispatch(DBTaskgroupsThunks.create({
            boardId: board._id,
            name: "Whatsapp Audience",
            owner: ownerId,
            createdBy: ownerId,
            colorAccent: randomColor(),
            order: 0,
        }));
        const group: TasksGroup = resGroup.payload;

        const currentBoardColumns = taskColumnSelectByQuery(state.DBTaskColumnReducer, { boardId: board._id });
        const contactColumn = currentBoardColumns.find(column => column.type === 'whatsapp-id-cell');

        // Use helper function for creating bulk update data with mapping
        const bulkUpdateData = createBulkUpdateData(waContactList, ownerId, board._id, group._id, contactColumn, null, null, importDataMapping);

        await dispatch(DBTasksThunks.createMany(bulkUpdateData));

        return board._id;
    }
);



export interface GetBoardEntitiesForImportModalThunkParams {
    displayName: string;
}

export const getBoardEntitiesForImportModalThunk = createAsyncThunk<
    string[],
    GetBoardEntitiesForImportModalThunkParams,
    { state: CommonRootState }
>(
    "campaigns/getBoardEntitiesForImportModal",
    async (getBoardEntitiesForImportModalThunkParams: GetBoardEntitiesForImportModalThunkParams, thunkAPI) => {
        const { displayName } = getBoardEntitiesForImportModalThunkParams;
        const state: CommonRootState = thunkAPI.getState();
        const ownerId = selectUserId(state)
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const user = selectUser(state)

        const board = await dispatch(DBBoardsThunks.create(
            {
                name: displayName,
                workspaceId: user?.currentWorkspace.toString(),
                type: 'audience',
                owner: ownerId,
            })).unwrap()


        const firstNameColumn = await dispatch(DBTaskColumnThunks.create({
            boardId: board._id,
            type: 'text-cell',
            title: 'First Name',
        })).unwrap()


        const lastNameColumn = await dispatch(DBTaskColumnThunks.create({
            boardId: board._id,
            type: 'text-cell',
            title: 'Last Name',
        })).unwrap()

        const contactColumn = await dispatch(DBTaskColumnThunks.create({
            boardId: board._id,
            type: 'whatsapp-id-cell',
            title: 'Contact',
        })).unwrap()



        return [board._id.toString() ?? '', firstNameColumn._id.toString() ?? '', lastNameColumn._id.toString() ?? '', contactColumn._id.toString() ?? '']
    }
);

export interface DuplicateTemplateForCampaignsThunk {
    oldTemplateId: string;
}

export const duplicateTemplateForCampaignsThunk = createAsyncThunk<
    any,
    DuplicateTemplateForCampaignsThunk,
    { state: CommonRootState }
>(
    "tasks/updateCellValueThunk",
    async (DuplicateTemplateForCampaignsThunk: DuplicateTemplateForCampaignsThunk, thunkAPI) => {
        const { oldTemplateId } = DuplicateTemplateForCampaignsThunk;
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        await dispatch(duplicateTemplateForCampaigns.initiate(oldTemplateId))

    }
);


export interface CreateNewColumnsForImportExcelThunkParams {
    boardId: string;
}

export const createNewColumnsForImportExcelThunk = createAsyncThunk<
    any,
    CreateNewColumnsForImportExcelThunkParams,
    { state: CommonRootState }
>(
    "tasks/updateCellValueThunk",
    async (CreateNewColumnsForImportExcelThunkParams: CreateNewColumnsForImportExcelThunkParams, thunkAPI) => {
        const { boardId } = CreateNewColumnsForImportExcelThunkParams;
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state: CommonRootState = thunkAPI.getState();
        const importDataMapping = state.ImportItemsReducer.importDataMapping
        const newColumnList = state.ImportItemsReducer.newColumnList
        /// return all the elements in newColumnList that their _id is a value in importDataMapping
        const _columnsToCreate = newColumnList.filter(column => Object.values(importDataMapping).includes(column._id))
        // map columnsToCreate to the same array just add to each element the field 'excelColumn' with the key of the importDataMapping that has the value of the column._id
        const columnsToCreate = _columnsToCreate.map(column => ({
            ...column,
            excelColumn: Object.keys(importDataMapping).find(key => importDataMapping[key] === column._id)
        }))

        if (columnsToCreate.length === 0) {
            return undefined
        } else {
            let newColumnsMapping: any = {}
            for (let newColumnToCreate of columnsToCreate) {
                const column = await dispatch(DBTaskColumnThunks.create({
                    boardId,
                    type: newColumnToCreate.type,
                    title: newColumnToCreate.title,
                })).unwrap();
                newColumnsMapping[newColumnToCreate._id] = {
                    _id: column._id,
                    excelColumn: newColumnToCreate.excelColumn
                }
            }
            return newColumnsMapping
        }

    }
);


export interface ToggleAudienceCheckboxParams {
    boardId: string;
}

export const toggleAudienceCheckboxThunk = createAsyncThunk<
    void,
    ToggleAudienceCheckboxParams,
    { state: CommonRootState }
>(
    "campaigns/toggleAudienceCheckboxThunk",
    async ({ boardId }: ToggleAudienceCheckboxParams, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state: CommonRootState = thunkAPI.getState();
        const newCampaign = state.CampaignReducer.newCampaign;

        // Determine if the boardId is already in the array
        const isBoardIdPresent = newCampaign?.boardIDArray?.includes(boardId);

        let newBoardIdArray = [];

        if (isBoardIdPresent) {
            // Remove the boardId from the array
            newBoardIdArray = newCampaign?.boardIDArray?.filter(id => id !== boardId) ?? [];
        } else {
            // Add the boardId to the array
            newBoardIdArray = [...(newCampaign?.boardIDArray ?? []), boardId];
        }

        // Dispatch the new state
        dispatch(setNewCampaign({
            ...newCampaign,
            boardIDArray: newBoardIdArray,
        }));
    }
);



export interface HandleActiveBulkViewNavigationParams {
    navigate: "toNewAudience"
    | "toCampaignBoardView"
    | "fromCampaignBoardView"
    | "toNewTemplate"
    | "fromNewAudience"
    | "fromNewTemplate";
    boardId?: string;
}

export const handleActiveBulkViewNavigationThunk = createAsyncThunk<
    void,
    HandleActiveBulkViewNavigationParams,
    { state: CommonRootState }
>(
    "campaigns/handleActiveBulkViewNavigationThunk",
    async (HandleActiveBulkViewNavigationParams: HandleActiveBulkViewNavigationParams, thunkAPI) => {
        const { navigate, boardId } = HandleActiveBulkViewNavigationParams
        const analyticsService: AnalyticsService = new AnalyticsService();

        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state: CommonRootState = thunkAPI.getState();
        const activeBulkView = state.CampaignReducer.activeBulkView

        if (navigate === "toNewAudience") {
            analyticsService.event('bulk_dashboard_tab_change', {
                category: 'bulk',
                action: 'newAudience'
            });
            if (activeBulkView === "campaignManagementView") {
                dispatch(setActiveTab('audiences'))
                dispatch(setActiveBulkView("campaignNewAudienceFromManagementView"))
            }
            else {
                dispatch(setActiveBulkView("campaignNewAudienceFromNewCampaignView"))
            }
        }

        if (navigate === "fromNewAudience") {
            if (activeBulkView === "campaignNewAudienceFromManagementView") {
                dispatch(setActiveBulkView("campaignManagementView"))
            }
            else {
                dispatch(setActiveBulkView("newCampaignView"))
            }
        }

        if (navigate === "toCampaignBoardView") {
            if (activeBulkView === "campaignManagementView") {
                dispatch(setActiveBulkView("campaignBoardFromManagementView"))
            }
            else {
                dispatch(setActiveBulkView("campaignBoardFromNewCampaignView"))
            }
            dispatch(bulkAudienceSelectBoardThunk({ boardId }))
        }

        if (navigate === "fromCampaignBoardView") {
            await dispatch(boardsActions.privateSetSelectedBoardId(undefined))
            if (activeBulkView === "campaignBoardFromManagementView") {
                dispatch(setActiveBulkView("campaignManagementView"))
            }
            else {
                dispatch(setActiveBulkView("newCampaignView"))
            }
        }

        if (navigate === "toNewTemplate") {
            if (activeBulkView === "campaignManagementView") {
                dispatch(setActiveBulkView("campaignNewTemplateViewFromManagement"))
            }
            else {
                dispatch(setActiveBulkView("campaignNewTemplateViewFromNewCampaignView"))
            }
        }
        if (navigate === "fromNewTemplate") {
            if (activeBulkView === "campaignNewTemplateViewFromManagement") {
                dispatch(setActiveBulkView("campaignManagementView"))
            }
            else {
                dispatch(setActiveTab('audiences'))
                dispatch(setActiveBulkView("newCampaignView"))
            }
        }
    }
);


export interface HandleTimeAndDateTabOnChangeEventParams {
    changeType: string;
    changeObject: any;
}

export const handleTimeAndDateTabOnChangeEventThunk = createAsyncThunk<
    void,
    HandleTimeAndDateTabOnChangeEventParams,
    { state: CommonRootState }
>(
    "campaigns/handleTimeAndDateTabOnChangeEventThunk",
    async (HandleTimeAndDateTabOnChangeEventParams: HandleTimeAndDateTabOnChangeEventParams, thunkAPI) => {
        const { changeType, changeObject } = HandleTimeAndDateTabOnChangeEventParams
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state: CommonRootState = thunkAPI.getState();
        const newCampaign = state.CampaignReducer.newCampaign



        if (changeType === 'setScheduleDueDate') {
            dispatch(setNewCampaign({
                ...newCampaign,
                schedule: {
                    ...newCampaign?.schedule,
                    dueDate: changeObject,
                }
            }));
        }
        if (changeType === 'setScheduleDueDateToNow') {
            dispatch(setNewCampaign({
                ...newCampaign,
                schedule: {
                    ...newCampaign?.schedule,
                    dueDate: changeObject,
                    isNow: true,
                }
            }));
        }
        if (changeType === 'setScheduleDueDateToNotNow') {
            dispatch(setNewCampaign({
                ...newCampaign,
                schedule: {
                    ...newCampaign?.schedule,
                    ...changeObject,
                }
            }));
        }
        if (changeType === 'setNewCampaignTiming') {
            dispatch(setNewCampaign({
                ...newCampaign,
                timing: {
                    ...newCampaign.timing,
                    ...changeObject,
                }
            }));
        }
        if (changeType === 'setNewCampaignTimingAutomaticTiming') {
            dispatch(setNewCampaign({
                ...newCampaign,
                timing: {
                    ...newCampaign.timing,
                    automaticTiming: {
                        ...newCampaign.timing?.automaticTiming,
                        ...changeObject,
                    }
                }
            }))
        }
        if (changeType === 'setNewCampaignTimingSendInterval') {
            dispatch(setNewCampaign({
                ...newCampaign,
                timing: {
                    ...newCampaign.timing,
                    sendInterval: {
                        ...newCampaign.timing?.sendInterval,
                        ...changeObject,
                    }
                }
            }));
        }
    }
);

export const launchCampaignThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("campaigns/launchCampaignThunk",
    async (voidParams, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state: CommonRootState = thunkAPI.getState();

        const newCampaign = state.CampaignReducer.newCampaign;

        dispatch(setIsCampaignLaunching(true))

        const analyticsService = new AnalyticsService();
        analyticsService.event("first_action", {
            category: "firstAction",
            action: "first action",
        });

        analyticsService.event("bulk_campaign_launched", {
            category: "bulk",
            action: "bulk_campaign_launched",
        });


        const launchedCampaign: Campaign = {
            ...newCampaign,
            template: {
                ...newCampaign?.template,
                message: newCampaign?.template?.asset?.type?.includes('audio') ? '' : newCampaign?.template?.message ?? "",
            },
            campaignStatus: 'pending',
            schedule: {
                ...newCampaign.schedule,
                dueDate: newCampaign.schedule?.isNow ? moment.utc().toISOString() : newCampaign.schedule?.dueDate
            },
            timing: {
                ...newCampaign.timing,
                startTime: {
                    hour: moment().set({ hour: newCampaign?.timing?.startTime?.hour ?? 0 }).utc().hour(),
                    minute: moment().set({ minute: newCampaign?.timing?.startTime?.minute ?? 0 }).utc().minute(),
                },
                endTime: {
                    hour: moment().set({ hour: newCampaign?.timing?.endTime?.hour ?? 23 }).utc().hour(),
                    minute: moment().set({ minute: newCampaign?.timing?.endTime?.minute ?? 59 }).utc().minute(),
                }
            }
        }


        dispatch(setNewCampaign(defaultNewCampaign));

        const res = await dispatch(DBCampaignThunks.create(launchedCampaign));


        dispatch(setActiveTab("manageCampaigns" as BulkDashboardTab))
        dispatch(setCampaignIdForLogsView((res.payload as Campaign)._id))
        dispatch(setActiveBulkView("campaignManagementView"))

        dispatch(setIsCampaignLaunching(false))
    }
);


export interface SendAllPendingCampaignMessagesThunkParams {
    campaignId: string;
}

export const sendAllPendingCampaignMessagesThunk = createAsyncThunk<
    void,
    SendAllPendingCampaignMessagesThunkParams,
    { state: CommonRootState }
>(
    "campaigns/SendAllPendingCampaignMessagesThunkThunk",
    async (SendAllPendingCampaignMessagesThunkParams: SendAllPendingCampaignMessagesThunkParams, thunkAPI) => {
        const { campaignId } = SendAllPendingCampaignMessagesThunkParams
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state: CommonRootState = thunkAPI.getState();

        const pendingMessages = userMessagesSelectByQuery(state.DBUserMessageReducer, {
            campaignId,
            status: 'pending'
        })

        const nowIsoString = moment().toISOString();

        const changes = pendingMessages.map((message, index) => {
            return {
                id: message._id,
                changes: {
                    dueDate: nowIsoString,
                    dueTime: nowIsoString
                }
            }
        })
        dispatch(DBUserMessageThunks.patchMany(changes))

    }
)











export interface ClearAllPendingCampaignMessagesThunk {
    campaignId: string;
}

export const clearAllPendingCampaignMessagesThunk = createAsyncThunk<
    void,
    ClearAllPendingCampaignMessagesThunk,
    { state: CommonRootState }
>(
    "campaigns/SendAllPendingCampaignMessagesThunkThunk",
    async (ClearAllPendingCampaignMessagesThunk: ClearAllPendingCampaignMessagesThunk, thunkAPI) => {
        const { campaignId } = ClearAllPendingCampaignMessagesThunk
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;

        dispatch(DBCampaignThunks.patch({
            entity: {
                _id: campaignId,
                campaignStatus: 'aborted',
            }
        }));

        await dispatch(removeAllPendingUserMessagesByCampaignId.initiate(campaignId))

        dispatch(userMessageReducerActions.clearState())
        dispatch(campaignReducerActions.clearState())
        dispatch(cleanMessageLogsFromStateExceptRunningCampaignsThunk())
        dispatch(loadUserMessagesAndCampaignsThunk())

    })


export const getLastUpdatedUserCampaigns = createAsyncThunk(
    "getLastUpdatedUserCampaigns",
    async (_, { dispatch }) => {

        const campaigns = dispatch(
            DBCampaignThunks.find({
                deleted: { $ne: true },
                $sort: {
                    updatedAt: -1,
                },
                $limit: 20,
            })
        );

        return campaigns;
    }
);





export const getCampaignMessagesAndLogs = createAsyncThunk<
    { campaign: any; campaignLogs: any; campaignMessages: any },
    string
>(
    "campaigns/getCampaignMessagesAndLogs",
    async (campaignId, { dispatch }) => {

        // get campaignthunks.find one by campaignId
        const campaign = await dispatch(DBCampaignThunks.find({ _id: campaignId })).unwrap()

        const campaignLogs = await dispatch(DBMessageLogThunks.find({ campaignId, $paginate: false })).unwrap()

        const campaignMessages = await dispatch(DBUserMessageThunks.find({ campaignId, $paginate: false })).unwrap()

        return {
            campaign,
            campaignLogs,
            campaignMessages
        }

    }
);


export const getCampaignRealStatus = (orignalCampaignStatus: string, isCompletedSendSupported: boolean) => {
    if (isCompletedSendSupported && orignalCampaignStatus === 'complete-send') {
        return 'Completed';
    }

    if (!isCompletedSendSupported && (orignalCampaignStatus === 'completed' || orignalCampaignStatus === 'complete-send')) {
        return 'Completed';
    }

    if (orignalCampaignStatus === 'pending') {
        return 'Pending';
    }

    if (isCompletedSendSupported && orignalCampaignStatus === 'completed') {
        return 'Running';
    }

    if (orignalCampaignStatus === 'running') {
        return 'Running';
    }

    if (orignalCampaignStatus === 'aborted') {
        return 'Aborted';
    }

    if (orignalCampaignStatus === 'paused') {
        return 'Paused';
    }

    return null;
}



export const cleanMessageLogsFromStateExceptRunningCampaignsThunk = createAsyncThunk<
    void,
    void,
    { state: CommonRootState }
>("campaigns/cleanMessageLogsFromStateExceptRunningCampaignsThunk",
    async (_, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state = thunkAPI.getState();
        const runningCampaigns = getRunningCampaigns(state);
        const runningCampaignIds = runningCampaigns.map(campaign => campaign._id);
        const idsToRemove = Object.values(state.DBMessageLogReducer.entities)
            .filter(log => !runningCampaignIds.includes(log.campaignId))
            .map(log => log._id);

        await dispatch(messageLogReducerActions.removeMany(idsToRemove));
    }
);


export const getNumberOfContactsByCampaignIdThunk = createAsyncThunk<
    void,
    string,
    { state: CommonRootState }
>(
    "campaigns/getNumberOfContactsByCampaignIdThunk",
    async (campaignId, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppThunkDispatch;
        const state = thunkAPI.getState();

        const campaign = campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignId })

        const boardIDArray = campaign?.boardIDArray

        for (const boardId of boardIDArray) {
            await dispatch(DBBoardsThunks.find({
                _id: boardId,
                deleted: false,
                type: 'audience',
                $sort: { createdAt: -1 }
            }))
        }
    }
)