import { Subscription } from "@common-models/subscription";
import { DBReducerMap, DBSubscriptionThunks } from "@common-reducers/DBServiceThunks";
import { isExtensionContext, isExtensionContextOrWhatsappView } from "@common-services/detect-context";
import { appConfig } from "@common/config/app.config";
import { WaContact } from "@common/models/waContact";
import { CommonRootState } from "@common/types/common-root-state-type";
import { UpdateEvent } from "@common/types/interface";
import { ContentMessageServiceActions } from "@extension-context-content/services/contentMessageServiceActions";
import { getChatsAndContactsThunk } from "@extension/context/content/thunks/WhatsappThunks";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Bowser from "bowser";
import moment from "moment";
import { io, Socket } from "socket.io-client";
import { selectIsReadyToConnect } from './ConnectionReadinessSlice';
import { NotifierActions } from "./NotifierReducer";
import { setSocketId } from "./UserReducer";
import { selectUser } from "./UserSelectors";
import { logoutWrapperThunk, validateSubscriptionsThunk } from "./UserThunks";
import { updateConnectedSockets } from './ConnectedEnginesSlice';
import { prepareMessageForWa } from "@wa-communication-layer/proccess-message-app-context";
import { withTimeout } from "@wa-communication-layer/wa-error-tracing";
import { MessageRecoveryService, MissingMessagesRequest, QueuedMessage } from "@common-services/MessageRecoveryService";
import { setLoaderProgressMessage } from "./ChatbotReducer";

let notifierSocket: Socket | null = null;

// Add these variables at the module level (outside of any thunk)
// Singleton heartbeat management
let heartbeatInterval: NodeJS.Timeout | null = null;
let lastMessageTimestamp = Date.now();
let messageRecoverySingleton: MessageRecoveryService | null = null;

// Helper function to update timestamp when messages are received
const updateMessageTimestamp = () => {
  lastMessageTimestamp = Date.now();
};

// Initialize the heartbeat singleton if it hasn't been initialized yet
const initializeHeartbeatIfNeeded = (messageRecovery: MessageRecoveryService) => {
  // Store reference to message recovery service singleton
  messageRecoverySingleton = messageRecovery;
  
  // Only set up the heartbeat once
  if (heartbeatInterval === null) {
    console.log('[Notifier] Setting up heartbeat interval (singleton)');
    heartbeatInterval = setInterval(() => {
      // Ensure we have a reference to the message recovery service
      if (!messageRecoverySingleton) return;
      
      const currentTime = Date.now();
      const inactiveTimeMs = currentTime - lastMessageTimestamp;
      const inactiveTimeSeconds = inactiveTimeMs / 1000;
      
      // If no messages received in the last 10 seconds
      if (inactiveTimeSeconds >= 30) {
        console.log(`[Notifier] No messages in last ${inactiveTimeSeconds.toFixed(1)} seconds, triggering heartbeat`);
        
        // Trigger the heartbeat for all tracked rooms
        messageRecoverySingleton.handleHeartbeat();
        
        // Update timestamp to prevent repeated triggers
        lastMessageTimestamp = Date.now();
      }
    }, 5000);
  }
};

export const notifierDisconnectThunk = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("notifierDisconnectThunk", async (options, thunkApi) => {
  const state: CommonRootState = thunkApi.getState();
  const dispatch = thunkApi.dispatch;

  console.log('[Notifier] Disconnecting socket');
  notifierSocket?.disconnect();
  notifierSocket.close();

  notifierSocket = null;
  dispatch(updateConnectedSockets([]));


  dispatch(setSocketId());
});

export const initializeNotifierConnectionThunk = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("initializeNotifierConnectionThunk", async (options, thunkApi) => {

  if (notifierSocket) {
    return;
  }

  const state: CommonRootState = thunkApi.getState();

  const dispatch = thunkApi.dispatch;

  dispatch(notifierDisconnectThunk);

  const accessToken = state?.UserReducer?.accessToken;
  const user = selectUser(state)

  const parser = Bowser.getParser(window.navigator.userAgent);
  const browserInfo = parser.getBrowser();

  const isMobile = parser.is("mobile");
  const isTablet = parser.is("tablet");
  const isDesktop = parser.is("desktop");


  notifierSocket = io(appConfig.NOTIFIER_ENDPOINT, {
    transports: ['websocket', 'polling'], // Use both transports
    upgrade: true,
    autoConnect: false,
    query: {
      EIO: "4",
      transport: "websocket",
    }
  });

  // Initialize message recovery service
  const messageRecovery = new MessageRecoveryService();

  // Create request handler
  const requestHandler = (request: MissingMessagesRequest) => {
    console.log(`[Notifier] Requesting missing messages: ${JSON.stringify(request)}`);
    // Ensure we have a valid socket and room
    if (!notifierSocket || !request.room) {
      console.log('[Notifier] Cannot request messages: invalid socket or room');
      return;
    }

    // Send the request to the server
    notifierSocket.emit('request_missing_messages', request);
  };

  // Create message processing handler
  const processMessage = (message: QueuedMessage) => {
    // Update timestamp whenever we process a message
    updateMessageTimestamp();
    
    console.log(`[Notifier] Processing message: ${message.messageId} ${message.method} ${message.path}`);
    
    if (message?.entity?.qrcode) {
      let smallQr = message.entity.qrcode.substring(25, 35);
      console.log('[Notifier] QR code:', smallQr);
    }

    const dbReducerSlice = DBReducerMap.get(message.path);
    if (dbReducerSlice) {
      switch (message.method) {
        case "create": {
          dispatch(dbReducerSlice.slice.actions.addOne(message.entity));
          break;
        }
        case "patch": {
          const state: CommonRootState = thunkApi.getState();
          if (message.entity) {
            if (Array.isArray(message.entity)) {
              const updatedEntities = message.entity.map((entity) => ({
                id: entity._id,
                changes: entity
              }));
              dispatch(dbReducerSlice.slice.actions.updateMany(updatedEntities));
            } else {
              const entity = dbReducerSlice.selectors.selectById(state, message.entity?._id);
              if (entity) {
                dispatch(
                  dbReducerSlice.slice.actions.updateOne({
                    id: message.entity._id,
                    changes: message.entity,
                  })
                );
              } else {
                dispatch(dbReducerSlice.slice.actions.addOne(message.entity));
              }
            }
          }
          break;
        }
        case "remove": {
          dispatch(dbReducerSlice.slice.actions.removeOne(message.entity._id));
          break;
        }
      }
    }
    return true; // Message processed successfully
  };

  // Set up message recovery handlers
  messageRecovery.setHandlers({
    onRequestMissingMessages: requestHandler,
    onProcessMessage: processMessage
  });

  // Enable socket.io debug logging
  localStorage.setItem('debug', 'socket.io-client:socket');

  notifierSocket.on("connect", () => {
    console.log(`Socket connected [${notifierSocket.id}]`);

    dispatch(NotifierActions.setConnectionStatus(true));
    dispatch(setSocketId(notifierSocket.id));
    let connectedFrom;
    if (window.localStorage.getItem("sessionId")) {
      connectedFrom = "gateway";
    } else if (isExtensionContextOrWhatsappView()) {
      connectedFrom = "extension";
    } else {
      connectedFrom = "web";
    }
    console.log('[Notifier] Authenticating with:', {
      accessToken,
      browserType: browserInfo.name,
      device: isMobile ? "Mobile" : isTablet ? "Tablet" : isDesktop ? "Desktop" : "Unknown",
      connectionTime: moment().format("DD/MM/YYYY HH:mm"),
      connectedFrom: connectedFrom,
    });

    notifierSocket.emit(
      "authenticate",
      {
        accessToken,
        browserType: browserInfo.name,
        device: isMobile
          ? "Mobile"
          : isTablet
            ? "Tablet"
            : isDesktop
              ? "Desktop"
              : "Unknown",
        connectionTime: moment().format("DD/MM/YYYY HH:mm"),
        connectedFrom: connectedFrom,
        domain: window.location.href,
      },
      (newAuthResult) => {
        console.log(newAuthResult);
        console.log('[Notifier] Authenticated with:', newAuthResult);

        if (newAuthResult.user?._id) {
          notifierSocket.emit("join", { room: user._id.toString() }, (joinResult) => {
            console.log(`[Notifier] Joined user room: ${user._id}`, joinResult);
          });
          messageRecovery.handleReconnect();
        }

        dispatch(
          notifierSubscribeThunk({
            entityType: "boards",
            entityId: state.BoardsReducer.selectedBoardId,
          })
        );

      }
    );
  });

  notifierSocket.on("disconnect", () => {
    console.log(`[Notifier] disconnected socket [${notifierSocket.id}]`);
    console.log(notifierSocket);
    
    dispatch(NotifierActions.setConnectionStatus(false));
    dispatch(updateConnectedSockets([]));
  });

  // Update room-sockets handler
  notifierSocket.on("room-sockets", async (sockets: any[]) => {
    try {
      console.log('[Notifier] Room sockets updated:', sockets);

      dispatch(updateConnectedSockets(sockets));
      await dispatch(checkAndRefreshContactsThunk());
    } catch (error) {
      console.error('[Notifier] Error in room-sockets handler:', error);
    }
  });

  notifierSocket.on("logout", () => {
    dispatch(logoutWrapperThunk({ accessToken }))
  });

  notifierSocket.on("error", () => {
    console.log("Socket error!");
    dispatch(NotifierActions.setConnectionStatus(false));
    setTimeout(() => {
      dispatch(initializeNotifierConnectionThunk());
    }, 3000);
  });

  // Update missed_messages handler
  notifierSocket.on('missed_messages', (data: QueuedMessage[] | { messages: QueuedMessage[], roomId: string }) => {
    // Update timestamp when we receive missed messages
    updateMessageTimestamp();
    
    // Handle both array and object formats
    if (Array.isArray(data)) {
      // If data is an array, we need to determine the roomId from the user
      const roomId = user?._id?.toString();
      if (roomId) {
        messageRecovery.handleMissedMessages(data, roomId);
      } else {
        console.error('[Notifier] Received messages array but no room ID available');
      }
    } else {
      // If data is an object with messages and roomId
      messageRecovery.handleMissedMessages(data.messages, data.roomId);
    }
  });

  // Update entity_update handler
  notifierSocket.on("update", async (updateEvent: UpdateEvent, callback?: (response: any) => void) => {
    // Update timestamp when we receive any update
    updateMessageTimestamp();
    
    const messageId = updateEvent._messageId;
    const roomId = updateEvent._roomId;

    // Handle whatsapp interface events separately
    if (updateEvent.path === 'whatsapp-interface') {
      if (isExtensionContext()) {
        await handleWhatsappInterfaceEvents(updateEvent, callback);
      }
      return;
    }

    if (typeof messageId === 'number' && roomId) {
      // Let MessageRecoveryService handle the message
      messageRecovery.handleEntityUpdate(roomId, {
        messageId,
        path: updateEvent.path,
        method: updateEvent.method,
        entity: updateEvent.entity
      });
    }
  });

  // on sync-user-data event, get user subscriptions
  notifierSocket.on("sync-user-data", async (updateEvent: UpdateEvent) => {
    // Update timestamp when we sync user data
    updateMessageTimestamp();
    
    console.log('[Notifier] Syncing user data');

    // Essential for the app to work, must await before continuing
    const subscriptionListRes = await dispatch(DBSubscriptionThunks.find({}));
    const subscriptionList: Subscription[] = subscriptionListRes.payload;

    dispatch(validateSubscriptionsThunk(subscriptionList));
  });

  // Add handler for the chatbot progress updates
  notifierSocket.on("chatbot-progress", (progressEvent) => {
    console.log('[Notifier] Received chatbot progress update:', progressEvent);
    if (progressEvent && progressEvent.message) {
      dispatch(setLoaderProgressMessage(progressEvent.message));
    }
  });

  notifierSocket.connect();
  
  // Initialize the heartbeat singleton
  initializeHeartbeatIfNeeded(messageRecovery);
});

export const notifierSubscribeThunk = createAsyncThunk<
  void,
  { entityType: string; entityId: string },
  { state: CommonRootState }
>("notifierSubscribeThunk", async (params, thunkApi) => {
  const { entityType, entityId } = params;
  if (!entityType || !entityId) {
    return;
  }

  notifierSocket.emit("subscribe", {
    entity: entityType,
    id: entityId,
  });
});

export const notifierLogoutThunk = createAsyncThunk<
  void,
  string,
  { state: CommonRootState }
>("notifier/logout", async (socketId, thunkApi) => {
  const state: CommonRootState = thunkApi.getState();
  notifierSocket.emit("logout-cmd", { socketId: socketId });
});


export const identifySocketThunk = createAsyncThunk<
  void,
  string,
  { state: CommonRootState }
>("notifier/identify", async (socketId, thunkApi) => {
  const state: CommonRootState = thunkApi.getState();
  notifierSocket.emit("identify-cmd", { socketId: socketId });
});

export const checkAndRefreshContactsThunk = createAsyncThunk<
  void,
  void,
  { state: CommonRootState }
>("checkAndRefreshContactsThunk", async (_, thunkApi) => {
  const state: CommonRootState = thunkApi.getState();
  const dispatch = thunkApi.dispatch;

  if (!isExtensionContext()) {
    // Get all contacts from the reducer
    const contacts = state.DBWaContactsCacheReducer.entities;
    const contactsArray = Object.values(contacts) as WaContact[];
    const CONTACTS_REFRESH_INTERVAL = 60;

    // Check if reducer is empty or data is older than 2 hours
    const shouldUpdate = contactsArray.length === 0 ||
      (contactsArray[0]?.updatedAt &&
        moment().diff(moment(contactsArray[0].updatedAt), 'minutes') >= CONTACTS_REFRESH_INTERVAL
      );

    if (shouldUpdate) {
      console.log('[Notifier] Data needs refresh, refreshing contacts');
      await dispatch(getChatsAndContactsThunk({ shouldForceUpdate: true, shouldRefreshContacts: true }));
    }
  }
});



const handleWhatsappInterfaceEvents = async (updateEvent: any, callback: any) => {
  if (updateEvent.path === 'whatsapp-interface') {
    console.log('[Notifier] Handling whatsapp-interface command:', updateEvent.method);
    try {
      let response;

      // Handle different commands
      switch (updateEvent.method) {
        case 'get_contacts':
          response = await ContentMessageServiceActions.getContacts({
            limit: updateEvent.entity?.params?.limit ?? 100,
            offset: updateEvent.entity?.params?.offset ?? 0
          });
          break;
        case 'get_chats':
          response = await ContentMessageServiceActions.getChats({
            limit: updateEvent.entity?.params?.limit ?? 100,
            offset: updateEvent.entity?.params?.offset ?? 0
          });
          break;
        case 'get_messages':
          response = await ContentMessageServiceActions.getMessages({
            chatId: updateEvent.entity?.params?.chatId,
            limit: updateEvent.entity?.params?.limit ?? 100,
            offset: updateEvent.entity?.params?.offset ?? 0,
            cutoffDate: updateEvent.entity?.params?.cutoffDate,
            latestDate: updateEvent.entity?.params?.latestDate
          });

          response = response
            .filter(msg => msg.type !== 'image')
            .map(msg => {
              const cleanedMsg = {};
              Object.entries(msg).forEach(([key, value]) => {
                if (key !== 'id' && value !== undefined) {
                  cleanedMsg[key] = value;
                }
              });
              return cleanedMsg;
            });
          break;


        case "get_wa_messages_ack_by_wa_messages_keys":
          const waMessageKeys = updateEvent.entity?.params?.params;
          response =
            await ContentMessageServiceActions.getWaMessagesAckByWaMessagesKeys(
              waMessageKeys
            );
          break;
        case "get_wa_messages_ack_data_by_wa_messages_keys":
          const waMessageKeysForAckData = updateEvent.entity?.params?.params
          response = await ContentMessageServiceActions.getWaMessagesAckDataByWaMessagesKeys(
            waMessageKeysForAckData
          );
          break;

        case 'search_messages':
          response = await ContentMessageServiceActions.searchMessages(updateEvent.entity?.params);
          break;
        case 'get_profile_pic':
          response = await ContentMessageServiceActions.getProfilePic(updateEvent.entity?.params?.contactIds);
          break;
        case 'send_message_from_whatsapp_interface_actions':
          const timeoutMs = 10000; // Set your timeout duration (in ms)

          const preparedWaMessage = await withTimeout(prepareMessageForWa(updateEvent.entity?.params?.message as any), 'prepareMessageForWa', timeoutMs);
          response = await ContentMessageServiceActions.sendMessageViaWhatsapp(preparedWaMessage, updateEvent.entity?.params?.userMessageId);
          break;
        default:
          throw new Error(`Unknown command: ${updateEvent.method}`);
      }

      console.log('[Notifier] Sending response:', response);
      callback({
        success: true,
        data: response
      });
    } catch (error) {
      console.error('[Notifier] Error processing request:', error);
      callback({
        success: false,
        error: error.message || 'Failed to process request'
      });
    }
  }
  return null;
};

export const tryInitializeConnection = createAsyncThunk(
  'notifier/tryInitializeConnection',
  async (_, { dispatch, getState }) => {
    console.log('[Notifier] Trying to initialize connection');
    const state: CommonRootState = getState() as CommonRootState;
    const isReady = selectIsReadyToConnect(state);

    console.log('[Notifier] Is ready to connect:', isReady);
    if (isReady) {
      console.log('[Notifier] Dispatching initializeNotifierConnectionThunk');
      await dispatch(initializeNotifierConnectionThunk());
    } else {
      console.log('[Notifier] Not ready to connect');
    }
  }
);

