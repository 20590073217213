import { getEdgeDataByEdgeId } from '@common-reducers/ChatbotSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import {
    BaseEdge,
    EdgeLabelRenderer,
    getBezierPath,
    Position,
    useReactFlow,
} from 'reactflow';

type CustomEdgeProps = {
    id: string;
    sourceX: number;
    sourceY: number;
    targetX: number;
    targetY: number;
    sourcePosition: Position;
    targetPosition: Position;
    selected?: boolean;
    style?: any;
    data?: any;
};

export default function CustomEdge({ 
    id, 
    sourceX, 
    sourceY, 
    targetX, 
    targetY, 
    sourcePosition, 
    targetPosition, 
    selected 
}: CustomEdgeProps) {
    const dispatch = useAppDispatch()
    const edge = useAppSelector(state => getEdgeDataByEdgeId(state,id))

    const { setEdges } = useReactFlow();

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
    });

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer>
                {selected && (
                    <button
                        key={`${edge?.sourceHandle}_${edge?.targetHandle}`}
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
                            pointerEvents: 'all',
                        }}
                        className="nodrag nopan"
                        onClick={() => {
                            setEdges((edges) => edges.filter((e) => e.id !== id));
                        }}
                    >
                        delete
                    </button>
                )}
            </EdgeLabelRenderer>
        </>
    );
}
