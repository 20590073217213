import mime from 'mime-types';
import moment from 'moment';
import { configManager } from './config/wcl.config';
import { UserMessage, WaMessageType } from "./wa-interfaces";
import { decodeUint8Array32ToString } from './proccess-message-app-context';


export function getWaMessageType(message: UserMessage): WaMessageType | undefined {
    const waCommunicationLayerConfig = configManager.getConfig();

    const contact = message.contactList?.[0];
    if (!contact || !contact.id) return;

    // Check if it's a newsletter chat
    const isNewsletter = contact.id.includes('newsletter');
    // Check if running in server environment
    const isServer = waCommunicationLayerConfig.WCL_ENV === 'server';

    // Handle poll messages
    if (message.isPoll) {
        return isNewsletter ? 'newsletterPoll' : 'poll';
    }

    // Handle media messages
    if (message.asset) {
        if (isImage(message.asset)) {
            if (isNewsletter) {
                return isServer ? 'newsletterServerImage' : 'newsletterImage';
            } else {
                return isServer ? 'serverImage' : 'image';
            }
        } else {
            // Non-image attachments (video, audio, etc)
            if (isNewsletter) {
                return isServer ? 'newsletterServerAttachmentWithoutImage' : 'newsletterAttachmentWithoutImage';
            } else {
                return isServer ? 'serverAttachmentWithoutImage' : 'attachmentWithoutImage';
            }
        }
    }

    // Handle text messages
    return 'text';
}

export function isImage(asset: any) {
    const mimeType = mime.lookup(asset.type ?? asset.name) || asset.type;
    return mimeType && mimeType.indexOf('image') > -1
}

export const initWaMessageOptions = (options: any) => {
    return {
        linkPreview: options.linkPreview === false ? undefined : true,
        sendAudioAsVoice: options.sendAudioAsVoice,
        sendVideoAsGif: options.sendVideoAsGif,
        sendMediaAsSticker: options.sendMediaAsSticker,
        sendMediaAsDocument: options.sendMediaAsDocument,
        caption: options.caption,
        quotedMessageId: options.quotedMessageId,
        parseVCards: options.parseVCards === false ? false : true,
        mentionedJidList: options.mentions || [],
        groupMentions: options.groupMentions,
        invokedBotWid: options.invokedBotWid,
        extraOptions: options.extra,
    };
}

export async function prepareMessageMentions(messageText: string, messageOptions: any) {
    const { messageTextWithMentions, messageOptionsWithMentionedIds } = replaceMentionsAndExtractIds(messageText, messageOptions);
    return { messageTextWithMentions, messageOptionsWithMentionedIds };
}

export function replaceMentionsAndExtractIds(messageText: string, options: any): { messageTextWithMentions: string, messageOptionsWithMentionedIds: any } {
    const pattern = /@\[(.*?)\]\((.*?)\)/g;
    const ids: string[] = [];

    const messageTextWithMentions = messageText?.replace(pattern, (match, display, id) => {
        ids.push(id);
        return `@${id.split('@')[0]}`;
    });

    const messageOptionsWithMentionedIds = { ...options, mentionedJidList: ids }
    return { messageTextWithMentions, messageOptionsWithMentionedIds };
}

export function prepareTextMessageWithReply(replyData: any, options: any) {
    if (replyData) {
        options = { ...options, quotedMessageId: replyData?._serialized };
    }
    return options;
}

export const isMessageSendAttemptedWas3MinutesAgo = (creationDate: string) => {
    const timeLimitInMinutes = 3
    if (moment().diff(moment(creationDate), 'minutes') < timeLimitInMinutes) {
        return false;
    }
    return true;
}

export const getThresholdTimestamp = (timeLimitInMinutes: number) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const timeLimitInSeconds = timeLimitInMinutes * 60;
    return currentTimestamp - timeLimitInSeconds;
}

export const isUserMessageIdMatchToWaMessage = (messageId: string, waMessage: any): { fromMe: boolean, remote: string, id: string, _serialized: string } | null => {
    if (!waMessage) return null;

    console.log(`[isUserMessageIdMatchToWaMessage]: serching for messageId ${messageId}`);
    console.log('[isUserMessageIdMatchToWaMessage]: waMessage:', waMessage);

    let decodedMessageId = null;
    try {
        if (waMessage.messageSecret) {
            decodedMessageId = decodeUint8Array32ToString(waMessage.messageSecret);
            if (decodedMessageId === messageId) {
                console.log('[isUserMessageIdMatchToWaMessage]: found by messageSecret');
                return waMessage.id;
            }
        }
        if (waMessage.campaignId) {
            if (waMessage.campaignId === messageId) {
                console.log('[isUserMessageIdMatchToWaMessage]: found by campaignId');
                return waMessage.id;
            }
        }
        if (waMessage.comment) {
            if (waMessage.comment === messageId) {
                console.log('[isUserMessageIdMatchToWaMessage]: found by comment');
                return waMessage.id;
            }
        }
    } catch (error) {
        console.log('[isUserMessageIdMatchToWaMessage]: error decoding message ID:', error);
    }

    return null;
}