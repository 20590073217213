import { campaignReducerActions, DBUserMessageThunks, userMessageReducerActions } from "@common-reducers/DBServiceThunks";
import { UserMessage } from "@common/models/user-message";
import { CommonRootState } from "@common/types/common-root-state-type";
import { ContentMessageServiceActions } from "@extension/context/content/services/contentMessageServiceActions";
import { prepareMessageForWa } from "@extension/wa-communication-layer/proccess-message-app-context";
import { deleteUserMessageAndCreateLog, getNextCampaignMessage, hasSendAttemptBeenMadeToMessage } from "@extension/wa-communication-layer/services/api-calls";
import { withTimeout } from "@extension/wa-communication-layer/wa-error-tracing";
import { WaMessage } from "@extension/wa-communication-layer/wa-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";

const timeoutMs = 50000; // Set your timeout duration (in ms)

export const sendMessageThunk = createAsyncThunk("sendMessageThunk", async (userMessage: UserMessage, thunkApi) => {

    if (!userMessage._id) {
        console.warn("[sendMessageThunk] userMessage._id is undefined");
        return;
    }

    const dispatch = thunkApi.dispatch;
    let sendMessageViaWhatsappResponse: any;

    const state = thunkApi.getState() as CommonRootState;
    const settings = state?.MainReducer?.settings;

    try {
        const preparedWaMessage: WaMessage = await withTimeout(prepareMessageForWa(userMessage as any), 'prepareMessageForWa', timeoutMs);

        let hasSendAttemptBeenMadeToMessageResponse = await withTimeout(hasSendAttemptBeenMadeToMessage(userMessage._id), 'hasSendAttemptBeenMadeToMessage', timeoutMs);
        if (!hasSendAttemptBeenMadeToMessageResponse) {
            console.warn("[sendMessageThunk] wasSendAttemptedRes is undefined");
            return;
        }

        if (hasSendAttemptBeenMadeToMessageResponse.wasSendAttempted === false) {
            const { payload } = await dispatch(sendViaWaAndLogMessageAndHandleRecurrenceThunk({ preparedWaMessage, userMessage }));
            sendMessageViaWhatsappResponse = payload;
        }
        else if (
            settings?.isSendMessageRetryEnabled === true &&
            userMessage.enableRetry === true &&
            userMessage.retryCount < (settings?.messageMaxRetries ?? 0)) {

            await dispatch(DBUserMessageThunks.patch({
                entity: {
                    _id: userMessage._id,
                    retryCount: (userMessage.retryCount ?? 0) + 1
                }
            }));

            const { payload } = await dispatch(sendViaWaAndLogMessageAndHandleRecurrenceThunk({ preparedWaMessage, userMessage }));
            sendMessageViaWhatsappResponse = payload;
        } else {
            console.log("[sendMessageThunk] wasSendAttemptedRes is true");

            // Call getUnloggedWaMessageKeyIfExistInStore, check if the message is already sent, if yes, call logMessageAndHandleRecurrenceThunk
            const waMessageKey = await withTimeout(ContentMessageServiceActions.getUnloggedWaMessageKeyIfExistInStore(
                preparedWaMessage.chatId,
                userMessage._id,
                new Date(new Date(userMessage.dueDate).getTime() - 20 * 60 * 1000) // 10 minutes before the due date
            ), 'getUnloggedWaMessageKeyIfExistInStore', timeoutMs);

            console.log("[sendMessageThunk] [getUnloggedWaMessageKeyIfExistInStore] [waMessageKey]:", waMessageKey);
            if (waMessageKey) {
                await dispatch(logMessageAndHandleRecurrenceThunk({
                    userMessageId: userMessage._id,
                    waMessageKey,
                    waMessageKeyPart2: ""
                }));
            }
        }



        /*
        else if (hasSendAttemptBeenMadeToMessageResponse?.wasSendAttempted === true) {

            if (settings?.isSendMessageRetryEnabled === false) {
                console.log("[sendMessageThunk] [retry send message] isSendMessageRetryEnabled is false");
                dispatch(userMessageReducerActions.removeOne(userMessage._id));
                await dispatch(logMessageAndHandleRecurrenceThunk({ userMessageId: userMessage._id, waMessageKey: "RetryDisabled" }));
                return;
            }

            console.log("[sendMessageThunk] [retry send message] triggerd");
            if (!isMessageSendAttemptedWas3MinutesAgo(hasSendAttemptBeenMadeToMessageResponse.creationDate)) {
                return;
            }
            console.log("[sendMessageThunk] [retry send message] isMessageSendAttemptedWas3MinutesAgo passed");

            sendMessageViaWhatsappResponse = await withTimeout(ContentMessageServiceActions.getUnloggedWaMessageKeyIfExistInStore(
                preparedWaMessage.chatId,
                userMessage._id,
                new Date(userMessage.dueDate)
            ), 'getUnloggedWaMessageKeyIfExistInStore', timeoutMs);

            console.log("[sendMessageThunk] [getUnloggedWaMessageKeyIfExistInStore] [sendMessageViaWhatsappResponse]:", sendMessageViaWhatsappResponse);

            const waMessageKey = sendMessageViaWhatsappResponse?._serialized ?? sendMessageViaWhatsappResponse?.id?._serialized ?? "";
            console.log("[sendMessageThunk] [getUnloggedWaMessageKeyIfExistInStore] [waMessageKey]:", waMessageKey);

            if (!waMessageKey) {
                await dispatch(sendViaWaAndLogMessageAndHandleRecurrenceThunk({ preparedWaMessage, userMessage }));
            }
            else if (waMessageKey) {
                await dispatch(logMessageAndHandleRecurrenceThunk({ userMessageId: userMessage._id, waMessageKey }));
            }
        }
            */
    } catch (error) {
        console.error(`Error in sendMessageThunk:`, error);
    }

    return sendMessageViaWhatsappResponse;
});


export const sendViaWaAndLogMessageAndHandleRecurrenceThunk = createAsyncThunk("sendViaWhatsappAndLogThunk",
    async ({ preparedWaMessage, userMessage, shouldSendMessage = true }:
        { preparedWaMessage: WaMessage; userMessage: UserMessage, shouldSendMessage?: boolean; },
        { dispatch }) => {

        let sendMessageViaWhatsappResponse: any = null;
        let waMessageKey: any = null;
        let waMessageKeyPart2: any = null;

        console.log(`[sendMessageThunk] [userMessage id]: ${userMessage?._id} [sendMessageViaWhatsappResponse]: ${waMessageKey}`);
        try {
            console.log(`[sendMessageThunk] [shouldSendMessage] ${shouldSendMessage} [userMessage id]: ${userMessage?._id} `);
            dispatch(userMessageReducerActions.removeOne(userMessage._id));

            if (shouldSendMessage) {
                sendMessageViaWhatsappResponse = await withTimeout(ContentMessageServiceActions.sendMessageViaWhatsapp(preparedWaMessage, userMessage._id),
                    "sendMessageViaWhatsapp",
                    timeoutMs
                );

                sendMessageViaWhatsappResponse = sendMessageViaWhatsappResponse?.msg ? sendMessageViaWhatsappResponse?.msg : sendMessageViaWhatsappResponse;

                waMessageKey = sendMessageViaWhatsappResponse?._serialized ?? sendMessageViaWhatsappResponse?.id?._serialized ?? sendMessageViaWhatsappResponse?.id ?? "";
                waMessageKeyPart2 = sendMessageViaWhatsappResponse?.from?._serialized ?? "";
                // if ack is 0, the message is pending and it suspicious that it failed to send
                if (waMessageKey && sendMessageViaWhatsappResponse.ack === 0) {

                    await new Promise(resolve => setTimeout(resolve, 3000));

                    const getMoreUpdatedWaMessage = await withTimeout(ContentMessageServiceActions.getWaMessageById(waMessageKey),
                        "getWaMessageById",
                        timeoutMs
                    );

                    if (getMoreUpdatedWaMessage?.isSendFailure === true) {
                        console.log('Message failed to send - refreshing page');
                        await dispatch(DBUserMessageThunks.patch({
                            entity: {
                                _id: userMessage._id,
                                enableRetry: true
                            }
                        }
                        ));

                        await dispatch(reloadPageThunk());
                        return;
                    }
                }
            }

            if (waMessageKey) {
                await dispatch(logMessageAndHandleRecurrenceThunk({
                    userMessageId: userMessage._id,
                    waMessageKey,
                    waMessageKeyPart2: ""
                }));
            }
            else {
                console.log(`[sendMessageThunk] message didnt sent - add it back to the state [userMessage id]: ${userMessage?._id} `);
                dispatch(userMessageReducerActions.addOne(userMessage));
            }

        } catch (error) {
            console.error(`Error in sendViaWhatsappAndLogThunk:`, error);
        }

        return sendMessageViaWhatsappResponse;
    }
);


export const logMessageAndHandleRecurrenceThunk = createAsyncThunk("handleMessageLogAndRecurrenceThunk", async (
    { userMessageId, waMessageKey, waMessageKeyPart2, shouldDeleteMessage = true }: { userMessageId: string; waMessageKey: string; waMessageKeyPart2: string; shouldDeleteMessage?: boolean; },
    { dispatch }
) => {
    try {

        let deleteUserMessageAndCreateLogResponse: any = null;
        if (shouldDeleteMessage) {
            dispatch(userMessageReducerActions.removeOne(userMessageId));
            deleteUserMessageAndCreateLogResponse = await deleteUserMessageAndCreateLog(userMessageId, waMessageKey, waMessageKeyPart2);
        }

        console.log(`[sendMessageThunk] [shouldDeleteMessage] ${shouldDeleteMessage} [deleteUserMessageAndCreateLogResponse]: ${JSON.stringify(deleteUserMessageAndCreateLogResponse)}`);
        // If the message is recurring and scheduled, add the new message
        if (deleteUserMessageAndCreateLogResponse?.isRecurringScheduled && deleteUserMessageAndCreateLogResponse?.newMessage) {
            dispatch(userMessageReducerActions.addOne(deleteUserMessageAndCreateLogResponse.newMessage));
        }

        return deleteUserMessageAndCreateLogResponse;
    } catch (error) {
        console.error(`Error in handleMessageLogAndRecurrenceThunk: ${error.message}`);
    }
}
);



export const nextCampaignMessageThunk = createAsyncThunk<
    void,
    string,
    { state }
>("nextCampaignMessageThunk", async (campaignId, thunkApi) => {

    const { dispatch } = thunkApi;
    const timeoutMs = 10000; // Set your timeout duration (in ms)

    try {
        let res = await withTimeout(getNextCampaignMessage(campaignId), 'getNextCampaignMessage', timeoutMs);
        if ("data" in res) {

            dispatch(campaignReducerActions.updateOne({
                id: campaignId,
                changes: res.data.campaign,
            }));

            if (res.data.campaignMessages?.length > 0) {
                dispatch(userMessageReducerActions.upsertMany(res.data.campaignMessages));
            }
        }

    } catch (error) {
        console.error(`Error in nextCampaignMessageThunk:`, error);
    }
});

export const reloadPageThunk = createAsyncThunk("reloadPageThunk", async (_, { dispatch }) => {
    try {
        await withTimeout(ContentMessageServiceActions.reloadPage(), "reloadPage", timeoutMs);
    } catch (error) {
        console.error(`Error in reloadPageThunk:`, error);
    }
});


