import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { getCalendarEvents } from "./WhatsAppSelectors";
import { UserMessage } from "@common/models/user-message";


export const getIsCustomMonthEventHighlighted = createSelector(
    [
        (state, contactWaId) => state,
        (state, contactWaId) => contactWaId,
    ],
    (state, contactWaId) => {
        const highlightedContactId = state.WhatsAppReducer.highlightedContactId
        const isHighlighted = highlightedContactId && contactWaId === highlightedContactId;
        return isHighlighted
    }
)

export const getIsDayCellHovered = createSelector(
    [
        (state, date) => state,
        (state, date) => date,
    ],
    (state, date) => {
        const hoveredDate = state.WhatsAppReducer.hoveredDate

        if (!hoveredDate) return false

        return moment(hoveredDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
    }
)


interface AgendaDayGroup {
    date: string;
    messages: UserMessage[];
}

export const getAgendaFlattenedData = createSelector(
    [
        (state) => state,
    ],
    (state) => {
        const events = getCalendarEvents(state)

        const groups: AgendaDayGroup[] = [];
        const sortedEvents = [...events].sort((a, b) =>
            moment(a.dueDate).valueOf() - moment(b.dueDate).valueOf()
        );

        sortedEvents.forEach(message => {
            const dateStr = moment(message.dueDate).format('YYYY-MM-DD');
            let group = groups.find(g => g.date === dateStr);

            if (!group) {
                group = { date: dateStr, messages: [] };
                groups.push(group);
            }

            group.messages.push(message);
        });

        return groups.reduce((acc, group) => {
            acc.push({ type: 'header', date: group.date });
            group.messages.forEach(message => {
                acc.push({ type: 'message', message });
            });
            return acc;
        }, [] as Array<{ type: 'header'; date: string; } | { type: 'message'; message: UserMessage; }>);
    }
)
