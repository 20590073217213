import CampaignsView from "@extension/context/content/components/bt-bulk-dashboard/campaigns-view";
import { Stack } from "@mui/material";
import classes from "../account-view/account-view.module.scss";
import BoardsPanel from "../boards-panel/BoardsPanel";
import ConnectedEngines from "@common/components/connected-engines/connected-engines";
import QrModal from "@common/views/qr-view/qr-modal";

const LaunchCampaignView = () => {

  return (
    <>
      <div className={classes.pageContainer}
        style={{
          height: '100vh',
          display: 'flex',
          backgroundColor: 'var(--background-default)',
        }}>
        {/* <CalendarSideBar view={view} onViewChange={setView} /> */}
        <BoardsPanel />
        <Stack sx={{
          flex: 1,
          transition: 'all 0.2s',
        }}>
          <div className={classes.titleContainer} style={{
            backgroundColor: 'var(--background-default)',
            borderBottom: '1px solid var(--separator-line)',
            padding: '12px 24px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            height: '87px',
            justifyContent: 'space-between'
          }}>
            <h4 className={classes.pageTitle} style={{ margin: 0 }}>Campaign Management</h4>
            <div style={{ marginLeft: 'auto' }}>
              <ConnectedEngines />
            </div>
          </div>
          <CampaignsView isWebsite={true} />
        </Stack>
      </div>
      <QrModal />
    </>
  );
};

export default LaunchCampaignView;
