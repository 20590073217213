import React from 'react';
import classes from "./bot-checkbox.module.scss";

interface BotCheckboxProps {
  id: string;
  checked: boolean;
  onChange: () => void;
  title: string;
  description: string;
}

const BotCheckbox: React.FC<BotCheckboxProps> = ({ 
  id, 
  checked, 
  onChange, 
  title, 
  description 
}) => {
  return (
    <div className={classes.free_text_checkbox}>
      <div className={classes.option_container}>
        <div className={classes.checkbox_wrapper}>
          <input 
            type="checkbox" 
            id={id}
            className={classes.checkbox_input}
            checked={checked || false}
            onChange={onChange}
          />
          <label 
            htmlFor={id} 
            className={classes.checkbox_label}
          >
            <span className={classes.checkbox_custom}></span>
            <span className={classes.checkbox_text}>
              <span className={classes.feature_title}>{title}</span>
              <span className={classes.feature_description}>{description}</span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default BotCheckbox; 