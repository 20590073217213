import { whatsappInterfaceClient } from '../../../common/services/whatsapp-interface-client';
import { ChatMessage } from './types';

interface SendMessageOptions {
    onMessageUpdate: (updater: (prev: ChatMessage[]) => ChatMessage[]) => void;
    onError: (error: string) => void;
    onLoadingChange: (isLoading: boolean) => void;
    history?: Array<{ role: 'user' | 'assistant'; content: string; }>;
}

export const sendMessage = async (query: string, options: SendMessageOptions) => {
    const { onMessageUpdate, onError, onLoadingChange, history } = options;
    
    try {
        onLoadingChange(true);
        
        // Add new message to the list
        onMessageUpdate(prevMessages => [
            ...prevMessages,
            {
                query,
                isStreaming: true,
                response: {
                    finalAnswer: '',
                    thinking: []
                }
            }
        ]);

        const response = await whatsappInterfaceClient.streamClaude(query, history);
        
        if (!response.body) {
            throw new Error('No response body');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk.split('\n');

            for (const line of lines) {
                if (!line.trim()) continue;
                if (line.startsWith('data: ')) {
                    try {
                        const data = JSON.parse(line.slice(6));
                        
                        onMessageUpdate(prevMessages => {
                            const newMessages = [...prevMessages];
                            const lastMessage = newMessages[newMessages.length - 1];
                            
                            if (lastMessage) {
                                lastMessage.response = {
                                    finalAnswer: data.content || '',
                                    thinking: data.thinking ? [{
                                        content: data.thinking,
                                        type: 'thinking',
                                        timestamp: Date.now()
                                    }] : []
                                };
                                lastMessage.isStreaming = true;
                            }
                            
                            return newMessages;
                        });
                    } catch (e) {
                        console.error('Error parsing SSE data:', e);
                    }
                }
            }
        }

        // Update final message state
        onMessageUpdate(prevMessages => {
            const newMessages = [...prevMessages];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage) {
                lastMessage.isStreaming = false;
            }
            return newMessages;
        });

    } catch (error) {
        onError(error instanceof Error ? error.message : 'An error occurred');
        onMessageUpdate(prevMessages => {
            const newMessages = [...prevMessages];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage) {
                lastMessage.error = error instanceof Error ? error.message : 'An error occurred';
                lastMessage.isStreaming = false;
            }
            return newMessages;
        });
    } finally {
        onLoadingChange(false);
    }
}; 