import { useAppSelector } from '@common-reducers/hooks/store.hook';
import { botDeploymentSelectByQuery } from '@common-reducers/DBServiceThunks';
import { getCurrentBotSelector, SessionFilter, getBotDeploymentIdsByBotIdSelector } from '@common/reducers/ChatbotSelectors';
import { useParams } from 'react-router-dom';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface SessionFiltersProps {
    sessionFilter: SessionFilter;
    onSessionFilterChange: (filter: SessionFilter) => void;
    selectedDeploymentId: string | null;
    onDeploymentSelect: (deploymentId: string | null) => void;
}

export const SessionFilters = ({ 
    sessionFilter, 
    onSessionFilterChange,
    selectedDeploymentId,
    onDeploymentSelect,
}: SessionFiltersProps) => {
    const { botId } = useParams<{ botId: string }>();
    const currentBot = useAppSelector(state => getCurrentBotSelector(state));
    
    // Get deploymentIds using the same selector as other components
    const deploymentIds = useAppSelector(state => 
        botId ? getBotDeploymentIdsByBotIdSelector(state, botId) : []
    );

    // Get deployment details for each ID
    const deployments = useAppSelector(state => 
        deploymentIds.length > 0 ? botDeploymentSelectByQuery(state.DBBotDeploymentReducer, { 
            _id: { $in: deploymentIds }
        }) ?? [] : []
    );

    // Sort deployments by version number in descending order
    const sortedDeployments = [...deployments].sort((a, b) => b.version - a.version);

    return (
        <div className="flex gap-4">
            <Select 
                value={sessionFilter} 
                onValueChange={(value) => onSessionFilterChange(value as SessionFilter)}
            >
                <SelectTrigger className="w-[200px]">
                    <SelectValue placeholder="Filter sessions" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">All Sessions</SelectItem>
                    <SelectItem value="active">Active</SelectItem>
                    <SelectItem value="inactive">Inactive</SelectItem>
                </SelectContent>
            </Select>

            <Select 
                value={selectedDeploymentId ?? 'all'} 
                onValueChange={(value) => onDeploymentSelect(value === 'all' ? null : value)}
            >
                <SelectTrigger className="w-[200px]">
                    <SelectValue placeholder="Select version" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="all">All Versions</SelectItem>
                    {sortedDeployments.map(deployment => (
                        <SelectItem 
                            key={deployment._id} 
                            value={deployment._id}
                            className={deployment._id === currentBot?.activeDeploymentId ? 'bg-emerald-50 text-emerald-900' : ''}
                        >
                            v{deployment.version} {deployment._id === currentBot?.activeDeploymentId ? ' (Active)' : ''}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
}; 