import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "dot-prop-immutable";

export interface UninstallState {
  uninstallForm: {
    uninstallReason: string;
    email: string;
  }
}

const initialState: UninstallState = {
  uninstallForm: {
    uninstallReason: "",
    email: ""
  }
};

export const UninstallReducerSlice = createSlice({
  name: "uninstallReducer",
  initialState,
  reducers: {
    updateUninstallState: (state, action: PayloadAction<{ path: string; value: any }>) => {
      state.uninstallForm = set(state.uninstallForm, action.payload.path, action.payload.value);
    },
  },
});

export const {
  updateUninstallState
} = UninstallReducerSlice.actions;

export default UninstallReducerSlice.reducer;
