import { closeBulkDashboard } from '@common-reducers/CampaignReducer';
import { messageLogSelectOneObjectByQuery } from "@common-reducers/DBServiceThunks";
import { calculatedWaContactName, selectProfilePic } from '@common-reducers/WhatsAppSelectors';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import CampaignMessageLogRow from '../../campaign-message-log-row';
import { ContentMessageServiceActions } from '@extension/context/content/services/contentMessageServiceActions';

type Props = {
    itemId: string;
    index: number;
}

export default function CampaignMessageLogSentRow({
    itemId,
    index,
}: Props) {
    const dispatch = useAppDispatch();
    const rowData = useAppSelector((state) => messageLogSelectOneObjectByQuery(state.DBMessageLogReducer, {
        $or: [
            { deleted: { $exists: false } },
            { deleted: false }
        ],
        _id: itemId,
    }))

    const whatsappId = rowData?.message?.contactList?.[0]?.id
    const isGroup = whatsappId?.includes('@g.us')
    const profilePicUrl = useAppSelector((state) => selectProfilePic(state, whatsappId));
    const contact = rowData?.message?.contactList?.[0];
    const contactName = calculatedWaContactName(contact);


    const getContactPhone = (contact: any) => {
        if (contact?.id.includes('@c.us')) {
            const phone = contact?.id?.replace('@c.us', '');
            return phone ? ` +${phone}` : '';
        }
        return ''
    }

    const focusWAContact = () => {
        const contactId = rowData?.message?.contactList?.[0]?.id;
        if (contactId) {
            ContentMessageServiceActions.focusContact(contactId)
            dispatch(closeBulkDashboard());
        }
    };

    if (!rowData) return null;

    return (
        <CampaignMessageLogRow
            index={index}
            profilePicUrl={profilePicUrl}
            isGroup={isGroup}
            contactName={contactName}
            contactPhone={getContactPhone(contact)}
            messageLog={rowData}
            onClick={focusWAContact}
            status={rowData.status}
        />
    )
}