import React from 'react';
import AppView from '@common/components/app-view';
import KnowledgeBaseContent from './components/KnowledgeBaseContent';

const KnowledgeBaseList = () => {
  return (
    <AppView title="Knowledge Bases" activeExtensionView="knowledge-bases" showHeader={false}>
      <KnowledgeBaseContent />
    </AppView>
  );
};

export default KnowledgeBaseList; 