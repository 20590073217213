import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { UserMessage } from "@common/models/user-message";
import ContactAvatar from "@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar";
import { Trash2 } from "lucide-react";
import moment from "moment";
import { memo, SyntheticEvent, useState } from "react";
import { EventProps } from "react-big-calendar";
import { MessageAssetPreview } from "./message-asset-preview";
import { EventPL } from "./message-calendar";
import classes from "./message-calendar.module.scss";
import { getIsCustomMonthEventHighlighted } from "@common/reducers/CalendarSelectors";

interface Props {
  eventProps: EventProps<UserMessage>;
  onRightClick: (pl: EventPL) => void;
  onDelete?: (event: UserMessage) => void;
}

const handleRightClick = (e: SyntheticEvent, props: Props) => {
  e.preventDefault();
  const { left, top, bottom, right } = e.currentTarget.getBoundingClientRect();
  props.onRightClick({ ...props.eventProps.event, left, top, bottom, right });
};

const handleDelete = (e: React.MouseEvent, props: Props) => {
  e.stopPropagation();
  if (props.onDelete) {
    props.onDelete(props.eventProps.event);
  }
};

export const CustomMonthEvent = memo((props: Props) => {
  const [showTrash, setShowTrash] = useState(false);
  const { title, event } = props.eventProps;

  const isHighlighted = useAppSelector(state => getIsCustomMonthEventHighlighted(state, event.contactList?.[0]?.id))


  return (
    <div
      className={`${classes.monthEvent} ${isHighlighted ? classes.highlighted : ''}`}
      onContextMenu={(e: SyntheticEvent) => handleRightClick(e, props)}
      onDragStart={() => setShowTrash(false)}
      onDragEnd={() => setShowTrash(false)}
      onMouseOver={() => setShowTrash(true)}
      onMouseOut={() => setShowTrash(false)}
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <div style={{ width: '25px' }}>
        <ContactAvatar
          contactId={props.eventProps?.event?.contactList?.[0]?.id}
          size={25}
          sx={{
            m: '0px 0px 0px 2px',
          }}
          profilePicURL={props.eventProps?.event?.contactList?.[0]?.profilePicUrl}
        />
      </div>
      <div style={{
        width: '8px',
        height: '8px',
        borderRadius: '4px',
        backgroundColor: event.messageColor ?? 'grey',
        marginLeft: '4px',
      }} />
      <div style={{ width: '52px' }} className={classes.time}>
        {moment(event.dueDate).format("hh:mma")}
      </div>
      <div style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</div>
      {props.eventProps?.event?.asset?.url && props.eventProps?.event?.asset?.type && (
        <MessageAssetPreview
          url={props.eventProps.event.asset.url}
          type={props.eventProps.event.asset.type}
        />
      )}
      {props.onDelete && showTrash && (
        <div
          style={{
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            color: 'var(--destructive)',
            display: 'flex',
            alignItems: 'center',
            zIndex: 10,
            backgroundColor: 'var(--background-default)',
            borderRadius: '4px',
            padding: '2px',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete(e, props);
          }}
        >
          <Trash2
            size={16}
          />
        </div>
      )}
    </div>
  );
});
