import { Box, Stack } from "@mui/system";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { getWhatsappContactNameById, selectWhatsappContactById } from "@common-reducers/WhatsAppSelectors";
import { WhatsappContact } from "@wa-communication-layer/whatsapp";
import ContactAvatar from '@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar';
import ScheduledMessagesCount from "@common-components/scheduled-messages-count/scheduled-messages-count";
import moment from "moment";

interface WhatsappContactProps {
  whatsappId?: string;
  contactName?: string;
  message?: string;
  messageScheduledCount?: number;
  messageApiCount?: number;
  messageCampaignCount?: number;
  dueDate?: string;
  justifyContentType?: 'space-between' | 'center',
  showMessageDetails?: boolean
  profilePicURL?: string;
}

const WhatsappContactBox = ({ message, contactName: _contactName, whatsappId, messageScheduledCount, messageApiCount, messageCampaignCount, dueDate, justifyContentType = 'space-between', showMessageDetails = true, profilePicURL }: WhatsappContactProps) => {
  const contactName = useAppSelector(state => getWhatsappContactNameById(state, whatsappId, _contactName))


  return (
    <Stack direction="row" justifyContent={justifyContentType} alignItems="flex-start" sx={{ maxWidth: "100%", position: 'relative' }}>
      <Stack direction="row" alignItems="center" sx={{ minWidth: "30%", maxWidth: "60%" }}>
        <ContactAvatar
          sx={{ mr: 1 }}
          contactId={whatsappId}
          size={49}
          textSize={12}
          profilePicURL={profilePicURL}
        />
        {showMessageDetails && (
          <Stack spacing={0.5} sx={{ maxWidth: '100%', flex: 1 }}>
            <Box sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: 'var(--text-color)',
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}>
              {contactName}
            </Box>
            <Box sx={{
              fontSize: '14px',
              color: 'var(--text-secondary)',
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}>
              {message}
              &nbsp;
            </Box>
          </Stack>
        )}
      </Stack>
      {showMessageDetails && (
        <Stack sx={{
          height: 49,
          alignItems: 'flex-end'
        }} spacing={0.5} justifyContent={'center'}>
          <Stack direction="row" spacing={0.5} justifyContent={'center'}>
            {messageScheduledCount ? <ScheduledMessagesCount title="SCHEDULED" countValue={messageScheduledCount} /> : null}
            {messageApiCount ? <ScheduledMessagesCount title="API" countValue={messageApiCount} /> : null}
            {messageCampaignCount ? <ScheduledMessagesCount title="CAMPAIGN" countValue={messageCampaignCount} /> : null}
          </Stack>
          <Box sx={{ fontSize: "12px", direction: "ltr", whiteSpace: "nowrap", color: 'var(--text-secondary)' }}>
            {moment(dueDate).format("HH:mm DD/MM/yyyy")}
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default WhatsappContactBox;
