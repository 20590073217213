import { EventType } from '@common/models/node-event';
import { Box, Paper, Typography } from '@mui/material';
import reactStringReplace from 'react-string-replace';
import AttachmentPreview from '../../../attachment-preview/attachment-preview';
import { blobMap } from "@common-services/blob-service";

interface MessageEventProps {
  message: string;
  timestamp: Date;
  type: EventType.OUTGOING_MESSAGE | EventType.INCOMING_MESSAGE;
  color: string;
  bgColor: string;
  asset?: {
    url?: string;
    type?: string;
    name?: string;
  };
  imageId?: string;
}

export const MessageEvent = ({ message, timestamp, type, color, bgColor, asset, imageId }: MessageEventProps) => {
  // Switch sides - incoming (bot) messages on right, outgoing (user) on left
  const isIncoming = type === EventType.INCOMING_MESSAGE;
  const imageBlob = imageId ? blobMap.get(imageId) : null;
  
  // Format the message - replace *text* with bold and handle numbered options
  const formattedMessage = reactStringReplace(message, /\*(.*?)\*/g, (match, i) => (
    <strong key={i}>{match}</strong>
  ));
  
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isIncoming ? 'flex-end' : 'flex-start',
        mb: 1,
        px: 2
      }}
    >
      <Paper
        elevation={0}
        sx={{
          maxWidth: '70%',
          p: 1.5,
          backgroundColor: isIncoming ? bgColor : 'grey.100',
          borderRadius: 2,
          position: 'relative'
        }}
      >
        {(imageBlob || asset) && (
          <Box sx={{ mb: 1 }}>
            <AttachmentPreview
              attachment={imageBlob}
              attachmentUrl={asset?.url}
              attachmentType={asset?.type}
              attachmentName={asset?.name}
              previewMaxHeight='150px'
            />
          </Box>
        )}
        <Typography 
          variant="body2" 
          component="div"
          sx={{ 
            color: isIncoming ? color : 'text.primary',
            whiteSpace: 'pre-line',
            '& strong': {
              fontWeight: 600
            }
          }}
        >
          {formattedMessage}
        </Typography>
        <Typography 
          variant="caption" 
          sx={{ 
            color: 'text.secondary',
            display: 'block',
            textAlign: isIncoming ? 'right' : 'left',
            mt: 0.5
          }}
        >
          {new Date(timestamp).toLocaleTimeString()}
        </Typography>
      </Paper>
    </Box>
  );
}; 