import ContactAvatar from "@extension/context/content/components/bulk-dashboard/contact-table/contact-avatar";
import RowStack from "@common-components/common/row-stack/RowStack";
import { Box, Skeleton } from "@mui/material";
import { calculatedWaContactName, extractPhoneNumber, selectWhatsappContactById } from "@common-reducers/WhatsAppSelectors";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { useMemo } from "react";
import { CellProps } from "../cell-interface";
import { PhoneDisplay } from "../phone-number-cell/phone-display";
import { isTempId } from "@common-reducers/DBServiceThunks";

export const WhatsappIdCell = ({
    cellValue,
    taskId,
    columnId,
    openPicker,
    withoutPlus = false
}: CellProps) => {
    const contact = useAppSelector((state) => selectWhatsappContactById(state, cellValue));
    const phoneNumber = useMemo(() => extractPhoneNumber(cellValue, withoutPlus), [cellValue]);
    const contactName = useMemo(() => contact ? calculatedWaContactName(contact) : '', [contact]);
    const isGroup = useMemo(() => cellValue?.includes && cellValue?.includes('@g.us'), [cellValue]);

    const pickerAnchorId = `wid_${taskId}_${columnId}`;
    function handleClick() {
        if (!isTempId(taskId)) {
            openPicker({
                anchorId: pickerAnchorId,
                pickerType: 'wid-cell-edit-picker',
            })
        }
    }

    const cellContent = () => (
        cellValue ?
            <>
                <ContactAvatar
                    sx={{ mr: 1 }}
                    contactId={cellValue}
                    size={24}
                    textSize={12}
                />
                {contactName && <Box sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                    {contactName}
                </Box>}

                {!contactName && !isGroup && <PhoneDisplay phone={phoneNumber} />}
            </> :
            <Box sx={{ fontStyle: 'italic', fontSize: 12, width: 1, textAlign: 'center' }}>
                Click to add a contact
            </Box>
    )


    return (

        <RowStack
            sx={{
                px: 1,
                overflow: 'hidden',
                cursor: 'pointer',
            }}
            flex={1}
            justifyContent={'center'}
            onClick={handleClick} id={pickerAnchorId}
        >
            {isTempId(taskId) ? <Box sx={{ width: 1, height: 1 }}><Skeleton /></Box> : cellContent()}
        </RowStack>

    )
}