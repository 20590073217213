import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToolSettingsModalState {
  isOpen: boolean;
  toolName: string | null;
  initialSettings: any;
  nodeId: string | null;
  isToolsDialogOpen: boolean;
}

const initialState: ToolSettingsModalState = {
  isOpen: false,
  toolName: null,
  initialSettings: {},
  nodeId: null,
  isToolsDialogOpen: false,
};

export const toolSettingsModalSlice = createSlice({
  name: 'toolSettingsModal',
  initialState,
  reducers: {
    openToolSettingsModal: (state, action: PayloadAction<{
      toolName: string;
      initialSettings: any;
      nodeId: string;
    }>) => {
      state.isOpen = true;
      state.toolName = action.payload.toolName;
      state.nodeId = action.payload.nodeId;
      state.initialSettings = action.payload.initialSettings;
    },
    closeToolSettingsModal: (state) => {
      state.isOpen = false;
    },
    openToolsDialog: (state, action: PayloadAction<{nodeId: string}>) => {
      state.isToolsDialogOpen = true;
      state.nodeId = action.payload.nodeId;
    },
    closeToolsDialog: (state) => {
      state.isToolsDialogOpen = false;
      state.nodeId = null;
    },
  }
});

export const { openToolSettingsModal, closeToolSettingsModal, openToolsDialog, closeToolsDialog } = toolSettingsModalSlice.actions;

export default toolSettingsModalSlice.reducer; 