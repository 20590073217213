import { createSelector } from '@reduxjs/toolkit';
import { CommonRootState } from '@common/types/common-root-state-type';
import { knowledgeBaseDocumentSelectByQuery } from './DBServiceThunks';

/**
 * Selects all documents for a specific knowledge base
 */
export const selectKnowledgeBaseDocuments = (state: CommonRootState, knowledgeBaseId: string) => {
  return knowledgeBaseDocumentSelectByQuery(state.DBKnowledgeBaseDocumentReducer, { 
    knowledgeBaseId 
  });
};

/**
 * Counts the number of documents in a knowledge base
 */
export const selectKnowledgeBaseDocumentCount = createSelector(
  [selectKnowledgeBaseDocuments],
  (documents) => {
    return documents.length;
  }
);

/**
 * Calculates the total size of all documents in a knowledge base
 */
export const selectKnowledgeBaseTotalSize = createSelector(
  [selectKnowledgeBaseDocuments],
  (documents) => {
    return documents.reduce((total, document) => {
      return total + (document.metadata?.contentLength || 0);
    }, 0);
  }
);

/**
 * Counts the number of documents by type in a knowledge base
 */
export const selectKnowledgeBaseDocumentTypeCount = createSelector(
  [selectKnowledgeBaseDocuments],
  (documents) => {
    return documents.reduce((counts, document) => {
      const type = document.type || 'unknown';
      counts[type] = (counts[type] || 0) + 1;
      return counts;
    }, {} as Record<string, number>);
  }
); 