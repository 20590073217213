import ImportExcelToBlueticks from '@common-components/tasks-panel/import-tasks-from-excel/import-excel';
import { Modal } from '@mui/material';
import { bulkActions } from '@common-reducers/BulkActionsReducer';
import { setIsImportModalOpen } from '@common-reducers/CampaignReducer';
import { handleActiveBulkViewNavigationThunk } from '@common-reducers/CampaignThunks';
import { importItemsActions } from '@common-reducers/ImportItemsReducer';
import { modalActions } from '@common-reducers/ModalReducer';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import React from 'react';



export default function ImportCSVOrGoogleSheets() {
    const dispatch = useAppDispatch()

    const isImportModalOpen = useAppSelector(state => state.CampaignReducer.isImportModalOpen)

    const handleExitNewAudienceView = () => {
        dispatch(handleActiveBulkViewNavigationThunk({ navigate: "fromNewAudience" }))

    }

    const handleFinishImporting = () => {
        dispatch(setIsImportModalOpen(false))
        handleExitNewAudienceView()
    }


    return (
        <Modal
        open={isImportModalOpen}
        disablePortal
        onClose={() => {
          dispatch(setIsImportModalOpen(false))
          dispatch(importItemsActions.setStep(0))
          dispatch(importItemsActions.resetImport())
          dispatch(modalActions.setImportType())
          dispatch(modalActions.setComponentToRender(''))
          dispatch(bulkActions.setBulkTasksMap({}))
          dispatch(modalActions.setOpenedby(''))
          dispatch(importItemsActions.setNewAudienceName(null))
        }}
        sx={{
            maxWidth: '1000px',
            minWidth: '800px',
            background: 'white',
            // Add any padding, border-radius, etc., as needed:
            p: 2,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
          }}
      >
        <ImportExcelToBlueticks handleFinishImporting={handleFinishImporting} />
      </Modal>
      
    )
}