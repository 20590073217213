import { createSlice } from "@reduxjs/toolkit";
import { Bot } from "@common-models/bot";
import { getChatbotTempId } from "@common-services/utils";
import {
    Connection,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
} from 'reactflow';
import { initialNodes, initialEdges } from '@common/components/bt-chatbot/initial-nodes';

const hardCodedVariables = {
    displayName: 'displayName',
    phoneNumber: 'phoneNumber',
    whatsappId: 'whatsappId',
    isGroup: 'isGroup',
    isChannel: 'isChannel',
}


export const initialBot: Bot = {
    name: 'New Chatbot (unsaved)',
    viewport: { x: 100, y: 400, zoom: 0.8 },
    botSettings: {
        blockSaveNewBotSettings: false,
        isFallbackMessage: true,
        fallbackMessageTrigger: 1,
        fallbackMessage: 'Use the number of the option you want to choose',
        isChatbotTimer: true,
        chatbotTimerCountdown: 30,
        chatbotTimerEndMessage: 'Chatbot session will be closed soon',
        chatbotTimerMessageBeforeEnd: 5,
        variables: hardCodedVariables,
    },
}
export interface ChatbotState {
    currentBot: Bot;
    isReadyToLoadFlow: boolean;
    currentReactFlowPosition: { x: number, y: number };
    menuPosition: { x: number, y: number };
    startNode: any;
    isLocked: boolean;
    isDraft: boolean;
    isInitialLoad: boolean;
    // New React Flow state
    nodes: Node[];
    edges: Edge[];
    slugBotId: string | undefined;
    isReadOnly: boolean;
    nodeEventsAccordionState: Record<string, boolean>;
    currentSessionId: string | null;
    focusedNodeId: string | null;
    // New event filter state
    showEvents: boolean;
    showChat: boolean;
    // Loader progress message
    loaderProgressMessage: string | null;
    // Node dragging state
    isDragging: boolean;
}


const initialState: ChatbotState = {
    currentBot: undefined,
    isReadyToLoadFlow: false,
    currentReactFlowPosition: { x: 0, y: 0 },
    menuPosition: { x: 0, y: 0 },
    startNode: null,
    isLocked: false,
    isDraft: false,
    isInitialLoad: true,
    // Initialize React Flow state
    nodes: initialNodes,
    edges: initialEdges,
    slugBotId: undefined,
    isReadOnly: false,
    nodeEventsAccordionState: {},
    currentSessionId: null,
    focusedNodeId: null,
    // Initialize event filter state
    showEvents: true,
    showChat: true,
    // Initialize loader progress message
    loaderProgressMessage: null,
    isDragging: false,
};

const ChatbotSlice = createSlice({
    name: "Chatbot",
    initialState,
    reducers: {
        setCurrentBot: (state, action) => {
            state.currentBot = action.payload ?? initialBot;
            if (!state.isInitialLoad && !state.isDraft) {
                state.isDraft = true;
            }
        },
        setIsReadyToLoadFlow: (state, action) => {
            state.isReadyToLoadFlow = action.payload;
        },
        setCurrentReactFlowPosition: (state, action) => {
            state.currentReactFlowPosition = action.payload;
        },
        setMenuPosition: (state, action) => {
            state.menuPosition = action.payload;
        },
        setStartNode: (state, action) => {
            state.startNode = action.payload;
        },
        setIsLocked: (state, action) => {
            state.isLocked = action.payload;
        },
        setIsDraft: (state, action) => {
            if (!action.payload) {
                state.currentBot = initialBot
            }
            state.isDraft = action.payload;
        },
        setIsInitialLoad: (state, action) => {
            state.isInitialLoad = action.payload;
        },
        resetFlowState: (state) => {
            state.isInitialLoad = true;
            state.currentBot = initialBot
            state.isDraft = false;
        },
        // New React Flow actions
        updateNodes: (state, action: { payload: Node[] }) => {

            state.nodes = action.payload;
        },
        updateEdges: (state, action: { payload: Edge[] }) => {
            state.edges = action.payload;
        },
        onNodesChange: (state, action: { payload: NodeChange[] }) => {
            state.nodes = applyNodeChanges(action.payload, state.nodes);
            if (!state.isInitialLoad && !state.isDraft) {
                state.isDraft = true;
            }
        },
        onEdgesChange: (state, action: { payload: EdgeChange[] }) => {
            state.edges = applyEdgeChanges(action.payload, state.edges);
            if (!state.isInitialLoad && !state.isDraft) {
                state.isDraft = true;
            }
        },
        onConnect: (state, action: { payload: Connection }) => {
            if (action.payload.target !== action.payload.source) {
                const customEdge = {
                    ...action.payload,
                    id: getChatbotTempId(),
                    type: 'custom-edge',
                };
                state.edges = addEdge(customEdge, state.edges);
                if (!state.isInitialLoad && !state.isDraft) {
                    state.isDraft = true;
                }
            }
        },
        updateViewport: (state, action: { payload: { x: number; y: number; zoom: number } }) => {
            state.currentBot = {
                ...state.currentBot,
                viewport: action.payload
            };
        },
        setSlugBotId: (state, action) => {
            state.slugBotId = action.payload;
        },
        setIsReadOnly: (state, action) => {
            state.isReadOnly = action.payload;
        },
        setNodeEventAccordion: (state, action: { payload: { eventId: string; isExpanded: boolean } }) => {
            const { eventId, isExpanded } = action.payload;
            state.nodeEventsAccordionState[eventId] = isExpanded;
        },
        expandAllNodeEvents: (state, action: { payload: { eventIds: string[] } }) => {
            // Create a new state object with only the current events expanded
            const newState: Record<string, boolean> = {};
            action.payload.eventIds.forEach(id => {
                newState[id] = true;
            });
            state.nodeEventsAccordionState = newState;
        },
        collapseAllNodeEvents: (state, action: { payload: { eventIds: string[] } }) => {
            // Create a new state object with only the current events collapsed
            const newState: Record<string, boolean> = {};
            action.payload.eventIds.forEach(id => {
                newState[id] = false;
            });
            state.nodeEventsAccordionState = newState;
        },
        setCurrentSessionId: (state, action: { payload: string | null }) => {
            state.currentSessionId = action.payload;
        },
        setFocusedNodeId: (state, action: { payload: string | null }) => {
            state.focusedNodeId = action.payload;
        },
        setShowEvents: (state, action: { payload: boolean }) => {
            state.showEvents = action.payload;
        },
        setShowChat: (state, action: { payload: boolean }) => {
            state.showChat = action.payload;
        },
        resetEventFilters: (state) => {
            state.showEvents = true;
            state.showChat = true;
        },
        setLoaderProgressMessage: (state, action: { payload: string | null }) => {
            state.loaderProgressMessage = action.payload;
        },
        setIsDragging: (state, action: { payload: boolean }) => {
            state.isDragging = action.payload;
            console.log('setIsDragging', action.payload);
        },
    },
});

export const {
    setCurrentBot,
    setIsReadyToLoadFlow,
    setCurrentReactFlowPosition,
    setMenuPosition,
    setStartNode,
    setIsLocked,
    setIsDraft,
    setIsInitialLoad,
    resetFlowState,
    updateNodes,
    updateEdges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    updateViewport,
    setSlugBotId,
    setIsReadOnly,
    setNodeEventAccordion,
    expandAllNodeEvents,
    collapseAllNodeEvents,
    setCurrentSessionId,
    setFocusedNodeId,
    setShowEvents,
    setShowChat,
    resetEventFilters,
    setLoaderProgressMessage,
    setIsDragging,
} = ChatbotSlice.actions;

export default ChatbotSlice.reducer;


