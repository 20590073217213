import { createSelector, createSlice } from "@reduxjs/toolkit";

import { UserMessage } from "@common-models/user-message";
import { campaignsSelectByQuery, userMessagesSelectByQuery, userMessagesSelectByQueryOnlyLength } from "@common-reducers/DBServiceThunks";
import { toRuleSet } from "@common/components/schedule-picker/recurrence-utils";
import { CommonRootState } from "@common/types/common-root-state-type";
import _ from 'lodash';
import moment from "moment";
import { getMessagesByCampaignId, getStuckMessagesByCampaignId } from "./CampaignSelectors";
import { messageLimitSelector } from "./UserSelectors";
import { selectWhatsappContactById } from "./WhatsAppSelectors";

export interface UserInfo {
  email: string;
  id: string;
}
type UserMessageInRedis = {
  [campaignId: string]: {
    id: string;
    text: string;
    createdAt: any;
    status: string;
    // more: number;
  }[];
};
export interface UserMessageState {
  contactWithLastMessage: UserInfo[];
  userMessagesInRedis: UserMessageInRedis;
}

const initialState: UserMessageState = {
  contactWithLastMessage: [],
  userMessagesInRedis: {},

};

const UserMessageSlice = createSlice({
  name: "UserMessage",
  initialState,
  reducers: {
    setUserMessagesInRedisByCampaignId(state, action) {
      state.userMessagesInRedis = {
        ...state.userMessagesInRedis,
        ...action.payload, // Merge the new structure into the state
      };
    },

  },
});



export const getContactWithLastMessage = createSelector(
  [
    (state) => state,
    (state, searchToken) => searchToken
  ],
  (state: CommonRootState, searchToken: string | undefined) => {

    // Check if a rule set has any future events after a given date
    const hasRemainingEvents = (ruleSetAsString: string | null, dueDate: string | null): boolean => {
      if (!dueDate) return false; // Default to false if no due date
      if (!ruleSetAsString) return moment.utc(dueDate).isAfter(moment.utc()); // Check due date only if no recurrence rules

      const now = moment.utc().startOf('minute').toDate();
      const parsedDueDate = moment.utc(dueDate).toDate();
      const ruleSet = toRuleSet(ruleSetAsString);

      // Check if dueDate is in the future
      if (parsedDueDate >= now) {
        // Future dueDate: Ensure recurrence rules do not exclude dueDate
        return ruleSet.between(parsedDueDate, parsedDueDate, true).length > 0 || ruleSet.after(now) !== null;
      }

      // Past dueDate: Check if there are future recurrence events
      return ruleSet.after(now) !== null;
    };


    const lowerCaseToken = searchToken?.toLowerCase();
    const messageList = userMessagesSelectByQuery(state.DBUserMessageReducer, {
      $or: [
        { deleted: { $exists: false } },
        { deleted: false }
      ],
      status: "pending"
    }, null, { dueDate: 1 })
      .filter(message => !message.campaignId)
      .filter(message => hasRemainingEvents(message.rruleset, message.dueDate))

    const groupedByWaId = _.groupBy(messageList, (message) => message?.contactList[0]?.id ?? '');

    let allMessages: UserMessage[] = []

    for (const [key, userMessages] of Object.entries(groupedByWaId)) {

      const apiCount = userMessages.filter(msg => msg.api).length;
      const campaignCount = userMessages.filter(msg => msg.campaignId).length;
      const scheduledCount = userMessages.length - apiCount - campaignCount;

      const message = userMessages[0];

      allMessages.push({
        ...message,
        contactWAId: message?.contactList[0]?.id ?? "",
        contactName: message?.contactList[0]?.name ?? message?.contactList[0]?.id ?? "",
        contactImageUrl: message?.contactList[0]?.imageUrl,
        messageCount: userMessages.length,

        messageScheduledCount: scheduledCount,
        messageApiCount: apiCount,
        messageCampaignCount: campaignCount,
      })
    }

    // let messageListGroupByPerson: UserMessage[] = []

    // messageList.forEach(message => {

    //   let isContactExist = false;

    //   message = {
    //     ...message,
    //     contactWAId: message?.contactList[0]?.id ?? "",
    //     contactName: message?.contactList[0]?.name ?? message?.contactList[0]?.id ?? "",
    //     contactImageUrl: message?.contactList[0]?.imageUrl,
    //     messageCount: 1,
    //   }

    //   messageListGroupByPerson.forEach(message2 => {

    //     if (message.contactWAId === message2.contactWAId) {
    //       message2.messageCount++
    //       isContactExist = true;
    //       return;
    //     }

    //   })

    //   if (!isContactExist) {
    //     messageListGroupByPerson.push(message)
    //   }

    // });


    return allMessages.filter(message => { // messageListGroupByPerson
      const whatsappContact = selectWhatsappContactById(state, message.contactWAId)

      return lowerCaseToken === undefined ||
        whatsappContact?.name?.toLowerCase()?.indexOf(lowerCaseToken) > -1 ||
        message.message?.toLowerCase()?.indexOf(lowerCaseToken) > -1;
    });

  }
);

export const getAllEntitiesIdsAndTypeForCalendarSidebar = createSelector(
  (state: CommonRootState) => state,
  (state,) => {
    const searchToken = state.WhatsAppReducer.extensionSidebarSearchToken
    // Function to sort combined array of UserMessages and Campaigns
    function sortCombinedEntities(allEntities) {
      return allEntities.sort((a, b) => {
        // Get the due date for each object based on its type
        const getDueDate = (entity) => {
          // If it has campaignStatus, it's a Campaign
          if ('campaignStatus' in entity) {
            return entity.schedule?.dueDate || ''; // Access dueDate through schedule
          }
          // Otherwise, assume it's a UserMessage
          return entity.dueDate || '';
        };

        // Get due dates for both objects
        const dueDateA = getDueDate(a);
        const dueDateB = getDueDate(b);

        // If both have valid due dates, compare them
        if (dueDateA && dueDateB) {
          const dateCompare = new Date(dueDateA).getTime() - new Date(dueDateB).getTime();

          // If dates are equal (or very close), use campaign status as tiebreaker
          if (dateCompare === 0 && 'campaignStatus' in a && 'campaignStatus' in b) {
            // If a is running and b is pending, a comes first
            if (a.campaignStatus === 'running' && b.campaignStatus === 'pending') return -1;
            // If a is pending and b is running, b comes first
            if (a.campaignStatus === 'pending' && b.campaignStatus === 'running') return 1;
          }

          return dateCompare;
        }

        // If only one has a due date, prioritize it
        if (dueDateA && !dueDateB) return -1;
        if (!dueDateA && dueDateB) return 1;

        // If neither has a due date, use campaign status as fallback for campaigns
        if ('campaignStatus' in a && 'campaignStatus' in b) {
          if (a.campaignStatus === 'running' && b.campaignStatus === 'pending') return -1;
          if (a.campaignStatus === 'pending' && b.campaignStatus === 'running') return 1;
        }

        // Otherwise maintain original order
        return 0;
      });
    }

    // Usage example
    const messageListGroupByPerson = getContactWithLastMessage(state, searchToken)
      .map(msg => ({
        _id: msg._id,
        type: 'message'
      }))
    const pendingOrRunningCampaigns = campaignsSelectByQuery(state.DBCampaignReducer, {
      campaignStatus: { $in: ['pending', 'running'] }
    })
      .map(campaign => ({
        _id: campaign._id,
        type: 'campaign'
      }))

    const allEntities = [...messageListGroupByPerson, ...pendingOrRunningCampaigns];
    const sortedEntities = sortCombinedEntities(allEntities);

    return sortedEntities;
  }
)

export const isOverLimitOfScheduledMessages = createSelector(
  (state: CommonRootState) => state,
  (state) => {
    const scheduledMessagesCount = userMessagesSelectByQueryOnlyLength(state.DBUserMessageReducer, { $or: [{ deleted: { $exists: false } }, { deleted: false }] });
    const currentMessageId = state.AddScheduledMessageButtonReducer.message._id
    const messageLimit = messageLimitSelector(state);
    return scheduledMessagesCount >= messageLimit && (!currentMessageId);

  }
);

export const selectUserMessagesInRedis = (state: CommonRootState) =>
  state.UserMessageReducer?.userMessagesInRedis;


export const selectScheduledMessagesInRedisAndInDBSelector = createSelector(
  [
    (state: CommonRootState) => state,
    selectUserMessagesInRedis,
    (_, campaignId: string) => campaignId
  ],
  (state, userMessagesInRedis, campaignId) => {
    const userMessagesInRedisByCampaignId = userMessagesInRedis?.[campaignId] ?? [];
    const userMessagesInDb = getMessagesByCampaignId(state, campaignId);
    const stuckMessages = getStuckMessagesByCampaignId(state, campaignId);

    const newMessagesListInDb = userMessagesInDb.map((message) => {
      if (stuckMessages.some(stuckMessage => stuckMessage._id === message._id)) {
        return null;
      }
      return {
        ...message,
        createdAt: new Date(message.createdAt),
        status: "Waiting In DB",

      }
    }).filter(Boolean);

    const mergedMessages = [...userMessagesInRedisByCampaignId, ...newMessagesListInDb];

    return mergedMessages;
  }
);

export const {
  setUserMessagesInRedisByCampaignId
} = UserMessageSlice.actions;

export const userMessageActions = UserMessageSlice.actions;

export default UserMessageSlice.reducer;
