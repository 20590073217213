import { setActiveBulkView, setCampaignIdForLogsView } from '@common-reducers/CampaignReducer';
import { getStuckMessagesByCampaignId, selectCampaignMessageStats, selectFilteredCampaignLogs } from '@common-reducers/CampaignSelectors';
import { getCampaignRealStatus } from '@common-reducers/CampaignThunks';
import { campaignsSelectOneObjectByQuery, DBCampaignThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { getMessagesAckAndCalculateStatisticsThunk } from '@common/reducers/CampaignLogsThunks';
import { selectScheduledMessagesInRedisAndInDBSelector } from '@common/reducers/UserMessageReducer';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Badge, Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getChatsAndContactsThunk } from '../../../thunks/WhatsappThunks';
import BulkButton from '../bulk-button';
import CampaignTabHeader from '../campaign-tab-header';
import InProgressCampaignIndicator from '../in-progress-campaign-indicator';
import { StatusBadge } from '../status-labels';
import CampaignMessageListContainer from './campaign-message-logs/campaign-message-list-container';
import CampaignMessageLogStatusFilter from './campaign-message-logs/campaign-message-log-status-filter';
import CampaignMessagesWaitingToBeSent from './campaign-message-logs/campaign-messages-waiting-to-be-sent';
import CampaignSentLogs from './campaign-message-logs/campaign-sent-logs';

// Types
type FilterType = 'sent' | 'delivered' | 'read' | 'played' | 'failed' | 'pending' | null;

// Constants
const POLLING_INTERVAL = 15000;

// Component
export default function BulkLogsView() {
    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [activeFilter, setActiveFilter] = useState<FilterType>(null);

    // Selectors
    const campaignIdForLogsView = useAppSelector((state) => state.CampaignReducer.campaignIdForLogsView);
    const messageStats = useAppSelector(state => selectCampaignMessageStats(state, campaignIdForLogsView));
    const messagesWaitingToBeSent = useAppSelector((state) => selectScheduledMessagesInRedisAndInDBSelector(state, campaignIdForLogsView));
    const stuckMessages = useAppSelector(state => getStuckMessagesByCampaignId(state, campaignIdForLogsView));
    const campaign = useAppSelector((state) => campaignsSelectOneObjectByQuery(state.DBCampaignReducer, { _id: campaignIdForLogsView }));

    // Campaign Status Polling
    useEffect(() => {
        if (!campaignIdForLogsView) return;

        dispatch(DBCampaignThunks.find({ _id: campaignIdForLogsView }));
    }, [campaignIdForLogsView, dispatch]);

    useEffect(() => {
        if (!campaignIdForLogsView) return;

        const updateCampaignStats = () => {
            dispatch(getMessagesAckAndCalculateStatisticsThunk(campaignIdForLogsView));
        };
        updateCampaignStats();
        const intervalId = setInterval(updateCampaignStats, POLLING_INTERVAL);
        return () => clearInterval(intervalId);
    }, [campaignIdForLogsView, dispatch]);

    useEffect(() => {
        dispatch(getChatsAndContactsThunk({ shouldForceUpdate: true }));
    }, [dispatch]);

    const handleBack = useCallback(() => {
        dispatch(setCampaignIdForLogsView(undefined));
        dispatch(setActiveBulkView("campaignManagementView"));
    }, [dispatch]);

    const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    }, []);

    const handleFilterClick = useCallback((filter: FilterType) => {
        setActiveFilter(current => current === filter ? null : filter);
    }, []);

    const filterSelector = (state: any) => {
        return selectFilteredCampaignLogs(state, campaignIdForLogsView, activeFilter);
    };

    if (!campaign || !campaignIdForLogsView) {
        return null;
    }

    const realStatus = getCampaignRealStatus(campaign.campaignStatus, !!campaign.contactCount);

    return (
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
            <Box sx={{ width: '700px', marginTop: "10px" }}>
                <BulkButton
                    onClick={handleBack}
                    buttonString="Back"
                    Icon={KeyboardArrowLeft}
                />

                {/* Campaign Header */}
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative', }}>
                
                    <CampaignTabHeader
                        sx={{ mt: 0.5 }}
                        title='Campaign Logs'
                        subTitle='Track the progress of your campaign'
                    />
                </Stack>
                <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', gap: 1,flexDirection: 'row',margin:'6px 0px' }}>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    Total Messages: {messageStats.total}
                </Typography>
                        {realStatus && StatusBadge(realStatus)}
                        {campaign?.contactCount && (realStatus === 'Running' || realStatus === 'Paused') && (
                            <InProgressCampaignIndicator
                                campaignId={campaign._id}
                                sx={{ height: 20, borderRadius: 1, mt: 1 }}
                            />
                        )}
                    </Stack>

                {/* Filters and Stats */}
                <CampaignMessageLogStatusFilter
                    messageStats={messageStats}
                    activeFilter={activeFilter}
                    onFilterClick={handleFilterClick}
                />
              

                {/* Tabs */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2, mb: 2 }}>
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        <Tab
                            label={
                                <Badge
                                    badgeContent={messageStats.sent + messageStats.delivered + messageStats.read}
                                    color="success"
                                    max={99999}
                                >
                                    <Box sx={{ pr: 1 }}>Sent</Box>
                                </Badge>
                            }
                        />
                        <Tab
                            label={
                                <Badge
                                    badgeContent={messagesWaitingToBeSent.length}
                                    color="secondary"
                                    max={99999}
                                >
                                    <Box sx={{ pr: 1 }}>Waiting to be sent</Box>
                                </Badge>
                            }
                        />
                        {stuckMessages?.length > 0 && (
                            <Tab
                                label={
                                    <Badge
                                        badgeContent={stuckMessages.length}
                                        color="error"
                                        max={99999}
                                    >
                                        <Box sx={{ pr: 1 }}>Stuck Messages</Box>
                                    </Badge>
                                }
                            />
                        )}
                    </Tabs>
                </Box>

                {/* Tab Content */}
                {activeTab === 0 && (
                    <CampaignSentLogs
                        campaignId={campaignIdForLogsView}
                        filterSelector={filterSelector}
                    />
                )}
                {activeTab === 1 && (
                    <CampaignMessagesWaitingToBeSent
                        logs={messagesWaitingToBeSent}
                    />
                )}
                {activeTab === 2 && stuckMessages?.length > 0 && (
                    <CampaignMessageListContainer
                        logsData={stuckMessages}
                    />
                )}
            </Box>
        </Stack>
    );
}