import { CircularProgress } from "@mui/material";
import { RocketIcon } from "lucide-react";
import { useCallback, useState } from "react";
import { useReactFlow } from "reactflow";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import { setIsReadyToLoadFlow, setLoaderProgressMessage } from "@common/reducers/ChatbotReducer";
import { getCurrentBotSelector } from "@common/reducers/ChatbotSelectors";
import { saveBotThunk } from "@common-reducers/ChatbotThunks";
import BTbotbutton from "./bt-bot-button";
import { useDeploymentValidation } from "@common/hooks/useDeploymentValidation";
import { EnhancedActivationDialog } from "./enhanced-activation-dialog";
import { DBBotDeploymentThunks } from "@/common/reducers/DBServiceThunks";

interface DeployButtonProps {
    disabled?: boolean;
    onBeforeDeploy?: () => boolean | Promise<boolean>;
    onAfterDeploy?: () => void;
    localCurrentBot?: any; // Type this properly based on your bot type
    shouldClose?: boolean;
}

export default function DeployButton({
    disabled = false,
    onBeforeDeploy,
    onAfterDeploy,
    localCurrentBot,
    shouldClose = false,
}: DeployButtonProps) {
    const dispatch = useAppDispatch();
    const reactFlowInstance = useReactFlow();

    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const currentBot = useAppSelector(getCurrentBotSelector);
    const validationResult = useDeploymentValidation(localCurrentBot);

    const handleSave = useCallback(async () => {
        try {
            setIsLoading(true);
            const viewport = reactFlowInstance.getViewport();
            dispatch(setIsReadyToLoadFlow(false));
            dispatch(setLoaderProgressMessage('Starting deployment process...'));

            const result = await dispatch(saveBotThunk({
                viewport,
                shouldDeploy: true,
                localCurrentBot: localCurrentBot || currentBot
            }));

            dispatch(DBBotDeploymentThunks.find({ botId: currentBot?._id }));

            if (result.type.endsWith('/rejected')) {
                console.error('Save failed:', result.payload);
                return false;
            }
            return true;
        } catch (error) {
            console.error('Save operation failed:', error);
            dispatch(setLoaderProgressMessage(`Error: ${error.message || 'Deployment failed'}`));
            setTimeout(() => dispatch(setLoaderProgressMessage(null)), 2000);
            return false;
        } finally {
            dispatch(setIsReadyToLoadFlow(true));
            setIsLoading(false);
        }
    }, [dispatch, reactFlowInstance, localCurrentBot, currentBot]);

    const handleDeploy = useCallback(async () => {
        try {
            if (onBeforeDeploy) {
                const canProceed = await onBeforeDeploy();
                if (!canProceed) return;
            }

            // Always show dialog if there's any validation warning
            if (validationResult.type !== 'VALID') {
                setDialogOpen(true);
            } else {
                const success = await handleSave();
                if (success && onAfterDeploy) {
                    onAfterDeploy();
                }
            }
        } catch (error) {
            console.error('Deploy operation failed:', error);
        }
    }, [validationResult, handleSave, onBeforeDeploy, onAfterDeploy]);

    const handleConfirm = async () => {
        setDialogOpen(false);
        const success = await handleSave();
        if (success && onAfterDeploy) {
            onAfterDeploy();
        }
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <BTbotbutton
                onClick={handleDeploy}
                disabled={disabled || isLoading}
                startIcon={isLoading ? null : <RocketIcon style={{ width: '18px' }} />}
                sx={{
                    minWidth: '120px',
                    position: 'relative',
                    '& .MuiCircularProgress-root': {
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }
                }}
            >
                {isLoading ? (
                    <CircularProgress size={20} sx={{ color: 'inherit' }} />
                ) : (
                    'Deploy'
                )}
            </BTbotbutton>

            <EnhancedActivationDialog
                open={dialogOpen}
                onClose={handleCancel}
                onConfirm={handleConfirm}
                isDeployment={true}
                loading={isLoading}
                validationResult={validationResult}
            />
        </>
    );
}