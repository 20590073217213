import BTButton from "@common-components/bt-button/bt-button";
import ExtensionIcon from '@mui/icons-material/Extension';
import { Box, Stack, TextField, Tooltip } from "@mui/material";
import {
  updateUninstallState
} from "@common-reducers/UninstallReducer";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import axios from "axios";
import { appConfig } from "@common-config/app.config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./uninstall-view.module.scss";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";
import { selectCurrentUserEmail } from '@common-reducers/UserSelectors';

const UninstallView = () => {
  const analyticsService: AnalyticsService = useAnalyticsService();

  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false);
  const uninstallForm = useAppSelector(
    (state) => state.UninstallReducer.uninstallForm
  );
  const userEmail = useAppSelector(selectCurrentUserEmail)

  const isValidFeedback = (uninstallReason: string) => {
    return (uninstallReason?.trim()?.length ?? 0) >= 5 && (userEmail || uninstallForm.email);
  }

  function sendFeedback() {
    if (!isValidFeedback(uninstallForm?.uninstallReason)) {
      return;
    }

    setSubmitted(true);

    axios.post(`${appConfig.API_ENDPOINT}/actions`, {
      cmd: "uninstall-feedback",
      feedbackItems: `${uninstallForm.uninstallReason}`,
      email: userEmail || uninstallForm.email
    });
  }

  const getValidationMessage = () => {
    if (!userEmail && !uninstallForm.email) {
      return "Please provide your email address";
    }
    if (!uninstallForm?.uninstallReason?.trim() || uninstallForm.uninstallReason.trim().length < 5) {
      return "Please provide feedback (Minimum 5 words required) so we can improve our service";
    }
    return "";
  };

  const buildFeedbackForm = () => <>
    <h6>Why did you uninstall Blueticks?</h6>
    {!userEmail && (
      <TextField
        required
        variant="filled"
        id="filled-multiline-static"
        label="Email"
        type="email"
        onChange={(event) => {
          dispatch(
            updateUninstallState({
              path: "email",
              value: event.target.value,
            })
          );
        }}
        sx={{  marginBottom: '20px' }}
      />
    )}
    <TextField
      required
      id="filled-multiline-static"
      label="Your Feedback"
      placeholder="Please help us understand why you uninstalled Blueticks so we can improve our service"
      helperText="Minimum 5 words required"
      onChange={(event) => {
        dispatch(
          updateUninstallState({
            path: "uninstallReason",
            value: event.target.value,
          })
        );
      }}
      multiline
      rows={4}
      defaultValue=""
      variant="filled"
      sx={{ width: '100%', marginBottom: '20px' }}
    />

    <div style={{
      display: 'flex',
      justifyContent: 'right',

    }} >
      <Tooltip 
        title={!isValidFeedback(uninstallForm?.uninstallReason) ? getValidationMessage() : ""}
        placement="top"
      >
        <span>
          <BTButton
            onClick={sendFeedback}
            disabled={!isValidFeedback(uninstallForm?.uninstallReason)}
            sx={{
              width: "150px",
              height: '40px',
            }}>
            Send Feedback
          </BTButton>
        </span>
      </Tooltip>
    </div>
  </>

  const buildThankyouMessage = () => {
    return <Stack direction="row" sx={{
      height: '100%'
    }} justifyContent="center" alignItems="flex-start">
      <h6>Thank you for you feedback!</h6>
    </Stack>;
  }

  useEffect(() => {
    analyticsService.event("uninstall_extension", {
      category: "uninstall",
      action: "uninstall extension",
    });

  }, []);

  return (
    <div>
      <div className={classes.headerContainer}>
        <div>
          <img className={classes.headerLogoImage} src="/img/logo.png" alt="" />
        </div>
        <div>


          <Link to={appConfig.extensionStoreLink}
            style={{
              textDecoration: "none"
            }} >
            <BTButton
              sx={{
                height: '40px',
              }}
              startIcon={<ExtensionIcon />}  > Reinstall Extension</BTButton>
          </Link>



        </div>
      </div>
      <div className={classes.titleContainer}>
        <h3 className={classes.mainTitle}>Help Us Improve</h3>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.blueticksMessage}>
          <div className={classes.imgContainer}>
            <img
              className={classes.robertImg}
              src="/img/robertimg.png"
              alt=""
            />
          </div>
          <p>Hi,</p>
          <p>
            I'm Robert, one of Blueticks' engineers. My team works hard to build features that can help anyone, anywhere, communicate clearly and effectively.
          </p>
          <p>
            We know our extension isn't perfect, but we're committed to making it the best possible for you. Can you help us by letting us know why you uninstalled the Blueticks browser extension?
          </p>
          <p>
            We use your feedback to understand how we can make our product better and more valuable. We're committed to improving it and hope to see you again.
          </p>
          <div className={classes.signature}>
            <img src="/img/Robert-Fiance-Signature.png" alt="" />
            <div>Senior Engineer</div>
          </div>
        </div>



        <Box flex={1} className={classes.whyUninstall}>
          {submitted ? buildThankyouMessage() : buildFeedbackForm()}
        </Box>
      </div>
    </div>
  );
};

export default UninstallView;
