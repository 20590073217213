import AttachmentPreview from '@common-components/attachment-preview/attachment-preview';
import CustomSourceHandle from '@common-components/bt-chatbot/custom-source-handle';
import RowStack from '@common-components/common/row-stack/RowStack';
import { getNodeDataByNodeId } from '@common-reducers/ChatbotSelectors';
import { changeNodeDataThunk } from '@common-reducers/ChatbotThunks';
import { useAppDispatch, useAppSelector } from '@common-reducers/hooks/store.hook';
import { getHandleId, getSourceHandleStyle } from '@common-services/utils';
import { orangeStyle } from '@common/reusable/commonStyles';
import { DeleteOutline, Download } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { Position } from 'reactflow';
import { nodeTypes } from '../bt-nodes-def';
import classes from "./question-node.module.scss";
import React, { useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import BTChatbotModal from '../../bot-components/bt-chatbot-modal';

interface QuestionNodeData {
    type: string;
    question?: string;
    questionAsset?: {
        name: string;
        type: string;
        url: string;
    };
    answers: {
        [key: string]: string | {
            label: string;
            bgcolor: string;
            order: number;
        };
    };
    handles: {
        inputs: Array<{ id: string; type: string; }>;
        outputs: Array<{ id: string; type: string; }>;
    };
    isFreeTextAnswer?: boolean;
}

interface Props {
    width?: string;
    disabled?: boolean;
    id: string;
}

function QuestionNodeContainer({ width, disabled, id }: Props) {
    const dispatch = useAppDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    const data = useAppSelector(state => getNodeDataByNodeId(state, id)) as QuestionNodeData;

    const isThereDataToShow = useMemo(() => {
        const isThereQuestion = data?.question;
        const isThereAttachment = data?.questionAsset?.url || data?.questionAsset?.name || data?.questionAsset?.type;
        const isFreeTextAnswer = data?.isFreeTextAnswer;
        return isThereQuestion || isThereAttachment || isFreeTextAnswer;
    }, [data]);

    let handleIds = Object.keys(data?.answers ?? {});
    if (data?.type === 'status') {
        handleIds = handleIds.sort((a, b) => {
            const answerA = data.answers[a] as { order: number };
            const answerB = data.answers[b] as { order: number };
            return answerA.order - answerB.order;
        });
    }
    const nodeColor = nodeTypes?.[data?.type]?.color;

    const removeAttachment = async () => {
        await dispatch(changeNodeDataThunk({
            id,
            dataToPatch: { questionAsset: undefined }
        }));
    };

    const downloadAttachment = async (attachmentUrl: string, attachmentName: string) => {
        try {
            const response = await fetch(attachmentUrl);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadUrl;
            downloadLink.setAttribute('download', attachmentName || 'download');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed', error);
        }
    };

    const attachmentBox = () => (
        <Stack spacing={2}>
            {data?.questionAsset && (
                <div style={{
                    position: "relative",
                }}>
                    <Stack alignItems="center" justifyContent="center">
                        <RowStack sx={{ mb: 2 }}>
                            <Button
                                variant="outlined"
                                startIcon={<DeleteOutline />}
                                onClick={removeAttachment}
                                style={{
                                    ...orangeStyle,
                                    marginRight: '10px',
                                }}
                            >
                                REMOVE ATTACHMENT
                            </Button>
                            <IconButton onClick={() => downloadAttachment(data.questionAsset.url, data.questionAsset.name)}>
                                <Download />
                            </IconButton>
                        </RowStack>

                        <AttachmentPreview
                            attachmentName={data.questionAsset.name}
                            attachmentType={data.questionAsset.type}
                            attachmentUrl={data.questionAsset.url}
                            previewImageWidth={'450px'}
                        />
                    </Stack>
                </div>
            )}
        </Stack>
    );

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <Stack>
            {!isThereDataToShow &&
                <div
                    onClick={handleOpenModal}
                    className={classes.answer}
                    style={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.transform = 'scale(1.02)';
                        e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                    }}
                >
                    Click HERE to edit
                </div>
            }
            {isThereDataToShow && (
                <div className={`${classes.question} ${classes.question_node_wrapper}`}>
                    <p style={{ margin: 0 }}>{data?.question}</p>
                    {attachmentBox()}
                </div>
            )}
            {handleIds?.length > 0 &&
                <div className={classes.answers}>
                    {handleIds.map((handleId, i) => {
                        const answer = data?.type === 'question'
                            ? data?.answers?.[handleId] as string
                            : (data?.answers?.[handleId] as { label: string }).label;
                        return (
                            <div
                                className={classes.answer}
                                key={i}
                                style={{
                                    backgroundColor: data?.type === 'status'
                                        ? (data?.answers?.[handleId] as { bgcolor: string }).bgcolor
                                        : undefined,
                                    color: data?.type === 'status' ? 'white' : undefined,
                                }}
                            >
                                {answer}
                                <CustomSourceHandle
                                    key={i}
                                    type="source"
                                    position={Position.Right}
                                    id={getHandleId(data.handles, 'source', i)}
                                    className={data?.type === 'question'
                                        ? classes.question_source_handle
                                        : classes.status_source_handle}
                                    style={getSourceHandleStyle(nodeColor)}
                                    connectioncount={1}
                                />
                            </div>
                        );
                    })}
                </div>
            }
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                sx={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <BTChatbotModal type="setQuestion" onClose={handleCloseModal} id={id} />
            </Modal>
        </Stack>
    );
}

export default React.memo(QuestionNodeContainer);