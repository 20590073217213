import { appConfig } from "../../common/config/app.config";
import store from "../../website/store/web-store";
import { ChatHistoryEntry } from "../views/chat-view/types";

interface ClaudeResponse {
    content: string;
    thinking: string | null;
    model: string;
    role: string;
}

export class WhatsappInterfaceClient {
    private static instance: WhatsappInterfaceClient;

    private constructor() {}

    public static getInstance(): WhatsappInterfaceClient {
        if (!WhatsappInterfaceClient.instance) {
            WhatsappInterfaceClient.instance = new WhatsappInterfaceClient();
        }
        return WhatsappInterfaceClient.instance;
    }

    private getHeaders(): Record<string, string> {
        const state = store.getState();
        const userState = state.UserReducer;
        const supportedUserId = new URLSearchParams(window.location.search).get('supportedUserId');
        const supportedWorkSpaceId = new URLSearchParams(window.location.search).get('workspaceId');

        const headers: Record<string, string> = {
            'Content-Type': 'application/json'
        };

        if (userState?.accessToken) {
            headers['Authorization'] = `Bearer ${userState.accessToken}`;
        }
        if (userState?.socketId) {
            headers['Socket'] = userState.socketId;
        }
        if (supportedUserId) {
            headers['supportedUserId'] = supportedUserId;
        }
        if (supportedWorkSpaceId) {
            headers['supportedWorkSpaceId'] = supportedWorkSpaceId;
        }

        return headers;
    }

    public async askClaude(query: string): Promise<ClaudeResponse> {
        const response = await fetch(`${appConfig.API_ENDPOINT}/actions`, {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify({ 
                cmd: "whatsapp-interface.askClaude",
                query 
            })
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.message || 'Failed to get response from Claude');
        }

        return response.json();
    }

    public async streamClaude(query: string, history?: ChatHistoryEntry[]): Promise<Response> {
        const response = await fetch(`${appConfig.API_ENDPOINT}/actions/stream`, {
            method: 'POST',
            headers: {
                ...this.getHeaders(),
                'Accept': 'text/event-stream',
                'Cache-Control': 'no-cache',
                'Connection': 'keep-alive'
            },
            body: JSON.stringify({
                cmd: 'whatsapp-interface.askClaude',
                query,
                history
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    }
}

export const whatsappInterfaceClient = WhatsappInterfaceClient.getInstance(); 