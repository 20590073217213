import { ReactComponent as WaGroupSVG } from '@common-assets/waGroup.svg';
import { ReactComponent as WaPersonSVG } from '@common-assets/waPerson.svg';
import RowStack from '@common-components/common/row-stack/RowStack';
import { MessageLog } from '@common-models/message-log';
import { Stack, Tooltip, useTheme, IconButton } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import moment from 'moment';
import { useState } from 'react';
import CampaignLogStatusHistoryDialog from './campaign-log-status-history-dialog';
import ContactAvatar from '../bulk-dashboard/contact-table/contact-avatar';


interface CampaignMessageLogRowProps {
    profilePicUrl?: string;
    isGroup?: boolean;
    contactName?: string;
    contactPhone?: string;
    messageLog: MessageLog;
    onClick?: () => void;
    index: number;
    status: string;
}

// Main component
export default function CampaignMessageLogRow({
    status,
    profilePicUrl,
    isGroup,
    contactName,
    contactPhone,
    messageLog,
    onClick,
    index,
}: CampaignMessageLogRowProps) {
    const theme = useTheme();
    const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false)
    // Styles with dark mode support
    const styles = {
        rowStack: {
            p: '12px',
            boxSizing: 'border-box',
            minHeight: '72px',
            borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.05)'}`,
            cursor: 'pointer',
            justifyContent: 'space-between',
            '&:hover': {
                bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.02)',
            },
        },
        messageText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
            fontSize: '0.875rem'
        },
        contactName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit'
        },
        timestamp: {
            color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'inherit'
        },
        getStatusChipStyle: (status: string) => {

            const baseStyle = {
                padding: '4px 12px',
                borderRadius: '16px',
                fontSize: '0.75rem',
                fontWeight: 500,
                display: 'inline-block',
                textTransform: 'capitalize' as const,
            };

            const statusStyles = {
                'sent to server': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#1976d215' : '#1976d215',
                    color: theme.palette.mode === 'dark' ? '#90caf9' : '#1976d2',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#1976d240' : '#1976d230'}`
                },
                'delivered to device': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2e7d3215' : '#2e7d3215',
                    color: theme.palette.mode === 'dark' ? '#66bb6a' : '#2e7d32',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#2e7d3240' : '#2e7d3230'}`
                },
                'read by recipient': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#9c27b015' : '#9c27b015',
                    color: theme.palette.mode === 'dark' ? '#ba68c8' : '#9c27b0',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#9c27b040' : '#9c27b030'}`
                },
                'played by recipient': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#ed6c0215' : '#ed6c0215',
                    color: theme.palette.mode === 'dark' ? '#ffb74d' : '#ed6c02',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#ed6c0240' : '#ed6c0230'}`
                },
                'pending redis': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#ed6c0215' : '#ed6c0215',
                    color: theme.palette.mode === 'dark' ? '#ffb74d' : '#ed6c02',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#ed6c0240' : '#ed6c0230'}`
                },
                'pending db': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#ed6c0215' : '#ed6c0215',
                    color: theme.palette.mode === 'dark' ? '#ffb74d' : '#ed6c02',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#ed6c0240' : '#ed6c0230'}`
                },
                'stuck': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#d32f2f15' : '#d32f2f15',
                    color: theme.palette.mode === 'dark' ? '#ef5350' : '#d32f2f',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#d32f2f40' : '#d32f2f30'}`
                },
                'Blueticks Sent': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#1976d215' : '#1976d215',
                    color: theme.palette.mode === 'dark' ? '#90caf9' : '#1976d2',
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#1976d240' : '#1976d230'}`
                },
                'default': {
                    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(170, 200, 170, 0.08)',
                    color: theme.palette.mode === 'dark' ? '#ffb74d' : '#ed6c02',
                    border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.12)'}`
                }
            };

            const styleKey = status.toLowerCase() as keyof typeof statusStyles;
            return { ...baseStyle, ...(statusStyles[styleKey] || statusStyles.default) };
        }
    };

    const getAckStatusText = (ackData: any): string => {
        const isSentToGroupWithYourself = ackData?.deliveryRemaining === 0 && ackData?.readRemaining === 0 && ackData?.playedRemaining === 0;

        if((ackData?.deliveryRemaining === 0 && ackData?.readRemaining !== 0 && ackData?.playedRemaining !== 0)) {
            return 'Delivered to device'
        }
        if(ackData?.readRemaining === 0 && !isSentToGroupWithYourself) {
            return 'Read by recipient'
        }
        if(ackData?.playedRemaining === 0 && !isSentToGroupWithYourself) {
            return 'Played by recipient'
        }
        if(isSentToGroupWithYourself || ackData?.deliveryRemaining > 0) {
            return 'Sent to server'
        }
    };

    const getStatusBeforeAck = (status: string) => {
        if(status === 'sent') {
            return 'Blueticks Sent';
        }
        return status;
    }

    const StatusChip = ({ status, ackData }: { status: string; ackData?: any; }) => {
        const ackStatus = getAckStatusText(ackData);
        const newStatus = getStatusBeforeAck(status);

        return (
            <Tooltip title={ackStatus ? `WhatsApp Status: ${ackStatus}` : newStatus} arrow>
                <div style={styles.getStatusChipStyle(ackStatus || newStatus)}>
                    {ackStatus || newStatus}
                </div>
            </Tooltip>
        );
    };
    const StatusField = ({ messageLog, status }: { messageLog: MessageLog; status: string; }) => {

        const timestamp = messageLog?.message?.createdAt ? moment(new Date(messageLog?.message?.createdAt)).format('DD/MM/YYYY HH:mm:ss') : moment(new Date(messageLog?.createdAt)).format('DD/MM/YYYY HH:mm:ss');

        return (
            <Stack sx={{ justifyContent: 'end' }}>
                <RowStack sx={{ fontWeight: 400 }} gap={2}>
                    <span style={styles.timestamp}>{timestamp}</span>
                    <StatusChip
                        status={status}
                        ackData={messageLog.ackData}
                    />
                    <Tooltip title="View Status History">
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsHistoryDialogOpen(true);
                            }}
                            sx={{
                                color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                }
                            }}
                        >
                            <LightbulbIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </RowStack>
            </Stack>
        );
    };

    const ContactInfo = ({
        isGroup,
        contactName,
        contactPhone,
        messageLog
    }: Partial<CampaignMessageLogRowProps>) => {
        const contactId = messageLog?.message?.contactList?.[0]?.id ?? (messageLog as any)?.contactList?.[0]?.id
        return (
            <RowStack gap={2} sx={{ flex: 1, minWidth: 0 }}>
                {contactId ? (
                    <ContactAvatar
                        contactId={contactId}
                        size={40}
                        sx={{
                            justifyContent: 'flex-start',
                        }}
                    />
                ) : isGroup ? (
                    <WaGroupSVG />
                ) : (
                    <WaPersonSVG />
                )}

                <Stack sx={{ minWidth: 0, flex: 1 }}>
                    <span style={styles.contactName}>
                        {contactName} {contactPhone}
                    </span>
                    {messageLog?.message?.message && (
                        <span style={styles.messageText}>
                            {messageLog.message.message}
                        </span>
                    )}
                </Stack>
            </RowStack>
        )
    };

    if (!messageLog) {
        return (
            <RowStack sx={styles.rowStack}>
                <span style={styles.messageText}>Invalid message data</span>
            </RowStack>
        );
    }

    return (
        <>
            <RowStack
                key={index}
                onClick={onClick}
                sx={styles.rowStack}
            >
                <ContactInfo
                    isGroup={isGroup}
                    contactName={contactName}
                    contactPhone={contactPhone}
                    messageLog={messageLog}
                />
                <StatusField
                    status={status}
                    messageLog={messageLog}
                />
            </RowStack>
            <CampaignLogStatusHistoryDialog
                open={isHistoryDialogOpen}
                onClose={() => setIsHistoryDialogOpen(false)}
                messageLog={messageLog}
            />
        </>
    );
} 