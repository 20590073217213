import { WaContact } from '@common-models/waContact';
import { DBWaContactsCacheThunks } from "@common-reducers/DBServiceThunks";
import { useAppDispatch, useAppSelector, useDebounce } from '@common-reducers/hooks/store.hook';
import { selectFilteredContactsMinimal, shouldGetMoreContactsSelector } from '@common-reducers/WhatsAppSelectors';
import { isExtensionContext } from '@common-services/detect-context';
import { requestMissingContactsImagesThunk } from '@extension/context/content/thunks/WhatsappThunks';
import { CircularProgress, debounce } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { createSelector } from '@reduxjs/toolkit';
import React, { useCallback, useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import WaContactLazyLoadingVirtuosoRow from './contact-row';

// Constants
const ROW_HEIGHT = '44px';
const MIN_WIDTH = '330px';
const ROW_WIDTH = '310px';
// const CONTACTS_BATCH_SIZE = 30;
const SEARCH_DEBOUNCE_MS = 300;
const IMAGE_UPDATE_DEBOUNCE_MS = 250;



const selectItemsLoadedLocally = createSelector(
    [(state) => state.DBWaContactsCacheReducer.itemsLoadedLocaly],
    (contactsLoaded) => contactsLoaded
);

interface Props {
    height: number;
    searchFilter?: string;
    onClick?: (contact: WaContact) => void;
}

const WaContactLazyLoadingVirtuoso: React.FC<Props> = ({
    height,
    searchFilter = '',
    onClick,
}) => {
    const dispatch = useAppDispatch();
    const itemsLoadedLocally = useAppSelector(selectItemsLoadedLocally);
    const isItemsLoaded = useAppSelector(state => state.DBWaContactsCacheReducer.itemsLoaded);
    const itemsLoading = useAppSelector(state => state.DBWaContactsCacheReducer.itemsLoading);
    const shouldGetMoreContacts = useAppSelector(shouldGetMoreContactsSelector);
    const debouncedSearchFilter = useDebounce(searchFilter, SEARCH_DEBOUNCE_MS);
    const filteredList = useAppSelector(state => selectFilteredContactsMinimal(state, debouncedSearchFilter));
    const isExtension = isExtensionContext();

    // Debounced version of the thunk call
    const debouncedRequestMissingContactsImages = debounce(({ startIndex, endIndex }) => {
        dispatch(requestMissingContactsImagesThunk({ startIndex, endIndex, debouncedSearchFilter }));
    }, IMAGE_UPDATE_DEBOUNCE_MS);

    const handleRangeChanged = useCallback(({ startIndex, endIndex }) => {
        debouncedRequestMissingContactsImages({ startIndex, endIndex });
    }, [debouncedRequestMissingContactsImages]);

    // Initial data fetch
    useEffect(() => {
        if (!itemsLoadedLocally || !shouldGetMoreContacts) return;

        dispatch(DBWaContactsCacheThunks.find({
            search: debouncedSearchFilter || undefined
        }));
    }, [debouncedSearchFilter, dispatch, shouldGetMoreContacts, itemsLoadedLocally]);

    // Loading indicator
    if ((itemsLoading || (!isItemsLoaded && shouldGetMoreContacts)) && !isExtension) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: MIN_WIDTH }}>
                <CircularProgress />
            </Box>
        );
    }

    // Empty state
    if ((isItemsLoaded || isExtension) && filteredList.length === 0) {
        return null;
    }

    return (
        <Stack>
            <Virtuoso
                data={filteredList}
                rangeChanged={handleRangeChanged}
                increaseViewportBy={350}
                style={{ height: `${height}px`, minWidth: MIN_WIDTH }}
                totalCount={filteredList.length}
                itemContent={(_, contact) => (
                    <div
                        className="height-preserving-container"
                        style={{ height: ROW_HEIGHT, width: ROW_WIDTH }}
                    >
                        <WaContactLazyLoadingVirtuosoRow
                            waContact={contact}
                            onClick={onClick}
                        />
                    </div>
                )}
                className="virtuoso-scroller"
                components={{
                    Scroller: React.forwardRef((props, ref) => (
                        <div {...props} ref={ref} style={{ ...props.style, overflowX: 'hidden' }} />
                    ))
                }}
            />
        </Stack>
    );
};

export default React.memo(WaContactLazyLoadingVirtuoso);
